import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import { PAGES } from "../../constants/localization";
import InputWrapper from "../Pin/InputWrapper";
import ActionRow from "../../components/Layouts/ActionRow";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/clientRoutes";
import FlowSubmitButton from "../../components/Buttons/FlowSubmitButton";
import BaseInput from "../../components/Common/UserInputs/BaseInput";

class UsernameEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
    };
  }

  /**
   * Sets state's username address value
   * @param {object} e - Input event object
   */
  updateUsername = (e) => {
    this.setState({
      username: e.target.value,
    });
  };

  /**
   * Sets state's username value and proceeds to the
   * next step
   * @param {object} e - The form submission event
   */
  submitUsername = (e) => {
    e.preventDefault();
    const { username } = this.state;
    if (username && username != "") {
      this.props.setUsername(username);
    }
  };

  render() {
    return (
      <PageBlock id="UsernameEntry">
        <PageBlock.Title>
          {PAGES.FORGOT_PASSWORD.USERNAME_ENTRY_HEADER}
        </PageBlock.Title>
        <PageBlock.Body>
          <form id="UsernameEntryForm" onSubmit={this.submitUsername}>
            <div className="row">
              <div className="col-sm-5">
                <InputWrapper
                  className={`${this.props.hasError ? " has-error" : ""}`}
                >
                  <InputWrapper.Label labelFor="UsernameEntry">
                    {PAGES.FORGOT_PASSWORD.USERNAME_INPUT_LABEL_TEXT}
                  </InputWrapper.Label>
                  <BaseInput
                    id="UsernameEntry"
                    required
                    data-cy="username-input"
                    onChange={this.updateUsername}
                    value={this.state.username}
                  />
                  <Link
                    className="input-help-link"
                    data-cy="forgot-username"
                    to={ROUTES.SETTINGS.FORGOT_USERNAME}
                  >
                    {PAGES.FORGOT_PASSWORD.FORGOT_USERNAME_LINK_TEXT}
                  </Link>
                </InputWrapper>
              </div>
            </div>
            <ActionRow>
              <ActionRow.Forward>
                <FlowSubmitButton
                  dataCy="enter-username-submit-button"
                  disabled={this.state.username.length == 0}
                />
              </ActionRow.Forward>
            </ActionRow>
          </form>
        </PageBlock.Body>
      </PageBlock>
    );
  }
}

UsernameEntry.propTypes = {
  submitUsername: PropTypes.func,
  hasError: PropTypes.bool,
  updateUsername: PropTypes.func,
  username: PropTypes.string,
  errorMessage: PropTypes.string,
  onSubmit: PropTypes.func,
  setUsername: PropTypes.func,
};

export default UsernameEntry;
