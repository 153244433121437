import React from "react";
import PropTypes from "prop-types";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

/**
 * A single navigation bar link
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export const NavigationBarListItem = (props) => {
  const isActive = props.url.toLowerCase().indexOf(location.pathname.toLowerCase()) >= 0;

  const handleClick = () => {
    if (props.id === "MyCardIndex") {
      trackEvent(EVENTS.MY_CARD.SELECTED, {});
    }

    if (props.id === "SupportIndex") {
      trackEvent(EVENTS.SUPPORT.SUPPORT_SELECTED_CREW_LINK);
    }
  }
  return (
    <li id={props.id} className="menu-item menu-state-default">
      <span className="badge-wrap">
        <a href={props.url} id={`${props.name}Page`} className={isActive ? 'active' : ''} onClick={handleClick}>
          {props.name}
        </a>
        {props.hasAlertBadge && <span className="notification-badge" />}
      </span>
    </li>
  );
};

NavigationBarListItem.propTypes = {
  /** Main item id */
  id: PropTypes.string.isRequired,
  /** Url for the link */
  url: PropTypes.string.isRequired,
  /** Display label */
  name: PropTypes.string.isRequired,
  /** Display the alert badge */
  hasAlertBadge: PropTypes.bool,
};

export default NavigationBarListItem;
