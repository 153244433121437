import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import BREAKPOINTS from "../../../constants/breakPoints";
import MobileDate from "./MobileDate";
import DesktopDate from "./DesktopDate";

class ResponsiveDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: null,
      day: null,
      year: null,
      value: null,
      monthTouched: false,
      dayTouched: false,
      yearTouched: false,
      valid: false,
      hasError: false,
      serviceProviderFieldId: this.props.serviceProviderFieldId || "",
      id: this.props.id || "",
      datatype: this.props.datatype,
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
      supportsMobileDate: true,
    };
  }

  checkDateInput() {
    let input = document.createElement("input");
    input.setAttribute("type", "date");

    let notADateValue = "not-a-date";
    input.setAttribute("value", notADateValue);
    this.setState({
      supportsMobileDate: input.value !== notADateValue,
    });
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleWindowSizeChange);
    this.checkDateInput();
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  };

  /**
   * Event that updates the width state when/as the page is resized
   */
  handleWindowSizeChange = () => {
    this.setState({
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    });
  };

  inputUpdate = () => {
    if (this.props.inputUpdate) {
      let { datatype, id, serviceProviderFieldId, value, hasError, valid } =
        this.state;
      value = moment(`${value}`).format("MM/DD/YYYY");
      this.props.inputUpdate({
        datatype,
        id,
        serviceProviderFieldId,
        value,
        hasError,
        valid,
      });
    }
  };

  calculateDate = () => {
    let { year, month, day } = this.state;
    year = year ? (year.length == 4 ? year : null) : null;
    let value = year && month && day ? `${year}-${month}-${day}` : null;
    if (value != null) {
      this.setState(
        {
          value,
          valid: value != null,
          hasError: false,
        },
        () => this.inputUpdate()
      );
    } else {
      this.setState(
        {
          value,
          valid: value != null,
        },
        () => this.inputUpdate()
      );
    }
  };

  handleChange = (e, element) => {
    let elementValue = e.target.value ? e.target.value : null;
    if (element == "month") {
      elementValue = !e.target.value
        ? null
        : moment().month(e.target.value).format("MM");
    }
    this.setState(
      {
        [`${element}`]: elementValue,
      },
      () => this.calculateDate()
    );
  };

  handleFocus = (elementTouched) => {
    this.setState({
      [`${elementTouched}Touched`]: true,
    });
  };

  handleBlur = () => {
    let { monthTouched, dayTouched, yearTouched, value } = this.state;
    if (monthTouched && dayTouched && yearTouched && value == null) {
      this.setState({
        hasError: true,
      });
    }
  };

  getElementError = (element) => {
    let result = false;
    let { hasError, value } = this.state;
    let elementTouched = this.state[`${element}Touched`];
    let elementValue = this.state[`${element}`];
    if (elementTouched && hasError && elementValue == null && value == null) {
      result = true;
    }
    return result;
  };

  handleMobileChange = (e) => {
    let input = e.target.value;
    if (input) {
      let dateSplit = input.split("-");
      this.setState(
        {
          year: dateSplit[0],
          month: dateSplit[1],
          day: dateSplit[2],
        },
        () => this.calculateDate()
      );
    } else {
      this.setState(
        {
          year: null,
          month: null,
          day: null,
          value: null,
          valid: false,
        },
        () => this.inputUpdate()
      );
    }
  };

  handleMobileFocus = () => {
    this.setState({
      yearTouched: true,
      monthTouched: true,
      dayTouched: true,
    });
  };

  getSizedDateComponent = () => {
    const { width, supportsMobileDate } = this.state;
    const isMobile = width <= BREAKPOINTS.SM_MIN;

    return isMobile && supportsMobileDate ? (
      <MobileDate
        disabled={this.props.disabled}
        hasError={this.state.hasError}
        value={this.state.value}
        handleMobileChange={this.handleMobileChange}
        handleMobileFocus={this.handleMobileFocus}
        handleBlur={this.handleBlur}
      />
    ) : (
      <DesktopDate
        disabled={this.props.disabled}
        handleFocus={this.handleFocus}
        handleBlur={this.handleBlur}
        month={this.state.month}
        day={this.state.day}
        handleChange={this.handleChange}
        year={this.state.year}
        elementError={this.getElementError}
      />
    );
  };

  render() {
    const sizedDateComponent = this.getSizedDateComponent();
    return <div className="date-field">{sizedDateComponent}</div>;
  }
}

ResponsiveDate.propTypes = {
  serviceProviderFieldId: PropTypes.string,
  id: PropTypes.string,
  datatype: PropTypes.number,
  inputUpdate: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  moreInfo: PropTypes.object,
  disabled: PropTypes.bool,
};

export default ResponsiveDate;
