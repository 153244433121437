import React from "react";
import Lottie from "react-lottie";
import * as checkmark from "../../../assets/animations/checkmark.json";

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkmark,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const SuccessCheckmark = () => (
  <Lottie
    options={animationOptions}
    height={80}
    width={140}
    isStopped={false}
    isPaused={false}
  />
);

export default SuccessCheckmark;
