import React from "react";
import PropTypes from "prop-types";

const IconType = (type) => {
  return type != null ? ` BWIcon_type_${type}` : "";
};

export const IconFont = (props) => {
  return (
    <span className={`BWIcon ${props.icon}${IconType(props.type)}`}></span>
  );
};

IconFont.propTypes = {
  icon: PropTypes.string.isRequired,
  type: PropTypes.string,
};
