/* Brightwell Theme*/

export const COLORS = {
  WHITE: "#FFFFFF",
  BELUGA_WHITE: "#EDEFF2",
  INTERACTIVE_BLUE: "#1C74D9",
  SHARK_GREY: "#8994A6",
  SKY_GREY: "#C5D0DE",
};

const FONT_SIZES = {
  DEFAULT: "14px",
  DROPDOWN_VALUE_LINE_HEIGHT: "16px",
};

export const DROPDOWN_STYLES = {
  container: (styles) => ({
    ...styles,
    "line-height": FONT_SIZES.DEFAULT,
  }),
  menu: (styles) => {
    return {
      ...styles,
      "z-index": "9000",
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      "max-height": 300,
    };
  },
  control: (styles, { isFocused }) => ({
    ...styles,
    "border-color": isFocused ? COLORS.INTERACTIVE_BLUE : COLORS.SKY_GREY,
    "min-height": 0,
    padding: "6px 12px",
    "box-shadow": "none",
    "&:hover": {
      "border-color": isFocused ? COLORS.INTERACTIVE_BLUE : COLORS.SKY_GREY,
    },
  }),
  option: (styles, { isFocused, isSelected, isDisabled }) => ({
    ...styles,
    "padding-top": 10,
    "padding-bottom": 10,
    "background-color": isSelected
      ? COLORS.INTERACTIVE_BLUE
      : isFocused
      ? COLORS.BELUGA_WHITE
      : COLORS.WHITE,
    color: isDisabled
      ? COLORS.SHARK_GREY
      : isSelected
      ? COLORS.WHITE
      : isFocused
      ? COLORS.INTERACTIVE_BLUE
      : COLORS.INTERACTIVE_BLUE,
    "font-weight": "bold",
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? COLORS.SKY_GREY : COLORS.INTERACTIVE_BLUE,
    "font-weight": "bold",
    padding: 0,
    "line-height": FONT_SIZES.DROPDOWN_VALUE_LINE_HEIGHT,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: 0,
  }),
  input: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? COLORS.SKY_GREY : COLORS.INTERACTIVE_BLUE,
    padding: 0,
    margin: 0,
    "font-weight": "bold",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? COLORS.SKY_GREY : COLORS.INTERACTIVE_BLUE,
    padding: 0,
    "&:hover": {
      color: isDisabled ? COLORS.SKY_GREY : COLORS.INTERACTIVE_BLUE,
    },
  }),
  clearIndicator: (styles) => ({
    // this is the 'x' right aligned on a dropdown when a value is selected.
    ...styles,
    padding: "0px 5px 0px 0px",
  }),
  placeholder: (styles, { isDisabled }) => {
    return {
      ...styles,
      color: isDisabled ? COLORS.SKY_GREY : COLORS.INTERACTIVE_BLUE,
      "font-weight": "bold",
    };
  },
};
