export const ICON_FONT_CLASS = {
  ALERT: "Alert",
  ARROW_RIGHT: "Arrow-Right",
  PLUS: "Plus",
  CHECKMARK: "Checkmark",
  EXPAND: "Expand",
  LEFT_ARROW: "Left-Arrow",
  CHEVRON_DOWN: "Chevron-Down",
  CHEVRON_RIGHT: "Chevron-Right",
  CLOSE_SMALL: "Close-Small",
  CLOSE_LARGE: "Close-Large",
  EYE: "Eye",
  HAMBURGER: "Hamburger",
  SEARCH: "Search",
};

export const ICON_TYPE = {
  ALERT: "Alert",
  SUCCESS: "Success",
};
