import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../components/Common/Modal";
import { ACTION_TYPES } from "../../constants/enums";
import { eventTracker, EVENTS } from "../../services/eventTracker";

/**
 * Telemarketer sales rule shows a modal to the user, optionally followed by a second warning modal.
 */
export class TelemarketerSalesRule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      warningModalShown: false,
      modal: null,
    };

    this.onModalUpdated = this.onModalUpdated.bind(this);
  }

  /**
   * Lifecycle method that fires after the component mounts.
   */
  componentDidMount() {
    this.onModalUpdated(this.props.rule);
  }

  /**
   * Lifecycle method that fires when the props or state changes.
   * @param {*} prevProps The props as they were before the state changed.
   */
  componentDidUpdate(prevProps) {
    if (prevProps.show !== this.props.show && prevProps.show === false) {
      this.onModalUpdated(this.props.rule);
    }
  }

  /**
   *
   * @param {object} modal A modal object defining either the rule or the warning.
   */
  onModalUpdated(modal) {
    // This cloning is weird. Because the modal object and its child actions are stored in
    // Redux app state, everything blows up if we change the onClick property.
    // In order to make the change we have to make clones of everything so that it's not mutating state.
    // There should be a better way to do this but I can't find it yet.
    let modalClone = Object.assign({}, modal);
    let clonedActions = [];

    for (let action of modalClone.actions) {
      // More weird cloning so we don't mutate the state when we assign the onClick property.
      let actionClone = Object.assign({}, action);

      switch (actionClone.actionType) {
        case ACTION_TYPES.CONFIRM:
          actionClone.onClick = this.props.onRulePassed;
          break;
        case ACTION_TYPES.MODAL:
          actionClone.onClick = (e, a) => {
            eventTracker.track(EVENTS.MoneyTransfer_TelemarketerQuestion, {
              answer: "Yes",
            });
            this.onModalUpdated(a.actionContent);
          };
          break;
        case ACTION_TYPES.DISMISS:
          actionClone.onClick = this.props.onRuleFailed;
          break;
      }

      clonedActions.push(actionClone);
    }

    modalClone.actions = clonedActions;

    this.setState({ modal: modalClone });
  }

  render() {
    return (
      <div>
        {this.state.modal ? (
          <Modal
            title={this.state.modal.title}
            open={this.props.show}
            onClose={this.props.onRuleFailed}
            actions={this.state.modal.actions}
            loading={this.state.validatingPromoCode}
            content={this.state.modal.message}
          />
        ) : null}
      </div>
    );
  }
}

TelemarketerSalesRule.propTypes = {
  /** The modal object defining the rule
   * {
   *  title: "string",
   *  message: "string",
   *  actions: [
   *    {
   *      title: "string",
   *      actionType: 1,
   *      displayTpe: 2,
   *      actionContent: { }
   *    }
   *  ]
   * }
   *
   */
  rule: PropTypes.object.isRequired,
  /** Triggers the modal to show or hide */
  show: PropTypes.bool,
  /** Callback for if the user successfully agrees to to the sales rule. */
  onRulePassed: PropTypes.func.isRequired,
  /** Callback for if the user fails to agree to the rule. */
  onRuleFailed: PropTypes.func.isRequired,
};

export default TelemarketerSalesRule;
