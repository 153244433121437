import React from "react";
import PropTypes from "prop-types";
import { renderCounter } from "./DynamicFieldsHelpers";

/**
 * Keeps track of the input value. Used to render the counter.
 * @param {*} event Event object of the change
 */
const handleValueChange = (event, input, maxLength, preventInputOnMax) => {
  if (
    !preventInputOnMax ||
    (maxLength > 0 && event.target.value.length <= maxLength)
  ) {
    input.onChange(event.target.value);
  } else {
    input.onChange(event.target.value.substring(0, maxLength));
  }
};
const TextAreaField = ({ input, maxLength, preventInputOnMax }) => {
  const maxLengthProp =
    preventInputOnMax && maxLength > 0 ? { maxLength } : null;
  return (
    <div>
      <textarea
        {...input}
        onChange={(e) =>
          handleValueChange(e, input, maxLength, preventInputOnMax)
        }
        {...maxLengthProp}
        className="form-control"
      />

      {renderCounter(maxLength, input.value.length)}
    </div>
  );
};

TextAreaField.propTypes = {
  /** The input element */
  input: PropTypes.any,
  /** The maximum length allowed in the text box. */
  maxLength: PropTypes.number,
  preventInputOnMax: PropTypes.bool,
};

export default TextAreaField;
