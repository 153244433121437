import React, { useEffect } from 'react';
import { ICONS, ACTION_DISPLAY_TYPES, MONEY_TRANSFER_FIELD_IDS, TRANSACTION_SERVICE_TYPES } from '../../constants/enums';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../types/redux';
import { ROUTES } from '../../constants/clientRoutes';
import { PAGES, COMMON } from '../../constants/localization';
import { DisplaySectionFieldType } from '../../types/api/moneyTransfer';
import { trackEvent } from '../../services/eventTracker';

import ActionLink from '../../components/Common/ActionLink';
import Icon from '../../components/Common/Icon';
import DisplaySection from '../../components/MoneyTransfer/DisplaySection';
import Action from '../../components/Common/Action';
import FooterLinks from '../../components/MoneyTransfer/FooterLinks';
import { GetReceipt } from '../../actions/moneyTransfer';
import { EVENTS } from '../../constants/events';

const Receipt = ({ redirectUrl }: { redirectUrl: string }) => {
  const dispatch = useDispatch();
  const completedTransfer = useSelector((state: RootState) => state.moneyTransfer.completedTransfer);
  const { displaySections, providerName, logo, displayFields, downloadReceiptText, footer } = completedTransfer;
  const serviceType = useSelector((state: RootState) => state.moneyTransfer.workflow.serviceType);

  const handleGetReceipt = async () => {
    if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
      trackEvent(EVENTS.TO_ACCOUNT.RECEIPT_DOWNLOADED);
    }
    const moneyTransferId = completedTransfer.moneyTransferId;
    const trackingNumberField = completedTransfer.displayFields.find((field: DisplaySectionFieldType) => field.id === MONEY_TRANSFER_FIELD_IDS.TRACKING_NUMBER);
    const trackingNumberArr = trackingNumberField.formattedValue.split("(MTCN): ");
    dispatch(GetReceipt(moneyTransferId, trackingNumberArr[1] || moneyTransferId, serviceType));
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
      trackEvent(EVENTS.TO_ACCOUNT.RECEIPT_VIEWED)
    }
  }, []);

  const handleDoneClick = () => {
    if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
      trackEvent(EVENTS.TO_ACCOUNT.DONE_SELECTED);
    }
    window.location.href = ROUTES.EXTERNAL.DASHBOARD
  }

  const handleAnotherTransferClick = () => {
    if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
      trackEvent(EVENTS.TO_ACCOUNT.ANOTHER_TRANSFER_SELECTED);
    }
    window.location.href = redirectUrl
  }

  return (
    <div className="receipt">
      <h1 className="text-center">Receipt</h1>

      {/* Top success and download section */}
      <div className="flex-row justify-content-between align-items-center">
        <div className="flex-row">
          <Icon icon={ICONS.GREEN_CHECK} />
          <h2>Success!</h2>
        </div>

        <ActionLink clickFunc={handleGetReceipt} classes="bold">
          <Icon icon={ICONS.DOWNLOAD} />
          {downloadReceiptText}
        </ActionLink>
      </div>

      <hr />
      {/* Provider logo and subtitles */}
      <div className="spacing-bottom-small center">
        <figure className="provider-logo spacing-bottom-tiny">
          <img
            src={logo}
            alt={providerName}
          />
        </figure>

        {displayFields.map((field: DisplaySectionFieldType) => {
          let className: string;

          switch (field.id) {
            case MONEY_TRANSFER_FIELD_IDS.RECEIPT_TITLE:
            case MONEY_TRANSFER_FIELD_IDS.TRACKING_NUMBER:
              className = "bold";
              break;
            case MONEY_TRANSFER_FIELD_IDS.RECEIPT_SUBTITLE:
            case MONEY_TRANSFER_FIELD_IDS.TRANSACTION_DATE:
            case MONEY_TRANSFER_FIELD_IDS.TRANSACTION_TIME:
              className = "paragraph-small";
              break;
            case MONEY_TRANSFER_FIELD_IDS.RECEIPT_INFO_BOX:
              className = "box-border";
              break;
          }

          if (field.id === MONEY_TRANSFER_FIELD_IDS.PROVIDER_ADDRESS) {
            return <div className="spacing-bottom-small marengo-grey" dangerouslySetInnerHTML={{ __html: field.formattedValue }} />
          } else {
            return <p className={`marengo-grey ${className}`} dangerouslySetInnerHTML={{ __html: field.formattedValue }} />
          }
        })}
      </div>

      <DisplaySection sections={displaySections} className={"full-width"} />

      <div className="marengo-grey paragraph-small" dangerouslySetInnerHTML={{ __html: footer.content }} />

      <FooterLinks
        disclaimerLinks={footer.disclaimerLinks}
        flexDirection="column"
        linkColor="blue"
        pageName="Receipt"
      />

      <div className="receipt-footer">
        <div className="container">
          <Action
            title={COMMON.DONE}
            onClick={handleDoneClick}
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            autoFocus={true}
          />
          <Action
            title={PAGES.MONEY_TRANSFER.SUCCESS.ANOTHER}
            onClick={handleAnotherTransferClick}
            displayType={ACTION_DISPLAY_TYPES.TERTIARY}
          />
        </div>
      </div>

    </div>
  );
}

export default Receipt;