import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/config';
import { Props } from '../../types/props';
import OnDemandTransferApi from '../../api/onDemandTransfer';
import { DisplaySectionType } from '../../types/api/moneyTransfer';
import { ICONS } from '../../constants/enums';

import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import Alert from '../../components/Common/Alerts/Alert';
import ActionLink from '../../components/Common/ActionLink';
import DisplaySection from '../../components/MoneyTransfer/DisplaySection';
import PrintPage from '../../components/ODT/PrintPage';
import AlertModal from '../../components/Common/Modals/AlertModal';
import CancelODTModal from '../../components/ODT/CancelODTModal';

const CancelTransfer = (props: Props) => {
  const [transferId, setTransferId] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [transferDate, setTransferDate] = useState<Date>();
  const [displaySections, setDisplaySections] = useState<DisplaySectionType[]>([]);
  const [beneficiaryName, setBeneficiaryName] = useState<string>("");
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const transferId = props?.match?.params?.transferId;
    setTransferId(transferId);

    const getTransferDetails = async () => {
      try {
        const response = await OnDemandTransferApi.GetTransferDetails(transferId);
        setDisplaySections(response.displaySections);
        setTransferDate(new Date(response.transferDate));
        const beneficiarySection = response.displaySections.find((section: DisplaySectionType) => section.id === "BeneficiaryDetails") as DisplaySectionType;
        setBeneficiaryName(() => beneficiarySection.fields.find(field => field.label === "Name")?.formattedValue);
        setLoading(false);
      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }
        setLoading(false);
        setShowErrorModal(true);
      }
    }

    getTransferDetails();

  }, []);

  return (
    <FlowScreen
      flowTitle={i18n.t("PAGES.ODT.CANCEL_TRANSFER.TITLE")}
      className="position-relative display-section-wrapper"
    >

      {!loading && transferDate &&
        <>
          <p>{i18n.t("COMMON.DATE").toUpperCase()}: {transferDate?.toLocaleString(window.navigator.language)} </p>

          <PrintPage />
        </>
      }

      <PageBlock>
        <PageBlock.Loader loadingContent={loading}>
          <InputSkeletonGroup numInputs={4} />
        </PageBlock.Loader>

        <PageBlock.Body loadingContent={loading}>
          <Alert
            alertClasses={"alert-info alert-bkgrd-white spacing-top-small flex-row font-regular"}
            icon={ICONS.INFO}
            iconClasses={"spacing-right-tiny"}
          >
            <p className="bold">{i18n.t("COMMON.CANCEL_TRANSACTION")}</p>
            <p>{i18n.t("PAGES.ODT.CANCEL_TRANSFER.ALERT_TEXT")}</p>
            <ActionLink
              text={i18n.t("COMMON.CANCEL_TRANSACTION")}
              clickFunc={() => setShowCancelModal(true)}
            />
          </Alert>

          <p className="marengo-grey spacing-top-small spacing-bottom-small bold">
            {i18n.t("COMMON.DATE")}: {transferDate?.toLocaleDateString(window.navigator.language, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>

          <DisplaySection sections={displaySections} className="spacing-bottom-small card-header__transparent card-header__padding" />

          <CancelODTModal
            showCancelModal={showCancelModal}
            setShowCancelModal={setShowCancelModal}
            transferId={transferId}
            beneficiaryName={beneficiaryName}
          />
        </PageBlock.Body>
      </PageBlock>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />

    </FlowScreen>
  );
}

export default CancelTransfer;