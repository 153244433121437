import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import App from "./App";
import ErrorLogger from "../ErrorLogger";

/**
 * Root component to set up app level state, routing, etc
 */
class Root extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        {/* NOTE: Any connected component that wraps ErrorLogger from here needs to wrap itself with `withRouter` */}
        <ErrorLogger>
          <App store={store} history={history} />
        </ErrorLogger>
      </Provider>
    );
  }
}

Root.propTypes = {
  /** The state tree */
  store: PropTypes.object.isRequired,
  /** react-router history */
  history: PropTypes.object.isRequired,
};

export default Root;
