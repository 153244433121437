import React from "react";
import EmailEntry from "./EmailEntry";
import { PAGES, COMMON } from "../../constants/localization";
import { ICON_SVG } from "../../constants/iconsvg";
import { eventTracker, EVENTS } from "../../services/eventTracker";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import { ROUTES } from "../../constants/clientRoutes";
import FlowScreen from "../../components/Layouts/FlowScreen";
import Action from "../../components/Common/Action";
import { REGEX } from "../../constants/regex";
import FlowSuccess from "../../components/Common/Success/FlowSuccess";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import UserApi from "../../api/user";
import ContactSupportModal from "../../components/Common/Modals/ContactSupportModal";

export default class ForgotUsernameContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validEmail: false,
      emailAddress: "",
      hasError: false,
      errorMessage: "",
      loading: false,
      showForgotEmailModal: false,
      showSuccessScreen: false,
      showGenericErrorModal: false,
    };
  }

  /**
   * Updates the emailAddress state with the
   * input typed in the email address input element
   */
  updateEmailAddress = (e) => {
    let inputText = e.target.value;
    let validEmail = inputText.length > 0;
    this.setState({
      emailAddress: e.target.value,
      validEmail: validEmail,
      hasError: false,
      errorMessage: "",
    });
  };

  /**
   * Checks if form container email address input
   * is valid. Also acts as a backup for browsers that don't
   * support input types og
   */
  checkFormValidity = (formId) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!document.getElementById(formId).checkValidity()) {
          this.setState({
            hasError: true,
            errorMessage: PAGES.FORGOT_USERNAME.EMAIL_ENTRY_ERROR,
            loading: false,
          });
        }
      }
    );
  };

  /**
   * Ensures email address conforms to an email address
   * pattern using regex. If true submits event to mixpanel and submits
   * email address to API
   */
  regexCheckEmailAddress = (e) => {
    e.preventDefault();
    /**
     * Email address regular expression
     */
    let regex = REGEX.EMAIL;
    if (regex.test(this.state.emailAddress)) {
      this.sendEmailAddress();
    } else {
      this.setState({
        hasError: true,
        errorMessage: PAGES.FORGOT_USERNAME.EMAIL_ENTRY_ERROR,
        loading: false,
      });
    }
  };

  /**
   * Submits email address to API
   */
  async sendEmailAddress() {
    eventTracker.track(EVENTS.ForgotUsername_EmailSubmitted);
    try {
      await UserApi.ForgotUsername(this.state.emailAddress);
      this.setState({
        hasError: false,
        errorMessage: "",
        showSuccessScreen: true,
      });
    } catch (error) {
      this.setState({
        showGenericErrorModal: true,
      });
    }
  }

  /**
   * Shows the modal that gives user options if they
   * have forgotten their email address and sends event to
   * Mixpanel
   */
  showForgotEmailModal = () => {
    eventTracker.track(EVENTS.ForgotUsername_EmailUnknown);
    this.setState({
      showForgotEmailModal: true,
    });
  };

  /**
   * Toggles the display of the modal that gives users options
   * if they have forgotten their email address
   */
  toggleForgotEmailModal = () => {
    let forgotEmailModalOpen = this.state.showForgotEmailModal;
    this.setState({
      showForgotEmailModal: !forgotEmailModalOpen,
    });
  };

  /**
   * Toggles the display of the GenericErrorModal
   */
  toggleGenericErrorModal = () => {
    let genericErrorModalOpen = this.state.showGenericErrorModal;
    this.setState({
      showGenericErrorModal: !genericErrorModalOpen,
    });
  };

  render() {
    return (
      <FlowScreen
        flowTitle={PAGES.FORGOT_USERNAME.MASTER_CONTAINER_TITLE}
        className="forgot-username"
      >
        {!this.state.showSuccessScreen && (
          <EmailEntry
            validEmail={this.state.validEmail}
            emailAddress={this.state.emailAddress}
            updateEmailAddress={this.updateEmailAddress}
            submitEmailAddress={this.regexCheckEmailAddress}
            checkFormValidity={this.checkFormValidity}
            hasError={this.state.hasError}
            errorMessage={this.state.errorMessage}
            loading={this.state.loading}
            showForgotEmailModal={this.showForgotEmailModal}
          />
        )}
        {this.state.showSuccessScreen && (
          <FlowSuccess id="ForgotUsernameSuccess">
            <FlowSuccess.Image>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${ICON_SVG.EMAIL_SUCCESS}`,
                }}
              ></div>
            </FlowSuccess.Image>
            <FlowSuccess.Title>
              {PAGES.FORGOT_USERNAME.SUCCESS_SCREEN.TITLE}
            </FlowSuccess.Title>
            <FlowSuccess.Body>
              {PAGES.FORGOT_USERNAME.SUCCESS_SCREEN.TEXT}
            </FlowSuccess.Body>
            <FlowSuccess.ActionRow>
              <Action
                title={COMMON.BACK_TO_LOGIN}
                onClick={() => (location.href = ROUTES.EXTERNAL.LOGIN)}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              />
            </FlowSuccess.ActionRow>
          </FlowSuccess>
        )}
        <ContactSupportModal
          open={this.state.showForgotEmailModal}
          title={PAGES.FORGOT_USERNAME.FORGOT_EMAIL_MODAL.TITLE}
          content={PAGES.FORGOT_USERNAME.FORGOT_EMAIL_MODAL.TEXT}
          onClose={this.toggleForgotEmailModal}
          hasSupport={false}
        />
        <GenericErrorModal
          open={this.state.showGenericErrorModal}
          onClose={this.toggleGenericErrorModal}
        />
      </FlowScreen>
    );
  }
}
