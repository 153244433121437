import ENV from "../constants/environment";
import ReactGA from 'react-ga4';

// https://medium.com/@andrewoons/setting-up-mixpanel-in-react-3e4c5b8c2a36

export const initEventTracker = () => {
  ReactGA.initialize(ENV.GA_MEASUREMENT_ID);
}

export const trackPageViews = (location) => {
  ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
}

export const trackEvent = (eventName, params) => {
  ReactGA.event(eventName, params);
}

let EVENTS = {
  MoneyTransfer_GetRates: "MoneyTransfer_GetRates",
  MoneyTransfer_PromoCode: "MoneyTransfer_PromoCode",
  MoneyTransfer_PromoCodeSubmitted: "MoneyTransfer_PromoCodeSubmitted",
  MoneyTransfer_ProviderSelected: "MoneyTransfer_ProviderSelected",
  MoneyTransfer_TelemarketerQuestion: "MoneyTransfer_TelemarketerQuestion",
  MoneyTransfer_SenderInfoSubmitted: "MoneyTransfer_SenderInfoSubmitted",
  MoneyTransfer_PayoutNetworkInfoSubmitted:
    "MoneyTransfer_PayoutNetworkInfoSubmitted",
  MoneyTransfer_ReceiverInfoSubmitted: "MoneyTransfer_ReceiverInfoSubmitted",
  MoneyTransfer_FraudWarningViewed: "MoneyTransfer_FraudWarningViewed",
  MoneyTransfer_TermsViewed: "MoneyTransfer_TermsViewed",
  MoneyTransfer_Send: "MoneyTransfer_Send",
  Profile_Edit: "Profile_Edit",
  PINCheck_Close: "PinCheck_Close",
  PINCheck_OK: "PINCheck_OK",
  MoneyTransfer_Sent: "MoneyTransfer_Sent",
  MoneyTransfer_PickupInstructions: "MoneyTransfer_PickupInstructions",
  MoneyTransfer_AnotherTransfer: "MoneyTransfer_AnotherTransfer",
  MoneyTransfer_DownloadReceipt: "MoneyTransfer_DownloadReceipt",
  ChangePIN_CurrentPINSubmitted: "ChangePIN_CurrentPINSubmitted",
  ChangePIN_ForgotPIN: "ChangePIN_ForgotPIN",
  ChangePIN_PINToolTip: "ChangePIN_PINToolTip",
  ChangePIN_NewPINSubmitted: "ChangePIN_NewPINSubmitted",
  ChangePIN_Done: "ChangePIN_Done",
  ChangePIN_IDVerificationSubmitted: "ChangePIN_IDVerificationSubmitted",
  ChangePIN_CardExpDateToolTip: "ChangePIN_CardExpDateToolTip",
  ChangePIN_CardLastSixToolTip: "ChangePIN_CardLastSixToolTip",
  ForgotUsername_EmailUnknown: "ForgotUsername_EmailUnknown",
  ForgotUsername_EmailSubmitted: "ForgotUsername_EmailSubmitted",
  ForgotPassword_ResetMethod_EmailSubmitted:
    "ForgotPassword_ResetMethod_EmailSubmitted",
  ForgotPassword_ResetMethod_PersonalInfoSubmitted:
    "ForgotPassword_ResetMethod_PersonalInfoSubmitted",
  ForgotPassword_NewPasswordSubmitted: "ForgotPassword_NewPasswordSubmitted",
  ChangePassword_PasswordChanged: "ChangePassword_PasswordChanged",
  CardCurrencyConverter_Calculate: "CardCurrencyConverter_Calculate",
  UpdateSecurityQuestions_AnswersSubmitted:
    "UpdateSecurityQuestions_AnswersSubmitted",
  SOURCE: {
    MONEY_TRANSFER: "Money Transfer",
    MONEY_TRANSFER_TYPE: {
      CASH_PICKUP: "Cash Pickup",
    },
  },
};

let eventTracker = {
  track: () => {
    // TODO 
    // Remove all references to eventTracker.track function in code
  },
};

export { eventTracker, EVENTS };
