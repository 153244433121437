import initialState from "./initialState";

export default function initialApiCallErrors(state = initialState.initialApiCallErrors, action) {
  switch (action.type) {
    case "ADD_INITIAL_API_ERRORS":
      return action.error;
    case "CLEAR_INITIAL_API_ERRORS":
      return null;
    default:
      return state;
  }
}