import React from "react";
import { CompanionCardRecipientType } from "../../../types/api/card";

type RecipientCardProps = {
  data: CompanionCardRecipientType;
  setShowAreYouSureModal: React.Dispatch<boolean>;
  setRecipientToDelete: React.Dispatch<CompanionCardRecipientType>;
}

const RecipientCard = ({ data, setShowAreYouSureModal, setRecipientToDelete }: RecipientCardProps) => {
  const { recipientId, name, lastSix } = data;

  const handleClick = () => {
    setShowAreYouSureModal(true);
    setRecipientToDelete(data);
  }

  return (
    <div className="recipient-card" key={recipientId}>
      <div>
        <p className="abyss-blue bold">{name}</p>
        <p className="spacing-bottom-none marengo-grey">Card ending {lastSix}</p>
      </div>

      <div>
        <button className="no-styling shark-grey bold" onClick={handleClick}>Delete</button>
      </div>
    </div>
  );
}

export default RecipientCard;