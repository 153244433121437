import { ROUTES } from "../constants/api";
import axios from "axios";
import AuthApi from "./auth";
import {
  HandleErrorResponse,
  HandleResponse,
  HandleNewErrorResponse,
  HandleNewResponse,
} from "./handlers";

/**
 * Class for static methods handling a user's card(s)
 */
class CatalogApi {
  static async GetPasswordQuestions() {
    try {
      let response = await axios.get(ROUTES.CATALOG.GET_PASSWORD_QUESTIONS);

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get all supported countries
   * country data
   */
  static async GetCountries() {
    let url = ROUTES.CATALOG.COUNTRIES;

    try {
      let response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
      });
      return HandleResponse(response);
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }

  /**
   * Get all supported stateProvinces by countryIso (ISO 2 Code)
   *  stateProvince data
   */
  static async GetStateProvinces(countryIso) {
    let url = ROUTES.CATALOG.STATE_PROVINCES(countryIso);
    try {
      let response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
      });
      return HandleResponse(response);
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }
}

export default CatalogApi;
