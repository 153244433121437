import moment from "moment";
import { COMMON, PAGES } from "../constants/localization";

export const validateDate = (value) => {
  if (!value) return COMMON.VALIDATION_ERRORS.IS_REQUIRED;

  if (value === null) {
    return COMMON.VALIDATION_ERRORS.CARD.MONTH_YEAR_REQUIRED;
  } else if (!moment(value).isValid()) {
    return COMMON.VALIDATION_ERRORS.CARD.YEAR_REQUIRED;
  } else {
    return undefined;
  }
};

export const validateCardNumber = (value) => {
  if (!value) return COMMON.VALIDATION_ERRORS.IS_REQUIRED;

  if (value.length > 16 || value.length < 16) {
    return COMMON.VALIDATION_ERRORS.CARD.LENGTH;
  }

  // The Luhn Algorithm.
  var nCheck = 0,
    nDigit = 0,
    bEven = false;
  let cardNumber = value.replace(/\D/g, "");

  for (let n = cardNumber.length - 1; n >= 0; n--) {
    var cDigit = cardNumber.charAt(n),
      nDigit = parseInt(cDigit, 10); // eslint-disable-line no-redeclare

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9;
    }

    nCheck += nDigit;
    bEven = !bEven;
  }

  if (nCheck % 10 === 0) {
    return undefined;
  } else {
    return COMMON.VALIDATION_ERRORS.CARD.NOT_VALID;
  }
};

export const validateConfirmCardNumber = (value, values) => {
  if (!value) return COMMON.VALIDATION_ERRORS.IS_REQUIRED;

  if (value.length > 16 || value.length < 16) {
    return COMMON.VALIDATION_ERRORS.CARD.LENGTH;
  } else if (value.length === 16 && value !== values.CardNumber) {
    return COMMON.VALIDATION_ERRORS.CARD.CARDS_DO_NOT_MATCH;
  } else {
    return undefined;
  }
};

export const validatePinNumber = (value) => {
  if (!value) return COMMON.VALIDATION_ERRORS.IS_REQUIRED;

  if (value.length !== 4) {
    return COMMON.VALIDATION_ERRORS.CARD.PIN_LENGTH;
  } else {
    return undefined;
  }
};

export const validateConfirmPinNumber = (value, values) => {
  if (!value) return COMMON.VALIDATION_ERRORS.IS_REQUIRED;

  if (value.length !== 4) {
    return COMMON.VALIDATION_ERRORS.CARD.PIN_LENGTH;
  } else if (value !== values.PinNumber) {
    return COMMON.VALIDATION_ERRORS.CARD.PINS_DO_NOT_MATCH;
  } else {
    return undefined;
  }
};

export const validateEmail = (value) => {
  return !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;
}

/**
required accepts a value and ensures that it's not undefined.
returns "Required" if value is not set
*/
export const required = (value) => (value ? undefined : COMMON.REQUIRED);

/**
 * Determines if the given value exceeds the available balance of the user. Card balance comes from application state.
 * @param {any} value The send amount of the transaction.
 */
const balanceCheck = (value, cardBalance, sendCurrencyIso) => {
  return value > cardBalance
    ? PAGES.MONEY_TRANSFER.VALIDATIONS.EXCEEDED_BALANCE(
      cardBalance,
      sendCurrencyIso
    )
    : undefined;
};

/**
 * Determines if the given value exceeds the maximum send limit for money transfers.
 * @param {any} value The send amount of the transaction.
 */
const limitCheck = (value, sendLimit, sendCurrencyIso) => {
  return value > sendLimit
    ? PAGES.MONEY_TRANSFER.VALIDATIONS.EXCEEDED_SEND_LIMIT(
      sendLimit,
      sendCurrencyIso
    )
    : undefined;
};


/**
 * Used for validating the receive currency selected.
 * @param {any} val The selected three digit currency code.
 */
export const currencyCheck = (val) => {
  return val && val != "-1" ? undefined : COMMON.REQUIRED;
};

export const sendAmountValidation = (value, allValues, props) => {
  const { sendLimit, sendCurrencyIso, cardBalance } = props;
  return (
    balanceCheck(value, cardBalance, sendCurrencyIso) ||
    limitCheck(value, sendLimit, sendCurrencyIso)
  );
};