import AuthApi from "../api/auth";

class AuthService {
  constructor() {
    this.isAuthenticated = false;
  }

  static IsAuthenticated() {
    return this.isAuthenticated;
  }

  static Authenticate() {
    let token = AuthApi.IsAuthenticated();
    this.isAuthenticated = !!token;
    return token;
  }
}

export default AuthService;
