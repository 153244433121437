import AuthApi, { authTokenFromResponse } from "../api/auth";
import AuthService from "../services/auth";
import * as types from "../constants/actionTypes";
import { DIRECTIVE_TYPE, AUTH_INFO_TYPE } from "../constants/enums";
import { ROUTES } from "../constants/clientRoutes";
import { AUTH } from "../constants/api";
import SupportApi from "../api/support";
import { checkBrowserSupport } from "./global";
import _ from "lodash";
import { UpdateUserCards } from "./card";
import { GetFeatureFlags, UpdateFeatureFlags, UpdateUserProfile } from "./user";
import ENV from "../constants/environment";
import { FEATURE_FLAGS } from "../constants/featureFlags";

/**
 * Action type for receiving the token
 * @param token
 * @returns {{type: string, token: *}}
 * @constructor
 */
export function AuthorizeTokenSuccess(token) {
  return {
    type: types.RECEIVE_TOKEN,
    token,
  };
}

/**
 * Action type for receiving the login token
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export function UpdateUsername(payload) {
  return {
    type: types.AUTH.UPDATE_USERNAME,
    payload,
  };
}

export function CleanLoginToken() {
  return {
    type: types.AUTH.CLEAN_LOGIN_TOKEN,
  };
}

/**
 * Action type for receiving the login token with password
 * @param payload
 * @returns {{type: string, payload: *}}
 * @constructor
 */
export function UpdateAuthResult(payload) {
  return {
    type: types.AUTH.RECEIVE_AUTH_RESULT,
    payload,
  };
}

export function UpdateAuthDirective(payload) {
  return {
    type: types.AUTH.UPDATE_AUTH_DIRECTIVE,
    payload,
  };
}

export function UpdateFacecheckEnrollmentInfo(payload) {
  return {
    type: types.AUTH.UPDATE_FACECHECK_ENROLLMENT_INFO,
    payload,
  };
}

export function UpdateFaceCheckSessionAutoStart(payload) {
  return {
    type: types.AUTH.UPDATE_FACECHECK_AUTOSTART,
    payload,
  };
}

export function UpdateNeedsToAcceptDocs(payload) {
  return {
    type: types.AUTH.UPDATE_ACCEPT_DOCS,
    payload,
  };
}

export function UpdateDashboardInAppMessage(payload) {
  return {
    type: types.AUTH.UPDATE_DASHBOARD_INAPP_MESSAGE,
    payload,
  };
}

function updateAuthTimeout(payload) {
  return {
    type: types.AUTH.TIMEOUT_ID,
    payload,
  };
}

function clearAuthTimeout() {
  return async function (dispatch, getState) {
    const {
      auth: { timeoutId },
    } = getState();
    if (timeoutId) {
      clearTimeout(timeoutId);
      dispatch(updateAuthTimeout(0));
    }
  };
}

/**
 * Authorizes a user for login flow and saves it in state
 * @param username
 * @returns {Function}
 * @constructor
 */
export function AuthorizeLoginToken(username) {
  return async function (dispatch) {
    // clear timeout if already set
    await dispatch(clearAuthTimeout());

    return AuthApi.authorizeLoginToken(username).then(async (tokenResponse) => {
      // save facecheck data
      if (tokenResponse.authResult.type === AUTH_INFO_TYPE.FaceCheck) {
        await dispatch(
          UpdateFacecheckEnrollmentInfo({
            deviceKey: tokenResponse.authResult.deviceKey,
            livenessSessionToken: tokenResponse.authResult.livenessSessionToken,
            licenseKey: tokenResponse.authResult.licenseKey,
          })
        );
        await dispatch(UpdateFaceCheckSessionAutoStart(true));
      }

      await dispatch(UpdateUsername(username));
      await dispatch(UpdateAuthResult(tokenResponse.authResult));

      // 13060 - set timeout to clear auth info on expire
      await dispatch(
        updateAuthTimeout(
          setTimeout(async () => {
            await dispatch(clearAuthTimeout());
            await dispatch(CleanLoginToken());
          }, AUTH.LOGIN_TIMEOUT_MILLISECONDS)
        )
      );

      return tokenResponse;
    });
  };
}

export function goToNextAuthStep(history) {
  return async function (dispatch, getState) {
    const {
      global: { returnUrl },
      auth: {
        authResult: {
          type,
          needsToAcceptDocs,
          message,
          directive: { directiveType },
        },
      },
      user: {
        featureFlags
      }
    } = getState();
    const returnUrlPath =
      returnUrl &&
        returnUrl.length > 2 &&
        returnUrl.startsWith(ENV.BASE_NAVIGATOR_URL)
        ? `${ENV.BASE_NAVIGATOR_URL}${returnUrl}`
        : returnUrl;
    switch (type) {
      case AUTH_INFO_TYPE.Password:
        history.push(ROUTES.AUTH.PASSWORD_LOGIN);
        break;
      case AUTH_INFO_TYPE.SecurityQuestion:
        history.push(ROUTES.AUTH.SECURITY_QUESTION);
        break;
      case AUTH_INFO_TYPE.Totp:
        history.push(ROUTES.AUTH.TOTP)
        break;
      case AUTH_INFO_TYPE.FaceCheck:
        await dispatch(checkBrowserSupport());
        history.push(ROUTES.AUTH.LOGIN_FACECHECK);
        break;
      case AUTH_INFO_TYPE.Authenticated:
        switch (directiveType) {
          case DIRECTIVE_TYPE.FaceCheckEnrollment:
            await dispatch(checkBrowserSupport());
            history.push(ROUTES.AUTH.INTRO_FACECHECK);
            break;
          case DIRECTIVE_TYPE.Dashboard:
            //Sending to accept disclosures takes priority
            await dispatch(GetFeatureFlags());
            if (needsToAcceptDocs) {
              location.href = ROUTES.EXTERNAL.REVIEW_DOCS;
            } else if (message?.type != null) {
              await dispatch(handleShowInAppMessageModal());
            } else if (featureFlags.includes(FEATURE_FLAGS.COMPANION_PARTICIPANT)) {
              location.href = ROUTES.EXTERNAL.MY_CARD;
            } else {
              location.href = returnUrlPath || ROUTES.EXTERNAL.DASHBOARD;
            }
            break;
          default:
            location.href = returnUrlPath || ROUTES.EXTERNAL.DASHBOARD;
        }
        break;
    }
  };
}

function checkAuthToken(tokenResponse) {
  return async function (dispatch) {
    let sessionInfo = null;
    const type = _.get(tokenResponse, "authResult.type", false);
    if (type === AUTH_INFO_TYPE.Authenticated) {
      //logged in
      // clear auth flow timeout
      await dispatch(clearAuthTimeout());

      if (
        tokenResponse.authResult.cards &&
        tokenResponse.authResult.cards.length > 0
      ) {
        await dispatch(UpdateUserCards(tokenResponse.authResult.cards));
      }

      if (tokenResponse.authResult.featureFlags) {
        await dispatch(
          UpdateFeatureFlags(tokenResponse.authResult.featureFlags)
        );
      }

      if (tokenResponse.authResult.userProfile) {
        await dispatch(UpdateUserProfile(tokenResponse.authResult.userProfile));
      }

      if (tokenResponse.authResult.message) {
        await dispatch(
          UpdateDashboardInAppMessage({
            message: tokenResponse.authResult.message,
            showMessage: false,
          })
        );
      }

      if (tokenResponse.authResult.needsToAcceptDocs) {
        await dispatch(
          UpdateNeedsToAcceptDocs(tokenResponse.authResult.needsToAcceptDocs)
        );
      }

      if (tokenResponse.authResult.directive) {
        await dispatch(UpdateAuthDirective(tokenResponse.authResult.directive));

        if (
          tokenResponse.authResult.directive.directiveType ===
          DIRECTIVE_TYPE.FaceCheckEnrollment
        ) {
          await dispatch(UpdateFaceCheckSessionAutoStart(true));
        }
      }

      if (tokenResponse.authResult.sessionInfo) {
        sessionInfo = tokenResponse.authResult.sessionInfo;
      }
    }

    if (!sessionInfo) {
      sessionInfo = _.get(tokenResponse, "sessionInfo", false);
    }

    if (sessionInfo) {
      const newToken = authTokenFromResponse(sessionInfo);
      AuthApi.saveAuthToken(newToken);
      return await dispatch(AuthorizeTokenSuccess(newToken));
    }

    return false;
  };
}

export function handleFaceCheckSuccess(tokenResponse) {
  return async function (dispatch) {
    await dispatch(UpdateFaceCheckSessionAutoStart(false));

    if (tokenResponse.authResult) {
      //more steps required
      await dispatch(UpdateAuthResult(tokenResponse.authResult));
      await dispatch(checkAuthToken(tokenResponse));
    } else {
      let sessionInfo = _.get(tokenResponse, "sessionInfo", false);
      if (sessionInfo) {
        const newToken = authTokenFromResponse(sessionInfo);
        AuthApi.saveAuthToken(newToken);
        await dispatch(AuthorizeTokenSuccess(newToken));
      }
    }
    return tokenResponse;
  };
}

export function disableFaceCheckSessionAutoStart() {
  return async function (dispatch) {
    await dispatch(UpdateFaceCheckSessionAutoStart(false));
  };
}

/**
 * Authorizes a user using password authType
 * @param password
 * @returns {Function}
 * @constructor
 */
export function AuthorizeLoginPassword(password) {
  return async function (dispatch, getState) {
    const {
      auth: {
        authResult: { token },
      },
    } = getState();
    return AuthApi.authorizeLoginPassword(password, token).then(
      async (tokenResponse) => {
        await dispatch(checkAuthToken(tokenResponse));

        //more steps required
        await dispatch(UpdateAuthResult(tokenResponse.authResult));
        return tokenResponse;
      }
    );
  };
}

export function AuthorizeSecurityQuestion(answer) {
  return async function (dispatch, getState) {
    const {
      auth: {
        authResult: { token, questionId },
      },
    } = getState();
    return AuthApi.authorizeSecurityQuestion(answer, questionId, token).then(
      async (tokenResponse) => {
        await dispatch(checkAuthToken(tokenResponse));

        //more steps required
        await dispatch(UpdateAuthResult(tokenResponse.authResult));

        return tokenResponse;
      }
    );
  };
}

/**
 * Authorizes a user using totp authType
 * @param passcode
 * @constructor
 */
export function AuthorizeLoginTotp(passcode) {
  return async function (dispatch, getState) {
    const {
      auth: {
        authResult: { token },
      },
    } = getState();

    // eslint-disable-next-line no-useless-catch
    try {
      const response = await AuthApi.authorizeLoginTotp(passcode, token);

      await dispatch(checkAuthToken(response));
      await dispatch(UpdateAuthResult(response.authResult));
      return response;
    } catch (err) {
      throw err;
    }
  };
}

export function getFacecheckEnrollmentToken() {
  return async function (dispatch) {
    return AuthApi.getFacecheckEnrollmentToken().then(async (data) => {
      // exclude errors prop from response
      await dispatch(UpdateFacecheckEnrollmentInfo(data.directive));
    });
  };
}

/**
 * Refreshes the auth token
 * @returns {Function}
 */
export function RefreshToken() {
  return async function (dispatch) {
    try {
      let token = await AuthApi.refreshAuthToken();
      AuthApi.navigatorKeepAlive();

      await dispatch(AuthorizeTokenSuccess(token));
    } catch (e) {
      // TODO: Handle auth errors
    }
  };
}

/**
 * Logs the user out.
 * @returns {Function}
 * @constructor
 */
export function Logout() {
  return async (dispatch) => {
    AuthApi.Logout();
    dispatch({
      type: types.AUTH.LOGOUT,
    });
  };
}

/**
 * Authenticates and handles token reception success/failure
 * @returns {Function}
 * @constructor
 */
export function Authenticate() {
  return async function (dispatch) {
    let token = await AuthService.Authenticate();
    if (token) {
      await dispatch(AuthorizeTokenSuccess(token));
    } else {
      await dispatch({
        type: types.RECEIVE_TOKEN_FAILURE,
      });
    }
  };
}

export function setAuthSupportInfo(payload) {
  return {
    type: types.AUTH.UPDATE_SUPPORT,
    payload,
  };
}

export function OpenAuthSupportTicket(
  category,
  email = null,
  description = null
) {
  return async function (dispatch, getState) {
    const {
      auth: {
        authResult: { token, type },
      },
    } = getState();
    const authToken =
      token && type !== AUTH_INFO_TYPE.Authenticated ? token : null;
    return SupportApi.PostAuthenticatedSupportTicket(
      category,
      authToken,
      email,
      description
    ).then((data) => {
      return data;
    });
  };
}

/**
 * @param {bool} show - Set true or false
 */
export function handleShowInAppMessageModal(route = null) {
  return async function (dispatch, getState) {
    const {
      auth: {
        authResult: { showMessage },
      },
    } = getState();
    await dispatch(
      UpdateDashboardInAppMessage({
        showMessage: !showMessage,
      })
    );

    if (route !== null) {
      location.href = route;
    }
  };
}
