// maps to EnrollmentPages in Constants.cs
module.exports = Object.freeze({
  LOGIN: 0,
  CREATE_USER: 1,
  ACCOUNT_SECURITY: 2,
  PERSONAL_DATA: 3,
  CARD_DETAILS: 4,
  BANK_ACCOUNT_DETAILS: 5,
  COMMUNITCAION_PREFERENCES: 6,
  COMPLETE: 7,
});
