import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { ROUTES } from "../../constants/clientRoutes";
import bigChevron from "../../assets/images/icons/big-chevron-grey.svg";
import MessageIcon from "./MessageIcon";

/**
 * Builds the list of conversations
 *
 * @param conversations
 * @param history
 * @returns {jsx}
 * @constructor
 */
export const ConversationList = ({ conversations, history }) => {
  const list = conversations.map((conversation) => {
    const status = conversation.status.name;
    const ticketId = conversation.conversationId;
    const itemClass =
      "conversation-item flex-row align-items-center justify-content-between " +
      status.toLowerCase();
    const isClosed = status.toLowerCase() === "closed";
    const latestMessage =
      conversation.messages[conversation.messages.length - 1];
    const firstMessage = conversation.messages[0];

    return (
      <li
        key={conversation.conversationId}
        className={itemClass}
        onClick={() =>
          history.push(
            `${ROUTES.SUPPORT_CONVERSATIONS}/${conversation.conversationId}`
          )
        }
      >
        <div className="conversation-item__info flex-row align-items-center">
          <MessageIcon
            categoryId={conversation.categoryId}
            isClosed={isClosed}
          />

          <div className="conversation-data ">

            {/* Status with dot */}
            <div className="conversation-data__status flex-row align-items-center spacing-bottom-tiny">
              <div className={`dot ${isClosed ? 'closed' : 'open'}`}></div>
              <p>{isClosed ? 'Closed' : 'Open'}</p>
            </div>

            <p className="abyss-blue">{firstMessage.text}</p>

            <p className="ticket marengo-grey">Ticket # {ticketId}</p>
          </div>
        </div>

        <div className="conversation-item__date flex-row">
          {format(latestMessage.timestamp, "MMM Do")}
          <img src={bigChevron} className="spacing-left-tiny" />
        </div>
      </li>
    );
  });

  return <ul className="conversation-list">{list}</ul>;
};

ConversationList.propTypes = {
  /** Support conversations */
  conversations: PropTypes.array.isRequired,
  /** react-router history */
  history: PropTypes.any.isRequired,
};

export default ConversationList;
