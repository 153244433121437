import React from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/clientRoutes";
import useWindowDimensions from "../../services/useWindowDimensions";
import { useSelector } from "react-redux";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import ENV from "../../constants/environment";
import i18n from "../../i18n/config";

/**
 * The site footer
 *
 * @param links
 * @param location
 * @returns {jsx}
 * @constructor
 */
export const Footer = ({ links, issuerStatement, location }) => {
  const openExternalLinksModal = useClickExternalLink();
  const cardIssuerStatement = useSelector(state => state.card.issuerStatement);
  const { width } = useWindowDimensions();
  if (
    location?.key &&
    location?.pathname.indexOf(ROUTES.SUPPORT_CONVERSATIONS) > -1
  ) {
    if (width < 768) {
      return null;
    }
  }

  if (location?.pathname.indexOf(ROUTES.PRIVACY) > -1) {
    return null;
  }

  const year = new Date().getFullYear();

  const docs = links.map((link, index) => {
    const url = `${ENV.BASE_NAVIGATOR_URL}/Administration/GetFile/${link.documentId}`;

    return (
      <li key={link.documentId}>
        <a href={url} target="_blank" rel="noopener noreferrer" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} onClick={(e) => openExternalLinksModal(e, url)}>
          {link.name}
        </a>
        {index !== links.length - 1 &&
          <span className="padding-left-xs padding-right-xs"> | </span>
        }
      </li>
    )
  });

  return (
    <footer>
      <div id="footerWrapper" data-testid="footerWrapper">
        {issuerStatement &&
          <div className="row text-center spacing-bottom-tiny" dangerouslySetInnerHTML={{ __html: issuerStatement }}></div>
        }
        <div className="row text-center">
          <p data-testid="brightwell-statement">
            © {year} - Brightwell, Inc. All rights reserved. P.O. Box 724026,
            Atlanta, GA 31139.
          </p>
          {cardIssuerStatement && <p dangerouslySetInnerHTML={{ __html: cardIssuerStatement }}></p>}
        </div>

        <ul className="flex-row justify-content-center unstyled padding-left-none">
          {docs}
        </ul>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  /** Links in key (name) value (id) pairs */
  links: PropTypes.array.isRequired,
  /** Issuer Statement from API, string can be raw html */
  issuerStatement: PropTypes.string,
  /** react-router location object */
  location: PropTypes.object,
};

export default Footer;
