import { Dispatch } from "redux";
import { ON_DEMAND_TRANSFER } from "../constants/actionTypes";
import { SetRateInquiryDataParams, UpdateDestinationInfoParams, UpdateODTTermsDocAndFeeBannerParams } from "../types/actions";
import { GetDoddFrankDisbursementResponse, PostTransferResponse } from "../types/api/odt";

export function UpdateDestinationInfo(payload: UpdateDestinationInfoParams) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ON_DEMAND_TRANSFER.UPDATE_DESTINATION_INFO,
      payload,
    });
  };
}

export function UpdateODTTermsDocAndFeeBanner(payload: UpdateODTTermsDocAndFeeBannerParams) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ON_DEMAND_TRANSFER.UPDATE_ODT_TERMS_AND_CONDTIONS_DOC,
      payload,
    });
  };
}

export function SetRateInquiryData(payload: SetRateInquiryDataParams) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ON_DEMAND_TRANSFER.SET_RATE_INQUIRY_DATA,
      payload,
    });
  };
}

export function UpdateDoddFrankDisbursement(payload: GetDoddFrankDisbursementResponse) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ON_DEMAND_TRANSFER.UPDATE_DODD_FRANK_DISBURSEMENT,
      payload,
    });
  };
}

export function PostTransferSuccess(payload: PostTransferResponse) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: ON_DEMAND_TRANSFER.POST_TRANSFER_SUCCESS,
      payload,
    });
  };
}

