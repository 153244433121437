import { ROUTES } from "../constants/api";
import {
  HandleNewErrorResponse,
  HandleNewResponse,
} from "./handlers";
import { get, post } from "./api";
import { GetDoddFrankDisbursementRequest, PostTransferDoddFrankRequest, PostTransferNonDoddFrankRequest } from "../types/api/odt";

/**
 * Class for static methods handling a user's On Demand Transfers.
 */
class OnDemandTransferApi {
  /**
   * Gets the user's list of bank accounts
   * @returns {Promise<void>} bank account data
   * @constructor
   */
  static async GetBankAccounts() {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_BANK_ACCOUNTS;

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets list of purpose of payment for give country id
   * @returns {Promise<void>} bank account data
   * @constructor
   */
  static async GetPurposeOfPayment(countryId: string) {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_PURPOSE_OF_PAYMENT(countryId);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets the exchange rate data
   * @returns {Promise<void>} rate inquiry data
   * @constructor
   */
  static async GetRateInquiry(cardCurrencyCode: string, destinationCurrencyCode: string, amount: string, allocationId: string) {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_RATE_INQUIRY(cardCurrencyCode, destinationCurrencyCode, amount, allocationId);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets disbursementId and beneficiary/bank details for Dodd Frank flow
   * @returns {Promise<void>} rate inquiry data
   * @constructor
   */
  static async GetDoddFrankDisbursement(data: GetDoddFrankDisbursementRequest) {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_DODD_FRANK_DISBURSEMENT;

    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Posts the on demand transfer for non Dodd Frank
   * @returns {Promise<void>} transfer POST response data
   * @constructor
   */
  static async PostTransferNonDoddFrank(data: PostTransferNonDoddFrankRequest) {
    let url = ROUTES.ON_DEMAND_TRANSFER.POST_TRANSFER_NON_DODD_FRANK;

    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Posts the on demand transfer for Dodd Frank flow
   * @returns {Promise<void>} transfer POST response data
   * @constructor
   */
  static async PostTransferDoddFrank(data: PostTransferDoddFrankRequest) {
    let url = ROUTES.ON_DEMAND_TRANSFER.POST_TRANSFER_DODD_FRANK;

    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets a list of On Demand transfers
   * @returns {Promise<void>} transfer list data
   * @constructor
   */
  static async GetTransfersList() {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_TRANSFERS_LIST;

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets details of ODT
   * @returns {Promise<void>} transfer details data
   * @constructor
   */
  static async GetTransferDetails(transferId: string) {
    let url = ROUTES.ON_DEMAND_TRANSFER.GET_TRANSFER_DETAILS(transferId);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Sends a request to cancel a transfer
   * @returns {Promise<void>} 200 if successful
   * @constructor
   */
  static async CancelTransfer(transferId: string) {
    let url = ROUTES.ON_DEMAND_TRANSFER.CANCEL_TRANSFER(transferId);

    try {
      let response = await post(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }
}

export default OnDemandTransferApi;