import React from 'react';
import PropTypes from 'prop-types';
import { ROUTES } from '../../constants/clientRoutes';
import ActionLink from '../../components/Common/ActionLink';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { trackEvent } from '../../services/eventTracker';
import { EVENTS } from '../../constants/events';
import ENV from '../../constants/environment';
import { useDispatch } from "react-redux";
import { SetDisplayODTModal } from "../../actions/card";
import i18n from '../../i18n/config';

const Balance = ({ card, featureFlags }) => {
  const dispatch = useDispatch();

  const handleClick = async () => {
    trackEvent(EVENTS.ODT.BANK_TRANSFER_STARTED);

    if (card.availability.isODTUnavailable) {
      dispatch(SetDisplayODTModal(true));
    } else {
      location.href = ROUTES.EXTERNAL.ON_DEMAND_TRANSFER;
    }
  }

  return (
    <section className="dashboard-banner row text-center">


      <div className="spacing-bottom-large">
        {card.status !== "Loading" &&
          <p className="card-balance">{card.balanceString || "Unavailable"}</p>
        }
        <h2>{i18n.t("PAGES.DASHBOARD.AVAILABLE_BALANCE")}</h2>
      </div>

      <div className="call-to-action">
        {featureFlags.includes(FEATURE_FLAGS.SEND_TO_BANK_ACCOUNT) &&
          <ActionLink
            text="Send to Bank"
            classes="btn btn-white-outline bold"
            clickFunc={handleClick}
          />
        }

        {featureFlags.includes(FEATURE_FLAGS.DISPLAY_CASH_PICKUP) &&
          <ActionLink
            text="Cash Pickup"
            href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.MONEY_TRANSFER}`}
            classes="btn btn-white-outline bold"
          />
        }

      </div>
    </section>
  );
}

Balance.propTypes = {
  /** user card data from redux */
  card: PropTypes.object,
  /** user featureFlags data from redux */
  featureFlags: PropTypes.array
}

export default Balance;