import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../constants/localization";
import ActionRow from "../../components/Layouts/ActionRow";
import ActionLink from "../../components/Common/ActionLink";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { connect } from "react-redux";
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import AnswerSecurityQuestion from "./AnswerSecurityQuestion";
import FlowNextButton from "../../components/Buttons/FlowNextButton";
import UserApi from "../../api/user";

const FORM_NAME = "PersonalInfoEntryForm";

class PersonalInfoEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsValid: false,
      scrollTop: false,
      showLoader: true,
    };
  }

  /**
   * Gets the fields to display for the Personal
   * Information entry screen
   */
  getPersonalInfoFields = async () => {
    try {
      let response = await UserApi.GetPersonalInfoFields(this.props.username);
      const { securityQuestion, fields } = response;
      this.setState({
        securityQuestion,
        dynamicFields: fields,
        showLoader: false,
      });
    } catch (error) {
      this.props.toggleGenericErrorModal();
    }
  };

  componentDidMount() {
    this.getPersonalInfoFields();
  }

  buildDynamicFields = () => {
    if (this.state.dynamicFields) {
      return Array.apply(
        null,
        this.state.dynamicFields.map((field, index) => (
          <DynamicInputField
            key={index}
            {...this.buildFieldPropObject(field)}
          />
        ))
      );
    }
  };

  buildFieldPropObject = (field) => {
    var dynamicField = {
      label: field.name,
      placeholderText:
        field.pattern != null ? field.pattern : field.placeholderText,
      type: field.datatype,
      max: field.max,
      required: true,
      valid: field.isValid,
      validationError: field.validationError,
      regex: field.regex,
      id: field.serviceProviderFieldId,
      options: field.options,
      value: field.value,
      optionSource: field.optionSource,
      moreInfo: field.moreInfo != null ? field.moreInfo : null,
      showModal: field.moreInfo != null ? this.showModal : null,
      pattern: field.pattern != null ? field.pattern : null,
    };

    if (field.isSensitive) {
      dynamicField["isSensitive"] = field.isSensitive;
    }

    return dynamicField;
  };

  buildSecurityQuestions = () => {
    if (this.state.securityQuestion) {
      return (
        <AnswerSecurityQuestion
          key={1}
          question={this.state.securityQuestion}
        />
      );
    }
  };

  updateFormProps = (isFormValid) => {
    this.setState({
      formIsValid: isFormValid,
    });
  };

  submitForm = (e) => {
    e.preventDefault();
    this.props.submitPersonalInfo(this.buildFieldObject());
  };

  buildFieldObject() {
    let fieldValues = this.props.personalInfoForm.values;
    let valuesToSubmit = [];

    Object.keys(fieldValues).forEach(function eachKey(key) {
      let keyValue = fieldValues[key];
      if (key == "dateOfBirth") {
        keyValue = keyValue.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      } else if (key === "securityQuestionAnswer" && keyValue instanceof Date) {
        keyValue = keyValue.toISOString().split("T")[0];
      }
      valuesToSubmit.push({
        serviceProviderFieldId: key,
        value: keyValue,
      });
    });

    valuesToSubmit.push({
      serviceProviderFieldId: "securityQuestion",
      value: this.state.securityQuestion.id,
    });

    return valuesToSubmit;
  }

  render() {
    const dynamicFields = this.buildDynamicFields();
    const securityQuestions = this.buildSecurityQuestions();
    return (
      <React.Fragment>
        <GenericForm
          form={FORM_NAME}
          onSubmit={this.submitForm}
          onUpdate={this.updateFormProps}
        >
          <GenericForm.Body>
            <PageBlock id="personal-info-entry" showScreenErrors>
              <PageBlock.Title>
                {PAGES.FORGOT_PASSWORD.PERSONAL_INFO_ENTRY.PAGE_BLOCK_TITLE}
              </PageBlock.Title>
              <PageBlock.IntroText>
                {PAGES.FORGOT_PASSWORD.PERSONAL_INFO_ENTRY.PAGE_BLOCK_TEXT}
              </PageBlock.IntroText>
              <PageBlock.Loader loadingContent={this.state.showLoader}>
                <InputSkeletonGroup numInputs={3} />
              </PageBlock.Loader>
              <PageBlock.Body loadingContent={this.state.showLoader}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="username">
                      <label>
                        {
                          PAGES.FORGOT_PASSWORD.PERSONAL_INFO_ENTRY
                            .USERNAME_LABEL_TEXT
                        }
                      </label>
                      <div data-cy="username" className="username-text">
                        {this.props.username}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">{dynamicFields}</div>
                </div>
                <div className="row">
                  <div className="col-sm-6">{securityQuestions}</div>
                </div>
                <ActionRow>
                  <ActionRow.Forward>
                    <FlowNextButton
                      dataCy="personal-info-entry-submit"
                      disabled={
                        !this.state.formIsValid || this.props.isAccountLocked
                      }
                      loading={this.props.loading}
                    />
                  </ActionRow.Forward>
                  <ActionRow.Backward>
                    <ActionLink
                      clickFunc={this.props.goBack}
                      dataCy="personal-info-entry-back-button"
                      text={COMMON.BACK}
                    />
                  </ActionRow.Backward>
                </ActionRow>
              </PageBlock.Body>
            </PageBlock>
          </GenericForm.Body>
        </GenericForm>
      </React.Fragment>
    );
  }
}

PersonalInfoEntry.propTypes = {
  renderFields: PropTypes.func,
  showLoader: PropTypes.bool,
  personalInfoFields: PropTypes.object,
  submitPersonalInfo: PropTypes.func,
  username: PropTypes.string,
  loading: PropTypes.bool,
  personalInfoForm: PropTypes.any,
  goBack: PropTypes.func,
  isAccountLocked: PropTypes.bool,
  toggleGenericErrorModal: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    personalInfoForm: state.form[FORM_NAME],
  };
};

export default connect(mapStateToProps)(PersonalInfoEntry);
