import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";

const textAndPicture = ({ picture, content }) => {
  return `<div class="info-picture-modal__picture">${picture}</div>\
    <div class="info-picture-modal__text">${content}</div>`;
};

const InfoPictureModal = (props) => {
  return (
    <Modal
      title={props.title}
      open={props.open}
      disableNativeActions={true}
      onClose={props.onClose}
      className="info-picture-modal"
      content={textAndPicture(props)}
    />
  );
};

InfoPictureModal.propTypes = {
  picture: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
};

export default InfoPictureModal;
