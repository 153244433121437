/* eslint-disable import/no-named-as-default */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PAGES } from "../../constants/localization";
import Footer from "../../components/Common/Footer";
import {
  Authenticate,
  RefreshToken,
  Logout,
} from "../../actions/auth";
import { Init, AppState } from "../../actions/global";
import { GetUserProfile, GetFeatureFlags, GetUserNews } from "../../actions/user";
import { GetUserCards } from "../../actions/card";
import { GetFooterLinks, GetNavBar } from "../../actions/htmlBuilders";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import { GetSupport } from "../../actions/support";
import { GetUserSelections } from "../../actions/userSelection";
import { GetEnrollmentData } from "../../actions/enrollment";
import { AddInitialApiErrors } from "../../actions/dashboard";
import SpinningLoader from "../../components/Common/SpinningLoader";
import NavigationBar from "../../components/Navigation/NavigationBar";
import Modal from "../../components/Common/Modal";
import {
  ACTION_DISPLAY_TYPES,
  APP_STATE,
  DIRECTIVE_TYPE,
} from "../../constants/enums";
import { AUTH } from "../../constants/api";
import { ROUTES, BASE_NAME } from "../../constants/clientRoutes";
import Routes from "./Routes";
import moment from "moment";
import ErrorModal from "../../components/Common/Modals/ErrorModal";
import ErrorProvider from "../../components/ErrorProvider";
import DashboardInAppMessageModal from "../../components/Common/Modals/DashboardInAppMessageModal";
import withAppInsights from "../../services/appInsights";
import { initEventTracker, trackPageViews } from "../../services/eventTracker";
import { BrowserRouter, Route } from "react-router-dom";
import WebviewWrapper from "../../components/Settings/TOTP-Login/WebviewWrapper";
import { ERROR_CODES } from "../../constants/errorCodes";
import ExternalLinksModal from "../../components/Common/Modals/ExternalLinksModal";

// Start GA
initEventTracker();

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

/**
 * The main app wrapper to handle
 */
class App extends React.Component {
  constructor(props) {
    super(props);

    this.sessionTimeout = null;
    this.sessionWarningTimeout = null;
    this.authTokenRefreshTimeout = null;

    this.state = {
      error: null,
      showSessionTimeoutModal: false,
    };
  }

  async componentDidUpdate(prevProps) {
    /// The auth token is found or created (log in)
    if (!prevProps.token && this.props.token) {
      await this.initializeAuthorizedSession();
      this.setupAuthTokenRefresh();
    }

    /// The auth token is removed (log out)
    else if (prevProps.token && !this.props.token) {
      clearTimeout(this.warningTimeout);
      clearTimeout(this.sessionTimeout);
      clearTimeout(this.authTokenRefreshTimeout);
    }

    /// The auth token changes (refresh token/stay connected)
    else if (
      prevProps.token &&
      this.props.token &&
      prevProps.token != this.props.token
    ) {
      this.setupAuthTokenRefresh();
    }

    const prevPath = prevProps.location.pathname + prevProps.location.search;
    const currentPath = window.location.pathname + window.location.search;

    // Route changed, track pageview
    if (prevPath !== currentPath) {
      trackPageViews(window.location);
    }
  }

  async componentDidMount() {
    // Track pageview on inital app load
    trackPageViews(window.location);

    await this.props.DisplayLoading();

    await this.props.Init().then(async ({ returnUrl }) => {
      this.props.Authenticate().then(() => {
        this.props.GetFooterLinks();
      });
      await this.props.GetUserSelections();

      this.props.FinishLoading();
      this.props.AppState(APP_STATE.Ready);
      if (this.props.token) {
        if (returnUrl) {
          // redirect if needed
          location.href = returnUrl;
        } else if (
          window.location.pathname === "/" ||
          window.location.pathname === ""
        ) {
          // redirect to dashboard if no returnUrl
          // and when they land on the root url, otherwise it should
          // open the react screen for the path
          location.href = ROUTES.EXTERNAL.DASHBOARD;
        }
      }
    });
  }

  setupAuthTokenRefresh = () => {
    /// Determine when to fire the token refresh.
    /// This will happen consistently in the background on an interval until logout.
    const tokenExpirationDateTime = moment(this.props.token.expirationDateTime);
    const tokenRefreshDateTime = tokenExpirationDateTime.subtract(
      AUTH.TOKEN_REFRESH_BUFFER_MILLISECONDS,
      "milliseconds"
    );
    const timeUntilTokenRefresh = moment
      .duration(tokenRefreshDateTime.diff(moment()))
      .asMilliseconds();

    /// If the token has already expired no refresh should be set up.
    if (timeUntilTokenRefresh > 0) {
      clearTimeout(this.authTokenRefreshTimeout);
      this.authTokenRefreshTimeout = setTimeout(() => {
        this.props.RefreshToken();
      }, timeUntilTokenRefresh);
    }
  };

  /**
   * Sets up the authenticated user and associated session timeouts.
   */
  initializeAuthorizedSession = async () => {
    if (this.props.initializeUserData) {
      const response = await Promise.allSettled([
        this.props.GetUserCards(),
        this.props.GetUserProfile(),
        this.props.GetFeatureFlags(),
        this.props.GetUserNews(),
        this.props.GetNavBar(),
      ]);

      const rejectedIndex = response.findIndex(apiCall => apiCall.status === "rejected");

      if (rejectedIndex !== -1) {
        let error = {};
        switch (rejectedIndex) {
          case 0:
            error.errorCode = ERROR_CODES.INITIAL_API_CALLS.GET_USER_CARDS;
            error.apiCall = "GetUserCard";
            break;
          case 1:
            error.errorCode = ERROR_CODES.INITIAL_API_CALLS.GET_USER_PROFILE;
            error.apiCall = "GetUserProfile";
            break;
          case 2:
            error.errorCode = ERROR_CODES.INITIAL_API_CALLS.GET_FEATURE_FLAGS;
            error.apiCall = "GetFeatureFlags";
            break;
          case 3:
            error.errorCode = ERROR_CODES.INITIAL_API_CALLS.GET_USER_NEWS;
            error.apiCall = "GetUserNews";
            break;
          case 4:
            error.errorCode = ERROR_CODES.INITIAL_API_CALLS.GET_NAV_BAR;
            error.apiCall = "GetNavBar";
            break;
        }

        this.props.AddInitialApiErrors(error);
      }
    }

    // FaceTec v.9.7.13 introduced a strange handling of the timeouts and displayed an error: 
    // Failed to execute 'postMessage' on 'Worker': function ()
    // So if the directive is a face check enrollment, we don't want to set up the session timeouts
    if (this.props.directive.directiveType !== DIRECTIVE_TYPE.FaceCheckEnrollment) {
      this.setupSessionTimeoutHandler();
    }

    return;
  };

  /**
   * Resets the session timeouts and starts the timers over.
   * This will show the session timeout popup after the expected intervals.
   */
  resetTimeouts = () => {
    clearTimeout(this.warningTimeout);
    clearTimeout(this.sessionTimeout);

    this.warningTimeout = setTimeout(() => {
      this.showSessionTimeoutModal();
    }, AUTH.SESSION_TIMEOUT_MILLISECONDS - AUTH.SESSION_TIMEOUT_WARNING_BUFFER_MILLISECONDS);

    this.sessionTimeout = setTimeout(() => {
      this.logOut();
    }, AUTH.SESSION_TIMEOUT_MILLISECONDS);
  };

  /**
   * Initializes the session timeouts.
   * Adds handlers to window events to mark the user as active.
   */
  setupSessionTimeoutHandler = () => {
    window.onload = this.resetTimeouts;
    window.onmousemove = this.resetTimeouts;
    window.onmousedown = this.resetTimeouts; // catches touchscreen presses as well
    window.ontouchstart = this.resetTimeouts; // catches touchscreen swipes as well
    window.onclick = this.resetTimeouts; // catches touchpad clicks as well
    window.onkeypress = this.resetTimeouts;
    window.addEventListener("scroll", this.resetTimeouts, true); // improved; see comments

    this.resetTimeouts();
  };

  /**
   * Displays the modal that warns the user their session is about to expire.
   */
  showSessionTimeoutModal = () => {
    this.setState({ showSessionTimeoutModal: true });
  };

  /**
   * If the user selects to stay connected, we reset all the modal timers and refresh the auth token
   */
  handleStayConnected = async () => {
    this.resetTimeouts();
    await this.props.RefreshToken();
    this.setState({ showSessionTimeoutModal: false });
  };

  /**
   * If the user chooses not to stay connected we log them out.
   */
  logOut = async () => {
    await this.props.Logout();

    clearTimeout(this.warningTimeout);
    clearTimeout(this.sessionTimeout);
    clearTimeout(this.authTokenRefreshTimeout);

    location.href = ROUTES.EXTERNAL.LOGIN;
  };

  render() {
    let {
      displaySpinner,
      navBarLinks,
      history,
      store,
      location,
      showInAppMessage,
      inAppMessage,
      isInert
    } = this.props;

    if (location.pathname.endsWith(ROUTES.TOTP_INSTRUCTIONS)) {
      return (
        <BrowserRouter basename={BASE_NAME}>
          <Route path={ROUTES.TOTP_INSTRUCTIONS} component={WebviewWrapper} />
        </BrowserRouter>
      )
    } else {
      return (

        <div id="full-height" {...(isInert ? { inert: "true" } : {})}>

          <a href="#main" className="skip">Skip to main content</a>

          {/* Top level Nav */}
          <NavigationBar
            navBarLinks={navBarLinks}
            history={history}
            location={location}
          />
          <SpinningLoader show={displaySpinner} />
          {/* Routes: */}
          <main className="flex-barrier">
            <div id="main" className="container">
              <Routes store={store} history={history} />
            </div>
          </main>
          <Footer
            links={this.props.footerLinks}
            issuerStatement={this.props.issuerStatement}
            location={location}
          />
          <Modal
            title={PAGES.SESSION.SESSION_TIMEOUT}
            open={this.state.showSessionTimeoutModal}
            onClose={this.handleStayConnected}
            content={PAGES.SESSION.SESSION_TIMEOUT_WARNING}
            actions={[
              {
                title: PAGES.SESSION.LOG_OUT,
                onClick: this.logOut,
              },
              {
                title: PAGES.SESSION.STAY_CONNECTED,
                displayType: ACTION_DISPLAY_TYPES.PRIMARY,
                onClick: this.handleStayConnected,
              },
            ]}
          />
          {showInAppMessage && (
            <DashboardInAppMessageModal
              open={showInAppMessage}
              inAppMessage={inAppMessage}
            />
          )}
          <ErrorProvider
            render={(errors) => (
              <ErrorModal errors={errors} isLoggedIn={this.props.token != null} />
            )}
          />

          <ExternalLinksModal />
        </div>
      );
    }


  }

  static propTypes = {
    children: PropTypes.element,
    navBarLinks: PropTypes.array.isRequired,
    footerLinks: PropTypes.array.isRequired,
    issuerStatement: PropTypes.string,
    displaySpinner: PropTypes.bool.isRequired,
    networkErrors: PropTypes.array,
    RefreshToken: PropTypes.func,
    Authenticate: PropTypes.any,
    Init: PropTypes.any,
    AppState: PropTypes.any,
    GetNavBar: PropTypes.any,
    GetFooterLinks: PropTypes.any,
    DisplayLoading: PropTypes.any,
    FinishLoading: PropTypes.any,
    GetUserProfile: PropTypes.any,
    GetUserCards: PropTypes.any,
    GetFeatureFlags: PropTypes.any,
    GetUserNews: PropTypes.func,
    GetSupport: PropTypes.any,
    GetUserSelections: PropTypes.any,
    AddInitialApiErrors: PropTypes.func,
    Logout: PropTypes.func,
    location: PropTypes.any,
    history: PropTypes.any,
    store: PropTypes.any,
    token: PropTypes.object,
    employerName: PropTypes.string,
    passportCountry: PropTypes.string,
    externalUserId: PropTypes.string,
    errors: PropTypes.array,
    card: PropTypes.object,
    initializeUserData: PropTypes.bool,
    showInAppMessage: PropTypes.bool,
    inAppMessage: PropTypes.any,
    directive: PropTypes.object,
    isInert: PropTypes.bool
  };
}

function mapStateToProps(state) {
  const {
    htmlBuilders,
    router,
    spinnersInProgress,
    token,
    user,
    errors,
    card,
    auth: {
      username,
      authResult: { message, showMessage, directive },
    },
    global
  } = state;
  return {
    errors,
    location: router.location,
    navBarLinks: htmlBuilders.navBarLinks,
    footerLinks: htmlBuilders.footerLinks,
    issuerStatement: htmlBuilders.issuerStatement,
    displaySpinner: spinnersInProgress > 0,
    token,
    employerName: user.profile.employerName,
    passportCountry: user.profile.passportCountry,
    externalUserId: user.profile.externalUserId,
    card,
    initializeUserData: user.isAuthenticated && username === null,
    inAppMessage: message,
    showInAppMessage: showMessage,
    directive,
    isInert: global.isInert
  };
}

/*
Note: When importing only a few actions, connect allows you to provide an object of actions
in place of a `mapDispatchToProps` function and auto-binds to props.  You will have to set
named actions in propTypes as PropTypes.any (not-required) for linting to pass

Otherwise use the standard:
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(importedActions, dispatch)
  };
}
 */

//the `pure: false` option is a required connect flag to support component driven navigation
export default connect(mapStateToProps, {
  RefreshToken,
  AppState,
  Init,
  Authenticate,
  GetUserProfile,
  GetUserCards,
  GetFeatureFlags,
  GetSupport,
  GetNavBar,
  GetFooterLinks,
  GetUserSelections,
  GetEnrollmentData,
  GetUserNews,
  AddInitialApiErrors,
  DisplayLoading,
  FinishLoading,
  Logout,
})(withAppInsights(App));
