import React, { useState } from "react";
import { useSelector } from "react-redux";
import CardDetailsForm from "../../components/Enrollment/Forms/CardDetailsForm";
import SpinningLoader from "../../components/Common/SpinningLoader";
import SessionEndModal from "../../components/Enrollment/SessionEndModal";
import PagePathConstants from "../../constants/page-numbers";

const CardDetails = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  if (currentPage !== PagePathConstants.CARD_DETAILS) {
    return null;
  }

  return (
    <div className="">
      <SpinningLoader show={showSpinner} />
      <CardDetailsForm
        setShowSpinner={setShowSpinner}
      />
      <SessionEndModal />
    </div>
  );
};

CardDetails.displayName = "CardDetailsComponent";

export default CardDetails;
