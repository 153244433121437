import React, { useState, useEffect } from 'react';
import { COMMON } from '../../constants/localization';
import { ACTION_DISPLAY_TYPES, ICONS } from '../../constants/enums';
import ENV from "../../constants/environment";
import { ReviewAndAcceptDocumentType } from '../../types/api/user';
import i18n from '../../i18n/config';
import useClickExternalLink from '../../hooks/useClickExternalLink';

import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import ActionRow from '../../components/Layouts/ActionRow';
import Action from '../../components/Common/Action';
import UserApi from '../../api/user';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import Icon from '../../components/Common/Icon';
import AlertModal from '../../components/Common/Modals/AlertModal';
import { ROUTES } from '../../constants/clientRoutes';

const ReviewAndAccept = () => {
  const openExternalLinksModal = useClickExternalLink();
  const [docs, setDocs] = useState<ReviewAndAcceptDocumentType[]>([]);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const response = await UserApi.GetReviewAndAcceptDocs();
        setDocs(response.documents);

      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }
        setLoading(false);
        setShowErrorModal(true);
      }

      setLoading(false);
    }

    getDocs();
  }, []);

  const handleSubmit = async () => {
    setSubmitting(true);

    try {
      await UserApi.UpdateReviewAndAcceptDocs();
      location.href = ROUTES.EXTERNAL.DASHBOARD;
    } catch (err) {
      setSubmitting(false);
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setLoading(false);
      setShowErrorModal(true);
    }

    setLoading(false);
  }

  return (
    <FlowScreen
      flowTitle={i18n.t("PAGES.SETTINGS.REVIEW_AND_ACCEPT.TITLE")}
      className="communications-preferences-screen"
    >
      <PageBlock>
        <PageBlock.Loader loadingContent={loading}>
          <InputSkeletonGroup numInputs={2} />
        </PageBlock.Loader>
        <PageBlock.Body
          loadingContent={loading}
        >
          { }
          <p>{i18n.t("PAGES.SETTINGS.REVIEW_AND_ACCEPT.P1")}</p>

          <p>{i18n.t("PAGES.SETTINGS.REVIEW_AND_ACCEPT.P2")}</p>

          <ul className="document-list spacing-top-large">
            {docs.map(document => {
              let url = `${ENV.BASE_NAVIGATOR_URL}/Administration/getfile/${document.id}`;
              return (
                <li key={document.id} className="flex-row justify-content-between align-items-center padding-top-medium padding-bottom-medium show-border">
                  <p className="large spacing-bottom-none">{document.title}</p>
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
                    className="no-underline bold icon-small"
                    onClick={(e) => openExternalLinksModal(e, url)}
                  >
                    View
                    <Icon icon={ICONS.EXTERNAL_LINK_BLUE} />
                  </a>
                </li>
              )
            })}
          </ul>

          <ActionRow>
            <ActionRow.Forward>
              <Action
                title={COMMON.SUBMIT}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
                loading={submitting}
                disabled={docs.length < 1}
                onClick={handleSubmit}
              />
            </ActionRow.Forward>
          </ActionRow>
        </PageBlock.Body>
      </PageBlock>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />
    </FlowScreen>
  )
}

export default ReviewAndAccept;