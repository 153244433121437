import React, { Component } from "react";

const withMobileResizing = (WrappedComponent) => {
  return class MobileResizingHOC extends Component {
    state = {
      width:
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth,
    };

    componentDidMount = () => {
      window.addEventListener("resize", this.handleWindowSizeChange);
    };

    componentWillUnmount = () => {
      window.removeEventListener("resize", this.handleWindowSizeChange);
    };

    /**
     * Event that updates the width state when/as the page is resized
     */
    handleWindowSizeChange = () => {
      this.setState({
        width:
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth,
      });
    };

    render() {
      return <WrappedComponent width={this.state.width} {...this.props} />;
    }
  };
};

export default withMobileResizing;
