import React, { useEffect, useState } from 'react';
import { DisplaySectionType } from '../../types/api/moneyTransfer';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';

import DisplaySection from '../MoneyTransfer/DisplaySection';

const BankBeneficiarySections = ({ sections }: { sections: DisplaySectionType[] }) => {
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);
  const [bankBeneSections, setBankBeneSections] = useState<DisplaySectionType[]>();
  const [transferDetails, setTransferDetails] = useState<DisplaySectionType[]>();
  const [transactionSummary, setTransactionSummary] = useState<{ heading: string, label: string, value: string } | null>();

  useEffect(() => {
    setBankBeneSections(sections.filter(section => section.id === "BeneficiaryDetails" || section.id === "BankDetails"));
    setTransferDetails(sections.filter(section => section.id === "TransferDetails"));
    setTransactionSummary(onDemandTransfer?.transactionNumber ? {
      heading: "Transfaction Summary",
      label: "transaction number",
      value: onDemandTransfer.transactionNumber
    } : null);
  }, []);

  return (
    <>
      {bankBeneSections && transferDetails &&
        <>
          {/* Beneficiary and Bank Details */}
          <DisplaySection sections={bankBeneSections} className="spacing-bottom-small" />

          {/* Transfer Details */}
          <DisplaySection sections={transferDetails} className={`spacing-bottom-small grid-cols-1 ${transactionSummary ? "display-card-header" : "null"}`} transactionSummary={transactionSummary} />
        </>
      }

    </>
  );
}

export default BankBeneficiarySections;