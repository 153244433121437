import { SUPPORT } from "../../constants/actionTypes";
import initialState from "../initialState";

// function mapConversation(conversation) {
//   return {
//     id: conversation.id,
//     status: conversation.status.name,
//     messages: conversation.messages,
//     title: conversation.title,
//   }
// }

export default function conversationsReducer(
  state = initialState.support.conversations,
  action
) {
  switch (action.type) {
    case SUPPORT.GET_INDEX_SUCCESS: {
      if (action.index.conversations) {
        return action.index.conversations.slice();
      }
      return state;
    }

    case SUPPORT.POST_NEW_CONVERSATION_SUCCESS: {
      let newState = state.slice();
      if (action.isActiveConversation) {
        return newState;
      }
      newState.splice(0, 0, action.conversation);
      return newState;
    }

    case SUPPORT.POST_NEW_ATTACHMENT_SUCCESS:
    case SUPPORT.POST_NEW_MESSAGE_SUCCESS: {
      return state.map((conversation) => {
        if (
          conversation.conversationId !== action.conversation.conversationId
        ) {
          return conversation;
        }

        const messages = conversation.messages.slice();
        messages.splice(messages.length, 0, ...action.conversation.messages);
        action.conversation.messages = messages.slice();
        return {
          ...conversation,
          ...action.conversation,
        };
      });
    }

    /**
     * Finds the conversation in a copy of the state,
     * updates the hasUnreadMessages to false, then replaces
     * the state with the newly updated copy
     */
    case SUPPORT.POST_READ_CONVERSATION: {
      let newState = [...state];
      const index = newState.findIndex(
        (c) => c.conversationId === action.conversationId
      );
      if (index >= 0) {
        let conversation = {
          ...newState[index],
          hasUnreadMessages: false,
        };
        newState[index] = conversation;
        return newState;
      }
      return newState;
    }

    default:
      return state;
  }
}
