import { DISPLAY_LOADING, HIDE_LOADING } from "../constants/actionTypes";

/**
 * Dispatches action to show the loading spinner.
 * @returns {function(*): *}
 * @constructor
 */
export function DisplayLoading() {
  return (dispatch) =>
    dispatch({
      type: DISPLAY_LOADING,
    });
}

/**
 * Dispatches action to hide the loading spinner.
 * @returns {function(*): *}
 * @constructor
 */
export function FinishLoading() {
  return (dispatch) =>
    dispatch({
      type: HIDE_LOADING,
    });
}
