export const COUNTRY_CURRENCIES = [
  {
    name: "Poland",
    id: 168,
    isoCode: "PL",
    currencies: [
      {
        id: 96,
        name: "Zloty",
        code: "PLN",
        numericCode: "985",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Philippines",
    id: 166,
    isoCode: "PH",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 94,
        name: "Philippine Peso",
        code: "PHP",
        numericCode: "608",
      },
    ],
  },
  {
    name: "United Arab Emirates",
    id: 225,
    isoCode: "AE",
    currencies: [
      {
        id: 3,
        name: "UAE Dirham",
        code: "AED",
        numericCode: "784",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Albania",
    id: 3,
    isoCode: "AL",
    currencies: [
      {
        id: 4,
        name: "Lek",
        code: "ALL",
        numericCode: "008",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Armenia",
    id: 11,
    isoCode: "AM",
    currencies: [
      {
        id: 5,
        name: "Armenian Dram",
        code: "AMD",
        numericCode: "051",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Angola",
    id: 7,
    isoCode: "AO",
    currencies: [
      {
        id: 7,
        name: "Kwanza",
        code: "AOA",
        numericCode: "973",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Argentina",
    id: 10,
    isoCode: "AR",
    currencies: [
      {
        id: 8,
        name: "Argentine Peso",
        code: "ARS",
        numericCode: "032",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Australia",
    id: 13,
    isoCode: "AU",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Azerbaijan",
    id: 15,
    isoCode: "AZ",
    currencies: [
      {
        id: 10,
        name: "Azerbaijanian Manat",
        code: "AZN",
        numericCode: "944",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bosnia and Herzegovina",
    id: 28,
    isoCode: "BA",
    currencies: [
      {
        id: 11,
        name: "Convertible Mark",
        code: "BAM",
        numericCode: "977",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Barbados",
    id: 19,
    isoCode: "BB",
    currencies: [
      {
        id: 12,
        name: "Barbados Dollar",
        code: "BBD",
        numericCode: "052",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bangladesh",
    id: 18,
    isoCode: "BD",
    currencies: [
      {
        id: 13,
        name: "Taka",
        code: "BDT",
        numericCode: "050",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bulgaria",
    id: 33,
    isoCode: "BG",
    currencies: [
      {
        id: 14,
        name: "Bulgarian Lev",
        code: "BGN",
        numericCode: "975",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Bahrain",
    id: 17,
    isoCode: "BH",
    currencies: [
      {
        id: 15,
        name: "Bahraini Dinar",
        code: "BHD",
        numericCode: "048",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Burundi",
    id: 35,
    isoCode: "BI",
    currencies: [
      {
        id: 16,
        name: "Burundi Franc",
        code: "BIF",
        numericCode: "108",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bermuda",
    id: 24,
    isoCode: "BM",
    currencies: [
      {
        id: 17,
        name: "Bermudian Dollar",
        code: "BMD",
        numericCode: "060",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Brunei",
    id: 32,
    isoCode: "BN",
    currencies: [
      {
        id: 18,
        name: "Brunei Dollar",
        code: "BND",
        numericCode: "096",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bolivia",
    id: 26,
    isoCode: "BO",
    currencies: [
      {
        id: 19,
        name: "Boliviano",
        code: "BOB",
        numericCode: "068",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Brazil",
    id: 30,
    isoCode: "BR",
    currencies: [
      {
        id: 20,
        name: "Brazilian Real",
        code: "BRL",
        numericCode: "986",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bahamas",
    id: 16,
    isoCode: "BS",
    currencies: [
      {
        id: 21,
        name: "Bahamian Dollar",
        code: "BSD",
        numericCode: "044",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bhutan",
    id: 25,
    isoCode: "BT",
    currencies: [
      {
        id: 22,
        name: "Ngultrum",
        code: "BTN",
        numericCode: "064",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Botswana",
    id: 29,
    isoCode: "BW",
    currencies: [
      {
        id: 23,
        name: "Pula",
        code: "BWP",
        numericCode: "072",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Belize",
    id: 22,
    isoCode: "BZ",
    currencies: [
      {
        id: 24,
        name: "Belize Dollar",
        code: "BZD",
        numericCode: "084",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Canada",
    id: 38,
    isoCode: "CA",
    currencies: [
      {
        id: 25,
        name: "Canadian Dollar",
        code: "CAD",
        numericCode: "124",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Liechtenstein",
    id: 122,
    isoCode: "LI",
    currencies: [
      {
        id: 26,
        name: "Swiss Franc",
        code: "CHF",
        numericCode: "756",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Switzerland",
    id: 208,
    isoCode: "CH",
    currencies: [
      {
        id: 26,
        name: "Swiss Franc",
        code: "CHF",
        numericCode: "756",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Chile",
    id: 43,
    isoCode: "CL",
    currencies: [
      {
        id: 27,
        name: "Chilean Peso",
        code: "CLP",
        numericCode: "152",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "China",
    id: 44,
    isoCode: "CN",
    currencies: [
      {
        id: 29,
        name: "Yuan Renminbi",
        code: "CNY",
        numericCode: "156",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Colombia",
    id: 47,
    isoCode: "CO",
    currencies: [
      {
        id: 30,
        name: "Colombian Peso",
        code: "COP",
        numericCode: "170",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Costa Rica",
    id: 51,
    isoCode: "CR",
    currencies: [
      {
        id: 31,
        name: "Costa Rican Colon",
        code: "CRC",
        numericCode: "188",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cape Verde",
    id: 39,
    isoCode: "CV",
    currencies: [
      {
        id: 32,
        name: "Cabo Verde Escudo",
        code: "CVE",
        numericCode: "132",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Czech Republic",
    id: 57,
    isoCode: "CZ",
    currencies: [
      {
        id: 33,
        name: "Czech Koruna",
        code: "CZK",
        numericCode: "203",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Djibouti",
    id: 61,
    isoCode: "DJ",
    currencies: [
      {
        id: 34,
        name: "Djibouti Franc",
        code: "DJF",
        numericCode: "262",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Denmark",
    id: 60,
    isoCode: "DK",
    currencies: [
      {
        id: 35,
        name: "Danish Krone",
        code: "DKK",
        numericCode: "208",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Faroe Islands",
    id: 71,
    isoCode: "FO",
    currencies: [
      {
        id: 35,
        name: "Danish Krone",
        code: "DKK",
        numericCode: "208",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Greenland",
    id: 85,
    isoCode: "GL",
    currencies: [
      {
        id: 35,
        name: "Danish Krone",
        code: "DKK",
        numericCode: "208",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Dominican Republic",
    id: 63,
    isoCode: "DO",
    currencies: [
      {
        id: 36,
        name: "Dominican Peso",
        code: "DOP",
        numericCode: "214",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Algeria\t",
    id: 4,
    isoCode: "DZ",
    currencies: [
      {
        id: 37,
        name: "Algerian Dinar",
        code: "DZD",
        numericCode: "012",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Egypt",
    id: 65,
    isoCode: "EG",
    currencies: [
      {
        id: 38,
        name: "Egyptian Pound",
        code: "EGP",
        numericCode: "818",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Eritrea",
    id: 68,
    isoCode: "ER",
    currencies: [
      {
        id: 39,
        name: "Nakfa",
        code: "ERN",
        numericCode: "232",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ethiopia",
    id: 70,
    isoCode: "ET",
    currencies: [
      {
        id: 40,
        name: "Ethiopian Birr",
        code: "ETB",
        numericCode: "230",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Andorra",
    id: 6,
    isoCode: "AD",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Austria",
    id: 14,
    isoCode: "AT",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Belgium",
    id: 21,
    isoCode: "BE",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cyprus",
    id: 56,
    isoCode: "CY",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Estonia",
    id: 69,
    isoCode: "EE",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Finland",
    id: 74,
    isoCode: "FI",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "France",
    id: 75,
    isoCode: "FR",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Germany",
    id: 81,
    isoCode: "DE",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Greece",
    id: 84,
    isoCode: "GR",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ireland",
    id: 103,
    isoCode: "IE",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Italy",
    id: 106,
    isoCode: "IT",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Latvia",
    id: 117,
    isoCode: "LV",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Luxembourg",
    id: 124,
    isoCode: "LU",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Malta",
    id: 130,
    isoCode: "MT",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Martinique",
    id: 132,
    isoCode: "MQ",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Monaco",
    id: 138,
    isoCode: "MC",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Montenegro",
    id: 140,
    isoCode: "ME",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Netherlands",
    id: 148,
    isoCode: "NL",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Portugal",
    id: 169,
    isoCode: "PT",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Réunion",
    id: 174,
    isoCode: "RE",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint-Barthélemy",
    id: 178,
    isoCode: "BL",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "San Marino",
    id: 186,
    isoCode: "SM",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Slovakia",
    id: 195,
    isoCode: "SK",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Slovenia",
    id: 196,
    isoCode: "SI",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Spain",
    id: 201,
    isoCode: "ES",
    currencies: [
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Fiji",
    id: 73,
    isoCode: "FJ",
    currencies: [
      {
        id: 41,
        name: "Fiji Dollar",
        code: "FJD",
        numericCode: "242",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guernsey",
    id: 90,
    isoCode: "GG",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Jersey",
    id: 109,
    isoCode: "JE",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Isle of Man",
    id: 104,
    isoCode: "IM",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "United Kingdom",
    id: 226,
    isoCode: "GB",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Georgia",
    id: 80,
    isoCode: "GE",
    currencies: [
      {
        id: 43,
        name: "Lari",
        code: "GEL",
        numericCode: "981",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ghana",
    id: 82,
    isoCode: "GH",
    currencies: [
      {
        id: 44,
        name: "Ghana Cedi",
        code: "GHS",
        numericCode: "936",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Gambia",
    id: 79,
    isoCode: "GM",
    currencies: [
      {
        id: 45,
        name: "Dalasi",
        code: "GMD",
        numericCode: "270",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guinea",
    id: 91,
    isoCode: "GN",
    currencies: [
      {
        id: 46,
        name: "Guinea Franc",
        code: "GNF",
        numericCode: "324",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guatemala",
    id: 89,
    isoCode: "GT",
    currencies: [
      {
        id: 47,
        name: "Quetzal",
        code: "GTQ",
        numericCode: "320",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guyana",
    id: 93,
    isoCode: "GY",
    currencies: [
      {
        id: 48,
        name: "Guyana Dollar",
        code: "GYD",
        numericCode: "328",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Hong Kong",
    id: 45,
    isoCode: "HK",
    currencies: [
      {
        id: 49,
        name: "Hong Kong Dollar",
        code: "HKD",
        numericCode: "344",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Honduras",
    id: 96,
    isoCode: "HN",
    currencies: [
      {
        id: 50,
        name: "Lempira",
        code: "HNL",
        numericCode: "340",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Croatia",
    id: 53,
    isoCode: "HR",
    currencies: [
      {
        id: 51,
        name: "Croatian Kuna",
        code: "HRK",
        numericCode: "191",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Haiti",
    id: 94,
    isoCode: "HT",
    currencies: [
      {
        id: 52,
        name: "Gourde",
        code: "HTG",
        numericCode: "332",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Hungary",
    id: 97,
    isoCode: "HU",
    currencies: [
      {
        id: 53,
        name: "Forint",
        code: "HUF",
        numericCode: "348",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Indonesia",
    id: 100,
    isoCode: "ID",
    currencies: [
      {
        id: 54,
        name: "Rupiah",
        code: "IDR",
        numericCode: "360",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Israel",
    id: 105,
    isoCode: "IL",
    currencies: [
      {
        id: 55,
        name: "New Israeli Sheqel",
        code: "ILS",
        numericCode: "376",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Palestine",
    id: 158,
    isoCode: "PS",
    currencies: [
      {
        id: 55,
        name: "New Israeli Sheqel",
        code: "ILS",
        numericCode: "376",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "India",
    id: 99,
    isoCode: "IN",
    currencies: [
      {
        id: 56,
        name: "Indian Rupee",
        code: "INR",
        numericCode: "356",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Jamaica",
    id: 107,
    isoCode: "JM",
    currencies: [
      {
        id: 57,
        name: "Jamaican Dollar",
        code: "JMD",
        numericCode: "388",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Jordan",
    id: 110,
    isoCode: "JO",
    currencies: [
      {
        id: 58,
        name: "Jordanian Dinar",
        code: "JOD",
        numericCode: "400",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Japan",
    id: 108,
    isoCode: "JP",
    currencies: [
      {
        id: 59,
        name: "Yen",
        code: "JPY",
        numericCode: "392",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Kenya",
    id: 112,
    isoCode: "KE",
    currencies: [
      {
        id: 60,
        name: "Kenyan Shilling",
        code: "KES",
        numericCode: "404",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    id: 115,
    isoCode: "KG",
    currencies: [
      {
        id: 61,
        name: "Som",
        code: "KGS",
        numericCode: "417",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cambodia",
    id: 36,
    isoCode: "KH",
    currencies: [
      {
        id: 62,
        name: "Riel",
        code: "KHR",
        numericCode: "116",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Comoros",
    id: 48,
    isoCode: "KM",
    currencies: [
      {
        id: 63,
        name: "Comoro Franc",
        code: "KMF",
        numericCode: "174",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "South Korea",
    id: 172,
    isoCode: "KR",
    currencies: [
      {
        id: 64,
        name: "Won",
        code: "KRW",
        numericCode: "410",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Kuwait",
    id: 114,
    isoCode: "KW",
    currencies: [
      {
        id: 65,
        name: "Kuwaiti Dinar",
        code: "KWD",
        numericCode: "414",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cayman Islands",
    id: 40,
    isoCode: "KY",
    currencies: [
      {
        id: 66,
        name: "Cayman Islands Dollar",
        code: "KYD",
        numericCode: "136",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Kazakhstan",
    id: 111,
    isoCode: "KZ",
    currencies: [
      {
        id: 67,
        name: "Tenge",
        code: "KZT",
        numericCode: "398",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Laos",
    id: 116,
    isoCode: "LA",
    currencies: [
      {
        id: 68,
        name: "Kip",
        code: "LAK",
        numericCode: "418",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Lebanon",
    id: 118,
    isoCode: "LB",
    currencies: [
      {
        id: 69,
        name: "Lebanese Pound",
        code: "LBP",
        numericCode: "422",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Sri Lanka",
    id: 202,
    isoCode: "LK",
    currencies: [
      {
        id: 70,
        name: "Sri Lanka Rupee",
        code: "LKR",
        numericCode: "144",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Lesotho",
    id: 119,
    isoCode: "LS",
    currencies: [
      {
        id: 71,
        name: "Loti",
        code: "LSL",
        numericCode: "426",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Morocco",
    id: 142,
    isoCode: "MA",
    currencies: [
      {
        id: 73,
        name: "Moroccan Dirham",
        code: "MAD",
        numericCode: "504",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Madagascar",
    id: 125,
    isoCode: "MG",
    currencies: [
      {
        id: 74,
        name: "Malagasy Ariary",
        code: "MGA",
        numericCode: "969",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Macedonia",
    id: 212,
    isoCode: "MK",
    currencies: [
      {
        id: 75,
        name: "Denar",
        code: "MKD",
        numericCode: "807",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "China, Macao Special Administrative Region",
    id: 46,
    isoCode: "MO",
    currencies: [
      {
        id: 76,
        name: "Pataca",
        code: "MOP",
        numericCode: "446",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mauritania",
    id: 133,
    isoCode: "MR",
    currencies: [
      {
        id: 77,
        name: "Ouguiya",
        code: "MRO",
        numericCode: "478",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mauritius",
    id: 134,
    isoCode: "MU",
    currencies: [
      {
        id: 78,
        name: "Mauritius Rupee",
        code: "MUR",
        numericCode: "480",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Maldives",
    id: 128,
    isoCode: "MV",
    currencies: [
      {
        id: 79,
        name: "Rufiyaa",
        code: "MVR",
        numericCode: "462",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Malawi",
    id: 126,
    isoCode: "MW",
    currencies: [
      {
        id: 80,
        name: "Kwacha",
        code: "MWK",
        numericCode: "454",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mexico",
    id: 136,
    isoCode: "MX",
    currencies: [
      {
        id: 82,
        name: "Mexican Peso",
        code: "MXN",
        numericCode: "484",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Malaysia",
    id: 127,
    isoCode: "MY",
    currencies: [
      {
        id: 81,
        name: "Malaysian Ringgit",
        code: "MYR",
        numericCode: "458",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mozambique",
    id: 143,
    isoCode: "MZ",
    currencies: [
      {
        id: 83,
        name: "Mozambique Metical",
        code: "MZN",
        numericCode: "943",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Namibia",
    id: 145,
    isoCode: "NA",
    currencies: [
      {
        id: 84,
        name: "Namibia Dollar",
        code: "NAD",
        numericCode: "516",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Nigeria",
    id: 153,
    isoCode: "NG",
    currencies: [
      {
        id: 85,
        name: "Naira",
        code: "NGN",
        numericCode: "566",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Nicaragua",
    id: 151,
    isoCode: "NI",
    currencies: [
      {
        id: 86,
        name: "Cordoba Oro",
        code: "NIO",
        numericCode: "558",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Norway",
    id: 157,
    isoCode: "NO",
    currencies: [
      {
        id: 87,
        name: "Norwegian Krone",
        code: "NOK",
        numericCode: "578",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Nepal",
    id: 147,
    isoCode: "NP",
    currencies: [
      {
        id: 88,
        name: "Nepalese Rupee",
        code: "NPR",
        numericCode: "524",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "New Zealand",
    id: 150,
    isoCode: "NZ",
    currencies: [
      {
        id: 89,
        name: "New Zealand Dollar",
        code: "NZD",
        numericCode: "554",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Oman",
    id: 159,
    isoCode: "OM",
    currencies: [
      {
        id: 90,
        name: "Rial Omani",
        code: "OMR",
        numericCode: "512",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Panama",
    id: 162,
    isoCode: "PA",
    currencies: [
      {
        id: 91,
        name: "Balboa",
        code: "PAB",
        numericCode: "590",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Peru",
    id: 165,
    isoCode: "PE",
    currencies: [
      {
        id: 92,
        name: "Nuevo Sol",
        code: "PEN",
        numericCode: "604",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Papua New Guinea",
    id: 163,
    isoCode: "PG",
    currencies: [
      {
        id: 93,
        name: "Kina",
        code: "PGK",
        numericCode: "598",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Pakistan",
    id: 160,
    isoCode: "PK",
    currencies: [
      {
        id: 95,
        name: "Pakistan Rupee",
        code: "PKR",
        numericCode: "586",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Paraguay",
    id: 164,
    isoCode: "PY",
    currencies: [
      {
        id: 97,
        name: "Guarani",
        code: "PYG",
        numericCode: "600",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Qatar",
    id: 171,
    isoCode: "QA",
    currencies: [
      {
        id: 98,
        name: "Qatari Rial",
        code: "QAR",
        numericCode: "634",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Romania",
    id: 175,
    isoCode: "RO",
    currencies: [
      {
        id: 99,
        name: "New Romanian Leu",
        code: "RON",
        numericCode: "946",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Serbia",
    id: 190,
    isoCode: "RS",
    currencies: [
      {
        id: 100,
        name: "Serbian Dinar",
        code: "RSD",
        numericCode: "941",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Russia",
    id: 176,
    isoCode: "RU",
    currencies: [
      {
        id: 101,
        name: "Russian Ruble",
        code: "RUB",
        numericCode: "643",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Rwanda",
    id: 177,
    isoCode: "RW",
    currencies: [
      {
        id: 102,
        name: "Rwanda Franc",
        code: "RWF",
        numericCode: "646",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saudi Arabia",
    id: 188,
    isoCode: "SA",
    currencies: [
      {
        id: 103,
        name: "Saudi Riyal",
        code: "SAR",
        numericCode: "682",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Solomon Islands",
    id: 197,
    isoCode: "SB",
    currencies: [
      {
        id: 104,
        name: "Solomon Islands Dollar",
        code: "SBD",
        numericCode: "090",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Seychelles",
    id: 191,
    isoCode: "SC",
    currencies: [
      {
        id: 105,
        name: "Seychelles Rupee",
        code: "SCR",
        numericCode: "690",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Sweden",
    id: 207,
    isoCode: "SE",
    currencies: [
      {
        id: 106,
        name: "Swedish Krona",
        code: "SEK",
        numericCode: "752",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Singapore",
    id: 193,
    isoCode: "SG",
    currencies: [
      {
        id: 107,
        name: "Singapore Dollar",
        code: "SGD",
        numericCode: "702",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Sierra Leone",
    id: 192,
    isoCode: "SL",
    currencies: [
      {
        id: 108,
        name: "Leone",
        code: "SLL",
        numericCode: "694",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Suriname",
    id: 204,
    isoCode: "SR",
    currencies: [
      {
        id: 109,
        name: "Surinam Dollar",
        code: "SRD",
        numericCode: "968",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Sao Tome and Principe",
    id: 187,
    isoCode: "ST",
    currencies: [
      {
        id: 110,
        name: "Dobra",
        code: "STD",
        numericCode: "678",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Swaziland",
    id: 206,
    isoCode: "SZ",
    currencies: [
      {
        id: 111,
        name: "Lilangeni",
        code: "SZL",
        numericCode: "748",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Thailand",
    id: 211,
    isoCode: "TH",
    currencies: [
      {
        id: 112,
        name: "Baht",
        code: "THB",
        numericCode: "764",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tunisia",
    id: 218,
    isoCode: "TN",
    currencies: [
      {
        id: 113,
        name: "Tunisian Dinar",
        code: "TND",
        numericCode: "788",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tonga",
    id: 216,
    isoCode: "TO",
    currencies: [
      {
        id: 114,
        name: "Pa’anga",
        code: "TOP",
        numericCode: "776",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Turkey",
    id: 219,
    isoCode: "TR",
    currencies: [
      {
        id: 115,
        name: "Turkish Lira",
        code: "TRY",
        numericCode: "949",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Trinidad and Tobago",
    id: 217,
    isoCode: "TT",
    currencies: [
      {
        id: 116,
        name: "Trinidad and Tobago Dollar",
        code: "TTD",
        numericCode: "780",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Taiwan",
    id: 243,
    isoCode: "TW",
    currencies: [
      {
        id: 117,
        name: "New Taiwan Dollar",
        code: "TWD",
        numericCode: "901",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tanzania",
    id: 227,
    isoCode: "TZ",
    currencies: [
      {
        id: 118,
        name: "Tanzanian Shilling",
        code: "TZS",
        numericCode: "834",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ukraine",
    id: 224,
    isoCode: "UA",
    currencies: [
      {
        id: 119,
        name: "Hryvnia",
        code: "UAH",
        numericCode: "980",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Uganda",
    id: 223,
    isoCode: "UG",
    currencies: [
      {
        id: 120,
        name: "Uganda Shilling",
        code: "UGX",
        numericCode: "800",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "United States of America",
    id: 228,
    isoCode: "US",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Uruguay",
    id: 230,
    isoCode: "UY",
    currencies: [
      {
        id: 121,
        name: "Peso Uruguayo",
        code: "UYU",
        numericCode: "858",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Uzbekistan",
    id: 231,
    isoCode: "UZ",
    currencies: [
      {
        id: 122,
        name: "Uzbekistan Sum",
        code: "UZS",
        numericCode: "860",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Vietnam",
    id: 234,
    isoCode: "VN",
    currencies: [
      {
        id: 123,
        name: "Dong",
        code: "VND",
        numericCode: "704",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Vanuatu",
    id: 232,
    isoCode: "VU",
    currencies: [
      {
        id: 124,
        name: "Vatu",
        code: "VUV",
        numericCode: "548",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Samoa",
    id: 185,
    isoCode: "WS",
    currencies: [
      {
        id: 125,
        name: "Tala",
        code: "WST",
        numericCode: "882",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cameroon",
    id: 37,
    isoCode: "CM",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Central African Republic",
    id: 41,
    isoCode: "CF",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Chad",
    id: 42,
    isoCode: "TD",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Congo",
    id: 49,
    isoCode: "CG",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Equatorial Guinea",
    id: 67,
    isoCode: "GQ",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Gabon",
    id: 78,
    isoCode: "GA",
    currencies: [
      {
        id: 126,
        name: "CFA Franc BEAC",
        code: "XAF",
        numericCode: "950",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Anguilla",
    id: 8,
    isoCode: "AI",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Antigua and Barbuda",
    id: 9,
    isoCode: "AG",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Dominica",
    id: 62,
    isoCode: "DM",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Grenada",
    id: 86,
    isoCode: "GD",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Montserrat",
    id: 141,
    isoCode: "MS",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint Kitts and Nevis",
    id: 180,
    isoCode: "KN",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint Lucia",
    id: 181,
    isoCode: "LC",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint Vincent and the Grenadines",
    id: 184,
    isoCode: "VC",
    currencies: [
      {
        id: 127,
        name: "East Caribbean Dollar",
        code: "XCD",
        numericCode: "951",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Benin",
    id: 23,
    isoCode: "BJ",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Burkina Faso",
    id: 34,
    isoCode: "BF",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ivory Coast",
    id: 52,
    isoCode: "CI",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guinea-Bissau",
    id: 92,
    isoCode: "GW",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mali",
    id: 129,
    isoCode: "ML",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Niger",
    id: 152,
    isoCode: "NE",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Senegal",
    id: 189,
    isoCode: "SN",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Togo",
    id: 214,
    isoCode: "TG",
    currencies: [
      {
        id: 128,
        name: "CFA Franc BCEAO",
        code: "XOF",
        numericCode: "952",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "French Polynesia",
    id: 77,
    isoCode: "PF",
    currencies: [
      {
        id: 129,
        name: "CFP Franc",
        code: "XPF",
        numericCode: "953",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "New Caledonia",
    id: 149,
    isoCode: "NC",
    currencies: [
      {
        id: 129,
        name: "CFP Franc",
        code: "XPF",
        numericCode: "953",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Yemen",
    id: 237,
    isoCode: "YE",
    currencies: [
      {
        id: 130,
        name: "Yemeni Rial",
        code: "YER",
        numericCode: "886",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "South Africa",
    id: 199,
    isoCode: "ZA",
    currencies: [
      {
        id: 131,
        name: "Rand",
        code: "ZAR",
        numericCode: "710",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Zambia",
    id: 238,
    isoCode: "ZM",
    currencies: [
      {
        id: 132,
        name: "Zambian Kwacha",
        code: "ZMW",
        numericCode: "967",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Curaçao",
    id: 55,
    isoCode: "CW",
    currencies: [
      {
        id: 6,
        name: "Netherlands Antillean Guilder",
        code: "ANG",
        numericCode: "532",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint Maarten (Dutch part)",
    id: 194,
    isoCode: "SX",
    currencies: [
      {
        id: 6,
        name: "Netherlands Antillean Guilder",
        code: "ANG",
        numericCode: "532",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Christmas Island",
    id: 244,
    isoCode: "CX",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cocos (Keeling) Islands",
    id: 245,
    isoCode: "CC",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Kiribati",
    id: 113,
    isoCode: "KI",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Nauru",
    id: 146,
    isoCode: "NR",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Norfolk Island",
    id: 155,
    isoCode: "NF",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Heard Island and McDonald Islands",
    id: 248,
    isoCode: "HM",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tuvalu",
    id: 222,
    isoCode: "TV",
    currencies: [
      {
        id: 9,
        name: "Australian Dollar",
        code: "AUD",
        numericCode: "036",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    id: 242,
    isoCode: "IO",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    id: 246,
    isoCode: "GS",
    currencies: [
      {
        id: 42,
        name: "Pound Sterling",
        code: "GBP",
        numericCode: "826",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Svalbard and Jan Mayen Islands",
    id: 205,
    isoCode: "SJ",
    currencies: [
      {
        id: 87,
        name: "Norwegian Krone",
        code: "NOK",
        numericCode: "578",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Cook Islands",
    id: 50,
    isoCode: "CK",
    currencies: [
      {
        id: 89,
        name: "New Zealand Dollar",
        code: "NZD",
        numericCode: "554",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Niue",
    id: 154,
    isoCode: "NU",
    currencies: [
      {
        id: 89,
        name: "New Zealand Dollar",
        code: "NZD",
        numericCode: "554",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Pitcairn",
    id: 167,
    isoCode: "PN",
    currencies: [
      {
        id: 89,
        name: "New Zealand Dollar",
        code: "NZD",
        numericCode: "554",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tokelau",
    id: 215,
    isoCode: "TK",
    currencies: [
      {
        id: 89,
        name: "New Zealand Dollar",
        code: "NZD",
        numericCode: "554",
      },
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Åland Islands",
    id: 2,
    isoCode: "AX",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Gibraltar",
    id: 83,
    isoCode: "GI",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guadeloupe",
    id: 87,
    isoCode: "GP",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Iceland",
    id: 98,
    isoCode: "IS",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 145,
        name: "Iceland Krona",
        code: "ISK",
        numericCode: "352",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Lithuania",
    id: 123,
    isoCode: "LT",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Moldova",
    id: 173,
    isoCode: "MD",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Wallis and Futuna Islands",
    id: 235,
    isoCode: "WF",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "French Guiana",
    id: 76,
    isoCode: "GF",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "French Southern Territories",
    id: 247,
    isoCode: "TF",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Holy See",
    id: 95,
    isoCode: "VA",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Mayotte",
    id: 135,
    isoCode: "YT",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Saint Pierre and Miquelon",
    id: 183,
    isoCode: "PM",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "Saint-Martin (French part)",
    id: 182,
    isoCode: "MF",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 2,
        name: "Euro",
        code: "EUR",
        numericCode: "978",
      },
    ],
  },
  {
    name: "American Samoa",
    id: 5,
    isoCode: "AS",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Netherlands Antilles",
    id: 27,
    isoCode: "AN",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 6,
        name: "Netherlands Antillean Guilder",
        code: "ANG",
        numericCode: "532",
      },
    ],
  },
  {
    name: "British Virgin Islands",
    id: 31,
    isoCode: "VG",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Ecuador",
    id: 64,
    isoCode: "EC",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "El Salvador",
    id: 66,
    isoCode: "SV",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Guam",
    id: 88,
    isoCode: "GU",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Marshall Islands",
    id: 131,
    isoCode: "MH",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Micronesia",
    id: 137,
    isoCode: "FM",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Northern Mariana Islands",
    id: 156,
    isoCode: "MP",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Palau",
    id: 161,
    isoCode: "PW",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Puerto Rico",
    id: 170,
    isoCode: "PR",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Timor-Leste",
    id: 213,
    isoCode: "TL",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Turks and Caicos Islands",
    id: 221,
    isoCode: "TC",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "United States Minor Outlying Islands",
    id: 249,
    isoCode: "UM",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "US Virgin Islands",
    id: 229,
    isoCode: "VI",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Zimbabwe",
    id: 239,
    isoCode: "ZW",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Bouvet Island",
    id: 241,
    isoCode: "BV",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 87,
        name: "Norwegian Krone",
        code: "NOK",
        numericCode: "578",
      },
    ],
  },
  {
    name: "Afghanistan",
    id: 1,
    isoCode: "AF",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Falkland Islands",
    id: 72,
    isoCode: "FK",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Iraq",
    id: 102,
    isoCode: "IQ",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Liberia",
    id: 120,
    isoCode: "LR",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Libya",
    id: 121,
    isoCode: "LY",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Mongolia",
    id: 139,
    isoCode: "MN",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Somalia",
    id: 198,
    isoCode: "SO",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Sudan",
    id: 203,
    isoCode: "SD",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Syria",
    id: 209,
    isoCode: "SY",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Tajikistan",
    id: 210,
    isoCode: "TJ",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Turkmenistan",
    id: 220,
    isoCode: "TM",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Aruba",
    id: 12,
    isoCode: "AW",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Democratic Republic of the Congo",
    id: 59,
    isoCode: "CD",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Belarus",
    id: 20,
    isoCode: "BY",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Saint Helena",
    id: 179,
    isoCode: "SH",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "South Sudan",
    id: 200,
    isoCode: "SS",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Venezuela",
    id: 233,
    isoCode: "VE",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
    ],
  },
  {
    name: "Western Sahara",
    id: 236,
    isoCode: "EH",
    currencies: [
      {
        id: 1,
        name: "US Dollar",
        code: "USD",
        numericCode: "840",
      },
      {
        id: 73,
        name: "Moroccan Dirham",
        code: "MAD",
        numericCode: "504",
      },
      {
        id: 37,
        name: "Algerian Dinar",
        code: "DZD",
        numericCode: "012",
      },
      {
        id: 77,
        name: "Ouguiya",
        code: "MRO",
        numericCode: "478",
      },
    ],
  },
];

export const COUNTRY_IDS = {
  USA: '228',
  CANADA: '38'
}
