import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const ErrorProvider = ({ render, errors, renderElement = false }) => {
  return errors != null ? (
    <React.Fragment>{render(errors)}</React.Fragment>
  ) : renderElement ? (
    <React.Fragment>{render(errors)}</React.Fragment>
  ) : null;
};

ErrorProvider.propTypes = {
  render: PropTypes.any,
  errors: PropTypes.any,
  renderElement: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    errors: state.errors,
  };
};

export default connect(mapStateToProps)(ErrorProvider);
