import React from "react";
import { ERROR_TYPE } from "../constants/enums";
import { ROUTES } from "../constants/clientRoutes";
import { COMMON, PAGES } from "../constants/localization";
import { ERROR_CODES } from "../constants/errorCodes";
import FaceTecSDK from "./faceTecSDK";
const FaceTecSessionStatus = FaceTecSDK.FaceTecSessionStatus;

class ErrorService {
  static GetModalError(errors) {
    if (!errors) return null;
    let modalError = errors.find(
      (error) =>
        error.errorType == ERROR_TYPE.OK ||
        error.errorType == ERROR_TYPE.ContactSupport ||
        error.errorType == ERROR_TYPE.Dashboard ||
        error.errorType == ERROR_TYPE.Login ||
        error.errorType == ERROR_TYPE.ContactSupportAndDashboard ||
        error.errorType == ERROR_TYPE.ContactSupportAndLogin
    );
    return modalError ? modalError : null;
  }

  static GetScreenErrors(errors) {
    if (!errors) return null;
    let screenErrors = errors.filter(
      (error) => error.errorType == ERROR_TYPE.Screen
    );
    return screenErrors.length ? screenErrors : null;
  }

  static GetFieldErrors(errors) {
    if (!errors) return null;
    let fieldErrors = errors.filter(
      (error) => error.errorType == ERROR_TYPE.Field
    );
    return fieldErrors.length ? fieldErrors : null;
  }

  static GetErrorsByType(errors) {
    return {
      allErrors: errors,
      screenErrors: this.GetScreenErrors(errors),
      modalErrors: this.GetModalError(errors),
      fieldErrors: this.GetFieldErrors(errors),
    };
  }

  /**
   * Replaces the "contact support" text in the transfer lock message with a hyperlink.
   * @param {object} error - The error that we are formatting the raw error message of.
   */
  static FormatSupportLink(error) {
    let supportUrl = ROUTES.EXTERNAL.SUPPORT;
    switch (error.errorCode) {
      case ERROR_CODES.USER.ACCOUNT_LOCKED:
        {
          const contactLink = `<span><a class="support-link" href="${supportUrl}">contact support</a></span>`;
          let errorMessage = error.errorMessage.replace(
            "contact support",
            contactLink
          );
          return <span dangerouslySetInnerHTML={
            { __html: errorMessage }}
          />;
        }
      default:
        return error.errorMessage || error.message || error;
    }
  }

  /** Deconstructs error from FaceCheck responses in authentication flow and
   * returns an object used to display a standardized mesage back to the user
   * @param {object} error - The error returned from either the API or web client SDK
   */
  static GetFaceCheckError(error) {
    let { code, title, message } = "";

    if (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length > 0
      ) {
        code = error.response.data.errors[0].errorCode;
      } else if (
        (error.message && error.message === ERROR_CODES.COMMON.TIMEOUT) ||
        error.toString().indexOf("500") > -1 ||
        error.request !== undefined
      ) {
        code = ERROR_CODES.COMMON.TIMEOUT;
      }
    }

    if (code) {
      switch (code) {
        case ERROR_CODES.USER.ACCOUNT_LOCKED:
          title = COMMON.ACCOUNT_LOCKED;
          message = COMMON.ACCOUNT_LOCKED_CONTENT;
          break;
        case ERROR_CODES.FACECHECK.PROFILE_EXISTS:
          title = PAGES.FACECHECK.ERROR_PROFILE_EXISTS;
          break;
        case ERROR_CODES.FACECHECK.PROFILE_NOT_FOUND:
          //PAGES.FACECHECK.ERROR_UPLOAD;
          break;
        case ERROR_CODES.FACECHECK.IN_REVIEW:
          title = PAGES.FACECHECK.ERROR_UPLOAD;
          message = PAGES.FACECHECK.ERROR_CONTENT;
          break;
        case ERROR_CODES.FACECHECK.TOO_MANY_ATTEMPTS:
          title = PAGES.FACECHECK.ERROR_TRY_AGAIN;
          message = PAGES.FACECHECK.ERROR_RETRY;
          break;
        case ERROR_CODES.COMMON.TIMEOUT:
          title = PAGES.FACECHECK.ERROR_TIMEOUT;
          message = PAGES.FACECHECK.ERROR_TIMEOUT_CONTENT;
          break;
        default:
          code = ERROR_CODES.FACECHECK.GENERIC_SDK_ERROR;
          title = PAGES.AUTH.GENERIC_ERROR_MESSAGE;
          message = PAGES.FACECHECK.ERROR_RETRY;
          break;
      }
    } else if (error && error.status) {
      switch (error.status) {
        case FaceTecSessionStatus.SessionCompletedSuccessfully: //edge case if the timeout doesn't set a status
          code = ERROR_CODES.COMMON.TIMEOUT;
          title = PAGES.FACECHECK.ERROR_TIMEOUT;
          message = PAGES.FACECHECK.ERROR_TIMEOUT_CONTENT;
          break;
        case FaceTecSessionStatus.Timeout:
        case FaceTecSessionStatus.ContextSwitch:
        case FaceTecSessionStatus.UserCancelled:
        case FaceTecSessionStatus.UserCancelledFromNewUserGuidance:
        case FaceTecSessionStatus.UserCancelledFromRetryGuidance:
        case FaceTecSessionStatus.UserCancelledViaClickableReadyScreenSubtext:
        case FaceTecSessionStatus.UserCancelledWhenAttemptingToGetCameraPermissions:
          error = null;
          // ignore user action to cancel
          break;
        default:
          code = ERROR_CODES.FACECHECK.GENERIC_SDK_ERROR;
          title = PAGES.AUTH.GENERIC_ERROR_MESSAGE;
          message = PAGES.FACECHECK.ERROR_RETRY;
          break;
      }
    } else {
      code = ERROR_CODES.FACECHECK.GENERIC_SDK_ERROR;
      title = PAGES.AUTH.GENERIC_ERROR_MESSAGE;
      message = PAGES.FACECHECK.ERROR_RETRY;
    }

    return { error, code, title, message };
  }
}

export default ErrorService;