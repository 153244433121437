import React from "react";
import { BankAccount } from "../../types/api/odt";
import i18n from "../../i18n/config";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { UpdateDestinationInfo } from "../../actions/onDemandTransfer";
import { ROUTES } from "../../constants/clientRoutes";
import { BANK_ACCOUNT_STATUS, ACTION_DISPLAY_TYPES, BANK_ACCOUNT_CARD_TYPES } from "../../constants/enums";
import { BankAccountCardType } from "../../types/components";
import { BankAccountDeleted, SetBankAccountDisbursementHistory, ShowDeleteModal } from "../../actions/bankAccounts";
import ENV from "../../constants/environment";

import Action from "./Action";
import BankAccountsApi from "../../api/bankAccounts";
import useErrorModal from "../../hooks/useErrorModal";
import { GetDisbursementHistoryResponse } from "../../types/api/bankAccounts";

type DestinationData = {
  destinationCountryName: string;
  destinationCountryId: string;
}

const BankAccountCardActions = ({ bank, type, destinationData }: { bank: BankAccount, type: BankAccountCardType, destinationData?: DestinationData }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const openErrorModal = useErrorModal();
  const { destinationCountryName, destinationCountryId } = destinationData || {};

  const handleSelectBank = () => {
    const payload = {
      allocationId: String(bank.allocationId),
      destinationCurrency: bank.destinationCurrencyCode,
      destinationCountryIso: bank.destinationCountryIso,
      destinationCountryName: destinationCountryName,
      destinationCountryId: destinationCountryId,
      isPOPRequired: bank.isPOPRequired,
      isPinRequired: bank.isPinRequired,
      isDoddFrank: bank.isDoddFrank,
      bankAccountFeeBanner: bank.feeBanner
    };
    dispatch(UpdateDestinationInfo(payload));

    history.push(ROUTES.ODT.AMT_PURPOSE);
  };

  const handleViewHistory = async () => {
    try {
      const response = await BankAccountsApi.GetDisbursementHistory(bank.allocationId) as GetDisbursementHistoryResponse;
      dispatch(SetBankAccountDisbursementHistory({
        showDisbursementHistory: true,
        bankAccountName: bank.accountName,
        bankAccountNumber: bank.accountNumber,
        disbursementHistory: response.result
      }));
    } catch (err) {
      openErrorModal(err);
    }
  }

  const handleDeleteBank = () => {
    dispatch(BankAccountDeleted(false));
    dispatch(ShowDeleteModal(bank.allocationId, true));
  }

  return (
    <div className="grid">

      <div className="flex-row justify-content-end row-1">
        {/* Edit */}
        {bank.canEdit && type === BANK_ACCOUNT_CARD_TYPES.BANK_ACCOUNT &&
          <Action
            displayType={ACTION_DISPLAY_TYPES.SECONDARY}
            title={i18n.t("COMMON.EDIT")}
            small={true}
            onClick={() => location.href = `${ENV.BASE_NAVIGATOR_URL}${ROUTES.BANKS_ACCOUNTS.REVIEW}`}
          />
        }

        {/* View History */}
        {bank.bankAccountStatus === BANK_ACCOUNT_STATUS.AVAILABLE && type === BANK_ACCOUNT_CARD_TYPES.BANK_ACCOUNT &&
          <Action
            displayType={ACTION_DISPLAY_TYPES.SECONDARY}
            className="spacing-left-tiny"
            title={i18n.t("COMMON.VIEW_HISTORY")}
            small={true}
            onClick={() => handleViewHistory()}
          />
        }

        {/* Select Bank (for odt) */}
        {bank.bankAccountStatus === BANK_ACCOUNT_STATUS.AVAILABLE && type === BANK_ACCOUNT_CARD_TYPES.ODT &&
          <Action
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            title={i18n.t("COMMON.SELECT")}
            small={true}
            onClick={() => handleSelectBank()}
          />
        }

        {/* Fix Bank Account */}
        {bank.bankAccountStatus === BANK_ACCOUNT_STATUS.INCOMPLETE &&
          <Action
            displayType={ACTION_DISPLAY_TYPES.SECONDARY}
            title={i18n.t("COMMON.FIX")}
            small={true}
            onClick={() => location.href = `${ENV.BASE_NAVIGATOR_URL}${ROUTES.BANKS_ACCOUNTS.REVIEW}`}
          />
        }

        {/* Under Review */}
        {bank.bankAccountStatus === BANK_ACCOUNT_STATUS.UNDER_REVIEW &&
          <p>{i18n.t("PAGES.ODT.BANK_SELECTION.ACCOUNT_UNDER_REVIEW_LABEL")}</p>
        }

      </div>

      {/* Bank account is availabe and is Bank Account page */}
      {bank.bankAccountStatus === BANK_ACCOUNT_STATUS.AVAILABLE && type === BANK_ACCOUNT_CARD_TYPES.BANK_ACCOUNT &&
        <div className="flex-row justify-content-end row-2">
          <Action
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            className="no-styling"
            title={i18n.t("COMMON.DELETE")}
            small={true}
            onClick={() => handleDeleteBank()}
          />
        </div>
      }
    </div>

  );
}

export default BankAccountCardActions;