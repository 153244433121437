import * as React from 'react';
import { PaymentSummaryResponse } from '../../types/api/user';

const PaymentTable = ({ payments }: { payments: PaymentSummaryResponse }) => {
  return (
    <div className='padding-left-small padding-right-small spacing-bottom-small payments-body'>
      <div className='flex-row justify-content-evenly spacing-top-medium spacing-bottom-medium'>
        <p>Date: {payments.disbursementDate}</p>
        <p>{payments.paymentType}: {payments.totalAmount}</p>
      </div>

      <table className="table old-navigator-style">
        <thead>
          <tr>
            <th>Account</th>
            <th>Amount</th>
            <th>Fee</th>
            <th className='text-right'>Total</th>
          </tr>
        </thead>

        <tbody>

          {payments.paymentDisbursements.map(payment => (
            <tr key={payment.displayName}>
              <td>
                <p>{payment.displayName}</p>
                <p className='subName'>{payment.displaySubName}</p>
              </td>
              <td>{payment.endAmount}</td>
              <td>{payment.feeAmount}</td>
              <td className='text-right'>{payment.totalAmount}</td>
            </tr>
          ))}
        </tbody>
      </table>

    </div>
  );
}

export default PaymentTable;