import React from "react";
import UserApi from "../../api/user";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { COMMON, PAGES } from "../../constants/localization";
import PageBlock from "../../components/Layouts/PageBlock.js";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import moment from "moment";
import SecurityQuestionsContainer from "./SecurityQuestionsContainer";
import { getQueryVariableFromSearch } from "../../services/location";
import SecurityQuestionResetError from "./SecurityQuestionResetError";
import FlowScreen from "../../components/Layouts/FlowScreen";
import { ROUTES } from "../../constants/clientRoutes";

class ForgotSecurityQuestionContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      isExpired: false,
      errorMessage: "",
      errorTitle: "",
      sessionInfo: {},
      showGenericErrorModal: false,
      loading: false,
    };
  }

  /**
   * Initializes the session if not already initialized.
   */
  componentDidMount() {
    this.intializeSession();
  }

  /**
   * Gets the membership token from the URL.
   */
  getKeyFromUrl = () => {
    const { search } = this.props.location;
    const tokenValue = getQueryVariableFromSearch(search, "key");
    return tokenValue || "";
  };

  /**
   * Clean up before unmounting
   */
  componentWillUnmount() {
    // Clean up step - remove timeout when the component is unmounted.
    clearTimeout(this.turnOffSessionTimeout);
  }

  /**
   * Sets the Change password session to state.
   */
  setSessionInfo = (sessionInfo) => {
    this.setState({
      loading: false,
      sessionInfo: sessionInfo,
    });
    let milliseconds = moment.duration(sessionInfo.duration).asMilliseconds();
    this.turnOffSessionTimeout = setTimeout(() => {
      //set to loading immediately
      this.setState({ loading: true });

      //set session as expired
      let sessionInfo = this.state.sessionInfo;
      sessionInfo.isExpired = true;
      this.setState({
        hasError: true,
        sessionInfo: sessionInfo,
        errorTitle: PAGES.SECURITY_QUESTIONS.SESSION_EXPIRED_TITLE,
        errorMessage: PAGES.SECURITY_QUESTIONS.SESSION_EXPIRED_TEXT,
      });

      //remove loading screen after half a second
      setTimeout(() => this.setState({ loading: false }), 500);
    }, milliseconds);
  };

  /**
   * Initializes or retrieves the change password session.
   * If a session is available, it returns the token,
   * duration (how much time remaining in the session),
   * and the password validation rules.
   */
  intializeSession = async () => {
    this.setState({ loading: true });

    try {
      const key = this.getKeyFromUrl();
      let response = await UserApi.InitializeForgotSecurityQuestionSession(
        key
      );
      const { duration, token } = response;
      this.setSessionInfo({ token, duration });
    } catch (error) {
      let {
        data: { errors },
      } = error;
      if (error.status != 500) {
        this.setState({
          hasError: true,
          loading: false,
          isExpired: true,
          errorTitle: errors[0].errorTitle,
          errorMessage: errors[0].errorMessage,
        });
      } else {
        this.setState({
          hasError: true,
          showGenericErrorModal: true,
          loading: false,
        });
      }
    }
  };

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <FlowScreen
            flowTitle={PAGES.SECURITY_QUESTIONS.MASTER_CONTAINER_TITLE}
            className="security-questions-screen"
          >
            <PageBlock>
              <PageBlock.Loader loadingContent={this.state.loading}>
                <InputSkeletonGroup numInputs={3} />
              </PageBlock.Loader>
            </PageBlock>
          </FlowScreen>
        )}
        {!this.state.loading && this.state.hasError && (
          <FlowScreen
            flowTitle={PAGES.SECURITY_QUESTIONS.MASTER_CONTAINER_TITLE}
            className="security-questions-screen"
          >
            <SecurityQuestionResetError
              title={this.state.errorTitle}
              description={this.state.errorMessage}
              sessionInfo={this.state.sessionInfo}
            />
          </FlowScreen>
        )}
        {!this.state.loading && !this.state.hasError && (
          <SecurityQuestionsContainer
            location={this.props.location}
            sessionToken={this.state.sessionInfo.token}
            successButtonLabel={COMMON.BACK_TO_LOGIN}
            successButtonUrl={ROUTES.EXTERNAL.LOGIN}
          />
        )}
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </React.Fragment>
    );
  }
}

ForgotSecurityQuestionContainer.propTypes = {
  location: PropTypes.object,
  loading: PropTypes.bool,
};

export default connect()(ForgotSecurityQuestionContainer);
