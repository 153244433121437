/* eslint-disable no-console */
import qs from "qs";
import Cookies from "universal-cookie";
import ENV from "../constants/environment";
import {
  ROUTES as API_ROUTES,
  HEADERS,
  PASSWORD_ACCESS_TOKEN,
  AUTH_TOKEN_GRANT_TYPES,
} from "../constants/api";
import { HandleNewResponse, HandleNewErrorResponse } from "./handlers";
import querystring from "querystring";
import moment from "moment";
import { postAnonymous, get, post } from "./api";
import { ROUTES } from "../constants/clientRoutes";
import {
  browserName,
  osVersion,
  osName,
  fullBrowserVersion,
  mobileVendor,
  mobileModel,
  getUA,
  deviceType,
} from "react-device-detect";
import FaceTecSDK from "../services/faceTecSDK";

/**
 * Access tokens are saved in the cookie as a string in the format:
 * access_token|refresh_token|expires_in_seconds
 */
export const parseAuthToken = (token) => {
  const tokenParts = token.split("|");

  /// There are technically 4 parts but the 4th part is for
  /// development only and we don't want to cause failures because of that.
  if (tokenParts.length < 3) {
    throw "Invalid authentication token format";
  }

  const accessToken = tokenParts[0];
  const refreshToken = tokenParts[1];
  const expiresInSeconds = tokenParts[2];

  /// When logging in through the dev login page we need to use a different
  /// Auth client to generate the token than when it is delegated through Navigator.
  /// This should go away when we login through the react app.
  const grantType = tokenParts[3];

  const expirationDateTime = moment().add(expiresInSeconds, "seconds").toDate();

  return {
    accessToken: accessToken,
    expirationDateTime: expirationDateTime,
    refreshToken: refreshToken,
    grantType: grantType,
  };
};

/**
 * Converts an API auth response to a token.
 */
export const authTokenFromResponse = (tokenResponse) => {
  const expirationDateTime = moment()
    .add(tokenResponse.expires_in || tokenResponse.expires_In, "seconds")
    .toDate();

  return {
    accessToken: tokenResponse.access_token || tokenResponse.access_Token,
    refreshToken: tokenResponse.refresh_token || tokenResponse.refresh_Token,
    expirationDateTime: expirationDateTime,
  };
};

/**
 * Class with static methods to interact with the authentication api
 */
class AuthApi {
  /**
   * Loads the auth token from a cookie
   * @returns {object} The token. Returns null if the cookie is missing.
   */
  static getAuthToken = () => {
    const cookie = new Cookies();
    let tokenCookie = cookie.get(ENV.TOKEN_COOKIE);

    if (tokenCookie) {
      let token = parseAuthToken(tokenCookie);
      return token;
    }
  };

  /**
   * Stores the token in a cookie.
   * NOTE: This should only be used by the REACT login page.
   * In higher environments, the token is created by Navigator and attached to the redirect.
   * @param {string} token
   */
  static saveAuthToken(token) {
    const cookie = new Cookies();
    let expiresInSeconds = moment
      .duration(moment(token.expirationDateTime).diff(moment()))
      .asSeconds();
    /// This is the same format used when the token is generated in Navigator
    /// Bwp.Navigator/NavigatorAuthenticationService
    let tokenString = `${token.accessToken}|${token.refreshToken}|${expiresInSeconds}|${token.grantType}`;
    cookie.set(ENV.TOKEN_COOKIE, tokenString, {
      path: "/",
      maxAge: expiresInSeconds.toString() * 2,
    });
  }

  /**
   * Get's necessary headers and appends the Authorization header
   * @returns {object} Headers
   */
  static getAuthorizationHeaders() {
    let headers = Object.assign({}, HEADERS);
    let token = this.getAuthToken();

    if (token) {
      headers["Authorization"] = `Bearer ${token.accessToken}`;
    }
    return headers;
  }

  /**
   * Removes the token from the auth cookie
   * @returns {Promise<void>}
   * @constructor
   */
  static async Logout() {
    const cookie = new Cookies();
    cookie.remove(ENV.TOKEN_COOKIE);
  }

  /**
   * Authorizes a user and sets the token to the auth cookie
   * @param username
   * @param password
   * @returns {Promise<*>}
   */
  static async authorizeToken(username, password) {
    const PLATFORM_WEB = 3;
    const APP_VERSION = 1;

    let payload = {
      username,
      password,
      scope: PASSWORD_ACCESS_TOKEN.SCOPE,
      client_id: PASSWORD_ACCESS_TOKEN.CLIENT_ID,
      client_secret: PASSWORD_ACCESS_TOKEN.CLIENT_SECRET,
      grant_type: PASSWORD_ACCESS_TOKEN.GRANT_TYPE.PASSWORD,
      DeviceInfo_PlatformId: PLATFORM_WEB,
      DeviceInfo_AppVersion: APP_VERSION,
      DeviceInfo_OsVersion: osVersion,
      DeviceInfo_OsName: osName,
      DeviceInfo_FullBrowserVersion: fullBrowserVersion,
      DeviceInfo_BrowserName: browserName,
      DeviceInfo_Vendor: mobileVendor,
      DeviceInfo_Model: mobileModel,
      DeviceInfo_UserAgent: getUA,
      DeviceInfo_Type: deviceType,
    };

    let response = await postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_TOKEN,
      querystring.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
    );

    let tokenResponse = response.data;

    let newToken = authTokenFromResponse(tokenResponse);
    newToken.grantType = AUTH_TOKEN_GRANT_TYPES.PASSWORD;
    this.saveAuthToken(newToken);

    return newToken;
  }

  /**
   * Refreshes the auth token and stores it in the cookie.
   * @returns {Promise<*>}
   */
  static async refreshAuthToken() {
    let authToken = this.getAuthToken();
    const refreshToken = authToken.refreshToken;

    let accessTokenDetails = PASSWORD_ACCESS_TOKEN;

    let payload = {
      refresh_token: refreshToken,
      scope: accessTokenDetails.SCOPE,
      client_id: accessTokenDetails.CLIENT_ID,
      client_secret: accessTokenDetails.CLIENT_SECRET,
      grant_type: accessTokenDetails.GRANT_TYPE.REFRESH_TOKEN,
    };

    let response = await postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_TOKEN,
      querystring.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
    );

    let tokenResponse = response.data;
    let newToken = authTokenFromResponse(tokenResponse);
    newToken.grantType = authToken.grantType;
    this.saveAuthToken(newToken);

    return newToken;
  }

  /**
   * Authorizes a user token for login flow
   * @param username
   * @returns {Promise<*>}
   */
  static async authorizeLoginToken(username) {
    const PLATFORM_WEB = 3;
    const APP_VERSION = 1;

    let payload = {
      username,
      DeviceInfo_PlatformId: PLATFORM_WEB,
      DeviceInfo_AppVersion: APP_VERSION,
      DeviceInfo_OsVersion: osVersion,
      DeviceInfo_OsName: osName,
      DeviceInfo_FullBrowserVersion: fullBrowserVersion,
      DeviceInfo_BrowserName: browserName,
      DeviceInfo_Vendor: mobileVendor,
      DeviceInfo_Model: mobileModel,
      DeviceInfo_UserAgent: getUA,
      DeviceInfo_Type: deviceType
    };

    return postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_LOGIN_TOKEN,
      qs.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
    ).then((response) => response.data);
  }

  /**
   * Authorizes a user using password
   * @param username
   * @returns {Promise<*>}
   */
  static async authorizeLoginPassword(password, token) {
    const payload = {
      password,
      token,
    };

    return postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_LOGIN_PASSWORD,
      qs.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
    ).then((response) => response.data);
  }

  static async authorizeFacecheckEnrollment(payload) {
    const xUserAgent = FaceTecSDK.createFaceTecAPIUserAgentString();

    return post(API_ROUTES.AUTH.AUTHORIZE_FACECHECK_ENROLLMENT, payload, {
      "X-User-Agent": xUserAgent
    }).then(
      (response) => response.data
    );
  }

  static async loginFacecheckEnrollment(faceCheckPayload, token) {
    const xUserAgent = FaceTecSDK.createFaceTecAPIUserAgentString();

    const payload = {
      ...faceCheckPayload,
      token,
    };

    return postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_FACECHECK,
      qs.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "X-User-Agent": xUserAgent
      }
    ).then((response) => response.data);
  }

  static async getFacecheckEnrollmentToken() {
    return get(API_ROUTES.AUTH.AUTHORIZE_FACECHECK_ENROLLMENT).then(
      (response) => response.data
    );
  }

  /**
   * Authorizes a user using a security question
   * @param username
   * @returns {Promise<*>}
   */
  static async authorizeSecurityQuestion(answer, questionId, token) {
    const payload = {
      answer,
      questionId,
      token,
    };

    return postAnonymous(
      API_ROUTES.AUTH.AUTHORIZE_LOGIN_SECURITY_QUESTION,
      qs.stringify(payload),
      {
        "Content-Type": "application/x-www-form-urlencoded",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      }
    ).then((response) => response.data);
  }

  static async navigatorKeepAlive() {
    post(ROUTES.EXTERNAL.SESSION_KEEP_ALIVE);
  }

  /**
   * Check's for a user's logged in state.
   * @returns {string}
   * @constructor
   */
  static IsAuthenticated() {
    return this.getAuthToken();
  }

  /**
   * Gets TOTP data
   */
  static async getTOTPSetupData() {
    let url = API_ROUTES.AUTH.GET_TOTP_SETUP;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * POST auth app code
   */
  static async postTOTPSetupCode(passcode) {
    let url = API_ROUTES.AUTH.POST_TOTP_SETUP;
    try {
      let response = await post(url, { passcode });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * POST disable TOTP
   */
  static async disbleTOTP() {
    let url = API_ROUTES.AUTH.DISABLE_TOTP;
    try {
      let response = await post(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Authorizes a user using totp
   * @param passcode
   * @param token
   */
  static async authorizeLoginTotp(passcode, token) {
    const payload = {
      passcode,
      token,
    };

    try {
      const response = await postAnonymous(
        API_ROUTES.AUTH.VERIFY_TOTP,
        qs.stringify(payload),
        {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        }
      );
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }
}

export default AuthApi;
