import React from "react";
import PropTypes from "prop-types";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockBody = ({ children, loadingContent, id }) =>
  children && !loadingContent ? (
    <div
      className="flow-page-block__component"
      id={id}
      data-cy={CyBuilder("page-block-body", id)}
    >
      {children}
    </div>
  ) : null;

PageBlockBody.propTypes = {
  children: PropTypes.any,
  loadingContent: PropTypes.bool,
  id: PropTypes.string,
};

export default PageBlockBody;
