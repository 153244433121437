import React from "react";
import PageBlock from "../../Layouts/PageBlock";
import PropTypes from "prop-types";

const Image = (props) => {
  return <div className="flow-success__image">{props.children}</div>;
};

Image.propTypes = {
  children: PropTypes.any,
};

const Title = (props) => {
  return <PageBlock.Title>{props.children}</PageBlock.Title>;
};

Title.propTypes = {
  children: PropTypes.node,
};

const Body = (props) => {
  return <PageBlock.Body>{props.children}</PageBlock.Body>;
};

Body.propTypes = {
  children: PropTypes.node,
};

const ActionRow = (props) => {
  return <div className="flow-success__action-row">{props.children}</div>;
};

ActionRow.propTypes = {
  children: PropTypes.any,
};

const FlowSuccess = (props) => {
  return (
    <div className="flow-success">
      <PageBlock id={props.id}>{props.children}</PageBlock>
    </div>
  );
};

FlowSuccess.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string,
};

FlowSuccess.Title = Title;
FlowSuccess.Body = Body;
FlowSuccess.ActionRow = ActionRow;
FlowSuccess.Image = Image;

export default FlowSuccess;
