import React from "react";
import Select from "react-select";
import PropTypes from "prop-types";
import { DROPDOWN_STYLES } from "../../../constants/theme";
import moment from "moment";
import IntegerInput from "../IntegerInput";
import { COMMON } from "../../../constants/localization";

/**
 * Renders a set of dropdown fields for day/month/year
 * Should be used as a component of a Redux form Field component.
 */
class DateField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      month: this.props.input.value
        ? moment(this.props.input.value).month()
        : null,
      day: this.props.input.value
        ? moment(this.props.input.value).date()
        : null,
      year: this.props.input.value
        ? moment(this.props.input.value).year()
        : null,
      displayDay:
        this.props.displayDay !== undefined ? this.props.displayDay : true,
    };

    this.handleMonthChange = this.handleMonthChange.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
    this.handleYearBlur = this.handleYearBlur.bind(this);
    this.updateDate = this.updateDate.bind(this);
  }

  /**
   * Event handler for the month dropdown changing.
   * Adds the selected month to the state and updates the uunderlying form value.
   * @param {object} option The dropdown option in the format { label: "AAA", value: "BBB" }
   */
  handleMonthChange(option) {
    this.setState({ month: option.value }, this.updateDate);
  }

  /**
   * Event handler for the day dropdown changing.
   * Adds the selected day to the state and updates the uunderlying form value.
   * @param {object} event The input change event object
   */
  handleDayChange(e) {
    this.setState({ day: e.target.value }, this.updateDate);
  }

  /**
   * Event handler for the year input changing.
   * Adds the selected year to the state and updates the uunderlying form value.
   * @param {object} event The input change event object
   */
  handleYearChange(e) {
    this.setState({ year: e.target.value }, this.updateDate);
  }

  /**
   * Event handler for the year input losing focus.
   * Triggers the blur event for the underlying
   * input to ensure the redux form is updated.
   */
  handleYearBlur() {
    this.props.input.onBlur();
  }

  /**
   * Updates the underlying form value with the three selected date components.
   */
  updateDate() {
    /// If the selected month is January the value is 0 which is "falsy"
    /// so the first if statement will always be true if January is
    /// chosen as it's a 0 index array and January is the first value.
    if (
      !this.state.year ||
      this.state.month == null ||
      (!this.state.day && this.state.displayDay)
    ) {
      this.props.input.onChange(null);
    } else {
      /// NOTE: Uses strict parsing for moment. The reason is that Jan 33 2000
      /// still parses as a valid date of Feb 2 2000. We actually want it to
      /// fail parsing so that we can trigger invalid date error.
      let date = moment(
        `${this.state.year}-${this.state.month + 1}-${this.state.day}`,
        "YYYY-M-D",
        true
      ).startOf("day");

      if (!this.state.displayDay) {
        date = moment(
          `${this.state.year}-${this.state.month + 1}-01`,
          "YYYY-M-D",
          true
        ).startOf("day");
      }

      this.props.input.onChange(date.toDate());
    }
  }

  /**
   * Lifecycle method for rendering the component.
   */
  render() {
    let months = [];
    for (let month = 0; month <= 11; month++) {
      months.push({
        label: moment().month(month).format("MMMM"),
        value: month,
      });
    }

    return (
      <div className="date-field">
        <div data-cy="month-date-selection">
          <label className="date-field__label">{COMMON.MONTH}</label>
          <Select
            styles={DROPDOWN_STYLES}
            menuPlacement="auto"
            id="MonthDateSelection"
            className="data-month-selector"
            onChange={this.handleMonthChange}
            value={
              this.state.month !== null && this.state.month !== undefined
                ? months.filter((o) => o.value == this.state.month)[0]
                : null
            }
            options={months}
          />
        </div>
        {this.state.displayDay && (
          <div>
            <label className="date-field__label">{COMMON.DAY}</label>
            <IntegerInput
              value={this.state.day != null ? this.state.day : ""}
              placeholder="xx"
              onChange={this.handleDayChange}
              className="form-control"
              maxLength="2"
              data-cy="day-date-selection"
              data-testid="day-date-selection"
            />
          </div>
        )}
        <div>
          <label className="date-field__label">{COMMON.YEAR}</label>
          <IntegerInput
            value={this.state.year != null ? this.state.year : ""}
            placeholder="xxxx"
            onChange={this.handleYearChange}
            onBlur={this.handleYearBlur}
            className="form-control"
            maxLength="4"
            data-cy="year-date-selection"
          />
        </div>
      </div>
    );
  }
}

DateField.propTypes = {
  /** The underlying input behind the field */
  input: PropTypes.object,
  /** Allow day field to be hidden and only display Month and Year */
  displayDay: PropTypes.bool,
};

export default DateField;
