import ENV from "./environment";
import { USER_PROFILE_FIELDS } from "./enums";

let oauthBaseUrl = ENV.BASE_OAUTH_URL;
let apiBase = ENV.BASE_API_URL;

export const ROUTES = {
  CATALOG: {
    COUNTRIES: `${apiBase}/catalog/countries`,
    STATE_PROVINCES: (countryIso) =>
      `${apiBase}/catalog/countries/${countryIso}/stateprovinces`,
    GET_PASSWORD_QUESTIONS: `${apiBase}/catalog/securityquestions`,
  },
  DASHBOARD: {
    GET_NEWS: `${apiBase}/Dashboard/GetNewsAsync`,
    GET_CARD_BALANCE: `${apiBase}/MyCard/GetCardBalance`,
    GET_QUICK_LINKS: `${apiBase}/Dashboard/QuickLinksAsync`,
    GET_MENU: `${apiBase}/Menu/GetMenuAsync`
  },
  AUTH: {
    INTROSPECT: `${oauthBaseUrl}/connect/introspect`,
    AUTHORIZE_TOKEN: `${oauthBaseUrl}/connect/token`,
    AUTHORIZE_LOGIN_TOKEN: `${apiBase}/auth/username`,
    AUTHORIZE_LOGIN_PASSWORD: `${apiBase}/auth/password`,
    AUTHORIZE_FACECHECK: `${apiBase}/auth/facecheck`,
    AUTHORIZE_FACECHECK_ENROLLMENT: `${apiBase}/auth/facecheckenrollment`,
    AUTHORIZE_FACECHECK_ENROLLMENT_TOKEN: `${apiBase}/auth/facecheck/token`,
    AUTHORIZE_LOGIN_SECURITY_QUESTION: `${apiBase}/auth/securityquestion`,
    GET_TOTP_SETUP: `${apiBase}/auth/totpsetup`,
    POST_TOTP_SETUP: `${apiBase}/auth/totpsetup`,
    DISABLE_TOTP: `${apiBase}/auth/disabletotp`,
    VERIFY_TOTP: `${apiBase}/auth/totp`,
  },
  USER: {
    FORGOT_USERNAME: `${apiBase}/users/username`,
    FORGOT_PASSWORD_PERSONAL_INFO: `${apiBase}/user/passwordresetidentity`,
    GET_PERSONAL_INFO_FIELDS: (username) =>
      `${apiBase}/user/passwordresetidentityV2?username=${username}`,
    SEND_PASSWORD_RESET_EMAIL: `${apiBase}/user/passwordresetemail`,
    PROFILE: `${apiBase}/users`,
    INFO: `${oauthBaseUrl}/connect/userinfo`,
    PIN_CHECK: `${apiBase}/cards/pincheck`,
    FEATURE_FLAGS: `${apiBase}/apps/user`,
    UPDATE_PASSWORD_QUESTIONS: `${apiBase}/user/passwordquestionsV2`,
    RESET_PASSWORD_QUESTIONS: `${apiBase}/user/resetpasswordquestions`,
    RESET_PASSWORD: `${apiBase}/user/resetpassword`,
    CHANGE_PASSWORD: `${apiBase}/user/changepassword`,
    GET_USER_PASSWORD_QUESTIONS: (userName) => {
      const includeUsername = userName ? `?Username=${userName}` : ``;
      return `${apiBase}/user/passwordquestions/${includeUsername}`;
    },
    GET_TRANSFER_LOCK_STATUS: `${apiBase}/user/transferlockstatus`,
    INITIALIZE_FORGOT_SECURITY_QUESTION_SESSION: `${apiBase}/user/forgotsecurityquestionsession`,
    INITIALIZE_CHANGE_PASSWORD_SESSION: `${apiBase}/user/changepasswordsession`,
    INITIALIZE_CHANGE_CURRENT_PASSWORD_SESSION: `${apiBase}/user/changecurrentpasswordsession`,
    GET_NEWS: `${apiBase}/user/news`,
    GET_USER_DOCUMENTS: `${apiBase}/documents`,
    GET_MENU: `${apiBase}/user/menu`,
    GET_FOOTER: `${apiBase}/user/footer`,
    GET_PAYMENT_SUMMARY: `${apiBase}/users/paymentsummary`,
    COMMUNICATION_PREFERENCES: `${apiBase}/user/communicationpreferences`,
    REVIEW_AND_ACCEPT_DOCS: `${apiBase}/user/reviewandacceptdocs`,
  },
  CARD: {
    CARDS: `${apiBase}/cards`,
    PIN_CHECK: `${apiBase}/cards/pincheck`,
    VERIFY_CURRENT_PIN: (cardId) => `${apiBase}/card/${cardId}/pin/workflow`,
    GET_PIN_RESET_WORKFLOW: `${apiBase}/card//pin/workflow`,
    VALIDATE_PIN_WORKFLOW: (cardId) => `${apiBase}/card/${cardId}/pin/workflow`,
    CHANGE_PIN: (cardId) => `${apiBase}/card/${cardId}/pin`,
    TRANSACTION_HISTORY: (cardId, skip, take) => `${apiBase}/cards/${cardId}/transactions?skip=${skip}&take=${take}`,
    MASTERCARD_CURRENCIES: `${apiBase}/mastercardcurrencies`,
    MASTERCARD_RATE_SUMMARY: `${apiBase}/mastercardfxratesummary`,
    LOCK_CARD: `${apiBase}/card/lock`,
    UNLOCK_CARD: `${apiBase}/card/unlock`,
    CARD_LOCK_FAQS: `${apiBase}/card/lockfaqs`,
    PROCESSOR_AVAILABILITY: `${apiBase}/processoravailability`,
    ACTIVATE_REPLACEMENT_CARD: `${apiBase}/card/activateorenroll`,
    GET_WESTERN_UNION_DOCS: `${apiBase}/documents/westernunion`,
    GET_REDIRECT_URL: `${apiBase}/cards/sendlinktowesternunion`,
    GET_RECIPIENTS: `${apiBase}/recipients`,
    ADD_RECIPIENT: `${apiBase}/recipients`,
    DELETE_RECIPIENT: (recipientId) => `${apiBase}/recipients/${recipientId}`,
    CARD_TO_CARD_TRANSFER: (cardId) => `${apiBase}/cards/${cardId}/transactions`,
    COMPANION_CARD: `${apiBase}/card/companioncard`,
    GET_PUSH_TO_CARD_TRANSFER_DETAILS: (transferId) => `${apiBase}/pushtocard/transactions/${transferId}`,
    GET_RR_SDK_TRANSFER_DETAILS: (transferId, transferMethod) => `${apiBase}/readyremit-sdk/transfer/${transferId}?transferMethod=${transferMethod}`,
    GET_CARD_IMAGE: `${apiBase}/card/cardimage`,
    GET_CARD_STATEMENTS: (cardId) => `${apiBase}/card/statements/${cardId}`,
    GET_CARD_STATEMENT_PDF: (cardId, statementId) => `${apiBase}/card/statements/${cardId}/${statementId}`,
  },
  CASH_PICKUP: {
    GET_RATES: `${apiBase}/cashpickup/rates`,
    POST_RATE: `${apiBase}/cashpickup/rate`,
    TRANSACTIONS: `${apiBase}/cashpickup`,
    GET_PROVIDER_DESTINATIONS: `${apiBase}/cashpickup/destinations`,
    GET_PROVIDERS_RATES: (countryCode, currencyCode, amount) =>
      `${apiBase}/cashpickup/${countryCode}/providers/${currencyCode}/${amount}`,
    GET_PAYER_CITIES: `${apiBase}/cashpickup/cities`,
    GET_PAYERS: (
      countryId,
      cityId,
      destinationCurrencyId,
      sourceCurrencyId
    ) => {
      return `${apiBase}/cashpickup/payers?providerId=4&countryid=${countryId}&cityid=${cityId}&destinationcurrency=${destinationCurrencyId}&sourcecurrency=${sourceCurrencyId}`;
    },
  },
  MONEY_TRANSFER: {
    GET_AVAILABLE_COUNTRIES: `${apiBase}/moneytransfer/countries`,
    GET_AVAILABLE_PROVINCES: (isoCountryCode) => `${apiBase}/moneytransfer/provinces/${isoCountryCode}`,
    GET_AVAILABLE_CITIES: (isoCountryCode, provinceCode) => `${apiBase}/moneytransfer/cities/${isoCountryCode}/${provinceCode}`,
    GET_PROVIDERS_RATES: (serviceType) => `${apiBase}/moneytransfer/providerrates?ServiceType=${serviceType}`,
    VALIDATE_PROMO_CODE: `${apiBase}/moneytransfer/validatePromo`,
    GET_WORKFLOW: (quoteId, serviceType) => `${apiBase}/moneytransfer/workflowv2/${quoteId}?ServiceType=${serviceType}`,
    GET_TERMS_AND_CONDITIONS: (quoteId) =>
      `${apiBase}/moneytransfer/termsandconditions/${quoteId}`,
    VALIDATE_WORKFLOW: (quoteId) =>
      `${apiBase}/moneytransfer/workflowv2/${quoteId}`,
    COMMIT: `${apiBase}/moneytransfer/commit`,
    GET_TRANSFER_DETAILS: (moneyTransferId, type) =>
      `${apiBase}/moneytransfer/transactions/${moneyTransferId}?serviceType=${type}`,
    RECEIPT: (moneyTransferId, serviceType) =>
      `${apiBase}/moneytransfer/${moneyTransferId}/receipt?serviceType=${serviceType}`,
    CANCEL_TRANSACTION: () =>
      `${apiBase}/moneytransfer/cancel`,
    GET_COUNTRIES: (serviceType) => `${apiBase}/moneytransfer/countries?ServiceType=${serviceType}`,
  },
  SUPPORT: {
    INDEX: `${apiBase}/support`,
    CATEGORIES: `${apiBase}/support/categories`,
    NEW_CONVERSATION: `${apiBase}/support/conversations`,
    POST_MESSAGE: (messageId) =>
      `${apiBase}/support/conversations/${messageId}/messages`,
    POST_ATTACHMENTS: (conversationId) =>
      `${apiBase}/support/conversations/${conversationId}/attachments`,
    POST_READ_CONVERSATION: (conversationId) =>
      `${apiBase}/support/conversations/${conversationId}/read`,
    POST_TICKET: `${apiBase}/support`,
  },
  WAGE_STATEMENTS: {
    GET_ALL: `${apiBase}/wagestatements`,
    GET_BY_ID: (id) => `${apiBase}/wagestatement/${id}`,
  },
  ENROLLMENT: {
    LOGIN: `${oauthBaseUrl}/connect/token`,
    SECURITY_QUESTIONS: `${apiBase}/catalog/securityQuestions`,
    GET_ALL_COUNTRIES: (forPhone) =>
      `${apiBase}/GetAllCountries/${forPhone}?format=json`,
    GET_SELECT_LIST_ITEMS: (url) => `${apiBase}/${url}`,
    GET_SUPPORTING_DOCS: (id, participantId) =>
      `${apiBase}/GetSupportingDocs/${id}?participantId=${participantId}&format=json`,
    GET_PASSWORD_RULES: `${apiBase}/user/passwordrules/`,
    GET_COMMUNICATION_PREFERENCES: (participantId) =>
      `${apiBase}/GetCommunicationPreferences/${participantId}`,
    GET_ENROLLMENT_DATA: (participantId) =>
      `${apiBase}/GetEmployeeData/${participantId}?format=json`,
    GET_FEATURE_FLAGS: (participantId) =>
      `${apiBase}/apps/enrollmentfeatures/${participantId}?format=json`,
    SAVE_USER_NAME_PASSWORD: `${apiBase}/SaveUserNamePassword`,
    SAVE_ACCOUNT_SECURITY_V3: `${apiBase}/SaveAccountSecurityV3`,
    SAVE_PERSONAL_DATA: `${apiBase}/SavePersonalData`,
    SAVE_CARD_DETAILS: `${apiBase}/SaveCardDetails`,
    COMPLETE_WORKFLOW: `${apiBase}/CompleteWorkflow`,
  },
  EXCHANGE_RATE: {
    CHECK_EXCHANGE_RATES: `${apiBase}/CheckExchangeRates`,
  },
  ON_DEMAND_TRANSFER: {
    GET_BANK_ACCOUNTS: `${apiBase}/odtbanks`,
    GET_PURPOSE_OF_PAYMENT: (countryId) => `${apiBase}/GetAllPurposeOfPayments?CountryId=${countryId}`,
    GET_RATE_INQUIRY: (cardCurrencyCode, destinationCurrencyCode, amount, allocationId) =>
      `${apiBase}/moneytransfer/rates/${cardCurrencyCode}/${destinationCurrencyCode}/${amount}/${allocationId}`,
    GET_ALLOCATION_DETAILS: (allocationId) =>
      `${apiBase}/banks/allocationdetails/${allocationId}`,
    GET_DODD_FRANK_DISBURSEMENT: `${apiBase}/moneytransfer/doddfrankdisbursement`,
    POST_TRANSFER_NON_DODD_FRANK: `${apiBase}/moneytransfer/nondoddfranktransfer`,
    POST_TRANSFER_DODD_FRANK: `${apiBase}/moneytransfer/doddfranktransfer`,
    GET_TRANSFERS_LIST: `${apiBase}/moneytransfer/odttransferslist`,
    GET_TRANSFER_DETAILS: (transferId) => `${apiBase}/moneytransfer/transfer/${transferId}`,
    CANCEL_TRANSFER: (transferId) => `${apiBase}/moneytransfer/canceltransfer/${transferId}`,
  },
  BANK_ACCOUNTS: {
    DELETE_BANK_ACCOUNT: (allocationId) => `${apiBase}/banks/${allocationId}`,
    GET_DISBURSEMENT_HISTORY: (allocationId) => `${apiBase}/banks/disbursementhistory/${allocationId}`,
    GET_COUNTRIES_CURRENCIES: (userGuid) => `${apiBase}/banks/countrycurrency?UserGuid=${userGuid}`,
  },
  LOG: `${apiBase}/log`
};

export let HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  "api-version": "1.0",
};

/**
 * Token that requires a username and password to authenticate.
 * This is used only in the dev environment by the login screen.
 */
export const PASSWORD_ACCESS_TOKEN = {
  SCOPE: "openid carddetails profile cardtransfers offline_access",
  CLIENT_ID: "Bwp.WebApi.NativeAppClient",
  CLIENT_SECRET:
    "d15335b6c2e22fbc1a5deeb4fcf25d243777d295f44b106cebd6808375d74f2b",
  GRANT_TYPE: {
    PASSWORD: "password",
    CLIENT: "client",
    REFRESH_TOKEN: "refresh_token",
  },
};

export let TRANSFAST_FIELDS = {
  SEND_AMOUNT: "sendAmount",
  RECEIVE_AMOUNT: "receiveAmount",
};

export let SUPPORT_STATUSES = {
  OPEN: 1,
  CLOSED: 2,
};

export let ERROR_CODES = {
  USER_PROFILE_EMAIL_ALREADY_IN_USE: "US005",
  USER_PROFILE_EMAIL_VALIDATION_ERROR: "US100",
  USER_PROFILE_POSTAL_VALIDATION_ERROR: "US101",
  USER_PROFILE_COUNTRY_VALIDATION_ERROR: "US102",
  USER_PROFILE_ADDRESS1_VALIDATION_ERROR: "US103",
  USER_PROFILE_ADDRESS2_VALIDATION_ERROR: "US104",
  USER_PROFILE_CITY_VALIDATION_ERROR: "US105",
  USER_PROFILE_PROVINCE_VALIDATION_ERROR: "US106",
  USER_PROFILE_PHONE_VALIDATION_ERROR: "US107",

  UNEXPECTED_ERROR: "RC999",
};

let errorCodesMap = {};
errorCodesMap[ERROR_CODES.USER_PROFILE_EMAIL_ALREADY_IN_USE] =
  USER_PROFILE_FIELDS.EMAIL;
errorCodesMap[ERROR_CODES.USER_PROFILE_EMAIL_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.EMAIL;
errorCodesMap[ERROR_CODES.USER_PROFILE_POSTAL_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.POSTAL_CODE;
errorCodesMap[ERROR_CODES.USER_PROFILE_COUNTRY_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.COUNTRY_ISO;
errorCodesMap[ERROR_CODES.USER_PROFILE_ADDRESS1_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.LINE_1;
errorCodesMap[ERROR_CODES.USER_PROFILE_ADDRESS2_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.LINE_2;
errorCodesMap[ERROR_CODES.USER_PROFILE_CITY_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.CITY;
errorCodesMap[ERROR_CODES.USER_PROFILE_PROVINCE_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.STATE_PROVINCE_NAME;
errorCodesMap[ERROR_CODES.USER_PROFILE_PHONE_VALIDATION_ERROR] =
  USER_PROFILE_FIELDS.PHONE_NUMBER;

export let ERROR_CODE_FIELDS_MAP = errorCodesMap;

export const HTTP_STATUS_CODES = {
  SUCCESS: 200,
  VALIDATION_ERROR: 226,
  BAD_REQUEST: 400,
  SERVER_ERROR: 500,
};

export const HTTP_REQUEST_METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  DELETE: "delete",
};

export const AUTH_TOKEN_GRANT_TYPES = {
  DELEGATION: "delegation",
  PASSWORD: "password",
};

export const AUTH = {
  // 10 minutes = 10 (minutes) * 60 (seconds) * 1000 (milliseconds)
  SESSION_TIMEOUT_MILLISECONDS: 600000,

  // 1 minute =  60 (seconds) * 1000 (milliseconds)
  SESSION_TIMEOUT_WARNING_BUFFER_MILLISECONDS: 60000,

  // 1 minute =  60 (seconds) * 1000 (milliseconds)
  TOKEN_REFRESH_BUFFER_MILLISECONDS: 60000,
  // 10 minutes for completing any login flow
  LOGIN_TIMEOUT_MILLISECONDS: 600000,
};