import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { FIELD_TYPES, ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import { COMMON, PAGES } from "../../../constants/localization";
import ENV from "../../../constants/environment";
import EnrollmentApi from "../../../api/enrollment";
import FlowScreen from "../../Layouts/FlowScreen";
import GenericForm from "../../Common/DynamicFields/GenericForm";
import PageBlock from "../../Layouts/PageBlock";
import ValidationSummary from "../ValidationSummary";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import ActionRow from "../../Layouts/ActionRow";
import DynamicInputField from "../../Common/DynamicFields/DynamicInputField";
import BooleanField from "../../Common/DynamicFields/BooleanField";
import Action from "../../Common/Action";
import { asyncValidate, validate } from "./ValidateCreateUserForm";
import DisclosureModal from "../DisclosureModal";
import { UpdateCurrentPage } from "../../../actions/enrollment";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";
import useClickExternalLink from "../../../hooks/useClickExternalLink";
import i18n from "../../../i18n/config";

const esignAgreementDocTypeId = 38;

const CreateUserForm = ({ valid, setShowSpinner }) => {
  const dispatch = useDispatch();
  const openExternalLinksModal = useClickExternalLink();
  const [errors, setErrors] = useState([]);
  const [docs, setDocs] = useState([]);
  const [passwordRules, setPasswordRules] = useState([]);
  const [esignAgreementUrl, setEsignAgreementUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [checkboxes, setCheckboxes] = useState({
    esignAgreement: {
      checked: false,
      touched: false,
    },
    disclosures: {
      checked: false,
      touched: false,
    },
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const formData = useSelector(
    (state) => state.form[PAGES.ENROLLMENT.CREATE_USER.FORM_NAME]
  );
  const userData = useSelector((state) => state.enrollment.userData);

  /**
   * Grab docs (esign agreement and disclosure docs) and password rules on initial render of component
   */
  useEffect(() => {
    if (userData) {
      getDocs();
      getPasswordRules();
    }
  }, [userData]);

  /**
   * Handle checking if checkboxes are checked and if other inputs are valid
   * valid prop from redux-forms won't check validity of boolean field as the input is
   */
  useEffect(() => {
    if (checkboxes.esignAgreement && checkboxes.disclosures && valid) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [checkboxes, valid]);

  /**
   * Get esign agreement and disclosure docs API call
   */
  const getDocs = async () => {
    try {
      const response = await EnrollmentApi.GetSupportingDocs(
        userData.productGroupId,
        userData.participantId
      );

      response.map(
        (doc) => (doc.url = ENV.BASE_API_URL + "/GetSiteDocument/" + doc.id)
      );

      let docs = response;
      let disclosures = [];
      let esign = null;

      for (let i of docs) {
        if (i.typeId !== esignAgreementDocTypeId) {
          disclosures.push(i);
        } else {
          esign = i;
        }
      }

      setDocs(disclosures);
      setEsignAgreementUrl(esign ? esign.url : "");
      setInitialRender(false);
    } catch (err) {
      setErrors([PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  /**
   * Get password rules API call
   */
  const getPasswordRules = async () => {
    try {
      const response = await EnrollmentApi.GetPasswordRules();
      setPasswordRules(response.rules);
    } catch (err) {
      setErrors([PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  /**
   * Handle form submission from CreateUserForm
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    const { values } = formData;

    try {
      const response = await EnrollmentApi.SaveUserNamePassword(
        userData.workflowId,
        userData.participantId,
        values.userName,
        values.password
      );

      trackEvent(EVENTS.ENROLLMENT.ACCOUNT_CREATED, { success: true });
      setShowSpinner(false);
      dispatch(UpdateCurrentPage(response.nextPage));
    } catch (err) {

      setShowSpinner(false);
      trackEvent(EVENTS.ENROLLMENT.ACCOUNT_CREATED, { success: false, failure_reason: err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL });
      setErrors([err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL]);
    }
  };

  /**
   * Handle opening Disclosures modal upon label link click
   */
  const handleDisclosures = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowModal(true);
  };

  const handleESignClick = (e, url) => {
    e.stopPropagation();
    openExternalLinksModal(e, url);
  };

  const handleKeyDown = (e, type) => {
    if (e.key === "Enter") {
      e.stopPropagation();

      if (type === "disclosures") {
        setShowModal(true);
      }
    }
  }

  /**
   * Label for esign agreement checkbox
   */
  const esignLabel = (
    <p>
      I have read and agree to the{" "}
      <a
        href={esignAgreementUrl}
        target="_blank"
        rel="noreferrer noopener"
        onClick={(e) => handleESignClick(e, esignAgreementUrl)}
        title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
        onKeyDown={(e) => handleKeyDown(e)}
      >
        Electronic Communications Agreement
      </a>
      .
    </p>
  );

  /**
   * Label for disclosures checkbox
   */
  const disclosuresLabel = (
    <p>
      I have read and agree to the following{" "}
      <button className="no-styling btn btn-link bold spacing-bottom-xs" onClick={handleDisclosures} onKeyDown={(e) => handleKeyDown(e, "disclosures")}>disclosures</button>{/* 
      */}.
    </p>
  );

  /**
   * Boolean input element is hidden and props.meta.touched is never registered on boolean label click
   * So need to handle if boolean field has been clicked but is no longer checked to display 'This field is required' validation error
   * However, onChange event is fired for esignAgreement checkbox when getDocs() API call is made
   * Therefore need to check if those calls have finished before listening to onChange event
   */
  const handleCheckboxChange = (value, newValue, previousValue, name) => {
    if (!initialRender) {
      setCheckboxes({
        ...checkboxes,
        [name]: {
          checked: value,
          touched: true,
        },
      });
    }
  };

  /**
   * Set checkbox 'touched' value to true after onBlur event fires
   */
  const handleOnBlur = (e) => {
    const name = e.target.name;
    setCheckboxes({
      ...checkboxes,
      [name]: {
        ...checkboxes[name],
        touched: true,
      },
    });
  };

  return (
    <FlowScreen flowTitle="Create Your Online Account">
      <form id={PAGES.ENROLLMENT.CREATE_USER.FORM_NAME} onSubmit={handleSubmit}>
        <GenericForm.Body>
          <PageBlock>
            <PageBlock.IntroText>
              {
                "In order to manage your bank accounts and allocations, you must enter a username and password to create your account."
              }
            </PageBlock.IntroText>
            <ValidationSummary errors={errors} />
            <PageBlock.Body>
              <div className="col-sm-12 col-md-6">
                <InputWrapper className="row">
                  <DynamicInputField
                    id="userName"
                    type={FIELD_TYPES.TEXT}
                    label="Create Username"
                    required={true}
                    focus={true}
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="password"
                    type={FIELD_TYPES.TEXT}
                    label="Create Password"
                    required={true}
                    focus={true}
                    isSensitive={true}
                    autoComplete="off"
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="confirmPassword"
                    type={FIELD_TYPES.TEXT}
                    label="Confirm Password"
                    required={true}
                    focus={true}
                    isSensitive={true}
                    autoComplete="off"
                  />
                </InputWrapper>

                <div className="margin-bottom-15">
                  <span className="agreementSummary">
                    <p>
                      As used in this Disclosure, “Account” means the Card
                      account you have with us. “Communication” means any
                      customer agreements or amendments thereto, disclosures,
                      notices, responses to claims, transaction histories,
                      privacy policies and all other information in connection
                      with a Brightwell Card or related products and services,
                      including but not limited to information that we are
                      required by law to provide you in writing.
                    </p>
                  </span>
                  {esignAgreementUrl.length > 0 && (
                    <div className="form-group checkbox-form-group">
                      <Field
                        id="esignAgreement"
                        name={"esignAgreement"}
                        component={BooleanField}
                        required={true}
                        label={esignLabel}
                        onChange={handleCheckboxChange}
                        onBlur={handleOnBlur}
                      />
                      <div className="help-block">
                        {
                          <span className="validation-error">
                            {checkboxes.esignAgreement.touched &&
                              !checkboxes.esignAgreement.checked
                              ? "This field is required"
                              : " "}
                          </span>
                        }
                      </div>
                    </div>
                  )}
                  <div className="form-group checkbox-form-group">
                    <Field
                      id="disclosures"
                      name={"disclosures"}
                      component={BooleanField}
                      required={true}
                      label={disclosuresLabel}
                      onChange={handleCheckboxChange}
                      onBlur={handleOnBlur}
                    />
                    <div className="help-block">
                      {
                        <span className="validation-error">
                          {checkboxes.disclosures.touched &&
                            !checkboxes.disclosures.checked
                            ? "This field is required"
                            : " "}
                        </span>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-12 col-md-6">
                <div
                  id="CardEnrollmentInstructions"
                  className="CardEnrollmentInstructions text-left"
                >
                  Username must:
                  <ul>
                    <li>Be 6 characters minimum</li>
                    <li>Contain alpha characters (the alphabet)</li>
                    <li>NOT contain the following special characters:</li>
                    <ul>
                      <li>
                        {
                          "! # $ % ^ & * - _ = + ' \" ? | : ; , ~ ` < > [ ] { } ( ) / "
                        }
                      </li>
                    </ul>
                  </ul>
                  {(() => {
                    if (passwordRules.length > 0) {
                      return (
                        <div>
                          Password must have:
                          <ul>
                            {passwordRules.map(({ displayText }) => (
                              <li key={displayText}>{displayText}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    }
                  })()}
                </div>
              </div>

              <ActionRow>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={isDisabled}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </form>

      <DisclosureModal
        docs={docs}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </FlowScreen>
  );
};

CreateUserForm.propTypes = {
  /** redux-form value */
  valid: PropTypes.bool.isRequired,
  /** Show SpinnerLoader */
  setShowSpinner: PropTypes.func.isRequired,
  /** useHistory hook value */
  history: PropTypes.object.isRequired,
};

CreateUserForm.displayName = "CreateUserFormComponent";

export default reduxForm({
  form: PAGES.ENROLLMENT.CREATE_USER.FORM_NAME,
  validate,
  asyncValidate,
  asyncBlurFields: ["password"],
})(CreateUserForm);
