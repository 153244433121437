import React from "react";
import PropTypes from "prop-types";
import { COMMON } from "../../constants/localization";
import InfoTip from "./InfoTip";

/**
 * Creates an HTML field label
 * Renders the  label.
 * If the field isn't required an (optional) label will be appended. *
 * @param label The text of the label
 * @param required Is the associated Form field required
 * @param hideLabel True if the label should NOT be displayed in the UI
 * @param moreInfo More info content
 * @param showModalFunction A delegate function used to display the modal
 * @param fieldId The ID of the associated field
 * @returns {*} An HTML field label
 * @constructor
 */
const Label = ({
  label,
  required,
  hideLabel,
  moreInfo,
  showModalFunction,
  fieldId,
  hideOptional
}) => {
  let forAttr = fieldId ? { htmlFor: fieldId } : {};
  if (moreInfo != null) {
    moreInfo["fieldId"] = fieldId;
    return (
      <div>
        <label>
          {label} {!required && COMMON.OPTIONAL_FIELD}
        </label>
        <InfoTip
          onClick={() => {
            showModalFunction(moreInfo);
          }}
        />
      </div>
    );
  } else if (!hideLabel && label) {
    return (
      <label {...forAttr}>
        {label} {!required && !hideOptional && COMMON.OPTIONAL_FIELD}
      </label>
    );
  } else {
    return null;
  }
};

Label.propTypes = {
  /** The text of the label */
  label: PropTypes.any,
  /** Is the associated Form field required */
  required: PropTypes.bool,
  /** True if the label should NOT be displayed in the UI */
  hideLabel: PropTypes.bool,
  /** More info content */
  moreInfo: PropTypes.any,
  /** A delegate function used to display the modal */
  showModalFunction: PropTypes.any,
  /** The ID of the associated field */
  fieldId: PropTypes.string,
  /** True to hide (optional) text following label */
  hideOptional: PropTypes.bool,
};

export default Label;
