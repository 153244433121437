import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NavBar from "react-bootstrap/lib/Navbar";
import LoggedInItems from "./LoggedInItems";
import { ROUTES } from "../../constants/clientRoutes";
import { Logout } from "../../actions/auth";
import NavigatorLogo from "../../assets/images/navigator_logo_white.svg";
import NavigationBarListItem from "./NavigationBarListItem";
import FocusedLayoutSelector from "../../selectors/isFocusedLayout";
import { trackEvent } from "../../services/eventTracker";
import ENV from "../../constants/environment";
import { EVENTS } from "../../constants/events";

/**
 * The navigation bar wrapper
 */
export class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    this.onLogOut = this.onLogOut.bind(this);
  }

  onLogOut() {
    this.props.Logout();
    this.props.history.push(ROUTES.EXTERNAL.LOGOUT);
  }

  render() {
    const { navBarLinks, isAuthed, hasSupport, isFocused } = this.props;

    const handleSupportClick = () => {
      let supportUrl;

      if (hasSupport) {
        trackEvent(EVENTS.SUPPORT.SUPPORT_SELECTED_CREW_LINK);
        supportUrl = ROUTES.SUPPORT_CONVERSATIONS;
      } else {
        trackEvent(EVENTS.SUPPORT.SUPPORT_SELECTED_LOGGED_OUT_SUPPORT);
        supportUrl = `${ENV.BASE_NAVIGATOR_URL}${ROUTES.SUPPORT_GET_HELP_FASTER}`;
      }

      window.location.href = supportUrl;
    }

    const linkList = navBarLinks.map((link) => {
      return (
        <NavigationBarListItem
          id={link.id}
          key={link.id}
          url={link.url}
          name={link.name}
          hasAlertBadge={link.hasAlertBadge}
        />
      );
    });

    const showSupport =
      linkList.length === 0 &&
      !isAuthed &&
      this.props.location.pathname.toLowerCase() !==
      ROUTES.SUPPORT_PUBLIC.toLowerCase();
    return (
      <NavBar staticTop default>
        <div className="">
          <NavBar.Header className={isFocused ? "focused" : ""}>
            {!isFocused && (
              <NavBar.Toggle>
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar" />
                <span className="icon-bar" />
                <span className="icon-bar" />
              </NavBar.Toggle>
            )}
            {isFocused && (
              <React.Fragment>
                <div id="account-support" className="right">
                  <button
                    id="Support-focused"
                    type="button"
                    className="btn btn-slim"
                    onClick={() => handleSupportClick()}
                  >
                    Support
                  </button>
                </div>
              </React.Fragment>
            )}
            <NavBar.Brand id="logoLink">
              <a href={ROUTES.EXTERNAL.DASHBOARD}>
                <div className="flex-row align-items-center logo-wrapper">
                  <img src={NavigatorLogo} alt="Navigator by Brightwell Home" className="navigator-logo" />
                </div>
              </a>
            </NavBar.Brand>
          </NavBar.Header>
          {!isFocused && (
            <NavBar.Collapse>
              <ul className="nav navbar-nav navbar-right" id="menu">
                {linkList}
                <LoggedInItems display={isAuthed} onLogOut={this.onLogOut} />
                {showSupport && (
                  <li id="account-support">
                    <button
                      id="Support"
                      type="button"
                      className="btn btn-slim"
                      onClick={() => handleSupportClick()}
                    >
                      Support
                    </button>
                  </li>
                )}
              </ul>
            </NavBar.Collapse>
          )}
        </div>
      </NavBar >
    );
  }
}

NavigationBar.propTypes = {
  /** Array of objects {id, url, name, hasAlertBadge} */
  navBarLinks: PropTypes.array.isRequired,
  /** Is the user authenticated */
  isAuthed: PropTypes.bool.isRequired,
  /** Can view the support button */
  hasSupport: PropTypes.bool.isRequired,
  /** Logout action */
  Logout: PropTypes.any,
  /** react-router history */
  history: PropTypes.any.isRequired,
  /** react-router location */
  location: PropTypes.any,
  /** Hides navigation is true */
  isFocused: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const { htmlBuilders, token, auth } = state;
  const hasSupport = !!auth.authResult.token || !!token;
  return {
    navBarLinks: htmlBuilders.navBarLinks || [],
    isAuthed: !!token,
    hasSupport: hasSupport || false,
    isFocused: FocusedLayoutSelector(state),
  };
}

export default connect(mapStateToProps, { Logout })(NavigationBar);
