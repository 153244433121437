import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function restrictions(
  state = initialState.moneyTransfer.restrictions,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES: {
      return action.restrictions;
    }
    default:
      return state;
  }
}
