import React from "react";
import PropTypes from "prop-types";
import Spinner from "./Spinner";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

/**
 * Generates a button
 */
export class Action extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let styleClass;

    switch (this.props.displayType) {
      case ACTION_DISPLAY_TYPES.PRIMARY:
        styleClass = "btn-primary";
        break;
      case ACTION_DISPLAY_TYPES.TERTIARY:
        styleClass = "btn-tertiary";
        break;
      case ACTION_DISPLAY_TYPES.LINK:
        styleClass = "btn-link";
        break;
      case ACTION_DISPLAY_TYPES.SECONDARY:
      default:
        styleClass = "btn-default";
        break;
    }

    let loadingClass = this.props.loading ? "loading" : "";
    let sizeClass = this.props.small ? "small" : "medium";
    let blockClass = this.props.block ? "block" : "";

    return (
      <button
        data-cy={this.props.dataCy}
        onClick={this.props.onClick}
        className={`btn ${styleClass} ${loadingClass} ${sizeClass} ${blockClass} ${this.props.className}`}
        autoFocus={this.props.autoFocus}
        disabled={this.props.disabled}
        type={this.props.type}
        form={this.props.formId}
      >
        {this.props.iconLeft && <img src={this.props.iconLeft} className="icon-left" />}

        {this.props.loading ? <Spinner show={true} /> : this.props.title}

        {this.props.iconRight && <img src={this.props.iconRight} />}
      </button>
    );
  }
}

Action.propTypes = {
  /** Button title */
  title: PropTypes.string,
  /** Image url if no link text is provided */
  imageSrc: PropTypes.string,
  /** Callback on click.
   * e: The button event
   * action: The action object that was used to generate the action
   * ) */
  onClick: PropTypes.func,
  /** Sets the style  */
  displayType: PropTypes.number,
  /** Sets the loading state */
  loading: PropTypes.bool,
  /** Sets the autoFocus property on the button */
  autoFocus: PropTypes.bool,
  /** Creates a small version of the button */
  small: PropTypes.bool,
  /** Determines if the button is disabled */
  disabled: PropTypes.bool,
  /** Determines if the button is larger and sits on it's own line */
  block: PropTypes.bool,
  /** Displays the given icon on the left side of the button */
  iconLeft: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Displays the given icon on the right side of the button */
  iconRight: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Addition css classes */
  className: PropTypes.string,
  /** data-cy attribute value for UI testing */
  dataCy: PropTypes.string,
  /** button type, to allow type to be 'submit' */
  type: PropTypes.string,
  /** form id that can be passed in to allow Action to submit form when not located within <form> element */
  formId: PropTypes.string,
};

export default Action;
