import React from "react";
import PageBlock from "../../components/Layouts/PageBlock.js";
import PropTypes from "prop-types";
import ActionRow from "../../components/Layouts/ActionRow";
import FlowContactSupportButton from "../../components/Buttons/FlowContactSupportButton";

const SecurityQuestionResetError = (props) => {
  return (
    <PageBlock>
      <PageBlock.Title>
        <span>{props.title}</span>
      </PageBlock.Title>
      <PageBlock.Body>
        <PageBlock.IntroText>{props.description}</PageBlock.IntroText>
        <ActionRow>
          <ActionRow.Forward>
            <FlowContactSupportButton />
          </ActionRow.Forward>
        </ActionRow>
      </PageBlock.Body>
    </PageBlock>
  );
};

SecurityQuestionResetError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  sessionInfo: PropTypes.object,
};

export default SecurityQuestionResetError;
