import ENV from "./environment";
import { EVENTS } from "./events";
import { FEATURE_FLAGS } from "./featureFlags";

export const PAGES = {
  NOT_FOUND: {
    PAGE_NOT_FOUND: "Page not found.",
  },

  SESSION: {
    SESSION_TIMEOUT: "Need more time?",
    SESSION_TIMEOUT_WARNING: "Your session is about to end. Do you want to stay logged in?",
    LOG_OUT: "No, log out",
    STAY_CONNECTED: "Yes, stay logged in",
  },

  USER_PROFILE: {
    EDIT_TITLE: "Edit account information",
    TITLE: "Your account information",
    NAME: "Name",
    EMAIL: "Email",
    ADDRESS: "Address",
    BIRTH_DATE: "Birth date",
    PHONE: "Phone",
    PHONE_NUMBER: "Phone number",
    CONTACT: "Contact",
    COUNTRY: "Country",
    CITY: "City",
    POSTAL_CODE: "Postal code",
    STREET_1: "Street address 1",
    STREET_2: "Street address 2",
    STATE_PROVINCE: "State/Province",
    FORMATS: {
      USERS_INFO: (firstName, lastName) =>
        `${firstName} ${lastName}'s information:`,
    },
  },

  CASH_PICKUP: {
    UPDATE_PASSPORT:
      "• Your passport information needs to be updated. Please submit a color image of your passport to support. Once your information has been updated Cash Pickup will be available.",
  },

  MONEY_TRANSFER: {
    MONEY_TRANSFER: "Money Transfer",
    CASH_PICKUP: "Cash Pickup",
    SEND_AMOUNT: "Send amount",
    AMOUNT_TO_SEND: "Amount to send",
    RECEIVING_COUNTRY: "Receiving country",
    RECEIVING_PROVINCE: "State/Province",
    RECEIVING_CITY: "City",
    RECEIVE_AMOUNT: "Receive amount",
    FORM_NAME: "money-transfer-info",
    RATE_LIST: {
      GET_RATES: "Get rates",
      GET_RATE: "Get rate",
      SELECT_ONE_OF: "Select one of the following options:",
      WHERE_TO_SEND: "Where do you want to send your money?",
      CURRENT_EXCHANGE_RATE: "Current exchange rate:",
      FEE: "Fee",
      RECEIVE_TAX: "Receive tax",
      ESTIMATED_ARRIVE: "Estimated arrival",
      TOTAL_COST: "Total cost",
      TOTAL_RECEIVE: "Total receive amount",
      RATES_LAST_UPDATED: "Rates last updated:",
      INVALID_PASSPORT:
        "Your passport needs to be updated to complete this transaction. Please submit a color photo of your passport to our support team.",
    },
    PROMO_CODE: {
      ENTER_PROMO: "Enter promo code",
      PROMO_CODE: "Promo code",
      APPLY: "Apply",
      PROMO_APPLIED: "{0} applied",
    },
    SENDER_INFO: {
      PLEASE_REVIEW: "Please review the following information.",
      ADDITIONAL_INFO: "Additional information",
      VALIDATION_ERRORS:
        "There is a problem with your account information. Please edit.",
      ALERT_MESSAGE: "Any address changes made below will be applied to this transaction only. If you want to update your address you can do it in ‘Account Settings’."
    },
    RECEIVER_INFO: {
      SUB_HEADER:
        "Enter names as they appear on the receiver’s government issued ID to avoid processing delays.",
    },
    NETWORK_PAYOUT_INFO: {
      SUB_HEADER:
        "Please provide your receiver's specific location and select a payout network.",
    },
    REVIEW: {
      REVIEW_SUBTITLE:
        "Please review your Cash Pickup details before pressing <b>Send</b>.",
      TRANSACTION_DETAILS: "Transaction details",
      SENDING: "Sending",
      RECEIVING: "Receiving",
      SENDER_INFORMATION: "Sender information",
      RECEIVER_INFORMATION: "Receiver information",
      TERMS_AGREEMENT: "By checking this box, you agree to our {0}",
      TERMS_AND_CONDITIONS: "Terms and Conditions",
      SEND: "Send",
      CONFIRM_AND_SEND: "Confirm and Send",
      ALERT_HEADING: "Need to edit details",
      ALERT_MESSAGE: "If you need to make changes go back to the previous step(s)."
    },
    SUCCESS: {
      SUBTITLE:
        "Your transaction was successful! We sent your receipt to your email address.",
      TRANSACTION_SUMMARY: "Transaction summary",
      REFERENCE_NUMBER: "Reference number",
      TRACKING_NUMBER: "Tracking Number (MTCN)",
      RECEIVER: "Receiver",
      RECEIVER_COUNTRY: "Receiver country",
      ANOTHER: "Make another transfer",
      DOWNLOAD_RECEIPT: "Download receipt copy",
      TRANSACTION_HOLD_MODAL_TITLE: "Transaction on hold",
      TRANSACTION_HOLD_MODAL_TEXT:
        "Unfortunately there was a problem with your transaction. Please contact our support team to finalize it.",
      PICKUP_INSTRUCTIONS_HEADER:
        "Your receiver needs 3 things to pick up the cash:",
      PICKUP_INSTRUCTIONS: "Pickup instructions",
    },
    DETAILS: {
      TRANSACTION_DETAILS: "Transaction details",
      TRANSACTION_TIME_LABEL: "Cash Pickup transaction made on:",
    },
    STEP_TITLES: {
      COUNTRY_AND_AMOUNT: "Country and amount",
      RATES: "Country and amount",
      SENDER_INFO: "Sender information",
      RECEIVER_INFO: "Receiver information",
      FRAUD_INFO: "Fraud information",
      NETWORK_PAYOUT_INFO: "Payout network",
      REVIEW: "Review and send",
      SUCCESS: "Success!",
    },
    FORMATS: {
      SENDING_TO: (sendAmount, sendCurr, desCurr, desCountry) =>
        ` Sending ${sendAmount} ${sendCurr} to ${desCurr}, ${desCountry}`,
    },
    VALIDATIONS: {
      EXCEEDED_BALANCE: (balance, code) =>
        `Insufficient funds. Your balance is ${balance} ${code}.`,
      EXCEEDED_SEND_LIMIT: (limit, code) =>
        `This amount exceeds our transfer limit of ${limit} ${code}.`,
      RATES_NOT_AVAILABLE:
        "Rates are not available at this time. Please try again later.",
    },
  },

  SUPPORT: {
    TITLE: "Support",
    CALL_US: "Call us",
    TOLL_FREE: "Toll free",
    LOCAL: "Local (USA)",
    BACKUP_1: "855-821-4694",
    BACKUP_2: "404-855-2475",
    SELECT_CALL: "Select a number to call:",
    EXIT_MODAL_TEXT:
      "By exiting this flow, you will lose the information you have entered so far.",
    EXIT_MODAL_HEADER: "Are you sure you want to exit?",
    EXIT: "Exit",
    STAY: "Stay",
    CONTACT_SUPPORT_NOW: "Contact Brightwell support now",
    CONTACT_SUPPORT: "Contact support",
    ACTIVE_TICKET_HEADER: "Ticket currently open",
    ACTIVE_TICKET_TEXT:
      "We see you already have a ticket open for this category. Please continue chatting with support here.",
    LOGGED_IN: {
      TITLE: "How can we help?",
      SUBTITLE: "Which category does your question fall into?",
    },
    FALLBACK_MODAL_TEXT: "Check the back of your card for a phone number to call us."
  },
  GET_HELP_FASTER: {
    TITLE: "Important",
    ALERT_TEXT: "If you contact Support without logging in first, you will be required to verify your identity.",
    ACTION_TEXT: "Continue to Support",
  },

  PIN_RESET: {
    MASTER_CONTAINER_TITLE: "Change card PIN",
    NEW_PIN_INPUT_LABEL: "New PIN",
    CONFIRM_PIN_INPUT_LABEL: "Confirm PIN",
    CARD_PIN: "Card PIN",
    PIN_INPUT_LABEL: "PIN",
    LAST_SIX_DIGITS_LABEL: "Last 6 digits of card number",
    MONTH_INPUT_LABEL: "Month",
    DAY_INPUT_LABEL: "Day",
    YEAR_INPUT_LABEL: "Year",
    FORGOT_PIN_HEADER: "Forgot PIN",
    FORGOT_PIN_HELP_LINK_TEXT: "Forgot PIN?",
    FORGOT_PIN_INTRO_TEXT:
      "If you do not know your current PIN, we will need to verify you identity before you can change your card PIN.",
    MOBILE_FORGOT_PIN_HELP_LINK_TEXT: "Forgot PIN?",
    ENTER_PIN_HEADER: "Enter current PIN",
    MOBILE_ENTER_PIN_HEADER: "Enter current PIN",
    CREATE_NEW_PIN_HEADER: "Create new PIN",
    REENTER_NEW_PIN_HEADER: "Re-enter new PIN",
    SUCCESS: "PIN Successfully changed!",
    SUCCESS_SUBTITLE:
      "Your new PIN is effective immediately on our website and ATMs.",
    RETURN_DASHBOARD: "Return to dashboard",
    PIN_LENGTH: 4,
    PIN_LENGTH_TEXT: "four",
    PIN_INPUT_PLACEHOLDER: "xxxx",
    TOO_MANY_PIN_ATTEMPTS: "Too many PIN attempts",
    TOO_MANY_FAILED_ATTEMPTS: "Too many failed attempts",
    LOCKED_OUT_MESSAGE:
      "You exceeded the maximum number of PIN attempts. Your account has been placed on hold. Please contact support.",
    CANCEL_URL: `${ENV.BASE_NAVIGATOR_URL}/MyCard`,
    ERRORS: {
      PINS_DONT_MATCH:
        "PIN entered does not match previous PIN. Please enter the same PIN.",
      PIN_CRITERIA: "PIN doesn't match the criteria",
    },
    MODALS: {
      PIN: `Your PIN (Personal Identification Number) is the four (4) digit code you need to withdraw money out of an ATM. You set up this number when you first received your card.<br><br>For security purposes, do not write or share your PIN with anyone.`,
    },
  },
  SECURITY_QUESTIONS: {
    MASTER_CONTAINER_TITLE: "Change Security Questions",
    TITLE_DISCLAIMER:
      "To keep your account safe, you can update your security questions by selecting a new question and entering a new answer.",
    PASSWORD_DISCLAIMER:
      "In order to save your changes, you must enter your password:",
    QUESTION_LABEL: "Question",
    ANSWER_LABEL: "Answer",
    PASSWORD_LABEL: "Password",
    CURRENT_PASSWORD_LABEL: "Current password",
    DROPDOWN_PLACEHOLDER: "Select one",
    PAGE_ALERT_SUCCESSFUL: "Security questions updated!",
    INVALID_PASSWORD_ERROR: "Invalid password",
    SESSION_EXPIRED_TITLE: "Session expired",
    SESSION_EXPIRED_TEXT:
      "Your session has expired. If you would like to reset your security questions, please contact support.",
  },
  FORGOT_USERNAME: {
    MASTER_CONTAINER_TITLE: "Forgot username",
    EMAIL_ENTRY_HEADER: "Enter email",
    EMAIL_ENTRY_SUBHEADER:
      "Please enter your email, and your username will be emailed to you.",
    FORGOT_EMAIL_LINK_TEXT: "Forgot email",
    SEND_BUTTON_TEXT: "Send",
    EMAIL_INPUT_LABEL_TEXT: "Email",
    EMAIL_ENTRY_ERROR: "Invalid email format.",
    FORGOT_EMAIL_MODAL: {
      TITLE: "Forgot email",
      TEXT: "If you have forgotten your email, please contact support for more help.",
    },
    SUCCESS_SCREEN: {
      TITLE: "Email sent",
      TEXT: "If you do not receive an email within 10 minutes, please contact support.",
    },
  },
  CHANGE_PASSWORD: {
    CONTAINER_TITLE: "Change Password",
    CURRENT_PASSWORD_LABEL_TEXT: "Current password",
    PAGE_ALERT_SUCCESSFUL: "Password updated successfully",
  },
  FORGOT_PASSWORD: {
    PASSWORDS_MUST_MATCH: "Passwords must match",
    MASTER_CONTAINER_TITLE: "Forgot password",
    USERNAME_ENTRY_HEADER: "Enter username",
    USERNAME_INPUT_LABEL_TEXT: "Username",
    FORGOT_USERNAME_LINK_TEXT: "Forgot username",
    PERSONAL_INFO_ENTRY: {
      PAGE_BLOCK_TITLE: "Reset with personal information",
      PAGE_BLOCK_TEXT:
        "Please provide the following information so we can verify your identity:",
      USERNAME_LABEL_TEXT: "Username",
    },
    RESET_PASSWORD_OPTIONS: {
      PAGE_BLOCK_TITLE: "Reset options",
      PAGE_BLOCK_TEXT:
        "Select one of the following options to reset your password:",
      OPTION_ONE_LABEL: "Use personal information",
      OPTION_TWO_LABEL: "Send email",
    },
    EMAIL_ENTRY: {
      PAGE_BLOCK_TITLE: "Reset with email",
      PAGE_BLOCK_TEXT:
        "Please enter the email address associated with your Brightwell account.",
      USERNAME_LABEL_TEXT: "Username",
      EMAIL_LABEL_TEXT: "Email",
      FORGOT_EMAIL_LINK_TEXT: "Forgot email",
    },
    NEW_PASSWORD_ENTRY: {
      PAGE_BLOCK_TITLE: "Create new password",
      NEW_PASSWORD_LABEL_TEXT: "New password",
      CONFIRM_PASSWORD_LABEL_TEXT: "Confirm password",
      SESSION_EXPIRED_TITLE: "Session expired",
      SESSION_EXPIRED_TEXT:
        "Your session has expired. If you would like to reset your password, please go back to login.",
    },
    MODALS: {
      FORGOT_EMAIL_MODAL_TITLE: "Forgot email",
      FORGOT_EMAIL_MODAL_CONTENT:
        "If you have forgotten your email, please contact support for more help.",
    },
  },
  CURRENCY_CONVERTER: {
    FORM_NAME: "CurrencyConverterForm",
    PAGE_BLOCK_TITLE: "Card Currency Converter",
    PAGE_BLOCK_TEXT:
      "See foreign exchange rates by Mastercard to convert from the transaction currency to your card’s currency for cross border purchases and ATM transactions.",

    TRANSACTION_DETAILS_HEADER: "Transaction details",
    CONVERSION_SUMMARY_HEADER: "Conversion summary",
    TRANSACTION_DATE_LABEL: "Transaction date",
    HIDDEN_FIELD_CURRENCY_VALUE: "currency-value",
    TRANSACTION_AMOUNT_LABEL: "Transaction amount",
    CURRENCY_LABEL: "Currency",
    CALCULATE_BUTTON_TEXT: "Calculate",
    MYCARD_BUTTON_TEXT: "Back to My Card",
    DEFAULT_CURRENCY: "EUR",
    DECIMAL_PLACES: 2,
    AMOUNT_PLACEHOLDER: "0.00",
  },
  FOOTER: {
    VISA_EXTRA_FOOTNOTE:
      "The Brightwell Visa® Prepaid Card is issued by The Bancorp Bank pursuant to a license from Visa U.S.A. Inc. and may be used everywhere Visa debit cards are accepted. The Bancorp Bank; Member FDIC.",
  },
  FACECHECK: {
    BACK_TO_FACECHECK: "Back to FaceCheck",
    SETUP_FACECHECK_TITLE: "Set up FaceCheck",
    SETUP_FACECHECK_SUBTITLE: "FaceCheck required",
    SETUP_FACECHECK_CONTENT:
      "Please continue with FaceCheck setup – it only takes a minute. FaceCheck setup will close if you switch to another tab during setup.",
    SETUP_FACECHECK_START: "Set up FaceCheck",
    LOGIN_FACECHECK_TITLE: "Log in",
    LOGIN_FACECHECK_SUBTITLE: "FaceCheck log in",
    LOGIN_FACECHECK_CONTENT: "Please start FaceCheck to login.",
    LOGIN_FACECHECK_START: "Start FaceCheck",
    INTRO_SETUP_NOW: "Set up now",
    INTRO_SKIP_TO_DASHBOARD: "Skip to dashboard",
    INTRO_SKIP_TITLE: "FaceCheck required",
    INTRO_SKIP_CONTENT:
      "You will be required to set up FaceCheck the next time you log in.",
    INTRO_MODAL_SETUP: "Setup now",
    INTRO_MODAL_SKIP: "Skip",
    INTRO_SLIDE_1_TITLE: "Introducing FaceCheck",
    INTRO_SLIDE_2_TITLE: "Replaces your password",
    INTRO_SLIDE_3_TITLE: "Set up FaceCheck now",
    INTRO_SLIDE_1_CONTENT:
      "Brightwell's safest way to protect your account with a video selfie.",
    INTRO_SLIDE_2_CONTENT:
      "Instead of typing your password, you can use your face to log in.",
    INTRO_SLIDE_3_CONTENT:
      "Setting up your FaceCheck video selfie only takes a minute.",
    ERROR_BACK_TO_LOGIN: "Back to log in",
    NO_WORKING_CAMERA: "No working camera?",
    DO_NOT_HAVE_CAMERA: "I do not have a working camera.",
    ERROR_RETRY_ACTION: "Try again",
    ERROR_TIMEOUT: "Connection timeout",
    ERROR_TIMEOUT_CONTENT:
      "Unable to upload your video selfie. Please check your internet connection and try again.",
    ERROR_CONTACT_SUPPORT: "Contact support",
    ERROR_UPLOAD: "Problem uploading your video selfie",
    ERROR_PROFILE_NOT_FOUND: "FaceCheck profile not found",
    ERROR_PROFILE_EXISTS: "Already enrolled in FaceCheck",
    ERROR_TITLE_GET_TOKEN: "Problem getting authorization information",
    ERROR_TRY_AGAIN: "Need help taking your video selfie?",
    ERROR_RETRY:
      "Please try again. If you continue to experience problems, please contact support.",
    ERROR_CONTENT: "Please contact support to get help setting up FaceCheck.",
    SUPPORT_MODAL_BODY:
      "Select <b>Submit ticket</b> and we will open a high-priority FaceCheck support ticket to get you help as fast as possible",
    SUPPORT_REQUEST_BROWSER: "I need help with my unsupported browser.",
    SUPPORT_REQUEST_CAMERA: "I do not have a working camera.",
    SUPPORT_REQUEST_FACESCAN: "I need help taking my video selfie.",
    SUPPORT_REQUEST_ERROR_UPLOAD:
      "There was a problem uploading my video selfie.",
    SUCCESS: {
      TITLE: "FaceCheck setup complete!",
      BODY: "FaceCheck is setup and ready to use. In the future when you log in, we will ask for your video selfie instead of your password",
    },
  },
  UNSUPPORTED: {
    TITLE: "Unsupported Browser",
    SUBTITLE: "Please upgrade your web browser",
    DESCRIPTION:
      "Brightwell recommends using the most up-to-date versions of these browsers:",
    CHROME: "Chrome",
    SAFARI: "Safari",
    EDGE: "Edge",
    GET_HELP_DESCRIPTION: "Cannot upgrade your browser? Get help to log in.",
    GET_HELP: "Get help",
    FOOTNOTE: "Updated browsers keep your information protected. Learn more: ",
    FOOTNOTE_LINK: "www.brightwell.com/facecheck",
    SUPPORT_MODAL_TITLE: "Cannot update your browser?",
    SUPPORT_MODAL_CONTENT:
      "Select <b>Submit ticket</b> and we will open a high-priority support ticket to get you help as fast as possible.",
  },
  AUTH: {
    LOGIN: "Continue",
    USERNAME: "Username",
    PASSWORD: "Password",
    REMEMBER_ME: "Remember me",
    SECURITY_QUESTION: {
      TITLE: "Security check",
      INFO: "Please answer your security question.",
      DO_NOT_REMEMBER: "I do not remember",
      DO_NOT_REMEMBER_MODAL_TITLE: "Forgot security answers",
      DO_NOT_REMEMBER_MODAL_CONTENT:
        "If you forgot the answers to your security questions, select Submit ticket and we will open a support ticket to help you.",
    },
    TOTP: {
      FORM_NAME: 'security-codes-input-totp-login',
      CANNOT_GET_CODE: 'Cannot get code',
      SECURITY_CODE: 'Security Code',
      CONTACT_SUPPORT: 'Contact Support to get help accessing your account.'
    },
    SUPPORT: {
      FACECHECK_SUPPORT_TICKET: "FaceCheck support ticket",
      EMAIL_CONFIRMATION: "Email confirmation sent to:",
      HIGH_PRIORITY_TITLE: "High-priority ticket submitted",
      CANNOT_ACCESS_EMAIL: "Cannot access that email?",
      SECURITY_QUESTION_TICKET_TITLE: "Forgot security answers",
      TICKET_SUBMITTED: "Ticket submitted",
      NEW_TICKET_SUBMITTED: "New ticket submitted",
      TICKET_NO: "Ticket# ",
      CANNOT_ACCESS_EMAIL_ON_FILE: "Cannot access email on file?",
      CANNOT_ACCESS_EMAIL_ON_FILE_DESCRIPTION:
        "Please provide an email address that you can access, and we will reach out to you.",
      EMAIL_ADDRESS: "Email address",
      TICKET_ALREADY_OPENED: "Ticket already opened",
      TICKET_NOT_CREATED: "Support ticket not created",
      TICKET_ALREADY_OPENED_CONTENT:
        "We see you already have a ticket open for this category. Please check your email for a response.",
      REQUEST_DESCRIPTION: "I need help answering my security question.",
    },
    FORGOT_USERNAME: "Forgot username",
    FORGOT_PASSWORD: "Forgot password",
    NEW_TO_NAVIGATOR: "New to Navigator?",
    ENROLL: "Enroll",
    GENERIC_ERROR_MESSAGE: "An error has occurred.",
  },
  GET_APP: {
    TITLE: "Send Money Faster",
    SUBTITLE: "with the Brightwell app",
    BUTTON_TEXT: "Get App",
  },
  ENROLLMENT: {
    ENROLL: {
      FORM_NAME: "IndexForm",
    },
    CREATE_USER: {
      FORM_NAME: "CreateUserForm",
      ERROR_USERNAME_MIN_LENGTH: "Username must be at least 6 characters.",
      ERROR_USERNAME_MAX_LENGTH: "Username must be less than 50 characters.",
      ERROR_USERNAME_INVALID_CHARS:
        "Username must not contain characters: ! # $ % ^ & * - _ = + ' \" ? | : ; , ~ ` < > [ ] { } ( ) / @ .",
      ERROR_USERNAME_NOT_VALID_EMAIL:
        "UserName cannot contain '@' or '.' unless using a valid email address.",
      ERROR_USERNAME_MUST_CONTAIN_ALPHA:
        "Username must contain alphabetic characters.",
      ERROR_PASSWORDS_DO_NOT_MATCH:
        "The Password and Confirm Password do not match.",
    },
    ACCOUNT_SECURITY: {
      FORM_NAME: "AccountSecurityForm",
    },
    PERSONAL_DATA: {
      FORM_NAME: "PersonalDataForm",
      ERROR_NO_PO_BOX: "Street Address can't be a postal box",
      ERROR_INVALID_ZIP_USA:
        "Zip Code is invalid. Must be formatted nnnnn-nnnn (9 digit Zip is optional).",
      ERROR_INVALID_ZIP_LENGTH:
        "Zip Code is invalid. Must be less than 10 characters.",
      ERROR_INVALID_ZIP:
        "Zip Code is invalid.",
      ERROR_EMAILS_DO_NOT_MATCH: "Emails do not match",
      ERROR_COUNTRY_CODE_REQUIRED:
        "Country code is required if phone number is specified.",
      ERROR_PHONE_NUMBER_REQUIRED:
        "Phone number is required if country code is specified.",
    },
    CARD_DETAILS: {
      FORM_NAME: "CardDetailsForm",
      ERROR_YEAR_REQUIRED: "Year is required",
      ERROR_MONTH_YEAR_REQUIRED: "Month and Year are required",
      ERROR_CARD_LENGTH: "Card Number must be 16 digits long",
      ERROR_CARD_NOT_VALID: "Not a valid card number",
      ERROR_CARDS_DO_NOT_MATCH:
        "Card Number and Re-enter Card Number do not match",
      ERROR_PIN_LENGTH: "Pin Number must be 4 digits long",
      ERROR_PINS_DO_NOT_MATCH: "Pin Number and Confirm Pin do not match",
    },
    COMMUNICATION_PREFERENCES: {
      FORM_NAME: "CommunicationPreferencesForm",
    },
    ERROR_IS_REQUIRED: "This field is required",
    ERROR_GENERAL: "An error has occurred.",
    ERROR_RETRY: "An error has occurred. Please try refreshing the page.",
  },
  DASHBOARD: {
    QUICK_LINKS: {
      HEADER: 'Quick Links',
      FX_RATES: 'See FX Rates',
      MY_CARD_TRANSACTIONS: 'My Card Transactions',
      WESTERN_UNION: 'Send Money With Western Union',
      PAYMENT_HISTORY: 'Payment History',
      BANK_ACCOUNTS: 'Add New Bank Account',
      CHANGE_ALLOCATION: 'Change Allocation',
      COMPANION_CARD: 'Request Companion Card',
      WAGE_STEMENTS: 'Wage Statements'
    },
    NEWS: {
      HEADER: 'News'
    },
    PAYMENT_SUMMARY: {
      HEADER: 'Payment Summary'
    },
    EXHANGE_RATE_MODAL: {
      BENEFICIARY_RECEIVES: 'Beneficiary receives'
    },
    PROCESSOR_DOWN_MODAL: {
      TITLE: '',
      CONTENT: 'We are currently experiencing a temporary service disruption with your card. We are working hard to resolve the problem. Please try again later.'
    },
    ODT_DOWN_MODAL: {
      TITLE: 'OnDemand temporarily unavailable',
      CONTENT: 'We are performing system upgrades with OnDemand services. We will be back up and running soon. Please try again later.'
    }
  },
  SETTINGS: {
    PERSONAL_DETAILS: {
      TITLE: 'Personal Details',
      PAGE_ALERT_SUCCESSFUL: 'Account details updated!'
    },
    COMMUNICATION_PREFERENCES: {
      TITLE: 'Communication Preferences',
      BANK_ACCOUNTS_HEADING: "Transfer initiaited to Bank Accounts",
      SUCCESS_MESSAGE: "Your communication preferences have been successfully updated."
    },
    DISCLOSURES: {
      TITLE: 'Disclosures'
    },
    REVIEW_AND_ACCEPT: {
      TITLE: 'Electronic Communications Agreement',
    },
    LOG_IN_METHOD: {
      TITLE: 'Log in method',
      HOW_IT_WORKS: 'How it works',
    },
    LINK_AUTH_APP_SCREEN: {
      TITLE: 'Link authenticator to Navigator'
    },
    VERIFY_AUTH_APP_SCREEN: {
      TITLE: 'Verify authenticator app',
      FORM_NAME: 'security-code-inputs'
    }
  },
  MY_CARD: {
    PAGE_NAV_LINKS: {
      CARD_BALANCE_AND_TRANSACTIONS: 'Card Balance & Transactions',
      TRANSFER_FUNDS: 'Transfer Funds',
      CASH_PICKUP: 'Cash Pickup*',
      TO_BANK_ACCOUNT: "To bank account",
      WESTERN_UNION: 'Send with Western Union*',
      ON_DEMAND_TRANSFER: 'On Demand--To A Bank Account*',
      CANCEL_ON_DEMAND_TRANSFER: "Cancel On Demand Transfer",
      TO_ANOTHER_CARD: 'Card to Card Transfer*',
      // ENROLL_REPLACEMENT_CARD: 'Enroll a Replacement Card',
      CHANGE_PIN: 'Change PIN',
      LOCK_CARD: 'Lock card',
      REQUEST_COMPANION_CARD: 'Request a Companion Card*',
      ACTIVATE_REPLACEMENT_CARD: 'Activate Replacement Card',
      WAGE_STATEMENTS: 'Wage Statements',
      CARD_CURRENCY_CONVERTER: "Card Currency Converter"
    },
    LOCK_CARD: {
      TITLE: "Lock Card",
      ALERT_TEXT: "Card Successfully locked",
      FAQ_HEADER: "Frequently asked questions",
      FAQ_ERROR: "An error occurred while loading the FAQs. Please try reloading the page.",
      HTML: "HTML",
      TEXT: "Text"
    },
    ACTIVATE_REPLACEMENT_CARD: {
      TITLE: "Activate Replacement Card",
      PAGE_BLOCK_TITLE: "To activate your replacement card, complete the steps below.",
      FORM_NAME: "ActivateReplacementCardForm",
      SUCCESS_MESSAGE: 'Replacement card successfully activated'
    },
    TRANSACTION_DETAILS: {
      CANCEL_MODAL_CONTENT: "You are about to cancel the Cash Pickup for ",
      CANCEL_MODAL_ACTION_TITLE: "Yes, cancel transaction"
    },
    WESTERN_UNION: {
      TITLE: "Western Union service*",
      PIN_CHECK_SOURCE: "Send To Western Union"
    },
    CARD_TO_CARD_TRANSFER: {
      TITLE: "Card to Card Transfer",
      FEE_DISCLAIMER: "Fees may apply. Refer to the fee schedule included in your Cardholder Agreement.",
      SEND_MONEY: "Send money",
      ADD_CARD: "add a card",
      ADD_RECIPIENT_FORM: "add-recipient-form",
      ARE_YOU_SURE_TITLE: "Are you sure?",
      TRANSFER_DETAILS_FORM: "card-to-card-transfer-details-form",
      ADD_RECIPIENT_FORM_TITLE: "Add recipient card",
      RECIPIENT_SUCCESS_TITLE: "Add recipient succeeded",
      RECIPIENT_SUCCESS_BODY: "Successfully added a card to card transfer recipient",
      TRANSFER_SUCCESS_TITLE: "Transfer submitted",
      DELETE_RECIPIENT_SUCCESS: "Recipient deleted"
    },
    REQUEST_COMPANION_CARD: {
      TITLE: "Request a Companion Card",
      FORM_NAME: "RequestCompanionCardForm",
    },
    TO_ACCOUNT: {
      TITLE: "To bank account",
      COUNTRY_AMOUNT: {
        TITLE: "Country and amount",
      },
      RATE_CARDS: {
        TITLE: "Exchange rates"
      }
    }
  }

};

export const PAGE_NAV_LINKS = {
  SETTINGS: {
    HEADER: "Settings",
    LINKS: [
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/AccountDetails`,
        Text: "Personal Details",
        Event: {
          name: EVENTS.USER_PROFILE.PROFILE_SETTINGS_MENU_CLICK,
          param: {
            name: EVENTS.PARAMS.USER_PROFILE,
            value: EVENTS.PARAM_VALUES.TRUE,
          }
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/ChangePassword`,
        Text: "Change Password",
        Event: {
          name: EVENTS.USER_PROFILE.PROFILE_SETTINGS_MENU_CLICK,
          param: {
            name: EVENTS.PARAMS.CHANGE_PASSWORD,
            value: EVENTS.PARAM_VALUES.TRUE,
          }
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/ChangeSecurityQuestions`,
        Text: "Change Security Questions",
        Event: {
          name: EVENTS.USER_PROFILE.PROFILE_SETTINGS_MENU_CLICK,
          param: {
            name: EVENTS.PARAMS.SECURITY_QUESTIONS,
            value: EVENTS.PARAM_VALUES.TRUE,
          }
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/LogInMethod`,
        Text: "Log In Method",
        FeatureFlag: {
          flag: 'TOTP_DisplaySetup'
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/LogInMethod`,
        Text: "Log In Method",
        FeatureFlag: {
          flag: 'TOTP_DisplayDisable'
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/CommunicationPreferences`,
        Text: "Communication Preferences",
        Event: {
          name: EVENTS.USER_PROFILE.PROFILE_SETTINGS_MENU_CLICK,
          param: {
            name: EVENTS.PARAMS.COMM_PREFS,
            value: EVENTS.PARAM_VALUES.TRUE,
          }
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Account/Disclosures`,
        Text: "Disclosures",
        Event: {
          name: EVENTS.USER_PROFILE.PROFILE_SETTINGS_MENU_CLICK,
          param: {
            name: EVENTS.PARAMS.DISCLOSURES,
            value: EVENTS.PARAM_VALUES.TRUE,
          }
        }
      },
    ],
  },
  MY_CARD: {
    HEADER: "My Card",
    LINKS: [
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.CARD_BALANCE_AND_TRANSACTIONS,
      },
      {
        Heading: true,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.TRANSFER_FUNDS
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/MoneyTransfer`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.CASH_PICKUP,
        FeatureFlag: {
          flag: FEATURE_FLAGS.DISPLAY_CASH_PICKUP
        }
      },
      {
        Heading: true,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.TO_BANK_ACCOUNT
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/OnDemandTransfer/ODTSelection`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.ON_DEMAND_TRANSFER,
        FeatureFlag: {
          flag: FEATURE_FLAGS.SEND_TO_BANK_ACCOUNT
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/WesternUnion`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.WESTERN_UNION,
        FeatureFlag: {
          flag: FEATURE_FLAGS.WESTERN_UNION
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/WesternUnionToAccount`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.WESTERN_UNION,
        FeatureFlag: {
          flag: FEATURE_FLAGS.WESTERN_UNION_TO_ACCOUNT_ENABLED
        },
        Event: {
          name: EVENTS.TO_ACCOUNT.STARTED
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/OnDemandTransfer/Transfers`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.CANCEL_ON_DEMAND_TRANSFER,
        FeatureFlag: {
          flag: FEATURE_FLAGS.CANCEL_ON_DEMAND_TRANSFER
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/CardToCardTransfer`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.TO_ANOTHER_CARD,
        FeatureFlag: {
          flag: FEATURE_FLAGS.ALLOW_CARD_TO_CARD_TRANSFER
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/PinChange`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.CHANGE_PIN,
        FeatureFlag: {
          flag: FEATURE_FLAGS.ALLOW_PIN_CHANGE
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/RequestCompanionCard`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.REQUEST_COMPANION_CARD,
        FeatureFlag: {
          flag: FEATURE_FLAGS.COMPANION_CARD
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/lock-card`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.LOCK_CARD,
        FeatureFlag: {
          flag: FEATURE_FLAGS.DISABLE_LOCK_UNLOCK_CARD,
          exclude: true
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/ActivateCard`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.ACTIVATE_REPLACEMENT_CARD
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/Payments/WageStatements`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.WAGE_STATEMENTS,
        FeatureFlag: {
          flag: FEATURE_FLAGS.WAGE_STATEMENTS
        }
      },
      {
        Href: `${ENV.BASE_NAVIGATOR_URL}/MyCard/CardCurrencyConverter`,
        Text: PAGES.MY_CARD.PAGE_NAV_LINKS.CARD_CURRENCY_CONVERTER,
        FeatureFlag: {
          flag: FEATURE_FLAGS.DISPLAY_CURRENCY_CONVERTER
        }
      },
    ]
  }
};

export const WAGE_STATEMENTS = {
  WAGE_STATEMENTS: "Wage Statements",
  MY_WAGE_STATEMENTS: "My Wage Statements",
};

export const EMAIL_SUCCESS = {
  TITLE: "Email sent",
  TEXT: "If you do not receive an email within 10 minutes, please contact support.",
};

export const COMMON = {
  BACK: "Back",
  NEXT: "Next",
  OK: "OK",
  SAVE: "Save",
  SAVE_AND_CLOSE: "Save and close",
  GO_BACK: "Go back",
  YES_DELETE: "Yes, delete",
  SELECT: "- select -",
  SELECT_COUNTRY: "Search country",
  SELECT_STATE: "Search State",
  SELECT_PROVINCE: "Search province",
  SELECT_CITY: "Search city",
  RECENT_SELECTIONS: "Recent selections",
  REQUIRED: "This field is required",
  REQUIRED_CURRENCY: "Please select a currency",
  INVALID_DATE: "Please enter a valid date",
  NA: "N/A",
  GENERIC_ERROR_MODAL_HEADER: "An error has occurred",
  ERROR: "An error occurred while transferring information. Please try again.",
  INITIALIZE_APP_ERROR: "An error occurred while transferring information.",
  ERROR_CONTACT_SUPPORT:
    "An error occurred while transferring information. Please contact support.",
  OPTIONAL_FIELD: "(optional)",
  EDIT: "Edit",
  DAY: "Day",
  MONTH: "Month",
  YEAR: "Year",
  DATE: "Date",
  TODAY: "Today",
  DONE: "Done",
  GO_TO_DASHBOARD: "Go to dashboard",
  BACK_TO_DASHBOARD: "Back to dashboard",
  CONTACT_SUPPORT: "Contact support",
  SUBMIT: "Submit",
  SUBMIT_TICKET: "Submit ticket",
  CANCEL: "Cancel",
  VIEW: "View",
  SUPPORT_URL: `${ENV.BASE_NAVIGATOR_URL}/Support/GenericSupportTicket`,
  BACK_TO_LOGIN: "Back to login",
  DISMISS: "Dismiss",
  SEND: "Send",
  TRANSFER_LOCKED_OUT:
    "Your account has been placed on hold. Please contact support.",
  CARD_ERROR_MESSAGE:
    "There is a problem with your card. Please contact support.",
  CONTACT_SUPPORT_TITLE: "Please contact support",
  ACCOUNT_LOCKED: "Account locked",
  ACCOUNT_LOCKED_CONTENT:
    "Your account has been locked. Please contact support for help.",
  SKIP: "Skip",
  UPDATE: "Update",
  TRY_AGAIN: 'Try again',
  PIN_CHECK: {
    PIN: "PIN",
    PIN_PROMPT: "Please enter your card PIN to continue",
    TOO_MANY_PIN_ATTEMPTS: "Too many PIN attempts",
    LOCKED_OUT_MESSAGE:
      "You exceeded the maximum number of PIN attempts. Your money transfers have been suspended. Please contact support.",
    WRONG_PIN: "Wrong PIN. Please try again",
  },
  VERIFY: 'Verify',
  CLOSE: 'Close',
  TRANSFER_TO: 'Transfer to',
  AMOUNT_TO_SEND: 'Amount to send',
  LOG_IN: "Log in",
  VALIDATION_ERRORS: {
    IS_REQUIRED: "This field is required",
    COUNTRY_CODE_REQUIRED:
      "Country code is required if phone number is specified.",
    PHONE_NUMBER_REQUIRED:
      "Phone number is required if country code is specified.",
    CARD: {
      YEAR_REQUIRED: "Year is required",
      MONTH_YEAR_REQUIRED: "Month and Year are required",
      LENGTH: "Card Number must be 16 digits long",
      NOT_VALID: "Not a valid card number",
      CARDS_DO_NOT_MATCH:
        "Card Number and Re-enter Card Number do not match",
      PIN_LENGTH: "Pin Number must be 4 digits long",
      PINS_DO_NOT_MATCH: "Pin Number and Confirm Pin do not match",
    },
    MAX_LENGTH_EXCEEDED: "Maximum length exceeded",
  },
  MY_CARD_BALANCE: "My card balance",
  SUCCESS: "Success"
};

export const SUPPORT = {
  LABELS: {
    EMPLOYEE_ID: "Employee ID",
    PASSPORT_NUMBER: "Passport number",
    EMAIL_ADDRESS: "Email address",
    PROBLEM_TYPE: "Problem type",
    SPECIFIC_PROBLEM: "Specific problem",
    PROVIDE_MORE: "Please provide more details",
    FIRST_NAME: "First name",
    LAST_NAME: "Last name",
    DATE_OF_BIRTH: "Date of birth",
    SELECT_ONE: "Select one",
  },
  LOGGED_OUT: {
    TITLE: "Support",
    DESCRIPTION: "Please give us your information and we will email you soon.",
    ADDITIONAL_FIELDS: "Additional fields",
  },
  ERROR_USER_NOT_FOUND: "We cannot find you. Please give us more information.",
};

export const PROVIDERS = {
  ODT: {
    CONVERA: {
      ADDRESS: {
        LINE_1: "Convera USA, LLC",
        LINE_2: "Suite 400. 7979 E Tufts Ave.",
        LINE_3: "Denver, CO 80237. USA",
        LINE_4: "Attention. Customer Service FINA"
      },
      TERMS_CONTACT_LINE: "Convera USA, LLC Suite 400, 7979 E. Tufts Ave., Denver, CO 80237, USA Attention: Customer Service FINA (800) 897-6333 (Option 3) CustomerServiceFINA@convera.com https://www.convera.com "
    }
  }
}