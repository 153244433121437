import React, { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CardType, RootState } from '../../types/redux';
import { CompanionCardRecipientType } from '../../types/api/card';
import { COMMON, PAGES, PAGE_NAV_LINKS } from '../../constants/localization';
import CardApi from '../../api/card';

import FlowScreen from '../../components/Layouts/FlowScreen';
import LayoutWithNav from '../../components/Layouts/LayoutWithNav';
import PageNav from '../../components/Layouts/PageNav';
import PageBlock from '../../components/Layouts/PageBlock';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import RecipientCard from '../../components/MyCard/CardToCardTransfer/RecipientCard';
import ActionLink from '../../components/Common/ActionLink';
import Modal from '../../components/Common/Modal';
import { ACTION_DISPLAY_TYPES, ICONS } from '../../constants/enums';
import { DropdownType } from '../../types/fields';
import SendMoneyForm from '../../components/MyCard/CardToCardTransfer/SendMoneyForm';
import Alert from '../../components/Common/Alerts/Alert';
import SuccessModalContent from '../../components/MyCard/CardToCardTransfer/SuccessModalContent';
import AlertModal from '../../components/Common/Modals/AlertModal';
import AddRecipientModalContainer from '../../components/MyCard/CardToCardTransfer/AddRecipientModalContainer';
import DeleteRecipientModalContainer from '../../components/MyCard/CardToCardTransfer/DeleteRecipientModalContainer';

const CardToCardTransfer = () => {
  const card = useSelector((state: RootState) => state.card) as CardType;
  const [recipients, setRecipients] = useState<CompanionCardRecipientType[]>();
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState<boolean>();
  const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
  const [showAreYouSureModal, setShowAreYouSureModal] = useState(false);
  const [recipientToDelete, setRecipientToDelete] = useState<CompanionCardRecipientType>();
  const [loading, setLoading] = useState(true);
  const [recipientOptions, setRecipientOptions] = useState<DropdownType[]>();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [successModalTitle, setSuccessModalTitle] = useState<string>();
  const [successModalContent, setSuccessModalContent] = useState<ReactElement>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const { MY_CARD: { CARD_TO_CARD_TRANSFER } } = PAGES;

  useEffect(() => {
    getRecipients();
  }, []);

  /** 
   * GET recipients call, 
   * Used when component first mounts and after adding or deleting a recipient
  */
  const getRecipients = async () => {
    try {
      const response = await CardApi.GetCardToCardRecipients() as CompanionCardRecipientType[];
      setRecipients(response);

      if (response.length > 0) {
        const options = response.map((recipient) => (
          {
            label: `${recipient.name} | card ending ${recipient.lastSix}`,
            value: recipient.recipientId
          }
        ));

        setRecipientOptions(options);
      } else {
        setDefaultOpen(true);
        setDetailsIsOpen(true);
      }
      setLoading(false);
    } catch (err) {
      if (err.data) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setLoading(false);
      setShowErrorModal(true);
    }
  }

  const handleSuccessModal = (title: string, message: string) => {
    setSuccessModalTitle(title);
    setSuccessModalContent(<SuccessModalContent message={message} />);
    setShowSuccessModal(true);
  }

  const handleDeleteRecipient = async (recipientId: string) => {
    try {
      const response = await CardApi.DeleteCardToCardRecipient(recipientId);
      handleSuccessModal(PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.DELETE_RECIPIENT_SUCCESS, response.message);
      getRecipients();
    } catch (err) {
      if (err.data) {
        setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setShowErrorModal(true);
    }
  }

  const successModalAction = [
    {
      title: COMMON.CLOSE,
      onClick: () => setShowSuccessModal(false),
      displayType: ACTION_DISPLAY_TYPES.PRIMARY
    },
  ]

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        <FlowScreen
          flowTitle={CARD_TO_CARD_TRANSFER.TITLE}
        >

          {loading && (
            <PageBlock>
              <PageBlock.Loader loadingContent={loading}>
                <InputSkeletonGroup numInputs={4} />
              </PageBlock.Loader>
            </PageBlock>
          )}

          {!loading &&
            <div className="double-page-block-container flex-row tablet-direction-column align-items-start">

              {/* Send Money Section */}
              <section className="flow-page-block col-flex-1">
                <p className="large ocean-blue">{CARD_TO_CARD_TRANSFER.SEND_MONEY}</p>

                {recipients?.length < 1 &&
                  <Alert
                    alertClasses="alert-info alert-bkgrd-white flex-row align-items-center"
                    icon={ICONS.INFO}
                    iconClasses={"spacing-right-tiny"}
                  >
                    <p className="spacing-bottom-none">
                      Before making a transfer {" "}
                      <ActionLink
                        text={CARD_TO_CARD_TRANSFER.ADD_CARD}
                        clickFunc={() => setShowAddRecipientModal(true)}
                      />
                    </p>
                  </Alert>

                }

                <div className="flex-row justify-content-between">
                  <p className="marengo-grey">{COMMON.DATE}</p>
                  <p className="bold">{COMMON.TODAY}</p>
                </div>

                <div className="flex-row justify-content-between">
                  <p className="marengo-grey">{COMMON.MY_CARD_BALANCE}</p>
                  <p className="bold">{card.balanceString}</p>
                </div>

                <hr className="beluga-white" />

                {/* Send Money Form */}
                {recipients?.length > 0 &&
                  <SendMoneyForm recipientOptions={recipientOptions} card={card} handleTransferSuccess={handleSuccessModal} />
                }

              </section>

              {/* Saved Recipients Section */}
              <details className="flow-page-block col-flex-1" open={defaultOpen} onToggle={() => setDetailsIsOpen(!detailsIsOpen)}>
                <summary className="flex-row justify-content-between">
                  <span>
                    Saved Recipient cards ({recipients?.length})
                  </span>
                  <span className="label-header-sm">{detailsIsOpen ? "-" : "+"}</span>
                </summary>

                <div className="flex-row justify-content-end spacing-top-small spacing-bottom-small">
                  <ActionLink
                    text="+ Add card"
                    clickFunc={() => setShowAddRecipientModal(true)}
                  />
                </div>

                {recipients?.length > 0 &&
                  recipients.map((recipient: CompanionCardRecipientType) => {
                    return (
                      <RecipientCard
                        key={recipient.recipientId}
                        data={recipient}
                        setShowAreYouSureModal={setShowAreYouSureModal}
                        setRecipientToDelete={setRecipientToDelete}
                      />
                    )
                  })
                }
              </details>

              <AddRecipientModalContainer
                showAddRecipientModal={showAddRecipientModal}
                setShowAddRecipientModal={setShowAddRecipientModal}
                handleSuccessModal={handleSuccessModal}
                getRecipients={getRecipients}
              />

              {recipientToDelete &&
                <DeleteRecipientModalContainer
                  showAreYouSureModal={showAreYouSureModal}
                  setShowAreYouSureModal={setShowAreYouSureModal}
                  recipient={recipientToDelete}
                  handleDeleteRecipient={handleDeleteRecipient}
                />
              }

              <Modal
                onClose={() => setShowSuccessModal(false)}
                open={showSuccessModal}
                title={successModalTitle}
                content={successModalContent}
                small={true}
                className="small-form left-aligned header-icon"
                actions={successModalAction}
                headerIcon={ICONS.GREEN_CHECK}
              />
            </div>
          }

          <AlertModal
            onClose={() => setShowErrorModal(false)}
            open={showErrorModal}
            title={COMMON.GENERIC_ERROR_MODAL_HEADER}
            content={errorMessage}
          />

        </FlowScreen>
      </LayoutWithNav.Content>
    </LayoutWithNav>
  );
}

export default CardToCardTransfer;