import { useDispatch } from "react-redux";
import { SetErrorModal } from "../actions/global";
import { ApiError } from "../types/errors";
import i18n from "../i18n/config";

const useErrorModal = () => {
  const dispatch = useDispatch();

  const openErrorModal = (err: ApiError) => {
    let errorMessage: string;
    if (err?.data?.errors) {
      errorMessage = err.data.errors[0].errorMessage;
    } else {
      errorMessage = i18n.t("COMMON.ERROR");
    }
    dispatch(SetErrorModal({ open: true, errorMessage }));
  }


  return openErrorModal;
};

export default useErrorModal;