import React from "react";
import PropTypes from "prop-types";
import Modal from "../../../components/Common/Modal";
import { COMMON, PAGES } from "../../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import ErrorBlock from "../ErrorBlock";

const renderErrors = (errors) => {
  return errors && <ErrorBlock filterErrors={false} errors={errors} />;
};

const FaceCheckSupportModal = (props) => {
  let isTicketOpenError =
    props?.errors?.some(
      (e) => e.message === PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED
    );
  let content = isTicketOpenError
    ? PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED_CONTENT
    : props.content;

  const actions = [
    {
      title: props.submitText,
      onClick: props.onSubmitClick,
      disabled: isTicketOpenError,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      className: "btn-lg",
    },
  ];

  if (!props.loading) {
    actions.splice(0, 0, {
      title: props.cancelText,
      onClick: props.onCancelClick,
      displayType: ACTION_DISPLAY_TYPES.LINK,
      className: "btn-lg",
    });
  }

  if (props.errors) {
    content = (
      <div>
        {renderErrors(props.errors)}
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    );
  }

  return (
    <Modal
      actions={actions}
      open={props.open}
      loading={props.loading}
      onClose={props.onClose}
      title={props.title}
      className="large-content"
      content={content}
    />
  );
};

FaceCheckSupportModal.defaultProps = {
  submitText: COMMON.SUBMIT_TICKET,
  cancelText: COMMON.CANCEL,
  title: PAGES.UNSUPPORTED.SUPPORT_MODAL_TITLE,
  content: PAGES.UNSUPPORTED.SUPPORT_MODAL_CONTENT,
};

FaceCheckSupportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  submitText: PropTypes.string,
  onSubmitClick: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  errors: PropTypes.array,
};

export default FaceCheckSupportModal;
