import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../constants/localization";
import DisplayField from "../../components/MoneyTransfer/DisplayField";
import { ROUTES } from "../../constants/clientRoutes";
import ActionLink from "../../components/Common/ActionLink";
import logo from "../../assets/images/MasterCard.svg";

const ConversionSummary = (props) => {
  const { fields, disclaimers } = props;

  /**
   * Renders the provider logo. If no image is available the provider name is used instead.
   */
  const renderLogo = () => {
    return <img src={logo} className="provider-logo" alt={"Mastercard"} />;
  };

  /**
   * Renders the exchange rate section, conditionally showing a disclaimer.
   */
  const renderExchangeRateSection = () => {
    if (!fields || fields.length === 0) {
      return null;
    }

    let fxRate = fields[0];
    var disclaimer = fxRate.disclaimerId ? (
      <sup>{fxRate.disclaimerId}</sup>
    ) : null;
    return (
      <div className="exchange-rate">
        <label className="rate-label">
          {fxRate.label}
          {disclaimer}:
        </label>
        <span className="rate-description"> {fxRate.formattedValue}</span>
      </div>
    );
  };

  /**
   * Creates a list of divs with disclaimer info.
   */
  const disclaimerList = disclaimers.map((d, i) => {
    return (
      <div className="disclaimer" key={`disclaimer-${i}`}>
        <sup>{d.disclaimerId}</sup> {d.text}
      </div>
    );
  });

  /**
   * Creates a list of dynamic fields. It ignores the first item because
   * it is generated specially in the @see renderExchangeRateSection
   */
  var tempfields = fields.slice(1);
  const fieldList = tempfields.map((field, i) => {
    return (
      <div
        className="col-xs-6 col-md-4"
        key={`field-container-${i}-${field.Id}`}
      >
        <DisplayField
          key={`field-${field.Id || i}`}
          label={field.label}
          value={field.formattedValue}
          superscript={field.disclaimerId}
          boldValue={i === tempfields.length - 1}
        />
      </div>
    );
  });

  return (
    <React.Fragment>
      <h3>{PAGES.CURRENCY_CONVERTER.CONVERSION_SUMMARY_HEADER}</h3>
      <div className="rate-card container-fluid">
        {/* Mobile */}
        <div className="row visible-xs visible-sm">
          <div className="col-xs-6 width-auto">{renderLogo()}</div>
        </div>
        {/* Desktop */}
        <div className="visible-md visible-lg col-md-3">
          <div className="logo-desktop">{renderLogo()}</div>
        </div>
        <div className="conversion-summary-details col-md-9 row row-no-gutters">
          <div className="row">
            <div className="col-xs-12">{renderExchangeRateSection()}</div>
          </div>
          <div className="row">{fieldList}</div>
        </div>
      </div>
      <div className="spacing-top-default">{disclaimerList}</div>
      <div className="my-card-button-container">
        <ActionLink
          href={ROUTES.EXTERNAL.MY_CARD}
          dataCy="currency-converter-mycard-button"
          text={PAGES.CURRENCY_CONVERTER.MYCARD_BUTTON_TEXT}
        />
      </div>
    </React.Fragment>
  );
};

ConversionSummary.propTypes = {
  fields: PropTypes.array,
  disclaimers: PropTypes.array,
};

export default ConversionSummary;
