import React from 'react';
import "../../../assets/styles/main.scss";

const AuthenticatorInstructions = () => {
  return (
    <div data-cy="auth-instructions">
      <h2>Authenticator App</h2>
      <p className='spacing-bottom-medium'>An app where you input a security code linked to your account. The authenticator app creates a unique 6-to-8 digit code every time you log in.</p>

      <h2>How to add authenticator app</h2>
      <ol className='ol-markers-large'>
        <li>
          <h3 className="bold">Navigator</h3>
          <p className='spacing-bottom-small'>Copy the security code from Navigator (next step).</p>
        </li>
        <li>
          <h3 className="bold">Authenticator app</h3>
          <p className='spacing-bottom-small'>Paste the Navigator security code into your authenticator app.</p>
        </li>
        <li>
          <h3 className="bold">Authenticator app</h3>
          <p className='spacing-bottom-small'>Copy the security code from your authenticator app.</p>
        </li>
        <li>
          <h3 className="bold">Navigator</h3>
          <p className='spacing-bottom-small'>Paste the security code from the authenticator app into Navigator. Once the code is accepted, you are done.</p>
        </li>
      </ol>
    </div>
  );
}

export default AuthenticatorInstructions;