import { SUPPORT } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function categoriesReducer(
  state = initialState.support.categories,
  action
) {
  switch (action.type) {
    case SUPPORT.GET_INDEX_SUCCESS: {
      if (action.index.categories) {
        return action.index.categories.slice();
      }
      return state;
    }
    case SUPPORT.UPDATE_CATEGORIES: {
      if (action.categories) {
        return action.categories;
      }
      return state;
    }
    default:
      return state;
  }
}
