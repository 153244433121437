import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import { BankAccountDeleted, ShowDeleteModal } from "../../actions/bankAccounts";
import i18n from "../../i18n/config";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import BankAccountsApi from "../../api/bankAccounts";
import useErrorModal from "../../hooks/useErrorModal";

import Modal from "../Common/Modal";

const DeleteBankAccountModal = () => {
  const dispatch = useDispatch();
  const openErrorModal = useErrorModal();
  const { showDeleteModal, allocationIdToDelete } = useSelector((state: RootState) => state.bankAccounts);

  const handleDelete = async () => {
    try {
      await BankAccountsApi.DeleteBankAccount(allocationIdToDelete);
      dispatch(BankAccountDeleted(true));
      dispatch(ShowDeleteModal(null, false));
    } catch (err) {
      dispatch(ShowDeleteModal(null, false));
      openErrorModal(err);
    }
  }

  const content = (
    <>
      <p className="bold">{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.P1")}</p>
      <p>{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.P2")}</p>
      <p>{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.P3")}</p>
    </>
  )

  const actions = [
    {
      title: i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.NO_BUTTON"),
      onClick: () => dispatch(ShowDeleteModal(null, false)),
      displayType: ACTION_DISPLAY_TYPES.SECONDARY
    },
    {
      title: i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.YES_BUTTON"),
      onClick: () => handleDelete(),
      displayType: ACTION_DISPLAY_TYPES.PRIMARY
    }
  ]
  return (
    <Modal
      open={showDeleteModal}
      onClose={() => dispatch(ShowDeleteModal(null, false))}
      title={i18n.t("PAGES.BANK_ACCOUNTS.INDEX.DELETE_BANK_MODAL.TITLE")}
      content={content}
      actions={actions}
    />
  );
}

export default DeleteBankAccountModal;