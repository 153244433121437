import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { PAGES, COMMON } from '../../constants/localization';
import { ROUTES } from '../../constants/clientRoutes';
import Action from '../../components/Common/Action';
import { ACTION_DISPLAY_TYPES } from '../../constants/enums';
import AuthenticationAppInputs from '../../components/Common/AuthenticationAppInputs';
import SecurityCode from "../../assets/images/icons/SecurityCode.svg";
import ActionLink from '../../components/Common/ActionLink';
import Modal from '../../components/Common/Modal';
import { AuthorizeLoginTotp, goToNextAuthStep } from "../../actions/auth";
import AlertModal from '../../components/Common/Modals/AlertModal';

const TOTPLogin = () => {
  const [submitting, setSubmitting] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await dispatch(AuthorizeLoginTotp(values));
      dispatch(goToNextAuthStep(history));
    } catch (err) {
      setSubmitting(false);
      setErrorMessage(err.data.errors[0].errorMessage);
      setShowErrorModal(true);
      setResetValues(true);
    }
  }
  return (
    <div className="v2 page-block sm login">
      <h1 className="text-center">Log in</h1>
      <div className='form-group'>
        <img src={SecurityCode} className="label-icon" />
        <p className='label padding-left-none padding-top-none padding-right-none auth-label'>{PAGES.AUTH.TOTP.SECURITY_CODE}</p>
      </div>

      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        title={PAGES.AUTH.TOTP.CANNOT_GET_CODE}
        content={<p>{PAGES.AUTH.TOTP.CONTACT_SUPPORT}</p>}
        actions={[
          {
            title: COMMON.CLOSE,
            onClick: () => (setShowModal(false)),
            displayType: ACTION_DISPLAY_TYPES.SECONDARY,
            focused: true,
          },
          {
            title: PAGES.SUPPORT.CONTACT_SUPPORT,
            onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
            displayType: ACTION_DISPLAY_TYPES.PRIMARY,
          },
        ]}
      />

      <AuthenticationAppInputs
        submitFunc={handleSubmit}
        resetValues={resetValues}
        formId={PAGES.AUTH.TOTP.FORM_NAME}
        classes={'full-width'}
      />

      <div className="text-right spacing-top-tiny spacing-bottom-medium">
        <ActionLink
          text={PAGES.AUTH.TOTP.CANNOT_GET_CODE}
          clickFunc={() => setShowModal(true)}
          classes={'spacing-bottom-medium'}
        />
      </div>

      <Action
        className="btn-lg"
        title={PAGES.AUTH.LOGIN}
        block
        loading={submitting}
        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
        type="submit"
        formId={PAGES.AUTH.TOTP.FORM_NAME}
      />
      <div className="text-center spacing-top-large spacing-bottom-medium">
        <Link className="btn btn-lg btn-link" to={ROUTES.AUTH.LOGIN}>
          {COMMON.BACK}
        </Link>
      </div>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage}
      />
    </div >
  );
}

export default TOTPLogin;