import React from "react";
import GenericForm from "../../Common/DynamicFields/GenericForm";
import { PAGES } from "../../../constants/localization";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import DynamicInputField from "../../Common/DynamicFields/DynamicInputField";
import { FIELD_TYPES } from "../../../constants/enums";
import { validateCardNumber, validateDate } from "../../../services/customValidations";

const AddRecipientForm = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <GenericForm
      form={PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.ADD_RECIPIENT_FORM}
      onSubmit={(e) => { }}
      onUpdate={() => { }}
    >
      <InputWrapper>
        <DynamicInputField
          id="cardNumber"
          type={FIELD_TYPES.INTEGER}
          label="Card number"
          required={true}
          focus={true}
          validate={validateCardNumber}
          max={16}
        />
      </InputWrapper>

      <InputWrapper>
        <DynamicInputField
          id="ExpirationDate"
          type={FIELD_TYPES.DATE}
          label="Card Expiration Date"
          required={true}
          displayDay={false}
          validate={validateDate}
        />
      </InputWrapper>

      <InputWrapper>
        <DynamicInputField
          id="name"
          type={FIELD_TYPES.TEXT}
          label="Name recipient card"
          required={true}
        />
      </InputWrapper>

      {errorMessage &&
        <p className="error">{errorMessage}</p>
      }
    </GenericForm>
  );
}

export default AddRecipientForm;