import React from "react";
import PropTypes from "prop-types";
import { parse } from "date-fns";
import MessageListItem from "./MessageListItem";
import DateHeader from "./DateHeader";
import _ from "lodash";

/**
 * Builds the list of messages
 */
export class MessageList extends React.Component {
  constructor(props) {
    super(props);
    this.getNode = this.getNode.bind(this);
  }

  componentDidMount() {
    this.node.scrollIntoView({ behavior: "smooth" });
  }

  componentDidUpdate() {
    this.node.scrollIntoView({ behavior: "smooth" });
  }

  getNode(node) {
    this.node = node;
  }

  /**
   * Takes a timestamp and returns the date without time
   *
   * @param timestamp
   * @returns {Date}
   */
  static getDate(timestamp) {
    let dt = parse(timestamp);
    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0, 0);
  }

  render() {
    const { messages, requesterId } = this.props;

    const list = messages.map((message, i) => {
      let date = MessageList.getDate(message.timestamp);

      let previous = messages[i - 1];
      let dayItem =
        i === 0 ? <DateHeader timestamp={message.timestamp} /> : null;

      if (previous) {
        let pDate = MessageList.getDate(previous.timestamp);
        if (pDate.toString() !== date.toString()) {
          dayItem = <DateHeader timestamp={message.timestamp} />;
        }
      }

      return (
        <MessageListItem
          key={_.uniqueId()}
          message={message}
          requesterId={requesterId}
          dayItem={dayItem}
        />
      );
    });

    return (
      <ul className="message-list">
        {list}
        <li ref={this.getNode} />
      </ul>
    );
  }
}

MessageList.propTypes = {
  /** Array of messages */
  messages: PropTypes.array.isRequired,
  /** Requester Id */
  requesterId: PropTypes.string.isRequired,
};

export default MessageList;
