import { CASH_PICKUP } from "../../constants/actionTypes";

export default (state, action) => {
  switch (action.type) {
    case CASH_PICKUP.POST_TRANSACTION_SUCCESS:
    case CASH_PICKUP.DESTROY_FORM:
      return {};
    default:
      return state;
  }
};
