import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  AuthorizeLoginPassword,
  goToNextAuthStep,
  Logout,
} from "../../actions/auth";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import { ROUTES } from "../../constants/clientRoutes";
import { Link } from "react-router-dom";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import userIcon from "../../assets/images/icons/User-grey.svg";
import ErrorBlock from "../../components/Common/ErrorBlock";
import { COMMON, PAGES } from "../../constants/localization";
import { ICON_SVG } from "../../constants/iconsvg";
import TrackedLink from "../../components/Buttons/TrackedLink";
import { eventTracker, trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

/**
 * Allows api login and handles retrieving necessary app/user data for the store
 */
class Password extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      showPassword: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
  }

  componentDidMount() {
    const { isAuthenticated, Logout } = this.props;
    if (isAuthenticated) {
      Logout();
      location.href = ROUTES.AUTH.LOGIN;
    }
  }

  /**
   * Calls API to log the user in and retrieve user data for the store
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  async onSubmit({ password }) {
    const { goToNextAuthStep, history, AuthorizeLoginPassword } = this.props;
    this.setState({ errors: null });
    return AuthorizeLoginPassword(password)
      .then(() => {
        // eventTracker.track("Login_Password_Entered", { success: "true" });
        trackEvent(EVENTS.LOGIN.PASSWORD_SUBMITTED, {
          success: true
        })
        goToNextAuthStep(history);
      })
      .catch((error) => {
        eventTracker.track("Login_Password_Entered", { success: "false" });

        if (error && error.response && error.response.data) {
          trackEvent(EVENTS.LOGIN.PASSWORD_SUBMITTED, {
            success: false,
            failure_reason: error.response.data.errors[0].errorMessage
          })
          this.setState({ errors: error.response.data.errors });
        } else {
          trackEvent(EVENTS.LOGIN.PASSWORD_SUBMITTED, {
            success: false,
            failure_reason: PAGES.AUTH.GENERIC_ERROR_MESSAGE
          })
          this.setState({
            errors: [
              { error: error, errorMessage: PAGES.AUTH.GENERIC_ERROR_MESSAGE },
            ],
          });
        }
      });
  }

  togglePasswordVisibility() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  renderErrors() {
    const { errors } = this.state;
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  render() {
    const { handleSubmit, submitting, username } = this.props;
    const { showPassword } = this.state;
    const forgotPasswordLink = username
      ? `${ROUTES.SETTINGS.FORGOT_PASSWORD}/${username}`
      : ROUTES.SETTINGS.FORGOT_PASSWORD;

    return (
      <div className="v2 page-block sm login">
        <h1 className="text-center">Log in</h1>
        {this.renderErrors()}
        <p className="username-label spacing-top-medium">
          Username: <b>{username}</b>
        </p>
        <form
          name="login"
          className="spacing-top-medium"
          onSubmit={handleSubmit(this.onSubmit)}
        >
          <div className="form-group mb-0">
            <img src={userIcon} className="label-icon" />
            <label htmlFor="password" className="auth-label">{PAGES.AUTH.PASSWORD}</label>
            <div className="password-input">
              <Field
                component="input"
                name="password"
                type={showPassword ? "text" : "password"}
                autoFocus
                required
                disabled={submitting}
                autoComplete="current-password"
                className="form-control"
              />
              <button
                type="button"
                className="password-display-toggle"
                onClick={this.togglePasswordVisibility}
              >
                {showPassword ? ICON_SVG.FULL_EYE : ICON_SVG.CROSSED_EYE}
              </button>
            </div>
            <div className="text-right spacing-top-tiny">
              <TrackedLink
                to={forgotPasswordLink}
                linkText={PAGES.AUTH.FORGOT_PASSWORD}
                eventName="Login_ForgotPassword"
              />
            </div>
          </div>
          <div className="spacing-top-large">
            <Action
              className="btn-lg"
              title={PAGES.AUTH.LOGIN}
              block
              loading={submitting}
              displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              type="submit"
              dataCy="password-submit"
            />
          </div>
        </form>
        <div className="text-center spacing-top-large spacing-bottom-medium">
          <Link className="btn btn-lg btn-link" to={ROUTES.AUTH.LOGIN}>
            {COMMON.BACK}
          </Link>
        </div>
      </div>
    );
  }
}

Password.propTypes = {
  /** redux-form submit wrapper */
  handleSubmit: PropTypes.func.isRequired,
  /** API login action */
  AuthorizeLoginPassword: PropTypes.any,
  goToNextAuthStep: PropTypes.any,
  DisplayLoading: PropTypes.any,
  /** Hide loading spinner action */
  FinishLoading: PropTypes.any,
  /** react-router history */
  history: PropTypes.any,
  /** redux-form initialize callback */
  initialize: PropTypes.func,
  submitting: PropTypes.bool,
  username: PropTypes.string,
  isAuthenticated: PropTypes.bool,
  Logout: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    username: state.auth.username,
    isAuthenticated: state.user.isAuthenticated,
  };
}

let loginForm = reduxForm({
  form: "Password",
})(Password);

export default connect(mapStateToProps, {
  AuthorizeLoginPassword,
  goToNextAuthStep,
  Logout,
  DisplayLoading,
  FinishLoading,
})(loginForm);
