import React from "react";
import PropTypes from "prop-types";
import { IconFont } from "./IconFont";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../constants/iconfont";
import ErrorService from "../../services/errorService";
import _ from "lodash";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const ErrorBlock = ({ errors, filterErrors }) => {
  const screenErrors = filterErrors
    ? ErrorService.GetScreenErrors(errors)
    : errors;
  if (screenErrors != null) {
    return (
      <div
        className="flow-page-block__alert"
        data-cy={CyBuilder("page-block-alert", null)}
        data-testid="error-block"
      >
        <div className="page-block-alert__error-block">
          <IconFont icon={ICON_FONT_CLASS.ALERT} type={ICON_TYPE.ALERT} />
          <div className="flow-page-block__alert-message">
            {screenErrors.map((error) => (
              <div key={_.uniqueId()}>{ErrorService.FormatSupportLink(error)}</div>
            ))}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

ErrorBlock.defaultProps = {
  filterErrors: true,
};

ErrorBlock.propTypes = {
  errors: PropTypes.array,
  filterErrors: PropTypes.bool,
};

export default ErrorBlock;
