import React from "react";
import { useSelector } from "react-redux";
import { PAGES } from "../../constants/localization";
import { Props } from "../../types/props";
import { RootState } from "../../types/redux";
import { trackEvent } from "../../services/eventTracker";
import _ from "lodash";

const Header = ({ children }: Props) => {
  return <div className="page-nav__header">{children}</div>;
};

const NavLink = (props) => {

  const handleClick = () => {
    if (props.event) {
      if (props.event.param) {
        trackEvent(props.event.name, {
          [props.event.param.name]: props.event.param.value
        })
      } else {
        trackEvent(props.event.name);
      }
    }
  }

  return (
    <li className={`page-nav__list-item ${props.customClass}`}>
      <a className="page-nav__list-item-link" href={props.Href} onClick={handleClick}>
        {props.Text}
      </a>
    </li>
  );
};

/**
 * Evaluates link for a feature flag filter.
 * Link is valid/unfiltered/matched when:
 *  1. There is no feature flag filter defined on the link
 *  2. The feature flag filter excludes a feature flag and
 *     the feature flag list does not contain the feature flag
 *  3. The feature flag filter includes a feature flag and
 *     the user's feature flag list contains the feature flag
 * @param {*} featureFlags The loggod in user's collection of feature flags
 * @param {*} link The page nave link to evaluate for the existence of a featureFlag filter.
 */
function evaluateFeatureFlag(featureFlags, link) {
  return (
    !link.FeatureFlag ||
    (link.FeatureFlag.exclude
      ? featureFlags.indexOf(link.FeatureFlag.flag) < 0
      : featureFlags.indexOf(link.FeatureFlag.flag) >= 0)
  );
}

/**
 * Determines if is in MyCard sidenav and should be indented
 * @param {*} text link text to see if it matches link text of MyCard sidenav
 */
function evaluateIfIndentedLink(text: string) {
  switch (text) {
    case PAGES.MY_CARD.PAGE_NAV_LINKS.CASH_PICKUP:
    case PAGES.MY_CARD.PAGE_NAV_LINKS.TO_ANOTHER_CARD:
      return "indented-single";
    case PAGES.MY_CARD.PAGE_NAV_LINKS.WESTERN_UNION:
    case PAGES.MY_CARD.PAGE_NAV_LINKS.ON_DEMAND_TRANSFER:
    case PAGES.MY_CARD.PAGE_NAV_LINKS.CANCEL_ON_DEMAND_TRANSFER:
      return "indented-double";
    default:
      return "";
  }
}

const LinksArray = (links) => {
  const featureFlags = useSelector((state: RootState) => state.user.featureFlags);
  const linksArray = [];
  if (links != null) {
    links.forEach((link) => {
      // If link is a heading, render it as a heading
      // If link text is To Bank Account, render it as an indented link
      if (link.Heading) {
        linksArray.push(
          <li className={`page-nav__list-item heading ${link.Text === PAGES.MY_CARD.PAGE_NAV_LINKS.TO_BANK_ACCOUNT ? "indented-single" : ""}`} key={_.uniqueId()}>{link.Text}</li>
        );
      } else {
        if (evaluateFeatureFlag(featureFlags, link)) {
          let customClass = evaluateIfIndentedLink(link.Text);
          linksArray.push(
            <NavLink Href={link.Href} Text={link.Text} key={_.uniqueId()} customClass={customClass} event={link.Event} />
          );
        }
      }
    });
  }
  return linksArray;
};

/// You can either pass in an array of objects
/// and the list links will be generated
/// or you can specify the links yourself
/// or both..
const NavContent = (props) => {
  return (
    <ul className="page-nav__list">
      {LinksArray(props.navLinks)}
      {props.children}
    </ul>
  );
};

const PageNav = (props) => {
  return <div className="page-nav">{props.children}</div>;
};

PageNav.Header = Header;
PageNav.NavContent = NavContent;
PageNav.NavLink = NavLink;

export default PageNav;
