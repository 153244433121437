import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import PageBlock from '../../Layouts/PageBlock';
import InputSkeletonGroup from '../../Common/Loaders/InputSkeleton';
import ActionRow from '../../Layouts/ActionRow';
import ActionLink from "../../Common/ActionLink";
import { COMMON, PAGES } from '../../../constants/localization';
import AuthApi from '../../../api/auth';
import Copy from "../../../assets/images/icons/Copy.svg";
import Action from '../../Common/Action';
import Modal from "../../Common/Modal";
import { ACTION_DISPLAY_TYPES, TOTP_FLOW } from '../../../constants/enums';
import Alert from "../../../assets/images/icons/Alert-blue.svg";
import AuthenticatorInstructions from './AuthenticatorInstructions';
import alertIcon from "../../../assets/images/icons/Alert.svg";
import MessageBlock from '../../Common/MessageBlock';
import _ from 'lodash';

const LinkAuthAppScreen = ({ page, setPage, loading, setLoading }) => {
  const [data, setData] = useState();
  const [errors, setErrors] = useState();
  const [showModal, setShowModal] = useState(false);

  // useEffect hook needs to be above return null or React throws error 
  useEffect(() => {
    const getTotpData = async () => {
      try {
        const response = await AuthApi.getTOTPSetupData();

        const splitSetupCode = response.manualSetupCode.match((/.{1,4}/g));
        setData({ ...response, splitSetupCode: splitSetupCode });
        setLoading(false);
      } catch (err) {
        setErrors(err.data.errors);
        setLoading(false);
      }
    }

    if (page === TOTP_FLOW.SCREENS.LINK_AUTHENTICATOR_APP) {
      setLoading(true);
      getTotpData();
    }

  }, [])

  if (page !== TOTP_FLOW.SCREENS.LINK_AUTHENTICATOR_APP) {
    return null;
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  return (
    <React.Fragment>
      {loading &&
        <PageBlock>
          <PageBlock.Loader loadingContent={loading}>
            <InputSkeletonGroup numInputs={1} />
          </PageBlock.Loader>
        </PageBlock>
      }

      {!loading && errors &&
        <MessageBlock
          actions={[{
            loading,
            displayType: ACTION_DISPLAY_TYPES.PRIMARY,
            title: COMMON.TRY_AGAIN,
            onClick: () => window.location.reload(true),
            className: "btn-lg",
          }]}
        >
          <React.Fragment>
            <h2>
              <img src={alertIcon} /> {COMMON.ERROR}
            </h2>
            <p className="large"> {errors[0].errorMessage} </p>
          </React.Fragment>
        </MessageBlock>
      }

      {!loading && !errors &&
        < div className='page-block'>

          <Modal
            onClose={toggleModal}
            open={showModal}
            title={PAGES.SETTINGS.LOG_IN_METHOD.HOW_IT_WORKS}
            content={<AuthenticatorInstructions />}
          />
          <PageBlock.Title>{PAGES.SETTINGS.LINK_AUTH_APP_SCREEN.TITLE}</PageBlock.Title>
          <PageBlock.Body>

            <p className='marengo-grey'>Copy the following security code, then paste it into your authenticator app.</p>
            <p className='marengo-grey'>
              <img src={Alert} alt="alert icon" />
              You need an authenticator app to continue.&#20;
              <ActionLink clickFunc={toggleModal} text={'How it works'} />
            </p>

            {data &&
              <div className='spacing-top-medium spacing-bottom-medium' data-cy="qr-code" dangerouslySetInnerHTML={{ __html: data.qrCodeSvg }} ></div>
            }

            <p className="marengo-grey">Unable to scan the code? Type this code instead.</p>

            {data &&
              <div className='flex-row align-items-center spacing-bottom-small'>
                {data.splitSetupCode.map((section) => (
                  <p className='spacing-right-tiny spacing-bottom-none large bold abyss-blue' key={section + _.uniqueId()}>{section}</p>
                ))}

                <button className="btn-copy" onClick={() => navigator.clipboard.writeText(data.manualSetupCode)}>
                  <img src={Copy} alt="copy button"></img>
                  Copy
                </button>

              </div>
            }

            <ActionRow>
              <ActionRow.Forward>
                <Action
                  displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                  title={"I've linked my Authenticator app"}
                  onClick={() => setPage(TOTP_FLOW.SCREENS.VERIFY_AUTHENTICATOR_APP)} />
              </ActionRow.Forward>
              <ActionRow.Backward>

                <ActionLink
                  clickFunc={() => setPage(TOTP_FLOW.SCREENS.HOW_IT_WORKS)}
                >
                  <div className="glyphicon glyphicon-menu-left link_icon"></div>
                  {COMMON.BACK}
                </ActionLink>
              </ActionRow.Backward>
            </ActionRow>
          </PageBlock.Body>

        </div>
      }
    </React.Fragment >
  );
}

LinkAuthAppScreen.propTypes = {
  /** number to track which screen to display in flow */
  page: PropTypes.number,
  /** Hook to change pages in flow */
  setPage: PropTypes.func,
  /** current loading state from parent component */
  loading: PropTypes.bool,
  /** hook to set loading state */
  setLoading: PropTypes.func,
};

export default LinkAuthAppScreen;