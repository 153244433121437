import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PAGES, COMMON } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../constants/enums";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker, EVENTS } from "../../services/eventTracker";
import PageBlock from "../../components/Layouts/PageBlock";
import FlowScreen from "../../components/Layouts/FlowScreen";
import InputWrapper from "../Pin/InputWrapper";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import moment from "moment";
import CardApi from "../../api/card";
import ConversionSummary from "./ConversionSummary";
import ActionLink from "../../components/Common/ActionLink";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import _ from "lodash";

/**
 * Currecy converter page
 */
class CurrencyConverter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      currencies: [],
      loading: false,
      showGenericErrorModal: false,
      rateSummary: { fields: [], disclaimers: [] },
    };
  }

  /**
   * Life cycle method called once after the component has mounted
   * Populates the currencies.
   */
  async componentDidMount() {
    this.populateCurrencies();
  }

  /**
   * Gathers the form values then calls the API to get the conversion summary.
   */
  submitForm = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    let form = this.props.currencyConverterForm;
    let amt =
      form.values[PAGES.CURRENCY_CONVERTER.HIDDEN_FIELD_CURRENCY_VALUE];
    let date = moment(
      form.values[PAGES.CURRENCY_CONVERTER.TRANSACTION_DATE_LABEL]
    );

    /// Trims leading zeros (only for the API call, UI is unchanged).
    let sendAmt = _.toNumber(amt.amount);

    try {
      let response = await CardApi.GetMastercardRateSummary(
        date,
        sendAmt,
        amt.targetCurrency
      );
      eventTracker.track(EVENTS.CardCurrencyConverter_Calculate, {
        success: true,
      });
      this.setState({
        loading: false,
        rateSummary: response,
      });
    } catch (error) {
      eventTracker.track(EVENTS.CardCurrencyConverter_Calculate, {
        success: false,
      });
      this.handleApiError(error);
      this.setState({ rateSummary: { fields: [], disclaimers: [] }, loading: false });
    }
  };

  /**
   * Sets the loading display to false and sets the error state.
   * If we are not able to read the error data,
   * it sets the error state as a generic error.
   * @param {*} error - The errors to display.
   */
  handleApiError(error) {
    this.setState(
      {
        loading: false,
      },
      () => {
        let hasStatus = Object.prototype.hasOwnProperty.call(error, "status");
        if (hasStatus && error.status != 500) {
          let {
            data: { errors },
          } = error;
          this.setState({
            errors: errors,
          });
        } else {
          this.setState({
            showGenericErrorModal: true,
          });
        }
      }
    );
  }

  /**
   * Lets us know that the form is valid. Updates the state.
   * */
  updateFormProps = (isFormValid) => {
    this.setState({
      formIsValid: isFormValid,
    });
  };

  /**
   * Populates the currency dropdown from the API
   * and sets it into state.
   */
  async populateCurrencies() {
    let response = [];
    try {
      response = await CardApi.GetMastercardCurrencies();
      this.setState({ currencies: response.currencies });
    } catch (error) {
      response = [];
      this.setState({ currencies: response });
      this.handleApiError(error);
    }
    return response;
  }

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  render() {
    const hasSummary = this.state.rateSummary.fields.length > 0;
    return (
      <FlowScreen
        flowTitle={PAGES.CURRENCY_CONVERTER.PAGE_BLOCK_TITLE}
        className={`v2 currency-converter${hasSummary ? " has-summary" : ""}`}
      >
        <GenericForm
          form={PAGES.CURRENCY_CONVERTER.FORM_NAME}
          onSubmit={this.submitForm}
          onUpdate={this.updateFormProps}
        >
          <GenericForm.Body>
            <PageBlock id="currency-converter-input">
              <PageBlock.BlockErrors errors={this.state.errors} />
              <PageBlock.IntroText>
                {PAGES.CURRENCY_CONVERTER.PAGE_BLOCK_TEXT}
              </PageBlock.IntroText>
              <PageBlock.Body>
                <div className="currency-converter-input">
                  <div className="transaction-details">
                    <h3>
                      {PAGES.CURRENCY_CONVERTER.TRANSACTION_DETAILS_HEADER}
                    </h3>
                    <InputWrapper className="row">
                      <DynamicInputField
                        id={PAGES.CURRENCY_CONVERTER.TRANSACTION_DATE_LABEL}
                        type={FIELD_TYPES.DATE}
                        label={PAGES.CURRENCY_CONVERTER.TRANSACTION_DATE_LABEL}
                        isSensitive={true}
                        required={true}
                        className="form-group col-sm-7"
                      />
                    </InputWrapper>
                    <InputWrapper className="row">
                      <DynamicInputField
                        id={PAGES.CURRENCY_CONVERTER.TRANSACTION_AMOUNT_LABEL}
                        type={FIELD_TYPES.CURRENCY}
                        label={
                          PAGES.CURRENCY_CONVERTER.TRANSACTION_AMOUNT_LABEL
                        }
                        hideLabel={true}
                        required={true}
                        targetCurrencies={this.state.currencies}
                        isTargetCurrencyRequired={true}
                        alwaysShowTargetCurrencySelector={true}
                        decimalPlaces={PAGES.CURRENCY_CONVERTER.DECIMAL_PLACES}
                        displayArrow={false}
                        targetCurrencyLabel={
                          PAGES.CURRENCY_CONVERTER.CURRENCY_LABEL
                        }
                        className="form-group col-sm-7"
                      />
                      {/**The button below is only to be visible on med to large screens after summary is displayed */}
                      <div className="calculate-button">
                        <Action
                          title={PAGES.CURRENCY_CONVERTER.CALCULATE_BUTTON_TEXT}
                          displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                          type="submit"
                          loading={this.state.loading}
                          disabled={!this.state.formIsValid}
                        />
                      </div>
                    </InputWrapper>
                  </div>
                </div>
                <div className="action-row-wrapper">
                  <ActionRow>
                    <ActionRow.Forward>
                      <Action
                        title={PAGES.CURRENCY_CONVERTER.CALCULATE_BUTTON_TEXT}
                        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                        type="submit"
                        loading={this.state.loading}
                        disabled={!this.state.formIsValid}
                      />
                    </ActionRow.Forward>
                    <ActionRow.Backward>
                      <ActionLink
                        href={ROUTES.EXTERNAL.MY_CARD}
                        dataCy="currency-converter-cancel-button"
                        text={COMMON.CANCEL}
                      />
                    </ActionRow.Backward>
                  </ActionRow>
                </div>
                {hasSummary && (
                  <ConversionSummary
                    fields={this.state.rateSummary.fields}
                    disclaimers={this.state.rateSummary.disclaimers}
                  />
                )}
              </PageBlock.Body>
            </PageBlock>
          </GenericForm.Body>
        </GenericForm>
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </FlowScreen>
    );
  }
}

CurrencyConverter.propTypes = {
  currencyConverterForm: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    currencyConverterForm: state.form[PAGES.CURRENCY_CONVERTER.FORM_NAME],
  };
};

export default connect(mapStateToProps)(CurrencyConverter);
