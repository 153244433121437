import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ROUTES } from "../../constants/clientRoutes";
import { APP_STATE } from "../../constants/enums";

/**
 * Wrapper for react-router to manage sending the user to a specified route if logged in 
 * or another route for unauthenticated.
 *
 * @param Component
 * @param rest
 * @returns {null|*}
 * @constructor
 */
const PrivateRedirectRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, redirectTo, appState } = rest;

  return (
      <Route
        {...rest}
        render={(props) =>
          appState !== APP_STATE.Ready 
            ? null 
            : (
                isAuthenticated ? (
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: redirectTo,
                      state: { from: props.location },
                    }}
                  />)
          )
        }
      />
  );
};

PrivateRedirectRoute.propTypes = {
  /** react-router location */
  location: PropTypes.any,
  /** Component to display at the route */
  component: PropTypes.object.isRequired,
  /** Is the user authenticated */
  isAuthenticated: PropTypes.bool,
  /** Route to redirect to if unauthenticated */
  redirectTo: PropTypes.string,
  /** current app state */
  appState: PropTypes.string,
  state: PropTypes.any,
};

PrivateRedirectRoute.defaultProps = {
  redirectTo: ROUTES.AUTH.LOGIN
};

function areEqual(prevProps, nextProps) {
  return (
    prevProps.isAuthenticated === nextProps.isAuthenticated &&
    prevProps.appState === nextProps.appState &&
    prevProps.location === nextProps.location
  );
}

export default connect((state) => {
  return {
    appState: state.global.appState,
    isAuthenticated: !!state.auth.authResult.token || !!state.token,
  };
})(React.memo(PrivateRedirectRoute, areEqual));
