import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GetUserCards } from '../../actions/card';
import Visa_Card from "../../assets/images/bw_visa_card.png";
import Lock from "../../assets/images/icons/Lock.svg";
import LockGrey from "../../assets/images/icons/Lock-grey.svg";
import LockOpen from "../../assets/images/icons/LockOpen.svg";
import { ACTION_DISPLAY_TYPES, LOCK_STATUS, ICONS } from '../../constants/enums';
import PAGE_ALERT from '../../constants/pageAlertTypes';
import { ICON_FONT_CLASS, ICON_TYPE } from '../../constants/iconfont';
import AlertService from '../../services/alertService';

import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import Action from '../../components/Common/Action';
import SpinningLoader from '../../components/Common/SpinningLoader';
import PageAlert from '../../components/Common/PageAlert';
import { IconFont } from '../../components/Common/IconFont';
import CardApi from '../../api/card';
import AlertModal from '../../components/Common/Modals/AlertModal';
import { COMMON, PAGES } from '../../constants/localization';
import { trackEvent } from '../../services/eventTracker';
import { EVENTS } from '../../constants/events';
import Alert from '../../components/Common/Alerts/Alert';
import { RootState } from '../../types/redux';

const LockCard = () => {
  const card = useSelector((state: RootState) => state.card);
  const dispatch = useDispatch();
  const [locked, setLocked] = useState(false);
  const [faqData, setFaqData] = useState([]);
  const [cardImage, setCardImage] = useState('');
  const [detailsState, setDetailsState] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);
  const inActiveCardMessage = AlertService.InActiveCardMessage(card);

  useEffect(() => {
    const getFAQs = async () => {
      try {
        const { lockFaqsList } = await CardApi.GetCardLockFAQs();
        setFaqData(lockFaqsList);
        setDetailsState(lockFaqsList.map(() => ({ open: false })))
      } catch (err) {
        setErrorMessage(PAGES.MY_CARD.LOCK_CARD.FAQ_ERROR);
        setShowErrorModal(true);
      }
    }

    const getCardImage = async () => {
      try {
        const response = await CardApi.GetCardImage();
        setCardImage(response.image);
      } catch (err) {
        setErrorMessage(PAGES.MY_CARD.LOCK_CARD.FAQ_ERROR);
        setShowErrorModal(true);
      }
    }

    getFAQs();
    getCardImage();
    setLoading(false);
  }, [])

  useEffect(() => {
    // If card is locked, isActive will return false
    if (card.status !== "Loading") {
      if (!card.isActive) {
        setLocked(true);
      } else {
        setLocked(false);
      }

      // If card is not active and user has locked card, then unlock button is enabled
      // Else if card is active and unlocked, lock button is enabled
      if (!card.isActive && card.lockStatus === LOCK_STATUS.LOCKED_BY_USER) {
        setIsDisabled(false);
      } else if (card.isActive && card.lockStatus === LOCK_STATUS.UNLOCKED) {
        setIsDisabled(false);
      }
    }
  }, [card])

  const handleClick = async () => {
    setLoading(true);
    let apiFunc = !card.isActive ? CardApi.UnlockCard : CardApi.LockCard;

    try {
      await apiFunc(card);
      // If api call function is successful and previous state for card was active 
      // then user has updated card to locked and success alert banner should be displayed
      if (card.isActive) {
        trackEvent(EVENTS.LOCK_CARD.LOCK_CARD_SUBMITTED, {
          success: true,
        });
        setShowAlert(true);
      } else {
        trackEvent(EVENTS.LOCK_CARD.UNLOCK_CARD_SUBMITTED, {
          success: true,
        });
        setShowAlert(false);
      }
      // After api call, update card state in redux
      await dispatch(GetUserCards());

      setLoading(false);
    } catch (err) {
      setErrorMessage(err?.data?.errors[0]?.errorMessage);
      let eventName = card.isActive ? EVENTS.LOCK_CARD.LOCK_CARD_SUBMITTED : EVENTS.LOCK_CARD.UNLOCK_CARD_SUBMITTED;

      if (err.data.errors) {
        trackEvent(eventName, {
          success: false,
          failure_reason: err.data.errors[0].errorMessage
        });
      } else {
        trackEvent(eventName, {
          success: false,
          failure_reason: "An error has occurred."
        });
      }
      setShowAlert(false);
      setLoading(false);
      setShowErrorModal(true);
    }
  }

  const handleOpen = (e: React.MouseEvent<HTMLDetailsElement>, index: number) => {
    const newState = [...detailsState];
    setDetailsState(newState);
  }

  return (
    <FlowScreen
      flowTitle={PAGES.MY_CARD.LOCK_CARD.TITLE}
    >
      {card?.inActiveCardMessage?.message && card.lockStatus !== LOCK_STATUS.LOCKED_BY_USER &&
        <Alert
          alertClasses={"alert-info flex-row align-items-center"}
          message={inActiveCardMessage}
          isHTML={true}
          icon={ICONS.INFO}
          iconClasses={"spacing-right-tiny"}
        />
      }
      <SpinningLoader show={loading} />
      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage || COMMON.ERROR}
      />
      {showAlert &&
        <PageAlert type={PAGE_ALERT.SUCCESS} className="page-alert-static" >
          <div className='flex-row align-items-center custom-icon-font checkmark-green-bg'>
            <IconFont
              icon={ICON_FONT_CLASS.CHECKMARK}
              type={ICON_TYPE.SUCCESS}
            />
            <p className='spacing-bottom-none spacing-left-tiny alert-green'>{PAGES.MY_CARD.LOCK_CARD.ALERT_TEXT}</p>
          </div>

        </PageAlert>
      }
      <PageBlock>

        <PageBlock.Body>
          <div className='lock-card-wrapper'>
            <div className='card-side'>
              {card.status !== "Loading" &&
                <React.Fragment>
                  <figure className={locked ? 'grayed-out' : ''}>
                    {locked &&
                      <div className='icon-wrapper'>
                        <img src={LockGrey} alt="lock" />
                      </div>
                    }
                    <img src={cardImage} alt="credit card" />
                  </figure>

                  <p className='marengo-grey'>Card is <span className='bold'>{locked ? 'locked' : 'unlocked'}</span></p>

                  <Action
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    title={locked ? "Unlock card" : "Lock card"}
                    iconLeft={locked ? LockOpen : Lock}
                    onClick={handleClick}
                    className={"vertical-align"}
                    disabled={isDisabled}
                  />
                </React.Fragment>
              }
            </div>

            <div className="faq-side accordion panel panel-default ">
              <div className="panel-heading panel-collapse bold">{PAGES.MY_CARD.LOCK_CARD.FAQ_HEADER}</div>
              <ul className="list-group" aria-expanded="false">
                {faqData.length > 0 && detailsState.length > 0 &&
                  faqData.map((item, index) => (
                    <li className="list-group-item" key={item.faqTitle}>
                      <div className="flex-row justify-content-between align-items-center">
                        <details onClick={(e: React.MouseEvent<HTMLDetailsElement>) => handleOpen(e, index)}>
                          <summary>
                            <div className="flex-row justify-content-between align-items-center">
                              <p className="bold">{item.faqTitle}</p>
                              <span>{detailsState[index].open ? '-' : '+'} </span>
                            </div>
                          </summary>
                          {item.formattingType === PAGES.MY_CARD.LOCK_CARD.HTML &&
                            <div dangerouslySetInnerHTML={{ __html: item.faqDetail }}>
                            </div>
                          }

                          {item.formattingType === PAGES.MY_CARD.LOCK_CARD.TEXT &&
                            <div>
                              <p>{item.faqDetail}</p>
                            </div>
                          }
                        </details>
                      </div>
                    </li>
                  ))
                }
              </ul>
            </div>

          </div>
        </PageBlock.Body>
      </PageBlock>
    </FlowScreen>
  );
}

export default LockCard; 