import React from "react";
import PropTypes from "prop-types";
import InfoTip from "../InfoTip";
import InfoPictureModal from "../Modals/InfoPictureModal";
import { INFO_MODAL_PICTURES } from "../../../constants/enums";
import InputService from "../../../services/input";

class InputContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  toggleModal = () => {
    let { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
  };

  render() {
    const { className, serviceProviderFieldId, id, name, moreInfo, datatype } =
      this.props;
    const Input = this.props.children
      ? this.props.children
      : InputService.GetInput(datatype);
    return (
      <div className={`${className || ""} form-group`.trim()}>
        <label htmlFor={serviceProviderFieldId || id}>
          {name}
          {moreInfo && <InfoTip onClick={this.toggleModal} />}
        </label>
        {React.cloneElement(Input, { ...this.props })}
        {moreInfo && (
          <InfoPictureModal
            title={moreInfo.title}
            picture={INFO_MODAL_PICTURES[moreInfo.image]}
            content={moreInfo.message}
            open={this.state.showModal}
            onClose={this.toggleModal}
          />
        )}
      </div>
    );
  }
}

InputContainer.propTypes = {
  value: PropTypes.any,
  serviceProviderFieldId: PropTypes.string,
  id: PropTypes.string,
  datatype: PropTypes.number,
  pattern: PropTypes.string,
  inputUpdate: PropTypes.func,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  name: PropTypes.string,
  moreInfo: PropTypes.object,
  isSensitive: PropTypes.bool,
  placeholderText: PropTypes.string,
  max: PropTypes.number,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default InputContainer;
