import initialState from "./initialState";

export default function faceCheckReducer(
  state = initialState.faceCheck,
  action
) {
  switch (action.type) {
    default:
      return state;
  }
}
