import React from "react";
import PropTypes from "prop-types";
import InputLabel from "../InputLabel";
import { PAGES } from "../../../constants/localization";
import { ASCII } from "../../../constants/forms";

const ACCEPTED_CHARACTERS = [
  ASCII.ONE,
  ASCII.TWO,
  ASCII.THREE,
  ASCII.FOUR,
  ASCII.FIVE,
  ASCII.SIX,
  ASCII.SEVEN,
  ASCII.EIGHT,
  ASCII.NINE,
  ASCII.ZERO,
];

export default class MobilePINInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.setInitialPINInputs();
  }

  componentDidUpdate() {
    this.setInitialPINInputs();
  }

  /**
   * Handles keydown events
   * Keydown events are raised by
   * noncharacter keys (delete, esc, backspace, etc.)
   */
  handleKeydown = (event) => {
    if (event.key === "Delete" || event.key === "Backspace") {
      event.target.value = "";
      this.moveFocus(event, "backward");
      this.setStateInputs();
    }
  };

  /**
   * Handles keypress events
   * Keypress events are raised by
   * character keys (a, b, c, etc.)
   */
  handleKeypress = (event) => {
    if (!this.checkForValidNumInput(event)) {
      event.target.classList.add("mobile-input-error");
    }
  };

  /**
   * Checks if key pressed is a valid integer (0-9).
   * Inputs with type of 'number' still allow
   * periods, commas, and the 'e' character. So we
   * need to still prevent those from being entered
   * into the input element
   * @param {event} event
   */
  checkForValidNumInput(event) {
    if (ACCEPTED_CHARACTERS.indexOf(event.charCode) < 0) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  /**
   * Splits current PIN prop value among the inputs
   */
  setInitialPINInputs() {
    if (this.props.currentPIN != "") {
      const allInputs = document.querySelectorAll("[data-pin-order]");
      Array.prototype.forEach.call(
        this.props.currentPIN.split(""),
        (pinCharacter, i) => {
          allInputs[i].value = pinCharacter;
          if (i == PAGES.PIN_RESET.PIN_LENGTH) {
            allInputs[i].setAttribute("disabled", "");
          }
        }
      );
    } else {
      this.restartInputs();
    }
  }

  /**
   * Event is raised AFTER keydown and keypress. For this to be raised
   * in this context means the character input was a valid integer (0-9).
   *  If it is, it sends the value to the container component and sets
   * focus to the next input. If it isn't, it deletes the value
   * @param {*} event
   */
  handleChange(event) {
    if (event.target.value != "") {
      this.removeAllErrors();
      this.setStateInputs();
      this.moveFocus(event, "forward");
    }
  }

  /**
   * Moves focus forward or backward and updates
   * previous or next element attributes accordingly
   * @param {event} event
   */
  moveFocus = (event, direction = "forward") => {
    this.removeAllErrors();
    if (direction == "forward") {
      if (this.elementIsInput(event.target.nextSibling)) {
        event.target.setAttribute("disabled", "");
        event.target.nextSibling.removeAttribute("disabled");
        event.target.nextSibling.focus();
      } else {
        /// This means we've reached the last
        /// input. Blurring here so user doesn't
        /// have to manually make the keyboard
        /// go down to press the submit button
        event.target.blur();
      }
    } else if (direction == "backward") {
      if (this.elementIsInput(event.target.previousSibling)) {
        event.target.setAttribute("disabled", "");
        event.target.previousSibling.value = "";
        event.target.previousSibling.removeAttribute("disabled");
        event.target.previousSibling.focus();
      }
    }
  };

  /**
   * Checks if element is an input
   */
  elementIsInput(element) {
    if (element == null) {
      return false;
    } else {
      return element.tagName.toLowerCase() == "input";
    }
  }

  /**
   * Clears all the inputs' values and sets focus
   * on the first input
   */
  restartInputs() {
    const allInputs = document.querySelectorAll("[data-pin-order]");
    Array.prototype.forEach.call(allInputs, (pinInput) => {
      pinInput.value = "";
      pinInput.type = "tel";
      pinInput.setAttribute("disabled", "");
    });

    if (!this.props.isTransferLocked) {
      allInputs[0].removeAttribute("disabled");
    }
  }

  /**
   * Removes error class from all inputs
   */
  removeAllErrors() {
    const allInputs = document.querySelectorAll("[data-pin-order]");
    Array.prototype.forEach.call(allInputs, (pinInput) => {
      pinInput.classList.remove("mobile-input-error");
    });
  }

  /**
   * Gets the value of all inputs and concats them together. The new
   * string is then passed to the container component
   */
  setStateInputs() {
    const allInputs = document.querySelectorAll("[data-pin-order]");
    let currentPINInputValues = "";
    Array.prototype.forEach.call(allInputs, (pinInput) => {
      currentPINInputValues += pinInput.value;
    });
    this.props.onPINUpdate(currentPINInputValues);
  }

  render() {
    const pinInputs = [];
    for (let i = 0; i < 4; i++) {
      /// Input type is telehpone to ensure that
      /// the user always get the number input keyboard
      pinInputs.push(
        <input
          maxLength="1"
          name={`pinInput${i}`}
          type="tel"
          inputMode="numeric"
          pattern="[0-9]*"
          className="pin-input"
          autoComplete="off"
          onChange={this.handleChange}
          data-pin-order={i}
          onKeyDown={this.handleKeydown}
          onKeyPress={this.handleKeypress}
          onPaste={(e) => {
            e.preventDefault();
          }}
          key={i}
        />
      );
    }

    return (
      <div
        className={`mobile-pin-inputs${
          this.props.hasError || this.props.isTransferLocked
            ? " mobile-input-error"
            : ""
        }`}
      >
        <InputLabel hidden={true}>{PAGES.PIN_RESET.PIN_INPUT_LABEL}</InputLabel>
        {pinInputs}
      </div>
    );
  }
}

MobilePINInput.propTypes = {
  currentPIN: PropTypes.string,
  onPINUpdate: PropTypes.func,
  hasError: PropTypes.bool,
  isTransferLocked: PropTypes.bool,
};
