import { ROUTES } from "../constants/api";
import {
  HandleResponse,
  HandleErrorResponse,
  HandleNewResponse,
  HandleNewErrorResponse,
} from "./handlers";
import {
  get,
  post,
  put,
  postAnonymous,
  getAnonymous,
  putAnonymous,
} from "./api";

/**
 * Handles calls to the User API
 */
class UserApi {
  /**
   * Get's the user's profile data
   * @returns {Promise<object>} Profile
   * @constructor
   */
  static async GetUserProfile() {
    let response = await get(ROUTES.USER.PROFILE);

    return HandleResponse(response);
  }

  /**
   * Post the user's profile data
   */
  static async PutUserProfile(userData) {
    try {
      let response = await put(ROUTES.USER.PROFILE, userData);
      return HandleResponse(response);
    } catch (error) {
      HandleNewErrorResponse(error);
    }
  }

  /**
   * Check's for the correct pin
   *
   * DEPRECATED: The API call returns IsOk = false for invalid/locked out.
   * This code interprets IsOk = false as a failed call and doesn't differentiate between invalid and locked.
   * The new versino of this doesn't look at the IsOk and just cares about the status code.
   *
   * This can be removed when cash pickup is fully merged into the new MoneyTransfer flow.
   *
   * @param {string} pin The 4 digit pin number
   * @param {number} cardId The unique identifier for the users card
   * @returns {bool} Boolean for provided PIN check
   */
  static async PinCheck_Deprecated(pin, cardId) {
    try {
      let response = await post(ROUTES.USER.PIN_CHECK, {
        pin,
        cardId,
      });

      let data = HandleResponse(response);
      return data.isValid;
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }

  /**
   * Check's for the correct pin
   * NOTE: The API call returns IsOk = false for invalid/locked out.
   * @param {string} pin The 4 digit pin number
   * @param {number} cardId The unique identifier for the users card
   * @returns {bool} Boolean for provided PIN check
   */
  static async PinCheck(pin, cardId) {
    try {
      let response = await post(ROUTES.USER.PIN_CHECK, {
        pin,
        cardId,
      });

      let data = HandleNewResponse(response);
      return data;
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }

  /**
   * Get's the available feature flags
   * @returns {Promise<void|array>} Array of feature flag objects
   * @constructor
   */
  static async GetFeatureFlags() {
    try {
      let response = await get(ROUTES.USER.FEATURE_FLAGS);

      return HandleResponse(response);
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }

  /**
   * Clears the user's current questions and replaces
   * them with these new questions. Uses unauthenticated
   * flow with the session token and emailed membership
   * token as an alternate mean of identification.
   * @param {*} securityQuestions
   */
  static async ResetPasswordQuestions(securityQuestions) {
    let url = ROUTES.USER.RESET_PASSWORD_QUESTIONS;
    try {
      let response = await putAnonymous(url, securityQuestions);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Clears the user's current questions and replaces them
   * with these new questions using the authenticated flow.
   * @param {*} securityQuestions
   */
  static async UpdatePasswordQuestions(securityQuestions) {
    let url = ROUTES.USER.UPDATE_PASSWORD_QUESTIONS;
    try {
      let response = await put(url, securityQuestions);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async GetUserPasswordQuestions(userName) {
    let url = ROUTES.USER.GET_USER_PASSWORD_QUESTIONS(userName);
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async ForgotUsername(emailAddress) {
    let url = ROUTES.USER.FORGOT_USERNAME;
    try {
      let response = await postAnonymous(url, {
        email: emailAddress,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async GetPersonalInfoFields(username) {
    let url = ROUTES.USER.GET_PERSONAL_INFO_FIELDS(username);
    try {
      let response = await getAnonymous(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async SubmitPersonalInfo(username, fields) {
    let url = ROUTES.USER.FORGOT_PASSWORD_PERSONAL_INFO;
    try {
      let response = await postAnonymous(url, {
        username,
        fields,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async SubmitEmail(username, email) {
    let url = ROUTES.USER.SEND_PASSWORD_RESET_EMAIL;
    try {
      let response = await postAnonymous(url, {
        username,
        email,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets the logged in user's transfer lock status.
   */
  static async GetTransferLockStatus() {
    let url = ROUTES.USER.GET_TRANSFER_LOCK_STATUS;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Initializes a change password session for an authenticated user.
   */
  static async InitializeChangeCurrentPasswordSession() {
    let url = ROUTES.USER.INITIALIZE_CHANGE_CURRENT_PASSWORD_SESSION;
    try {
      let response = await post(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Initializes a forgot security question session.
   */
  static async InitializeForgotSecurityQuestionSession(key) {
    let url = ROUTES.USER.INITIALIZE_FORGOT_SECURITY_QUESTION_SESSION;
    try {
      let response = await postAnonymous(url, {
        key,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Initializes a change password session.
   */
  static async InitializeChangePasswordSession(key) {
    let url = ROUTES.USER.INITIALIZE_CHANGE_PASSWORD_SESSION;
    try {
      let response = await postAnonymous(url, {
        key,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Resets the user's password
   */
  static async ResetUserPassword({ userId, token, password }) {
    let url = ROUTES.USER.RESET_PASSWORD;
    try {
      let response = await putAnonymous(url, {
        userId,
        token,
        password,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Changes the user's password
   */
  static async ChangeUserPassword({ token, currentPassword, newPassword }) {
    let url = ROUTES.USER.CHANGE_PASSWORD;
    try {
      let response = await put(url, {
        token,
        currentPassword,
        newPassword,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get's user's documents for disclosures page
   */
  static async GetUserDocuments() {
    let url = ROUTES.USER.GET_USER_DOCUMENTS;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets menu items for NavigationBar
   */
  static async GetMenu() {
    let url = ROUTES.USER.GET_MENU;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets footer items for authenticated user
   */
  static async GetAuthFooter() {
    let url = ROUTES.USER.GET_FOOTER;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
     * Gets footer items for non-authenticated user
     */
  static async GetNonAuthFooter() {
    let url = ROUTES.USER.GET_FOOTER;
    try {
      let response = await getAnonymous(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets user's payment summary
   */
  static async GetPaymentSummary() {
    let url = ROUTES.USER.GET_PAYMENT_SUMMARY;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets user's communication preferences
   */
  static async GetCommunicationPreferences() {
    let url = ROUTES.USER.COMMUNICATION_PREFERENCES;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Updates user's communication preferences
   */
  static async UpdateCommunicationPreferences(data) {
    let url = ROUTES.USER.COMMUNICATION_PREFERENCES;
    try {
      let response = await put(url, data);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get Review and Accept docs
   */
  static async GetReviewAndAcceptDocs() {
    let url = ROUTES.USER.REVIEW_AND_ACCEPT_DOCS;

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Accept Review and Accept docs
   */
  static async UpdateReviewAndAcceptDocs() {
    let url = ROUTES.USER.REVIEW_AND_ACCEPT_DOCS;

    try {
      let response = await put(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }
}

export default UserApi;
