import CatalogApi from "../api/catalog";
import { CATALOG } from "../constants/actionTypes";

/**
 * Gets all available countries from the API and stores in state
 * @returns {Function}
 */
export function GetCountries() {
  return async function (dispatch) {
    let cacheKey = "GET_COUNTRIES";
    let cached = localStorage.getItem(cacheKey);
    let expirationTime = localStorage.getItem(cacheKey + ":ts");

    if (
      cached &&
      (!expirationTime || Date.parse(expirationTime) > Date.now())
    ) {
      dispatch({
        type: CATALOG.GET_COUNTRIES,
        countries: JSON.parse(cached),
      });
    } else {
      let response = await CatalogApi.GetCountries();
      if (response != undefined && response.countries.length > 0) {
        localStorage.setItem(cacheKey, JSON.stringify(response.countries));
        localStorage.setItem(
          cacheKey + ":ts",
          new Date(Date.now() + 10 * 60000)
        ); // set expiration for 10 min
      }
      dispatch({
        type: CATALOG.GET_COUNTRIES,
        countries: response.countries,
      });
    }
  };
}

/**
 * Gets all available stateProvinces by countryIso (ISO 2 Code) from the API and stores in state
 * 10-min Caching occurs
 * @returns {Function}
 */
export function GetStateProvinces(countryIso) {
  return async function (dispatch) {
    let cacheKey = "GET_STATEPROVINCES_" + countryIso;
    let cached = localStorage.getItem(cacheKey);
    let expirationTime = localStorage.getItem(cacheKey + ":ts");
    if (
      cached &&
      (!expirationTime || Date.parse(expirationTime) > Date.now())
    ) {

      dispatch({
        type: CATALOG.GET_STATE_PROVINCES,
        stateProvinces: JSON.parse(cached),
      });
    } else {
      let response = await CatalogApi.GetStateProvinces(countryIso);
      if (response != undefined && response.stateProvinces.length > 0) {
        localStorage.setItem(cacheKey, JSON.stringify(response.stateProvinces));
        localStorage.setItem(
          cacheKey + ":ts",
          new Date(Date.now() + 10 * 60000)
        ); // set expiration for 10 min
      }
      dispatch({
        type: CATALOG.GET_STATE_PROVINCES,
        stateProvinces: response.stateProvinces,
      });
    }
  };
}

/**
 * Sets all available stateProvinces by countryIso (ISO 2 Code) from the API and stores in state
 * 10-min Caching occurs
 * @returns {Function}
 */
export function SetStateProvinces(payload) {
  return (dispatch) => {
    dispatch({
      type: CATALOG.GET_STATE_PROVINCES,
      stateProvinces: payload,
    });
  };
}