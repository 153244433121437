import axios from "axios";
import AuthApi from "./auth";
import { ROUTES } from "../constants/api";
import {
  HandleResponse,
  HandleErrorResponse,
  HandleNewErrorResponse,
  HandleNewResponse,
} from "./handlers";

/**
 * Interacts with the wage statements API
 */
class WageStatementsApi {
  /**
   * Get's the user's wage statements
   * @returns {Promise<void|array>} List of statements
   * @constructor
   */
  static async GetWageStatements() {
    try {
      let response = await axios.get(ROUTES.WAGE_STATEMENTS.GET_ALL, {
        headers: AuthApi.getAuthorizationHeaders(),
      });

      return HandleResponse(response).docs;
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Get's a specific statement
   * @param {number} id
   * @returns {Promise<void|object>}
   * @constructor
   */
  static async GetWageStatement(id) {
    const url = ROUTES.WAGE_STATEMENTS.GET_BY_ID(id);

    try {
      let response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
        responseType: "blob", // important
      });

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }
}

export default WageStatementsApi;
