import React from "react";
import PickupInstructions from "./PickupInstructions";
import Icon from "../Common/Icon";
import ActionLink from "../Common/ActionLink";
import { ICONS, SUCCESS_FIELDS, MONEY_TRANSFER_PROVIDER_NAMES } from "../../constants/enums";
import { PAGES } from "../../constants/localization";
import * as _ from "lodash";
import { DisplaySectionFieldType, TransferDetailsType } from "../../types/api/moneyTransfer";

/**
 * Builds display logic for horizontal field/value display
 * @param {*} fields
 */
const renderFields = (fields: DisplaySectionFieldType[], transactionHeld: boolean) => {
  return (
    <div className="display-fields">
      {fields &&
        fields.length > 0 &&
        fields.map((field) => (
          <div className="field" key={field.id}>
            <div className="row" key={field.id}>
              <div className="col-xs-6">
                <div className="text-left">
                  <label>
                    {field.label}
                    {field.disclaimerId ? <sup>{field.disclaimerId}</sup> : ""}:
                  </label>
                </div>
              </div>
              <div className="col-xs-6">
                <div
                  className={`text-right value ${field.id === SUCCESS_FIELDS.TIME_UNTIL_AVAILABLE &&
                    transactionHeld &&
                    "text-danger"
                    }`}
                >
                  {field.formattedValue}
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

/**
 *
 *
 * @returns {*}
 * @constructor
 */
const TransactionSummary = ({ transfer, onGetReceipt }: { transfer: TransferDetailsType, onGetReceipt: Function }) => {
  const referenceNumberField = _.find(
    transfer.displayFields,
    (f) => f.id == SUCCESS_FIELDS.REFERENCE_NUMBER
  );
  const fields = _.filter(transfer.displayFields, (f) => {
    return (
      f.id != SUCCESS_FIELDS.REFERENCE_NUMBER &&
      f.id != SUCCESS_FIELDS.TRANSFER_DATE
    );
  });

  const handleCopyClick = () => {
    navigator.clipboard.writeText(referenceNumberField.formattedValue);
  }

  return (
    <div className="transaction-summary">
      {/** Transaction Details */}
      <div className="content-block highlight spacing-top-medium">
        <div className="center">
          <h2>{PAGES.MONEY_TRANSFER.SUCCESS.TRANSACTION_SUMMARY}</h2>

          {/* Provider logo */}
          <img src={transfer.logo} alt={transfer.providerName} className="max-w-200 spacing-bottom-tiny spacing-top-tiny" />

          {/** Reference Number */}
          {referenceNumberField && (
            <div>
              <label>{referenceNumberField.label}</label>
              <div className="reference-number">
                {referenceNumberField.formattedValue}
                <button onClick={handleCopyClick} className="no-styling">
                  <Icon icon={ICONS.COPY_BLUE} />
                </button>

              </div>
            </div>
          )}
        </div>
        {renderFields(fields, transfer.hasTransactionHold)}
      </div>
      <div className="content-block">
        <div className="row hidden-xs">
          {transfer.pickupInstructions.length > 0 &&
            <div className="col-xs-6">
              <PickupInstructions instructions={transfer.pickupInstructions} />
            </div>
          }
          <div className={`col-xs-${transfer.pickupInstructions.length > 0 ? "6" : "12"} text-right`}>
            {transfer?.downloadReceiptText && (
              <ActionLink clickFunc={onGetReceipt} classes="bold">
                <Icon icon={ICONS.DOWNLOAD} />
                {PAGES.MONEY_TRANSFER.SUCCESS.DOWNLOAD_RECEIPT}
              </ActionLink>
            )}
          </div>
        </div>

        <div className="row visible-xs">
          <div className="col-xs-12 spacing-bottom-default">
            {transfer?.downloadReceiptText && (
              <ActionLink clickFunc={onGetReceipt} classes="bold">
                <Icon icon={ICONS.DOWNLOAD} />
                {PAGES.MONEY_TRANSFER.SUCCESS.DOWNLOAD_RECEIPT}
              </ActionLink>
            )}
          </div>
          {transfer.pickupInstructions.length > 0 &&
            <div className="col-xs-12 spacing-top-default">
              <PickupInstructions instructions={transfer.pickupInstructions} />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default TransactionSummary;
