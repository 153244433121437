import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  PageBlockBody,
  PageBlockError,
  PageBlockIntroText,
  PageBlockLoader,
  PageBlockText,
  PageBlockTitle,
  PageBlockErrorBlock,
} from "./PageBlockSubcomponents/index.js";
import ErrorBlock from "../Common/ErrorBlock";
import ErrorProvider from "../ErrorProvider";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlock = ({ children, id, showScreenErrors }) => {
  return (
    <div
      className="flow-page-block"
      id={id}
      data-cy={CyBuilder("page-block", id)}
      data-testid="page-block"
    >
      {showScreenErrors && (
        <ErrorProvider render={(errors) => <ErrorBlock errors={errors} />} />
      )}
      {children}
    </div>
  );
};

PageBlock.Title = PageBlockTitle;
PageBlock.IntroText = PageBlockIntroText;
PageBlock.Body = PageBlockBody;
PageBlock.Error = PageBlockError;
PageBlock.Text = PageBlockText;
PageBlock.Loader = PageBlockLoader;
PageBlock.BlockErrors = PageBlockErrorBlock;

PageBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
  error: PropTypes.string,
  loadingContent: PropTypes.bool,
  id: PropTypes.string,
  showScreenErrors: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    errors: state.errors,
  };
}

export default connect(mapStateToProps)(PageBlock);
