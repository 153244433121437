import React from "react";
import PropTypes from "prop-types";

const InputSkeleton = () => {
  return (
    <div className="input-skeleton">
      <div className="input-skeleton__label"></div>
      <div className="input-skeleton__input"></div>
    </div>
  );
};

/**
 * Returns a specified number of InputSkeleton
 * components as an array
 * @param {*} numInputs
 */
const returnInputSkeletons = (numInputs) => {
  let skeletonInputs = [];
  for (let i = 0; i < numInputs; i++) {
    skeletonInputs.push(<InputSkeleton key={i} />);
  }
  return skeletonInputs;
};

const InputSkeletonGroup = (props) => {
  const direction = props.direction === 'vertical' ? ' vertical' : '';
  const hideLabels = props.hideLabels ? ' hide-labels' : '';
  return (
    <div className={`input-skeleton-group ${direction + hideLabels}`} data-cy="input-skeleton-group" data-testid="input-skeleton-group">
      {returnInputSkeletons(props.numInputs)}
    </div>
  );
};

InputSkeletonGroup.propTypes = {
  numInputs: PropTypes.number,
  direction: PropTypes.string,
  hideLabels: PropTypes.bool
};

export default InputSkeletonGroup;
