import * as types from "../constants/actionTypes";
import initialState from "./initialState";
import ENV from "../constants/environment";

export default function htmlBuildersReducer(
  state = initialState.htmlBuilders,
  action
) {
  switch (action.type) {
    case types.RECEIVE_NAVBAR_LINKS: {
      const newState = Array.isArray(action.navBarLinks)
        ? action.navBarLinks.map((item) => {
          const { controllerName, actionName } = item;

          return {
            id: controllerName + actionName,
            url: `${ENV.BASE_NAVIGATOR_URL}/${controllerName}${actionName !== 'Index' ? `/${actionName}` : ''}`,
            name: item.text,
            hasAlertBadge: item.HasAlertBadge,
          };
        })
        : [];

      return Object.assign({}, state, { navBarLinks: newState });
    }
    case types.RECEIVE_FOOTER_LINKS:
      return Object.assign({}, state, { footerLinks: action.links, issuerStatement: action.issuerStatement });
    default:
      return state;
  }
}
