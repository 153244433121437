import React from "react";
import PropTypes from "prop-types";

/**
 * Loading spinner overlay
 *
 * @param show
 * @returns {null|*}
 * @constructor
 */
export const SpinningLoader = ({ show }) => {
  if (!show) {
    return null;
  }

  return (
    <div id="overlay-loader">
      <span className="loader"></span>
    </div>
  );
};

SpinningLoader.propTypes = {
  /** Toggle for the overlay/spinner */
  show: PropTypes.bool.isRequired,
};

export default SpinningLoader;
