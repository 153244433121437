import React from "react";
import PropTypes from "prop-types";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockText = (props) =>
  props.children ? (
    <p
      className="flow-page-block__caption"
      id={props.id}
      data-cy={CyBuilder("page-block-caption", props.id)}
    >
      {props.children}
    </p>
  ) : null;

PageBlockText.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default PageBlockText;
