import { ROUTES } from "../constants/api";
import { ENROLLMENT } from "../constants/enrollment";
import {
  HandleNewErrorResponse,
  HandleNewResponse,
  HandleAltResponse,
  HandleDoesPassResponse
} from "./handlers";
import ENV from "../constants/environment";
import axios from "axios";
import { SaveCardDetailsRequest, SavePersonalDetailsRequest } from "../types/api/enrollment";

/**
 * Class for static methods handling user enrollment
 */
class EnrollmentApi {
  /**
   * Gets the local storage access_token
   * @returns {Object} headers data
   * @constructor
   */
  static Headers() {
    const config = {
      Authorization: `Bearer ${localStorage.getItem(
        ENROLLMENT.ACCESS_TOKEN_KEY
      )}`,
      "Access-Control-Allow-Origin": "*",
    };

    return config;
  }

  /**
   * Handles errors
   * @returns {Object} headers data
   * @constructor
   */
  static LogError(error) {
    return error;
    // Do something with error
  }

  /**
   * Logs in user with employee id, birthdate, passport, and country.
   * @returns {Promise<void>} user data
   * @constructor
   */
  static async Login(employeeId, birthDate, passportNumber, countryId) {
    let url = ROUTES.ENROLLMENT.LOGIN;

    let payload = {
      employeeId: employeeId,
      birthDate: birthDate,
      passportNumber: passportNumber,
      countryId: countryId,
      grant_type: "enrollment",
      client_secret: ENV.CLIENT_SECRET,
      client_id: ENV.CLIENT_ID,
    };

    let params = new URLSearchParams();
    Object.entries(payload).forEach(item => params.append(item[0], item[1]));

    try {
      let response = await axios.post(url, params);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets the list of security questions.
   * @returns {Promise<void>} security question data
   * @constructor
   */
  static async GetSecurityQuestions() {
    let url = ROUTES.ENROLLMENT.SECURITY_QUESTIONS;

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets list of all countries for country picker component in IndexForm.
   * @returns {Promise<void>} country data
   * @constructor
   */
  static async GetAllCountries(forPhone) {
    let url = ROUTES.ENROLLMENT.GET_ALL_COUNTRIES(forPhone);

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get list items based on provided urlVar (currently StateDropDown items).
   * @returns {Promise<void>} state data
   * @constructor
   */
  static async GetSelectListItems(urlVar) {
    let url = ROUTES.ENROLLMENT.GET_SELECT_LIST_ITEMS(urlVar);

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get disclosure and electronic communications agreement documents.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async GetSupportingDocs(id, particpantId) {
    let url = ROUTES.ENROLLMENT.GET_SUPPORTING_DOCS(id, particpantId);

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get password rules for user creating new account.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async GetPasswordRules() {
    let url = ROUTES.ENROLLMENT.GET_PASSWORD_RULES;

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get communication preferences for receiving emails.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async GetCommunicationPreferences(participantId) {
    let url = ROUTES.ENROLLMENT.GET_COMMUNICATION_PREFERENCES(participantId);

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get user enrollment data.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async GetEnrollmentData(participantId) {
    let url = ROUTES.ENROLLMENT.GET_ENROLLMENT_DATA(participantId);

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Save User username and password from CreateUser component.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async SaveUserNamePassword(
    workflowId,
    participantId,
    userName,
    password
  ) {
    let url = ROUTES.ENROLLMENT.SAVE_USER_NAME_PASSWORD;

    try {
      let response = await axios.post(
        url,
        {
          id: workflowId,
          participantId: participantId,
          userName: userName,
          password: password,
        },
        {
          headers: this.Headers(),
        }
      );

      return HandleAltResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Save account security answers from AccountSecurity component.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async SaveAccountSecurityV2(workflowId, passwordQuestionAnswers) {
    let url = ROUTES.ENROLLMENT.SAVE_ACCOUNT_SECURITY_V3;

    try {
      let response = await axios.post(
        url,
        {
          id: workflowId,
          passwordQuestionAnswers,
        },
        {
          headers: this.Headers(),
        }
      );

      return HandleAltResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Save personal data from PersonalData component.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async SavePersonalData(data: SavePersonalDetailsRequest) {
    let url = ROUTES.ENROLLMENT.SAVE_PERSONAL_DATA;

    try {
      let response = await axios.post(
        url,
        data,
        {
          headers: this.Headers(),
        }
      );

      return HandleAltResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Save card details from CardDetails component.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async SaveCardDetails(data: SaveCardDetailsRequest) {
    let url = ROUTES.ENROLLMENT.SAVE_CARD_DETAILS;

    try {
      let response = await axios.post(
        url,
        data,
        {
          headers: this.Headers(),
        }
      );

      return HandleAltResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Complete workflow from CommunicationPreferences component.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async CompleteWorkflow(
    id,
    preferences,
    productGroupId,
    clientId,
    participantId
  ) {
    let url = ROUTES.ENROLLMENT.COMPLETE_WORKFLOW;

    try {
      let response = await axios.post(
        url,
        {
          id,
          preferences,
          productGroupId,
          clientId,
          participantId,
        },
        {
          headers: this.Headers(),
        }
      );

      return HandleDoesPassResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }
}

export default EnrollmentApi;
