import React from 'react';
import { FOOTER_DISCLAIMER_LINKS_IDS, MONEY_TRANSFER_DIRECTIVE_TYPES, TRANSACTION_SERVICE_TYPES } from '../../constants/enums';
import { EVENTS } from '../../constants/events';
import { trackEvent } from '../../services/eventTracker';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import useClickExternalLink from '../../hooks/useClickExternalLink';
import i18n from '../../i18n/config';

type MoneyTransferDisclaimerLinks = {
  text: string;
  directives: FooterLinkDirective[]
}

type FooterLinkDirective = {
  id: string;
  text: string;
  type: string;
  url: string;
}

const FooterLinks = ({ disclaimerLinks, flexDirection, linkColor, pageName }: { disclaimerLinks: MoneyTransferDisclaimerLinks[], flexDirection?: string, linkColor?: "blue", pageName?: string }) => {
  const serviceType = useSelector((state: RootState) => state.moneyTransfer.workflow.serviceType);
  const openExternalLinksModal = useClickExternalLink();

  const handleClick = (id: string, e: React.MouseEvent<HTMLAnchorElement>, url: string) => {

    if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
      let eventName: string;

      switch (id) {
        case FOOTER_DISCLAIMER_LINKS_IDS.PRIVACY_STATEMENT:
          eventName = EVENTS.TO_ACCOUNT.PRIVACY_POLICY_VIEWED
          break;
        case FOOTER_DISCLAIMER_LINKS_IDS.TERMS_AND_CONDITIONS:
          eventName = EVENTS.TO_ACCOUNT.TERMS_VIEWED
          break;
        case FOOTER_DISCLAIMER_LINKS_IDS.ESIGN:
          eventName = EVENTS.TO_ACCOUNT.ESIGN_VIEWED
          break;
      }

      trackEvent(eventName, { screen_name: pageName })
    }

    openExternalLinksModal(e, url);
  }

  return (
    <div className={`flex money-transfer__footer-links ${flexDirection} ${linkColor}`}>
      {disclaimerLinks.map(link => {
        if (link.directives[0].type === MONEY_TRANSFER_DIRECTIVE_TYPES.EXTERNAL_LINK) {
          return <a key={link.directives[0].id} href={link.directives[0].url} target="_blank" rel="noopener noreferrer" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} className="footer-directive-link" onClick={(e) => handleClick(link.directives[0].id, e, link.directives[0].url)}>{link.directives[0].text}</a>
        }
      })}
    </div>
  )
}

export default FooterLinks;