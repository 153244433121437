import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import slide1 from "../../assets/images/facecheck/slide-1.svg";
import slide2 from "../../assets/images/facecheck/slide-2.svg";
import slide3 from "../../assets/images/facecheck/slide-3.svg";
import prevIcon from "../../assets/images/icons/carouselPrev.svg";
import nextIcon from "../../assets/images/icons/carouselNext.svg";
import { Carousel } from "react-bootstrap";
import ActionLink from "../../components/Common/ActionLink";
import Action from "../../components/Common/Action";
import Modal from "../../components/Common/Modal";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import { PAGES } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker } from "../../services/eventTracker";

/**
 * Allows api login and handles retrieving necessary app/user data for the store
 */
class IntroFaceCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      showSkipModal: false,
    };

    this.openSkipModal = this.openSkipModal.bind(this);
    this.closeSkipModal = this.closeSkipModal.bind(this);
    this.goToSetup = this.goToSetup.bind(this);
    this.goToDashboard = this.goToDashboard.bind(this);
  }

  renderSkipModal() {
    var action = {
      title: PAGES.FACECHECK.INTRO_MODAL_SKIP,
      onClick: this.goToDashboard,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    };
    var exitAction = {
      title: PAGES.FACECHECK.INTRO_MODAL_SETUP,
      onClick: this.goToSetup,
      displayType: ACTION_DISPLAY_TYPES.LINK,
    };

    return (
      <Modal
        actions={[exitAction, action]}
        open={this.state.showSkipModal}
        onClose={this.closeSkipModal}
        title={PAGES.FACECHECK.INTRO_SKIP_TITLE}
        content={PAGES.FACECHECK.INTRO_SKIP_CONTENT}
      />
    );
  }

  openSkipModal() {
    eventTracker.track("FaceCheck_Enrollment_Skip");

    this.setState({ showSkipModal: true });
  }

  closeSkipModal() {
    this.setState({ showSkipModal: false });
  }

  goToSetup() {
    eventTracker.track("FaceCheck_Enrollment_Setup");

    const { history } = this.props;
    history.push(ROUTES.AUTH.SETUP_FACECHECK);
  }

  goToDashboard() {
    location.href = ROUTES.EXTERNAL.DASHBOARD;
  }

  render() {
    const { forceEnrollment } = this.props;

    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1>Set up FaceCheck</h1>
            </div>
          </div>
        </div>
        <div className="v2 page-block">
          <div className="facecheck-carousel">
            <Carousel
              interval={null}
              prevIcon={
                <img
                  src={prevIcon}
                  onClick={eventTracker.track("FaceCheck_Enrollment_Arrow")}
                />
              }
              nextIcon={
                <img
                  src={nextIcon}
                  onClick={eventTracker.track("FaceCheck_Enrollment_Arrow")}
                />
              }
            >
              <Carousel.Item>
                <div className="text-center">
                  <img src={slide1} />
                  <h2>{PAGES.FACECHECK.INTRO_SLIDE_1_TITLE}</h2>
                  <p>{PAGES.FACECHECK.INTRO_SLIDE_1_CONTENT}</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="text-center">
                  <img src={slide2} />
                  <h2>{PAGES.FACECHECK.INTRO_SLIDE_2_TITLE}</h2>
                  <p>{PAGES.FACECHECK.INTRO_SLIDE_2_CONTENT}</p>
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <div className="text-center">
                  <img src={slide3} />
                  <h2>{PAGES.FACECHECK.INTRO_SLIDE_3_TITLE}</h2>
                  <p>{PAGES.FACECHECK.INTRO_SLIDE_3_CONTENT}</p>
                </div>
              </Carousel.Item>
            </Carousel>
            <div className="text-center text-right-sm">
              {!forceEnrollment && (
                <ActionLink
                  classes="btn btn-link spacing-bottom-medium spacing-bottom-none-sm spacing-right-medium-sm btn-lg"
                  clickFunc={this.openSkipModal}
                  text={PAGES.FACECHECK.INTRO_SKIP_TO_DASHBOARD}
                />
              )}
              <Action
                className="btn-lg"
                onClick={this.goToSetup}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                title={PAGES.FACECHECK.INTRO_SETUP_NOW}
              />
            </div>
          </div>
        </div>
        {this.renderSkipModal()}
      </div>
    );
  }
}

IntroFaceCheck.propTypes = {
  /** Display loading spinner action */
  DisplayLoading: PropTypes.any,
  /** Hide loading spinner action */
  FinishLoading: PropTypes.any,
  /** react-router history */
  history: PropTypes.any,
  forceEnrollment: PropTypes.bool,
};

function mapStateToProps(state) {
  return {
    forceEnrollment:
      state.auth &&
      state.auth.authResult &&
      state.auth.authResult.directive &&
      state.auth.authResult.directive.forceEnrollment,
  };
}

export default connect(mapStateToProps, {
  DisplayLoading,
  FinishLoading,
})(IntroFaceCheck);
