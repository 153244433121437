import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

const AuthenticationAppInputs = ({ submitFunc, resetValues, formId, classes }) => {
  const [inputField, setInputField] = useState({
    'auth-app-code-1': '',
    'auth-app-code-2': '',
    'auth-app-code-3': '',
    'auth-app-code-4': '',
    'auth-app-code-5': '',
    'auth-app-code-6': ''
  });
  const [pasted, setPasted] = useState(false);

  useEffect(() => {
    if (resetValues) {
      setInputField({
        'auth-app-code-1': '',
        'auth-app-code-2': '',
        'auth-app-code-3': '',
        'auth-app-code-4': '',
        'auth-app-code-5': '',
        'auth-app-code-6': ''
      })
    }
  }, [resetValues])

  const handleChange = (e) => {
    // When pasting in code, onChange event is fired, causing issue with input that was focused being overwritten 
    if (!pasted) {
      // Only allow numbers
      let value = e.target.value.replace(/\D/g, '');
      // If e.target.value is different than current inputValue, change input value
      value = value.replace(inputField[e.target.name], '');
      setInputField({ ...inputField, [e.target.name]: value });

      // If value has been entered and element has sibling, move focus to next sibling
      if (value.length === 1) {
        const currentEl = document.querySelector(`#${e.target.id}`);
        const nextSibling = currentEl.nextElementSibling;

        if (nextSibling) {
          nextSibling.focus();
        }
      }
    }

    // Set pasted variable to false, to allow user to manually input code again
    setPasted(false);
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Backspace' && e.target.value === '') {
      const currentEl = document.querySelector(`#${e.target.id}`);
      const prevSibling = currentEl.previousElementSibling.previousElementSibling;

      if (prevSibling) {
        prevSibling.focus();
      }
    }
  }

  const handlePaste = (e) => {
    const text = e.clipboardData.getData('text/plain');
    const splitText = text.split('');

    setInputField({
      'auth-app-code-1': splitText[0].replace(/\D/g, '') || '',
      'auth-app-code-2': splitText[1].replace(/\D/g, '') || '',
      'auth-app-code-3': splitText[2].replace(/\D/g, '') || '',
      'auth-app-code-4': splitText[3].replace(/\D/g, '') || '',
      'auth-app-code-5': splitText[4].replace(/\D/g, '') || '',
      'auth-app-code-6': splitText[5].replace(/\D/g, '') || ''
    });

    // When pasting in code, onChange event is fired, causing issue with input that was focused being overwritten
    setPasted(true);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const values = Object.values(inputField).join('');
    await submitFunc(values);
  }

  return (
    <form className={`authentication-app-inputs ${classes}`} name={formId} onSubmit={handleSubmit} id={formId}>
      <label htmlFor="auth-app-code-1" className='sr-only'>Security Code Number 1</label>
      <input id="auth-app-code-1" name="auth-app-code-1" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-1']} />
      <label htmlFor="auth-app-code-2" className='sr-only'>Security Code Number 2</label>
      <input id="auth-app-code-2" name="auth-app-code-2" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-2']} />
      <label htmlFor="auth-app-code-3" className='sr-only'>Security Code Number 3</label>
      <input id="auth-app-code-3" name="auth-app-code-3" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-3']} />
      <label htmlFor="auth-app-code-4" className='sr-only'>Security Code Number 4</label>
      <input id="auth-app-code-4" name="auth-app-code-4" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-4']} />
      <label htmlFor="auth-app-code-5" className='sr-only'>Security Code Number 5</label>
      <input id="auth-app-code-5" name="auth-app-code-5" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-5']} />
      <label htmlFor="auth-app-code-6" className='sr-only'>Security Code Number 6</label>
      <input id="auth-app-code-6" name="auth-app-code-6" type="number" onPaste={handlePaste} onKeyDown={handleKeyDown} onChange={handleChange} value={inputField['auth-app-code-6']} />
    </form>
  );
}

AuthenticationAppInputs.propTypes = {
  /** Parent level submit function */
  submitFunc: PropTypes.func.isRequired,
  /** Handle if input values should all be reset to '' */
  resetValues: PropTypes.bool,
  /** form id to allow submission to be triggered by outside button */
  formId: PropTypes.string,
  /** string of css classes to add to form element */
  classes: PropTypes.string,
};


export default AuthenticationAppInputs;