import { WAGE_STATEMENTS } from "../constants/actionTypes";
import initialState from "./initialState";

export default function wageStatementReducer(
  state = initialState.wageStatements,
  action
) {
  switch (action.type) {
    case WAGE_STATEMENTS.RECEIVE_WAGE_STATEMENTS_SUCCESS: {
      const list = action.list.sort((a, b) => {
        return a.date > b.date ? -1 : 1;
      });
      return Object.assign({}, state, { list });
    }
    case WAGE_STATEMENTS.RECEIVE_WAGE_STATEMENTS_FAILURE: {
      return Object.assign({}, state, { error: action.message });
    }
    default:
      return state;
  }
}
