import { AUTH } from "../constants/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case AUTH.UPDATE_USERNAME:
      return Object.assign({}, state, {
        ...state,
        username: action.payload,
      });
    case AUTH.CLEAN_LOGIN_TOKEN:
      return initialState.auth;
    case AUTH.RECEIVE_AUTH_RESULT:
      return Object.assign({}, state, {
        ...state,
        authResult: {
          ...state.authResult,
          ...action.payload,
        },
      });
    case AUTH.TIMEOUT_ID:
      return Object.assign({}, state, {
        ...state,
        timeoutId: action.payload,
      });
    case AUTH.UPDATE_AUTH_DIRECTIVE:
      return Object.assign({}, state, {
        ...state,
        authResult: {
          ...state.authResult,
          directive: action.payload,
        },
      });
    case AUTH.UPDATE_FACECHECK_ENROLLMENT_INFO:
      return Object.assign({}, state, {
        ...state,
        authResult: {
          ...state.authResult,
          directive: action.payload,
        },
      });
    case AUTH.UPDATE_DASHBOARD_INAPP_MESSAGE:
      return Object.assign({}, state, {
        ...state,
        authResult: {
          ...state.authResult,
          ...action.payload,
        },
      });
    case AUTH.UPDATE_ACCEPT_DOCS:
      return Object.assign({}, state, {
        ...state,
        needsToAcceptDocs: action.payload,
      });
    case AUTH.UPDATE_FACECHECK_AUTOSTART:
      return Object.assign({}, state, {
        ...state,
        autoStartFaceCheckSession: action.payload,
      });
    case AUTH.UPDATE_SUPPORT:
      return Object.assign({}, state, {
        ...state,
        support: {
          ...state.support,
          ...action.payload,
        },
      });
    default:
      return state;
  }
}
