import { ROUTES } from "../constants/api";
import {
  HandleNewResponse,
  HandleNewErrorResponse,
} from "./handlers";
import {
  post
} from "./api";

/**
 * Handles calls to the User API
 */
class ExchangeRateApi {

  /**
   * Gets exchange rate, give amount, source currency code, destination country id, and destination currency id
   * @returns {Promise<object>}
   * @constructor
   */
  static async CheckExchangeRate(amount: string, sourceCurrencyCode: string, destinationCountryId: number, destinationCurrencyId: number) {
    try {
      const response = await post(ROUTES.EXCHANGE_RATE.CHECK_EXCHANGE_RATES, {
        amount,
        sourceCurrencyCode,
        destinationCountryId,
        destinationCurrencyId
      });

      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

}

export default ExchangeRateApi;