import React from "react";
import PropTypes from "prop-types";

class ValidationSummary extends React.Component {
  constructor(props) {
    super(props);
    let errors = [];
    if (this.props.errors) errors = this.props.errors;
    this.setValidationRef = (element) => {
      this.validationSummary = element;
    };
    this.state = { className: "validation-summary-valid", errors: errors };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        className:
          nextProps.errors.length === 0
            ? "validation-summary-valid"
            : "validation-summary-errors",
      });
    }
  }
  setErrors(errors) {
    this.setState({ className: "validation-summary-errors", errors: errors });
  }
  render() {
    let key = 0;

    const errors = this.state.errors.map(function (e) {
      key++;
      return <li key={"li" + key} dangerouslySetInnerHTML={{ __html: e }}></li>;
    });
    return (
      <div
        className={this.state.className}
        data-valmsg-summary="true"
        ref={this.setValidationRef}
      >
        <ul>{errors}</ul>
      </div>
    );
  }
}

ValidationSummary.propTypes = {
  errors: PropTypes.any,
};

export default ValidationSummary;
