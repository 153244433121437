import React from "react";
import PropTypes from "prop-types";
import { COMMON } from "../../../constants/localization";
import AlertModal from "./AlertModal";

const GenericErrorModal = (props) => {
  return (
    <AlertModal
      className="generic-error-modal"
      open={props.open}
      onClose={props.onClose}
      title={COMMON.GENERIC_ERROR_MODAL_HEADER}
      content={COMMON.ERROR}
    />
  );
};

GenericErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default GenericErrorModal;
