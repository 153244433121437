import React from "react";
import PropTypes from "prop-types";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

const FlowSubmitButton = (props) => {
  return (
    <Action
      title={props.title || COMMON.SUBMIT}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      {...props}
    />
  );
};

FlowSubmitButton.propTypes = {
  title: PropTypes.string,
};

export default FlowSubmitButton;
