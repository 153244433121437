import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import { COMMON, PAGES } from "../../../constants/localization";
import EnrollmentApi from "../../../api/enrollment";
import FlowScreen from "../../Layouts/FlowScreen";
import ValidationSummary from "../ValidationSummary";
import GenericForm from "../../Common/DynamicFields/GenericForm";
import PageBlock from "../../Layouts/PageBlock";
import ActionRow from "../../Layouts/ActionRow";
import ActionLink from "../../Common/ActionLink";
import Action from "../../Common/Action";
import SecurityQuestions from "../../../containers/SecurityQuestions/SecurityQuestions";
import { UpdateCurrentPage } from "../../../actions/enrollment";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";

const AccountSecurityForm = ({ setShowSpinner }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [securityQuestions, setSecurityQuestions] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const formData = useSelector(
    (state) => state.form[PAGES.ENROLLMENT.ACCOUNT_SECURITY.FORM_NAME]
  );
  const userData = useSelector((state) => state.enrollment.userData);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  /**
   * Call getSecurityQuestions when userData has been updated with data from AccountSecurity component
   */
  useEffect(() => {
    if (userData) {
      getSecurityQuestions();
    }
  }, [userData]);

  /**
   * Get security questions
   */
  const getSecurityQuestions = async () => {
    try {
      let response = await EnrollmentApi.GetSecurityQuestions();
      setSecurityQuestions(response.groups);
    } catch (err) {
      setErrors([PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  /**
   * Handle form submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    const { values } = formData;

    const questions = createQuestionsArr(values);

    try {
      const response = await EnrollmentApi.SaveAccountSecurityV2(
        userData.workflowId,
        questions
      );

      trackEvent(EVENTS.ENROLLMENT.SECURITY_QUESTIONS_SUBMITTED, { success: true });
      setShowSpinner(false);
      dispatch(UpdateCurrentPage(response.nextPage));
    } catch (err) {
      setShowSpinner(false);
      trackEvent(EVENTS.ENROLLMENT.SECURITY_QUESTIONS_SUBMITTED, { success: false, failure_reason: err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL });
      setErrors([err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL]);
    }
  };

  /**
   * Create array from submitted form data
   * @param questions AccountSecurityForm data
   */
  const createQuestionsArr = (questions) => {
    const formattedDate = formatDateObjToStr(questions.Answer3);

    return [
      {
        groupId: 1,
        passwordQuestionId: questions.Question1,
        answer: questions.Answer1,
      },
      {
        groupId: 2,
        passwordQuestionId: questions.Question2,
        answer: questions.Answer2,
      },
      {
        groupId: 3,
        passwordQuestionId: questions.Question3,
        answer: formattedDate,
      },
    ];
  };

  /**
   * Format JS date object to string
   * @param dateObj SecurityQuestionAnswer3 JS date object
   */
  const formatDateObjToStr = (dateObj) => {
    const newDate = new Date(dateObj);
    const year = newDate.getFullYear();
    const month =
      newDate.getMonth() + 1 < 10
        ? `0${newDate.getMonth() + 1}`
        : newDate.getMonth();
    const date =
      newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

    return `${year}-${month}-${date}`;
  };

  return (
    <FlowScreen flowTitle="Create Your Online Account">
      <GenericForm
        form={PAGES.ENROLLMENT.ACCOUNT_SECURITY.FORM_NAME}
        onSubmit={handleSubmit}
        onUpdate={(isFormValid) => setIsDisabled(!isFormValid)}
      >
        <GenericForm.Body>
          <PageBlock>
            <PageBlock.IntroText>
              {
                "In order to manage your bank accounts and allocations, you must select and answer the following security quetions to create your account."
              }
            </PageBlock.IntroText>
            <ValidationSummary errors={errors} />
            <PageBlock.Body>
              <SecurityQuestions questions={securityQuestions} />
              <ActionRow>
                <ActionRow.Backward>
                  <ActionLink
                    clickFunc={() => dispatch(UpdateCurrentPage(currentPage - 1))}
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={isDisabled}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </GenericForm>
    </FlowScreen>
  );
};

AccountSecurityForm.propTypes = {
  /** set showSpinner boolean from AccountSecurity */
  setShowSpinner: PropTypes.func.isRequired,
  /** react-router history */
  history: PropTypes.object.isRequired,
};

AccountSecurityForm.displayName = "AccountSecurityFormComponent";

export default AccountSecurityForm;
