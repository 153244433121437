import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Modal, Button } from "react-bootstrap";
import _ from "lodash";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import { useSelector } from "react-redux";
import i18n from "../../i18n/config";

const DisclosureModal = ({ showModal, setShowModal, docs }) => {
  const openExternalLinksModal = useClickExternalLink();
  const { open } = useSelector((state) => state.global.externalLinksModal);

  const documents = docs.map(function (doc) {
    return (
      <tr key={_.uniqueId()}>
        <td>
          <span
            className="text-underline"
            dangerouslySetInnerHTML={{ __html: doc.title }}
          ></span>
        </td>
        <td>
          <a
            href={doc.url}
            target="_blank"
            rel="noreferrer noopener"
            className="btn btn-primary small"
            onClick={(e) => openExternalLinksModal(e, doc.url)}
            title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
          >
            <span className="sr-only">{doc.title}</span>{/*}
            */}View
          </a>
        </td>
      </tr>
    );
  });

  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      className={`disclosure-modal v2 ${open ? "hide" : ""}`}
    >
      <Modal.Header closeButton>
        <Modal.Title>Disclosures</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={12}>
            <table className="navigatorTable">
              <tbody>{documents}</tbody>
            </table>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button bsStyle="info" onClick={() => setShowModal(false)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

DisclosureModal.propTypes = {
  docs: PropTypes.array,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
};

export default DisclosureModal;
