import React from 'react';
import PropTypes from "prop-types";
import { SUPPORT_CONVERSATION_CATEGORY_TYPES } from '../../constants/enums';

import BankAccountsBlue from "../../assets/images/support/BankAccounts-blue.svg";
import BankAccountsGrey from "../../assets/images/support/BankAccounts-grey.svg";
import CardBlue from "../../assets/images/support/Card-blue.svg";
import CardGrey from "../../assets/images/support/Card-grey.svg";
import DisputeBlue from "../../assets/images/support/Dispute-blue.svg";
import DisputeGrey from "../../assets/images/support/Dispute-grey.svg";
import TransferProblemBlue from "../../assets/images/support/TransferProblem-blue.svg";
import TransferProblemGrey from "../../assets/images/support/TransferProblem-grey.svg";
import HelpBlue from "../../assets/images/support/Help-blue.svg";
import HelpGrey from "../../assets/images/support/Help-grey.svg";

const MessageIcon = ({ categoryId, isClosed }) => {
  let iconSrc;
  switch (categoryId) {
    case SUPPORT_CONVERSATION_CATEGORY_TYPES.BANK_ACCOUNT:
      iconSrc = isClosed ? BankAccountsGrey : BankAccountsBlue;
      break;
    case SUPPORT_CONVERSATION_CATEGORY_TYPES.MY_CARD:
      iconSrc = isClosed ? CardGrey : CardBlue;
      break;
    case SUPPORT_CONVERSATION_CATEGORY_TYPES.TRANSACTION_QUESTION_DISPUTE:
      iconSrc = isClosed ? DisputeGrey : DisputeBlue;
      break;
    case SUPPORT_CONVERSATION_CATEGORY_TYPES.MONEY_TRANSFER:
      iconSrc = isClosed ? TransferProblemGrey : TransferProblemBlue;
      break;
    default:
      iconSrc = isClosed ? HelpGrey : HelpBlue;
  }

  return (
    <img src={iconSrc} className="profile-icon spacing-right-tiny spacing-bottom-tiny" />
  );
}

MessageIcon.propTypes = {
  /** Support conversation category type from API  */
  categoryId: PropTypes.string,
  /** Is the conversation marked closed */
  isClosed: PropTypes.bool.isRequired,
};

export default MessageIcon;