import React from "react";

/**
 * Renders a character counter in the form of 1/30
 * @param {number} max The maximum number of characters allowed.
 * @param {number} current The minimum number of characters allowed.
 */
const renderCounter = (max, current) => {
  if (max > 0 && max < 999) {
    return (
      <span className="counter">
        {current}/{max}
      </span>
    );
  }
};

export { renderCounter };
