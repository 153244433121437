import { CARD } from "../constants/actionTypes";
import initialState from "./initialState";

export default function cardReducer(state = initialState.card, action) {
  switch (action.type) {
    case CARD.RECEIVE_USER_CARDS_SUCCESS: {
      let card = action.cards[0];
      return Object.assign({}, state, { ...card });
    }
    case CARD.GET_USER_CARDS_STATUS: {
      let { status } = action;
      return Object.assign({}, state, { status });
    }
    case CARD.GET_CARD_TRANSACTION_HISTORY: {
      let { transactions } = action;
      return Object.assign({}, state, { transactions });
    }
    case CARD.GET_AVAILABILITY: {
      let { availability } = action;
      return Object.assign({}, state, { availability });
    }
    case CARD.SHOW_ODT_MODAL: {
      let availability = {...state.availability}
      availability.showODTModal = action.showODTModal;
      return Object.assign({}, state, { availability });
    }
    default:
      return state;
  }
}
