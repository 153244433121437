import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { COMMON } from "../../../constants/localization";
import { ROUTES } from "../../../constants/clientRoutes";
import {
  ACTION_DISPLAY_TYPES,
  IN_APP_MESSAGE_TYPE,
} from "../../../constants/enums";
import Modal from "../Modal";
import { handleShowInAppMessageModal } from "../../../actions/auth";

/**
 * Currently only displays Login/Dashboard-type in-app messages
 */
const DashboardInAppMessageModal = (props) => {
  const { inAppMessage, open, handleShowInAppMessageModal } = props;

  if (!open || inAppMessage.type === null) {
    return null;
  }

  let allowSkip = false;
  let primaryAction = null;
  let secondaryAction = null;
  let disabled = false;
  let actionTitle = "";

  switch (inAppMessage.type) {
    case IN_APP_MESSAGE_TYPE.SECURITY_QUESTION_UPDATE_OPTIONAL:
      allowSkip = true;
      actionTitle = COMMON.UPDATE;
      primaryAction = () =>
        handleShowInAppMessageModal(
          `${ROUTES.SETTINGS.SECURITY_QUESTIONS}?isFocused=True&msgType=${5}`
        );
      secondaryAction = () =>
        handleShowInAppMessageModal(ROUTES.EXTERNAL.DASHBOARD);
      break;
    case IN_APP_MESSAGE_TYPE.SECURITY_QUESTION_UPDATE_REQUIRED:
      actionTitle = COMMON.UPDATE;
      primaryAction = () =>
        handleShowInAppMessageModal(
          `${ROUTES.SETTINGS.SECURITY_QUESTIONS}?isFocused=True&msgType=${4}`
        );
      disabled = true;
      break;
    default:
      break;
  }

  const actions = [
    {
      title: actionTitle,
      onClick: primaryAction,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      className: "btn-lg",
    },
  ];

  if (allowSkip) {
    actions.splice(0, 0, {
      title: COMMON.SKIP,
      onClick: secondaryAction,
      displayType: ACTION_DISPLAY_TYPES.LINK,
      className: "btn-lg",
    });
  }

  return (
    <Modal
      className="large-content"
      open={open}
      onClose={() => ({})}
      actions={actions}
      title={inAppMessage.title || ""}
      content={inAppMessage.description || ""}
      closeOnOverlayClick={false}
      hideCloseIcon={true}
      disabled={disabled}
    />
  );
};

DashboardInAppMessageModal.propTypes = {
  inAppMessage: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleShowInAppMessageModal: PropTypes.func,
};

export default connect(null, { handleShowInAppMessageModal })(
  DashboardInAppMessageModal
);
