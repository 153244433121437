import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSelector, connect } from "react-redux";
import PersonalDataForm from "../../components/Enrollment/Forms/PersonalDataForm";
import SessionEndModal from "../../components/Enrollment/SessionEndModal";
import SpinningLoader from "../../components/Common/SpinningLoader";
import PagePathConstants from "../../constants/page-numbers";

const PersonalData = ({ userData }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  if (currentPage !== PagePathConstants.PERSONAL_DATA) {
    return null;
  }

  return (
    <div className="v2">
      <SpinningLoader show={showSpinner} />
      <PersonalDataForm
        userData={userData}
        setShowSpinner={setShowSpinner}
      />
      <SessionEndModal />
    </div>
  );
};

PersonalData.propTypes = {
  /** enrollment user data */
  userData: PropTypes.object,
};

PersonalData.displayName = "PersonalDataComponent";

const mapStateToProps = (state) => {
  return {
    userData: state.enrollment.userData
  };
};

export default connect(mapStateToProps)(PersonalData);
