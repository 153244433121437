import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function sendLimit(
  state = initialState.moneyTransfer.sendLimit,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES: {
      return action.sendLimit;
    }
    default:
      return state;
  }
}
