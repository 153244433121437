import React from "react";
import PropTypes from "prop-types";
import { PAGES } from "../../constants/localization";
import { ICONS } from "../../constants/enums";
import PickupInstructionItem from "./PickupInstructionItem";
import Icon from "../Common/Icon";
import ActionLink from "../Common/ActionLink";
import { SlideDown } from "react-slidedown";
import { eventTracker, EVENTS } from "../../services/eventTracker";

/**
 *
 * @param instructions
 * @returns {*}
 * @constructor
 */
class PickupInstructions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pickupInstructionsShown: false,
    };

    this.toggleInstructions = this.toggleInstructions.bind(this);
  }

  toggleInstructions() {
    /// The tracking event is only necessary when the pickup instructions are being opened.
    if (!this.state.pickupInstructionsShown) {
      eventTracker.track(EVENTS.MoneyTransfer_PickupInstructions);
    }

    this.setState({
      pickupInstructionsShown: !this.state.pickupInstructionsShown,
    });
  }

  render() {
    const { instructions } = this.props;
    let shown = this.state.pickupInstructionsShown;

    return (
      <div className={`mt-pickup-instructions ${shown ? "open" : "closed"}`}>
        <div>
          <ActionLink
            classes="slider-button bold"
            clickFunc={this.toggleInstructions}
          >
            <Icon icon={ICONS.CHEVRON_RIGHT} />
            {PAGES.MONEY_TRANSFER.SUCCESS.PICKUP_INSTRUCTIONS}
          </ActionLink>
        </div>
        <SlideDown closed={!shown} className="slider">
          <p>{PAGES.MONEY_TRANSFER.SUCCESS.PICKUP_INSTRUCTIONS_HEADER}</p>
          <div className="instructions-list">
            {instructions.map((i) => {
              return (
                <PickupInstructionItem
                  key={i.id}
                  title={i.title}
                  content={i.content}
                  icon={i.icon}
                />
              );
            })}
          </div>
        </SlideDown>
      </div>
    );
  }
}

PickupInstructions.propTypes = {
  instructions: PropTypes.array.isRequired,
};

export default PickupInstructions;
