import * as React from 'react';
import { DisplaySectionType } from '../../types/api/moneyTransfer';
import DisplaySectionField from './DisplaySectionField';
import { TransactionSummaryProps } from '../../types/props';

const DisplaySection = ({ sections, transactionSummary, className }: { sections: DisplaySectionType[], transactionSummary?: TransactionSummaryProps, className?: string }) => {
  const renderTransactionSummary = () => (
    <div className="text-center">
      <span className="display__block spacing-bottom-xs">{transactionSummary.heading}</span>
      <span className="shark-grey display__block spacing-bottom-xs font-regular">{transactionSummary.label}</span>
      <span className="display__block spacing-bottom-xs">{transactionSummary.value}</span>
    </div>
  )

  return (
    <div className={`grid grid-cols-2 spacing-bottom-small ${className}`}>
      {sections.map((section: DisplaySectionType) => (
        <div className="card" key={section.id}>
          <div className="card-header">
            {transactionSummary
              ? renderTransactionSummary()
              : section.label
            }
          </div>
          <div className="card-body">
            {section.fields.map(field => (
              <DisplaySectionField field={field} key={field.id} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default DisplaySection;