import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InjectedFormProps, initialize, SubmissionError, reduxForm } from "redux-form";
import { PAGES } from "../../constants/localization";
import ActionLink from "../../components/Common/ActionLink";
import { ACTION_DISPLAY_TYPES, FIELD_CATEGORIES, MONEY_TRANSFER_STEPS, MONEY_TRANSFER_FIELD_IDS } from "../../constants/enums";
import { AddWorkflowStep, SetWorkflowPageNumber } from "../../actions/moneyTransfer";
import { MoneyTransferPage, WorkflowStep, MoneyTransferField } from "../../types/moneyTransfer";
import { RootState } from "../../types/redux";

import DynamicForm from "../../components/MoneyTransfer/DynamicForm";
import Action from "../../components/Common/Action";
import FooterLinks from "../../components/MoneyTransfer/FooterLinks";
import DisplaySection from "../../components/MoneyTransfer/DisplaySection";
import MoneyTransferApi from "../../api/moneyTransfer";
import { HTTP_STATUS_CODES } from "../../constants/api";
import { ValidateWorkflowResponse } from "../../types/api/moneyTransfer";
import Review from "./Review";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

type CustomProps = {
  pageData: MoneyTransferPage;
  handleError: (error: string) => void;
}

const DynamicFormWrapper: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = ({ pageData, handleError, handleSubmit, error, valid, submitting, submitFailed }) => {
  const dispatch = useDispatch();
  const workflow = useSelector((state: RootState) => state.moneyTransfer.workflow);
  const pageNumber = workflow.pages.findIndex((page: WorkflowStep) => page.type === pageData.id) + 1;

  useEffect(() => {
    window.scrollTo(0, 0);

    // Set up initial values for redux-form based on sections.fields that we get back from api
    // But don't do this if the form has been submitted and failed, otherwise we reinitialize the form
    if (pageData.id === FIELD_CATEGORIES.SENDER && !submitFailed) {
      const initialValues = {};
      pageData.sections.forEach(section => {
        section.fields.forEach(field => {
          initialValues[field.serviceProviderFieldId] = field.value
        })
      })

      dispatch(initialize(PAGES.MONEY_TRANSFER.FORM_NAME, initialValues));
    }

  }, []);

  const onNext = () => {
    handleGAEvent();
    dispatch(SetWorkflowPageNumber(pageNumber + 1));
  }

  const onBack = () => {
    dispatch(SetWorkflowPageNumber(pageNumber - 1));
  }

  /**
   * Handle sending events to GA
   */
  const handleGAEvent = () => {
    let eventName: string;
    switch (pageData.id) {
      case FIELD_CATEGORIES.SENDER:
        eventName = EVENTS.TO_ACCOUNT.SENDER_INFO_COMPLETED;
        break;
      case FIELD_CATEGORIES.RECEIVER:
        eventName = EVENTS.TO_ACCOUNT.RECEIVER_INFO_COMPLETED;
        break;
      case FIELD_CATEGORIES.BANK:
        eventName = EVENTS.TO_ACCOUNT.BANK_INFO_COMPLETED;
        break;
    }

    trackEvent(eventName);
  }

  const renderFormErrors = (errors) => {
    if (errors && errors.length > 0) {
      return (
        <ul className="bullet-list error-list">
          {errors.map((error) => (
            <li key={error.errorCode}>{error.errorMessage}</li>
          ))}
        </ul>
      );
    }
  }

  /**
   * Receiver (bank account) fields use dot notation in field name
   * Redux is saving that field name as a nested object in the form values.
   * E.g. { receiver: bank_account: { account_number: 1234 }}
   * When we get an error the field will be receiver.bank_account.account_number, but we want to update the field with an error in redux
   * So we need to convert the dot notation string to an object to update the field with an error.
   */
  const formatDotNotationStringToObj = (key: string, value) => {
    let result = {};
    let object = result;
    let arr = key.split('.');
    for (let i = 0; i < arr.length - 1; i++) {
      object = object[arr[i]] = {};
    }
    object[arr[arr.length - 1]] = value;
    return result;
  }

  /**
   * Receiver bank account fields come to us in a nested object.
   * This function flattens the object to a single level to send to the api.
   * E.g. { receiver: bank_account: { account_number: 1234 }} becomes { receiver.bank_account.account_number: 1234 }
   */
  const flattenObject = (ob) => {
    const toReturn = {};

    for (let i in ob) {
      if (i === "senderPhone") {
        toReturn[i] = ob[i];
        continue;
      }

      if (!ob.hasOwnProperty(i)) continue;

      if ((typeof ob[i]) == 'object' && ob[i] !== null) {
        let flatObject = flattenObject(ob[i]);
        for (let x in flatObject) {
          if (!flatObject.hasOwnProperty(x)) continue;

          toReturn[i + '.' + x] = flatObject[x];
        }
      } else {
        toReturn[i] = ob[i];
      }
    }
    return toReturn;
  }

  /**
   * Takes the values from the form and flattens them to a single level to send to the api.
   */
  const workflowValues = (values) => {
    const flattenedValues = flattenObject(values);
    let fields = [];

    if (values != undefined) {
      fields = Object.entries(flattenedValues).map(
        ([serviceProviderFieldId, value]) => ({ serviceProviderFieldId, value })
      );
    }
    return fields;
  }

  const createError = (field: MoneyTransferField) => {
    // if field name has dot notation, we need to format it to an object
    if (field.serviceProviderFieldId.includes('.')) {
      const obj = formatDotNotationStringToObj(field.serviceProviderFieldId, field.validationError[0].errorMessage);
      return obj;
    } else {
      return { [field.serviceProviderFieldId]: field.validationError[0].errorMessage }
    }
  }

  /**
   * Gets errors from the workflow object based on the category passed in.
   * This is used to trigger validation on the sender/receiver pages.
   * */
  const handleWorkflowErrors = (pages: MoneyTransferPage[]) => {
    let errors = {};
    let pageIdWithError = null;
    if (pages) {

      pages.forEach((page: MoneyTransferPage) => {
        if (page.sections) {
          for (let section of page.sections) {
            for (let field of section.fields) {
              if (field.validationError?.length > 0) {
                const error = createError(field);
                errors = { ...errors, ...error };
                if (!pageIdWithError) {
                  pageIdWithError = page.id;
                }
              }
            }
          }
        }
      });
    }

    return {
      errors,
      pageIdWithError
    }
  }

  /**
   * Finds bank name field from response, if exists, and sends bank name to GA
   * */
  const handleBankNameGAEvent = (response: ValidateWorkflowResponse) => {
    // Find bank name field from response and send bank name to GA
    const bankNameField = response.result.displaySections.find(section => section.id === FIELD_CATEGORIES.BANK).fields.find(field => field.id === MONEY_TRANSFER_FIELD_IDS.BANK_NAME);
    if (bankNameField) {
      trackEvent(EVENTS.TO_ACCOUNT.BANK_INFO_COMPLETED, {
        [EVENTS.PARAMS.WU_TO_ACCOUNT_BANK_NAME]: bankNameField.formattedValue
      })
    }
  }

  const onSubmit = async (e) => {
    // If we are on the last page, we need to validate the workflow
    // Else, we can just move to the next page
    if (pageNumber === workflow.pages.length) {
      const values = workflowValues(e);

      try {
        const response = await MoneyTransferApi.ValidateWorkflow(workflow.quoteId, values, workflow.serviceType) as ValidateWorkflowResponse;

        handleBankNameGAEvent(response);

        dispatch(AddWorkflowStep(
          {
            number: workflow.pages.length + 1,
            order: workflow.pages.length + 1,
            title: response.result.title,
            type: MONEY_TRANSFER_STEPS.REVIEW,
            renderComponent: () => (
              <Review
                pageData={response}
                handleError={handleError}
              />
            )
          }
        ))

      } catch (err) {
        // If the error is a validation error, we need to handle the errors and display them
        if (err.status === HTTP_STATUS_CODES.VALIDATION_ERROR) {
          const { errors, pageIdWithError } = handleWorkflowErrors(err.data.result.pages);

          if (errors && Object.keys(errors).length > 0) {
            const page = workflow.pages.find((page: WorkflowStep) => page.type === pageIdWithError);
            dispatch(SetWorkflowPageNumber(page.order));
            throw new SubmissionError(errors);
          } else {
            handleError(err);
          }
        } else {
          handleError(err);
        }
      }
    } else {
      onNext();
    }
  }

  return (
    <>
      {pageData &&
        <p className="large spacing-bottom-small">
          {pageData.subTitle}
        </p>
      }


      {pageData &&
        <DisplaySection sections={pageData.displaySections} />
      }

      <form onSubmit={handleSubmit(onSubmit)}>
        {renderFormErrors(error)}

        {pageData && (
          <DynamicForm fieldCategory={pageData} formName={PAGES.MONEY_TRANSFER.FORM_NAME} />
        )}

        <div className="row navRow">
          <div className="col-xs-12 col-sm-6 two">
            <ActionLink clickFunc={onBack} classes="mt-link">
              <div className="glyphicon glyphicon-menu-left link_icon"></div>
              {pageData.previousButton.title}
            </ActionLink>
          </div>
          <div className="col-xs-12 col-sm-6 button-cell one">
            <Action
              type="submit"
              loading={submitting}
              disabled={!valid}
              title={pageData.nextButton.title}
              displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            />
          </div>
        </div>

        {workflow.footer &&
          <FooterLinks
            disclaimerLinks={workflow.footer.disclaimerLinks}
            pageName={pageData.id}
          />
        }

      </form>
    </>
  );
}

const form = reduxForm<{}, CustomProps>({
  form: PAGES.MONEY_TRANSFER.FORM_NAME,
  destroyOnUnmount: false,
})(DynamicFormWrapper);

export default form;