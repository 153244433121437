import React from "react";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import nextIcon from "../../assets/images/icons/RightArrow_White.svg";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

const FlowNextButton = (props) => {
  return (
    <Action
      title={COMMON.NEXT}
      iconRight={nextIcon}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      {...props}
    />
  );
};

export default FlowNextButton;
