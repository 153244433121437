import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import PageBlock from "../../components/Layouts/PageBlock";
import { change, formValueSelector, reduxForm } from "redux-form";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import { COMMON, PAGES, SUPPORT } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../constants/enums";
import ActionLink from "../../components/Common/ActionLink";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import {
  CreateSupportTicket,
  GetSupportCategories,
} from "../../actions/support";
import ErrorBlock from "../../components/Common/ErrorBlock";
import { setAuthSupportInfo } from "../../actions/auth";
import { ROUTES } from "../../constants/clientRoutes";
import { ERROR_CODES } from "../../constants/errorCodes";
import ENV from "../../constants/environment";
import Modal from "../../components/Common/Modal";
import { DOCUMENT_NUMBER } from "../../constants/forms";
import { enrollLogout } from "../../services/enrollment";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

const FORM_NAME = "PublicSupportForm";

class PublicSupport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      submitting: false,
      requiresExtraInfo: false,
      errors: null,
      secondaryOptions: [],
      showDocumentNumberModal: false
    };

    this.mobileErrors = React.createRef();
    this.onBackPress = this.onBackPress.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.toggleDocumentNumberModal = this.toggleDocumentNumberModal.bind(this);
  }

  componentDidMount() {
    enrollLogout();
    this.props.GetSupportCategories().then(() => {
      this.setState({ loading: false });
    });
  }

  onSubmit(values) {
    trackEvent(EVENTS.SUPPORT.SUPPORT_SUBMITTED_LOGGED_OUT_SUPPORT);
    const { CreateSupportTicket, setAuthSupportInfo, history } = this.props;

    this.setState({
      submitting: true,
    });

    CreateSupportTicket(values)
      .then((data) => {
        if (data.supportTicketCreated) {
          setAuthSupportInfo({
            title: PAGES.SUPPORT.TITLE,
            subtitle: PAGES.AUTH.SUPPORT.NEW_TICKET_SUBMITTED,
            ticketInfo: `${PAGES.AUTH.SUPPORT.TICKET_NO} ${data.ticketNumber}`,
            content: `<p>${PAGES.AUTH.SUPPORT.EMAIL_CONFIRMATION}</p><p><b>${data.email}</b></p>`,
          });
          // go to success screen
          history.push(ROUTES.AUTH.SUPPORT);

          return data;
        }

        this.setState({ submitting: false });
      })
      .catch((e) => {
        if (e.response && e.response.data && e.response.data.errors) {
          const remoteErrors = e.response.data.errors;
          const requiresExtraInfo =
            remoteErrors.length > 0 &&
            remoteErrors[0].errorCode === ERROR_CODES.USER.REQUIRES_MORE_INFO;
          this.setState({
            errors: requiresExtraInfo
              ? [{ error: "", errorMessage: SUPPORT.ERROR_USER_NOT_FOUND }]
              : remoteErrors,
            submitting: false,
            requiresExtraInfo: this.state.requiresExtraInfo
              ? true
              : requiresExtraInfo, //once true, always true
          });

          if (requiresExtraInfo && this.mobileErrors.current) {
            //scroll to mobile error if additional fields
            this.mobileErrors.current.scrollIntoView();
          }
        } else if (e instanceof Error) {
          this.setState({ errors: [e], submitting: false });
        }
      });
  }

  onBackPress() {
    window.location.href = ENV.BASE_NAVIGATOR_URL;
  }

  onTypeChange(selectedOption) {
    const { categories, selectedCategory } = this.props;
    if (selectedOption !== selectedCategory) {
      const category = categories.find(
        (category) => category.categoryId === selectedOption
      );
      // reset sub option
      this.props.change(FORM_NAME, "supportRequestSubCategory", "");

      this.setState(
        {
          secondaryOptions: category
            ? this.convertCategories(category.options)
            : [],
        },
        () => {
          // change again to force revalidate form :/
          this.props.change(FORM_NAME, "supportRequestSubCategory", null);
        }
      );
    }
  }

  convertCategories(categories) {
    return categories.map((category) => ({
      label: category.name,
      value: category.categoryId,
    }));
  }

  renderErrors() {
    const { errors } = this.state;
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  toggleDocumentNumberModal() {
    this.setState({ showDocumentNumberModal: !this.state.showDocumentNumberModal })
  }

  render() {
    const { loading, submitting, requiresExtraInfo, secondaryOptions } =
      this.state;
    const { valid, handleSubmit, categories } = this.props;
    return (
      <div>
        <Modal
          onClose={this.toggleDocumentNumberModal}
          open={this.state.showDocumentNumberModal}
          title={DOCUMENT_NUMBER.MODAL_TITLE}
          content={DOCUMENT_NUMBER.MODAL_CONTENT}
        />
        <div className="row">
          <div className="col-sm-12">
            <h1>{SUPPORT.LOGGED_OUT.TITLE}</h1>
          </div>
        </div>
        <form onSubmit={handleSubmit(this.onSubmit)}>
          <PageBlock>
            <PageBlock.Loader loadingContent={loading}>
              <InputSkeletonGroup numInputs={6} />
            </PageBlock.Loader>
            <PageBlock.Body loadingContent={loading}>
              <div className="v2">
                <div className="row">
                  <div
                    className="col-sm-12"
                    data-testid="top-error-container"
                    data-cy="top-error-container"
                  >
                    {this.renderErrors()}
                    <p className="large-normal">
                      {SUPPORT.LOGGED_OUT.DESCRIPTION}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-sm-12 col-md-5"
                    data-testid="form-container"
                  >
                    <DynamicInputField
                      label={SUPPORT.LABELS.EMPLOYEE_ID}
                      id="employeeId"
                      required={true}
                      type={FIELD_TYPES.TEXT}
                    />
                    <DynamicInputField
                      label="Document Number"
                      id="passportNumber"
                      required={true}
                      type={FIELD_TYPES.TEXT}
                      moreInfo={{ fieldId: 'tooltip', message: 'message' }}
                      showModal={this.toggleDocumentNumberModal}
                    />
                    <DynamicInputField
                      label={SUPPORT.LABELS.EMAIL_ADDRESS}
                      id="email"
                      required={true}
                      type={FIELD_TYPES.EMAIL}
                    />
                    <DynamicInputField
                      label={SUPPORT.LABELS.PROBLEM_TYPE}
                      id="supportRequest"
                      required={true}
                      isSearchable={false}
                      placeholderText={SUPPORT.LABELS.SELECT_ONE}
                      onChange={this.onTypeChange}
                      options={this.convertCategories(categories)}
                      type={FIELD_TYPES.SELECT}
                    />
                    <DynamicInputField
                      label={SUPPORT.LABELS.SPECIFIC_PROBLEM}
                      id="supportRequestSubCategory"
                      required={
                        secondaryOptions && secondaryOptions.length > 0
                      }
                      isSearchable={false}
                      options={secondaryOptions}
                      placeholderText={SUPPORT.LABELS.SELECT_ONE}
                      type={FIELD_TYPES.SELECT}
                    />
                    <DynamicInputField
                      label={SUPPORT.LABELS.PROVIDE_MORE}
                      id="supportQuestion"
                      required={true}
                      preventInputOnMax={true}
                      max={300}
                      type={FIELD_TYPES.TEXTAREA}
                    />
                  </div>
                  <div className="col-sm-12 col-md-5 col-md-offset-2">
                    {requiresExtraInfo && (
                      <React.Fragment>
                        <div
                          ref={this.mobileErrors}
                          className="hidden-md hidden-lg"
                        >
                          {this.renderErrors()}
                        </div>
                        <h3 className="spacing-top-none alt">
                          {SUPPORT.LOGGED_OUT.ADDITIONAL_FIELDS}
                        </h3>
                        <DynamicInputField
                          label={SUPPORT.LABELS.FIRST_NAME}
                          id="firstName"
                          required={true}
                          autoFocus
                          type={FIELD_TYPES.TEXT}
                        />
                        <DynamicInputField
                          label={SUPPORT.LABELS.LAST_NAME}
                          id="lastName"
                          required={true}
                          type={FIELD_TYPES.TEXT}
                        />
                        <DynamicInputField
                          label={SUPPORT.LABELS.DATE_OF_BIRTH}
                          id="dateOfBirthDisplay"
                          required={true}
                          type={FIELD_TYPES.DATE}
                        />
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <ActionRow>
                  <ActionRow.Forward>
                    <Action
                      data-testid="submit-btn"
                      title={COMMON.SEND}
                      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                      loading={submitting}
                      disabled={!valid}
                      type="submit"
                    />
                  </ActionRow.Forward>
                  <ActionRow.Backward>
                    <ActionLink
                      classes="inline-block mt-link"
                      clickFunc={this.onBackPress}
                      text={COMMON.BACK}
                    />
                  </ActionRow.Backward>
                </ActionRow>
              </div>
            </PageBlock.Body>
          </PageBlock>
        </form>
      </div>
    );
  }
}

PublicSupport.propTypes = {
  history: PropTypes.any,
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  /** Get the support index action */
  GetSupportCategories: PropTypes.func.isRequired,
  CreateSupportTicket: PropTypes.func.isRequired,
  setAuthSupportInfo: PropTypes.func.isRequired,
  categories: PropTypes.array,
  change: PropTypes.func.isRequired,
  selectedCategory: PropTypes.string,
};

function mapStateToProps(state) {
  const selector = formValueSelector(FORM_NAME);
  return {
    categories: state.support.categories,
    selectedCategory: selector(state, "supportRequest"),
  };
}

export default reduxForm({
  form: FORM_NAME,
})(
  connect(mapStateToProps, {
    GetSupportCategories,
    CreateSupportTicket,
    setAuthSupportInfo,
    change,
  })(PublicSupport)
);
