import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../../i18n/config';
import { ACTION_DISPLAY_TYPES } from '../../../constants/enums';
import { RootState } from '../../../types/redux';
import { setExternalLinksModalOpen } from '../../../actions/global';

import Modal from "../Modal";

const ExternalLinksModal = () => {
  const dispatch = useDispatch();
  const { open, url } = useSelector((state: RootState) => state.global.externalLinksModal);

  const onClose = () => {
    dispatch(setExternalLinksModalOpen(false));
  }

  const openLink = () => {
    onClose();
    window.open(url, "_blank");
  }

  const actions = [
    {
      title: i18n.t("COMMON.NO"),
      onClick: onClose,
      displayType: ACTION_DISPLAY_TYPES.LINK,
      className: "btn btn-link",
    },
    {
      title: i18n.t("COMMON.YES_OPEN_IN_NEW_TAB"),
      onClick: openLink,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      className: "btn btn-primary",
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={i18n.t("COMPONENTS.MODALS.EXTERNAL_LINKS.TITLE")}
      content={i18n.t("COMPONENTS.MODALS.EXTERNAL_LINKS.CONTENT")}
      actions={actions}
      className="z-9999"
    />
  );
}

export default ExternalLinksModal;