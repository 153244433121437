import React from "react";
import PropTypes from "prop-types";
import FlowSuccess from "./FlowSuccess";
import { EMAIL_SUCCESS, COMMON } from "../../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import { ROUTES } from "../../../constants/clientRoutes";
import { ICON_SVG } from "../../../constants/iconsvg";
import Action from "../Action";

const EmailSentSuccess = (props) => (
  <FlowSuccess id={props.id}>
    <FlowSuccess.Image>
      <div
        dangerouslySetInnerHTML={{ __html: `${ICON_SVG.EMAIL_SUCCESS}` }}
      ></div>
    </FlowSuccess.Image>
    <FlowSuccess.Title>{EMAIL_SUCCESS.TITLE}</FlowSuccess.Title>
    <FlowSuccess.Body>{EMAIL_SUCCESS.TEXT}</FlowSuccess.Body>
    <FlowSuccess.ActionRow>
      <Action
        title={COMMON.BACK_TO_LOGIN}
        onClick={() => (location.href = ROUTES.EXTERNAL.LOGIN)}
        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      />
    </FlowSuccess.ActionRow>
  </FlowSuccess>
);

EmailSentSuccess.propTypes = {
  id: PropTypes.string,
};

export default EmailSentSuccess;
