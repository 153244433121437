import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import {
  AuthorizeLoginToken,
  CleanLoginToken,
  disableFaceCheckSessionAutoStart,
  goToNextAuthStep,
} from "../../actions/auth";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import { ROUTES } from "../../constants/clientRoutes";
import Action from "../../components/Common/Action";
import {
  ACTION_DISPLAY_TYPES,
  FIELD_TYPES,
  LOCAL_STORAGE,
} from "../../constants/enums";
import { enrollLogout } from "../../services/enrollment";
import userIcon from "../../assets/images/icons/User-grey.svg";
import { PAGES } from "../../constants/localization";
import ActionLink from "../../components/Common/ActionLink";
import ErrorBlock from "../../components/Common/ErrorBlock";
import GetApp from "../../components/Common/GetApp";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import ENV from "../../constants/environment";

/**
 * Allows api login and handles retrieving necessary app/user data for the store
 */
class Login extends Component {
  constructor(props) {
    super(props);

    const username = localStorage.getItem(LOCAL_STORAGE.Username);
    const rememberMe = username ? true : false;

    this.state = {
      errors: null,
      username: username,
      rememberMe: rememberMe,
    };

    this.props.initialize({
      username: this.state.username,
      rememberMe: this.state.rememberMe,
    });

    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    // clear auth token and auth info previously fetched
    // in case back buttons are clicked
    this.props.CleanLoginToken();
    this.props.disableFaceCheckSessionAutoStart();
    enrollLogout();
  }

  /**
   * Calls API to log the user in and retrieve user data for the store
   * @param username
   * @param password
   * @returns {Promise<void>}
   */
  async onSubmit({ username }) {
    trackEvent(EVENTS.LOGIN.USERNAME_SUBMITTED);

    if (this.state.rememberMe) {
      localStorage.setItem(LOCAL_STORAGE.Username, username);
    } else {
      localStorage.removeItem(LOCAL_STORAGE.Username);
    }

    return this.props
      .AuthorizeLoginToken(username)
      .then(() => {
        this.props.goToNextAuthStep(this.props.history);
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ errors: error.response.data.errors });
        } else {
          this.setState({
            errors: [
              { error: error, errorMessage: PAGES.AUTH.GENERIC_ERROR_MESSAGE },
            ],
          });
        }
      });
  }

  renderErrors() {
    const { errors } = this.state;
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  handleRememberMe = (checked) => {
    this.setState({ rememberMe: checked });
  };

  render() {
    const { handleSubmit, submitting } = this.props;
    return (
      <React.Fragment>
        <GetApp />
        <div className="v2 page-block sm login">
          <h1 className="text-center">Log in</h1>
          {this.renderErrors()}
          <form
            name="login"
            className="spacing-top-medium spacing-bottom-large"
            onSubmit={handleSubmit(this.onSubmit)}
          >
            <div className="form-group">
              <img src={userIcon} className="label-icon" />
              <label htmlFor="username" className="auth-label">{PAGES.AUTH.USERNAME}</label>
              <Field
                component="input"
                name="username"
                type="text"
                autoFocus
                required
                disabled={submitting}
                className="form-control"
              />
              <div className="flex-row spacing-top-tiny">
                <DynamicInputField
                  id="rememberMe"
                  name="rememberMe"
                  type={FIELD_TYPES.BOOL}
                  label={PAGES.AUTH.REMEMBER_ME}
                  checked={this.state.rememberMe}
                  onChange={this.handleRememberMe}
                  hideOptional
                  className="remember-me-checkbox col"
                />
                <div className="text-right row-link col">
                  <ActionLink
                    classes="bold"
                    href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.SETTINGS.FORGOT_USERNAME}`}
                  >
                    {PAGES.AUTH.FORGOT_USERNAME}
                  </ActionLink>
                </div>
              </div>
            </div>
            <div className="spacing-top-small">
              <Action
                className="btn-lg"
                title={PAGES.AUTH.LOGIN}
                block
                loading={submitting}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
              />
            </div>
          </form>
          <div className="separator-title">
            <h3>{PAGES.AUTH.NEW_TO_NAVIGATOR}</h3>
          </div>
          <div className="text-center spacing-top-medium spacing-bottom-medium">
            <ActionLink
              classes="btn btn-lg btn-link"
              href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.ENROLLMENT.ENROLL}`}
              clickFunc={() => trackEvent(EVENTS.ENROLLMENT.STARTED, {})}
            >
              {PAGES.AUTH.ENROLL}
            </ActionLink>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  /** redux-form submit wrapper */
  handleSubmit: PropTypes.func.isRequired,
  /** API login action */
  AuthorizeLoginToken: PropTypes.func.isRequired,
  goToNextAuthStep: PropTypes.func.isRequired,
  /** Display loading spinner action */
  DisplayLoading: PropTypes.any,
  /** Hide loading spinner action */
  FinishLoading: PropTypes.any,
  /** react-router history */
  history: PropTypes.any,
  /** redux-form initialize callback */
  initialize: PropTypes.func,
  submitting: PropTypes.bool,
  CleanLoginToken: PropTypes.func.isRequired,
  disableFaceCheckSessionAutoStart: PropTypes.func.isRequired,
};

let loginForm = reduxForm({
  form: "Login",
})(Login);

export default connect(null, {
  AuthorizeLoginToken,
  goToNextAuthStep,
  DisplayLoading,
  FinishLoading,
  CleanLoginToken,
  disableFaceCheckSessionAutoStart,
})(loginForm);
