import React from "react";
import PropTypes from "prop-types";
import CurrencyInput from "../CurrencyInput";
import enabledArrow from "../../../assets/images/MiniArrow-enabled.svg";
import DropdownField from "./DropdownField";
import BaseInput from "../UserInputs/BaseInput";
import { connect } from "react-redux";
import { change, Field } from "redux-form";
import { COMMON, PAGES } from "../../../constants/localization";
import _ from "lodash";
import Label from "../Label";

/**
 * Renders a set of dropdown fields for day/month/year
 * Should be used as a component of a Redux form Field component.
 */
class CurrencyField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      targetCurrency: "",
      isCurrencyTouched: false,
    };
    this.onAmountChange = this.onAmountChange.bind(this);
    this.targetCurrencyCheck = this.targetCurrencyCheck.bind(this);
    this.onTargetCurrencyChange = this.onTargetCurrencyChange.bind(this);
  }

  /**
   * Updates the target currency after the component mounts.
   */
  componentDidMount() {
    this.updateTargetCurrency();
  }

  /**
   * If there are target currencies, this grabs the selected
   * target currency and sets its value in state. If the
   * targetCurrencies has values but the props.targetCurrency
   * is not in the list, the target currency is not changed.
   */
  updateTargetCurrency() {
    let targetCurrencies = this.props.targetCurrencies || [];
    if (targetCurrencies.length > 0) {
      let value =
        this.props.targetCurrency ||
        (targetCurrencies.length === 1 ? targetCurrencies[0] : "");
      if (targetCurrencies.find((c) => c === value)) {
        this.onTargetCurrencyChange(value);
      }
    }
  }

  /**
   * Life cycle method that fires when the component updates
   * If the target currency changes, this updates the value in state.
   * @param {object} nextProps - The new prop values
   */
  componentDidUpdate(nextProps) {
    const { targetCurrencies } = this.props;
    if (nextProps.targetCurrencies !== targetCurrencies) {
      this.updateTargetCurrency();
    }
  }

  /**
   * Sets the new value of the target currency in state.
   * @param value - The new value of the target currency
   */
  onTargetCurrencyChange(value) {
    this.setState(
      {
        targetCurrency: value,
        isCurrencyTouched: true,
      },
      this.updateValue
    );
  }

  /**
   * Updates the state when the amount changes.
   * @param {*} event - The event that triggered this.
   */
  onAmountChange(event) {
    event.persist();
    this.setState(
      {
        amount: event.target.value,
      },
      this.updateValue
    );
  }
  /**
   * Triggers the blur event on the underlying input
   * only if the target currency is touched (if applicable).
   * @param {*} event - The event that triggered this.
   */
  onAmountBlur(event) {
    event.persist();
    if (
      !this.props.isTargetCurrencyRequired ||
      (this.props.isTargetCurrencyRequired && this.state.isCurrencyTouched)
    ) {
      this.props.input.onBlur && this.props.input.onBlur();
    } else {
      event.preventDefault();
    }
  }

  /**
   * Updates the value of the underlying input for redux form.
   * The input's value is not important for display but can hold
   * an object when retrieved from the redux form.
   * The form is valid only when all required values are selected.
   */
  updateValue() {
    let value = {
      amount: this.state.amount,
      targetCurrency: this.state.targetCurrency,
      sourceCurrency: this.props.currencyIso,
    };

    this.props.dispatch(
      change(
        PAGES.CURRENCY_CONVERTER.FORM_NAME,
        PAGES.CURRENCY_CONVERTER.HIDDEN_FIELD_CURRENCY_VALUE,
        value
      )
    );
    this.props.input.onChange && this.props.input.onChange(this.state.amount);
  }

  /** Validation check. Fails if value is not set. */
  required = (value) => (value ? undefined : COMMON.REQUIRED);

  /** Validation check. Fails if value is less than or equal to 0 */
  min(value, allValues, props, name) {
    return _.toNumber(value || null) > 0 ? undefined : `${name} cannot be zero`;
  }

  /**
   * Validation check for target currency.
   * Fails if target currency is required and has not been set.
   */
  targetCurrencyCheck() {
    if (this.props.isTargetCurrencyRequired && !this.state.targetCurrency) {
      return COMMON.REQUIRED_CURRENCY;
    }
    return undefined;
  }

  /**
   * Lifecycle method for rendering the component.
   */
  render() {
    let targetCurrencies = this.props.targetCurrencies || [];
    let currencyOptions = targetCurrencies.map((c) => {
      return { value: c, label: c };
    });
    let displayTargetCurrencySelector =
      this.props.alwaysShowTargetCurrencySelector ||
      targetCurrencies.length > 0;

    let showInnerLabel = this.props.hideLabel && this.props.targetCurrencyLabel; //We show the inner label if the outer label is hidden and  target label provided
    return (
      <div className="currency-field-container">
        <div className="currency-field">
          {showInnerLabel && <Label label={this.props.label} required={true} />}
          <div
            data-cy="currency-input"
            className="currency-input currency-input-section"
          >
            <Field
              name={this.props.id}
              component={CurrencyInput}
              {...this.props}
              placeholder={PAGES.CURRENCY_CONVERTER.AMOUNT_PLACEHOLDER}
              customChangeHandler={(e) => this.onAmountChange(e)}
              customBlurHandler={(e) => this.onAmountBlur(e)}
              hideError={true}
              validate={[this.required, this.min, this.targetCurrencyCheck]}
            />
          </div>
        </div>
        {this.props.displayArrow && (
          <React.Fragment>
            {displayTargetCurrencySelector && (
              <div
                data-cy="right-arrow"
                className="right-arrow currency-input-section"
              >
                <img alt="arrow" src={enabledArrow} />
              </div>
            )}
          </React.Fragment>
        )}
        {displayTargetCurrencySelector && (
          <React.Fragment>
            <div
              data-cy="target-currency-input"
              className="target-currency-input currency-input-section"
            >
              {showInnerLabel && (
                <Label label={this.props.targetCurrencyLabel} required={true} />
              )}
              <React.Fragment>
                {targetCurrencies.length === 1 && (
                  <BaseInput
                    disabled={true}
                    value={this.state.targetCurrency}
                  />
                )}
                {targetCurrencies.length !== 1 && (
                  <DropdownField
                    id="targetCurrency"
                    isClearable={false}
                    options={currencyOptions}
                    input={{
                      value: this.state.targetCurrency,
                      onChange: (v) => {
                        this.onTargetCurrencyChange(v);
                      },
                    }}
                  />
                )}
                <Field
                  component="input"
                  name={PAGES.CURRENCY_CONVERTER.HIDDEN_FIELD_CURRENCY_VALUE}
                  type="hidden"
                />
              </React.Fragment>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

CurrencyField.defaultProps = {
  input: {},
  displayArrow: true,
  targetCurrencyLabel: "",
};

CurrencyField.propTypes = {
  /** The underlying input behind the field */
  input: PropTypes.object,

  /** The field ID. This will be used as the form field name */
  id: PropTypes.string.isRequired,

  /** The label to associate with the currency input field */
  label: PropTypes.string,

  /** There are 2 label designs
   *    - one surrounds the entire currency input sections
   *    - the other are labels for each individual input section
   *  Only the outer or the inner can display at once.
   *  A value of true indicates a preference for the inner label
   *  to show while hiding the outer label.
   * */
  hideLabel: PropTypes.string.isRequired,

  /** The available ISO codes for the target currency field.
   * There must be at least one value in the property for the
   * target currency input to display
   */
  targetCurrencies: PropTypes.arrayOf(PropTypes.string),

  /** The pre-selected target currency. If there is only
   * one value in the @see targetCurrencies, then this defaults
   * to that value. If this is not provided or does not match any
   * of the target currencies, "Select..." is shown.
   */
  targetCurrency: PropTypes.string,

  /** The source currency. This is displayed in the currency add-on. */
  currencyIso: PropTypes.string,

  /** Always show the target currency selector even if it does
   * not meet all the requirements to display.
   */
  alwaysShowTargetCurrencySelector: PropTypes.bool,

  /** Display error if target currency is not provided. */
  isTargetCurrencyRequired: PropTypes.bool,

  /* Allows forcing the arrow to either show or not show. The default behavior is to show. */
  displayArrow: PropTypes.bool,

  /** Allows one to specify a label that goes above the target currency drop down. */
  targetCurrencyLabel: PropTypes.string,

  /** The action dispatcher */
  dispatch: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapDispatchToProps)(CurrencyField);
