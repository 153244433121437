import React from "react";
import PropTypes from "prop-types";
import { COMMON } from "../../../constants/localization";
import { ROUTES } from "../../../constants/clientRoutes";
import _Modal from "../Modal";
import { ACTION_DISPLAY_TYPES, ERROR_TYPE } from "../../../constants/enums";
import ErrorService from "../../../services/errorService";
import { connect } from "react-redux";
import { setExternalLinksModalOpen } from "../../../actions/global";

class ErrorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      modalError: null,
    };
  }

  componentDidMount() {
    this.getErrorModalAndSetDisplay();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors != this.props.errors) {
      this.getErrorModalAndSetDisplay();
    }
  }

  getErrorModalAndSetDisplay = () => {
    this.setState({
      showModal: ErrorService.GetModalError(this.props.errors) ? true : false,
    });
  };

  toggleModal = () => {
    const { showModal } = this.state;
    this.setState({
      showModal: !showModal,
    });
    this.props.setExternalLinksModalOpen(false);
  };

  getModalTypeButtons = (errorType) => {
    let modalTypeButtons = {};
    modalTypeButtons[ERROR_TYPE.OK] = [];
    modalTypeButtons[ERROR_TYPE.ContactSupport] = [
      this.BackToLogin(ACTION_DISPLAY_TYPES.SECONDARY),
      this.ContactSupport(ACTION_DISPLAY_TYPES.PRIMARY),
    ];
    modalTypeButtons[ERROR_TYPE.Dashboard] = [
      this.BackToDashboard(ACTION_DISPLAY_TYPES.PRIMARY),
    ];
    modalTypeButtons[ERROR_TYPE.Login] = [
      this.BackToLogin(ACTION_DISPLAY_TYPES.PRIMARY),
    ];
    modalTypeButtons[ERROR_TYPE.ContactSupportAndDashboard] = [
      this.BackToDashboard(ACTION_DISPLAY_TYPES.SECONDARY),
      this.ContactSupport(ACTION_DISPLAY_TYPES.PRIMARY),
    ];
    modalTypeButtons[ERROR_TYPE.ContactSupportAndLogin] = [
      this.BackToLogin(ACTION_DISPLAY_TYPES.SECONDARY),
      this.ContactSupport(ACTION_DISPLAY_TYPES.PRIMARY),
    ];

    return modalTypeButtons[errorType] || [];
  };

  BackToLogin = (displayType) => ({
    title: COMMON.BACK_TO_LOGIN,
    displayType,
    onClick: () => (location.href = ROUTES.EXTERNAL.LOGIN),
  });

  supportLink = () =>
    this.props.isLoggedIn
      ? ROUTES.EXTERNAL.SUPPORT
      : ROUTES.EXTERNAL.SUPPORT_OLD;

  ContactSupport = (displayType) => ({
    title: COMMON.CONTACT_SUPPORT,
    displayType,
    onClick: () => (location.href = this.supportLink()),
  });

  BackToDashboard = (displayType) => ({
    title: COMMON.BACK_TO_DASHBOARD,
    displayType,
    onClick: () => (location.href = ROUTES.EXTERNAL.DASHBOARD),
  });

  render() {
    const { errors } = this.props;
    const { showModal } = this.state;
    const modalError = ErrorService.GetModalError(errors);
    if (showModal) {
      return (
        <_Modal
          open={showModal}
          title={modalError.errorTitle || "Message"}
          content={modalError.errorMessage}
          actions={this.getModalTypeButtons(modalError.errorType)}
          onClose={this.toggleModal}
        />
      );
    }
    return null;
  }
}

ErrorModal.propTypes = {
  errors: PropTypes.array,
  isLoggedIn: PropTypes.bool,
  setExternalLinksModalOpen: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    setExternalLinksModalOpen: (open) => dispatch(setExternalLinksModalOpen(open)),
  }
}

export default connect(null, mapDispatchToProps)(ErrorModal);
