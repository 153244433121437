import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { eventTracker } from "../../services/eventTracker";

/*
   Wraps react-router-dom Link with onClick that submits eventTracker(eventName, eventProps)
*/
const TrackedLink = (props) => {
  return (
    <Link
      to={props.to}
      onClick={() => eventTracker.track(props.eventName, props.eventProps)}
    >
      {props.linkText}
    </Link>
  );
};

TrackedLink.propTypes = {
  to: PropTypes.string,
  linkText: PropTypes.string,
  eventName: PropTypes.string,
  eventProps: PropTypes.object,
};

export default TrackedLink;
