import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { QUERYSTRING_KEYS } from "../constants/clientRoutes";

/**
 * Gets a specific query string parameter by key.
 * e.g for ?key1=1&key2=2, calling this with "key1"
 * returns "1", calling with "key2" returns 2, and
 * calling with "key3" returns undefined.
 * NOTE:  It only support cases where you need to call this method
 *       before the location is available in @see useLocation, which
 *       requires callers to be children of the Route/Router object.
 * @param {string} search - The querystring from the location object.
 * @param {string} variable - The querystring parameter key to retrieve the value for.
 */
export const getQueryVariableFromSearch = (search, variable) => {
  if (search && search !== "") {
    const query = search.substring(1);
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
  }
};

/**
 * Hook for determining whether to load the page in focused layout or not.
 */
export const useFocusedLayout = () => {
  const location = useLocation();
  const [isFocused, setIsFocused] = useState(false);
  useEffect(() => {
    setIsFocused(isFocusedLayout(location));
  }, []);
  return isFocused;
};

/**
 * Determines whether to load the page in focused layout or not.
 * NOTE: Only use this if @see useLocation is not yet available.
 *       In most cases, you want to use @see useFocusedLayout.
 */
export const isFocusedLayout = (location) => {
  let { search } = location || {};
  const value = (
    getQueryVariableFromSearch(search, QUERYSTRING_KEYS.IS_FOCUSED) || ""
  ).toLowerCase();
  return value === "true";
};
