import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HowItWorksScreen from "../../components/Settings/TOTP-Login/HowItWorksScreen";
import LinkAuthAppScreen from "../../components/Settings/TOTP-Login/LinkAuthAppScreen";
import SuccessScreen from "../../components/Settings/TOTP-Login/SuccessScreen";
import VerifyAuthAppScreen from "../../components/Settings/TOTP-Login/VerifyAuthAppScreen";
import FlowScreen from "../../components/Layouts/FlowScreen";
import { PAGES } from "../../constants/localization";

const LogInMethod = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const featureFlags = useSelector((state) => state.user.featureFlags);

  useEffect(() => {
    if (!featureFlags.length) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [featureFlags]);

  return (
    <React.Fragment>
      <HowItWorksScreen loading={loading} setLoading={setLoading} featureFlags={featureFlags} page={page} setPage={setPage} />
      {page !== 1 &&
        <FlowScreen flowTitle={PAGES.SETTINGS.LOG_IN_METHOD.TITLE} className="totp-flow">
          <LinkAuthAppScreen loading={loading} page={page} setPage={setPage} setLoading={setLoading} />
          <VerifyAuthAppScreen page={page} setPage={setPage} />
          <SuccessScreen page={page} />
        </FlowScreen>
      }
    </React.Fragment>
  )
}

export default LogInMethod;
