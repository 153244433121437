import React from "react";
import PropTypes from "prop-types";

/**
 * Loading spinner overlay
 * https://github.com/loadingio/css-spinner/
 *
 * @param show
 * @returns {null|*}
 * @constructor
 */
export const Spinner = ({ show, fullWidth }) => {
  if (!show) {
    return null;
  }

  // The spinner is made up of a collection of divs that rotate around a core with different transition times.
  return (
    <div className={`spinner lds-ring ${fullWidth ? "width_full" : ""}`} data-testid="spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

Spinner.propTypes = {
  /** Toggle for the overlay/spinner */
  show: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool,
};

export default Spinner;
