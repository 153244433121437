import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PAGES } from "../../constants/localization";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES, ACTION_DISPLAY_TYPES_BY_NAME, ACTION_TYPES_BY_NAME } from "../../constants/enums";
import Modal from "../../components/Common/Modal";
import { ROUTES } from "../../constants/clientRoutes";
import FooterLinks from "../../components/MoneyTransfer/FooterLinks";
import { camelCase } from "lodash";

/**
 * Stub for fraud information
 */
class FraudInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supportModalShown: false,
      redirect: false,
    };

    this.openSupportModal = this.openSupportModal.bind(this);
    this.closeSupportModal = this.closeSupportModal.bind(this);
    this.renderSupportModal = this.renderSupportModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * Renders the dialog modal that allows users to navigate to support
   */
  renderSupportModal() {
    var action = {
      title: PAGES.SUPPORT.STAY,
      onClick: this.closeSupportModal,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    };
    var exitAction = {
      title: PAGES.SUPPORT.EXIT,
      onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
    };

    return (
      <Modal
        title={PAGES.SUPPORT.EXIT_MODAL_HEADER}
        open={this.state.supportModalShown}
        onClose={this.closeSupportModal}
        actions={[exitAction, action]}
        content={PAGES.SUPPORT.EXIT_MODAL_TEXT}
        className="support-modal"
      />
    );
  }

  /**
   * Open the support modal
   */
  openSupportModal() {
    this.setState({ supportModalShown: true });
  }

  /**
   * Close the support modal
   */
  closeSupportModal() {
    this.setState({ supportModalShown: false });
  }

  /**
   * Checks action type returned by API, and returns appropriate onClick function
   */
  checkActionType = (actionType) => {
    let clickFunc;
    switch (actionType) {
      case ACTION_TYPES_BY_NAME.BACK:
        clickFunc = this.props.onBack;
        break;
      case ACTION_TYPES_BY_NAME.CONTINUE:
        clickFunc = this.props.onNext;
        break;
    }

    return clickFunc;
  }

  /**
   * API returns display types in string format, need to map to number for <Action /> component
   */
  mapDisplayType = (displayTypeText) => {
    let displayType;
    switch (displayTypeText) {
      case ACTION_DISPLAY_TYPES_BY_NAME.PRIMARY:
        displayType = ACTION_DISPLAY_TYPES.PRIMARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.SECONDARY:
        displayType = ACTION_DISPLAY_TYPES.SECONDARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.TERTIARY:
        displayType = ACTION_DISPLAY_TYPES.TERTIARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.LINK:
        displayType = ACTION_DISPLAY_TYPES.LINK;
        break;
    }

    return displayType;
  }

  /**
   * Renders the action button elements
   */
  renderButton = (type, data) => {
    const displayType = this.mapDisplayType(data.displayType);
    const actionFunc = this.checkActionType(data.actionType);
    return (
      <div className={`col-xs-12 col-sm-6 ${type === "prev" ? "two" : "button-cell one"}`} data-cy={camelCase(data.title)}>
        <Action
          onClick={() => actionFunc()}
          displayType={displayType}
          title={data.title}
        />
      </div>
    )
  }

  /**
   * Render the page
   */
  render() {
    return (
      <div>
        <div
          className="reset-styles"
          dangerouslySetInnerHTML={{ __html: this.props.pageData.content }}
        ></div>
        <div className="row navRow">
          {this.renderButton("prev", this.props.pageData.previousButton)}
          {this.renderButton("next", this.props.pageData.nextButton)}
        </div>

        {this.props.workflow.footer &&
          <FooterLinks disclaimerLinks={this.props.workflow.footer.disclaimerLinks} />
        }

        {this.renderSupportModal()}
      </div>
    );
  }
}

FraudInformation.propTypes = {
  onNext: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  fraudWarning: PropTypes.string.isRequired,
  pageData: PropTypes.object,
  workflow: PropTypes.object
};

function mapStateToProps(state) {
  return {
    fraudWarning: state.moneyTransfer.workflow.fraudWarning,
    workflow: state.moneyTransfer.workflow
  };
}

export default connect(mapStateToProps, {})(FraudInformation);