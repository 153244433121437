import MoneyTransferApi from "../api/moneyTransfer";
import { Dispatch } from "redux";
import { MONEY_TRANSFER } from "../constants/actionTypes";
import { trackEvent } from "../services/eventTracker";
import { EVENTS } from "../constants/events";
import { COMMON, PAGES } from "../constants/localization";
import { HTTP_STATUS_CODES } from "../constants/api";
import { SubmissionError } from "redux-form";
import { GetMoneyTransferProviderRates } from "../types/actions";
import { CommitMoneyTransferResponse, GetAvailableCountriesResponse, GetMoneyTransferDetailsResponse, GetWorkflowResponse } from "../types/api/moneyTransfer";
import { WorkflowStep } from "../types/moneyTransfer";

/**
 * Retrieves all available rates from all providers for a transaction and saves in state.
 * @param {number} sendAmount The send amount of the transaction
 * @param {string} receiveCurrency The receive currency of the transaction in 3 digit ISO
 * @param {string} destinationCountry The destination country of the transaction as a 2 digit ISO code
 */
export function GetProviderRates(
  sendAmount,
  receiveCurrency,
  destinationCountry,
  destinationCountryName,
  destinationProvince,
  destinationCity
) {
  return async (dispatch) => {
    try {
      const providerRates = await MoneyTransferApi.GetProviderRates(
        sendAmount,
        receiveCurrency,
        destinationCountry,
        destinationProvince,
        destinationCity
      );

      dispatch({
        type: MONEY_TRANSFER.GET_RATES_SUCCESS,
        rateQuery: {
          destinationCountry,
          destinationCountryName,
          destinationProvince,
          destinationCity,
          sendAmount,
          receiveCurrency,
        },
        providerRates,
      });
    } catch (error) {
      if (error?.data?.errors?.length > 0) {
        dispatch({
          type: MONEY_TRANSFER.GET_RATES_FAILURE,
          errors: error.data.errors,
        })
      } else {
        throw new SubmissionError({
          _error: PAGES.MONEY_TRANSFER.VALIDATIONS.RATES_NOT_AVAILABLE,
        });
      }
    }
  };
}

/**
 * Retrieves all available money transfer countries for all providers and saves in state.
 */
export function GetAvailableCountries() {
  return async (dispatch) => {
    const availableCountries = await MoneyTransferApi.GetAvailableCountries();
    dispatch({
      type: MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES,
      availableCountries: availableCountries.moneyTransferCountries,
      sendLimit: availableCountries.sendLimit,
      restrictions: availableCountries.restrictions,
    });
  };
}

/**
 * Retrieves all available money transfer provinces for provided country.
 */
export function GetAvailableProvinces(isoCountryCode) {
  return async (dispatch) => {
    const response = await MoneyTransferApi.GetAvailableProvinces(isoCountryCode);
    dispatch({
      type: MONEY_TRANSFER.GET_AVAILABLE_PROVINCES,
      availableProvinces: response.options,
    });
  };
}

/**
 * Retrieves all available money transfer cities for provided country and province
 */
export function GetAvailableCities(isoCountryCode, provinceCode) {
  return async (dispatch) => {
    const response = await MoneyTransferApi.GetAvailableCities(isoCountryCode, provinceCode);
    dispatch({
      type: MONEY_TRANSFER.GET_AVAILABLE_CITIES,
      availableCities: response.options,
    });
  };
}

/**
 * Updated list of provider rates. All matching rates will be updated.
 * @param {array} providerRates The updated list of provider rates
 */
export function UpdateProviderRates(providerRates) {
  return async (dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.UPDATE_PROVIDER_RATES,
      providerRates,
    });
  };
}

/**
 * Retrieves all workflow information from the provider of this quote.
 * @param {number} quoteId The ID of the quote to get workflow info for
 */
export function GetWorkflow(quoteId) {
  return async (dispatch) => {
    const workflow = await MoneyTransferApi.GetWorkflow(quoteId);
    dispatch({
      type: MONEY_TRANSFER.GET_WORKFLOW,
      workflow,
      quoteId
    });
  };
}

/**
 * When a new set of rates is retrieved the workflow information is cleared.
 * All inputed form information is also cleared.
 */
export function ClearWorkflow() {
  return async (dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.CLEAR_WORKFLOW,
    });
  };
}

/**
 * Validates all input workflow information
 * @param {number} quoteId The ID of the quote to get workflow info for
 * @param {array} values The serviceProviderFieldId : value key pairs
 */
export function ValidateWorkflow(quoteId, values) {
  return async (dispatch) => {
    try {
      const validationResult = await MoneyTransferApi.ValidateWorkflow(
        quoteId,
        values
      );

      return dispatch({
        type: MONEY_TRANSFER.VALIDATE_WORKFLOW_SUCCESS,
        validationResult: validationResult.result,
      });
    } catch (error) {
      /// If we get a 400 or 226 from ValidateWorkflow expect to see the fieldCategories returned with validation errors
      /// or generic errors in the errors array.
      if (error.status === HTTP_STATUS_CODES.BAD_REQUEST || error.status === HTTP_STATUS_CODES.VALIDATION_ERROR) {
        dispatch({
          type: MONEY_TRANSFER.VALIDATE_WORKFLOW_INVALID,
          validationResult: error.data.result,
          errors: error.data.errors,
        });
        throw error;
      } else {
        dispatch({
          type: MONEY_TRANSFER.VALIDATE_WORKFLOW_FAILURE,
          errors: error.data.errors,
        });
        throw error;
      }
    }
  };
}

/**
 * Commits a moneytransfer transaction
 * @param {number} quoteId The ID of the quote to commit
 */
export function Commit(quoteId, params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: MONEY_TRANSFER.RESET_COMMIT_ERRORS,
      });

      const commitResult = await MoneyTransferApi.Commit(quoteId);
      commitResult.success = true;
      trackEvent(EVENTS.MONEY_TRANSFER.SUBMITTED, {
        success: true,
        ...params
      })

      dispatch({
        type: MONEY_TRANSFER.COMMIT_SUCCESS,
        commitResult: commitResult,
      });
    } catch (error) {
      trackEvent(EVENTS.MONEY_TRANSFER.SUBMITTED, {
        success: false,
        failure_reason: error,
        ...params
      })
      dispatch({
        type: MONEY_TRANSFER.COMMIT_FAILURE,
        errors: [COMMON.ERROR],
        message:
          error.message ||
          "Unfortunately there was an issue. Please try again",
      });
    }
  };
}

/**
 * Commits a moneytransfer transaction
 * @param {number} moneyTransferId The unique identifier of the money transfer
 * @param {string} referenceNumber The reference number of the transactio
 */
export function GetReceipt(moneyTransferId, referenceNumber, serviceType?: number) {
  return async (dispatch) => {
    try {
      await MoneyTransferApi.GetReceipt(moneyTransferId, referenceNumber, serviceType);

      dispatch({
        type: MONEY_TRANSFER.GET_RECEIPT_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: MONEY_TRANSFER.GET_RECEIPT_FAILURE,
        message:
          error.message ||
          "Unfortunately there was an issue. Please try again",
      });
    }
  };
}

/**
 * Gets the raw HTML terms and conditions from the API and puts it in the workflow state.
 * @param {number} quoteId The ID of the quote to get terms and conditions for.
 */
export function GetTermsAndConditions(quoteId) {
  return async (dispatch) => {
    const termsAndConditionsResult =
      await MoneyTransferApi.GetTermsAndConditions(quoteId);
    dispatch({
      type: MONEY_TRANSFER.GET_TERMS_AND_CONDITIONS_SUCCESS,
      termsAndConditions: termsAndConditionsResult.termsAndConditions,
    });
  };
}

/**
 * Gets the details for a money transfer and adds it to state
 * @param {string} moneyTransferId The ID of the completed moneytransfer
 */
export function GetTransferDetails(moneyTransferId) {
  return async (dispatch) => {
    try {
      const transferDetailsResult = await MoneyTransferApi.GetTransferDetails(
        moneyTransferId
      );
      dispatch({
        type: MONEY_TRANSFER.GET_TRANSFER_DETAILS_SUCCESS,
        transferDetails: transferDetailsResult,
      });
    } catch (err) {
      dispatch({
        type: MONEY_TRANSFER.GET_TRANSFER_DETAILS_ERROR,
        errors: err.data.errors
      });
    }

  };
}

/**
 * Cancel money transfer details
 * @param {number} moneyTransferId The ID of the completed moneytransfer
 */
export function CancelTransaction(moneyTransferId) {
  return async (dispatch) => {
    try {
      const response = await MoneyTransferApi.CancelTransaction(
        moneyTransferId
      );

      dispatch({
        type: MONEY_TRANSFER.CANCEL_TRANSACTION_SUCCESS,
        cancelledTransaction: {
          success: true,
          message: response.data.message
        }
      });
    } catch (err) {
      dispatch({
        type: MONEY_TRANSFER.CANCEL_TRANSACTION_ERROR,
        cancelledTransaction: {
          success: false,
        },
        errors: err.data.errors
      });
    }
  };
}

export function SetInitialToAccountWorkflowPages(serviceType: number, payload: WorkflowStep[]) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.SET_INITIAL_TO_ACCOUNT_WORKFLOW_PAGES,
      pages: payload,
      serviceType
    });
  };
}

export function SetWorkflowPageNumber(payload: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.SET_WORKFLOW_PAGE_NUMBER,
      pageNumber: payload,
    });

    // If user clicks back button and goes to rates card pages, clear the dynamic workflow pages
    if (payload === 2) {
      dispatch({
        type: MONEY_TRANSFER.CLEAR_DYNAMIC_WORKFLOWPAGES
      })
    }
  };
}

/**
 * Retrieves all available money transfer countries for all providers and saves in state.
 */
export function SetAvailableCountries(payload: GetAvailableCountriesResponse) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES,
      availableCountries: payload.moneyTransferCountries,
      sendLimit: payload.sendLimit,
      restrictions: payload.restrictions,
    });
  };
}

/**
 * Retrieves all available money transfer countries for all providers and saves in state.
 */
export function SetAvailableProvinces(payload: { options: { label: string, value: string, disabled: boolean }[] }) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.GET_AVAILABLE_PROVINCES,
      availableProvinces: payload.options,
    });
  };
}

export function GetMoneyTransferProviderRates(payload: GetMoneyTransferProviderRates) {
  return (dispatch: Dispatch) => {
    const { destinationCountry, destinationCountryName, destinationProvince, destinationCity, sendAmount, receiveCurrency } = payload;
    dispatch({
      type: MONEY_TRANSFER.GET_RATES_SUCCESS,
      rateQuery: {
        destinationCountry,
        destinationCountryName,
        destinationProvince,
        destinationCity,
        sendAmount,
        receiveCurrency,
      },
      providerRates: payload.providerRates
    });
    dispatch({
      type: MONEY_TRANSFER.SET_WORKFLOW_PAGE_NUMBER,
      pageNumber: 2
    });
  };
}

/**
 * Retrieves all workflow information from the provider of this quote.
 * @param {number} quoteId The ID of the quote to get workflow info for
 */
export function GetMoneyTransferWorkflow(quoteId: number, workflow: GetWorkflowResponse) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.GET_WORKFLOW,
      workflow,
      quoteId
    });
  };
}

/**
 * Adds Review and Confirm page to workfloow
 */
export function AddWorkflowStep(page: WorkflowStep) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.ADD_WORKFLOW_STEP,
      page
    });
  };
}

/**
 * Remove workflow step
 */
export function RemoveWorkflowStep(payload: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.SET_WORKFLOW_PAGE_NUMBER,
      pageNumber: payload,
    });

    dispatch({
      type: MONEY_TRANSFER.REMOVE_WORKFLOW_STEP
    });
  };
}

/**
 * Adds Receipt page to workfloow
 */
export function AddReceiptStep(response: CommitMoneyTransferResponse, page: WorkflowStep) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.COMMIT_SUCCESS,
      commitResult: response
    });

    dispatch({
      type: MONEY_TRANSFER.ADD_WORKFLOW_STEP,
      page
    });
  };
}

/**
 * Set Money Transfer Details from api response
 */
export function SetMoneyTransferDetails(response: GetMoneyTransferDetailsResponse) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.GET_TRANSFER_DETAILS_SUCCESS,
      transferDetails: response,
    });
  };
}

/**
 * Set Money Transfer serviceType
 */
export function SetMoneyTransferServiceType(serviceType: number) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: MONEY_TRANSFER.SET_SERVICE_TYPE,
      serviceType
    });
  };
}