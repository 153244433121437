import React from "react";
import PropTypes from "prop-types";

/**
 * Generates a button
 */
export class FormControl extends React.Component {
  constructor(props) {
    super(props);
  }

  renderValidationError() {
    if (this.props.validationError) {
      return <span className="text-danger">{this.props.validationError}</span>;
    } else {
      return null;
    }
  }

  render() {
    let errorClass = this.props.validationError ? "has-error" : "";

    return (
      <div className={`form-group ${errorClass}`}>
        <label>{this.props.label}</label>
        <input
          className="form-control"
          type="text"
          onChange={this.props.onChange}
          defaultValue={this.props.value}
          disabled={this.props.disabled}
          autoFocus={this.props.autoFocus}
        />
        {this.renderValidationError()}
      </div>
    );
  }
}

FormControl.propTypes = {
  /** Button title */
  label: PropTypes.string,
  /** Image url if no link text is provided */
  value: PropTypes.string,
  /** On text cange event */
  onChange: PropTypes.func,
  /** Validation error text */
  validationError: PropTypes.string,
  /** Determines if the input is selected by default. */
  autoFocus: PropTypes.bool,
  /** Disables the form control */
  disabled: PropTypes.bool,
};

export default FormControl;
