import React from "react";
import PropTypes from "prop-types";
import bankAccount from "../../assets/images/categories/BankAccount_icon_web.svg";
import forgotPin from "../../assets/images/categories/ForgotPin_Icon_web.svg";
import lostStolen from "../../assets/images/categories/LostStolen_Icon_web.svg";
import moneyTransfer from "../../assets/images/categories/TransferIssue_Dollar_icon_web.svg";
import Card from "../../assets/images/categories/Card.svg";
import other from "../../assets/images/categories/binoculars-offBlue.svg";
import wallet from "../../assets/images/categories/Dispute_Dollar_icon_web.svg";

/**
 * Switch component to determine the category icon
 * @param id
 * @returns {*}
 * @constructor
 */
export const CategoryImage = ({ id, ...props }) => {
  let icon;
  switch (id) {
    case "loggedin_forgot_pin":
      icon = forgotPin;
      break;
    case "lost/stolen_card":
      icon = lostStolen;
      break;
    case "moneytransfer":
      icon = moneyTransfer;
      break;
    case "my_card":
      icon = Card;
      break;
    case "transaction_question/dispute":
      icon = wallet;
      break;
    case "bank_account":
      icon = bankAccount;
      break;
    default:
      icon = other;
      break;
  }

  return <img alt={id} src={icon} {...props} />;
};

CategoryImage.propTypes = {
  /** Category Id */
  id: PropTypes.string.isRequired,
};

export default CategoryImage;
