import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { Field, reduxForm, change } from 'redux-form';
import CountryPicker from '../Common/CountryPicker';
import GeneralSelect from '../Common/GeneralSelect';
import CurrencyInput from '../Common/CurrencyInput';
import { COMMON, PAGES } from '../../constants/localization';
import { ACTION_DISPLAY_TYPES } from '../../constants/enums';
import Action from '../Common/Action';
import ExchangeRateApi from '../../api/exchangeRate';
import { COUNTRY_CURRENCIES } from '../../constants/countryCurrencies';
import { ROUTES } from '../../constants/clientRoutes';
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import ActionLink from '../Common/ActionLink';

/** 
  overZero returns zero if a person attempts to input a number less than zero.
  this is the preferred way to handle min/max with react/redux.
@param value = number parameter from the number input
 */
const overZero = (value) => {
  if (value < 0) {
    return 0;
  } else {
    return value;
  }
};

const ExchangeRateModal = (props) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState()
  const [destinationCountry, setDestinationCountry] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [response, setResponse] = useState(null);
  const [showFooter, setShowFooter] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState(null);
  const [disableGetRates, setDisableGetRates] = useState(true);
  const sendCurrency = useSelector((state) => state.card.currencyCode);
  const exchangeRateForm = useSelector((state) => state.form.ExchangeRate);
  const { featureFlags } = props;

  useEffect(() => {
    let sortedCountries = COUNTRY_CURRENCIES.slice();
    sortedCountries.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });
    sortedCountries = sortedCountries.map(country => ({
      name: country.name,
      id: country.id,
      iso: country.isoCode,
      currencies: country.currencies
    }));

    setCountries(sortedCountries);
  }, []);

  useEffect(() => {
    if (exchangeRateForm?.values) {
      const { values } = exchangeRateForm;
      if (values.destinationCountry && values.sendAmount && (Number(values.sendAmount) > 0)) {
        setDisableGetRates(false);
      } else {
        setDisableGetRates(true);
      }
    } else {
      setDisableGetRates(true);
    }
  }, [exchangeRateForm]);

  const handleCountryChange = (countryCode) => {
    const country = countries?.filter(
      (c) => c.iso === countryCode
    )[0];

    // handle when user click x in country dropdown selection
    // and removes country
    if (country) {
      setDestinationCountry(country);

      let currencyOptions = country.currencies.map((c) => {
        return { value: c.code, label: c.code };
      });

      resetValues(currencyOptions);
      setCurrencyOptions(currencyOptions);
    } else {
      setDestinationCountry('');
    }
  }

  const resetValues = (currencyOptions) => {
    setShowFooter(false);
    dispatch(change('ExchangeRate', 'receiveCurrency', currencyOptions[0].value));
    dispatch(change('ExchangeRate', 'sendAmount', ''));
    setResponse(null);
  }

  const onSubmit = async (e) => {
    setSubmitting(true);
    const destinationCurrencyId = countries?.filter((c) => c.iso === destinationCountry.iso)[0].currencies.filter((c) => c.code === e.receiveCurrency)[0].id;
    try {
      const response = await ExchangeRateApi.CheckExchangeRate(e.sendAmount, sendCurrency, destinationCountry.id, destinationCurrencyId);
      setResponse(response);
      setShowFooter(true);
      setSubmitting(false);
    } catch (err) {
      setSubmitting(false);
      setErrors(err.data.errors[0].errorDescription);
    }
  }

  return (
    <React.Fragment>
      {errors &&
        <p className="validation-error">{errors}</p>
      }

      <form onSubmit={props.handleSubmit(onSubmit)}>
        <div className="exchange-rate-modal-body">

          <div className="form-group">
            <label>{COMMON.TRANSFER_TO}</label>
            <Field
              type="select"
              component={CountryPicker}
              props={{
                dataCy: "exchangeRateModalCountryPicker",
              }}
              name="destinationCountry"
              placeholder={COMMON.SELECT_COUNTRY}
              onChange={handleCountryChange}
              disabled={submitting}
              countries={countries}
              autoFocus
            />
          </div>

          <div className={"form-group"}>
            <label>{COMMON.AMOUNT_TO_SEND}</label>
            <Field
              type="number"
              normalize={overZero}
              component={CurrencyInput}
              decimalPlaces={2}
              currencyIso={sendCurrency}
              disabled={destinationCountry === ''}
              name="sendAmount"
              aria-describedby="basic-addon2"
            />
          </div>

          <div className="form-group">
            <label>{PAGES.DASHBOARD.EXHANGE_RATE_MODAL.BENEFICIARY_RECEIVES}</label>
            <div className="flex-row align-items-center">
              <p className="spacing-right-tiny fx-amount spacing-bottom-none">{response ? response.fxAmountFormatted : '0'}</p>
              <Field
                name="receiveCurrency"
                component={GeneralSelect}
                disabled={destinationCountry === ''}
                placeholder={""}
                options={currencyOptions}
                className="currency-picker"
              ></Field>
            </div>
          </div>

          <div className={`row actions`}>
            <Action
              className="get-rates-button full-width"
              type="submit"
              title={PAGES.MONEY_TRANSFER.RATE_LIST.GET_RATES}
              displayType={ACTION_DISPLAY_TYPES.SECONDARY}
              loading={submitting}
              disabled={disableGetRates}
            />
          </div>

          {showFooter &&
            <React.Fragment>
              <p className="marengo-grey text-left spacing-top-tiny">{response.exchangeRateDescription}</p>

              <p className="marengo-grey text-left">*The exchange rate used for your transfer will be determined on the day your payment is made. The exchange rate presented above is representative of the rate you would receive for a transfer performed now.</p>

              {featureFlags.includes(FEATURE_FLAGS.SEND_TO_BANK_ACCOUNT) &&
                <ActionLink
                  classes="btn btn-primary full-width"
                  href={ROUTES.EXTERNAL.ON_DEMAND_TRANSFER}
                  text="Send to a Bank Account"
                />
              }
            </React.Fragment>
          }

        </div>
      </form>
    </React.Fragment>
  );
}

ExchangeRateModal.propTypes = {
  /** redux-form submit function */
  handleSubmit: PropTypes.func,
  /** user featureFlags data from redux */
  featureFlags: PropTypes.array
}

export default reduxForm({
  form: 'ExchangeRate',
})(ExchangeRateModal);