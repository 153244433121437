import React from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import get from "lodash/get";
import pdfPlaceholder from "../../assets/images/pdf-attachment-placeholder.svg";
import imagePlaceholder from "../../assets/images/upload-attachment.svg";
import NavigatorLogo from "../../assets/images/support/navigator-logo-white.svg";
import _ from "lodash";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import i18n from "../../i18n/config";

export const Message = ({
  text,
  name,
  isUser,
  timestamp,
  attachments,
}) => {
  const openExternalLinksModal = useClickExternalLink();
  let split = name.split(" ");
  let initials = get(split, "[0][0]", "") + get(split, "[1][0]", "");

  const hiddenImage = (
    <div>
      <img className="security-image" src={imagePlaceholder} /> {text}
      <div className="security-message">Image hidden for your security</div>
    </div>
  );

  let attachmentView = null;

  if (attachments && attachments.length > 0) {
    attachmentView = attachments.map((attachment) => {
      if (isUser) return;
      let url = attachment.url;
      let imageUrl = url;
      if (attachment.type === 2) {
        imageUrl = pdfPlaceholder;
      }
      return (
        <div key={_.uniqueId()} className="attachment-container">
          <a href={url} target="_blank" rel="noreferrer noopener" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} onClick={(e) => openExternalLinksModal(e, url)}>
            <img src={imageUrl} />
          </a>
        </div>
      );
    });
  }

  const showHiddenImage = isUser && attachments.length > 0;

  return (
    <div className="clearfix">
      <div className={`message message-${isUser ? "own" : "bwp"}`}>
        <div className="message-container">
          <div className="message-text">
            {showHiddenImage && hiddenImage}
            {!showHiddenImage && text}
            {attachmentView}
          </div>
          <div className={`profile-icon-container ${isUser ? "" : "profile-icon-container__brightwell"}`}>
            {!isUser && <img src={NavigatorLogo} className="profile-icon" />}
            {isUser && (
              <div data-initials={initials} className="profile-icon" />
            )}
          </div>
        </div>
        <div className="time">
          <span>{format(timestamp, "hh:mm a")}</span>
        </div>
      </div>
    </div>
  );
};

Message.propTypes = {
  text: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  isUser: PropTypes.bool.isRequired,
  timestamp: PropTypes.string.isRequired,
  attachments: PropTypes.array,
};

export default Message;
