import { SUPPORT } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function errorsReducer(
  state = initialState.support.errors,
  action
) {
  switch (action.type) {
    case SUPPORT.GET_INDEX_SUCCESS:
    case SUPPORT.POST_NEW_MESSAGE_SUCCESS:
    case SUPPORT.POST_NEW_ATTACHMENT_SUCCESS:
    case SUPPORT.POST_NEW_CONVERSATION_SUCCESS:
      return null;
    case SUPPORT.GET_INDEX_FAILURE:
    case SUPPORT.POST_NEW_MESSAGE_FAILURE:
    case SUPPORT.POST_NEW_ATTACHMENT_FAILURE:
    case SUPPORT.POST_NEW_CONVERSATION_FAILURE:
      return Object.assign({}, { message: action.message });
    default:
      return state;
  }
}
