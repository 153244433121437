import React, { useEffect, useState } from 'react';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import DisplaySection from '../../components/MoneyTransfer/DisplaySection';
import { DisclaimerType, DisplaySectionType } from '../../types/api/moneyTransfer';
import { GetRRTransferDetailsResponse } from '../../types/api/card';
import ENV from "../../constants/environment";
import { ROUTES } from '../../constants/clientRoutes';
import i18n from '../../i18n/config';
import { RRSDKTransferDetailsProps } from '../../types/props';
import CardApi from '../../api/card';

import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import ActionRow from '../../components/Layouts/ActionRow';
import ActionLink from '../../components/Common/ActionLink';
import AlertModal from '../../components/Common/Modals/AlertModal';
import { TRANSFER_METHODS } from '../../constants/enums';

const RRSDKTransferDetails = (props: RRSDKTransferDetailsProps) => {
  const [sections, setSections] = useState<DisplaySectionType[]>();
  const [disclaimers, setDisclaimers] = useState<DisclaimerType[]>();
  const [receipt, setReceipt] = useState<{ downloadReceiptText: string, downloadReceiptUrl: string }>();
  const [footer, setFooter] = useState<string>();
  const [transferId, setTransferId] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const getTransferDetails = async () => {
      try {
        const transferId = props.transferMethod === TRANSFER_METHODS.PUSH_TO_CARD ? props.match.params.pushToCardTransferId : props.match.params.transferId;
        const { result } = await CardApi.GetRRSDKTransferDetails(transferId, props.transferMethod) as GetRRTransferDetailsResponse;
        setSections(result.displaySections);
        setDisclaimers(result.footer.disclaimers);
        setReceipt({ downloadReceiptText: result.downloadReceiptText, downloadReceiptUrl: result.downloadReceiptUrl });
        setFooter(result.footer.content);
        setTransferId(transferId);
        setLoading(false);

      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }
        setLoading(false);
        setShowErrorModal(true);
      }
    }

    getTransferDetails()
  }, []);

  const handleGetReceipt = async () => {
    try {
      await CardApi.GetRRSDKTransferReceipt(receipt.downloadReceiptUrl, transferId, props.transferMethod);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setLoading(false);
      setShowErrorModal(true);
    }
  }

  /**
   * Build the disclaimers to display
   * @param {*} disclaimers
   */
  const renderDisclaimers = (disclaimers: DisclaimerType[]) => {
    if (disclaimers?.length > 0) {
      return disclaimers.map((d) => {
        return (
          <p key={d.disclaimerId} className="x-small disclaimer marengo-grey">
            <sup>{d.disclaimerId} </sup>
            {d.text}
          </p>
        );
      });
    }
  }

  return (
    <FlowScreen flowTitle="Transaction details" className="receipt display-section-wrapper">
      <PageBlock>
        <PageBlock.Loader loadingContent={loading} >
          <InputSkeletonGroup numInputs={4} />
        </PageBlock.Loader>
        <PageBlock.Body loadingContent={loading}>
          <DisplaySection sections={sections} className="spacing-bottom-small grid-cols-1 card-header__transparent" />
          {renderDisclaimers(disclaimers)}
          <div dangerouslySetInnerHTML={{ __html: footer }} />
          <ActionRow>
            <ActionRow.Forward>
              <ActionLink
                clickFunc={handleGetReceipt}
              >
                {receipt?.downloadReceiptText}
              </ActionLink>
            </ActionRow.Forward>
            <ActionRow.Backward>
              <ActionLink
                classes="spacing-none"
                href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.MY_CARD.INDEX}`}
              >
                <div className="glyphicon glyphicon-menu-left link_icon"></div>
                {i18n.t("COMMON.BACK")}
              </ActionLink>
            </ActionRow.Backward>
          </ActionRow>

        </PageBlock.Body>
      </PageBlock>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />
    </FlowScreen>
  );
}

export default RRSDKTransferDetails;