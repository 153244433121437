import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function onDemandTransfer(state = initialState.onDemandTransfer, action) {
  switch (action.type) {
    case types.ON_DEMAND_TRANSFER.UPDATE_DESTINATION_INFO:
      return Object.assign({}, state, {
        ...state,
        allocationId: action.payload.allocationId,
        destinationCurrency: action.payload.destinationCurrency,
        destinationCountryIso: action.payload.destinationCountryIso,
        destinationCountryName: action.payload.destinationCountryName,
        destinationCountryId: action.payload.destinationCountryId,
        isPOPRequired: action.payload.isPOPRequired,
        isPinRequired: action.payload.isPinRequired,
        isDoddFrank: action.payload.isDoddFrank,
        bankAccountFeeBanner: action.payload.bankAccountFeeBanner,
      });
    case types.ON_DEMAND_TRANSFER.UPDATE_ODT_TERMS_AND_CONDTIONS_DOC:
      return Object.assign({}, state, {
        ...state,
        odtTermsAndConditionsDocId: action.payload.odtTermsAndConditionsDocId,
        feeBanner: action.payload.feeBanner,
      });

    case types.ON_DEMAND_TRANSFER.UPDATE_DODD_FRANK_DISBURSEMENT:
      return Object.assign({}, state, {
        ...state,
        displaySections: action.payload.displaySections,
        disbursementId: action.payload.disbursementId,
      });
    case types.ON_DEMAND_TRANSFER.SET_RATE_INQUIRY_DATA:
    case types.ON_DEMAND_TRANSFER.POST_TRANSFER_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        ...action.payload,
      });
    default:
      return state;
  }
};
