import React from "react";
import PropTypes from "prop-types";
import { format, parse } from "date-fns";

/**
 * Displays a formatted timestamp
 *
 * @param timestamp
 * @returns {jsx}
 * @constructor
 */
export const DateHeader = ({ timestamp }) => {
  let dt = parse(timestamp);
  let date = new Date(
    dt.getFullYear(),
    dt.getMonth(),
    dt.getDate(),
    0,
    0,
    0,
    0
  );

  return (
    <div className="date-header">{format(date, "ddd[,] MMM Do YYYY")}</div>
  );
};

DateHeader.propTypes = {
  /** DateTime to display */
  timestamp: PropTypes.string.isRequired,
};

export default DateHeader;
