import React from "react";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import PropTypes from "prop-types";

const FlowBackToDashboardButton = (props) => {
  return (
    <Action
      iconRight={null}
      title={props.title}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      className="btn-lg flowbutton__return-dashboard"
      onClick={() => (location.href = ROUTES.EXTERNAL.DASHBOARD)}
      {...props}
    />
  );
};

FlowBackToDashboardButton.propTypes = {
  title: PropTypes.string,
};

FlowBackToDashboardButton.defaultProps = {
  title: COMMON.BACK_TO_DASHBOARD,
};

export default FlowBackToDashboardButton;
