import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";
import Action from "./Action";
import { COMMON } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES, ACTION_TYPES } from "../../constants/enums";
import Spinner from "./Spinner";
import Icon from "./Icon";
import { setIsInert } from "../../actions/global";
import { useDispatch } from "react-redux";

/**
 * Renders the modal body element
 * @param {string || node} content The modal content.
 * @param {bool} contentLoading Shows a loading spinner if the content is loading.
 */
const renderModalBody = (content, contentLoading) => {
  if (contentLoading) {
    return (
      <div className="modal-body loading">
        <Spinner show />
      </div>
    );
  }

  const contentType = typeof content;
  if (contentType === "string") {
    return (
      <div
        className="modal-body"
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    );
  } else {
    return <div className="modal-body">{content}</div>;
  }
};

/**
 * Renders the modal footer based on the existence of actions
 * @param {array} actions list of actions to be rendered in the footer
 * @param {bool} loading loading state of the buttons
 */
const renderModalFooter = (actions, loading) => {
  if (actions.length > 0) {
    return (
      <div>
        {/* Buttons need to be reversed order in mobile view */}
        <div className="modal-footer hidden-xs">
          {actions.map((action) => {
            return renderAction(action, loading);
          })}
        </div>
        <div className="modal-footer visible-xs">
          {actions
            .slice()
            .reverse()
            .map((action) => {
              return renderAction(action, loading);
            })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

/**
 * Renders a modal action
 * @param {object} action The action object
 * @param {boolean} loading Flag indicating whether or not the action should be in a loading state.
 */
const renderAction = (action, loading) => {
  return (
    <Action
      key={action.title}
      onClick={(e) => {
        action.onClick(e, action);
      }}
      dataCy={action.title.toLowerCase().split(" ").join("")}
      title={action.title}
      displayType={action.displayType}
      loading={loading && !action.dontDisplayLoading}
      disabled={action.disabled}
      autoFocus={action.focused}
      className={action.className}
    />
  );
};

/**
 * Renders the close button on the modal
 * @param {bool} hide - Skips rendering the close icon when true
 * @param {function} onClose - Fucntion executed when button/icon clicked
 */
const renderCloseButton = (hide, onClose) => {
  if (!hide) {
    return (
      <button
        onClick={onClose}
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">
          <span className="glyphicon glyphicon-remove-circle"></span>
        </span>
      </button>
    );
  }
};

/**
 * Displays a simple informational modal
 */
export const _Modal = ({
  open,
  onClose,
  content,
  title,
  actions,
  disableNativeActions,
  loading,
  small,
  stickyFooter,
  contentLoading,
  className,
  closeOnOverlayClick,
  hideCloseIcon,
  headerIcon
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsInert(open));
  }, [open])

  let sizeClass = small ? "small" : "";
  let stickyFooterClass = stickyFooter ? "sticky-footer" : "";
  let classNames = {
    modal: `modal-dialog v2 ${sizeClass} ${className} ${stickyFooterClass}`,
  };

  if (!actions?.length) {
    if (disableNativeActions) {
      actions = [];
    } else {
      actions = [
        {
          title: COMMON.OK,
          displayType: ACTION_DISPLAY_TYPES.PRIMARY,
          onClick: onClose,
          actionType: ACTION_TYPES.DISMISS,
          focused: true,
        },
      ];
    }
  }

  return (
    <Modal
      classNames={classNames}
      open={open}
      onClose={onClose}
      showCloseIcon={false}
      closeOnOverlayClick={closeOnOverlayClick}
      center
    >
      <div className="modal-content">
        <div className="modal-header">
          {renderCloseButton(hideCloseIcon, onClose)}
          {headerIcon &&
            <Icon icon={headerIcon} />
          }
          <h4 className="modal-title">{title}</h4>
        </div>
        {renderModalBody(content, contentLoading)}
        {renderModalFooter(actions, loading)}
      </div>
    </Modal>
  );
};

_Modal.defaultProps = {
  closeOnOverlayClick: true,
  hideCloseIcon: false,
};

_Modal.propTypes = {
  /** Inner modal content */
  content: PropTypes.any.isRequired,
  /** Modal title */
  title: PropTypes.any.isRequired,
  /** Callback on close */
  onClose: PropTypes.func.isRequired,
  /** Callback on open */
  open: PropTypes.bool.isRequired,
  /** The collection of actions to display in the modal */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      className: PropTypes.string,
      title: PropTypes.string,
      displayType: PropTypes.number,
      onClick: PropTypes.func,
      actionType: PropTypes.number,
      focused: PropTypes.bool,
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
    })
  ),
  /** Sets the loading state and displays a spinner */
  loading: PropTypes.bool,
  /** Sets the size of the modal. */
  small: PropTypes.bool,
  /** The css class to apply to the modal */
  className: PropTypes.string,
  /** The bool to override the default action. Useful when modal renders a form with it's own actions */
  disableNativeActions: PropTypes.bool,
  /** Shows a loading spinner in the content box */
  contentLoading: PropTypes.bool,
  /** Content scrolls behind the footer. */
  stickyFooter: PropTypes.bool,
  /** Sets behavior when clicking outside of the modal */
  closeOnOverlayClick: PropTypes.bool,
  /** Sets visibility of the close icon */
  hideCloseIcon: PropTypes.bool,
  /** Icon to be included in header to the left of header title */
  headerIcon: PropTypes.number
};

export default _Modal;
