import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../../i18n/config';
import { ACTION_DISPLAY_TYPES } from '../../constants/enums';
import { ROUTES } from '../../constants/clientRoutes';
import { Switch, Route, useHistory } from 'react-router-dom';
import { RootState } from '../../types/redux';
import { PostTransferResponse } from '../../types/api/odt';
import OnDemandTransferApi from '../../api/onDemandTransfer';
import { PostTransferSuccess } from '../../actions/onDemandTransfer';

import FlowScreen from '../../components/Layouts/FlowScreen';
import ActionRow from '../../components/Layouts/ActionRow';
import Action from '../../components/Common/Action';
import ActionLink from '../../components/Common/ActionLink';
import BankAccountSelection from './BankAccountSelection';
import AmountPurpose from './AmountPurpose';
import AlertModal from '../../components/Common/Modals/AlertModal';
import ExchangeRate from './ExchangeRate';
import ODTTransferDetails from './ODTTransferDetails';
import PrintPage from '../../components/ODT/PrintPage';
import ODTTransferConfirmation from './ODTTransferConfirmation';

type ODTAction = {
  displayType?: number;
  title?: string;
  type?: string;
  onClick: () => void;
}

const ODTWrapper = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [backButton, setBackButton] = useState<ODTAction>();
  const [forwardButton, setForwardButton] = useState<ODTAction>();
  const [disabled, setDisabled] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showButtons, setShowButtons] = useState(true);
  const [showPrint, setShowPrint] = useState(false);
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);

  const props = { setTitle, setButtonLoading, setBackButton, setForwardButton, setShowErrorModal, setErrorMessage, setShowButtons, setDisabled };

  const handleSubmit = async () => {
    setButtonLoading(true);
    try {
      let response: PostTransferResponse;

      if (onDemandTransfer.isDoddFrank) {
        const data = {
          disbursementId: onDemandTransfer.disbursementId,
          rateLogGuid: onDemandTransfer.rateLogGuid,
          serviceProviderId: onDemandTransfer.providerId,
        }
        response = await OnDemandTransferApi.PostTransferDoddFrank(data);
      } else {
        const data = {
          rateLogGuid: onDemandTransfer.rateLogGuid,
          providerReferenceId: onDemandTransfer.providerReferenceId,
          serviceProviderId: onDemandTransfer.providerId,
          allocationId: onDemandTransfer.allocationId,
          transferAmount: onDemandTransfer.transferAmount,
          transferFee: onDemandTransfer.feeAmount,
          fxRate: onDemandTransfer.exchangeRate.rate,
          purposeOfPaymentCustom: onDemandTransfer.purposeOfPaymentCustom,
          purposeOfPaymentSelected: onDemandTransfer.purposeOfPaymentSelected,
          purposeOfPaymentId: onDemandTransfer.purposeOfPaymentId,
        }
        response = await OnDemandTransferApi.PostTransferNonDoddFrank(data);
      }

      dispatch(PostTransferSuccess(response));
      setButtonLoading(false);
      history.push(ROUTES.ODT.TRANSFER_CONFIRMATION);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setButtonLoading(false);
      setShowErrorModal(true);
    }

  }

  useEffect(() => {
    const { pathname } = history.location;
    if (pathname.includes(ROUTES.ODT.TRANSFER_DETAILS) || pathname.includes(ROUTES.ODT.TRANSFER_CONFIRMATION)) {
      setShowPrint(true);
    } else {
      setShowPrint(false);
    }
  }, [history.location.pathname]);

  return (
    <FlowScreen
      flowTitle={title}
      className="position-relative"
    >
      {showPrint &&
        <PrintPage />
      }

      <Switch>
        <Route path={ROUTES.ODT.BANK_ACCOUNT_SELECTION}  >
          <BankAccountSelection props={props} />
        </Route>

        <Route path={ROUTES.ODT.AMT_PURPOSE}  >
          <AmountPurpose props={props} />
        </Route>

        <Route path={ROUTES.ODT.EXCHANGE_RATE}  >
          <ExchangeRate props={props} handleSubmit={handleSubmit} />
        </Route>

        <Route path={ROUTES.ODT.TRANSFER_DETAILS}  >
          <ODTTransferDetails props={props} handleSubmit={handleSubmit} />
        </Route>

        <Route path={ROUTES.ODT.TRANSFER_CONFIRMATION}  >
          <ODTTransferConfirmation props={props} />
        </Route>

      </Switch>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />

      {showButtons &&
        <ActionRow>
          <ActionRow.Forward>
            <Action
              displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              title={forwardButton?.title || ""}
              onClick={() => forwardButton?.onClick()}
              type={forwardButton?.type}
              loading={buttonLoading}
              disabled={disabled}
            />

          </ActionRow.Forward>
          <ActionRow.Backward>

            <ActionLink
              clickFunc={() => backButton?.onClick()}
            >
              <div className="glyphicon glyphicon-menu-left link_icon"></div>
              {i18n.t("COMMON.BACK")}
            </ActionLink>
          </ActionRow.Backward>
        </ActionRow>
      }
    </FlowScreen>
  );
}

export default ODTWrapper;