import React from "react";
import UserApi from "../../api/user";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import { connect } from "react-redux";
import { AddErrors, ClearErrors } from "../../actions/errors";
import PropTypes from "prop-types";
import { PAGES } from "../../constants/localization";
import { ERROR_CODES } from "../../constants/errorCodes";
import PasswordResetError from "./PasswordResetError";
import PageBlock from "../../components/Layouts/PageBlock.js";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import NewPasswordEntry from "./NewPasswordEntry";
import * as _ from "lodash";
import moment from "moment";

class ChangePasswordContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      isExpired: false,
      isUsernameError: false,
      errorMessage: "",
      errorTitle: "",
      sessionInfo: {},
      showGenericErrorModal: false,
      loading: false,
    };
  }

  /**
   * Initializes the session if not already initialized.
   */
  componentDidMount() {
    this.setState({
      loading: true,
    });
    if (_.isEmpty(this.props.sessionInfo)) {
      this.intializeSession();
    } else {
      const { userId, duration, token, passwordValidationRules } =
        this.props.sessionInfo;
      this.setSessionInfo({
        userId,
        token,
        duration,
        passwordValidationRules,
        isExpired: false,
      });
    }
  }

  /**
   * Clean up before unmounting
   */
  componentWillUnmount() {
    // Clean up step - remove timeout when the component is unmounted.
    clearTimeout(this.turnOffSessionTimeout);
  }

  /**
   * Sets the Change password session to state.
   */
  setSessionInfo = (sessionInfo) => {
    this.setState({
      loading: false,
      sessionInfo: sessionInfo,
    });
    let milliseconds = moment.duration(sessionInfo.duration).asMilliseconds();
    this.turnOffSessionTimeout = setTimeout(() => {
      //set to loading immediately
      this.setState({ loading: true });

      //set session as expired
      let sessionInfo = this.state.sessionInfo;
      sessionInfo.isExpired = true;
      this.setState({
        hasError: true,
        sessionInfo: sessionInfo,
        errorTitle:
          PAGES.FORGOT_PASSWORD.NEW_PASSWORD_ENTRY.SESSION_EXPIRED_TITLE,
        errorMessage:
          PAGES.FORGOT_PASSWORD.NEW_PASSWORD_ENTRY.SESSION_EXPIRED_TEXT,
      });

      //remove loading screen after half a second
      setTimeout(() => this.setState({ loading: false }), 500);
    }, milliseconds);
  };

  /**
   * Initializes or retrieves the change password session.
   * If a session is available, it returns the token,
   * duration (how much time remaining in the session),
   * and the password validation rules.
   */
  intializeSession = () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          let response = await UserApi.InitializeChangePasswordSession(
            this.props.token
          );
          const { userId, duration, token, rules } = response;
          this.setSessionInfo({
            userId,
            token,
            duration,
            passwordValidationRules: rules,
          });
        } catch (error) {
          let {
            data: { errors },
          } = error;
          if (error.status != 500) {
            let isUsernameError =
              errors[0].errorCode ==
              ERROR_CODES.PASSWORD_RESET.DUPLICATE_USERNAME;
            this.setState({
              hasError: true,
              loading: false,
              isExpired: !isUsernameError,
              isUsernameError: isUsernameError,
              errorTitle: errors[0].errorTitle,
              errorMessage: errors[0].errorMessage,
            });
          } else {
            this.setState({
              hasError: true,
              showGenericErrorModal: true,
              loading: false,
            });
          }
        }
      }
    );
  };

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <PageBlock>
            <PageBlock.Loader loadingContent={this.state.loading}>
              <InputSkeletonGroup numInputs={1} />
            </PageBlock.Loader>
          </PageBlock>
        )}
        {!this.state.loading && this.state.hasError && (
          <PasswordResetError
            title={this.state.errorTitle}
            description={this.state.errorMessage}
            isExpired={this.state.isExpired}
            sessionInfo={this.state.sessionInfo}
            isUsernameError={this.state.isUsernameError}
          />
        )}
        {!this.state.loading && !this.state.hasError && (
          <NewPasswordEntry
            loading={this.state.loading}
            sessionInfo={this.state.sessionInfo}
            addError={this.props.addError}
            clearError={this.props.clearError}
            resetUserPassword={this.props.resetUserPassword}
            changePasswordResponse={this.props.changePasswordResponse}
          />
        )}
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addError: (errors) => {
      dispatch(AddErrors(errors));
    },
    clearError: () => {
      dispatch(ClearErrors());
    },
  };
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
  };
}

ChangePasswordContainer.propTypes = {
  token: PropTypes.string,
  errors: PropTypes.array,
  addError: PropTypes.func,
  clearError: PropTypes.func,
  loading: PropTypes.bool,
  sessionInfo: PropTypes.object,
  resetUserPassword: PropTypes.func,
  changePasswordResponse: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer);
