import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ActionLink from "../../components/Common/ActionLink";
import { OpenAuthSupportTicket, setAuthSupportInfo } from "../../actions/auth";
import ActionRow from "../../components/Layouts/ActionRow";
import { COMMON, PAGES } from "../../constants/localization";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../constants/enums";
import Action from "../../components/Common/Action";
import ErrorBlock from "../../components/Common/ErrorBlock";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker } from "../../services/eventTracker";

class LoginSupportEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      loadingSubmitTicket: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onBackPress = this.onBackPress.bind(this);
  }

  async onSubmit({ email }) {
    const { OpenAuthSupportTicket, setAuthSupportInfo, history, category } =
      this.props;
    this.setState({ loadingSubmitTicket: true, errors: null });

    OpenAuthSupportTicket(category, email)
      .then((data) => {
        if (data.supportTicketCreated) {
          eventTracker.track("NoEmailAccess_Support", { success: "true" });

          return setAuthSupportInfo({
            title: PAGES.AUTH.SUPPORT.FACECHECK_SUPPORT_TICKET,
            subtitle: PAGES.AUTH.SUPPORT.NEW_TICKET_SUBMITTED,
            ticketInfo: null,
            content: `<p>${PAGES.AUTH.SUPPORT.EMAIL_CONFIRMATION}</p><p><b>${data.email}</b></p>`,
            showCannotAccessEmail: false,
          });
        } else {
          eventTracker.track("NoEmailAccess_Support", { success: "false" });

          throw new Error(
            data.activeTicketInCategory
              ? PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED
              : PAGES.AUTH.SUPPORT.TICKET_NOT_CREATED
          );
        }
      })
      .then(() => {
        history.push(ROUTES.AUTH.SUPPORT);
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ errors: error.response.data.errors });
        } else {
          this.setState({ errors: [error] });
        }

        this.setState({ loadingSubmitTicket: false });
      });
  }

  renderErrors(errors) {
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  onBackPress() {
    location.href = ROUTES.AUTH.LOGIN;
  }

  validateEmail(value) {
    return !value || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
      ? "Invalid email address"
      : undefined;
  }

  render() {
    const { handleSubmit, valid } = this.props;
    const { loadingSubmitTicket, errors } = this.state;
    return (
      <div className="container container-md login-support-email">
        <div className="row">
          <div className="col-xs-12">
            <h1>{PAGES.AUTH.SUPPORT.FACECHECK_SUPPORT_TICKET}</h1>
          </div>
        </div>
        <div className="v2 page-block">
          {this.renderErrors(errors)}
          <h2 className="spacing-bottom-medium">
            {PAGES.AUTH.SUPPORT.CANNOT_ACCESS_EMAIL_ON_FILE}
          </h2>
          <p className="large spacing-bottom-medium">
            {PAGES.AUTH.SUPPORT.CANNOT_ACCESS_EMAIL_ON_FILE_DESCRIPTION}
          </p>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="security-question-form">
              <label>{PAGES.AUTH.SUPPORT.EMAIL_ADDRESS}</label>
              <div className="row">
                <div className="col-xs-12 col-lg-4">
                  <DynamicInputField
                    type={FIELD_TYPES.EMAIL}
                    id="email"
                    className="question-input form-group"
                    required
                    validate={this.validateEmail}
                  />
                </div>
              </div>
            </div>
            <ActionRow>
              <ActionRow.Forward>
                <Action
                  className="btn-lg"
                  disabled={!valid}
                  title={COMMON.SUBMIT}
                  displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                  loading={loadingSubmitTicket}
                  type="submit"
                />
              </ActionRow.Forward>
              <ActionRow.Backward>
                <ActionLink
                  classes="btn-lg"
                  clickFunc={this.onBackPress}
                  text={COMMON.BACK}
                />
              </ActionRow.Backward>
            </ActionRow>
          </form>
        </div>
      </div>
    );
  }
}

LoginSupportEmail.propTypes = {
  /** redux-form submit wrapper */
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  /** API login action */
  OpenAuthSupportTicket: PropTypes.func.isRequired,
  setAuthSupportInfo: PropTypes.func.isRequired,
  /** react-router history */
  history: PropTypes.any,
  submitting: PropTypes.bool,
  category: PropTypes.string,
};

let loginSupportEmailForm = reduxForm({
  form: "loginSupportEmail",
})(LoginSupportEmail);

function mapStateToProps(state) {
  return {
    category: state.auth.support.category,
  };
}

export default connect(mapStateToProps, {
  OpenAuthSupportTicket,
  setAuthSupportInfo,
})(loginSupportEmailForm);
