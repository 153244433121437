import React from 'react';
import { ODTTransferDetails } from '../../types/api/odt';
import ENV from "../../constants/environment";
import i18n from '../../i18n/config';

import ActionLink from '../Common/ActionLink';
import { ROUTES } from '../../constants/clientRoutes';

const TransferCard = ({ data }: { data: ODTTransferDetails }) => {

  const formatDate = (dateTime: string) => {
    const dateObj = new Date(dateTime);
    const month = dateObj.toLocaleString('default', { month: 'long' });
    return (
      `${dateObj.getDate()}-${month}-${dateObj.getFullYear()}`
    )
  }

  return (
    <article className="transfer-card">
      <div>
        <p>{formatDate(data.transferDate)}</p>
        <p>{data.bankAccountName}</p>
      </div>

      <div>
        <p>{data.formattedAmount}</p>
        <p>{data.bankAccountNumber}</p>
      </div>

      <div>
        <p>{data.transferCurrencyName} ({data.transferCurrencyCode})</p>
        <p>{data.bankName}</p>
      </div>

      <div>
        <p>{data.transferStatus}</p>
      </div>

      <div>
        {data.canCancel &&
          <ActionLink
            classes="btn btn-secondary small"
            text={i18n.t("COMMON.CANCEL")}
            href={`${ENV.BASE_NAVIGATOR_URL}/OnDemandTransfer/CancelTransfer/${data.transferId}`}
          />
        }
      </div>

    </article>
  );
}

export default TransferCard;