import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reduxForm, SubmissionError, getFormValues, initialize, isValid, change } from 'redux-form';
import { FORM_NAMES } from "../../constants/forms";
import { PAGES, PAGE_NAV_LINKS, COMMON } from "../../constants/localization";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import FlowSuccess from "../../components/Common/Success/FlowSuccess";
import SuccessCheckmark from "../../components/Common/Success/SuccessCheckmark";
import ActionLink from "../../components/Common/ActionLink";
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import PageBlock from "../../components/Layouts/PageBlock";
import { FIELD_TYPES, ACTION_DISPLAY_TYPES, USER_PROFILE_FIELDS } from "../../constants/enums";
import { ROUTES } from "../../constants/clientRoutes";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import { GetCountries, SetStateProvinces } from "../../actions/catalog";
import UserApi from "../../api/user";
import { RootState } from "../../types/redux";
import FormBody from "../../components/Common/DynamicFields/FormBody";
import { UpdateUserProfileRequest } from "../../types/api/user";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import CatalogApi from "../../api/catalog";
import AlertModal from "../../components/Common/Modals/AlertModal";
import i18n from "../../i18n/config";
import { mapErrorsToFields, renameKey } from "../../services/formatter";

/**
 * Main container for the personal details page
 */
const PersonalDetails = (props) => {
  const dispatch = useDispatch();
  const formValues = useSelector(state => getFormValues(FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS)(state)) as UpdateUserProfileRequest;
  const countries = useSelector((state: RootState) => state.catalog.countries);
  const states = useSelector((state: RootState) => state.catalog.stateProvinces);
  const valid = useSelector((state: RootState) => isValid(FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS)(state));
  const user = useSelector((state: RootState) => state.user);
  const [formattedCountries, setFormattedCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successfulUpdate, setSuccessfulUpdate] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    const dispatchGetCountries = async () => {
      await dispatch(GetCountries());
      setLoading(false);
    }

    dispatchGetCountries();

  }, []);

  useEffect(() => {
    if (user.profile.address && countries.length > 0) {

      dispatch(initialize(FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS, {
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        addressLine1: user.profile.address.line1,
        addressLine2: user.profile.address.line2,
        country: user.profile.address.country.iso,
        city: user.profile.address.city,
        state: user.profile.address.province.iso,
        province: user.profile.address.province.name,
        zipPostal: user.profile.address.postalCode,
        email: user.profile.email,
        phone: {
          number: user.profile.phoneNumbers[0].number,
          country: user.profile.phoneNumbers[0].country
        },
        mobileNumber: user.profile.isMobilePhone
      }));
    }

  }, [user.profile, countries]);

  useEffect(() => {

    const handleGettingStates = async () => {
      try {
        const response = await getStateProvinces(formValues.country);
        dispatch(SetStateProvinces(response));
        // Strange behavior with redux form where stateId initial value is being displayed when changing between countries with state/province dropdowns (US, CA, MX)
        // E.g. User has initial country value of US and initial state value of AL (Alabama)
        // User changes country to Canada and state dropdown still displays Alabama (initial value) as the placeholder 
        // Because there is no immediate value selected of state dropdown when country is changed to canada, redux-form reverts to initial value
        // However, we only want to start using this behavior after inital render, 
        // If we dispatch on initial render, then user's initial value from DB will be overwritten with the first state in the list
        if (!initialRender) {
          dispatch(change(
            FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS,
            "state",
            response[0].code
          ));
        }

      } catch (err) {
        dispatch(change(
          FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS,
          "state",
          ""
        ));
        handleDisplayErrorModal(err);
      }
    }

    // Inital render will not have formValues.countryIso
    if (formValues?.country) {
      // Only call handleGettingStates if country is US, CA, or MX
      if (formValues.country === "US" || formValues.country === "CA" || formValues.country === "MX") {
        handleGettingStates();
      }

      // When country is changed, reset province field to empty
      // Only do this after initial render, otherwise we will overwrite user's initial value from DB
      if (!initialRender) {
        dispatch(change(
          FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS,
          "province",
          ""
        ));
      }

      setInitialRender(false);
    }

    setFormattedCountries(() => {
      return countries.map(country => (
        {
          label: country.name,
          value: country.iso
        }
      ));
    })

  }, [formValues?.country]);

  const getStateProvinces = async (countryIso: string) => {
    let cacheKey = "GET_STATEPROVINCES_" + countryIso;
    let cached = localStorage.getItem(cacheKey);
    let expirationTime = localStorage.getItem(cacheKey + ":ts");
    if (
      cached &&
      (!expirationTime || Date.parse(expirationTime) > Date.now())
    ) {

      return JSON.parse(cached);
    } else {
      try {
        const response = await CatalogApi.GetStateProvinces(countryIso);
        if (response.stateProvinces.length > 0) {
          localStorage.setItem(cacheKey, JSON.stringify(response.stateProvinces));
          localStorage.setItem(
            cacheKey + ":ts",
            String(new Date(Date.now() + 10 * 60000))
          ); // set expiration for 10 min
        }

        return response.stateProvinces;
      } catch (err) {
        throw err;
      }
    }
  }

  const handleDisplayErrorModal = (err) => {
    if (err?.data?.errors) {
      setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
    } else {
      setErrorMessage(i18n.t("COMMON.ERROR"));
    }

    setShowErrorModal(true);
  }

  const formatValuesForAPI = () => {
    const isMobilePhone = formValues.mobileNumber ? 1 : 0;

    const userData = {
      userProfile: {
        phoneNumbers: [
          {
            type: isMobilePhone,
            number: formValues.phone.number,
            country: {
              iso: formValues?.phone?.country?.iso || ""
            }
          }
        ],
        isMobilePhone: isMobilePhone,
        email: formValues.email,
        address: {
          line1: formValues.addressLine1,
          line2: formValues.addressLine2,
          postalCode: formValues.zipPostal ? formValues.zipPostal : null,
          city: formValues.city,
          province: {
            iso: formValues.state,
            name: formValues.province,
          },
          country: {
            iso: formValues.country,
          },
        },
      },
    };
    return userData;
  }

  const onSubmit = async () => {
    trackEvent(EVENTS.USER_PROFILE.PROFILE_DETAILS_SAVE);
    setIsSubmitting(true);
    const userData = formatValuesForAPI();

    try {
      await UserApi.PutUserProfile(userData);
      setSuccessfulUpdate(true);
    } catch (err) {
      setIsSubmitting(false);
      let errorObj = mapErrorsToFields(err.data.errors);

      // The dropdown for state/province we use for US, MX, and CA is using the field name state
      // However server returns us 2 different field names depending on the country
      // i.e. "state" for US and MX, and "province" for CA
      // We want to rename the key to state so that the error message is displayed on the correct field
      if (formValues.country === "CA") {
        errorObj = renameKey(errorObj, USER_PROFILE_FIELDS.PROVINCE, USER_PROFILE_FIELDS.STATE);
      }

      // Check if any of the errors are related to the form fields
      // If so, throw a submission error to display the error messages on the form
      // Otherwise, display a generic error modal
      const errorKeys = Object.keys(errorObj);
      const formKeys = Object.keys(formValues);
      const isValidationError = errorKeys.some(key => formKeys.includes(key));

      if (isValidationError) {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        })
        throw new SubmissionError(errorObj);
      } else {
        handleDisplayErrorModal(err);
      }
    }
  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.SETTINGS.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        {successfulUpdate && (
          <FlowScreen
            flowTitle={PAGES.SETTINGS.PERSONAL_DETAILS.TITLE}
          >
            <FlowSuccess>
              <FlowSuccess.Image>
                <SuccessCheckmark />
              </FlowSuccess.Image>
              <FlowSuccess.Title>
                {PAGES.SETTINGS.PERSONAL_DETAILS.PAGE_ALERT_SUCCESSFUL}
              </FlowSuccess.Title>
              <FlowSuccess.ActionRow>
                <ActionLink
                  dataCy="AccountDetailsSuccessGoToDashboard"
                  classes="btn btn-primary"
                  href={ROUTES.EXTERNAL.DASHBOARD}
                  text={COMMON.BACK_TO_DASHBOARD}
                />
              </FlowSuccess.ActionRow>
            </FlowSuccess>
          </FlowScreen>
        )}
        {!successfulUpdate &&
          <FlowScreen
            flowTitle={PAGES.SETTINGS.PERSONAL_DETAILS.TITLE}
          >
            <GenericForm
              form={FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS}
              onSubmit={props.handleSubmit(onSubmit)}
              onUpdate={() => { }}
            >
              <FormBody>
                <PageBlock>
                  <PageBlock.Loader
                    loadingContent={loading}
                  >
                    <InputSkeletonGroup numInputs={6} />
                  </PageBlock.Loader>
                  <PageBlock.Body
                    loadingContent={loading}
                  >
                    <div className="col-sm-12 col-md-8 padding-left-none">

                      <DynamicInputField
                        label={"First Name"}
                        type={FIELD_TYPES.TEXT}
                        id={'firstName'}
                        required={true}
                        disabled={true}
                      />

                      <DynamicInputField
                        label={"Last Name"}
                        type={FIELD_TYPES.TEXT}
                        id={'lastName'}
                        disabled={true}
                        required={true}
                      />

                      <DynamicInputField
                        label={"Address Line 1"}
                        type={FIELD_TYPES.TEXT}
                        id={USER_PROFILE_FIELDS.ADDRESS_LINE_1}
                        required={true}
                      />

                      <DynamicInputField
                        label={"Address Line 2"}
                        type={FIELD_TYPES.TEXT}
                        id={USER_PROFILE_FIELDS.ADDRESS_LINE_2}
                        required={false}
                      />

                      <DynamicInputField
                        id={USER_PROFILE_FIELDS.COUNTRY}
                        type={FIELD_TYPES.COUNTRY}
                        label="Country"
                        required={true}
                        placeholderText="Select a Country"
                        options={formattedCountries}
                        focus={true}
                        isSearchable={true}
                      />

                      <DynamicInputField
                        label={"City"}
                        type={FIELD_TYPES.TEXT}
                        id={USER_PROFILE_FIELDS.CITY}
                        required={true}
                      />

                      {formValues?.country !== "US" && formValues?.country !== "CA" && formValues?.country !== "MX" && (
                        <DynamicInputField
                          id={USER_PROFILE_FIELDS.PROVINCE}
                          type={FIELD_TYPES.TEXT}
                          label="Province"
                          required={true}
                          focus={true}
                        />
                      )}

                      {(formValues?.country === "US" || formValues?.country === "MX" || formValues?.country === "CA") && (
                        <DynamicInputField
                          id={USER_PROFILE_FIELDS.STATE}
                          type={FIELD_TYPES.COUNTRY}
                          label={formValues?.country === "US" ? "State" : "Province"}
                          required={true}
                          placeholderText={`Select a ${formValues?.country ? "State" : "Province"}`}
                          options={states}
                          focus={true}
                          isSearchable={true}
                        />
                      )}

                      <DynamicInputField
                        id={USER_PROFILE_FIELDS.ZIP_POSTAL}
                        type={FIELD_TYPES.TEXT}
                        label="Postal"
                        focus={true}
                        required={formValues?.country === "US" || formValues?.country === "CA"}
                      />

                      <DynamicInputField
                        id={USER_PROFILE_FIELDS.EMAIL}
                        type={FIELD_TYPES.TEXT}
                        label="Email"
                        required={true}
                        focus={true}
                      />

                      <DynamicInputField
                        id={USER_PROFILE_FIELDS.PHONE}
                        name={USER_PROFILE_FIELDS.PHONE}
                        type={FIELD_TYPES.PHONE_NUMBER}
                        label="Phone Number"
                        required={true}
                        valid={true}
                      />

                      {/* need to check that user profile data has been returned first before evaluating if checkbox should be checked or not */}
                      {user?.profile?.firstName &&
                        <DynamicInputField
                          id="mobileNumber"
                          name="mobileNumber"
                          type={FIELD_TYPES.BOOL}
                          label="Is this a mobile number? (check if yes)"
                          focus={true}
                          hideOptional={true}
                          checked={user.profile.isMobilePhone}
                        />
                      }

                    </div>

                    <ActionRow>
                      <ActionRow.Forward>
                        <Action
                          title={COMMON.SAVE}
                          displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                          type="submit"
                          loading={isSubmitting}
                          disabled={!valid}
                        />
                      </ActionRow.Forward>
                    </ActionRow>
                  </PageBlock.Body>
                </PageBlock>
              </FormBody>
            </GenericForm>
          </FlowScreen>
        }

      </LayoutWithNav.Content>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage}
      />
    </LayoutWithNav>
  );
}

const form = reduxForm({
  form: FORM_NAMES.ACCOUNT.ACCOUNT_DETAILS
})(PersonalDetails);

export default form;

