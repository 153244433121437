export function AddErrors(errors) {
  return (dispatch) => {
    dispatch({
      type: "ADD_ERRORS",
      errors,
    });
  };
}

export function ClearErrors() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_ERRORS",
      errors: null,
    });
  };
}
