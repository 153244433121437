import { ROUTES } from "../constants/api";
import {
  HandleErrorResponse,
  HandleResponse,
  HandleNewErrorResponse,
  HandleNewResponse,
} from "./handlers";
import { deleteMethod, get, post, put } from "./api";
import { AddRecipientRequest, CardToCardTransferRequest } from "../types/api/card";
import FileSaver from "file-saver";
import axios from "axios";
import AuthApi from "./auth";
import { TRANSFER_METHODS } from "../constants/enums";

/**
 * Class for static methods handling a user's card(s)
 */
class CardApi {
  /**
   * Get's the user's list of available cards.
   * @returns {Promise<void>} card data
   * @constructor
   */
  static async GetCards() {
    let url = ROUTES.CARD.CARDS;

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Get's the user's transaction history
   * @param cardId
   * @returns {Promise<void>} transaction history data
   * @constructor
   */
  static async GetCardTransactions(cardId, skip?: number, take?: number) {
    let url = ROUTES.CARD.TRANSACTION_HISTORY(cardId, skip, take);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Verify the user's current PIN.
   * Used in the PIN reset workflow
   * @param pin
   * @param cardId
   * @constructor
   */
  static async VerifyCurrentPIN(pin, cardId) {
    let url = ROUTES.CARD.VERIFY_CURRENT_PIN(cardId);
    try {
      let response = await post(url, {
        pin,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async GetPINResetWorkflow() {
    let url = ROUTES.CARD.GET_PIN_RESET_WORKFLOW;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async ValidateForgotPINWorkflow(fields, cardId) {
    let url = ROUTES.CARD.VALIDATE_PIN_WORKFLOW(cardId);
    try {
      let response = await post(url, {
        fields,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  static async ChangepIN(pin, cardId, token) {
    let url = ROUTES.CARD.CHANGE_PIN(cardId);
    try {
      let response = await put(url, {
        pin: pin,
        token: token,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets all available mastercard currencies
   */
  static async GetMastercardCurrencies() {
    let url = ROUTES.CARD.MASTERCARD_CURRENCIES;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Calculates the rate summary based on the given inputs.
   * @param {string} transactionDate - the date in string format (i.e 2020-06-05T00:00:00Z).
   * @param {*} amount - the amount
   * @param {*} currency - the destination/target currency.
   */
  static async GetMastercardRateSummary(transactionDate, amount, currency) {
    let url = ROUTES.CARD.MASTERCARD_RATE_SUMMARY;
    try {
      let response = await post(url, {
        transactionDate,
        amount,
        currency,
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Lock user's card
   * @param {string} card - the user's card data from redux
   */
  static async LockCard(card) {
    let url = ROUTES.CARD.LOCK_CARD;
    try {
      let response = await post(url, {
        cardid: card.id
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Unlocks user's card
   * @param {string} card - the user's card data from redux
   */
  static async UnlockCard(card) {
    let url = ROUTES.CARD.UNLOCK_CARD;
    try {
      let response = await post(url, {
        cardid: card.id
      });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets Faqs for Card Lock page
   */
  static async GetCardLockFAQs() {
    let url = ROUTES.CARD.CARD_LOCK_FAQS;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets ODT and Processor availability from the API
   */
  static async GetODTAndProcessorAvailability() {
    let url = ROUTES.CARD.PROCESSOR_AVAILABILITY;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Activates or Enrolls replacement card
   */
  static async ActivateOrEnrollCard(data) {
    let url = ROUTES.CARD.ACTIVATE_REPLACEMENT_CARD;
    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets user's western union documents, if any, for /MyCard/WesternUnion page
  */
  static async GetWesternUnionDocs() {
    let url = ROUTES.CARD.GET_WESTERN_UNION_DOCS;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets user's redirect url for western union site
  */
  static async GetRedirectURL() {
    let url = ROUTES.CARD.GET_REDIRECT_URL;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets companion card recipients for user
   */
  static async GetCardToCardRecipients() {
    let url = ROUTES.CARD.GET_RECIPIENTS;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Send card to card transfer to recipient
   */
  static async CardToCardTransfer(data: CardToCardTransferRequest, cardId: number) {
    let url = ROUTES.CARD.CARD_TO_CARD_TRANSFER(cardId);
    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Add card to card transfer recipient
   */
  static async AddCardToCardRecipient(data: AddRecipientRequest) {
    let url = ROUTES.CARD.ADD_RECIPIENT;
    try {
      let response = await post(url, data);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Delete card to card transfer recipient
   */
  static async DeleteCardToCardRecipient(recipientId: string) {
    let url = ROUTES.CARD.DELETE_RECIPIENT(recipientId);
    try {
      let response = await deleteMethod(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get companion card page info
   */
  static async GetCompanionCard() {
    let url = ROUTES.CARD.COMPANION_CARD;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * POST companion card form data
   */
  static async PostCompanionCard(formData: FormData) {
    let url = ROUTES.CARD.COMPANION_CARD;
    try {
      let response = await post(url, formData, {
        "Content-Type": "multipart/form-data",
      });

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * GET Push to card transfer details
   */
  static async GetRRSDKTransferDetails(transferId: string, transferMethod: string) {
    let url = ROUTES.CARD.GET_RR_SDK_TRANSFER_DETAILS(transferId, transferMethod);
    try {
      let response = await get(url);

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * GET Push to card transfer receipt
   */
  static async GetRRSDKTransferReceipt(url: string, transactionNumber: string, transferMethod: string) {
    const filename = transferMethod === TRANSFER_METHODS.PUSH_TO_CARD ? `Brightwell_Send_To_Debit_Card_${transactionNumber}.pdf` : `Send_With_Brightwell_${transactionNumber}.pdf`;

    try {
      const response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
        responseType: "blob",
      });

      await FileSaver.saveAs(new Blob([response.data]), filename);

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get card image corresponding to user's card
   */
  static async GetCardImage() {
    let url = ROUTES.CARD.GET_CARD_IMAGE;
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get user's card statements
   */
  static async GetCardStatements(cardId: string) {
    let url = ROUTES.CARD.GET_CARD_STATEMENTS(cardId);
    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Get user's card statements
   */
  static async GetCardStatementPdf(cardId: string, statementId: string) {
    let url = ROUTES.CARD.GET_CARD_STATEMENT_PDF(cardId, statementId);

    const filename = `CardStatement.pdf`;
    try {
      const response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
        responseType: "blob",
      });

      await FileSaver.saveAs(new Blob([response.data]), filename);

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }
}

export default CardApi;
