import React, { Component } from "react";
import BaseInput from "./BaseInput";
import { ICON_SVG } from "../../../constants/iconsvg";

export default class PasswordInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
    };
  }

  togglePasswordVisibility = () => {
    const { passwordVisible } = this.state;
    this.setState({
      passwordVisible: !passwordVisible,
    });
  };

  render() {
    const { passwordVisible } = this.state;
    return (
      <div className="password-input">
        <BaseInput
          type={passwordVisible ? "text" : "password"}
          {...this.props}
          required
        />
        <button
          type="button"
          className="password-display-toggle"
          onClick={this.togglePasswordVisibility}
        >
          {passwordVisible && ICON_SVG.FULL_EYE}
          {!passwordVisible && ICON_SVG.CROSSED_EYE}
        </button>
      </div>
    );
  }
}
