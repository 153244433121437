import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import { DisbursementHistoryItem } from "../../types/api/bankAccounts";

const DisbursementHistoryTable = () => {
  const { disbursementHistory } = useSelector((state: RootState) => state.bankAccounts);

  return (
    <>
      <p>{disbursementHistory.bankAccountName} {disbursementHistory.bankAccountNumber}</p>

      <table className="navigatorTable">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>FX Rate</th>
            <th>Amount</th>
            <th>Disbursement ID</th>
            <th>Type</th>
          </tr>
        </thead>

        <tbody>
          {disbursementHistory.disbursementHistory.map((item: DisbursementHistoryItem) => (
            <tr key={item.disbursementId}>
              <td>{new Date(item.dateTime).toLocaleDateString(navigator.language, {
                day: "2-digit",
                month: "short",
                year: "numeric"
              }).replace(/ /g, "-").replace(",", "")}</td>
              <td>{item.description}</td>
              <td>{item.fxRate}</td>
              <td>{item.amountString}</td>
              <td>{item.disbursementId}</td>
              <td>{item.allocationType}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export default DisbursementHistoryTable;