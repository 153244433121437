import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FilteredDropDown from "./FilteredDropDown";
import { SELECTION_TYPES } from "../../constants/enums";

/**
 * Provides a select input field that allows filtering and recently selected options
 */
export class CountryPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryOptions: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.countries != prevProps.countries) {
      this.updateCountriesList();
    }
  }

  componentDidMount() {
    this.updateCountriesList();
  }

  updateCountriesList() {
    const { countries } = this.props;

    let countryOptions = countries.map((c) => {
      return { label: c.name, value: c.iso };
    });

    this.setState({
      countryOptions,
    });
  }

  render() {
    const { props } = this;

    return (
      <FilteredDropDown
        fullOptions={this.state.countryOptions}
        recentOptions={this.props.recentOptions}
        {...props}
      />
    );
  }
}

CountryPicker.defaultProps = {
  countries: [],
};

CountryPicker.propTypes = {
  /** List of country options */
  countries: PropTypes.array.isRequired,
  /** Should the countries be limited to available countries */
  recentOptions: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    recentOptions: state.userSelections[SELECTION_TYPES.COUNTRIES],
  };
}

export default connect(mapStateToProps, {})(CountryPicker);
