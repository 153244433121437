export const ERROR_CODES = {
  PIN: {
    INVALID: "CD002",
    INFORMATION_MATCH: "CD003",
    TOO_MANY_ATTEMPTS: "CD004",
    USING_OLD_PIN: "PV008",
    INVALID_TOKEN: "PV006",
  },
  PASSWORD_RESET: {
    DUPLICATE_USERNAME: "PR019",
    INVALID_CURRENT_PASSWORD: "PR020",
  },
  USER: {
    INVALID_USER_OR_PASSWORD: "US009",
    ACCOUNT_LOCKED: "US013",
    REQUIRES_MORE_INFO: "SU100",
  },
  FACECHECK: {
    GENERIC_SDK_ERROR: "FC999",
    FACE_MISMATCH: "US025",
    ENROLL_FAILED: "US027",
    IN_REVIEW: "US028",
    PROFILE_NOT_FOUND: "US030",
    PROFILE_EXISTS: "US031",
    TOO_MANY_ATTEMPTS: "US034",
  },
  COMMON: {
    UNEXPECTED: "EX999",
    TIMEOUT: "TO408",
  },
  INITIAL_API_CALLS: {
    GET_USER_CARDS: "AP001",
    GET_USER_PROFILE: "AP002",
    GET_FEATURE_FLAGS: "AP003",
    GET_USER_NEWS: "AP004",
    GET_NAV_BAR: "AP005",
  }
};
