import * as types from "../constants/actionTypes";
import EnrollmentApi from "../api/enrollment";

/**
 * GET user enrollment data from API
 * @returns {Function}
 * @constructor
 */
export function GetEnrollmentData(participantId) {
  return async function (dispatch) {
    let userData = await EnrollmentApi.GetEnrollmentData(participantId);

    dispatch({
      type: types.ENROLLMENT.UPDATE_ENROLLMENT_DATA,
      userData
    });
  };
}

export function SetEnrollmentData(userData) {
  return (dispatch) => {
    dispatch({
      type: types.ENROLLMENT.UPDATE_ENROLLMENT_DATA,
      userData
    })
  };
}

export function UpdateCurrentPage(newPage) {
  return (dispatch) => {
    dispatch({
      type: types.ENROLLMENT.UPDATE_CURRENT_PAGE,
      newPage
    })
  };
}