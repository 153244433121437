import * as types from "../constants/actionTypes";
import initialState from "./initialState";

export default function user(state = initialState.user, action) {
  switch (action.type) {
    // case types.USER.RECEIVE_USER:
    //   return Object.assign({}, state, action.user);
    case types.RECEIVE_TOKEN:
      return Object.assign({}, state, {
        ...state,
        isAuthenticated: true,
      });
    case types.AUTH.LOGOUT:
      return Object.assign({}, initialState.user);
    case types.USER.GET_USER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        profile: action.profile,
      });
    case types.USER.PUT_USER_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        profile: action.profile,
      });
    case types.USER.PUT_USER_PROFILE_FAILURE:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          errors: action.errors,
        },
      });
    case types.USER.PUT_USER_PROFILE_INVALID:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          validationErrors: action.validationErrors,
        },
      });
    case types.SUPPORT.GET_INDEX_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        supportId: action.index.requesterId,
      });
    case types.USER.GET_FEATURE_FLAGS_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        featureFlags: action.featureFlags,
      });
    case types.USER.PIN_CHECK_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        pinCheck: {
          success: true,
        },
      });
    case types.USER.PIN_CHECK_FAILURE:
      return Object.assign({}, state, {
        ...state,
        pinCheck: {
          success: false,
          tooManyAttempts: action.isLockedOut,
        },
      });
    case types.USER.CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        changePasswordResponse: {
          success: true,
          title: action.response.title,
          message: action.response.message,
        },
      });
    case types.USER.CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        ...state,
        changePasswordResponse: {
          success: false,
          error: action.error,
        },
      });
    case types.USER.ACCOUNT_LOCKED:
      return Object.assign({}, state, {
        ...state,
        isAccountLocked: action.isAccountLocked,
      });
    case types.USER.GET_NEWS:
      return Object.assign({}, state, {
        ...state,
        news: action.news,
      });
    default:
      return state;
  }
}
