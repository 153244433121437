import React from "react";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import PropTypes from "prop-types";

const FlowBackToReviewAndAcceptButton = (props) => {
  return (
    <Action
      iconRight={null}
      title={props.title}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      className="btn-lg flowbutton__return-reviewdocs"
      onClick={() => (location.href = ROUTES.EXTERNAL.REVIEW_DOCS)}
      {...props}
    />
  );
};

FlowBackToReviewAndAcceptButton.propTypes = {
  title: PropTypes.string,
};

FlowBackToReviewAndAcceptButton.defaultProps = {
  title: COMMON.BACK_TO_DASHBOARD,
};

export default FlowBackToReviewAndAcceptButton;
