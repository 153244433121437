import React from 'react';
import { BankAccount } from '../../types/api/odt';
import InfoTip from '../Common/InfoTip';

const BankAccountToolTip = ({ bank, handleInfoTipModal }: { bank: BankAccount, handleInfoTipModal: (title: string, content: string) => void }) => {
  return (
    <div className="info-tip-container">
      <p>
        {bank.moreInfo.label}
      </p>
      <InfoTip
        onClick={() => handleInfoTipModal(bank.moreInfo.title, bank.moreInfo.message)}
      />
    </div>
  );
}

export default BankAccountToolTip;
