import decode from "jwt-decode";
import CryptoJS from "crypto-js";
import { ENROLLMENT } from "../constants/enrollment";
import "url-search-params-polyfill";

const LAST_PAGE = 0;

export function enrollLogout() {
  clearAccessToken();
  clearUserData();
}

export function getAccessToken() {
  return localStorage.getItem(ENROLLMENT.ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  localStorage.removeItem(ENROLLMENT.ACCESS_TOKEN_KEY);
}

function clearUserData() {
  localStorage.removeItem(ENROLLMENT.USER_DATA_KEY);
}

// Helper function that will allow us to extract the access_token and id_token
function getParameterByName(name) {
  let match = RegExp("[#&]" + name + "=([^&]*)").exec(window.location.hash);
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

// Get and store access_token in local storage
export function setAccessToken() {
  let accessToken = getParameterByName("access_token");
  localStorage.setItem(ENROLLMENT.ACCESS_TOKEN_KEY, accessToken);
}

export function setLastPage(pageNumber) {
  localStorage.setItem(LAST_PAGE, pageNumber);
}

export function getLastPage() {
  return localStorage.getItem(LAST_PAGE);
}

export function setParticipantId(userData) {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(userData.participantId),
    ENROLLMENT.CYPHER
  );

  localStorage.setItem(ENROLLMENT.USER_DATA_KEY, ciphertext.toString());
}

export function getUserData() {
  let userData = localStorage.getItem(ENROLLMENT.USER_DATA_KEY);
  if (userData) {
    const bytes = CryptoJS.AES.decrypt(userData.toString(), ENROLLMENT.CYPHER);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(plaintext);
  }
  return null;
}

export function setAccessTokenString(accessToken) {
  localStorage.setItem(ENROLLMENT.ACCESS_TOKEN_KEY, accessToken);
}

export function setAccessTokens(data) {
  localStorage.setItem(ENROLLMENT.ACCESS_TOKEN_KEY, data.access_token);
  localStorage.setItem(ENROLLMENT.REFRESH_TOKEN_KEY, data.refresh_token);
}

export function isLoggedIn() {
  const accessToken = localStorage.getItem(ENROLLMENT.ACCESS_TOKEN_KEY);
  return !!accessToken && !isTokenExpired(accessToken);
}

function getTokenExpirationDate(encodedToken) {
  const token = decode(encodedToken);
  if (!token.exp) {
    return null;
  }

  const date = new Date(0);
  date.setUTCSeconds(token.exp);

  return date;
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate < new Date();
}
