import { USER, CARD } from "../constants/actionTypes";
import UserApi from "../api/user";
import CardApi from "../api/card";
import DashboardApi from "../api/dashboard";
import { trackEvent } from "../services/eventTracker";
import { EVENTS } from "../constants/events";
import { HTTP_STATUS_CODES } from "../constants/api";

export function UpdateUserProfile(payload) {
  return {
    type: USER.GET_USER_PROFILE_SUCCESS,
    profile: payload,
  };
}

/**
 * Get's the user's profile and sets it to state.
 * @returns {Function}
 * @constructor
 */
export function GetUserProfile() {
  return async function (dispatch) {
    let response = await UserApi.GetUserProfile();
    dispatch(UpdateUserProfile(response.userProfile));
  };
}

/**
 * Post's the user's profile and sets it to state.
 * @returns {object} user profile
 */
export function PutUserProfile(userData) {
  return async (dispatch) => {
    try {
      let response = await UserApi.PutUserProfile(userData);

      return dispatch({
        type: USER.PUT_USER_PROFILE_SUCCESS,
        profile: response.userProfile,
      });
    } catch (error) {
      if (error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
        throw error.data.errors;
      } else {
        return dispatch({
          type: USER.PUT_USER_PROFILE_FAILURE,
          errors: error.data.errors,
        });
      }
    }
  };
}

export function UpdateFeatureFlags(payload) {
  return {
    type: USER.GET_FEATURE_FLAGS_SUCCESS,
    featureFlags: payload,
  };
}

/**
 * Get's all available feature flags
 * @returns {Function}
 * @constructor
 */
export function GetFeatureFlags() {
  return async (dispatch) => {
    let response = await UserApi.GetFeatureFlags();
    dispatch(UpdateFeatureFlags(response.featureFlags));
  };
}

/**
 * Saves pin check response
 * @returns {Function}
 * @constructor
 */
export function PinCheck(pin, cardId) {
  return async (dispatch) => {
    let response = await UserApi.PinCheck(pin, cardId);

    if (response.isValid) {
      trackEvent(EVENTS.MONEY_TRANSFER.PIN_ID, {
        success: true,
      })

      dispatch({
        type: USER.PIN_CHECK_SUCCESS,
      });
    } else if (response.isLockedOut) {
      trackEvent(EVENTS.MONEY_TRANSFER.PIN_ID, {
        success: false,
        failure_reason: EVENTS.PARAM_VALUES.USER_LOCKED_OUT
      })

      dispatch({
        type: USER.PIN_CHECK_FAILURE,
        isLockedOut: true,
      });

      let response = await CardApi.GetCards();
      dispatch({
        type: CARD.RECEIVE_USER_CARDS_SUCCESS,
        cards: response,
      });
    } else {
      trackEvent(EVENTS.MONEY_TRANSFER.PIN_ID, {
        success: false,
        failure_reason: EVENTS.PARAM_VALUES.INCORRECT_PIN
      })

      dispatch({ type: USER.PIN_CHECK_FAILURE });
    }
  };
}

/**
 * Attempts to update the user's password at the server.
 */
export function ResetUserPassword(userId, token, password) {
  return async (dispatch) => {
    try {
      let response = await UserApi.ResetUserPassword({
        userId,
        token,
        password,
      });
      dispatch({
        type: USER.CHANGE_PASSWORD_SUCCESS,
        response: response,
      });
    } catch (error) {
      dispatch({
        type: USER.CHANGE_PASSWORD_FAILURE,
        error: error,
      });
    }
  };
}

/**
 * Attempts to update the user's password at the server
 * for an authenticated user.
 */
export function ChangeUserPassword(token, currentPassword, newPassword) {
  return async (dispatch) => {
    try {
      let response = await UserApi.ChangeUserPassword({
        token,
        currentPassword,
        newPassword,
      });
      dispatch({
        type: USER.CHANGE_PASSWORD_SUCCESS,
        response: response,
      });
    } catch (error) {
      dispatch({
        type: USER.CHANGE_PASSWORD_FAILURE,
        error: error,
      });
    }
  };
}

/**
 * Updates the state with the user's account lockout status.
 */
export function UpdateAccountLockoutStatus(isAccountLocked) {
  return async (dispatch) => {
    dispatch({
      type: USER.ACCOUNT_LOCKED,
      isAccountLocked: isAccountLocked,
    });
  };
}

/**
 * Updates the state with the user's news items.
 */
export function GetUserNews() {
  return async (dispatch) => {
    try {
      let response = await DashboardApi.GetNews();
      dispatch({
        type: USER.GET_NEWS,
        news: response.news,
      });
    } catch (err) {
      dispatch({
        type: USER.GET_NEWS,
        news: [],
      });
      throw err;
    }

  };
}
