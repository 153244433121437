import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../constants/localization";
import ActionRow from "../../components/Layouts/ActionRow";
import ActionLink from "../../components/Common/ActionLink";
import FlowNextButton from "../../components/Buttons/FlowNextButton";
import { FORGOT_PASSWORD_STEPS } from "../../constants/enums";

class PasswordResetOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetOption: null,
    };
  }
  /**
   * Set the reset option selected on the Password
   * Reset Option screen
   * @param {object} e - The event (submission of form in this case)
   */
  updateResetOption = (e) => {
    this.setState({
      resetOption: e.target.value,
    });
  };

  setResetOption = (e) => {
    e.preventDefault();
    this.props.changeFlowStep(this.state.resetOption);
  };

  render() {
    const { props } = this;

    return (
      <PageBlock id="PasswordResetOptions">
        <PageBlock.Title>
          {PAGES.FORGOT_PASSWORD.RESET_PASSWORD_OPTIONS.PAGE_BLOCK_TITLE}
        </PageBlock.Title>
        <PageBlock.IntroText>
          {PAGES.FORGOT_PASSWORD.RESET_PASSWORD_OPTIONS.PAGE_BLOCK_TEXT}
        </PageBlock.IntroText>
        <PageBlock.Body>
          <form id="PasswordResetOptionsForm" onSubmit={this.setResetOption}>
            <div className="radio-btns">
              <label htmlFor="ResetOptionOne">
                <input
                  type="radio"
                  id="ResetOptionOne"
                  data-cy="reset-option-one"
                  value={FORGOT_PASSWORD_STEPS.PERSONAL_INFO_ENTRY}
                  name="RESET_OPTION"
                  onChange={this.updateResetOption}
                />
                <span className="radio__label">
                  {
                    PAGES.FORGOT_PASSWORD.RESET_PASSWORD_OPTIONS
                      .OPTION_ONE_LABEL
                  }
                </span>
              </label>
              <label htmlFor="ResetOptionTwo">
                <input
                  type="radio"
                  id="ResetOptionTwo"
                  data-cy="reset-option-two"
                  value={FORGOT_PASSWORD_STEPS.EMAIL_ENTRY}
                  name="RESET_OPTION"
                  onChange={this.updateResetOption}
                />
                <span className="radio__label">
                  {
                    PAGES.FORGOT_PASSWORD.RESET_PASSWORD_OPTIONS
                      .OPTION_TWO_LABEL
                  }
                </span>
              </label>
            </div>
            <ActionRow>
              <ActionRow.Forward>
                <FlowNextButton
                  dataCy="submit-reset-option-button"
                  disabled={!this.state.resetOption}
                />
              </ActionRow.Forward>
              <ActionRow.Backward>
                <ActionLink
                  clickFunc={props.goBack}
                  dataCy="back-button"
                  text={COMMON.BACK}
                />
              </ActionRow.Backward>
            </ActionRow>
          </form>
        </PageBlock.Body>
      </PageBlock>
    );
  }
}

PasswordResetOptions.propTypes = {
  updateResetOption: PropTypes.func,
  nextResetOptionPath: PropTypes.func,
  resetOption: PropTypes.string,
  goBack: PropTypes.func,
  changeFlowStep: PropTypes.func,
};

export default PasswordResetOptions;
