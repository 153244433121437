import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import { FEATURE_FLAGS } from "../../constants/featureFlags";
import { PAGES, PAGE_NAV_LINKS } from "../../constants/localization";
import { ICONS } from "../../constants/enums";
import ENV from '../../constants/environment';
import ExternalLink from "../../assets/images/icons/ExternalLink.svg";

import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import Alert from "../../components/Common/Alerts/Alert";
import { SupportDocType } from "../../types/api/card";
import CardApi from "../../api/card";
import ActionLink from "../../components/Common/ActionLink";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import i18n from "../../i18n/config";

/**
 * Main container for the WesternUnion page
 */
const WesternUnion = () => {
  const openExternalLinksModal = useClickExternalLink();
  const featureFlags = useSelector((state: RootState) => state.user.featureFlags);
  const [westernUnionDocs, setWesternUnionDocs] = useState<SupportDocType[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getDocs = async () => {
      try {
        const response = await CardApi.GetWesternUnionDocs() as SupportDocType[];
        setWesternUnionDocs(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }

    getDocs();
  }, []);

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        <FlowScreen
          flowTitle={PAGES.MY_CARD.WESTERN_UNION.TITLE}
          className="western-union-page"
        >
          {featureFlags.includes(FEATURE_FLAGS.HAS_WESTERN_UNION_PROVIDER) &&
            <Alert
              alertClasses="alert-info flex-row align-items-start"
              icon={ICONS.INFO}
              iconClasses={"spacing-right-tiny"}
            >
              <p className="bold">New feature on mobile app</p>
              <p className="spacing-bottom-none">Now you can do Cash Pickup with Western Union from your Brightwell Navigator mobile app.</p>
              <p className="bold">Update your app to see the new feature.</p>
            </Alert>
          }

          <PageBlock>
            <PageBlock.Loader loadingContent={loading}>
              <InputSkeletonGroup numInputs={4} />
            </PageBlock.Loader>
            <PageBlock.Body loadingContent={loading}>

              <div className="flex-row align-items-center mobile-direction-column">
                <div className="col-flex-1">
                  <h3 className="bold">Send Money to a Bank</h3>
                  <p>
                    With Western Union, you can send money from your Card to a bank account in over 200 countries.
                  </p>
                </div>

                <div className="col-flex-1 spacing-left-medium mobile-margin-none">
                  <ActionLink text="Go to Western Union website" classes="btn btn-primary" href={`${ENV.BASE_NAVIGATOR_URL}/MyCard/SendToWesternUnion`}>
                    <img src={ExternalLink} className={"relative spacing-left-tiny"} />
                  </ActionLink>
                </div>
              </div>

              {featureFlags.includes(FEATURE_FLAGS.HAS_WESTERN_UNION_PROVIDER) &&
                <div className="flex-row align-items-center mobile-direction-column spacing-top-medium">
                  <div className="col-flex-1">
                    <h3 className="bold">Cash Pickup</h3>
                    <p>
                      Complete a Cash Pickup with Western Union
                    </p>
                  </div>

                  <div className="col-flex-1 spacing-left-medium mobile-margin-none">
                    <ActionLink
                      classes={'btn btn-primary'}
                      href={`${ENV.BASE_NAVIGATOR_URL}/MyCard/MoneyTransfer`}
                      onClick={() => console.log("click")}
                      text={"Go to Cash Pickup"}
                    />
                  </div>
                </div>
              }

              <Alert
                alertClasses="alert-info alert-bkgrd-white flex-row align-items-start spacing-top-medium"
                icon={ICONS.INFO}
                iconClasses={"spacing-right-tiny"}
              >
                <p className="bold">First-time user?</p>
                <p>
                  As a first-time user, you must register with Western Union before sending money with your Card.
                  Once you complete registration, you simply need your Username and Password to login and send money.
                </p>
              </Alert>

              {westernUnionDocs?.length > 0 &&
                <div>
                  <h3 className="bold">Need help?</h3>
                  <p>Reference the documents below to get started.</p>
                  {westernUnionDocs.map(doc => {
                    const url = `${ENV.BASE_NAVIGATOR_URL}/Administration/GetFile/${doc.urlId}`;
                    return (
                      <p key={doc.typeId}>
                        <a href={url} target="_blank" rel="noopener noreferrer" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} onClick={(e) => openExternalLinksModal(e, url)}>{doc.name}</a>
                      </p>
                    )
                  })}
                </div>
              }
            </PageBlock.Body>
          </PageBlock>

          <p>*Fees may apply. Refer to the fee schedule included in your Cardholder Agreement.</p>
        </FlowScreen>
      </LayoutWithNav.Content>
    </LayoutWithNav>
  );
}

export default WesternUnion;