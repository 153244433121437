import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/config';
import { BankAccount, FeeBanner, GetBankAccountsResponse } from '../../types/api/odt';
import { ODTContainerProps } from '../../types/props';
import { useDispatch } from 'react-redux';
import { UpdateODTTermsDocAndFeeBanner } from '../../actions/onDemandTransfer';

import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import BankAccountCard from '../../components/ODT/BankAccountCard';
import Modal from '../../components/Common/Modal';
import { ACTION_DISPLAY_TYPES, BANK_ACCOUNT_STATUS, ICONS } from '../../constants/enums';
import { ROUTES } from '../../constants/clientRoutes';
import OnDemandTransferApi from '../../api/onDemandTransfer';
import Alert from '../../components/Common/Alerts/Alert';

const BankAccountSelection = ({ props }: { props: ODTContainerProps }) => {
  const dispatch = useDispatch();
  const [availableBanks, setAvailableBanks] = useState<BankAccount[]>();
  const [unavailableBanks, setUnavailableBanks] = useState<BankAccount[]>();
  const [feeBanner, setFeeBanner] = useState<FeeBanner>();
  const [loading, setLoading] = useState(true);
  const [showInfoTipModal, setShowInfoTipModal] = useState(false);
  const [infoTipModal, setInfoTipModal] = useState<{ title: string, content: string }>({ title: "", content: "" });

  useEffect(() => {
    // Set title and buttons
    const { setTitle, setForwardButton, setErrorMessage, setShowErrorModal } = props;
    setTitle(i18n.t("PAGES.ODT.BANK_SELECTION.TITLE"));
    setForwardButton({
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      title: i18n.t("COMMON.ADD_BANK_ACCOUNT"),
      onClick: () => location.href = ROUTES.EXTERNAL.ADD_BANK
    });

    const getBanks = async () => {
      try {
        const response = await OnDemandTransferApi.GetBankAccounts() as GetBankAccountsResponse;

        setAvailableBanks(response.bankAccounts.filter(bank => bank.bankAccountStatus === BANK_ACCOUNT_STATUS.AVAILABLE));
        setUnavailableBanks(response.bankAccounts.filter(bank => bank.bankAccountStatus !== BANK_ACCOUNT_STATUS.AVAILABLE));
        const payload = {
          odtTermsAndConditionsDocId: String(response.odtTermsAndConditionsDocId),
          feeBanner: response.feeBanner
        };
        dispatch(UpdateODTTermsDocAndFeeBanner(payload));
        setFeeBanner(response.feeBanner);
        setLoading(false);
      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }
        setLoading(false);
        setShowErrorModal(true);
      }
    };

    getBanks();

  }, []);

  const handleInfoTipModal = (title: string, content: string) => {
    setShowInfoTipModal(!showInfoTipModal);
    setInfoTipModal({ title, content });
  }

  return (
    <>

      {loading &&
        <InputSkeletonGroup numInputs={4} />
      }

      {!loading &&
        <>

          {feeBanner?.htmlFeeBanner &&
            <Alert
              alertClasses={"alert-info spacing-top-small flex-row font-regular"}
              icon={ICONS.INFO}
              iconClasses={"spacing-right-tiny"}
            >
              <p className="bold">{feeBanner.title}</p>
              <div dangerouslySetInnerHTML={{ __html: feeBanner.htmlFeeBanner }} />
            </Alert>
          }

          {/* Available banks */}
          <section>
            {availableBanks?.map((bank) => (
              <BankAccountCard key={bank.allocationId} bank={bank} />
            ))}
          </section>

          {/* Unavailable banks */}
          <section className="spacing-top-medium">
            <h2>{i18n.t("PAGES.ODT.BANK_SELECTION.UNAVAILABLE_HEADING")}</h2>
            {unavailableBanks?.map((bank) => (
              <BankAccountCard key={bank.allocationId} bank={bank} handleInfoTipModal={handleInfoTipModal} />
            ))}
          </section>

          <Modal
            onClose={() => setShowInfoTipModal(false)}
            open={showInfoTipModal}
            title={infoTipModal?.title}
            content={infoTipModal?.content}
          />
        </>
      }

    </>
  );
}

export default BankAccountSelection;