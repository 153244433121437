import React from "react";
import PropTypes from "prop-types";
import ErrorBlock from "../../Common/ErrorBlock";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockErrorBlock = (props) =>
  props.errors ? (
    <div
      className="flow-page-block__alert"
      data-cy={CyBuilder("page-block-alert", null)}
    >
      <ErrorBlock errors={props.errors} />
    </div>
  ) : null;

PageBlockErrorBlock.propTypes = {
  errors: PropTypes.array,
};

export default PageBlockErrorBlock;
