import * as React from 'react';
import { ICONS } from '../../constants/enums';
import i18n from '../../i18n/config';

import Alert from '../Common/Alerts/Alert';
import { trackEvent } from '../../services/eventTracker';
import { EVENTS } from '../../constants/events';
import { ROUTES } from '../../constants/clientRoutes';
import useClickExternalLink from '../../hooks/useClickExternalLink';

const CrewPageAlert = ({ className }: { className: string }) => {
  const openExternalLinksModal = useClickExternalLink();
  const url = "https://www.brightwell.com/crew?utm_source=navapp&utm_medium=hyperlink&utm_campaign=crewlink&utm_content=suppmain"

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const eventName = window.location.pathname.includes(ROUTES.SUPPORT_NEW) ? EVENTS.SUPPORT.CREW_LINK_SELECTED_MESSAGE_CENTER : EVENTS.SUPPORT.CREW_LINK_SELECTED;
    trackEvent(eventName);

    openExternalLinksModal(e, url);
  }

  return (
    <Alert
      alertClasses={`alert-info flex-row ${className}`}
      isHTML={true}
      icon={ICONS.INFO}
      iconClasses={"spacing-right-tiny"}
    >
      <p className="navy-blue bold">Find answers faster!</p>
      <p>Get help and answers without waiting. Visit navigator crew page: </p>
      <a
        href={url}
        onClick={(e) => handleClick(e)}
        target="_blank"
        rel="noopener noreferrer"
        title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
        className="bold no-underline"
      >
        Navigator Crew page
      </a>
    </Alert>
  );
}

export default CrewPageAlert;