import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector, change } from "redux-form";
import { COMMON, PAGES } from "../../constants/localization";
import CountryPicker from "../../components/Common/CountryPicker";
import CurrencyInput from "../../components/Common/CurrencyInput";
import enabledArrow from "../../assets/images/MiniArrow-enabled.svg";
import { FORM_NAMES } from "../../constants/forms";
import GeneralSelect from "../../components/Common/GeneralSelect";
import { GetAvailableCountries, GetAvailableProvinces, GetAvailableCities } from "../../actions/moneyTransfer";
import get from "lodash/get";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import Restrictions from "../../components/MoneyTransfer/Restrictions";

/**
required accepts a value and ensures that it's not undefined.
returns "Required" if value is not set
*/
const required = (value) => (value ? undefined : COMMON.REQUIRED);

/** 
  overZero returns zero if a person attempts to input a number less than zero.
  this is the preferred way to handle min/max with react/redux.
  TODO: Where do we enforce the min? 
@param value = number parameter from the number input
 */
const overZero = (value) => {
  if (value < 0) {
    return 0;
  } else {
    return value;
  }
};

/**
 * Determines if the given value exceeds the available balance of the user. Card balance comes from application state.
 * @param {any} value The send amount of the transaction.
 */
const balanceCheck = (value, cardBalance, sendCurrencyIso) => {
  return value > cardBalance
    ? PAGES.MONEY_TRANSFER.VALIDATIONS.EXCEEDED_BALANCE(
      cardBalance,
      sendCurrencyIso
    )
    : undefined;
};

/**
 * Determines if the given value exceeds the maximum send limit for money transfers.
 * @param {any} value The send amount of the transaction.
 */
const limitCheck = (value, sendLimit, sendCurrencyIso) => {
  return value > sendLimit
    ? PAGES.MONEY_TRANSFER.VALIDATIONS.EXCEEDED_SEND_LIMIT(
      sendLimit,
      sendCurrencyIso
    )
    : undefined;
};

/**
 * Used for validating the receive currency selected.
 * @param {any} val The selected three digit currency code.
 */
const currencyCheck = (val) => {
  return val && val != "-1" ? undefined : COMMON.REQUIRED;
};

const sendAmountValidation = (value, allValues, props) => {
  var { sendLimit, sendCurrencyIso, cardBalance } = props;
  return (
    balanceCheck(value, cardBalance, sendCurrencyIso) ||
    limitCheck(value, sendLimit, sendCurrencyIso)
  );
};

/**
 * Container to display the first screen of the money transfer flow.
 * This contains a country picker, send amount field, and a currency picker.
 */
export class CountryAmount extends Component {
  /**
   * Component constructor. Sets up state and binds methods.
   * @param {any} props Props object passed in from the parent component. Also includes mapped state.
   * @param {any} context TODO
   */
  constructor(props) {
    super(props);

    this.state = {
      posting:
        !this.props.availableCountries ||
        this.props.availableCountries.length === 0,
      availableCountries: [],
      availableProvinces: [],
      availableCities: [],
      receiveCurrencies: [],
      displayProvinces: false,
      displayCities: false,
      amountDisabled: true,
      gettingRates: false,
    };

    if (this.props.availableCountries.length === 0) {
      this.props.GetAvailableCountries();
    }
  }

  /**
   * Life cycle method that fires after the component is initialized but before it is rendered.
   * TODO: The logic has moved to componentDidMount. Remove this comment after code is validated as working.
   */
  UNSAFE_componentWillMount() {
    /*this.props.initialize({
      sendAmount: this.props.rateQuery.sendAmount,
      destinationCountry: this.props.rateQuery.destinationCountry,
      receiveCurrency: this.props.rateQuery.receiveCurrency
    })*/
  }

  /**
   * Life cycle method that fires after the component is rendered.
   */
  componentDidMount() {
    this.props.initialize({
      sendAmount: this.props.rateQuery.sendAmount,
      destinationCountry: this.props.rateQuery.destinationCountry,
      receiveCurrency: this.props.rateQuery.receiveCurrency,
    });
    // if we have rateQuery, user has clicked back from next page
    if (this.props.rateQuery.destinationCountry) {
      const { rateQuery } = this.props;
      const country = this.getCountryData(rateQuery.destinationCountry);
      this.populateCurrencies(country);
      // if country has province requirement, show province dropdown and populate with user's previously selected province
      if (rateQuery.destinationProvince) {
        this.setState({ displayProvinces: true });
        this.props.change("destinationProvince", rateQuery.destinationProvince);

        if (!country.requiresCity) {
          this.setState({ amountDisabled: false });
        }
      } else {
        // If country does not need province, then enable buttons
        this.setState({ amountDisabled: false })
      }

      // if country has city requirement, show city dropdown and populate with user's previously selected city
      if (rateQuery.destinationCity) {
        this.setState({ displayCities: true, amountDisabled: false });
        this.props.change("destinationCity", rateQuery.destinationCity);
      }
    }
  }

  /**
   * Life cycle method that fires after the component's state or props update.
   * @param {any} prevProps The props object before the change
   * @param {any} prevState The state before the change
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.availableCountries.length !==
      prevProps.availableCountries.length &&
      this.props.availableCountries.length > 0
    ) {
      this.setState({ posting: false });
    }
  }

  /**
   * Given a country code, get available currencies and populate the collection. This collection is used to fill the dropdown.
   * @param {object} country: country data 
   */
  populateCurrencies(country) {
    let currencies = [];
    if (country) {
      currencies = country.currencies;

      var currencyCode = country.currencies[0].iso;
      this.props.change("receiveCurrency", currencyCode);
    }

    this.setState({
      receiveCurrencies: currencies,
    });
  }

  /**
   * Given a country code, if province required, get available provinces and populate the collection. This collection is used to fill the dropdown.
   * @param {any} country: country data
   */
  populateProvinces(country) {
    if (country && country.requiresProvince) {
      this.setState({ displayProvinces: true })
      this.props.GetAvailableProvinces(country.iso);
    } else {
      this.setState({ displayProvinces: false })
    }
  }

  /**
   * Handles country dropdown change handler
   * @param {string} countryCode
   */
  handleCountryChange(countryCode) {
    const country = this.getCountryData(countryCode);
    this.populateCurrencies(country);

    // if there is a country
    if (country) {
      // set destinationProvince and destinationCity values to null as country has changed
      this.props.change("destinationProvince", null);
      this.props.change("destinationCity", null);

      // if country requiresProvince then get provinces, disable buttons, display province dropdown, and hide city dropdown
      if (country.requiresProvince) {
        this.populateProvinces(country);
        this.setState({ amountDisabled: true, displayProvince: true, displayCities: false });
      } else {
        // if not, enable buttons and hide province and city dropdowns
        this.setState({ amountDisabled: false, displayProvinces: false, displayCities: false });
      }
    } else {
      // if country code is null, set destinationProvince and destinationCity values to null and hide dropdowns
      // Set receiveCurrency to null so that this.props.valid is false and 'Get rates' button is disabled
      this.props.change("destinationProvince", null);
      this.props.change("destinationCity", null);
      this.props.change("receiveCurrency", null);
      this.setState({ amountDisabled: true, displayProvinces: false, displayCities: false });
    }
  }

  /**
   * Handles province dropdown change handler
   * takes in province code and handles if country requires city, gets city data for province, and sets requiresCity: true
   * @param {string} provinceCode: Province code
   */
  handleProvinceChange(provinceCode) {
    const country = this.getCountryData(this.props.destinationCountry);
    if (country.requiresCity) {
      // if provinceCode is null (user has clicked the x) then hide city dropdown and disable buttons
      if (!provinceCode) {
        this.setState({ displayCities: false, amountDisabled: true });
      } else {
        this.props.GetAvailableCities(country.iso, provinceCode);
        this.props.change("destinationCity", null);
        this.setState({ displayCities: true, amountDisabled: true });
      }
    } else {
      // if country doesn't require city, but provinceCode is null, disable buttons
      if (!provinceCode) {
        this.setState({ amountDisabled: true });
      } else {
        this.setState({ amountDisabled: false });
      }

    }
  }

  /**
   * Handles city dropdown change handler
   * @param {string} cityCode
   */
  handleCityChange(cityCode) {
    // if cityCode is null (user has clicked the x) then disable buttons
    if (!cityCode) {
      this.setState({ amountDisabled: true });
    } else {
      this.setState({ amountDisabled: false });
    }
  }

  /**
   * Returns country data from availableCountries list by country code
   * @param {string} countryCode
   */
  getCountryData(countryCode) {
    return this.props.availableCountries.filter(
      (c) => c.iso === countryCode
    )[0];
  }

  /**
   * Display passport errors
   * Can be expanded to handle all errors (non card specific)
   * @param {*} errors
   */
  renderPassportError() {
    return (
      <div className="help-block">
        <div className="validation-error">
          {PAGES.MONEY_TRANSFER.RATE_LIST.INVALID_PASSPORT}
        </div>
      </div>
    );
  }

  /**
   * Display errors
   * @param {*} errors
   */
  renderError(error) {
    return <div className="validation-error">{error}</div>;
  }

  /**
   * Life cycle method that renders the component. This fires anytime the state or props change.
   */
  render() {
    const { sendCurrencyIso, submitting, handleSubmit, isValid, error } =
      this.props;
    const { posting, receiveCurrencies } = this.state;

    let currencyOptions = receiveCurrencies.map((c) => {
      return { value: c.iso, label: c.iso };
    });

    let sortedCountries = this.props.availableCountries.slice();
    sortedCountries.sort(function (a, b) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    });

    return (
      <form onSubmit={handleSubmit}>
        <Restrictions restrictions={this.props.restrictions} />
        <p>{PAGES.MONEY_TRANSFER.RATE_LIST.WHERE_TO_SEND}</p>

        <div id="country-amount-form">
          <div className="form-group spacing-top-medium">
            <label>{PAGES.MONEY_TRANSFER.RECEIVING_COUNTRY}</label>
            <Field
              type="select"
              component={CountryPicker}
              props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_COUNTRY }}
              name="destinationCountry"
              placeholder={COMMON.SELECT_COUNTRY}
              disabled={posting}
              countries={sortedCountries}
              onChange={(e) => this.handleCountryChange(e)}
              ref={(input) => {
                this.destinationCountryField = input;
              }}
              autoFocus
            />
          </div>

          {this.state.displayProvinces && this.props.availableProvinces.length > 0 &&
            <div className="form-group">
              <label>{PAGES.MONEY_TRANSFER.RECEIVING_PROVINCE}</label>
              <Field
                type="select"
                component={CountryPicker}
                props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_PROVINCE }}
                name="destinationProvince"
                placeholder={COMMON.SELECT}
                disabled={posting}
                countries={this.props.availableProvinces}
                onChange={(e) => this.handleProvinceChange(e)}
                ref={(input) => {
                  this.destinationProvinceField = input;
                }}
                autoFocus
              />
            </div>
          }

          {this.state.displayCities && this.props.availableCities.length > 0 &&
            <div className="form-group">
              <label>{PAGES.MONEY_TRANSFER.RECEIVING_CITY}</label>
              <Field
                type="select"
                component={CountryPicker}
                props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_CITY }}
                name="destinationCity"
                placeholder={COMMON.SELECT}
                disabled={posting}
                countries={this.props.availableCities}
                onChange={(e) => this.handleCityChange(e)}
                ref={(input) => {
                  this.destinationCityField = input;
                }}
                autoFocus
              />
            </div>
          }

          <div className={"amount-row" + (this.state.amountDisabled ? " disabled" : "")}>
            <div className={"form-group"}>
              <label>{PAGES.MONEY_TRANSFER.SEND_AMOUNT}</label>
              <Field
                type="number"
                normalize={overZero}
                component={CurrencyInput}
                decimalPlaces={2}
                currencyIso={sendCurrencyIso}
                disabled={this.state.amountDisabled}
                name="sendAmount"
                validate={[required, sendAmountValidation]}
                focused={this.state.amountFocused}
                warn={sendAmountValidation}
                aria-describedby="basic-addon2"
              />
            </div>

            <div className="right-arrow">
              <img alt="arrow" src={enabledArrow} />
            </div>

            <div className="form-group no-label">
              <Field
                name="receiveCurrency"
                component={GeneralSelect}
                disabled={this.state.amountDisabled}
                validate={[required, currencyCheck]}
                placeholder={""}
                options={currencyOptions}
              ></Field>
            </div>
          </div>

          {error && this.renderError(error)}

          <div className={`row actions ${isValid ? "error" : ""}`}>
            <div className="col-sm-7">
              <Action
                className="get-rates-button"
                type="submit"
                title={PAGES.MONEY_TRANSFER.RATE_LIST.GET_RATES}
                disabled={!this.props.valid}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                loading={submitting}
              />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

CountryAmount.defaultProps = {};

CountryAmount.propTypes = {
  GetAvailableCountries: PropTypes.any,
  GetAvailableProvinces: PropTypes.func,
  GetAvailableCities: PropTypes.func,
  availableProvinces: PropTypes.array,
  availableCities: PropTypes.array,
  handleSubmit: PropTypes.func.isRequired,
  sendCurrencyIso: PropTypes.string,
  availableCountries: PropTypes.array,
  providerRatesErrors: PropTypes.array,
  /** redux-form is valid */
  valid: PropTypes.bool.isRequired,
  /** the user's card balance */
  cardBalance: PropTypes.number,
  change: PropTypes.any.isRequired,
  rateQuery: PropTypes.object.isRequired,
  sendAmount: PropTypes.any,
  destinationCountry: PropTypes.any,
  receiveCurrency: PropTypes.any,
  initialize: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  sendLimit: PropTypes.number,
  submitting: PropTypes.bool,
  isValid: PropTypes.bool,
  error: PropTypes.string,
  restrictions: PropTypes.array,
};

const selector = formValueSelector("moneytransfer");

function mapStateToProps(state) {
  var providerRates = get(state, "moneyTransfer.providerRates", []);
  var user = get(state, "user", {});

  return {
    // Will come from somewhere like on the state.card.cashPickupProviders[0].countries:
    availableCountries: get(state, "moneyTransfer.availableCountries", []),
    availableProvinces: state.moneyTransfer.availableProvinces,
    availableCities: state.moneyTransfer.availableCities,
    providerRatesErrors: providerRates.errors,
    cardBalance: state.card.balance,
    sendAmount: selector(state, "sendAmount"),
    receiveCurrency: selector(state, "receiveCurrency"),
    destinationCountry: selector(state, "destinationCountry"),
    destinationProvince: selector(state, "destinationProvince"),
    destinationCity: selector(state, "destinationCity"),
    user: user,
    sendLimit: state.moneyTransfer.sendLimit,
    restrictions: state.moneyTransfer.restrictions,
    rateQuery: state.moneyTransfer.providerRates.rateQuery,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    GetAvailableCountries: () => dispatch(GetAvailableCountries()),
    GetAvailableProvinces: (isoCountryCode) => dispatch(GetAvailableProvinces(isoCountryCode)),
    GetAvailableCities: (isoCountryCode, provinceCode) => dispatch(GetAvailableCities(isoCountryCode, provinceCode)),
    change: (field, value) => dispatch(change(field, value))
  }
}

var form = reduxForm({
  form: FORM_NAMES.MONEY_TRANSFER,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(CountryAmount);

export default connect(mapStateToProps, mapDispatchToProps)(form);
