import React from "react";
import PropTypes from "prop-types";
import { ASCII } from "../../../constants/forms";
import IntegerInput from "../IntegerInput";

const ACCEPTED_CHARACTERS = [
  ASCII.ONE,
  ASCII.TWO,
  ASCII.THREE,
  ASCII.FOUR,
  ASCII.FIVE,
  ASCII.SIX,
  ASCII.SEVEN,
  ASCII.EIGHT,
  ASCII.NINE,
  ASCII.ZERO,
];

/**
 * Validates the input to only allow integer values.
 * @param {*} event The keypress event object
 */
const handleKeyPress = (event) => {
  if (ACCEPTED_CHARACTERS.indexOf(event.charCode) < 0) {
    event.preventDefault();
    return false;
  }
};

/**
 * Renders an integer field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const IntegerField = ({ max, ...props }) => (
  <IntegerInput
    id={props.id}
    {...props.input}
    max={max}
    maxLength={max}
    onKeyPress={handleKeyPress}
    type={props.isSensitive != null ? "password" : "text"}
    className="form-control"
  />
);

IntegerField.propTypes = {
  id: PropTypes.string,
  input: PropTypes.any,
  max: PropTypes.number,
  isSensitive: PropTypes.bool,
};

export default IntegerField;
