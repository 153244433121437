import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function transferValidation(
  state = initialState.moneyTransfer.transferValidation,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.VALIDATE_WORKFLOW_SUCCESS: {
      return Object.assign({}, state, {
        ...action.validationResult,
      });
    }
    default:
      return state;
  }
}
