export const FEATURE_FLAGS = {
  CHOICE_ENGINE_CASH_PICKUP: "choice-engine-cash-pickup",
  ACCOUNT_DETAILS: "account-details",
  NEW_SECURITY_QUESTIONS: "7501-new-security-questions",
  FX_RATES: 'fx-rates',
  BANK_ACCOUNTS: 'bank-accounts',
  PAYMENT_HISTORY: 'payment-history',
  CHANGE_ALLOCATION: 'change-allocation',
  COMPANION_CARD: 'companion-card',
  WAGE_STATEMENTS: 'wage-statements',
  WESTERN_UNION: 'western-union',
  WESTERN_UNION_TO_ACCOUNT_ENABLED: 'western-union-to-account-enabled',
  DISPLAY_CASH_PICKUP: 'display-cash-pickup',
  SEND_TO_BANK_ACCOUNT: 'send-bank-account',
  TOTP_DISPLAY_SETUP: 'TOTP_DisplaySetup',
  TOTP_DisplayDisable: 'TOTP_DisplayDisable',
  COMPANION_PARTICIPANT: 'companion-participant',
  ALLOW_PIN_CHANGE: 'allow-pin-change',
  ALLOW_CARD_TO_CARD_TRANSFER: 'allow-card-to-card-transfer',
  DISABLE_LOCK_UNLOCK_CARD: 'disable-lock-unlock-card',
  CANCEL_ON_DEMAND_TRANSFER: 'cancel-on-demand-transfer',
  DISPLAY_CURRENCY_CONVERTER: 'display-currency-converter',
  HAS_WESTERN_UNION_PROVIDER: 'western-union-provider-available',
  ENABLE_CREWLINK_BANNER: 'enable-crewlink-banner',
};

export const CONDITIONS = {
  EMPLOYER_ID: "employer-id",
};
