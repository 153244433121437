import React from "react";
import PropTypes from "prop-types";
import CategoryImage from "./CategoryImage";
import chevronDown from "../../assets/images/icons/chevron-down.svg";
import { hasSelectedCategory } from "./SupportHelpers";

/**
 * Single card for category type display
 *
 * @param title
 * @param id
 * @returns {*}
 * @constructor
 */
export const CategoryCard = ({
  category,
  selectedCategoryId,
  expandedCategoryId,
  onSelect,
}) => {
  const { name, categoryId, options } = category;
  const hasSubCategories = options && options.length > 0;
  const subCategoryOptions = hasSubCategories
    ? options.map((subCategory) => (
        <button
          key={subCategory.categoryId}
          onClick={() => onSelect(subCategory)}
          className={
            hasSelectedCategory(subCategory, selectedCategoryId)
              ? "selected"
              : ""
          }
        >
          <span className="category-title">{subCategory.name}</span>
        </button>
      ))
    : null;

  let className = "category-card";

  if (!hasSubCategories) {
    className += " no-childs";
  }

  if (expandedCategoryId === category.categoryId) {
    className += " expanded";
  }

  if (hasSelectedCategory(category, selectedCategoryId)) {
    className += " selected";
  }

  return (
    <div className={className}>
      <button
        onClick={() => onSelect(category)}
        className={`category-root card-md`}
      >
        <CategoryImage id={categoryId} className="card-image" />
        <span className="category-title">{name}</span>
        {hasSubCategories && (
          <img className="drop-icon" src={chevronDown} alt="" />
        )}
      </button>
      {hasSubCategories && (
        <div className="sub-container">
          <div className="card-md">{subCategoryOptions}</div>
        </div>
      )}
    </div>
  );
};

CategoryCard.propTypes = {
  category: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.string,
  expandedCategoryId: PropTypes.string,
};

export default CategoryCard;
