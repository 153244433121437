import React, { Component } from "react";
import PropTypes from "prop-types";
import format from "date-fns/format";
import { connect } from "react-redux";
import { COMMON } from "../../constants/localization";
import find from "lodash/find";
import get from "lodash/get";
import {
  PostMessage,
  PostAttachment,
  GetSupport,
  ReadConversation,
} from "../../actions/support";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import MissingConversation from "../../components/Support/MissingConversation";
import MessageList from "../../components/Support/MessageList";
import { SUPPORT_STATUSES } from "../../constants/api";
import MessageForm from "../../components/Support/MessageForm";
import InfoModal from "../../components/Common/InfoModal";
import { Link } from "react-router-dom";

/**
 * Manages display of a support conversation
 */
export class Conversation extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onAttachFile = this.onAttachFile.bind(this);

    this.onCloseError = this.onCloseError.bind(this);

    this.state = {
      attachment: null,
      showErrorModal: false,
    };
  }

  async componentDidMount() {
    const { conversation, conversations } = this.props;
    if (conversations.length === 0) {
      this.props.DisplayLoading();
      await this.props.GetSupport();
      this.props.FinishLoading();
    }

    //if conversation is unread, mark as read.
    if (conversation && conversation.hasUnreadMessages) {
      await this.props.ReadConversation(conversation.conversationId);
    }
  }

  /**
   * Closes the error modal
   * @private
   */
  onCloseError() {
    this.setState({
      showErrorModal: false,
    });
  }

  /**
   * Handles submitting a new message
   * @param message
   * @returns {Promise<void>}
   */
  async onSubmit({ message }) {
    if (this.props.conversation.status.statusId === SUPPORT_STATUSES.CLOSED) {
      return;
    }
    await this.props.DisplayLoading();
    const { messages, conversationId } = this.props.conversation;
    let lastMessageId = messages[messages.length - 1].messageId;
    await this.props.PostMessage(message, lastMessageId, conversationId);
    // handle if there is an error returned from API call
    if (this.props.errors) {
      this.setState({
        showErrorModal: true
      })
    }

    this.props.FinishLoading();
  }

  /**
   * Handles attaching a file to the conversation
   * @param e event
   * @returns {Promise<void>}
   */
  async onAttachFile(e) {
    let file = e.target.files[0];
    await this.props.DisplayLoading();
    const { conversationId, messages } = this.props.conversation;
    let lastMessageId = messages[messages.length - 1].messageId;

    await this.props.PostAttachment(conversationId, lastMessageId, file);
    if (this.props.errors) {
      this.setState({
        showErrorModal: true,
      });
    }
    this.props.FinishLoading();
  }

  render() {
    const { conversation, errors, displaySpinner } = this.props;
    const { showErrorModal } = this.state;
    const firstMessage = conversation?.messages[0];
    /*
    This logic is a little strange, but it allows the spinner to display
    over the conversation chat and prevents displaying the missing conversation
    view on initial load.
    */
    if (conversation) {
      const isClosed = conversation.status.statusId === SUPPORT_STATUSES.CLOSED;
      //Hack to prevent the modal from displacing the conversation container
      const containerClass = showErrorModal
        ? "support-container support-container-modal"
        : "support-container";

      return (
        <div className="support">
          <div className={containerClass}>
            <div className="conversation-title flex-row justify-content-center align-items-center">
              <Link to="/support">
                <div className="glyphicon glyphicon-menu-left link_icon"></div>
                {COMMON.BACK}
              </Link>
              <p className="marengo-grey">{firstMessage.text} ({format(firstMessage.timestamp, "MMM Do")} | #{conversation.conversationId})</p>
            </div>
            <div className="flex-row justify-content-center">
              <div className="conversation-container col-xs-12 col-md-8">
                <div className="messages-container">
                  <MessageList
                    className="message-list"
                    messages={conversation.messages}
                    requesterId={this.props.requesterId}
                  />

                  <MessageForm
                    onSubmit={this.onSubmit}
                    isClosed={isClosed}
                    inputDisabled={displaySpinner || isClosed}
                    attachFile={this.onAttachFile}
                  />
                </div>

              </div>
            </div>

          </div>
          {errors && (
            <InfoModal
              text={errors.message}
              onClose={this.onCloseError}
              open={this.state.showErrorModal}
            />
          )}
        </div>
      );
    }

    if (!conversation && !displaySpinner) {
      return <MissingConversation />;
    }
    return null;
  }
}

Conversation.propTypes = {
  conversationId: PropTypes.string,
  conversations: PropTypes.array,
  conversation: PropTypes.object,
  requesterId: PropTypes.string,
  errors: PropTypes.object,
  PostMessage: PropTypes.any,
  PostAttachment: PropTypes.any,
  FinishLoading: PropTypes.any,
  DisplayLoading: PropTypes.any,
  GetSupport: PropTypes.any,
  displaySpinner: PropTypes.bool,
  ReadConversation: PropTypes.any,
};

function mapStateToProps(state, ownProps) {
  const id = get(ownProps, "match.params.id", ownProps.conversationId);

  const { conversations } = state.support;
  let conversation = find(conversations, (c) => {
    return c.conversationId === id;
  });

  return {
    conversations,
    conversation,
    requesterId: state.user.supportId,
    errors: state.support.errors,
    displaySpinner: state.spinnersInProgress > 0,
  };
}

export default connect(mapStateToProps, {
  PostMessage,
  PostAttachment,
  DisplayLoading,
  FinishLoading,
  GetSupport,
  ReadConversation,
})(Conversation);
