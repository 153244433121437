export function AddInitialApiErrors(error) {
  return (dispatch) => {
    dispatch({
      type: "ADD_INITIAL_API_ERRORS",
      error
    });
  };
}

export function ClearInitialApiErrors() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_INITIAL_API_ERRORS",
    });
  };
}