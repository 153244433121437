import { SUPPORT } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function activeConversationReducer(
  state = initialState.support.activeConversation,
  action
) {
  switch (action.type) {
    case SUPPORT.POST_NEW_CONVERSATION_SUCCESS: {
      if (action.isActiveConversation) {
        return action.conversation.conversationId;
      }
      return initialState.support.activeConversation;
    }

    default:
      return state;
  }
}
