import React from 'react';

const FileField = ({ input }) => {
  const handleChange = (e) => {
    input.onChange(e.target.files[0]);
  }
  return (
    <input
      id={input.name}
      name={input.name}
      type="file"
      onChange={handleChange}
      accept=".jpg,.jpeg,.png,.pdf,.doc,.docx"
    />
  );
}

export default FileField;