import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { enrollLogout } from "../../services/enrollment";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import ActionRow from "../../components/Layouts/ActionRow";
import ENV from "../../constants/environment";
import PagePathConstants from "../../constants/page-numbers";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import i18n from "../../i18n/config";

const Complete = () => {
  const openExternalLinksModal = useClickExternalLink();
  const userData = useSelector((state) => state.enrollment.userData);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  /**
   * Clear localstorage tokens after userData has been received
   */
  useEffect(() => {
    if (currentPage === PagePathConstants.COMPLETE) {
      enrollLogout();
    }

  }, [currentPage]);

  if (currentPage !== PagePathConstants.COMPLETE) {
    return null;
  }

  return (
    <FlowScreen flowTitle="Congratulations!">
      <PageBlock>
        <PageBlock.IntroText>
          {"You have successfully completed enrollment."}
        </PageBlock.IntroText>
        <PageBlock.Body>
          {userData?.workflow?.cardNumber && (
            <p>Your card is active and ready to be used.</p>
          )}

          <p>
            With Navigator Online Account (
            <a href={ENV.BASE_NAVIGATOR_URL}>www.brightwellnavigator.com</a>)
            you are always in the driver&apos;s seat!
          </p>

          {userData.workflow && userData.companionParticipant && (
            <ul>
              <li className="spacing-top-tiny">
                Change your allocation
                <ul>
                  <li className="spacing-top-tiny">
                    Allocate percentages or a fixed amount to one account and
                    the remaining to another.
                  </li>
                </ul>
              </li>

              <li className="spacing-top-tiny">
                Create and edit bank accounts
              </li>

              <li className="spacing-top-tiny">View activity</li>

              <li className="spacing-top-tiny">Payments from your employer</li>

              <li className="spacing-top-tiny">
                Disbursements to your{" "}
                {userData.cardNumber && userData.workflow.accountDetail
                  ? "My card and bank accounts"
                  : userData.cardNumber
                    ? "My card"
                    : "bank accounts"}
                {userData.cardNumber ? " and My card transactions" : ""}
              </li>

              <li className="spacing-top-tiny">Request a companion card</li>

              {(userData.onDemandWireTransferWubs ||
                userData.onDemandWireTransferNav) && (
                  <li className="spacing-top-tiny">
                    Send money
                    <ul>
                      {userData.onDemandWireTransferWubs && (
                        <li className="spacing-top-tiny">
                          Western Union{" "}
                          <a
                            href={
                              ENV.BASE_NAVIGATOR_URL +
                              "/OceanPayCard/SendToWesternUnion"
                            }
                            target="_blank"
                            onClick={(e) => openExternalLinksModal(e, `${ENV.BASE_NAVIGATOR_URL}/OceanPayCard/SendToWesternUnion`)}
                            rel="noopener noreferrer"
                            title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
                          >
                            Register now!
                          </a>
                        </li>
                      )}

                      {userData.onDemandWireTransferNav && (
                        <li className="spacing-top-tiny">OnDemand Transfer</li>
                      )}
                    </ul>
                  </li>
                )}
            </ul>
          )}

          <ActionRow>
            <ActionRow.Forward>
              <Link to={`/`}>
                Connect to Online Account
              </Link>
            </ActionRow.Forward>
          </ActionRow>
        </PageBlock.Body>
      </PageBlock>
    </FlowScreen>
  );
};

Complete.displayName = "CompleteComponent";

export default Complete;
