import React, { FormEvent, useEffect, useState } from 'react';
import { Field, InjectedFormProps, reduxForm, getFormValues, change } from 'redux-form';
import { DropdownType } from '../../../types/fields';
import { CardType, RootState } from '../../../types/redux';
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from '../../../constants/enums';

import InputWrapper from '../../../containers/Pin/InputWrapper';
import DynamicInputField from '../../Common/DynamicFields/DynamicInputField';
import Action from '../../Common/Action';
import CurrencyInput from '../../Common/CurrencyInput';
import { COMMON, PAGES } from '../../../constants/localization';
import { useDispatch, useSelector } from 'react-redux';
import CardApi from '../../../api/card';
import { CardToCardTransferRequest } from '../../../types/api/card';

type CustomProps = {
  recipientOptions: DropdownType[];
  card: CardType;
  handleTransferSuccess: Function;
}

const SendMoneyForm: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = ({ recipientOptions, card, handleTransferSuccess }) => {
  const dispatch = useDispatch();
  const formName = PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.TRANSFER_DETAILS_FORM;
  const formValues = useSelector((state: RootState) => getFormValues(formName)(state)) as CardToCardTransferRequest;
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    if (formValues && Object.keys(formValues).length === 2) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [formValues])

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMessage("");
    setSubmitting(true);

    try {
      const response = await CardApi.CardToCardTransfer(formValues, card.id);
      handleTransferSuccess(PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.TRANSFER_SUCCESS_TITLE, response.message);
      dispatch(change(PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.TRANSFER_DETAILS_FORM, "amount", ""));
      setSubmitting(false);
    } catch (err) {
      if (err?.data?.error) {
        setErrorMessage(err.data.error);
      } else if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setSubmitting(false);
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <InputWrapper>
        <DynamicInputField
          id="recipientId"
          type={FIELD_TYPES.SELECT}
          label="Select recipient card"
          required={true}
          focus={true}
          options={recipientOptions}
        />
      </InputWrapper>

      <div className={"form-group"}>
        <label>Amount to send</label>
        <Field
          type="number"
          required={true}
          component={CurrencyInput}
          decimalPlaces={2}
          currencyIso={card.currencyCode}
          disabled={false}
          name="amount"
          aria-describedby="basic-addon2"
        />
      </div>

      {errorMessage &&
        <p className="error">{errorMessage}</p>
      }

      <p className="spacing-top-medium shark-grey font-12-px">{PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.FEE_DISCLAIMER}</p>

      <Action
        iconRight={null}
        title={"Transfer money"}
        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
        className="full-width"
        loading={submitting}
        disabled={disabled}
      />

    </form>
  );
}

export default reduxForm<{}, CustomProps>({
  form: PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.TRANSFER_DETAILS_FORM
})(SendMoneyForm);