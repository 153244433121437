export const RECEIVE_NAVBAR_LINKS = "HTMLBUILDERS/RECEIVE_NAVBAR_LINKS";
export const RECEIVE_FOOTER_LINKS = "HTMLBUILDERS/RECEIVE_FOOTER_LINKS";
export const RECEIVE_TOKEN = "TOKEN/RECEIVE_TOKEN";
export const RECEIVE_TOKEN_FAILURE = "TOKEN/RECEIVE_TOKEN_FAILURE";
export const DISPLAY_LOADING = "GLOBAL/DISPLAY_LOADING";
export const HIDE_LOADING = "GLOBAL/HIDE_LOADING";

const global = "GLOBAL/";
export const GLOBAL = {
  RETURN_URL: `${global}RETURN_URL`,
  APP_STATE: `${global}APP_STATE`,
  BROWSER_SUPPORTED: `${global}BROWSER_SUPPORTED`,
  SET_IS_INERT: `${global}SET_IS_INERT`,
  SET_EXTERNAL_LINKS_MODAL_OPEN: `${global}SET_EXTERNAL_LINKS_MODAL_OPEN`,
  SET_ERROR_MODAL: `${global}SET_ERROR_MODAL`,
};

const cashPickup = "CASH_PICKUP/";
const providerRates = `${cashPickup}/PROVIDER_RATES/`;
export const CASH_PICKUP = {
  GET_RATES: `${cashPickup}GET_RATES`,
  FETCHING_RATES: `${cashPickup}FETCHING_RATES`,
  RECEIVE_RATES_SUCCESS: `${cashPickup}RECEIVE_RATES_SUCCESS`,
  RECEIVE_RATES_FAILURE: `${cashPickup}RECEIVE_RATES_FAILURE`,
  POST_TRANSACTION: `${cashPickup}POST_TRANSACTION`,
  POST_RATE_SUCCESS: `${cashPickup}POST_RATE_SUCCESS`,
  POST_RATE_FAILURE: `${cashPickup}POST_RATE_FAILURE`,
  POST_TRANSACTION_SUCCESS: `${cashPickup}POST_TRANSACTION_SUCCESS`,
  POST_TRANSACTION_FAILURE: `${cashPickup}POST_TRANSACTION_FAILURE`,
  LOAD_INITIAL_VALUES: `${cashPickup}LOAD_INITIAL_VALUES`,
  SELECT_RATE: `${cashPickup}SELECT_RATE`,
  SET_VALIDATION: `${cashPickup}SET_VALIDATION`,
  SET_RECIPIENT: `${cashPickup}SET_RECIPIENT`,
  DESTROY_FORM: `${cashPickup}DESTROY_FORM`,
  SET_PAYOUT_NETWORK: `${cashPickup}SET_PAYOUT_NETWORK`,
  RECEIVE_PAYER_CITIES_SUCCESS: `${cashPickup}RECEIVE_PAYER_CITIES_SUCCESS`,
  RECEIVE_PAYER_CITIES_FAILURE: `${cashPickup}RECEIVE_PAYER_CITIES_FAILURE`,
  RECEIVE_PAYERS_SUCCESS: `${cashPickup}RECEIVE_PAYERS_SUCCESS`,
  RECEIVE_PAYERS_FAILURE: `${cashPickup}RECEIVE_PAYERS_FAILURE`,
  PROVIDER_RATES: {
    START_OVER: `${providerRates}START_OVER`,
    GET_DESTINATIONS: `${providerRates}GET_DESTINATIONS`,
    RECEIVE_DESTINATIONS_SUCCESS: `${providerRates}RECEIVE_DESTINATIONS_SUCCESS`,
    RECEIVE_DESTINATIONS_FAILURE: `${providerRates}RECEIVE_DESTINATIONS_FAILURE`,
    SELECT_DESTINATION: `${providerRates}SELECT_DESTINATION`,
    SELECT_CURRENCY: `${providerRates}SELECT_CURRENCY`,
    GET_PROVIDER_RATES: `${providerRates}GET_PROVIDER_RATES`,
    RECEIVE_PROVIDER_RATES_SUCCESS: `${providerRates}RECEIVE_PROVIDER_RATES_SUCCESS`,
    RECEIVE_PROVIDER_RATES_FAILURE: `${providerRates}RECEIVE_PROVIDER_RATES_FAILURE`,
    SELECT_PROVIDER_RATE: `${providerRates}SELECT_PROVIDER_RATE`,
  },
};

const user = "USER/";
export const USER = {
  GET_USER: `${user}GET_USER`,
  GET_USER_PROFILE_SUCCESS: `${user}GET_USER_PROFILE_SUCCESS`,
  PUT_USER_PROFILE_SUCCESS: `${user}PUT_USER_PROFILE_SUCCESS`,
  PUT_USER_PROFILE_FAILURE: `${user}PUT_USER_PROFILE_FAILURE`,
  PUT_USER_PROFILE_INVALID: `${user}PUT_USER_PROFILE_INVALID`,
  RECEIVE_USER: `${user}RECEIVE_USER`,
  GET_FEATURE_FLAGS_SUCCESS: `${user}GET_FEATURE_FLAGS_SUCCESS`,
  PIN_CHECK_SUCCESS: `${user}PIN_CHECK_SUCCESS`,
  PIN_CHECK_FAILURE: `${user}PIN_CHECK_FAILURE`,
  CHANGE_PASSWORD_SUCCESS: `${user}CHANGE_PASSWORD_SUCCESS`,
  CHANGE_PASSWORD_FAILURE: `${user}CHANGE_PASSWORD_FAILURE`,
  ACCOUNT_LOCKED: `${user}ACCOUNT_LOCKED`,
  GET_NEWS: `${user}GET_NEWS`
};

const card = "CARD/";
export const CARD = {
  GET_USER_CARDS: `${card}GET_USER_CARDS`,
  GET_USER_CARDS_STATUS: `${card}GET_USER_CARDS_STATUS`,
  RECEIVE_USER_CARDS_SUCCESS: `${card}RECEIVE_USER_CARDS_SUCCESS`,
  GET_CARD_TRANSACTION_HISTORY: `${card}GET_CARD_TRANSACTION_HISTORY`,
  GET_AVAILABILITY: `${card}GET_AVAILABILITY`,
  SHOW_ODT_MODAL: `${card}SHOW_ODT_MODAL`,
};

export const CATALOG = {
  GET_COUNTRIES: `CATALOG/GET_COUNTRIES`,
  GET_STATE_PROVINCES: `CATALOG/GET_STATE_PROVINCES`,
};

const support = "SUPPORT/";
export const SUPPORT = {
  GET_INDEX: `${support}GET_INDEX`,
  GET_INDEX_SUCCESS: `${support}GET_INDEX_SUCCESS`,
  UPDATE_CATEGORIES: `${support}UPDATE_CATEGORIES`,
  GET_INDEX_FAILURE: `${support}GET_INDEX_FAILURE`,
  POSTING_NEW_CONVERSATION: `${support}POSTING_NEW_CONVERSATION`,
  POST_NEW_CONVERSATION_SUCCESS: `${support}POST_NEW_CONVERSATION_SUCCESS`,
  POST_NEW_CONVERSATION_FAILURE: `${support}POST_NEW_CONVERSATION_FAILURE`,
  POST_NEW_MESSAGE_SUCCESS: `${support}POST_NEW_MESSAGE_SUCCESS`,
  POST_NEW_MESSAGE_FAILURE: `${support}POST_NEW_MESSAGE_FAILURE`,
  POST_NEW_ATTACHMENT_SUCCESS: `${support}POST_NEW_ATTACHMENT_SUCCESS`,
  POST_NEW_ATTACHMENT_FAILURE: `${support}POST_NEW_ATTACHMENT_FAILURE`,
  POST_READ_CONVERSATION: `${support}POST_READ_CONVERSATION`,
};

export const WAGE_STATEMENTS = {
  RECEIVE_WAGE_STATEMENTS_SUCCESS:
    "WAGESTATEMENTS/RECEIVE_WAGE_STATEMENTS_SUCCESS",
  RECEIVE_WAGE_STATEMENTS_FAILURE:
    "WAGESTATEMENTS/RECEIVE_WAGE_STATEMENTS_FAILURE",
};

export const USER_SELECTIONS = {
  ADD_SELECTION: "USERSELECTIONS/ADD_SELECTION",
  GET_SELECTIONS: "USERSELECTIONS/GET_SELECTIONS",
};

const moneytransfer = "MONEY_TRANSFER/";
export const MONEY_TRANSFER = {
  GET_RATES_SUCCESS: `${moneytransfer}GET_RATES_SUCCESS`,
  GET_RATES_FAILURE: `${moneytransfer}GET_RATES_FAILURE`,
  GET_AVAILABLE_COUNTRIES: `${moneytransfer}GET_AVAILABLE_COUNTRIES`,
  GET_AVAILABLE_PROVINCES: `${moneytransfer}GET_AVAILABLE_PROVINCES`,
  GET_AVAILABLE_CITIES: `${moneytransfer}GET_AVAILABLE_CITIES`,
  UPDATE_PROVIDER_RATES: `${moneytransfer}UPDATE_PROVIDER_RATES`,
  GET_WORKFLOW: `${moneytransfer}GET_WORKFLOW`,
  CLEAR_WORKFLOW: `${moneytransfer}CLEAR_WORKFLOW`,
  VALIDATE_WORKFLOW_SUCCESS: `${moneytransfer}VALIDATE_WORKFLOW_SUCCESS`,
  VALIDATE_WORKFLOW_INVALID: `${moneytransfer}VALIDATE_WORKFLOW_INVALID`,
  VALIDATE_WORKFLOW_FAILURE: `${moneytransfer}VALIDATE_WORKFLOW_FAILURE`,
  COMMIT_SUCCESS: `${moneytransfer}COMMIT_SUCCESS`,
  COMMIT_FAILURE: `${moneytransfer}COMMIT_FAILURE`,
  RESET_COMMIT_ERRORS: `${moneytransfer}RESET_COMMIT_ERRORS`,
  GET_RECEIPT_SUCCESS: `${moneytransfer}GET_RECEIPT_SUCCESS`,
  GET_RECEIPT_FAILURE: `${moneytransfer}GET_RECEIPT_FAILURE`,
  GET_TERMS_AND_CONDITIONS_SUCCESS: `${moneytransfer}GET_TERMS_AND_CONDITIONS_SUCCESS`,
  GET_TRANSFER_DETAILS_SUCCESS: `${moneytransfer}DETAILS`,
  GET_TRANSFER_DETAILS_ERROR: `${moneytransfer}DETAILS_ERROR`,
  CANCEL_TRANSACTION_SUCCESS: `${moneytransfer}CANCEL_TRANSACTION_SUCCESS`,
  CANCEL_TRANSACTION_ERROR: `${moneytransfer}CANCEL_TRANSACTION_ERROR`,
  SET_INITIAL_TO_ACCOUNT_WORKFLOW_PAGES: `${moneytransfer}SET_INITIAL_TO_ACCOUNT_WORKFLOW_PAGES`,
  SET_WORKFLOW_PAGE_NUMBER: `${moneytransfer}SET_WORKFLOW_PAGE_NUMBER`,
  CLEAR_DYNAMIC_WORKFLOWPAGES: `${moneytransfer}CLEAR_DYNAMIC_WORKFLOWPAGES`,
  ADD_WORKFLOW_STEP: `${moneytransfer}ADD_WORKFLOW_STEP`,
  REMOVE_WORKFLOW_STEP: `${moneytransfer}REMOVE_WORKFLOW_STEP`,
  SET_SERVICE_TYPE: `${moneytransfer}SET_SERVICE_TYPE`,
};

const auth = "AUTH/";
export const AUTH = {
  CLEAN_LOGIN_TOKEN: `${auth}/CLEAN_LOGIN_TOKEN`,
  UPDATE_USERNAME: `${auth}/UPDATE_USERNAME`,
  RECEIVE_AUTH_RESULT: `${auth}/RECEIVE_AUTH_RESULT`,
  UPDATE_AUTH_DIRECTIVE: `${auth}/UPDATE_AUTH_DIRECTIVE`,
  UPDATE_SUPPORT: `${auth}/UPDATE_SUPPORT`,
  UPDATE_FACECHECK_ENROLLMENT_INFO: `${auth}/UPDATE_FACECHECK_ENROLLMENT_INFO`,
  TIMEOUT_ID: `${auth}/TIMEOUT_ID`,
  UPDATE_FACECHECK_AUTOSTART: `${auth}/UPDATE_FACECHECK_AUTOSTART`,
  UPDATE_DASHBOARD_INAPP_MESSAGE: `${auth}/UPDATE_DASHBOARD_INAPP_MESSAGE`,
  UPDATE_ACCEPT_DOCS: `${auth}/UPDATE_ACCEPT_DOCS`,
  LOGOUT: `${auth}/LOGOUT`,
};

export const ENROLLMENT = {
  UPDATE_ENROLLMENT_DATA: 'ENROLLMENT/UPDATE_ENROLLMENT_DATA',
  UPDATE_CURRENT_PAGE: 'ENROLLMENT/UPDATE_CURRENT_PAGE'
}

export const ON_DEMAND_TRANSFER = {
  UPDATE_DESTINATION_INFO: 'ON_DEMAND_TRANSFER/UPDATE_DESTINATION_INFO',
  UPDATE_ODT_TERMS_AND_CONDTIONS_DOC: 'ON_DEMAND_TRANSFER/UPDATE_ODT_TERMS_AND_CONDTIONS_DOC',
  SET_RATE_INQUIRY_DATA: 'ON_DEMAND_TRANSFER/SET_RATE_INQUIRY_DATA',
  UPDATE_DODD_FRANK_DISBURSEMENT: 'ON_DEMAND_TRANSFER/UPDATE_DODD_FRANK_DISBURSEMENT',
  POST_TRANSFER_SUCCESS: 'ON_DEMAND_TRANSFER/POST_TRANSFER_SUCCESS',
};

export const BANK_ACCOUNTS = {
  SHOW_DELETE_MODAL: 'BANK_ACCOUNTS/SHOW_DELETE_MODAL',
  BANK_ACCOUNT_DELETED: 'BANK_ACCOUNTS/BANK_ACCOUNT_DELETED',
  SET_BANK_ACCOUNT_DISBURSEMENT_HISTORY: 'BANK_ACCOUNTS/SET_BANK_ACCOUNT_DISBURSEMENT_HISTORY',
  SET_SELECTED_COUNTRY: 'BANK_ACCOUNTS/SET_SELECTED_COUNTRY',
  SET_SELECTED_CURRENCY: 'BANK_ACCOUNTS/SET_SELECTED_CURRENCY',
};
