import React, { useEffect, useState } from "react";
import { ROUTES } from "../../constants/clientRoutes";
import { Props } from "../../types/props";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

import FlowScreen from "../../components/Layouts/FlowScreen";
import CountrySelection from "./CountrySelection";
import CurrencySelection from "./CurrencySelection";
import ActionRow from "../../components/Layouts/ActionRow";
import ActionLink from "../../components/Common/ActionLink";
import Action from "../../components/Common/Action";
import ApiErrorModal from "../../components/Common/Modals/ApiErrorModal";
import AccountDetails from "./AccountDetails";

const BankAccountWizard = (props: Props) => {
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [page, setPage] = useState("");
  const [backButton, setBackButton] = useState({
    text: "",
    clickFunc: () => { }
  });
  const [forwardButton, setForwardButton] = useState({
    text: "",
    clickFunc: () => { },
    disabled: false
  });

  const childProps = { setTitle, setSubTitle, setBackButton, setForwardButton };

  useEffect(() => {
    setPage(props.location.pathname);
  }, [props.location.pathname]);

  return (
    <FlowScreen
      flowTitle={title}
      className="position-relative"
    >
      <p>{subTitle}</p>

      {/* Country Selection */}
      {page === ROUTES.BANKS_ACCOUNTS.COUNTRY && <CountrySelection {...childProps} />}

      {/*  Currency Selection */}
      {page === ROUTES.BANKS_ACCOUNTS.CURRENCY && <CurrencySelection {...childProps} />}

      {/*  Account Details */}
      {page === ROUTES.BANKS_ACCOUNTS.ACCOUNT_DETAILS && <AccountDetails {...childProps} />}

      <ActionRow>
        <ActionRow.Backward>
          <ActionLink
            clickFunc={() => backButton.clickFunc()}
            text={backButton.text}
          />
        </ActionRow.Backward>
        <ActionRow.Forward>
          <Action
            title={forwardButton.text}
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            onClick={forwardButton.clickFunc}
            disabled={forwardButton.disabled}
          />
        </ActionRow.Forward>
      </ActionRow>

      <ApiErrorModal />
    </FlowScreen>
  );
}

export default BankAccountWizard;