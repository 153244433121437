import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import orderBy from "lodash/orderBy";
import ConversationList from "../../components/Support/ConversationList";
import { GetSupport } from "../../actions/support";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import { ROUTES } from "../../constants/clientRoutes";
import messageImage from "../../assets/images/support/chat-icon.svg";
import callImage from "../../assets/images/support/callus-icon.svg";
import SpinningLoader from "../../components/Common/SpinningLoader";
import Modal from "../../components/Common/Modal";
import { PAGES } from "../../constants/localization";
import ActionLink from "../../components/Common/ActionLink";
import CrewPageAlert from "../../components/Support/CrewPageAlert";
import { FEATURE_FLAGS } from "../../constants/featureFlags";

export class Conversations extends Component {
  constructor(props) {
    super(props);
    this.showPhones = this.showPhones.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.buildCallSupportModal = this.buildCallSupportModal.bind(this);

    this.state = {
      showPhones: false,
      modalContent: null
    };
  }

  /*TODO: This method was renamed from componentWillMount. 
  Remove once code is verified as working. */
  async componentDidMount() {
    this.props.DisplayLoading();

    this.setState({
      modalContent: <div>
        <p>{PAGES.SUPPORT.FALLBACK_MODAL_TEXT}</p>
      </div>
    })

    if (this.props.conversations.length === 0) {
      await this.props.GetSupport();
    }

    this.props.FinishLoading();
  }

  componentDidUpdate(prevProps) {
    if (this.props.phoneNumbers !== prevProps.phoneNumbers) {
      const modalContent = this.buildCallSupportModal(this.props.phoneNumbers);
      this.setState({
        modalContent
      })
    }
  }

  showPhones() {
    this.setState({
      showPhones: true,
    });
  }

  onCancel() {
    this.setState({
      showPhones: false,
    });
  }

  /**
   * Builds the Call Us modal body.
   * Accepts a list of phone number objects. Has a fallback if needed.
   * @param {*} phoneNumbers array of phone number dtos
   */
  buildCallSupportModal(phoneNumbers) {
    let phoneModalContent;
    if (phoneNumbers != undefined && phoneNumbers.length > 0) {
      phoneModalContent = (
        <div>
          <div className="row support-modal-sub-header">
            {PAGES.SUPPORT.SELECT_CALL}
          </div>
          {phoneNumbers.map((num) => {
            return (
              <div className="row spacing-bottom-small" key={num.number}>
                <ActionLink
                  classes={"btn btn-primary phone-btn-block"}
                  href={`tel:${num.number}`}
                >
                  <span className="bold spacing-right-xs">{num.name}</span>{" "}{num.number}
                </ActionLink>
              </div>
            );
          })}
        </div>
      )
    } else {
      phoneModalContent = (
        <div>
          <p>{PAGES.SUPPORT.FALLBACK_MODAL_TEXT}</p>
        </div>
      );
    }

    return this.createPhoneModal(phoneModalContent);
  }

  createPhoneModal = (content) => {
    return (
      <div className="phone-modal">
        <div className="modal-text">
          {content}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.loading) {
      return <SpinningLoader show={true} />;
    }

    return (
      <div className="support">
        <h1 className="page-title marengo-grey">Support center</h1>

        {this.props?.featureFlags.includes(FEATURE_FLAGS.ENABLE_CREWLINK_BANNER) &&
          <div className="flex-row justify-content-center">
            <div className="col-xs-12 col-md-8 padding-left-none padding-right-none">
              <CrewPageAlert className="spacing-bottom-none" />
            </div>
          </div>
        }

        <div className="actions-container flex-row justify-content-center">
          <div className="col-xs-12 col-md-8 support-banner">
            <Link
              to={ROUTES.SUPPORT_NEW}
              className="center-text spacing-right-medium"
            >
              <div className="action-image-container">
                <img
                  src={messageImage}
                  className="action-image img-responsive"
                />
              </div>
              <span>Message Us</span>
            </Link>
            <div className="link center-text spacing-left-medium">
              <div className="action-image-container" onClick={this.showPhones} data-cy="support-call-us">
                <img
                  src={callImage}
                  className="action-image img-responsive"
                />
              </div>
              <span onClick={this.showPhones}>Call Us</span>
            </div>
          </div>
        </div>

        <div className="message-history-container flex-row justify-content-center">
          <div className="col-xs-12 col-md-8 padding-left-none padding-right-none">
            <p className="abyss-blue">Message history</p>
            <ConversationList
              conversations={this.props.conversations}
              history={this.props.history}
            />

            <Modal
              title={PAGES.SUPPORT.CALL_US}
              open={this.state.showPhones}
              onClose={this.onCancel}
              disableNativeActions={true}
              content={this.state.modalContent}
              small={true}
            />
          </div>
        </div>
      </div>
    );
  }
}

Conversations.propTypes = {
  /** A list of conversations */
  conversations: PropTypes.array.isRequired,
  /** react-router history */
  history: PropTypes.any.isRequired,
  /** List of support phone numbers */
  phoneNumbers: PropTypes.array,
  /** Is data being retrieved */
  loading: PropTypes.bool,
  /** Get the support index action */
  GetSupport: PropTypes.any,
  /** Display spinner action */
  DisplayLoading: PropTypes.any,
  /** Hide spinner action */
  FinishLoading: PropTypes.any,
  /** User feature flags */
  featureFlags: PropTypes.array
};

function mapStateToProps(state) {
  const conversations = orderBy(
    state.support.conversations,
    [
      "hasUnreadMessages",
      (c) => {
        let lastMessage = c.messages[c.messages.length - 1];
        return lastMessage ? new Date(lastMessage.timestamp) : new Date();
      },
    ],
    ["desc", "desc"]
  );

  return {
    conversations,
    phoneNumbers: state.support.phoneNumbers,
    loading: state.spinnersInProgress > 0,
    featureFlags: state.user.featureFlags
  };
}

export default connect(mapStateToProps, {
  GetSupport,
  DisplayLoading,
  FinishLoading,
})(Conversations);
