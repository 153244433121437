import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/config';
import { ODTTransferDetails } from '../../types/api/odt';
import OnDemandTransferApi from '../../api/onDemandTransfer';

import FlowScreen from '../../components/Layouts/FlowScreen';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import TransferCard from '../../components/ODT/TransferCard';
import AlertModal from '../../components/Common/Modals/AlertModal';

const Transfers = () => {
  const [loading, setLoading] = useState(true);
  const [transfersList, setTransfersList] = useState<ODTTransferDetails[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {

    const getTransfersList = async () => {
      try {
        const response = await OnDemandTransferApi.GetTransfersList();
        setTransfersList(response.transfers);
        setLoading(false);
      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }
        setLoading(false);
        setShowErrorModal(true);
      }
    };

    getTransfersList();
  }, []);


  return (
    <FlowScreen
      flowTitle={i18n.t("PAGES.ODT.TRANSFERS.TITLE")}
      className="position-relative"
    >

      {loading &&
        <InputSkeletonGroup numInputs={4} />
      }

      {!loading &&
        <>
          <h2>{i18n.t("PAGES.ODT.TRANSFERS.SUBTITLE")}</h2>
          <section className="transfers-list">
            {transfersList.map((transfer) => (
              <TransferCard key={transfer.transferId} data={transfer} />
            ))}
          </section>
        </>
      }

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />

    </FlowScreen>
  );
}

export default Transfers;