import * as React from 'react';
import { DisplaySectionFieldType } from '../../types/api/moneyTransfer';
import { FIELD_TYPES_BY_NAME } from '../../constants/enums';

const DisplaySectionField = ({ field }: { field: DisplaySectionFieldType }) => {
  if (field.type === FIELD_TYPES_BY_NAME.HORIZONTAL_RULE) {
    return <hr className="small" />
  }

  return (
    <div className="flex-row">
      {field.label &&
        <div>
          <label className="small">
            {field.label}
            {field.superscript &&
              <sup>{field.superscript}</sup>
            }
          </label>
        </div>
      }
      {/* If field does not have formattedValue, then label should take up 100% width */}
      <div className={`${field.label ? "text-right" : ""}`}>
        <p className={`value ${!field.label ? "shark-grey" : ""}`} dangerouslySetInnerHTML={{ __html: field.formattedValue }} ></p>
      </div>


    </div>
  );
}

export default DisplaySectionField;