import React, { Component } from "react";
import FlowScreen from "../../components/Layouts/FlowScreen";
import CurrentPINContainer from "./Containers/CurrentPINContainer";
import ChangePINContainer from "./Containers/ChangePINContainer";
import withMobileResizing from "../../HOCs/withMobileResizing";
import { PAGES } from "../../constants/localization";
import ForgotPINContainer from "./Containers/ForgotPINContainer";
import { RESET_PIN_STEPS } from "../../constants/enums";
import UserApi from "../../api/user";
import RequireCard from "../../components/Common/RequireCard";

const CurrentPIN = withMobileResizing(CurrentPINContainer);
const ChangePIN = withMobileResizing(ChangePINContainer);

export default class ResetPIN extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: RESET_PIN_STEPS.CURRENT_PIN,
      isTransferLocked: null,
      responseToken: "",
    };

    this.changeStep = this.changeStep.bind(this);
  }

  /**
   * Moves to next step in PIN reset flow
   */
  changeStep(resetPINStep) {
    this.setState({
      currentStep: resetPINStep,
    });
  }

  setResponseToken = (token) => {
    this.setState({
      responseToken: token,
    });
  };

  componentDidMount() {
    this.checkTransferLock();
  }

  /*
   * Makes call to API to check the logged in user's transfer lock status.
   * This ignores errors and assumes transferLocked is false for error scenarios.
   */
  checkTransferLock = async () => {
    try {
      let response = await UserApi.GetTransferLockStatus();

      this.setState({
        isTransferLocked: response.transferLocked,
      });
    } catch (error) {
      /// Should submit errors here?
    }
  };

  render() {
    return (
      <FlowScreen
        flowTitle={PAGES.PIN_RESET.MASTER_CONTAINER_TITLE}
        className="pin-reset"
      >
        <RequireCard>
          {this.state.currentStep == RESET_PIN_STEPS.CURRENT_PIN && (
            <CurrentPIN
              changeStep={this.changeStep}
              setResponseToken={this.setResponseToken}
              checkTransferLock={this.checkTransferLock}
              isTransferLocked={this.state.isTransferLocked}
            />
          )}
          {this.state.currentStep == RESET_PIN_STEPS.FORGOT_PIN && (
            <ForgotPINContainer
              changeStep={this.changeStep}
              checkTransferLock={this.checkTransferLock}
              isTransferLocked={this.state.isTransferLocked}
              setResponseToken={this.setResponseToken}
            />
          )}
          {this.state.currentStep == RESET_PIN_STEPS.CHANGE_PIN && (
            <ChangePIN
              changeStep={this.changeStep}
              responseToken={this.state.responseToken}
            />
          )}
        </RequireCard>
      </FlowScreen>
    );
  }
}
