import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function providerRates(
  state = initialState.moneyTransfer.providerRates,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_RATES_SUCCESS: {
      return Object.assign({}, state, {
        rateQuery: action.rateQuery,
        providerRates: action.providerRates.providers.slice(),
        disclaimers: action.providerRates.disclaimers.slice(),
        restrictions: action.providerRates.restrictions.slice(),
        errors: [],
      });
    }
    case MONEY_TRANSFER.GET_RATES_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        errors: action.errors,
      });
    }
    case MONEY_TRANSFER.UPDATE_PROVIDER_RATES: {
      let providerIndex = state.providerRates.findIndex(
        (r) => r.providerId == action.providerRates.serviceProviderId
      );
      let provider = Object.assign({}, state.providerRates[providerIndex]);

      provider.quotes = action.providerRates.quotes;

      let newProviders = state.providerRates.slice();
      newProviders[providerIndex] = provider;

      return Object.assign({}, state, {
        providerRates: newProviders,
      });
    }
    default:
      return state;
  }
}
