import React from "react";
import PropTypes from "prop-types";
import Modal from "react-responsive-modal";

/**
 * Displays a simple informational modal
 *
 * @param open
 * @param onClose
 * @param text
 * @returns {*}
 * @constructor
 */
export const InfoModal = ({ open, onClose, text }) => {
  return (
    <Modal open={open} onClose={onClose} showCloseIcon={false} center>
      <div className="modal-text">
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <div className="row button-row">
        <div className="col-xs-6 col-xs-offset-6">
          <button className="btn btn-primary right" onClick={onClose}>
            Ok
          </button>
        </div>
      </div>
    </Modal>
  );
};

InfoModal.propTypes = {
  /** Inner modal text */
  text: PropTypes.string.isRequired,
  /** Callback on close */
  onClose: PropTypes.func.isRequired,
  /** Callback on open */
  open: PropTypes.bool.isRequired,
};

export default InfoModal;
