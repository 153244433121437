import React, { useState } from 'react';
import PropTypes from "prop-types";
import PageBlock from '../../Layouts/PageBlock';
import ActionRow from '../../Layouts/ActionRow';
import Action from '../../Common/Action';
import ActionLink from "../../Common/ActionLink";
import { COMMON, PAGES } from '../../../constants/localization';
import { ACTION_DISPLAY_TYPES, TOTP_FLOW } from '../../../constants/enums';
import Modal from "../../Common/Modal";
import AuthApi from '../../../api/auth';
import AlertModal from '../../Common/Modals/AlertModal';
import AuthenticatorInstructions from './AuthenticatorInstructions';
import AuthenticationAppInputs from '../../Common/AuthenticationAppInputs';

const VerifyAuthAppScreen = ({ page, setPage }) => {
  const [showModal, setShowModal] = useState(false);
  const [resetValues, setResetValues] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (page !== TOTP_FLOW.SCREENS.VERIFY_AUTHENTICATOR_APP) {
    return null;
  }

  const toggleModal = () => {
    setShowModal(!showModal);
  }

  const handleBackClick = () => {
    setResetValues(true);
    setPage(TOTP_FLOW.SCREENS.LINK_AUTHENTICATOR_APP);
  }

  const handleSubmit = async (values) => {
    setSubmitting(true);
    try {
      await AuthApi.postTOTPSetupCode(values);
      setPage(TOTP_FLOW.SCREENS.SUCCESS);
    } catch (err) {
      setSubmitting(false);
      if (err.data) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setShowErrorModal(true);
    }
  }

  return (
    <div className="page-block">
      <Modal
        onClose={toggleModal}
        open={showModal}
        title={PAGES.SETTINGS.LOG_IN_METHOD.HOW_IT_WORKS}
        content={<AuthenticatorInstructions />}
      />
      <PageBlock.Title>{PAGES.SETTINGS.VERIFY_AUTH_APP_SCREEN.TITLE}</PageBlock.Title>
      <PageBlock.Body>

        <p className='marengo-grey'>From the authentication app, get a code and enter it below to complete the verification process.</p>
        <p className='marengo-grey'>If you don&#39;t have an authentication app on your phone, you&#39;ll need to install one first. <ActionLink clickFunc={toggleModal} text={' How it works'} /></p>
        <p className="marengo-grey">Enter the code.</p>

        <AuthenticationAppInputs
          submitFunc={handleSubmit}
          resetValues={resetValues}
          formId={PAGES.SETTINGS.VERIFY_AUTH_APP_SCREEN.FORM_NAME}
          classes={'spacing-bottom-medium'}
        />

        <ActionRow>
          <ActionRow.Forward>
            <Action
              displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              title={COMMON.VERIFY}
              type={'submit'}
              formId={PAGES.SETTINGS.VERIFY_AUTH_APP_SCREEN.FORM_NAME}
              loading={submitting}
            />
          </ActionRow.Forward>

          <ActionRow.Backward>
            <ActionLink
              clickFunc={handleBackClick}
            >
              <div className="glyphicon glyphicon-menu-left link_icon"></div>
              {COMMON.BACK}
            </ActionLink>
          </ActionRow.Backward>

        </ActionRow>
      </PageBlock.Body>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage}
      />
    </div>
  );
}

VerifyAuthAppScreen.propTypes = {
  /** number to track which screen to display in flow */
  page: PropTypes.number,
  /** Hook to change pages in flow */
  setPage: PropTypes.func,
};

export default VerifyAuthAppScreen;