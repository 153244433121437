import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/config';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import { ODTContainerProps } from '../../types/props';
import { ICONS } from '../../constants/enums';

import AmountPurposeForm from '../../components/ODT/AmountPurposeForm';
import PinCheck from '../../components/Common/PinCheck';
import Alert from '../../components/Common/Alerts/Alert';

const AmountPurpose = ({ props }: { props: ODTContainerProps }) => {
  const { setTitle, setShowButtons } = props;
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);
  const { isPinRequired, bankAccountFeeBanner } = onDemandTransfer;
  const [showPinCheck, setShowPinCheck] = useState(isPinRequired);

  useEffect(() => {
    // Set title
    setTitle(i18n.t("PAGES.ODT.AMT_PURPOSE.TITLE"));
    // Hide parent component buttons to allow redux-form buttons to be displayed
    setShowButtons(false);
  }, []);

  return (
    <>
      {isPinRequired &&
        <PinCheck
          open={showPinCheck}
          onSuccess={() => setShowPinCheck(false)}
          onDismiss={() => { }}
          source={{
            name: "PIN CHECK",
            type: "odt-pin-check",
          }}
        />
      }

      {!showPinCheck &&
        <>
          {bankAccountFeeBanner &&
            <Alert
              alertClasses={"alert-info spacing-top-small flex-row align-items-center font-regular"}
              icon={ICONS.INFO}
              iconClasses={"spacing-right-tiny"}
              message={bankAccountFeeBanner}
              isHTML={false}
            />
          }
          <div className="max-w-360">
            <p className="bold navy-blue">{i18n.t("PAGES.ODT.AMT_PURPOSE.INSTRUCTIONS")}</p>
            <p className="marengo-grey">{i18n.t("PAGES.ODT.AMT_PURPOSE.EXAMPLE")}</p>
          </div>
          <AmountPurposeForm {...props} />
        </>
      }
    </>

  );
}

export default AmountPurpose;