import React, { useEffect } from "react";
import i18n from "../../i18n/config";
import { useHistory } from "react-router-dom";
import { BankAccountChildProps } from "../../types/bankAccounts";
import { ROUTES } from "../../constants/clientRoutes";
import { useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import { FORM_NAMES } from "../../constants/forms";
import { CURRENCY_CODES, CURRENCY_NAMES } from "../../constants/enums";
import ActionLink from "../../components/Common/ActionLink";

const AccountDetails = (props: BankAccountChildProps) => {
  const { setTitle, setSubTitle, setBackButton, setForwardButton } = props;
  const history = useHistory();
  const cardCurrencyCode = useSelector((state: RootState) => state.card.currencyCode);
  const destinationCurrencyCode = useSelector((state: RootState) => state.form[FORM_NAMES.BANK_ACCOUNTS].values.destinationCurrency);
  const { destinationCurrencyName, destinationCountryName } = useSelector((state: RootState) => state.bankAccounts);
  let cardCurrencyName;

  useEffect(() => {
    setTitle(i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.TITLE"));
    setSubTitle(i18n.t(""));
    setBackButton({
      text: i18n.t("COMMON.BACK"),
      clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.CURRENCY)
    });
    setForwardButton({ text: i18n.t("COMMON.CONFIRM"), clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.REVIEW), disabled: false });

  }, []);

  switch (cardCurrencyCode) {
    case CURRENCY_CODES.USD:
      cardCurrencyName = CURRENCY_NAMES.USD;
      break;
    case CURRENCY_CODES.EUR:
      cardCurrencyName = CURRENCY_NAMES.EUR;
      break;
    case CURRENCY_CODES.AUD:
      cardCurrencyName = CURRENCY_NAMES.AUD;
      break;
    default:
      cardCurrencyName = "";
  }

  return (
    <>
      <p>{i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.P1")}</p>

      <ul>
        <li>{destinationCurrencyName} {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.L1")} {destinationCountryName}</li>
        {cardCurrencyCode !== destinationCurrencyCode &&
          <li>{i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.L2_1")} {cardCurrencyName} ({cardCurrencyCode}). {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.L2_2")}</li>
        }
      </ul>

      <p>
        {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.P2_1")} {destinationCurrencyName} ({destinationCurrencyCode}).{" "}
        {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.P2_2")} {destinationCurrencyName} ({destinationCurrencyCode}), {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.P2_3")}
      </p>

      <p>
        {i18n.t("PAGES.BANK_ACCOUNTS.ACCOUNT_DETAILS.P3")}{" "}
        <ActionLink
          clickFunc={() => history.push(ROUTES.BANKS_ACCOUNTS.COUNTRY)}
          text={i18n.t("COMMON.CLICK_HERE")}
        />
      </p>
    </>

  );
}

export default AccountDetails;