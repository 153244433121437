import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function workflow(
  state = initialState.moneyTransfer.workflow,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_WORKFLOW: {
      return Object.assign({}, state, {
        ...action.workflow,
        quoteId: action.quoteId
      });
    }
    case MONEY_TRANSFER.CLEAR_WORKFLOW: {
      return initialState.moneyTransfer.workflow;
    }
    case MONEY_TRANSFER.GET_WORKFLOW_SUCCESS: {
      break;
      // TODO: set state with successful workflow validation
      /*return Object.assign({}, state, {
        ...action.workflow
      });*/
    }
    case MONEY_TRANSFER.GET_WORKFLOW_FAILURE: {
      return Object.assign({}, state, {
        ...action.workflow,
      });
    }
    case MONEY_TRANSFER.VALIDATE_WORKFLOW_INVALID: {
      return Object.assign({}, state, {
        ...state,
        pages: action.validationResult.pages,
        errors: action.errors,
      });
    }
    case MONEY_TRANSFER.VALIDATE_WORKFLOW_SUCCESS: {
      /// Messy, but we need to immutably update field values 3 levels down in the state
      return Object.assign({}, state, {
        ...state,
        errors: [],
        fieldCategories: state.fieldCategories.map((fieldCategory) => {
          return {
            ...fieldCategory,
            sections: fieldCategory.sections.map((section) => {
              return {
                ...section,
                fields: section.fields.map((field) => {
                  return {
                    ...field,
                    validationError: null,
                    isValid: true,
                  };
                }),
              };
            }),
          };
        }),
      });
    }
    case MONEY_TRANSFER.GET_TERMS_AND_CONDITIONS_SUCCESS: {
      return Object.assign({}, state, {
        ...state,
        termsAndConditions: action.termsAndConditions,
      });
    }
    case MONEY_TRANSFER.SET_INITIAL_TO_ACCOUNT_WORKFLOW_PAGES: {
      return {
        ...state,
        pages: action.pages,
        serviceType: action.serviceType,
        pageNumber: 1
      };
    }
    case MONEY_TRANSFER.SET_WORKFLOW_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.pageNumber,
      };
    }
    case MONEY_TRANSFER.CLEAR_DYNAMIC_WORKFLOWPAGES: {
      return {
        ...state,
        pages: [...state.pages.slice(0, 2)],
      };
    }
    case MONEY_TRANSFER.ADD_WORKFLOW_STEP: {
      return {
        ...state,
        pages: [...state.pages, action.page],
        pageNumber: state.pageNumber + 1
      };
    }
    case MONEY_TRANSFER.REMOVE_WORKFLOW_STEP: {
      const newPages = state.pages.slice(0, state.pages.length - 1);
      return {
        ...state,
        pages: [...newPages],
      };
    }
    case MONEY_TRANSFER.SET_SERVICE_TYPE: {
      return {
        ...state,
        serviceType: action.serviceType,
      };
    }
    default:
      return state;
  }
}
