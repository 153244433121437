import React from "react";
import PropTypes from "prop-types";
import { ROUTES } from "../../constants/clientRoutes";
import ENV from "../../constants/environment";

/**
 * List of navigation elements not returned by the api
 *
 * @param display
 * @param onLogOut
 * @returns {null|*}
 * @constructor
 */
export const LoggedInItems = ({ display, onLogOut }) => {
  if (!display) {
    return null;
  }

  return (
    <React.Fragment>
      <li id={"AccountSettingsIndex"} className={"menu-item menu-state-default"}>
        <span className="badge-wrap">
          <a
            href={ENV.BASE_NAVIGATOR_URL + ROUTES.EXTERNAL.PROFILE}
            id="AccountSettingsPage"
            className={location.pathname.toLowerCase().indexOf(ROUTES.SETTINGS.INDEX.toLowerCase()) >= 0 ? 'active' : ''}
          >
            Settings
          </a>
        </span>
      </li>
      <li
        id="account-support"
        className="menu-item menu-state-default menu-last"
      >
        <div id="support-button">
          <button
            id="LogOut"
            type="button"
            className="logOut btn btn-slim"
            onClick={onLogOut}
          >
            Log Out
          </button>
        </div>
      </li>
    </React.Fragment>
  );
};

LoggedInItems.propTypes = {
  /** Display toggle */
  display: PropTypes.bool.isRequired,
  /** Log out callback */
  onLogOut: PropTypes.func.isRequired,
};

export default LoggedInItems;
