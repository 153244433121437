import React, { useState } from "react";
import { useSelector } from "react-redux";
import AccountSecurityForm from "../../components/Enrollment/Forms/AccountSecurityForm";
import SessionEndModal from "../../components/Enrollment/SessionEndModal";
import SpinningLoader from "../../components/Common/SpinningLoader";
import PagePathConstants from "../../constants/page-numbers";

/**
 * Account Security Page, third page of enrollment
 */
const AccountSecurity = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  if (currentPage !== PagePathConstants.ACCOUNT_SECURITY) {
    return null;
  }

  return (
    <div className="v2">
      <SpinningLoader show={showSpinner} />
      <AccountSecurityForm
        setShowSpinner={setShowSpinner}
      />
      <SessionEndModal />
    </div>
  );
};

AccountSecurity.displayName = "AccountSecurityComponent";

export default AccountSecurity;
