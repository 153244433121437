import find from "lodash/find";
import { USER_SELECTIONS } from "../constants/actionTypes";
import initialState from "./initialState";

function sort(a, b) {
  return a.label < b.label ? -1 : 1;
}

export default function userSelectionsReducer(
  state = initialState.userSelections,
  action
) {
  switch (action.type) {
    case USER_SELECTIONS.GET_SELECTIONS: {
      const selections = {};
      // eslint-disable-next-line no-unused-vars
      for (const selectionType in action.selections) {
        selections[selectionType] = [...action.selections[selectionType]].sort(
          sort
        );
      }
      return Object.assign({}, state, selections);
    }
    case USER_SELECTIONS.ADD_SELECTION: {
      const { selectionType, option } = action;
      const existing = state[selectionType] || [];

      if (!find(existing, (o) => o.label === option.label)) {
        const newState = existing.concat(option).sort(sort);
        return Object.assign({}, { [selectionType]: [...newState] });
      }
      return state;
    }
    default:
      return state;
  }
}
