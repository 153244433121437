import FaceTecSDK from "./faceTecSDK";
import { ZoomGlobalState } from "../constants/zoomGlobalState";
import ZoomStrings from "../constants/ZoomStrings";
import { LivenessCheckProcessor } from "./facetec/processors/LivenessCheckProcessor";
import ENV from "../constants/environment";
import { FACETEC_SDK_VERSION } from "../constants/faceTecVersion";
import { trackEvent } from "./eventTracker";
import { EVENTS } from "../constants/events";

let _initialized = false;

function getCustomization() {
  const customization = new FaceTecSDK.FaceTecCustomization();
  const fontFamily = "'Muli', sans-serif";
  const boldFontFamily = "'Muli Bold', sans-serif";
  const backgroundColor = "#F6F7F8";
  const primaryColor = "#1C74D9";
  customization.enableHotKeyProtection = false;
  customization.shouldDrawBackgroundsOpaque = true;
  customization.overlayCustomization.backgroundColor = "white";
  customization.initialLoadingAnimationCustomization.backgroundColor = "green";
  customization.frameCustomization.backgroundColor = backgroundColor;
  customization.frameCustomization.borderCornerRadius = "5px";
  customization.frameCustomization.borderWidth = "1px";
  customization.frameCustomization.borderColor = "#C5D0DE";
  customization.guidanceCustomization.backgroundColors = backgroundColor;
  customization.guidanceCustomization.foregroundColor = "#004780";
  customization.guidanceCustomization.buttonCornerRadius = "27.5px";
  customization.guidanceCustomization.buttonBackgroundNormalColor =
    primaryColor;
  customization.guidanceCustomization.headerFont = boldFontFamily;
  customization.guidanceCustomization.headerTextSize = "22px";
  customization.guidanceCustomization.headerTextSize = "16px";
  customization.guidanceCustomization.subtextFont = fontFamily;
  customization.guidanceCustomization.subtextTextSize = "16px";
  customization.guidanceCustomization.subtextTextSize = "12px";
  customization.guidanceCustomization.buttonFont = fontFamily;
  customization.guidanceCustomization.buttonTextSize = "18px";
  customization.ovalCustomization.strokeColor = primaryColor;
  customization.ovalCustomization.strokeWidth = 5;
  customization.feedbackCustomization.backgroundColor = "#004780";
  customization.feedbackCustomization.textFont = fontFamily;
  customization.feedbackCustomization.textSize = "20px";
  customization.feedbackCustomization.shadow = "none";
  customization.resultScreenCustomization.backgroundColors = backgroundColor;
  customization.resultScreenCustomization.activityIndicatorColor = primaryColor;
  customization.resultScreenCustomization.uploadProgressFillColor =
    primaryColor;
  customization.resultScreenCustomization.uploadProgressTrackColor = "white";

  return customization;
}

function initialize(licenseKey, deviceLicenseKeyIdentifier, onStatusChange) {
  return new Promise((resolve, reject) => {
    if (_initialized) {
      resolve();
      return;
    }

    try {
      // Set a the directory path for other ZoOm Resources.
      FaceTecSDK.setResourceDirectory(`${ENV.PUBLIC_PATH}vendor/core-sdk/v${FACETEC_SDK_VERSION}/FaceTecSDK.js/resources`);
      // Set the directory path for required ZoOm images.
      FaceTecSDK.setImagesDirectory(`${ENV.PUBLIC_PATH}vendor/core-sdk/v${FACETEC_SDK_VERSION}/FaceTec_images`);

      let initializeCallback = (initializedSuccessfully) => {
        if (initializedSuccessfully) {
          trackEvent(EVENTS.LOGIN.FACECHECK_SDK_INITIALIZED, {
            success: true,
            sdk_status: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
              FaceTecSDK.getStatus()
            )
          });
          const currentCustomization = getCustomization();
          FaceTecSDK.configureLocalization(ZoomStrings);
          FaceTecSDK.setCustomization(currentCustomization);
          _initialized = true;
          resolve();
        } else {
          trackEvent(EVENTS.LOGIN.FACECHECK_SDK_INITIALIZED, {
            success: false,
            failure_reason: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
              FaceTecSDK.getStatus()
            )
          });
          reject(
            FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
              FaceTecSDK.getStatus()
            )
          );
        }

        if (onStatusChange) {
          onStatusChange(FaceTecSDK.getStatus());
        }
      };

      FaceTecSDK.initializeInProductionMode(
        licenseKey,
        deviceLicenseKeyIdentifier,
        ZoomGlobalState.PublicFaceMapEncryptionKey,
        initializeCallback
      );

    } catch (e) {
      reject("error on initialize");
    }
  });
}

class FacetecService {
  /**
   * @param {onStatusChange: fn, livenessSessionToken: string} params
   */
  static async startFacetec(params) {
    return initialize(
      params.licenseKey,
      params.deviceKey,
      params.onStatusChange
    ).then(() => {
      return new Promise((resolve, reject) => {
        new LivenessCheckProcessor(
          params.livenessSessionToken,
          (isSuccess, sessionResult) => {
            isSuccess ? resolve(sessionResult) : reject(sessionResult);
          },
          () => {
            reject(
              FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
                FaceTecSDK.getStatus()
              )
            );
          },
          params.loginToken
        );
      });
    });
  }
}

export default FacetecService;
