export const FORM_NAMES = {
  MONEY_TRANSFER: "moneytransfer",
  CASH_PICKUP: "cashpickup",
  ACCOUNT: {
    ACCOUNT_DETAILS: 'ACCOUNT_DETAILS',
    COMMUNICATION_PREFERENCES: 'COMMUNICATION_PREFERENCES',
    ACTIVATE_REPLACEMENT_CARD: 'ACTIVATE_REPLACEMENT_FORM'
  },
  ODT: {
    AMOUNT_PURPOSE: 'odt-amount-purpose',
  },
  BANK_ACCOUNTS: "bank-account-flow"
};

export const ASCII = {
  DOT: 46,
  ZERO: 48,
  ONE: 49,
  TWO: 50,
  THREE: 51,
  FOUR: 52,
  FIVE: 53,
  SIX: 54,
  SEVEN: 55,
  EIGHT: 56,
  NINE: 57,
  RETURN: 13,
};

export const DOCUMENT_NUMBER = {
  MODAL_TITLE: "Document Number Information",
  MODAL_CONTENT: "<p>In this field please provide your passport number or your U.S. social security number.</p>"
}

export const BANK_ACCOUNT_FORM_NAMES = {
  destinationCountry: 'destinationCountry',
  destinationCurrency: 'destinationCurrency',
}