import React from 'react';
import AlertModal from "./AlertModal";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../types/redux';
import { SetErrorModal } from '../../../actions/global';
import { COMMON } from '../../../constants/localization';

const ApiErrorModal = () => {
  const dispatch = useDispatch();
  const { open, errorMessage } = useSelector((state: RootState) => state.global.errorModal);

  const handleClose = () => {
    dispatch(SetErrorModal({ open: false, errorMessage: "" }));
  }

  return (
    <AlertModal
      onClose={() => handleClose()}
      open={open}
      title={COMMON.GENERIC_ERROR_MODAL_HEADER}
      content={errorMessage}
    />
  );
}

export default ApiErrorModal;