import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import i18n from '../../i18n/config';
import { ICONS, ON_DEMAND_TRANSFER_PROVIDERS } from '../../constants/enums';
import ENV from "../../constants/environment";
import { ODTContainerProps } from '../../types/props';
import { ROUTES } from '../../constants/clientRoutes';
import { PROVIDERS } from '../../constants/localization';

import BankBeneficiarySections from '../../components/ODT/BankBeneficiarySections';
import Alert from '../../components/Common/Alerts/Alert';

const ODTTransferDetails = ({ props }: { props: ODTContainerProps }) => {
  const { setTitle, setShowButtons } = props;
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);

  const date = new Date();
  const dateString = new Intl.DateTimeFormat(navigator.language).format(date);

  useEffect(() => {
    setTitle(i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.TITLE"));
    setShowButtons(false);
  }, []);
  return (

    <div className="odt-transfer-confirmation-page">

      {onDemandTransfer.providerId === ON_DEMAND_TRANSFER_PROVIDERS.CONVERA_WUBS &&

        <div className="marengo-grey">
          {/* Address */}
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_1}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_2}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_3}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_4}</p>

          {/* Date */}
          <p>{i18n.t("COMMON.DATE").toUpperCase()} - {dateString}</p>

        </div>

      }

      {onDemandTransfer.feeBanner?.htmlFeeBanner &&
        <Alert
          alertClasses={"alert-info spacing-top-small flex-row font-regular"}
          icon={ICONS.INFO}
          iconClasses={"spacing-right-tiny"}
        >
          <p className="bold">{onDemandTransfer.feeBanner.title}</p>
          <div dangerouslySetInnerHTML={{ __html: onDemandTransfer.feeBanner.htmlFeeBanner }} />
        </Alert>
      }

      <Alert
        alertClasses="alert-info flex-row"
        icon={ICONS.INFO}
        iconClasses="spacing-right-tiny"
      >
        <p className="bold">{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ALERT_CANCELLATION.TITLE")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ALERT_CANCELLATION.P1")}</p>
        <a href={`${ENV.BASE_NAVIGATOR_URL}/OnDemandTransfer/Transfers`}>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ALERT_CANCELLATION.LINK_TEXT")}</a>

      </Alert>

      <Alert
        alertClasses="alert-info flex-row align-items-center"
        icon={ICONS.INFO}
        iconClasses="spacing-right-tiny"
        isHTML={false}
        message={i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ALERT_SAVE.TITLE")}
      />

      {/* Date */}
      <p>{i18n.t("COMMON.DATE")}: {dateString}</p>

      <BankBeneficiarySections sections={onDemandTransfer.displaySections} />

      {/* Error resolution and cancellation */}
      <div className="marengo-grey">

        {/* Errors */}
        <p className="bold">{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.DISCLOSURES")}</p>
        <p className="bold">{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.INTRO_TEXT")}</p>
        <p>
          {i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.P1")}{" "}
          <a href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.SUPPORT_INDEX}`}>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.P1_LINK_TEXT")}</a>
        </p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.P2")}</p>
        <ul>
          <li>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.LIST.ITEM_1")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.LIST.ITEM_2")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.LIST.ITEM_3")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.LIST.ITEM_4")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.LIST.ITEM_5")}</li>
        </ul>
        <p>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.ERRORS.DESCRIPTION")}</p>

        {/* Cancellation */}
        <p className="bold">{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.CANCELLATION.INTRO_TEXT")}</p>
        <p>
          {i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.CANCELLATION.P1_1")}
          <a href={`${ENV.BASE_NAVIGATOR_URL}`}> www.brightwellnavigator.com </a>
          {i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.CANCELLATION.P1_2")}
        </p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_CONFIRMATION.CANCELLATION.P2")}</p>

      </div>

    </div>

  );
}

export default ODTTransferDetails;