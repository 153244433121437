import * as types from "../constants/actionTypes";

export function ReturnUrl(payload) {
  return {
    type: types.GLOBAL.RETURN_URL,
    payload,
  };
}

export function AppState(payload) {
  return {
    type: types.GLOBAL.APP_STATE,
    payload,
  };
}

export function Init() {
  return async function (dispatch) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const returnUrl = urlParams.get("ReturnUrl");

    await dispatch(ReturnUrl(returnUrl));

    return { returnUrl };
  };
}

function browserSupported(payload) {
  return {
    type: types.GLOBAL.BROWSER_SUPPORTED,
    payload,
  };
}

function alertIfOldBrowser() {
  try {
    // browser doesn't not support const/workers/wasm - cannot run FaceTecSDK
    // call incompatible browser handler here
    let testBrowserSupport = new Function(
      "'use strict'; const testConstSupport = 0; var testWorkerSupport = Worker.length; var testWasmSupport = WebAssembly.Module;"
    );
    testBrowserSupport();
    return true;
  } catch (e) {
    return false;
  }
}

export function checkBrowserSupport() {
  return async function (dispatch) {
    const browserSupport = alertIfOldBrowser();
    return await dispatch(browserSupported(browserSupport));
  };
}


export function setIsInert(payload) {
  return {
    type: types.GLOBAL.SET_IS_INERT,
    payload,
  };
}

export function setExternalLinksModalOpen(payload) {
  return {
    type: types.GLOBAL.SET_EXTERNAL_LINKS_MODAL_OPEN,
    payload,
  };
}

export function SetErrorModal(payload) {
  return {
    type: types.GLOBAL.SET_ERROR_MODAL,
    payload
  };
}