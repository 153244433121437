import React from "react";
import PropTypes from "prop-types";
import { PAGES, COMMON } from "../../../constants/localization";
import Action from "../../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import ActionRow from "../../../components/Layouts/ActionRow";
import InputWrapper from "../InputWrapper";
import InputLabel from "../InputLabel";
import PageBlock from "../../../components/Layouts/PageBlock";
import PinField from "../../../components/Common/DynamicFields/PinField";

const ChangePINDesktop = (props) => {
  const handleFirstPINInput = (event) => {
    props.handleFirstPINChange(event.target.value);
  };

  const handleConfirmPINInput = (event) => {
    props.handleConfirmPINChange(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitPIN();
  };

  const submitPIN = () => {
    props.handleSubmit();
  };

  const initialPINEvents = {
    onChange: handleFirstPINInput,
  };

  const confirmPINEvents = {
    onChange: handleConfirmPINInput,
  };

  const buttonDisabled =
    props.initialPIN.length < PAGES.PIN_RESET.PIN_LENGTH ||
    props.confirmPIN.length < PAGES.PIN_RESET.PIN_LENGTH;

  return (
    <PageBlock>
      <PageBlock.Title>{PAGES.PIN_RESET.CREATE_NEW_PIN_HEADER}</PageBlock.Title>
      <PageBlock.Body>
        <form id="ChangePIN" onSubmit={handleSubmit}>
          <div className="pin-reset-input-group">
            <InputWrapper
              className={`pin-input-wrapper ${props.showErrorOnFirstInput ? "has-error" : ""
                }`}
            >
              <InputLabel>{PAGES.PIN_RESET.NEW_PIN_INPUT_LABEL}</InputLabel>
              <PinField events={initialPINEvents} pin={props.initialPIN} dataCy="new-pin" />
              {props?.showErrorOnFirstInput &&
                <div className="text-danger">{props.errorMessage}</div>
              }
            </InputWrapper>
            <InputWrapper
              className={`pin-input-wrapper ${props.showErrorOnSecondInput ? "has-error" : ""
                }`}
            >
              <InputLabel>{PAGES.PIN_RESET.CONFIRM_PIN_INPUT_LABEL}</InputLabel>
              <PinField events={confirmPINEvents} pin={props.confirmPIN} dataCy="confirm-pin" />

              {props?.showErrorOnSecondInput &&
                <div className="text-danger">{props.errorMessage}</div>
              }
            </InputWrapper>
          </div>
          <ActionRow>
            <ActionRow.Forward>
              <Action
                title={COMMON.SUBMIT}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
                loading={props.loading}
                disabled={buttonDisabled}
              />
            </ActionRow.Forward>
            <ActionRow.Backward>
              <a href={PAGES.PIN_RESET.CANCEL_URL}>{COMMON.CANCEL}</a>
            </ActionRow.Backward>
          </ActionRow>
        </form>
      </PageBlock.Body>
    </PageBlock>
  );
};

ChangePINDesktop.propTypes = {
  initialPIN: PropTypes.string,
  confirmPIN: PropTypes.string,
  handleFirstPINInput: PropTypes.func,
  handleConfirmPINInput: PropTypes.func,
  handleConfirmPINChange: PropTypes.func,
  handleFirstPINChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errorMessage: PropTypes.string,
  loading: PropTypes.bool,
  showErrorOnFirstInput: PropTypes.bool,
  showErrorOnSecondInput: PropTypes.bool,
};

export default ChangePINDesktop;
