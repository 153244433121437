import { ROUTES } from "../constants/api";
import {
  HandleNewErrorResponse,
  HandleNewResponse,
} from "./handlers";
import { deleteMethod, get } from "./api";

/**
 * Class for static methods handling a user's bank accounts.
 */
class BankAccountsApi {
  /**
   * Deletes a bank account.
   * @constructor
   */
  static async DeleteBankAccount(allocationId: number) {
    let url = ROUTES.BANK_ACCOUNTS.DELETE_BANK_ACCOUNT(allocationId);

    try {
      let response = await deleteMethod(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets a bank account disbursement history
   * @constructor
   */
  static async GetDisbursementHistory(allocationId: number) {
    let url = ROUTES.BANK_ACCOUNTS.GET_DISBURSEMENT_HISTORY(allocationId);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }

  /**
   * Gets a list of countries and their currencies available for new bank accounts given user guid.
   * @constructor
   */
  static async GetCountriesCurrencies(userGuid: string) {
    let url = ROUTES.BANK_ACCOUNTS.GET_COUNTRIES_CURRENCIES(userGuid);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (err) {
      return HandleNewErrorResponse(err);
    }
  }
}

export default BankAccountsApi;
