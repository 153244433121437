import React from "react";
import PropTypes from "prop-types";

/// This component is temporary
const style = {
  display: "inline-block",
};

const InputLabel = (props) => {
  return (
    <label
      htmlFor=""
      style={style}
      className={`input-label ${props.hidden ? "sr-only" : ""}`}
    >
      {props.children}
    </label>
  );
};

InputLabel.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node,
};

export default InputLabel;
