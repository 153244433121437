import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field, InjectedFormProps, reduxForm, getFormValues, change, SubmissionError } from 'redux-form';
import { PAGES, COMMON } from "../../constants/localization";
import { overZero } from "../../services/formatter";
import { required, sendAmountValidation, currencyCheck } from "../../services/customValidations";
import MoneyTransferApi from "../../api/moneyTransfer";
import enabledArrow from "../../assets/images/MiniArrow-enabled.svg";
import { GetAvailableCountriesResponse } from "../../types/api/moneyTransfer";
import { Country, Currency } from "../../types/moneyTransfer";
import { RootState } from "../../types/redux";
import { ACTION_DISPLAY_TYPES, TRANSACTION_SERVICE_TYPES } from "../../constants/enums";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

import CountryPicker from "../../components/Common/CountryPicker";
import CurrencyInput from "../../components/Common/CurrencyInput";
import GeneralSelect from "../../components/Common/GeneralSelect";
import Action from "../../components/Common/Action";
import { GetMoneyTransferProviderRates, SetAvailableCountries, SetAvailableProvinces } from "../../actions/moneyTransfer";

type CustomProps = {
  form: string;
  handleError: (error: string) => void;
}

const CountryAndAmount: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = ({ form, handleError, valid, submitting, handleSubmit, error }) => {
  const dispatch = useDispatch();
  const cardCurrency = useSelector((state: RootState) => state.card.currencyCode);
  const formValues = useSelector((state: RootState) => getFormValues(form)(state)) as { destinationCountry: string, destinationProvince: string, destinationCity: string, sendAmount: number, receiveCurrency: string };
  const availableCountries = useSelector((state: RootState) => state.moneyTransfer.availableCountries);
  const availableProvinces = useSelector((state: RootState) => state.moneyTransfer.availableProvinces);
  const rateQuery = useSelector((state: RootState) => state.moneyTransfer.providerRates.rateQuery);
  const serviceType = useSelector((state: RootState) => state.moneyTransfer.workflow.serviceType);
  const [displayProvinces, setDisplayProvinces] = useState(false);
  const [displayCities, setDisplayCities] = useState(false);
  const [availableCities, setAvailableCities] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState<Currency[]>([]);
  const [disabled, setDisabled] = useState(true);

  // On initial render, get available countries
  useEffect(() => {
    const getData = async () => {
      await getCountries();
    }

    getData();

    if (rateQuery?.destinationCountry) {
      const country = getCountryData(rateQuery.destinationCountry);
      populateCurrencies(country);
      // if country has province requirement, show province dropdown and populate with user's previously selected province
      if (rateQuery.destinationProvince) {
        setDisplayProvinces(true);
        dispatch(change(form, "destinationProvince", rateQuery.destinationProvince));

        if (!country.requiresCity) {
          setDisabled(false);
        }
      } else {
        // If country does not need province, then enable buttons
        setDisabled(false);
      }

      // if country has city requirement, show city dropdown and populate with user's previously selected city
      if (rateQuery.destinationCity) {
        setDisplayCities(true);
        setDisabled(false);
        dispatch(change(form, "destinationCity", rateQuery.destinationCity));
      }
    }
  }, []);

  const getCountries = async () => {
    try {
      const response = await MoneyTransferApi.GetCountries(serviceType) as GetAvailableCountriesResponse;
      dispatch(SetAvailableCountries(response));
    } catch (err) {
      handleError(err);
    }
  }

  const getProvinces = async (countryCode: string) => {
    try {
      const response = await MoneyTransferApi.GetAvailableProvinces(countryCode);
      dispatch(SetAvailableProvinces(response));
    } catch (err) {
      handleError(err);
    }
  }

  const getCities = async (countryCode: string, provinceCode) => {
    try {
      const response = await MoneyTransferApi.GetAvailableCities(countryCode, provinceCode);
      const formattedOptions = response.options.map((city: { label: string, value: string }) => {
        return {
          name: city.label,
          iso: city.value
        }
      });
      setAvailableCities(formattedOptions);
    } catch (err) {
      handleError(err);
    }
  }

  const handleCountryChange = async (e) => {
    const country = getCountryData(e);
    populateCurrencies(country);

    // if there is a country
    if (country) {
      // set destinationProvince and destinationCity values to null as country has changed
      dispatch(change(form, "destinationProvince", null));
      dispatch(change(form, "destinationCity", null));

      // if country requiresProvince then get provinces, disable buttons, display province dropdown, and hide city dropdown
      if (country.requiresProvince) {
        await populateProvinces(country);
        setDisabled(true);
        setDisplayProvinces(true);
        setDisplayCities(false);
      } else {
        // if not, enable buttons and hide province and city dropdowns
        setDisabled(false);
        setDisplayProvinces(false);
        setDisplayCities(false);
      }
    } else {
      // if country code is null, set destinationProvince and destinationCity values to null and hide dropdowns
      // Set receiveCurrency to null so that this.props.valid is false and 'Get rates' button is disabled
      dispatch(change(form, "destinationProvince", null));
      dispatch(change(form, "destinationCity", null));
      dispatch(change(form, "receiveCurrency", null));

      setDisabled(true);
      setDisplayProvinces(false);
      setDisplayCities(false);
    }
  }

  /**
   * Handles province dropdown change handler
   * takes in province code and handles if country requires city, gets city data for province, and sets requiresCity: true
   * @param {string} provinceCode: Province code
   */
  const handleProvinceChange = async (provinceCode) => {
    const country = getCountryData(formValues.destinationCountry);
    if (country.requiresCity) {
      // if provinceCode is null (user has clicked the x) then hide city dropdown and disable buttons
      if (!provinceCode) {
        setDisplayCities(false);
        setDisabled(true);
      } else {
        // if provinceCode is not null, get available cities for province, enable buttons, and display city dropdown
        await getCities(country.iso, provinceCode);

        dispatch(change(form, "destinationCity", null));
        setDisplayCities(true);
        setDisabled(true);
      }
    } else if (!provinceCode) {
      // if country doesn't require city, but provinceCode is null, disable buttons
      setDisabled(true);
    } else {
      // if provinceCode is not null, enable buttons
      setDisabled(false);
    }
  }

  const handleCityChange = (cityCode) => {
    // if cityCode is null (user has clicked the x) then disable buttons
    setDisabled(!cityCode);
  }

  /**
   * Given a country code, get available currencies and populate the collection. This collection is used to fill the dropdown.
   * @param {object} country: country data 
   */
  const populateCurrencies = (country: Country) => {
    let currencies = [];
    if (country) {
      currencies = country.currencies.map((currency: Currency) => {
        return {
          label: currency.iso,
          value: currency.iso
        }
      });

      const currencyCode = country.currencies[0].iso;
      dispatch(change(form, "receiveCurrency", currencyCode));
    }

    setCurrencyOptions(currencies);
  }

  /**
   * Returns country data from availableCountries list by country code
   * @param {string} countryCode
   */
  const getCountryData = (countryCode: string) => {
    return availableCountries.filter(
      (c) => c.iso === countryCode
    )[0];
  }

  /**
   * Given a country code, if province required, get available provinces and populate the collection. This collection is used to fill the dropdown.
   * @param {any} country: country data
   */
  const populateProvinces = async (country) => {
    if (country?.requiresProvince) {
      setDisplayProvinces(true);
      await getProvinces(country.iso);
    } else {
      setDisplayProvinces(false);
    }
  }

  const onSubmit = async (e) => {
    try {
      const response = await MoneyTransferApi.GetProviderRates(e.sendAmount, e.receiveCurrency, e.destinationCountry, e.destinationProvince, e.destinationCity, serviceType);

      dispatch(GetMoneyTransferProviderRates({
        destinationCountry: e.destinationCountry,
        destinationCountryName: getCountryData(e.destinationCountry).name,
        destinationProvince: e.destinationProvince,
        destinationCity: e.destinationCity,
        sendAmount: e.sendAmount,
        receiveCurrency: e.receiveCurrency,
        providerRates: response
      }))

      if (serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT) {
        trackEvent(EVENTS.TO_ACCOUNT.CORRIDOR_SELECTED, {
          [EVENTS.PARAMS.WU_TO_ACCOUNT_CORRIDOR_SELECTED_VALUE]: `${e.destinationCountry}-${e.receiveCurrency}`,
          [EVENTS.PARAMS.WU_TO_ACCOUNT_TRANSFER_AMOUNT]: e.sendAmount
        });
      }

    } catch (err) {
      if (err?.data?.errors) {
        throw new SubmissionError({ _error: err.data.errors[0].errorMessage });
      } else {
        throw new SubmissionError({ _error: PAGES.MONEY_TRANSFER.VALIDATIONS.RATES_NOT_AVAILABLE });
      }
    }
  }

  /**
   * Display errors
   * @param {*} errors
   */
  const renderError = (error: string) => {
    return <div className="validation-error">{error}</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p>{PAGES.MONEY_TRANSFER.RATE_LIST.WHERE_TO_SEND}</p>
      <div id="country-amount-form">
        <div className="form-group spacing-top-medium">
          <label>{PAGES.MONEY_TRANSFER.RECEIVING_COUNTRY}</label>
          <Field
            type="select"
            component={CountryPicker}
            props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_COUNTRY }}
            name="destinationCountry"
            placeholder={COMMON.SELECT_COUNTRY}
            disabled={submitting || availableCountries.length === 0}
            countries={availableCountries}
            onChange={handleCountryChange}
            autoFocus
          />
        </div>

        {displayProvinces && availableProvinces.length > 0 &&
          <div className="form-group">
            <label>{PAGES.MONEY_TRANSFER.RECEIVING_PROVINCE}</label>
            <Field
              type="select"
              component={CountryPicker}
              props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_PROVINCE }}
              name="destinationProvince"
              placeholder={COMMON.SELECT}
              disabled={submitting}
              countries={availableProvinces}
              onChange={handleProvinceChange}
              autoFocus
            />
          </div>
        }

        {displayCities && availableCities.length > 0 &&
          <div className="form-group">
            <label>{PAGES.MONEY_TRANSFER.RECEIVING_CITY}</label>
            <Field
              type="select"
              component={CountryPicker}
              props={{ dataCy: PAGES.MONEY_TRANSFER.RECEIVING_CITY }}
              name="destinationCity"
              placeholder={COMMON.SELECT}
              disabled={submitting}
              countries={availableCities}
              onChange={handleCityChange}
              autoFocus
            />
          </div>
        }

        <div className={"amount-row" + (disabled ? " disabled" : "")}>
          <div className={"form-group"}>
            <label>{PAGES.MONEY_TRANSFER.AMOUNT_TO_SEND}</label>
            <Field
              type="number"
              normalize={overZero}
              component={CurrencyInput}
              decimalPlaces={2}
              currencyIso={cardCurrency}
              disabled={disabled}
              name="sendAmount"
              validate={[required, sendAmountValidation]}
              warn={sendAmountValidation}
              aria-describedby="basic-addon2"
            />
          </div>

          <div className="right-arrow">
            <img alt="arrow" src={enabledArrow} />
          </div>

          <div className="form-group no-label">
            <Field
              name="receiveCurrency"
              component={GeneralSelect}
              disabled={disabled}
              validate={[required, currencyCheck]}
              placeholder={""}
              options={currencyOptions}
            />
          </div>
        </div>

        {error && renderError(error)}

        <div className={`row actions ${valid ? "error" : ""}`}>
          <div className="col-sm-7">
            <Action
              className="get-rates-button"
              type="submit"
              title={PAGES.MONEY_TRANSFER.RATE_LIST.GET_RATE}
              disabled={!valid}
              displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              loading={submitting}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

const form = reduxForm<{}, CustomProps>({
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true,
})(CountryAndAmount);

export default form;
