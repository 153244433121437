/* eslint-disable import/default */
if (!window.crypto) {
  window.crypto = window.msCrypto || {
    getRandomValues: (array) => {
      for (let i = 0, l = array.length; i < l; i++) {
        array[i] = Math.floor(Math.random() * 256);
      }
      return array;
    },
  };
}
