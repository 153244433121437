import { DISPLAY_LOADING, HIDE_LOADING } from "../constants/actionTypes";
import initialState from "./initialState";

export default function spinnersInProgressReducer(
  state = initialState.spinnersInProgress,
  action
) {
  switch (action.type) {
    case DISPLAY_LOADING:
      return state + 1;
    case HIDE_LOADING:
      return state - 1;
    default:
      return state;
  }
}
