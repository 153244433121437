import * as React from 'react';
import Modal from '../../Common/Modal';
import { COMMON, PAGES } from '../../../constants/localization';
import { CompanionCardRecipientType } from '../../../types/api/card';
import { ACTION_DISPLAY_TYPES } from '../../../constants/enums';

type DeleteRecipientModalContainerType = {
  showAreYouSureModal: boolean;
  setShowAreYouSureModal: React.Dispatch<boolean>;
  recipient: CompanionCardRecipientType;
  handleDeleteRecipient: Function;
}

const DeleteRecipientModalContainer = ({ showAreYouSureModal, setShowAreYouSureModal, recipient, handleDeleteRecipient }: DeleteRecipientModalContainerType) => {

  const handleClick = () => {
    setShowAreYouSureModal(false);
    handleDeleteRecipient(recipient.recipientId)
  }

  const actions = [
    {
      title: COMMON.GO_BACK,
      onClick: () => setShowAreYouSureModal(false),
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
      dontDisplayLoading: true
    },
    {
      title: COMMON.YES_DELETE,
      onClick: () => handleClick(),
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      className: "warning"
    },
  ];

  return (
    <Modal
      onClose={() => setShowAreYouSureModal(false)}
      open={showAreYouSureModal}
      title={PAGES.MY_CARD.CARD_TO_CARD_TRANSFER.ARE_YOU_SURE_TITLE}
      content={`Are you sure you want to delete ${recipient.name}?`}
      small={true}
      className="small-form left-aligned"
      actions={actions}
    />
  );
}

export default DeleteRecipientModalContainer;