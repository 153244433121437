import { GLOBAL } from "../constants/actionTypes";
import initialState from "./initialState";

export default function authReducer(state = initialState.global, action) {
  switch (action.type) {
    case GLOBAL.RETURN_URL:
      return Object.assign({}, state, {
        ...state,
        returnUrl: action.payload,
      });
    case GLOBAL.APP_STATE:
      return Object.assign({}, state, {
        ...state,
        appState: action.payload,
      });
    case GLOBAL.BROWSER_SUPPORTED:
      return Object.assign({}, state, {
        ...state,
        browserSupported: action.payload,
      });
    case GLOBAL.SET_IS_INERT: {
      return {
        ...state,
        isInert: action.payload,
      }
    }
    case GLOBAL.SET_EXTERNAL_LINKS_MODAL_OPEN: {
      return {
        ...state,
        externalLinksModal: {
          open: action.payload.open,
          url: action.payload.url,
        }
      }
    }
    case GLOBAL.SET_ERROR_MODAL: {
      return {
        ...state,
        errorModal: {
          open: action.payload.open,
          errorMessage: action.payload.errorMessage,
        }
      }
    }
    default:
      return state;
  }
}
