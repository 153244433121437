import { CATALOG } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function countries(
  state = initialState.catalog.countries,
  action
) {
  switch (action.type) {
    case CATALOG.GET_COUNTRIES: {
      let countries = [];
      try {
        countries = action.countries.slice();
      } catch (err) {
        countries = [];
      }

      return countries;
    }
    default:
      return state;
  }
}
