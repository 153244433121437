import React from "react";
import PropTypes from "prop-types";
import _Modal from "../Modal";
import { COMMON } from "../../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import { ROUTES } from "../../../constants/clientRoutes";

const ModalActions = (props) => {
  return [
    {
      title: COMMON.BACK_TO_LOGIN,
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
      onClick: () => (location.href = ROUTES.EXTERNAL.LOGIN),
    },
    {
      title: COMMON.CONTACT_SUPPORT,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      onClick: () =>
        (location.href = props.hasSupport
          ? ROUTES.EXTERNAL.SUPPORT
          : ROUTES.EXTERNAL.SUPPORT_OLD),
    },
  ];
};

const ContactSupportModal = (props) => {
  return (
    <_Modal
      open={props.open}
      title={props.title}
      content={props.content}
      actions={ModalActions(props)}
      onClose={props.onClose}
    />
  );
};

ContactSupportModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
  hasSupport: PropTypes.bool,
};

export default ContactSupportModal;
