import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorService from "../../../services/errorService";

class BaseFormControl extends Component {
  constructor(props) {
    super(props);
  }

  inputHasError = () => {
    if (!this.props.errors) return null;
    let fieldErrors = ErrorService.GetFieldErrors(this.props.errors);
    if (!fieldErrors) return null;
    let currentFieldErrors = fieldErrors.filter(
      (error) => error.fieldId == this.props.inputId
    );
    return currentFieldErrors.length ? "has-error" : null;
  };

  render() {
    const { className, children } = this.props;
    const hasError = this.inputHasError();
    return (
      <div className={`form-group ${className} ${hasError}`.trim()}>
        {children}
      </div>
    );
  }
}

BaseFormControl.propTypes = {
  errors: PropTypes.array,
  inputId: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps)(BaseFormControl);
