import React, { useEffect, useState } from 'react';
import i18n from '../../i18n/config';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import { ACTION_DISPLAY_TYPES } from '../../constants/enums';
import { ROUTES } from '../../constants/clientRoutes';
import { useHistory } from 'react-router-dom';
import { formatWithDecimals, getCurrencyString } from '../../services/formatter';

import { ODTContainerProps } from '../../types/props';
import OnDemandTransferApi from '../../api/onDemandTransfer';
import { GetDoddFrankDisbursementResponse } from '../../types/api/odt';
import { UpdateDoddFrankDisbursement } from '../../actions/onDemandTransfer';

const ExchangeRate = ({ props, handleSubmit }: { props: ODTContainerProps, handleSubmit: () => void }) => {
  const dispatch = useDispatch();
  const { setTitle, setBackButton, setForwardButton, setDisabled, setButtonLoading, setErrorMessage, setShowErrorModal } = props;
  const history = useHistory();
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);
  const cardCurrencyCode = useSelector((state: RootState) => state.card.currencyCode);
  const [liftingFeeChecked, setLiftingFeeChecked] = useState(!onDemandTransfer.isLiftingFee);
  const [transferNowChecked, setTranferNowChecked] = useState(onDemandTransfer.isDoddFrank);

  const getDoddFrankDisbursement = async () => {
    setButtonLoading(true);

    try {
      const response = await OnDemandTransferApi.GetDoddFrankDisbursement({
        allocationId: onDemandTransfer.allocationId,
        serviceProviderId: onDemandTransfer.providerId,
        transferAmount: onDemandTransfer.transferAmount,
        purposeOfPaymentCustom: onDemandTransfer.purposeOfPaymentCustom,
        purposeOfPaymentSelected: onDemandTransfer.purposeOfPaymentSelected,
        purposeOfPaymentId: onDemandTransfer.purposeOfPaymentId,
        transferFee: onDemandTransfer.feeAmount,
        providerReferenceId: onDemandTransfer.providerReferenceId,
        fxRate: onDemandTransfer.exchangeRate.rate
      }) as GetDoddFrankDisbursementResponse;

      dispatch(UpdateDoddFrankDisbursement(response));
      setButtonLoading(false);
      history.push(ROUTES.ODT.TRANSFER_DETAILS);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setShowErrorModal(true);
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    // Set title
    setTitle(i18n.t("PAGES.ODT.EXCHANGE_RATE.TITLE"));
    // Set forward button
    setForwardButton({
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      title: onDemandTransfer.isDoddFrank ? i18n.t("COMMON.NEXT") : i18n.t("COMMON.TRANSFER_NOW"),
      onClick: onDemandTransfer.isDoddFrank ? () => getDoddFrankDisbursement() : handleSubmit
    });
    setBackButton({
      onClick: () => history.push(ROUTES.ODT.AMT_PURPOSE),
    });
  }, []);

  useEffect(() => {
    setDisabled(!liftingFeeChecked || !transferNowChecked);
  }, [liftingFeeChecked, transferNowChecked]);

  return (
    <div className="odt-exchange-rates-page">
      <div className="max-w-360">
        <div className="flex-row justify-content-between">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.TRANSFER_TO")}</p>
          <p>{onDemandTransfer.destinationCountryName}</p>
        </div>

        <div className="flex-row justify-content-between">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.AMOUNT")}</p>
          <p>{formatWithDecimals(onDemandTransfer.transferAmount, cardCurrencyCode)} {cardCurrencyCode}</p>
        </div>

        <div className="flex-row justify-content-between">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.TRANSFER_FEE")}</p>
          <p>
            {formatWithDecimals(onDemandTransfer.feeAmount, cardCurrencyCode)} {cardCurrencyCode}
          </p>
        </div>

        <div className="flex-row justify-content-between">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.BENEFICIARY_RECEIVES")}</p>
          <p>{onDemandTransfer.beneficiaryReceives}</p>
        </div>

        <div className="flex-row justify-content-between">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.CURRENCY")}</p>
          <p>{getCurrencyString(cardCurrencyCode)}</p>
        </div>

        <div className="flex-row justify-content-between spacing-bottom-medium">
          <p>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LABELS.CURRENT_EXCHANGE_RATE")}</p>
          <p>{onDemandTransfer.formattedExchangeRate}</p>
        </div>
      </div>

      <ul>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_1")}</li>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_2")}</li>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_3")}</li>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_4")}</li>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_5")}</li>
        <li>{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIST.ITEM_6")}</li>
      </ul>

      {onDemandTransfer.isLiftingFee &&
        <div className="checkbox spacing-top-small">
          <input
            type="checkbox"
            id="liftingFee"
            onChange={() => setLiftingFeeChecked(!liftingFeeChecked)}
          />{" "}
          <label htmlFor="liftingFee">{i18n.t("PAGES.ODT.EXCHANGE_RATE.LIFTING_FEE_LABEL")}</label>
        </div>
      }

      {!onDemandTransfer.isDoddFrank &&
        <div className="checkbox">
          <input
            type="checkbox"
            id="transferNow"
            onChange={() => setTranferNowChecked(!transferNowChecked)}
          />{" "}
          <label htmlFor="transferNow">{i18n.t("PAGES.ODT.EXCHANGE_RATE.TRANSFER_NOW_LABEL")}</label>
        </div>
      }
    </div>
  );
}

export default ExchangeRate;