import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { COMMON, PAGES } from "../../constants/localization";
import ActionLink from "../../components/Common/ActionLink";
import nextIcon from "../../assets/images/icons/RightArrow_White.svg";
import DynamicForm from "../../components/MoneyTransfer/DynamicForm";
import { reduxForm } from "redux-form";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import FooterLinks from "../../components/MoneyTransfer/FooterLinks";
import DisplaySection from "../../components/MoneyTransfer/DisplaySection";

class DynamicFormWrapper extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  renderFormErrors(errors) {
    if (errors && errors.length > 0) {
      return (
        <ul className="bullet-list error-list">
          {errors.map((error, index) => (
            <li key={index}>{error.errorMessage}</li>
          ))}
        </ul>
      );
    }
  }

  /**
   * Page that displays bw dynamic form data for MoneyTransfer flow
   * as additional info the provider may need
   * @param fields
   * @returns {*}
   * @constructor
   */
  render() {
    const { handleSubmit, invalid, submitting, error, pageData } = this.props;

    return (
      <div>
        {pageData &&
          <p className="large spacing-bottom-small">
            {pageData.subTitle}
          </p>
        }


        {pageData &&
          <DisplaySection sections={pageData.displaySections} />
        }

        <form onSubmit={handleSubmit}>
          {this.renderFormErrors(error)}

          {pageData && (
            <DynamicForm fieldCategory={pageData} formName={PAGES.MONEY_TRANSFER.FORM_NAME} />
          )}

          <div className="row navRow">
            <div className="col-xs-12 col-sm-6 two">
              <ActionLink clickFunc={this.props.onBack} classes="mt-link">
                <div className="glyphicon glyphicon-menu-left link_icon"></div>
                {COMMON.BACK}
              </ActionLink>
            </div>
            <div className="col-xs-12 col-sm-6 button-cell one">
              <Action
                type="submit"
                loading={submitting}
                disabled={
                  invalid
                }
                title={COMMON.NEXT}
                iconRight={nextIcon}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
              />
            </div>
          </div>

          {this.props.workflow.footer &&
            <FooterLinks disclaimerLinks={this.props.workflow.footer.disclaimerLinks} />
          }

        </form>
      </div>
    );
  }
}

DynamicFormWrapper.propTypes = {
  onBack: PropTypes.func.isRequired,
  user: PropTypes.object,
  handleSubmit: PropTypes.func,
  workflow: PropTypes.object,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  error: PropTypes.array,
  pageData: PropTypes.object
};

function mapStateToProps(state, props) {
  var workflow = state.moneyTransfer.workflow;
  // Set up initial values for redux-form based on sections.fields that we get back from api

  // if props.pageData is undefined or null, don't set initialValues for form
  // Will cause issues if user goes from MG back to provider rates to WU
  // Because we dispatch destroy from MoneyTransfer parent component, however that action is dispatched before DynamicFormWrapper is unmounted, 
  // So the form is destroyed, then re-initialized here, then the component is unmounted.
  if (props.pageData && props.pageData.sections) {
    const initialValues = {};
    props.pageData.sections.forEach(section => {
      section.fields.forEach(field => {
        initialValues[field.serviceProviderFieldId] = field.value
      })
    })

    return {
      workflow: workflow,
      initialValues: initialValues
    };
  }

  return {
    workflow: workflow,
  }
}

export default connect(
  mapStateToProps,
  {}
)(
  reduxForm({
    form: PAGES.MONEY_TRANSFER.FORM_NAME,
    destroyOnUnmount: false,
  })(DynamicFormWrapper)
);