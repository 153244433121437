import {
  HandleNewResponse,
  HandleNewErrorResponse
} from "./handlers";
import { ROUTES } from "../constants/api";
import { get } from "./api";

/**
 * Class for static methods handling dashboard calls
 */
class DashboardApi {
  /**
   * Get's the user's news items.
   * @returns {Promise<void>} user's news
   * @constructor
   */
  static async GetNews() {
    try {
      let response = await get(ROUTES.USER.GET_NEWS);

      return HandleNewResponse(response);
    } catch (err) {

      return HandleNewErrorResponse(err);
    }
  }
}

export default DashboardApi;