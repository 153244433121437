import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../types/redux";
import { COMMON, PAGES } from "../../constants/localization";
import RateCard from "../../components/MoneyTransfer/RateCard";
import ActionLink from "../../components/Common/ActionLink";
import Restrictions from "../../components/MoneyTransfer/Restrictions";
import { GetMoneyTransferProviderRates, GetMoneyTransferWorkflow, SetWorkflowPageNumber } from "../../actions/moneyTransfer";
import { MONEY_TRANSFER_PAGES, RATE_CARD_FIELDS } from "../../constants/enums";
import { DisplaySectionFieldType, GetWorkflowResponse } from "../../types/api/moneyTransfer";
import { MoneyTransferPage, Provider, WorkflowStep } from "../../types/moneyTransfer";
import MoneyTransferApi from "../../api/moneyTransfer";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

import TelemarketerSalesRule from "../../components/MoneyTransfer/TelemarketerSalesRule";
import DynamicFormWrapper from "./DynamicFormWrapper";
import StaticHTMLPage from "./StaticHTMLPage";

const RatesDisplay = ({ pageNumber, handleError }: { pageNumber: number, handleError: (error: string) => void; }) => {
  const dispatch = useDispatch();
  const moneyTransfer = useSelector((state: RootState) => state.moneyTransfer);
  const formValues = useSelector((state: RootState) => state.form.moneytransfer.values);
  const [telemarketerSalesRule, setTelemarketerSalesRule] = useState<string>('');
  const [showTelemarketerSalesRule, setShowTelemarketerSalesRule] = useState<boolean>(false);
  const [quoteId, setQuoteId] = useState<number>(0);
  const cardCurrency = useSelector((state: RootState) => state.card.currencyCode);
  const [loading, setLoading] = useState(false);

  /**
   * Updates the page number in the redux store to navigate to the previous page
   */
  const onBack = () => {
    dispatch(SetWorkflowPageNumber(pageNumber - 1));
  }

  /**
   * Updates the page number in the redux store to navigate to the next page
   */
  const onNext = () => {
    dispatch(SetWorkflowPageNumber(pageNumber + 1));
  }

  /**
   * Returns country data from availableCountries list by country code
   * @param {string} countryCode
   */
  const getCountryData = (countryCode: string) => {
    return moneyTransfer.availableCountries.filter(
      (c) => c.iso === countryCode
    )[0];
  }

  /**
   * If user clicks 'X' button on applied promo code, set loading state, re-fetch provider rates, and then remove loading state
   */
  const handleResetProviders = async () => {

    try {
      const { sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity } = formValues;
      const response = await MoneyTransferApi.GetProviderRates(sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity, moneyTransfer.workflow.serviceType);

      dispatch(GetMoneyTransferProviderRates({
        destinationCountry,
        destinationCountryName: getCountryData(destinationCountry).name,
        destinationProvince,
        destinationCity,
        sendAmount,
        receiveCurrency,
        providerRates: response
      }))

    } catch (err) {
      handleError(err);
    }
  }

  const GetProviderAndQuote = (quoteId: number) => {
    for (let provider of moneyTransfer.providerRates.providerRates) {
      for (let quote of provider.quotes) {
        if (quote.quoteId === quoteId) {
          return {
            provider,
            quote
          }
        }
      }
    }
  }

  const addDynamicFormStep = (workflowPages: WorkflowStep[], pageData: MoneyTransferPage) => {
    workflowPages.push({
      title: pageData.title,
      type: pageData.id,
      number: workflowPages.length + 1,
      order: workflowPages.length + 1,
      renderComponent: () => (
        <DynamicFormWrapper
          key={pageData.id}
          pageData={pageData}
          handleError={handleError}
        />
      ),
    });
  }

  const addStaticHTMLPageWorkflowStep = (workflowPages: WorkflowStep[], pageData: MoneyTransferPage) => {
    workflowPages.push({
      title: pageData.title,
      type: pageData.id,
      number: workflowPages.length + 1,
      order: workflowPages.length + 1,
      renderComponent: () => (
        <StaticHTMLPage
          pageData={pageData}
        />
      ),
    });
  };

  /**
   * Builds out the workflow pages based on data in the workflow state object
   */
  const setupWorkflow = (pages: MoneyTransferPage[]) => {
    const workflowPages = [...moneyTransfer.workflow.pages];

    pages.forEach(page => {
      switch (page.type) {
        case MONEY_TRANSFER_PAGES.DYNAMIC_FORM:
          addDynamicFormStep(workflowPages, page);
          break;
        case MONEY_TRANSFER_PAGES.STATIC_HTML:
          addStaticHTMLPageWorkflowStep(workflowPages, page);
          break;
      }
    })

    return workflowPages;
  }

  const handleQuoteSelected = async (quoteId: number) => {
    setQuoteId(quoteId);
    const { provider } = GetProviderAndQuote(quoteId);

    if (provider.telemarketerSalesRule) {
      setTelemarketerSalesRule(provider.telemarketerSalesRule);
      setShowTelemarketerSalesRule(true);
    } else {
      await handleGetWorkflow(quoteId);
    }
  }

  const handleGetWorkflow = async (quoteId: number) => {
    setLoading(true);
    try {
      const response = await MoneyTransferApi.GetWorkflow(quoteId, moneyTransfer.workflow.serviceType) as GetWorkflowResponse;
      const workflowPages = setupWorkflow(response.pages);

      dispatch(GetMoneyTransferWorkflow(quoteId, {
        pages: workflowPages,
        footer: response.footer,
        errors: response.errors
      }));

      const { quote } = GetProviderAndQuote(quoteId);

      trackEvent(EVENTS.TO_ACCOUNT.EXCHANGE_RATE_COMPLETED, {
        [EVENTS.PARAMS.WU_TO_ACCOUNT_SEND_AMOUNT]: quote.fields.find((f: DisplaySectionFieldType) => f.id === RATE_CARD_FIELDS.SEND_AMOUNT).formattedValue,
        [EVENTS.PARAMS.WU_TO_ACCOUNT_RECEIVE_AMOUNT]: quote.fields.find((f: DisplaySectionFieldType) => f.id === RATE_CARD_FIELDS.TOTAL_RECEIVE_AMOUNT).formattedValue,
        [EVENTS.PARAMS.WU_TO_ACCOUNT_FEE_AMOUNT]: quote.fields.find((f: DisplaySectionFieldType) => f.id === RATE_CARD_FIELDS.FEE).formattedValue,
        [EVENTS.PARAMS.WU_TO_ACCOUNT_TOTAL_COST]: quote.fields.find((f: DisplaySectionFieldType) => f.id === RATE_CARD_FIELDS.TOTAL_COST).formattedValue,
      })

      onNext();
      setLoading(false);
    } catch (err) {
      handleError(err);
      setLoading(false);
    }
  }

  let parsedSend = parseFloat(moneyTransfer.providerRates.rateQuery.sendAmount).toFixed(2);

  const rateList = moneyTransfer.providerRates.providerRates.map((provider: Provider) => {
    const rate = provider.quotes[0];
    return (
      <RateCard
        onSelectedQuote={handleQuoteSelected}
        providerName={provider.name}
        fields={rate ? rate.fields : null}
        promoCodeAvailable={rate ? rate.promoCodeAvailable : null}
        logo={provider.logo}
        providerId={provider.providerId} //This will need to be generated for multiple provider rates
        quoteId={rate ? rate.quoteId : null}
        loading={loading}
        handleResetProviders={handleResetProviders}
        key={provider.providerId}
        disableButton={
          moneyTransfer.restrictions && moneyTransfer.restrictions.length > 0
        }
        errors={provider.errors}
      />
    );
  });

  const disclaimerList = moneyTransfer.providerRates.disclaimers.map((d, i) => {
    if (d.superscript) {
      return (
        <div className="disclaimer" key={d.disclaimerId}>
          <sup>{d.superscript}</sup> {d.text}
        </div>
      );
    } else {
      return (
        <div className="disclaimer" key={d.disclaimerId}>
          {d.text}
        </div>
      );
    }
  });

  const handleTelemarketerSalesRulePassed = () => {
    setShowTelemarketerSalesRule(false);
    handleGetWorkflow(quoteId);
  }

  return (
    <div>
      <Restrictions restrictions={moneyTransfer.restrictions} />
      <p className="bold large">
        {PAGES.MONEY_TRANSFER.FORMATS.SENDING_TO(
          parsedSend,
          cardCurrency,
          moneyTransfer.providerRates.rateQuery.receiveCurrency,
          moneyTransfer.providerRates.rateQuery.destinationCountryName
        )}
      </p>
      <hr />
      <div>
        {moneyTransfer.providerRates.length > 1 && (
          <p>{PAGES.MONEY_TRANSFER.RATE_LIST.SELECT_ONE_OF}</p>
        )}
        <div className="rate-list">{rateList}</div>
      </div>
      <div className="spacing-top-default">{disclaimerList}</div>
      <div className="spacing-top-medium">
        <ActionLink
          clickFunc={onBack}
          classes={"mt-link" + (loading && " disabled")}
        >
          <div className="glyphicon glyphicon-menu-left link_icon"></div>
          {COMMON.BACK}
        </ActionLink>
      </div>

      {showTelemarketerSalesRule &&
        <TelemarketerSalesRule
          show={showTelemarketerSalesRule}
          rule={telemarketerSalesRule}
          onRulePassed={handleTelemarketerSalesRulePassed}
          onRuleFailed={() => setShowTelemarketerSalesRule(false)}
        />
      }
    </div>
  );
}

export default RatesDisplay;
