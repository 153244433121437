import React from "react";
import PropTypes from "prop-types";
import IntegerInput from "../../Common/IntegerInput";
import { PAGES } from "../../../constants/localization";
import MaskedInput from "react-maskedinput";

const FormatPatternText = (pattern) => {
  return pattern.replace(/x/g, "1");
};

/**
 * Renders an integer field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const PinField = ({ ...props }) => {
  if (props.pattern != null) {
    return (
      <MaskedInput
        mask={FormatPatternText(props.pattern)}
        {...props.events}
        {...props.input}
        value={props.pin}
        onPaste={(e) => {
          e.preventDefault();
        }}
        placeholder={
          props.placeholderText != null
            ? props.placeholderText
            : PAGES.PIN_RESET.PIN_INPUT_PLACEHOLDER
        }
        autoFocus={props.autoFocus}
        maxLength={PAGES.PIN_RESET.PIN_LENGTH}
        autoComplete="off"
        disabled={props.disabled}
        inputMode="numeric"
        className="form-control"
      />
    );
  } else {
    return (
      <IntegerInput
        {...props.events}
        {...props.input}
        value={props.pin}
        onPaste={(e) => {
          e.preventDefault();
        }}
        placeholder={
          props.placeholderText != null
            ? props.placeholderText
            : PAGES.PIN_RESET.PIN_INPUT_PLACEHOLDER
        }
        autoFocus={props.autoFocus}
        maxLength={PAGES.PIN_RESET.PIN_LENGTH}
        autoComplete="off"
        disabled={props.disabled}
        inputMode="numeric"
        type="password"
        className="form-control"
        data-cy={props.dataCy}
      />
    );
  }
};

PinField.propTypes = {
  input: PropTypes.any,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  events: PropTypes.any,
  pin: PropTypes.string,
  pattern: PropTypes.string,
  placeholderText: PropTypes.string,
  dataCy: PropTypes.string,
};

export default PinField;
