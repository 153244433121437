import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../types/redux';
import i18n from '../../i18n/config';
import { useHistory } from 'react-router-dom';
import { PROVIDERS } from '../../constants/localization';
import { ACTION_DISPLAY_TYPES, ICONS, ON_DEMAND_TRANSFER_PROVIDERS } from '../../constants/enums';
import ENV from '../../constants/environment';
import { ROUTES } from '../../constants/clientRoutes';
import { ODTContainerProps } from '../../types/props';

import BankBeneficiarySections from '../../components/ODT/BankBeneficiarySections';
import Alert from '../../components/Common/Alerts/Alert';

const ODTTransferDetails = ({ props, handleSubmit }: { props: ODTContainerProps, handleSubmit: () => void }) => {
  const history = useHistory();
  const { setTitle, setBackButton, setForwardButton, setDisabled } = props;
  const onDemandTransfer = useSelector((state: RootState) => state.onDemandTransfer);
  const [transferNowChecked, setTranferNowChecked] = useState(false);

  useEffect(() => {
    // Set title
    setTitle(i18n.t("PAGES.ODT.TRANSFER_DETAILS.TITLE"));
    // Set back button
    setBackButton({
      onClick: () => history.push(ROUTES.ODT.EXCHANGE_RATE),
    });
    // Set forward button
    setForwardButton({
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      title: i18n.t("COMMON.TRANSFER_NOW"),
      onClick: handleSubmit,
    });
  }, []);

  useEffect(() => {
    setDisabled(!transferNowChecked);
  }, [transferNowChecked]);

  const date = new Date();
  const dateString = new Intl.DateTimeFormat(navigator.language).format(date);

  return (

    <div className="odt-transfer-details-page">

      <Alert
        alertClasses="alert alert__bkgrnd-blue alert-info flex-row align-items-center"
        icon={ICONS.INFO_WHITE}
        iconClasses="spacing-right-tiny"
        isHTML={false}
        message={i18n.t("PAGES.ODT.TRANSFER_DETAILS.ALERT_SCROLL")}
      />

      {onDemandTransfer.providerId === ON_DEMAND_TRANSFER_PROVIDERS.CONVERA_WUBS &&

        <div className="marengo-grey">
          {/* Address */}
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_1}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_2}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_3}</p>
          <p>{PROVIDERS.ODT.CONVERA.ADDRESS.LINE_4}</p>

          {/* Date */}
          <p>{i18n.t("COMMON.DATE").toUpperCase()} - {dateString}</p>

          <p><span className="bold">{i18n.t("PAGES.ODT.TRANSFER_DETAILS.NOTICE.BOLD_TEXT")}</span>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.NOTICE.TEXT")}</p>
        </div>

      }

      <BankBeneficiarySections sections={onDemandTransfer.displaySections} />

      <div className="marengo-grey">
        {/* Terms and Conditions */}
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P1")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P2")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P3")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P4")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P5")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P6")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P7")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P8")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.TERMS.P9")}</p>

        {/* Fraud Warning */}
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.HEADING")}</p>
        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.DESCRIPTION")}</p>
        <ul>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_1")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_2")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_3")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_4")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_5")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_6")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_7")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_8")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_9")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_10")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_11")}</li>
          <li>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LIST.ITEM_12")}</li>
        </ul>

        <p>{i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.P1")}</p>
        <p>
          {i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.P2")}
          <a href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.SUPPORT_INDEX}`}>
            {i18n.t("PAGES.ODT.TRANSFER_DETAILS.FRAUD_WARNING.LINK_TEXT")}
          </a>
        </p>
      </div>

      <div className="checkbox spacing-top-medium">
        <input
          type="checkbox"
          id="transferNow"
          onChange={() => setTranferNowChecked(!transferNowChecked)}
        />{" "}
        <label htmlFor="transferNow">{i18n.t("PAGES.ODT.EXCHANGE_RATE.TRANSFER_NOW_LABEL")}</label>
      </div>
    </div>
  );
}

export default ODTTransferDetails;