import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class SessionEndModal extends React.Component {
  constructor(props) {
    super(props);
    this.closeNo = this.closeNo.bind(this);
    this.closeYes = this.closeYes.bind(this);
    this.tick = this.tick.bind(this);
    this.state = {
      showModal: this.props.show,
      secondsRemaining: 0,
    };
  }
  tick() {
    const timeRemaining = this.state.secondsRemaining - 1;
    const show = timeRemaining <= this.props.showWithSecondsRemaining;
    this.setState({ secondsRemaining: timeRemaining, showModal: show });
    if (timeRemaining <= 0) {
      clearInterval(this.interval);
      this.props.history.push("/");
    }
  }
  componentDidMount() {
    this.setState({ secondsRemaining: this.props.secondsRemaining });
    this.interval = setInterval(this.tick, 1000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  closeNo() {
    this.setState({
      showModal: false,
      secondsRemaining: this.props.secondsRemaining,
    });
    this.interval = setInterval(this.tick, 1000);
  }
  closeYes() {
    this.setState({ showModal: false });
    this.props.history.push("/");
  }
  render() {
    return (
      <Modal show={this.state.showModal} backdrop="static">
        <Modal.Header>
          <Modal.Title>Session Ending</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You will be automatically logged out in {this.state.secondsRemaining}.
          <br />
          <br />
          Your session is about to expire.
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button bsStyle="info" onClick={this.closeNo}>
              Stay Connected
            </Button>
            <Button bsStyle="danger" onClick={this.closeYes}>
              Log Out Now
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

SessionEndModal.defaultProps = {
  secondsRemaining: 1200,
  showWithSecondsRemaining: 60,
  show: false,
};

SessionEndModal.propTypes = {
  show: PropTypes.bool,
  secondsRemaining: PropTypes.number,
  showWithSecondsRemaining: PropTypes.number,
  /** react-router history */
  history: PropTypes.any.isRequired,
};

export default withRouter(SessionEndModal);
