import CardApi from "../api/card";
import { CARD } from "../constants/actionTypes";
import { REQUEST_STATUS } from "../constants/enums";
import { CardTransaction } from "../types/api/card";

export function UpdateUserCards(payload) {
  return (dispatch) => {
    dispatch({
      type: CARD.RECEIVE_USER_CARDS_SUCCESS,
      cards: payload,
    });
    dispatch(UpdateCardRequestStatus(REQUEST_STATUS.Complete));
  };
}

export function UpdateCardRequestStatus(payload) {
  return {
    type: CARD.GET_USER_CARDS_STATUS,
    status: payload,
  };
}

/**
 * Get's the user's cards and dispatches to state.
 * @returns {Function}
 * @constructor
 */
export function GetUserCards() {
  return async function (dispatch) {
    try {
      let response = await CardApi.GetCards();
      // only update if response has card(s)
      if (response && response.length > 0) {
        dispatch(UpdateUserCards(response));
      }
      dispatch(UpdateCardRequestStatus(REQUEST_STATUS.Complete));
    } catch (err) {
      dispatch(UpdateCardRequestStatus(REQUEST_STATUS.Complete));
      throw (err);
    }
  };
}

/**
 * Get's the user's recent card transactions and dispatches to state.
 * @param cardId
 * @returns {Function}
 * @constructor
 */
export function GetCardTransactionHistory(response: CardTransaction[]) {
  return async function (dispatch) {
    dispatch({
      type: CARD.GET_CARD_TRANSACTION_HISTORY,
      transactions: response,
    });
  };
}

/**
 * Get's the ODt and Processor availability and dispatches to state.
 * @param cardId
 * @returns {Function}
 * @constructor
 */
export function GetODTAndProcessorAvailability() {
  return async function (dispatch) {
    let response = await CardApi.GetODTAndProcessorAvailability();
    dispatch({
      type: CARD.GET_AVAILABILITY,
      availability: response,
    });
  };
}

/**
 * Sets the showODTModal property in state.
 * @param showModal
 * @returns {Function}
 * @constructor
 */
export function SetDisplayODTModal(showModal) {
  return async function (dispatch) {
    dispatch({
      type: CARD.SHOW_ODT_MODAL,
      showODTModal: showModal,
    });
  };
}