import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PageBlock from "../Layouts/PageBlock";
import InputSkeletonGroup from "./Loaders/InputSkeleton";
import { ContactSupportMessageBlock } from "./MessageBlock";
import { REQUEST_STATUS } from "../../constants/enums";
import { COMMON } from "../../constants/localization";

const RequireCard = ({ children, card }) => {
  if (card.status !== REQUEST_STATUS.Complete) {
    return (
      <PageBlock>
        <PageBlock.Loader loadingContent={true}>
          <InputSkeletonGroup numInputs={1} />
        </PageBlock.Loader>
      </PageBlock>
    );
  } else if (!card.id) {
    return <ContactSupportMessageBlock message={COMMON.CARD_ERROR_MESSAGE} />;
  } else {
    return <div>{children}</div>;
  }
};

RequireCard.propTypes = {
  children: PropTypes.node,
  card: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    card: state.card,
  };
}

export default connect(mapStateToProps)(RequireCard);
