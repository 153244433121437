import React from "react";
import PropTypes from "prop-types";

const ACCEPTED_CHARACTERS = "1234567890.";

/**
 * Validates input and ensures only numbers and decimals are typed.
 * Also ensures that only a single decimal is entered.
 * "Number" fields rely on keyCode instead of charCodes
 * @param {*} event The event object from the keypress
 */
const handleOnChange = (event, decimalPlaces, inputOnChange) => {
  let valid = true;
  let value = event.currentTarget.value;
  let acceptableValues = ACCEPTED_CHARACTERS.split("");
  let hasDot = false;
  for (let i = 0; i < value.length; i++) {
    if (
      acceptableValues.indexOf(value[i]) < 0 ||
      (hasDot && value[i] === ".")
    ) {
      event.preventDefault();
      valid = false;
    } else if (value[i] === ".") {
      hasDot = true;
    }
  }

  if (
    decimalPlaces &&
    decimalCount(event.currentTarget.value) > decimalPlaces + 1
  ) {
    valid = false;
  }

  if (valid) {
    inputOnChange && inputOnChange(event);
  }
};

/**
 * Returns the number of decimal places a number has currently.
 * Regex is not 'performant' so string split will suffice
 * @param {*} number
 */
const decimalCount = (number) => {
  let char_array = number.toString().split(""); // split every single char
  let not_decimal = char_array.lastIndexOf(".");
  return not_decimal < 0 ? 0 : char_array.length - not_decimal;
};

/**
 * Wraps
 *
 * @param currencyIso
 * @param disabled
 * @param input
 * @param touched
 * @param error
 * @param warning
 * @returns {*}
 * @constructor
 */
const CurrencyInput = ({
  currencyIso,
  disabled,
  input,
  decimalPlaces,
  customChangeHandler,
  customBlurHandler,
  hideError,
  meta: { visited, touched, error, warning },
}) => {
  return (
    <div>
      <div
        className={`${currencyIso && "input-group"} ${touched && !error && "has-success"
          } ${touched && error && "has-error"}`}
      >
        <input
          {...input}
          disabled={disabled}
          type="text"
          placeholder="0.00"
          className={`form-control currency-input`}
          onChange={(e) =>
            handleOnChange(
              e,
              decimalPlaces,
              customChangeHandler || input.onChange
            )
          }
          onBlur={customBlurHandler}
          aria-describedby="basic-addon2"
        />
        {currencyIso && (
          <span className="input-group-addon" id="basic-addon2">
            {currencyIso}
          </span>
        )}
      </div>
      {!hideError && touched && error && (
        <div className="text-danger">{error}</div>
      )}
      {!hideError && !touched && visited && warning && (
        <div className="text-danger">{warning}</div>
      )}
    </div>
  );
};

CurrencyInput.propTypes = {
  /** ISO Currency code to display in the label */
  currencyIso: PropTypes.string,
  /** Form field */
  input: PropTypes.any.isRequired,
  /** Form field metadata */
  meta: PropTypes.object.isRequired,
  /** Disable the input */
  disabled: PropTypes.bool,
  /**
   * Hides the error section in this component. Useful when the
   * component is used within a @see DynamicInputField, which also
   * renders an error panel. This prevents duplicate errors from appearing.
   */
  hideError: PropTypes.bool,
  /** Decimal Limit */
  decimalPlaces: PropTypes.number,
  /**The custom onChange function to apply to the @see CurrencyInput */
  customChangeHandler: PropTypes.func,
  /**The custom onBlur function to apply to the @see CurrencyInput */
  customBlurHandler: PropTypes.func,
};

export default CurrencyInput;
