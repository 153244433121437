import React, { Component } from "react";
import PropTypes from "prop-types";
import { COMMON } from "../../../constants/localization";

/**
 * Renders a text input with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
class BooleanField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /// The bool field sets its own value to a bool
      /// The dynamic field generator/api might send it as the string "true"
      // Include final false statement, because props.checked can return undefined
      // Which will then set this.state.checked to undefined
      // And when a user clicks the checkbox React throws an error that a component is changing from uncontrolled to controlled.
      checked:
        props.input.value === "true" ||
        props.input.value === true ||
        props.checked || false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateInputValue();
  }

  /**
   * Renders the field label.
   * If the field isn't required an (optional) label will be appended.
   * @param {string} label The field label
   * @param {bool} required If the field is required or not
   * @param {string} fieldId Distinct field id for the checkbox built off the label name. Used to
   * prevent 'check' issued due to duplicated checkbox names
   */
  renderLabel(label, required, fieldId) {
    return (
      <label onClick={this.handleClick} htmlFor={fieldId}>
        {label} {!required && COMMON.OPTIONAL_FIELD}
      </label>
    );
  }

  /**
   * Triggers the input onchange with the value from state.
   */
  updateInputValue() {
    this.props.input.onChange(this.state.checked);
  }

  /**
   * Only the label needs the click event because we're hiding the actual checkbox with styles.
   */
  handleClick() {
    this.setState({ checked: !this.state.checked }, this.updateInputValue);
  }

  /**
   * Check if 'Enter' key is pressed to allow keyboard to check/uncheck input
   */
  handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ checked: !this.state.checked }, this.updateInputValue);
    }
  };

  render() {
    const { input, required, hideOptional, label } = this.props;
    const fieldId = input.name;
    if (required && !input.required) {
      input.required = true;
    }
    return (
      <div className="checkbox" onKeyDown={this.handleKeyDown} data-testid="boolean-field">
        <input {...input} checked={this.state.checked} type="checkbox" />
        {this.renderLabel(label, hideOptional || input.required, fieldId)}
      </div>
    );
  }
}

BooleanField.propTypes = {
  /** The label to display beside the text box. */
  label: PropTypes.any.isRequired,
  /** Is the associated Form field required */
  required: PropTypes.bool,
  /** Hides the (optional) label if the field is not required */
  hideOptional: PropTypes.bool,
  /** The input element */
  input: PropTypes.any,
  /** Input checked attribute */
  checked: PropTypes.bool,
};

export default BooleanField;
