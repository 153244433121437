import { combineReducers } from "redux";
import providerRates from "./providerRates";
import availableCountries from "./availableCountries";
import availableProvinces from "./availableProvinces";
import availableCities from "./availableCities";
import workflow from "./workflow";
import transferValidation from "./transferValidation";
import completedTransfer from "./completedTransfer";
import sendLimit from "./sendLimit";
import restrictions from "./restrictions";
import transferDetails from "./transferDetails";
import cancelTransaction from "./cancelTransaction";

export default combineReducers({
  providerRates,
  availableCountries,
  availableProvinces,
  availableCities,
  workflow,
  transferValidation,
  completedTransfer,
  restrictions,
  sendLimit,
  transferDetails,
  cancelTransaction
});
