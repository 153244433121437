import React, { useState, useEffect } from 'react';
import { PAGE_NAV_LINKS } from '../../constants/localization';
import { useLocation } from 'react-router-dom';
import CardApi from '../../api/card';
import i18n from '../../i18n/config';
import { CardStatement } from '../../types/api/card';

import LayoutWithNav from '../../components/Layouts/LayoutWithNav';
import PageNav from '../../components/Layouts/PageNav';
import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import AlertModal from '../../components/Common/Modals/AlertModal';

const CardStatements = () => {
  const location = useLocation();
  const cardId = new URLSearchParams(location.search).get("cardid");
  const [currentYearStatements, setCurrentYearStatements] = useState<CardStatement[]>([]);
  const [previousYearStatements, setPreviousYearStatements] = useState<CardStatement[]>([]);
  const [loading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {

    const getCardStatements = async () => {
      try {
        const response = await CardApi.GetCardStatements(cardId);
        const statementsReversed = response.statements.reverse();
        const currentYearStatements = statementsReversed.filter((statement: CardStatement) => isCurrentYear(new Date(statement.statementDate)));
        const previousYearStatements = statementsReversed.filter((statement: CardStatement) => isPreviousYear(new Date(statement.statementDate)));
        setCurrentYearStatements(currentYearStatements);
        setPreviousYearStatements(previousYearStatements);
        setLoading(false);
      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
        } else {
          setErrorMessage(i18n.t("COMMON.ERROR"));
        }

        setShowErrorModal(true);
      }
    }

    getCardStatements();
  }, []);

  const isCurrentYear = (date: Date) => {
    const currentYear = new Date().getFullYear();
    return date.getFullYear() === currentYear;
  }

  const isPreviousYear = (date: Date) => {
    const currentYear = new Date().getFullYear();
    return date.getFullYear() === currentYear - 1;
  }

  const renderStatementElement = (statement: CardStatement) => {
    const date = new Date(statement.statementDate);
    const monthName = date.toLocaleString('default', { month: 'long' });
    const formattedDate = `${monthName} ${date.getFullYear()}`;
    return (
      <li className="spacing-bottom-small" key={statement.statementId}>
        <button className="no-styling btn-link" onClick={() => getPdf(statement.statementId)}>{formattedDate}</button>
      </li>

    )
  }

  const getPdf = async (statementId: string) => {
    try {
      await CardApi.GetCardStatementPdf(cardId, statementId);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }

      setShowErrorModal(true);
    }

  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content className="allow-overflow">
        <FlowScreen
          flowTitle={i18n.t("PAGES.MY_CARD.CARD_STATEMENTS.TITLE")}
          className="v3"
        >
          <PageBlock>
            <PageBlock.Loader
              loadingContent={loading}
            >
              <InputSkeletonGroup numInputs={4} />
            </PageBlock.Loader>

            <PageBlock.Body loadingContent={loading}>
              <h2>{i18n.t("PAGES.MY_CARD.CARD_STATEMENTS.HEADING")} {new Date().getFullYear()}</h2>
              <ul className="cols-4 no-li-style">
                {currentYearStatements.map((statement: CardStatement) => (
                  renderStatementElement(statement)
                ))}
              </ul>


              <h2>{i18n.t("PAGES.MY_CARD.CARD_STATEMENTS.HEADING")} {new Date().getFullYear() - 1}</h2>
              <ul className="cols-4 no-li-style">
                {previousYearStatements.map((statement: CardStatement) => (
                  renderStatementElement(statement)
                ))}
              </ul>

            </PageBlock.Body>
          </PageBlock>
        </FlowScreen>
      </LayoutWithNav.Content>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />
    </LayoutWithNav>
  );
}

export default CardStatements;