import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DisplayLoading, FinishLoading } from "../../actions/loading";
import SuccessCheckmark from "../../components/Common/Success/SuccessCheckmark";
import FlowSuccess from "../../components/Common/Success/FlowSuccess";
import { COMMON, PAGES } from "../../constants/localization";
import FlowBackToDashboardButton from "../../components/Buttons/FlowBackToDashboardButton";
import FlowBackToReviewAndAcceptButton from "../../components/Buttons/FlowBackToReviewAndAcceptButton";
import { handleShowInAppMessageModal } from "../../actions/auth";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

/**
 * Allows api login and handles retrieving necessary app/user data for the store
 */
class SuccessFaceCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
    };

    this.renderInAppModal = this.renderInAppModal.bind(this);
  }

  renderInAppModal = () => {
    this.props.handleShowInAppMessageModal();
  };

  render() {
    const { inAppMessage, needsToAcceptDocs } = this.props;
    const title = COMMON.GO_TO_DASHBOARD;
    const showInAppModal = inAppMessage && inAppMessage.type !== null;

    return (
      <div className="v2">
        <FlowSuccess>
          <FlowSuccess.Image>
            <SuccessCheckmark />
          </FlowSuccess.Image>
          <FlowSuccess.Title>{PAGES.FACECHECK.SUCCESS.TITLE}</FlowSuccess.Title>
          <FlowSuccess.Body>{PAGES.FACECHECK.SUCCESS.BODY}</FlowSuccess.Body>
          <FlowSuccess.ActionRow>
            {showInAppModal && (
              <Action
                iconRight={null}
                title={title}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                className="btn-lg"
                onClick={this.renderInAppModal}
              />
            )}
            {!showInAppModal && needsToAcceptDocs ? (
              <FlowBackToReviewAndAcceptButton title={title} />
            ) : (
              !showInAppModal && <FlowBackToDashboardButton title={title} />
            )}
          </FlowSuccess.ActionRow>
        </FlowSuccess>
      </div>
    );
  }
}

SuccessFaceCheck.propTypes = {
  /** Display loading spinner action */
  DisplayLoading: PropTypes.any,
  /** Hide loading spinner action */
  FinishLoading: PropTypes.any,
  /** react-router history */
  history: PropTypes.any,
  needsToAcceptDocs: PropTypes.bool,
  inAppMessage: PropTypes.any,
  handleShowInAppMessageModal: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    state: state,
    needsToAcceptDocs: state.auth.authResult.needsToAcceptDocs,
    inAppMessage: state.auth.authResult.message,
  };
}

export default connect(mapStateToProps, {
  DisplayLoading,
  FinishLoading,
  handleShowInAppMessageModal,
})(SuccessFaceCheck);
