import get from "lodash/get";
import LogApi from "./log";
import ENV from "../constants/environment";
import { COMMON } from "../constants/localization";
import { ERROR_CODES } from "../constants/api";

/**
 * Service to handle successful network responses
 * @param response
 * @returns {*} response response data
 * @constructor
 */
export const HandleResponse = function (response) {
  let success = [200, 201, 202, 204];

  //Some responses do not contain isOk
  let isOk = get(response, "data.isOk", true);
  if (success.indexOf(response.status) > -1 && isOk) {
    delete response.data.isOk;
    delete response.data.isNotOk;
    delete response.data.errors;
    return response.data;
  }

  return response.errors;
};

/**
 * Service to handle successful network responses.
 * The difference between this function and the original handler is the IsOk property is ignored.
 * @param response
 * @returns {*} response response data
 */
export const HandleNewResponse = function (response) {
  let success = [200, 201, 202, 204];

  if (success.indexOf(response.status) > -1) {
    delete response.data.isOk;
    delete response.data.isNotOk;
    delete response.data.errors;
    return response.data;
  }

  return response.errors;
};

/**
 * Service to handle responses when response status is 200 but still response contains errors or response.data.isOk !== true
 * The difference between this function and the new handler is error is thrown if response.data.errors contains errors or isOk !== true
 * @param response
 * @returns {*} response response data
 */
export const HandleAltResponse = function (response) {
  let success = [200, 201, 202, 204];

  if (
    success.indexOf(response.status) > -1 &&
    response.data.isOk &&
    response.data.errors.length < 1
  ) {
    delete response.data.isOk;
    delete response.data.isNotOk;
    delete response.data.errors;
    return response.data;
  }

  if (response.data.errors.length < 1 && response.data.messages.length > 0) {
    throw response.data.messages;
  } else {
    throw response.data.errors;
  }
};

/**
 * Service to handle responses when response status is 200 but still response contains errors or response.data.doesPass !== true
 * The difference between this function and the new handler is error is thrown if response.data.errors contains errors or doesPass !== true
 * @param response
 * @returns {*} response response data
 */
export const HandleDoesPassResponse = function (response) {
  let success = [200, 201, 202, 204];

  if (
    success.indexOf(response.status) > -1 &&
    response.data.doesPass &&
    response.data.errors.length < 1
  ) {
    delete response.data.isOk;
    delete response.data.isNotOk;
    delete response.data.doesPass;
    delete response.data.doesNotPass;
    delete response.data.errors;
    return response.data;
  }

  if (response.data.errors.length < 1 && response.data.messages.length > 0) {
    throw response.data.messages;
  } else {
    throw response.data.errors;
  }
};

/**
 * Service to handle network failure responses.
 * @param {*} data error data
 * @constructor
 */
export const HandleErrorResponse = (data) => {
  let errorResponse = get(data, "response.data", {});
  if (!errorResponse.errors) {
    if (!ENV.__DEV__) {
      LogApi.OldErrorHandler(`Error in response: ${errorResponse.message}`);
    }
    throw new Error(errorResponse.message);
  } else {
    let message = get(errorResponse, "errors[0].errorMessage", "no message");
    if (!ENV.__DEV__) {
      LogApi.OldErrorHandler(`Platform Error: ${message}`);
    }

    throw new Error(
      message || "Your request was unsuccessful due to an error."
    );
  }
};

/**
 * Service to handle network failure responses that follow the new format.
 * @param {*} data error data
 * @constructor
 */
export const HandleNewErrorResponse = (error) => {
  if (!error.response) {
    throw error;
  }

  let data = error.response.data;
  const response = {
    status: error.response.status,
    data,
  };

  if (!data?.errors) {
    response.data.errors = [
      {
        errorCode: ERROR_CODES.UNEXPECTED_ERROR,
        errorDescription: COMMON.ERROR,
      },
    ];
  }

  throw response;
};
