import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AddErrors } from "../../actions/errors";
import { appInsights } from "../../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { COMMON, PAGES } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import { GetODTAndProcessorAvailability, SetDisplayODTModal } from "../../actions/card";

import QuickLinks from "../../components/Dashboard/QuickLinks";
import Alerts from "../../components/Dashboard/Alerts";
import Balance from "../../components/Dashboard/Balance";
import PaymentSummary from "../../components/Dashboard/PaymentSummary";
import Modal from "../../components/Common/Modal";
import ExchangeRateModal from "../../components/Dashboard/ExchangeRateModal";
import { ROUTES } from "../../constants/clientRoutes";
import { FEATURE_FLAGS } from "../../constants/featureFlags";
import { Helmet } from "react-helmet";
import i18n from "../../i18n/config";

const Dashboard = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [showProcessorDownModal, setShowProcessorDownModal] = useState(false);
  const card = useSelector((state) => state.card);
  const user = useSelector((state) => state.user);
  const showODTModal = card.availability.showODTModal === true;
  const initialApiCallErrors = useSelector((state) => state.initialApiCallErrors);

  useEffect(() => {
    const getProcessorAvailability = async () => {
      await dispatch(GetODTAndProcessorAvailability());
    }

    getProcessorAvailability();
  }, []);

  useEffect(() => {
    if (card.availability.isProcessorUnavailable) {
      setShowProcessorDownModal(true);
    }
  }, [card.availability.isProcessorUnavailable]);

  useEffect(() => {

    if (initialApiCallErrors) {
      dispatch(AddErrors([{
        errorType: 4,
        errorTitle: COMMON.GENERIC_ERROR_MODAL_HEADER,
        errorMessage: `${COMMON.INITIALIZE_APP_ERROR} Error Code returned: ${initialApiCallErrors.errorCode}`
      }]));

      appInsights.trackException({
        exception: new Error('App Initial API calls Error'), severityLevel: SeverityLevel.Error, properties: {
          errorCode: initialApiCallErrors.errorCode,
          apiCallError: initialApiCallErrors.apiCall,
          username: user?.profile?.userName || null
        },
      });
    }

  }, [initialApiCallErrors])

  const dismissProcessorDownModal = () => {
    setShowProcessorDownModal(false);
  }

  const dismissODTDownModal = async () => {
    await dispatch(SetDisplayODTModal(false));
  }

  if (user.featureFlags.includes(FEATURE_FLAGS.COMPANION_PARTICIPANT)) {
    location.href = ROUTES.EXTERNAL.MY_CARD;
  }

  return (
    <div id="dashboard" className="v2 v3 spacing-bottom-large">
      <Helmet>
        <title>{i18n.t("PAGES.DASHBOARD.PAGE_TITLE")}</title>
      </Helmet>
      <h1 className="sr-only">{i18n.t("PAGES.DASHBOARD.PAGE_TITLE")}</h1>
      <Alerts featureFlags={user.featureFlags} card={card} />

      <Balance card={card} featureFlags={user.featureFlags} />

      <div className="row">

        <QuickLinks
          setShowModal={setShowModal}
          featureFlags={user.featureFlags}
          initialApiCallErrors={initialApiCallErrors}
        />

        <div className="col-sm-7">
          <PaymentSummary />
        </div>
      </div>

      <Modal
        onClose={() => setShowModal(false)}
        open={showModal}
        title={'Check Current Exchange Rates'}
        className={'medium'}
        disableNativeActions={true}
        content={<ExchangeRateModal featureFlags={user.featureFlags} />}
      />

      <Modal
        open={showProcessorDownModal}
        title={PAGES.DASHBOARD.PROCESSOR_DOWN_MODAL.TITLE}
        content={PAGES.DASHBOARD.PROCESSOR_DOWN_MODAL.CONTENT}
        onClose={dismissProcessorDownModal}
        actions={[
          {
            title: COMMON.CLOSE,
            onClick: () => dismissProcessorDownModal(),
            displayType: ACTION_DISPLAY_TYPES.PRIMARY,
            focused: true,
          }
        ]}
      />

      <Modal
        open={showODTModal}
        title={PAGES.DASHBOARD.ODT_DOWN_MODAL.TITLE}
        content={PAGES.DASHBOARD.ODT_DOWN_MODAL.CONTENT}
        onClose={dismissODTDownModal}
        actions={[
          {
            title: COMMON.OK,
            onClick: () => dismissODTDownModal(),
            displayType: ACTION_DISPLAY_TYPES.PRIMARY,
            focused: true,
          }
        ]}
      />
    </div>
  )
}

export default Dashboard;