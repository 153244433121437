import React, { FormEvent, useEffect, useState } from 'react';
import Modal from '../../Common/Modal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../types/redux';
import { getFormValues } from 'redux-form';
import { COMMON, PAGES } from '../../../constants/localization';
import CardApi from '../../../api/card';
import { ACTION_DISPLAY_TYPES } from '../../../constants/enums';
import AddRecipientForm from './AddRecipientForm';

type AddRecipientModalContainerType = {
  showAddRecipientModal: boolean;
  setShowAddRecipientModal: React.Dispatch<boolean>;
  handleSuccessModal: Function;
  getRecipients: Function;
}

type RecipientFormValues = {
  ExpirationDate: string;
  cardNumber: string;
  name: string;
}

const AddRecipientModalContainer = ({ showAddRecipientModal, setShowAddRecipientModal, handleSuccessModal, getRecipients }: AddRecipientModalContainerType) => {
  const { MY_CARD: { CARD_TO_CARD_TRANSFER } } = PAGES;
  const addRecipientFormValues = useSelector((state: RootState) => getFormValues(CARD_TO_CARD_TRANSFER.ADD_RECIPIENT_FORM)(state)) as RecipientFormValues;
  const [submittingAddRecipient, setSubmittingAddRecipient] = useState(false);
  const [addRecipientErrorMessage, setAddRecipientErrorMessage] = useState<string>();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (addRecipientFormValues && Object.keys(addRecipientFormValues).length === 3) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }, [addRecipientFormValues]);

  const handleAddRecipientSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmittingAddRecipient(true);
    const expDate = new Date(addRecipientFormValues.ExpirationDate);
    const expMonth = expDate.getMonth() + 1;
    const expYear = expDate.getFullYear();

    const request = {
      name: addRecipientFormValues.name,
      cardNumber: addRecipientFormValues.cardNumber,
      expMonth,
      expYear
    }

    try {
      await CardApi.AddCardToCardRecipient(request);
      getRecipients();
      setSubmittingAddRecipient(false);
      setShowAddRecipientModal(false);
      handleSuccessModal(CARD_TO_CARD_TRANSFER.RECIPIENT_SUCCESS_TITLE, CARD_TO_CARD_TRANSFER.RECIPIENT_SUCCESS_BODY);
    } catch (err) {
      setSubmittingAddRecipient(false);
      if (err?.data?.InvalidCardNumber) {
        setAddRecipientErrorMessage(err.data.InvalidCardNumber[0]);
      } else if (err?.data?.ExpDate) {
        setAddRecipientErrorMessage(err.data.ExpDate[0]);
      } else if (err?.data?.errors[0]?.errorDescription) {
        setAddRecipientErrorMessage(err.data?.errors[0]?.errorDescription);
      } else {
        setAddRecipientErrorMessage(COMMON.ERROR);
      }
    }
  }

  const actions = [
    {
      title: COMMON.CANCEL,
      onClick: () => closeAddRecipientModal(),
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
      dontDisplayLoading: true
    },
    {
      title: COMMON.SAVE_AND_CLOSE,
      onClick: (e: FormEvent<HTMLFormElement>) => handleAddRecipientSubmit(e),
      disabled: disabled,
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    },
  ];

  const closeAddRecipientModal = () => {
    setShowAddRecipientModal(false);
    setAddRecipientErrorMessage("");
  }

  return (
    <Modal
      onClose={() => closeAddRecipientModal()}
      open={showAddRecipientModal}
      title={CARD_TO_CARD_TRANSFER.ADD_RECIPIENT_FORM_TITLE}
      content={<AddRecipientForm errorMessage={addRecipientErrorMessage} />}
      small={true}
      className="small-form left-aligned"
      disableNativeActions={true}
      actions={actions}
      loading={submittingAddRecipient}
    />
  );
}

export default AddRecipientModalContainer;