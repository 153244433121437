import React from "react";
import { Props } from "../../types/props";

/**
 * Component that house actions that take the flow forward
 * @param {Node} children
 */
const Forward = ({ children }: Props) => {
  return <div className="action-row__forward">{children}</div>;
};

/**
 * Component that house actions that take the flow backward
 * @param {Node} children
 */
const Backward = ({ children }: Props) => {
  return <div className="action-row__backward">{children}</div>;
};

const ActionRow = (props: any) => {
  return <div className="action-row">{props.children}</div>;
};

ActionRow.Forward = Forward;
ActionRow.Backward = Backward;

export default ActionRow;
