import React from "react";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import ActionLink from "../Common/ActionLink";

const FlowBackToLoginLink = (props) => {
  return (
    <ActionLink
      text={COMMON.BACK_TO_LOGIN}
      classes="return-to-login"
      clickFunc={() => (location.href = ROUTES.EXTERNAL.LOGIN)}
      {...props}
    />
  );
};

export default FlowBackToLoginLink;
