import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { COMMON, PAGES } from "../../constants/localization";
import RateCard from "../../components/MoneyTransfer/RateCard";
import ActionLink from "../../components/Common/ActionLink";
import Restrictions from "../../components/MoneyTransfer/Restrictions";
import get from "lodash/get";
import SpinningLoader from "../../components/Common/SpinningLoader";
import { UpdateProviderRates, GetProviderRates } from "../../actions/moneyTransfer";

class RatesDisplay extends Component {
  constructor(props) {
    super(props);

    this.onNext = this.onNext.bind(this);
    this.onBack = this.onBack.bind(this);
    this.handleResetProviders = this.handleResetProviders.bind(this);

    this.state = {
      loading: false
    }
  }

  /**
   * Action for selecting a rate provider
   *
   * @param providerId
   */
  onNext() { }

  /**
   * The 'disable' back link css is not enough to disable the click.
   */
  onBack() {
    if (!this.props.loading) {
      this.props.onBack();
    }
  }

  /**
   * Display passport errors
   * Can be expanded to handle all errors (non card specific)
   * @param {*} errors
   */
  renderPassportError() {
    return (
      <div className="rate-errors">
        <div className="text-danger">
          {PAGES.MONEY_TRANSFER.RATE_LIST.INVALID_PASSPORT}
        </div>
      </div>
    );
  }

  /**
   * If user clicks 'X' button on applied promo code, set loading state, re-fetch provider rates, and then remove loading state
   */
  async handleResetProviders() {
    const { moneyTransferValues, GetProviderRates } = this.props;
    const { sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity } = moneyTransferValues;
    this.setState({ loading: true });
    await GetProviderRates(sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity);
    this.setState({ loading: false });
  }

  render() {
    let parsedSend = parseFloat(this.props.rateQuery.sendAmount).toFixed(2);

    const { providerRates, disclaimers } = this.props;
    const rateList = providerRates.map((provider, i) => {
      // Hard coding first rate until multiple are known to return from the api
      const rate = provider.quotes[0];
      return (
        <RateCard
          onSelectedQuote={this.props.onSelectedQuote}
          providerName={provider.name}
          fields={rate ? rate.fields : null}
          promoCodeAvailable={rate ? rate.promoCode : null}
          logo={provider.logo}
          providerId={provider.providerId} //This will need to be generated for multiple provider rates
          quoteId={rate ? rate.quoteId : null}
          loading={this.props.loading}
          handleResetProviders={this.handleResetProviders}
          key={i}
          disableButton={
            this.props.restrictions && this.props.restrictions.length > 0
          }
          errors={provider.errors}
        />
      );
    });

    const disclaimerList = disclaimers.map((d, i) => {
      if (d.superscript) {
        return (
          <div className="disclaimer" key={i}>
            <sup>{d.superscript}</sup> {d.text}
          </div>
        );
      } else {
        return (
          <div className="disclaimer" key={i}>
            {d.text}
          </div>
        );
      }
    });

    return (
      <div>
        <SpinningLoader show={this.state.loading} />
        <Restrictions restrictions={this.props.restrictions} />
        <p className="bold large">
          {PAGES.MONEY_TRANSFER.FORMATS.SENDING_TO(
            parsedSend,
            this.props.sendCurrency,
            this.props.rateQuery.receiveCurrency,
            this.props.rateQuery.destinationCountryName
          )}
        </p>
        <hr />
        <div>
          {providerRates.length > 1 && (
            <p>{PAGES.MONEY_TRANSFER.RATE_LIST.SELECT_ONE_OF}</p>
          )}
          <div className="rate-list">{rateList}</div>
        </div>
        <div className="spacing-top-default">{disclaimerList}</div>
        <div className="spacing-top-medium">
          <ActionLink
            clickFunc={this.onBack}
            classes={"mt-link" + (this.props.loading && " disabled")}
          >
            <div className="glyphicon glyphicon-menu-left link_icon"></div>
            {COMMON.BACK}
          </ActionLink>
        </div>
      </div>
    );
  }
}

RatesDisplay.propTypes = {
  providerRates: PropTypes.array.isRequired,
  disclaimers: PropTypes.array.isRequired,
  onSelectedQuote: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  sendCurrency: PropTypes.string,
  loading: PropTypes.bool,
  rateQuery: PropTypes.object,
  restrictions: PropTypes.array,
  moneyTransferValues: PropTypes.object,
  GetProviderRates: PropTypes.func
};

function mapStateToProps(state) {
  return {
    sendCurrency: get(state, "card.currencyCode", ""), // get data from stored state. great for /user and /cards calls
    providerRates: state.moneyTransfer.providerRates.providerRates,
    disclaimers: state.moneyTransfer.providerRates.disclaimers,
    rateQuery: state.moneyTransfer.providerRates.rateQuery,
    restrictions: state.moneyTransfer.restrictions,
    moneyTransferValues: state.form.moneytransfer.values
  };
}


function mapDispatchToProps(dispatch) {
  return {
    UpdateProviderRates: (promoCodeResponse) => dispatch(UpdateProviderRates(promoCodeResponse)),
    GetProviderRates: (sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity) => dispatch(GetProviderRates(sendAmount,
      receiveCurrency,
      destinationCountry,
      destinationProvince,
      destinationCity))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RatesDisplay);
