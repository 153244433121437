import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { GetWageStatements } from "../../actions/wageStatements";
import ActionLink from "../../components/Common/ActionLink";
import { WAGE_STATEMENTS, COMMON } from "../../constants/localization";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import WageStatementsApi from "../../api/wageStatements";
import ChevronRight from "../../assets/images/icons/ChevronRight.svg";
import Spinner from "../../components/Common/Spinner";
import AlertModal from "../../components/Common/Modals/AlertModal";
import FileSaver from "file-saver";
import Icon from "../../components/Common/Icon";
import { ICONS } from "../../constants/enums";

/**
 * Displays a list of wage statement links
 */
class WageStatements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingStatementsList: true,
      showGenericAlertModal: false,
    };
  }

  /*TODO: This method was renamed from componentWillMount. 
  Remove once code is verified as working. */
  async componentDidMount() {
    try {
      await this.props.GetWageStatements();
      this.setState({
        loadingStatementsList: false,
      });
    } catch (error) {
      this.toggleGenericAlertModal();
    }
  }

  download = async ({ id, title }) => {
    try {
      let response = await WageStatementsApi.GetWageStatement(id, title);
      FileSaver.saveAs(new Blob([response]), `${title}.pdf`);
    } catch (error) {
      this.toggleGenericAlertModal();
    }
  };

  toggleGenericAlertModal = () => {
    let modalOpen = this.state.showGenericAlertModal;
    this.setState({
      showGenericAlertModal: !modalOpen,
    });
  };

  render() {
    const list = this.props.statements.map((statement) => {
      return (
        <li key={statement.id} className="wage-statement-item">
          <div className="row">
            <ActionLink
              clickFunc={this.download}
              value={{ id: statement.id, title: statement.title }}
              classes="wage-statement-link"
            >
              <div className="col-xs-9">{statement.title}</div>
              <div className="col-xs-3">
                <img src={ChevronRight} className="pull-right" />
              </div>
            </ActionLink>
          </div>
        </li>
      );
    });

    const { error } = this.props;

    return (
      <React.Fragment>
        <FlowScreen
          flowTitle={WAGE_STATEMENTS.WAGE_STATEMENTS}
          className="wage-statements"
        >
          <PageBlock>
            <PageBlock.Title>
              {WAGE_STATEMENTS.MY_WAGE_STATEMENTS}
            </PageBlock.Title>
            <PageBlock.Loader loadingContent={this.state.loadingStatementsList}>
              <Spinner show={true} fullWidth={true} />
            </PageBlock.Loader>
            <PageBlock.Body loadingContent={this.state.loadingStatementsList}>
              {error && <div className="text-danger">{error}</div>}
              {list.length > 0
                ? <ul className="wage-statements-list">{list}</ul>
                : <div className="flex-row align-items-center">
                  <Icon
                    icon={ICONS.INFO_GREY}
                  />
                  <p className="marengo-grey spacing-bottom-none spacing-left-tiny">You do not have any wage statements at the moment.</p>
                </div>
              }
            </PageBlock.Body>
          </PageBlock>
        </FlowScreen>
        <AlertModal
          open={this.state.showGenericAlertModal}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
          onClose={this.toggleGenericAlertModal}
        />
      </React.Fragment>
    );
  }
}

WageStatements.propTypes = {
  /** A list of wage statement objects */
  statements: PropTypes.array.isRequired,
  /** Error message if the retrieval failed */
  error: PropTypes.string,
  /** Action to retrieve statements */
  GetWageStatements: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    statements: state.wageStatements.list,
    error: state.wageStatements.error,
  };
}

export default connect(mapStateToProps, { GetWageStatements })(WageStatements);
