import React from 'react';
import { useSelector } from 'react-redux';
import { MONEY_TRANSFER_SUCCESS_TYPES } from '../../constants/enums';
import { RootState } from '../../types/redux';
import { ROUTES } from '../../constants/clientRoutes';
import Receipt from './Receipt';
import Success from './Success';


const CompletedTransfer = () => {
  const completedTransfer = useSelector((state: RootState) => state.moneyTransfer.completedTransfer);

  if (completedTransfer.type === MONEY_TRANSFER_SUCCESS_TYPES.RECEIPT) {
    return <Receipt redirectUrl={ROUTES.EXTERNAL.MONEY_TRANSFER} />
  } else {
    return <Success moneyTransferId={completedTransfer.moneyTransferId} />
  }
}

export default CompletedTransfer;