/* eslint-disable import/default */
import "./public-path";
import React from "react";
import { render } from "react-dom";
import { AppContainer } from "react-hot-loader";
import configureStore, { history } from "./store/configureStore.js";
import initialState from "./reducers/initialState";
import Root from "./containers/App/Root";
import "./assets/styles/main.scss"; // Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
//require('./favicon.ico'); // Tell webpack to load favicon.ico
// import "./styles/site.css";
// import "./styles/uniform.default.css";

export const store = configureStore(initialState);

render(
  <AppContainer>
    <Root store={store} history={history} />
  </AppContainer>,
  document.getElementById("app")
);

if (module.hot) {
  module.hot.accept("./containers/App/Root", () => {
    const NewRoot = require("./containers/App/Root").default;
    render(
      <AppContainer>
        <NewRoot store={store} history={history} />
      </AppContainer>,
      document.getElementById("app")
    );
  });
}
