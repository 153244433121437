// EnrollmentPages in Constants.cs mapped to app usable url
module.exports = Object.freeze({
  0: "/enroll",
  1: "/enroll/CreateUser",
  2: "/enroll/AccountSecurity",
  3: "/enroll/PersonalData",
  4: "/enroll/CardDetails",
  5: "/enroll/BankAccountDetails",
  6: "/enroll/CommunicationPreferences",
  7: "/enroll/Complete",
});
