import React from 'react';
import PropTypes from 'prop-types';
import FlowSuccess from '../../Common/Success/FlowSuccess';
import SuccessCheckmark from '../../Common/Success/SuccessCheckmark';
import ActionLink from '../../Common/ActionLink';
import ENV from '../../../constants/environment';

const SuccessScreen = ({ page }) => {

  if (page !== 4) {
    return null;
  }

  return (
    <FlowSuccess>
      <FlowSuccess.Image>
        <SuccessCheckmark />
      </FlowSuccess.Image>
      <FlowSuccess.Title>
        {'Success'}
        <p>Multi-factor authentication is set!</p>
      </FlowSuccess.Title>

      <FlowSuccess.Body>
        <div className='text-left mobile-padding-default'>
          <h3>Next time you log in to Navigator: </h3>
          <ol className='ol-markers-abyss-blue'>
            <li><p className='marengo-grey'>You will NOT be asked for a selfie.</p></li>
            <li><p className='marengo-grey'>You will be asked for a one time pass code from your authenticator app.</p></li>
          </ol>
        </div>

      </FlowSuccess.Body>

      <FlowSuccess.ActionRow>
        <ActionLink
          classes="btn btn-primary"
          href={`${ENV.BASE_NAVIGATOR_URL}/dashboard`}
          text={'Done'}
        />
      </FlowSuccess.ActionRow>
    </FlowSuccess>
  );
}

SuccessScreen.propTypes = {
  /** number to track which screen to display in flow */
  page: PropTypes.number,
};

export default SuccessScreen;