import React, { Component } from "react";
import PropTypes from "prop-types";
import CategoryCard from "./CategoryCard";

/**
 * Builds a list of cards for categories
 *
 * @param categories
 * @param onSelect
 * @returns {*}
 * @constructor
 */
class CategorySelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedCategoryId: null,
    };
  }

  isLeaf(category) {
    return !category.options || category.options.length === 0;
  }

  onSelect = (category) => {
    const { onChange } = this.props;
    if (this.isLeaf(category)) {
      onChange(category.categoryId);
    } else {
      onChange(null);
      this.setState({ expandedCategoryId: category.categoryId });
    }
  };
  render() {
    const { categories, selectedCategoryId } = this.props;
    const { expandedCategoryId } = this.state;
    const cards = categories.map((category) => {
      const { categoryId } = category;
      return (
        <CategoryCard
          selectedCategoryId={selectedCategoryId}
          category={category}
          expandedCategoryId={expandedCategoryId}
          key={categoryId}
          onSelect={this.onSelect}
        />
      );
    });

    return <div className="category-selection card-sm">{cards}</div>;
  }
}

CategorySelection.propTypes = {
  /** List of category id strings */
  categories: PropTypes.array.isRequired,
  /** Selection callback */
  onChange: PropTypes.func.isRequired,
  selectedCategoryId: PropTypes.string,
};

export default CategorySelection;
