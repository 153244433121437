import * as React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1 className="text-center">BRIGHTWELL NAVIGATOR PRIVACY POLICY</h1>

      <p>
        Brightwell Payments, Inc. ("We") are committed to protecting and respecting your
        privacy.
      </p>

      <p>This policy (together with our Brightwell Navigator Terms of Use and any other
        documents referred to on it) sets out the basis on which any personal data we collect
        from you, or that you provide to us, will be processed by us. Please read the
        following carefully to understand our views and practices regarding your personal
        data and how we will treat it. By visiting and using the Brightwell Navigator website
        https://www.brightwellnavigator.com/ you are accepting and consenting to the
        practices described in this policy. By “your data”, “your personal data”, and “your
        information” we mean any information about you, which you or third parties provide to
        us.
      </p>

      {/* Information we may collect */}
      <h2>INFORMATION WE MAY COLLECT FROM YOU</h2>

      <p>We may collect and process the following data about you:</p>

      <ul>
        <li>
          <p className="bold">Information you give us.</p>
          <ul>
            <li>
              <p>You may give us information about you by filling in forms on our site
                https://www.brightwellnavigator.com/ (“<span className="bold">our site</span>”) or by corresponding with
                us by phone, e-mail or otherwise. This includes information you provide
                when you enroll to use our site and the Brightwell Navigator Service,
                select how you want distributed your salary, wages, tips, commissions
                and other forms of employment/contractor related compensation to be
                paid to you by your employer/contractee (“<span className="bold">Salary/Wage</span>”), enroll an
                OceanPay Card, input or modify beneficiary bank account details and
                allocate how much of your salary/wage go to each account and when you
                report a problem with our site. The information you give us may include
                your name, date of birth, employee identification number, passport
                number and details, mailing address, e-mail address and phone number,
                OceanPay Card information and bank account details.
              </p>
            </li>
            <li>
              <p>
                Where you provide personal information relating to others (e.g.
                dependants, joint account holders) for the purpose of Salary/Wage
                disbursements facilitated via the Brightwell Navigator Service you confirm
                that you have their consent or are otherwise entitled to provide this
                information to us and for us to use it in accordance with this agreement.
              </p>
            </li>
          </ul>
        </li>

        <li>
          <p>
            <span className="bold">Information we collect about you.</span> With regard to each of your visits to our site
            we may automatically collect the following information:
          </p>
          <ul>
            <li>
              <p>
                technical information, including the Internet protocol (IP) address used to
                connect your computer to the Internet, your login information, browser
                type and version, time zone setting, browser plug-in types and versions,
                operating system and platform;
              </p>
            </li>
            <li>
              <p>
                information about your visit, including the full Uniform Resource Locators
                (URL) clickstream to, through and from our site (including date and time);
                page response times, download errors, length of visits to certain pages,
                page interaction information (such as scrolling, clicks, and mouse-overs),
                and methods used to browse away from the page and any phone number
                used to call our customer service number.
              </p>
            </li>
          </ul>
        </li>

        <li>
          <p className="bold">
            Information we receive from other sources.
          </p>
          <ul>
            <li>
              <p>
                We may receive information about you if you use any of the other
                websites we operate or the other services we provide, including, without
                limitation, in relation to your OceanPay Card. Such data may be shared
                internally and combined with data collected on this site. We are also
                working closely with third parties, in particular your employer/contractee
                but also including, for example, business partners, sub-contractors in
                technical and payment services, advertising networks, analytics providers,
                credit reference agencies and fraud prevention agencies) and may
                receive information about you from them.
              </p>
            </li>
            <li>
              <p>
                The information we will receive from your employer/contractee will
                include, without limitation, information to assist with your enrollment for
                and continued participation in the Brightwell Navigator Service and details
                of salary, wages, tips, commissions and other forms of
                employment/contractor related compensation to be paid to you by your
                employer/contractee to enable the method(s) and amount per method of
                disbursement of such payments to be selected via the Brightwell
                Navigator Service.
              </p>
            </li>
          </ul>
        </li>
      </ul>

      {/* Cookies */}
      <h2>COOKIES</h2>

      <p>
        When you access our website to use the Brightwell Navigator service, we may place
        small data files called “cookies” on your computer or other device. Cookies are used
        to facilitate your Internet session, to maintain security and to improve the user
        experience. You may decline our cookies if your browser permits, however,
        declination may interfere with your use of our website and the Brightwell Navigator
        service.
      </p>


      <h2>USES MADE OF THE INFORMATION</h2>

      <p>We use information held about you in the following ways:</p>

      <ul>
        <li>
          <p><span className="bold">Information you give to us.</span>We will use this information:</p>
          <ul>
            <li>
              <p>
                to carry out our obligations to your employer/contractee who has
                contracted with us for the provision of the Brightwell Navigator Service for
                use by you;
              </p>
            </li>
            <li>
              <p>
                to carry out our obligations to you (as set out in the Brightwell Navigator
                Terms of User) and to provide you with the information, products and
                services that you request from or via us;
              </p>
            </li>
            <li>
              <p>
                to provide you with information about other goods and services we offer
                that are similar to those that you already use through us or have already
                acquired from or through us or which you have enquired about;
              </p>
            </li>
            <li>
              <p>
                to provide you with information about goods or services provided by third
                parties we feel may interest you.
              </p>
            </li>
            <li>
              <p>
                to notify you about changes to our service;
              </p>
            </li>
            <li>
              <p>
                to ensure that content from our site is presented in the most effective
                manner for you and for your computer.
              </p>
            </li>
          </ul>
        </li>

        <li>
          <p><span className="bold">Information we collect about you.</span>We will use this information:</p>
          <ul>
            <li>
              <p>
                to administer our site and for internal operations, including
                troubleshooting, data analysis, testing, research, statistical and survey
                purposes;
              </p>
            </li>
            <li>
              <p>
                to improve our site to ensure that content is presented in the most
                effective manner for you and for your computer;
              </p>
            </li>
            <li>
              <p>as part of our efforts to keep our site safe and secure;</p>
            </li>
            <li>
              <p>
                to measure or understand the effectiveness of advertising we serve to you
                and others, and to deliver relevant advertising to you;
              </p>
            </li>
            <li>
              <p>to make suggestions and recommendations to you and other users of our
                site about goods or services that may interest you or them.</p>
            </li>
          </ul>
        </li>

        <li>
          <p>
            <span className="bold">Information we receive from other sources.</span>
            We may combine this information
            with information you give to us and information we collect about you. We may use
            this information and the combined information for the purposes set out above
            (depending on the types of information we receive).
          </p>
        </li>
      </ul>

      {/* Disclosure of your information */}
      <h2>DISCLOSURE OF YOUR INFORMATION</h2>

      <p>
        We may share your personal information with any member of our group, which
        means our subsidiaries, our ultimate holding company and its subsidiaries.
      </p>
      <p>
        We may share your information with selected third parties including:
      </p>

      <ul>
        <li>
          <p>
            Your employer/contractee, business partners, suppliers and sub-contractors for
            the performance of any contract we have entered or enter into with them and/or
            for the performance of our obligations to you as set out in the Brightwell
            Navigator Terms of Use. In particular, we will share your information with: (i) your
            employer/contractee to enable use of the Brightwell Navigator Service by you; (ii)
            the Issuer in connection with your OceanPay Card account; and (as applicable)
            (iii) the Remitter to facilitate the provision of the Remitter Service to you (as
            described in the Brightwell Navigator Terms of Use).
          </p>
        </li>
        <li>
          <p>
            Analytics and search engine providers that assist us in the improvement and
            optimisation of our site.
          </p>
        </li>
      </ul>

      <p>
        We may disclose your personal information to third parties:
      </p>

      <ul>
        <li>
          <p>
            In the event that we sell or buy any business or assets, in which case we may
            disclose your personal data to the prospective seller or buyer of such business or
            assets.
          </p>
        </li>
        <li>
          <p>
            If Brightwell Payments, Inc. or substantially all of its assets are acquired by a third
            party, in which case personal data held by it about its customers/users of the
            Brightwell Navigator Service will be one of the transferred assets.
          </p>
        </li>
        <li>
          <p>
            If we are under a duty to disclose or share your personal data in order to comply
            with any legal obligation, or in order to enforce or apply the Brightwell Navigator
            Terms of Use and other agreements; or to protect the rights, property, or safety of
            Brightwell Payments, Inc., our customers/service users, or others. This includes
            exchanging information with other companies and organisations for the purposes
            of fraud protection and risk reduction.
          </p>
        </li>
      </ul>

      {/* Where we store your personal data */}
      <h2>WHERE WE STORE YOUR PERSONAL DATA</h2>

      <p>
        The data that we collect from you will be transferred to, and stored in the United
        States of America. By submitting your personal data, you agree to this transfer,
        storing or processing. We will take all steps reasonably necessary to ensure that your
        data is treated securely and in accordance with this privacy policy.
      </p>

      <p>
        All information you provide to us is stored on our secure servers and will be will be
        encrypted [using SSL technology]. Where we have given you (or where you have
        chosen) a password which enables you to access certain parts of our site, you are
        responsible for keeping this password confidential. We ask you not to share a
        password with anyone.
      </p>

      <p>
        Unfortunately, the transmission of information via the internet is not completely
        secure. Although we protect your personal data using physical, technical and
        administrative security measures, we cannot guarantee the security of your data
        transmitted to our site; any transmission is at your own risk. Once we have received
        your information, we will use strict procedures and security features to prevent loss,
        misuse and unauthorized access.
      </p>

      {/* Future use and retention */}
      <h2>
        FURTHER USE AND RETENTION OF YOUR INFORMATION
      </h2>

      <p>
        Other than as specified herein, we will keep information about you confidential unless
        we have a duty to disclose it or law or regulation allows us to do so for legitimate
        business purposes. We will retain information about you after you have terminated
        your use of the Brightwell Navigator Service, or if your enrollment is declined or
        abandoned, for as long as permitted or required for legal, regulatory, fraud and other
        financial crime prevention purposes and for lawful permitted business purposes.
      </p>

      {/* Your rights */}
      <h2>YOUR RIGHTS</h2>

      <p>
        You will always have the ability to opt out of any promotional contact or usage of your
        personal data for promotional purposes. Your information will not be given or sold to
        any outside organization/third party for use in marketing without your consent. Where
        you have provided specific consent, communications may include promotions from
        relevant third parties. You may tell us at any time if you do not wish to receive
        marketing communications from us and/or third parties online by responding to the
        marketing communication, using your online account, submitting an OceanPay
        support ticket or by contacting us at the toll-free number on the back of your
        OceanPay Card and advising us of the types of communication that you no longer
        wish to receive.
      </p>

      <p>
        Our site may, from time to time, contain links to and from the websites of our partner
        networks, advertisers and affiliates, including, without limitation of the Issuer (in
        connection with your OceanPay Card) and (as applicable) the Remitter (in
        connection with the Remitter Services). If you follow a link to any of these websites,
        please note that these websites have their own privacy policies and that we do not
        accept any responsibility or liability for these policies. Please check these policies
        before you submit any personal data to these websites.
      </p>

      {/* Access to information */}
      <h2>ACCESS TO INFORMATION</h2>

      <p>
        You are entitled to see the information held about you by us and you may ask us to
        make any necessary changes to ensure that it is accurate and kept up to date. If you
        wish to do this, please contact us using your online account, submitting an OceanPay
        support ticket or by contacting us at the toll-free number on the back of your
        OceanPay Card. You can request the deletion of any of your personal data. Upon
        such request, we will delete or suspend access to such data to the extent permitted
        by law. You can request any changes or deletion of your personal data kept by us by
        contacting us as set out in the “Contact” section below.
      </p>

      {/* Changes to our privacy policy */}
      <h2>CHANGES TO OUR PRIVACY POLICY</h2>
      <p>
        Any changes we may make to our privacy policy in the future will be posted on this
        page and, where appropriate, you will be notified through your online account or by
        e-mail. Please check back regularly to see any updates or changes to our privacy
        policy.
      </p>

      {/* Contact */}
      <h2>CONTACT</h2>

      <p>
        Questions, comments and requests regarding this privacy policy are welcomed and
        should be sent using your online account message option.
      </p>

      <p className="spacing-top-medium spacing-bottom-large bold">Effective 9/1/2015</p>
    </div>
  );
}

export default PrivacyPolicy;