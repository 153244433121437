import React from "react";
import PageBlock from "../../components/Layouts/PageBlock.js";
import PropTypes from "prop-types";
import ActionRow from "../../components/Layouts/ActionRow";
import FlowContactSupportButton from "../../components/Buttons/FlowContactSupportButton";
import FlowBackToLoginButton from "../../components/Buttons/FlowBackToLoginButton";
import FlowBackToLoginLink from "../../components/Buttons/FlowBackToLoginLink.js";
import { IconFont } from "../../components/Common/IconFont.js";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../constants/iconfont.js";

const PasswordResetError = (props) => {
  const isExpired = props.isExpired || props.sessionInfo.isExpired;
  return (
    <PageBlock>
      <PageBlock.Title>
        {isExpired && <span>{props.title}</span>}
        {props.isUsernameError && (
          <React.Fragment>
            <IconFont icon={ICON_FONT_CLASS.ALERT} type={ICON_TYPE.ALERT} />
            <span className="spacing-left-tiny">{props.title}</span>
          </React.Fragment>
        )}
      </PageBlock.Title>
      <PageBlock.Body>
        <PageBlock.IntroText>{props.description}</PageBlock.IntroText>
        <ActionRow>
          <ActionRow.Forward>
            {isExpired && <FlowBackToLoginButton />}
            {props.isUsernameError && (
              <FlowContactSupportButton useInAppSupport={false} />
            )}
          </ActionRow.Forward>
          {props.isUsernameError && (
            <ActionRow.Backward>
              <FlowBackToLoginLink />
            </ActionRow.Backward>
          )}
        </ActionRow>
      </PageBlock.Body>
    </PageBlock>
  );
};

PasswordResetError.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  isExpired: PropTypes.bool,
  isUsernameError: PropTypes.bool,
  sessionInfo: PropTypes.object,
};

export default PasswordResetError;
