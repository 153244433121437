import React from "react";
import PropTypes from "prop-types";

const InfoTip = (props) => {
  return (
    <button type="button" className="info-tip" onClick={props.onClick}>
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5 20C15.7467 20 20 15.7467 20 10.5C20 5.25329 15.7467 1 10.5 1C5.25329 1 1 5.25329 1 10.5C1 15.7467 5.25329 20 10.5 20Z"
          stroke="#1C74D9"
          strokeWidth="1.5"
        />
        <path
          d="M9.4331 16V8.902H11.5471V16H9.4331ZM9.3631 5.57H11.6171V7.558H9.3631V5.57Z"
          fill="#1C74D9"
        />
      </svg>
    </button>
  );
};

InfoTip.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default InfoTip;
