import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../../constants/enums";
import { setAccessTokens } from "../../../services/enrollment";
import FlowScreen from "../../../components/Layouts/FlowScreen";
import ValidationSummary from "../../../components/Enrollment/ValidationSummary";
import GenericForm from "../../../components/Common/DynamicFields/GenericForm";
import PageBlock from "../../../components/Layouts/PageBlock";
import DynamicInputField from "../../../components/Common/DynamicFields/DynamicInputField";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import ActionRow from "../../../components/Layouts/ActionRow";
import { COMMON, PAGES } from "../../../constants/localization";
import EnrollmentApi from "../../../api/enrollment";
import Action from "../../../components/Common/Action";
import Modal from "../../../components/Common/Modal";
import { DOCUMENT_NUMBER } from "../../../constants/forms";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";

/**
 * Initial Enrollment form, renders inputs for Employee ID, Birthdate, Passport Number, and Country
 */
const IndexForm = ({
  errors,
  setErrors,
  getEnrollmentData,
  setShowSpinner,
  setEnrolledModal,
}) => {
  const [countries, setCountries] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const formData = useSelector(
    (state) => state.form[PAGES.ENROLLMENT.ENROLL.FORM_NAME]
  );

  /**
   * Get All Countries data for dropdown select element
   */
  useEffect(() => {
    getAllCountries();
  }, []);

  /**
   * Get country data and format country objects correctly for FIELD_TYPES.BOOL
   */
  const getAllCountries = async () => {
    try {
      const response = await EnrollmentApi.GetAllCountries(false);

      const countries = response.map((country) => ({
        label: country.text,
        value: country.value,
      }));

      setCountries(countries);
    } catch (err) {
      setErrors([PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  /**
   * Submits user enrollment data (employee id, birthdate, passport number, and country). Will redirect user to next page if data is correct/on success or display modal on error.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const { values } = formData;
    const BirthDate = formatBirthDate(values.Birthdate);

    const countryName = countries.find(country => values.Country === country.value).label;

    try {
      const response = await EnrollmentApi.Login(
        values.EmployeeID,
        BirthDate,
        values.PassportNumber,
        values.Country
      );

      setAccessTokens(response);
      getEnrollmentData(response.participantId);
      trackEvent(EVENTS.ENROLLMENT.ID_VERIFICATION_SUBMITTED, { success: true, passport_country: countryName });
    } catch (err) {
      setShowSpinner(false);
      if (err?.data?.errors) {
        trackEvent(EVENTS.ENROLLMENT.ID_VERIFICATION_SUBMITTED, {
          success: false,
          failure_reason: err.data.errors[0].errorMessage,
          passport_country: countryName
        });
        showModalOrAddMessage(err.data.errors);
      } else {
        trackEvent(EVENTS.ENROLLMENT.ID_VERIFICATION_SUBMITTED, {
          success: false,
          failure_reason: PAGES.ENROLLMENT.ERROR_GENERAL,
          passport_country: countryName
        });
        setErrors([PAGES.ENROLLMENT.ERROR_GENERAL]);
      }
    }
  };

  /**
   * Formats JS Date Object into MM-DD-YYYY string for API call
   */
  const formatBirthDate = (dateObj) => {
    const newDate = new Date(dateObj);
    const BirthDate = `${newDate.getMonth() + 1
      }-${newDate.getDate()}-${newDate.getFullYear()}`;
    return BirthDate;
  };

  /**
   * Displays modal with correct error message based on error data received from parseErrorResponse.
   */
  const showModalOrAddMessage = (errors) => {
    let errorsCollection = [];
    const contactLink = `<div><a class="support-link" href="${COMMON.SUPPORT_URL}">Contact Support</a></div>`;
    errors.map((error) => {
      switch (error.errorCode) {
        case "EN001": /// Already Enrolled
          setShowSpinner(false);
          setEnrolledModal({ show: true, bodyMessage: error.errorMessage });
          break;
        case "EN002": /// Invalid Credentials
          errorsCollection.push(error.errorMessage.concat(contactLink));
          break;
        default:
          errorsCollection.push(error.errorMessage);
      }
    });

    if (errorsCollection.length) {
      setShowSpinner(false);
      setErrors(errorsCollection);
    }
  };

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <FlowScreen flowTitle="Welcome">
      <Modal
        onClose={toggleModal}
        open={showModal}
        title={DOCUMENT_NUMBER.MODAL_TITLE}
        content={DOCUMENT_NUMBER.MODAL_CONTENT}
      />
      <h3 className="spacing-bottom-medium">
        With Brightwell Navigator, you control where your wages go and how they
        are distributed.
      </h3>
      <GenericForm
        form={PAGES.ENROLLMENT.ENROLL.FORM_NAME}
        onSubmit={handleSubmit}
        onUpdate={(isValid) => setIsDisabled(!isValid)}
      >
        <GenericForm.Body>
          <PageBlock>
            <PageBlock.IntroText>
              {"Enter the following information to get started"}
            </PageBlock.IntroText>
            <ValidationSummary errors={errors} />
            <PageBlock.Body>
              <InputWrapper className="row">
                <DynamicInputField
                  type={FIELD_TYPES.TEXT}
                  label="Your Employee ID"
                  required={true}
                  id="EmployeeID"
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>

              <InputWrapper className="row">
                <DynamicInputField
                  type={FIELD_TYPES.DATE}
                  label="Your Date of Birth"
                  required={true}
                  id="Birthdate"
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>

              <InputWrapper className="row">
                <DynamicInputField
                  type={FIELD_TYPES.TEXT}
                  label="Document Number"
                  required={true}
                  id="PassportNumber"
                  focus={true}
                  className="col-xs-12 col-sm-7"
                  moreInfo={{ fieldId: 'tooltip' }}
                  showModal={toggleModal}
                />
              </InputWrapper>

              <InputWrapper className="row">
                <DynamicInputField
                  id="Country"
                  type={FIELD_TYPES.COUNTRY}
                  label="Your Issuing Country"
                  required={true}
                  menuPlacement={"top"}
                  placeholderText="Select a Country"
                  options={countries}
                  focus={true}
                  isSearchable={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>

              <ActionRow>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={isDisabled}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </GenericForm>
    </FlowScreen>
  );
};

IndexForm.propTypes = {
  /** errors received from API call to send to ValidationSummary */
  errors: PropTypes.array.isRequired,
  /** error handling function from Enroll page component */
  setErrors: PropTypes.func.isRequired,
  /** EnrollmentApi call to get user enrollment data based on participant Id */
  getEnrollmentData: PropTypes.func.isRequired,
  /** Handle displaying SpinnerLoader in Enroll component */
  setShowSpinner: PropTypes.func.isRequired,
  /** Handle displaying EnrollModal in Enroll component */
  setEnrolledModal: PropTypes.func.isRequired,
};

IndexForm.displayName = "IndexForm";

export default IndexForm;
