import React, { useEffect, useState } from 'react';
import LayoutWithNav from '../../components/Layouts/LayoutWithNav';
import PageNav from '../../components/Layouts/PageNav';
import { COMMON, PAGE_NAV_LINKS } from '../../constants/localization';
import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import InputSkeletonGroup from '../../components/Common/Loaders/InputSkeleton';
import RequestForm from '../../components/MyCard/RequestCompanionCard/RequestForm';
import { GetCompanionCardResponse, SupportDocType } from '../../types/api/card';
import CatalogApi from '../../api/catalog';
import { GetCountriesResponse } from '../../types/api/catalog';
import CardApi from '../../api/card';
import { DropdownType } from '../../types/fields';
import Modal from '../../components/Common/Modal';
import ExampleIdModalContent from '../../components/MyCard/RequestCompanionCard/ExampleIdModalContent';
import AlertModal from '../../components/Common/Modals/AlertModal';
import SuccessContent from '../../components/MyCard/RequestCompanionCard/SuccessContent';
import _ from 'lodash';

const RequestCompanionCard = () => {
  const [loading, setLoading] = useState(true);
  const [companionCards, setCompanionCards] = useState<boolean>(false);
  const [instructions, setInstructions] = useState<string[]>();
  const [idTypeList, setIdTypeList] = useState<DropdownType[]>();
  const [documents, setDocuments] = useState<SupportDocType[]>();
  const [countryOptions, setCountryOptions] = useState<DropdownType[]>();
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    getCountries();
    getCompanionCardDetails();
    setLoading(false);
  }, []);

  const getCompanionCardDetails = async () => {
    try {
      const response = await CardApi.GetCompanionCard() as GetCompanionCardResponse;
      if (response.idTypeList.length > 0) {
        setIdTypeList(response.idTypeList.map((idType) => (
          {
            label: idType.name,
            value: String(idType.id)
          }
        )));
      }

      if (response.documents.length > 0) {
        setDocuments(response.documents);
      }

      if (response.instructions.length > 0) {
        setInstructions(response.instructions);
      }

      setCompanionCards(response.companionCards);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setShowErrorModal(true);
    }
  }

  const getCountries = async () => {
    try {
      const response = await CatalogApi.GetCountries() as GetCountriesResponse;

      if (response.countries.length > 0) {
        const options = response.countries.map((country) => (
          {
            label: country.name,
            value: String(country.id)
          }
        ));

        setCountryOptions(options);
      }
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(COMMON.ERROR);
      }
      setShowErrorModal(true);
    }
  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        {!success &&
          <FlowScreen
            flowTitle={"Companion Card Request"}
          >
            {instructions?.length > 0 &&
              instructions.map((instruction) => (
                <p key={_.uniqueId()}>{instruction}</p>
              ))
            }
            <PageBlock>
              <PageBlock.Loader
                loadingContent={loading}
              >
                <InputSkeletonGroup numInputs={4} />
              </PageBlock.Loader>
              <PageBlock.Body loadingContent={loading}>
                <RequestForm
                  companionCards={companionCards}
                  idTypeList={idTypeList}
                  documents={documents}
                  countryOptions={countryOptions}
                  setShowModal={setShowModal}
                  setSuccess={setSuccess}
                  setShowErrorModal={setShowErrorModal}
                  setErrorMessage={setErrorMessage}
                />

                {success &&
                  <SuccessContent />
                }

              </PageBlock.Body>
            </PageBlock>

            <Modal
              onClose={() => setShowModal(false)}
              open={showModal}
              title={"Government issued Photo IDs"}
              content={<ExampleIdModalContent idTypeList={idTypeList} />}
            />

            {errorMessage &&
              <AlertModal
                onClose={() => setShowErrorModal(false)}
                open={showErrorModal}
                title={COMMON.GENERIC_ERROR_MODAL_HEADER}
                content={errorMessage}
              />
            }

          </FlowScreen>
        }

        {success &&
          <SuccessContent />
        }
      </LayoutWithNav.Content>
    </LayoutWithNav>
  );
}

export default RequestCompanionCard;