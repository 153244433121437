import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import CurrentPINMobile from "../Mobile/CurrentPINMobile";
import CurrentPINDesktop from "../Desktop/CurrentPINDesktop";
import BREAKPOINTS from "../../../constants/breakPoints";
import CardApi from "../../../api/card";
import { connect } from "react-redux";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import InfoPictureModal from "../../../components/Common/Modals/InfoPictureModal";
import { PAGES, COMMON } from "../../../constants/localization";
import { RESET_PIN_STEPS } from "../../../constants/enums";
import { ICON_SVG } from "../../../constants/iconsvg";
import { eventTracker, EVENTS } from "../../../services/eventTracker";
import { AddErrors, ClearErrors } from "../../../actions/errors";

class CurrentPINContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPIN: "",
      errors: [],
      showAlertModal: false,
      showGenericAlertModal: false,
      showPINHelpModal: false,
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeGenericModal = this.closeGenericModal.bind(this);
    this.togglePINHelpModal = this.togglePINHelpModal.bind(this);
  }

  componentDidMount() {
    this.props.clearError();
  }

  handleChange(pinInput) {
    this.setState({
      currentPIN: pinInput,
    });
  }

  handleSubmit() {
    if (!this.state.loading) {
      this.submitPIN();
    }
  }

  /**
   * Makes call to API to verify current PIN
   */
  async submitPIN() {
    if (this.props.cardId != null) {
      try {
        this.setState({
          loading: true,
        });
        let response = await CardApi.VerifyCurrentPIN(
          this.state.currentPIN,
          this.props.cardId
        );
        if (response.verified) {
          this.props.clearError();
          eventTracker.track(EVENTS.ChangePIN_CurrentPINSubmitted, {
            success: true,
          });
          this.props.setResponseToken(response.token);
          this.props.changeStep(RESET_PIN_STEPS.CHANGE_PIN);
        }
      } catch (error) {
        eventTracker.track(EVENTS.ChangePIN_CurrentPINSubmitted, {
          success: false,
        });
        let {
          data: { errors },
        } = error;
        this.props.addError(errors);
        this.props.checkTransferLock();
        if (error.status != 500) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            showGenericErrorModal: true,
            loading: false,
          });
        }
      }
    }
  }

  /**
   * Closes generic error modal
   */
  closeGenericModal() {
    this.setState({
      showGenericAlertModal: false,
    });
  }

  /**
   * Toggle display of PIN help modal
   */
  togglePINHelpModal() {
    let modalVisibleState = this.state.showPINHelpModal;
    this.setState(
      {
        showPINHelpModal: !modalVisibleState,
      },
      () => {
        if (this.state.showPINHelpModal) {
          eventTracker.track(EVENTS.ChangePIN_PINToolTip);
        }
      }
    );
  }

  trackForgotPINClick = () => {
    eventTracker.track(EVENTS.ChangePIN_ForgotPIN);
    this.props.changeStep(RESET_PIN_STEPS.FORGOT_PIN);
  };

  render() {
    const { width } = this.props;
    const isMobile = width <= BREAKPOINTS.SM_MIN;

    return (
      <Fragment>
        {!isMobile && (
          <CurrentPINDesktop
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            currentPIN={this.state.currentPIN}
            errors={this.props.errors}
            togglePINHelpModal={this.togglePINHelpModal}
            forgotPINEvent={this.trackForgotPINClick}
            loading={this.state.loading}
            isTransferLocked={this.props.isTransferLocked}
          />
        )}
        {isMobile && (
          <CurrentPINMobile
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
            currentPIN={this.state.currentPIN}
            errors={this.props.errors}
            togglePINHelpModal={this.togglePINHelpModal}
            forgotPINEvent={this.trackForgotPINClick}
            loading={this.state.loading}
            isTransferLocked={this.props.isTransferLocked}
          />
        )}
        <AlertModal
          open={this.state.showGenericAlertModal}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
          onClose={this.closeGenericModal}
        />
        <InfoPictureModal
          open={this.state.showPINHelpModal}
          title={PAGES.PIN_RESET.CARD_PIN}
          picture={ICON_SVG.PIN}
          content={PAGES.PIN_RESET.MODALS.PIN}
          onClose={this.togglePINHelpModal}
          disableNativeActions={true}
        />
      </Fragment>
    );
  }
}

CurrentPINContainer.propTypes = {
  changeStep: PropTypes.func,
  width: PropTypes.number,
  cardId: PropTypes.number,
  setResponseToken: PropTypes.func,
  errors: PropTypes.array,
  addError: PropTypes.func,
  clearError: PropTypes.func,
  isTransferLocked: PropTypes.bool,
  checkTransferLock: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cardId: state.card.id,
    errors: state.errors,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addError: (errors) => {
      dispatch(AddErrors(errors));
    },
    clearError: () => {
      dispatch(ClearErrors());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentPINContainer);
