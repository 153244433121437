import React from "react";
import PropTypes from "prop-types";
import { COMMON } from "../../../constants/localization";
import moment from "moment";
import InputService from "../../../services/input";
import _ from "lodash";

const Months = () => {
  let months = [];
  months.push({
    label: "Month",
    value: "",
  });
  for (let month = 0; month <= 11; month++) {
    months.push({
      label: moment().month(month).format("MMMM"),
      value: month,
    });
  }

  return months;
};

const DesktopDate = (props) => {
  return (
    <React.Fragment>
      <div
        className={`${props.elementError("month") ? "has-error" : ""}`}
        data-cy="month-date-selection"
      >
        <label htmlFor="Month" className="date-field__label">
          {COMMON.MONTH}
        </label>
        <select
          id="Month"
          disabled={props.disabled}
          onFocus={() => props.handleFocus("month")}
          onBlur={props.handleBlur}
          className="form-control"
          value={props.month ? props.month - 1 : ""}
          onChange={(e) => props.handleChange(e, "month")}
        >
          {Months().map((month) => (
            <option key={_.uniqueId()} value={month.value}>
              {month.label}
            </option>
          ))}
        </select>
      </div>
      <div className={`${props.elementError("day") ? "has-error" : ""}`}>
        <label htmlFor="Day" className="date-field__label">
          {COMMON.DAY}
        </label>
        <input
          id="Day"
          disabled={props.disabled}
          onFocus={() => props.handleFocus("day")}
          onBlur={props.handleBlur}
          className="form-control"
          value={props.day ? props.day : ""}
          type="text"
          inputMode="numeric"
          placeholder="xx"
          onKeyPress={InputService.HandleNumericKeyPress}
          onChange={(e) => props.handleChange(e, "day")}
          maxLength="2"
        />
      </div>
      <div className={`${props.elementError("year") ? "has-error" : ""}`}>
        <label htmlFor="Year" className="date-field__label">
          {COMMON.YEAR}
        </label>
        <input
          id="Year"
          disabled={props.disabled}
          onFocus={() => props.handleFocus("year")}
          onBlur={props.handleBlur}
          className="form-control"
          inputMode="numeric"
          value={props.year ? props.year : ""}
          type="text"
          placeholder="xxxx"
          onKeyPress={InputService.HandleNumericKeyPress}
          onChange={(e) => props.handleChange(e, "year")}
          maxLength="4"
        />
      </div>
    </React.Fragment>
  );
};

DesktopDate.propTypes = {
  disabled: PropTypes.bool,
  handleFocus: PropTypes.func,
  handleBlur: PropTypes.func,
  month: PropTypes.string,
  day: PropTypes.string,
  handleChange: PropTypes.func,
  year: PropTypes.string,
  elementError: PropTypes.func,
};

export default DesktopDate;
