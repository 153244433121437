import React, { Component } from "react";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { PAGES, COMMON } from "../../constants/localization";
import {
  RATE_CARD_FIELDS,
  FIELD_CATEGORIES,
  MONEY_TRANSFER_STEPS,
  ACTION_DISPLAY_TYPES,
  MONEY_TRANSFER_PAGES,
} from "../../constants/enums";
import CountryAmount from "./CountryAmount";
import {
  GetProviderRates,
  GetWorkflow,
  ClearWorkflow,
  ValidateWorkflow,
  Commit,
} from "../../actions/moneyTransfer";
import RatesDisplay from "./RatesDisplay";
import FraudInformation from "./FraudInformation";
import Review from "./Review";
import TelemarketerSalesRule from "../../components/MoneyTransfer/TelemarketerSalesRule";
import { getFormValues, SubmissionError, submit, destroy } from "redux-form";
import { ROUTES } from "../../constants/clientRoutes";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import * as _ from "lodash";
import Modal from "../../components/Common/Modal";
import RequireCard from "../../components/Common/RequireCard";
import CompletedTransfer from "./CompletedTransfer";
import DynamicFormWrapper from "./DynamicFormWrapper";

/**
 * Main Money Transfer page that manages the full cashPickup / on-demand flow
 */
class MoneyTransfer extends Component {
  constructor(props) {
    super(props);

    let workflowPages = [];

    this.addCountryAmountWorkflowStep(workflowPages);
    this.addRatesWorkflowStep(workflowPages);

    this.state = {
      currentStep: _.first(workflowPages),
      posting: false,
      transfer: {},
      quoteId: null,
      workflowPages: workflowPages,
      errorModalMessage: "",
      showGenericErrorModal: false,
    };

    this.onNext = this.onNext.bind(this);
    this.onBack = this.onBack.bind(this);
    this.handleCountryAmountSubmit = this.handleCountryAmountSubmit.bind(this);
    this.handleQuoteSelected = this.handleQuoteSelected.bind(this);
    this.handleQuoteConfirmed = this.handleQuoteConfirmed.bind(this);
    this.handleTelemarketerSalesRuleFailed =
      this.handleTelemarketerSalesRuleFailed.bind(this);
    this.handleSubmitPayoutNetworkInformation =
      this.handleSubmitPayoutNetworkInformation.bind(this);
    this.handleSubmitSenderInformation =
      this.handleSubmitSenderInformation.bind(this);
    this.handleSubmitReceiverInformation =
      this.handleSubmitReceiverInformation.bind(this);
    this.handleFraudWarningAccepted =
      this.handleFraudWarningAccepted.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.getErrorFromWorkflow = this.getErrorFromWorkflow.bind(this);
    this.getQuote = this.getQuote.bind(this);
    this.setupWorkflow = this.setupWorkflow.bind(this);
    this.clearWorkflow = this.clearWorkflow.bind(this);
    this.closeGenericErrorModal = this.closeGenericErrorModal.bind(this);
  }

  componentDidMount() {
    trackEvent(EVENTS.MONEY_TRANSFER.STARTED, {});
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.wasCompletedTransferSuccessful(prevProps)) {
      this.onNext();
      return;
    }

    /// This is due to workflow state not clearing when user clicks the browser back button to the
    /// get rates screen.
    /// This is assuming that Country and GetRates components will always be number one in step order
    if (
      this.state.workflowPages.length > 2 &&
      prevState.currentStep.order > 1 &&
      this.state.currentStep.order == 1
    ) {
      this.clearWorkflow();
    }

    /// Before a workflow has been fetched, we expect there to be no pages.
    /// Once we have pages we can condidently move on with the transfer.
    if (
      !prevProps.workflow.pages.length &&
      this.props.workflow.pages.length
    ) {
      this.setupWorkflow();
    }

    if (
      prevProps.location.hash != this.props.location.hash &&
      this.props.location.hash
    ) {
      const hashStep = location.hash.substring(1);
      const stepAsNumber = parseInt(hashStep);

      /// If the transfer is complete we shouldn't allow the user to navigate through the flow anymore
      if (
        stepAsNumber < this.state.workflowPages.length &&
        this.props.completedTransfer.moneyTransferId
      ) {
        location.href = ROUTES.EXTERNAL.DASHBOARD;
      }

      const step = _.find(
        this.state.workflowPages,
        (page) => page.order === stepAsNumber
      );

      /// If we try to change step to one that isn't there, bail.
      /// NOTE: No error message will show in this scenario.
      if (step) {
        this.setState({ currentStep: step });
      }
    }
  }

  addCountryAmountWorkflowStep = (workflowPages) => {
    workflowPages.push({
      title: PAGES.MONEY_TRANSFER.STEP_TITLES.COUNTRY_AND_AMOUNT,
      type: MONEY_TRANSFER_STEPS.COUNTRY_AND_AMOUNT,
      number: 1,
      order: 1,
      renderComponent: () => (
        <CountryAmount
          sendCurrencyIso={this.props.sendCurrency}
          onSubmit={this.handleCountryAmountSubmit}
        />
      ),
    });
  };

  addRatesWorkflowStep = (workflowPages) => {
    workflowPages.push({
      title: PAGES.MONEY_TRANSFER.STEP_TITLES.RATES,
      type: MONEY_TRANSFER_STEPS.RATES,
      number: 1,
      order: 2,
      renderComponent: () => (
        <RatesDisplay
          loading={this.state.posting}
          onSelectedQuote={this.handleQuoteSelected}
          onBack={this.onBack}
        />
      ),
    });
  };

  addDynamicFormStep = (workflowPages, pageData) => {
    let handleSubmitFunc;
    let type;

    switch (pageData.id) {
      case MONEY_TRANSFER_PAGES.RECEIVER:
        handleSubmitFunc = this.handleSubmitReceiverInformation;
        type = MONEY_TRANSFER_STEPS.RECEIVER_INFO;
        break;
      case MONEY_TRANSFER_PAGES.SENDER:
        type = MONEY_TRANSFER_STEPS.SENDER_INFO
        handleSubmitFunc = this.handleSubmitSenderInformation;
        break;
      case MONEY_TRANSFER_PAGES.PAYER_NETWORKS:
        type = MONEY_TRANSFER_STEPS.PAYER_NETWORKS;
        handleSubmitFunc = this.handleSubmitPayoutNetworkInformation;
        break;
    }

    workflowPages.push({
      title: pageData.title,
      type: type,
      order: workflowPages.length + 1,
      renderComponent: () => (
        <DynamicFormWrapper
          key={type}
          pageData={this.props.workflow.pages.find(workflowPage => workflowPage.id === pageData.id)}
          onBack={this.onBack}
          onSubmit={handleSubmitFunc}
        />
      ),
    });
  };

  addFraudWarningWorkflowStep = (workflowPages, pageData) => {
    workflowPages.push({
      title: pageData.title,
      type: MONEY_TRANSFER_STEPS.FRAUD_WARNING,
      order: workflowPages.length + 1,
      renderComponent: () => (
        <FraudInformation
          pageData={pageData}
          onBack={this.onBack}
          onNext={this.handleFraudWarningAccepted}
        />
      ),
    });
  };

  addReviewWorkflowStep = (workflowPages) => {
    workflowPages.push({
      type: MONEY_TRANSFER_STEPS.REVIEW,
      order: workflowPages.length + 1,
      renderComponent: () => (
        <Review
          quoteId={this.state.quoteId}
          onBack={this.onBack}
          onCommit={this.handleCommit}
        />
      ),
    });
  };

  addSuccessWorkflowStep = (workflowPages) => {
    workflowPages.push({
      type: MONEY_TRANSFER_STEPS.SUCCESS,
      order: workflowPages.length + 1,
      renderComponent: () => <CompletedTransfer moneyTransferId={this.state.quoteId} />,
    });
  };

  /**
   * Builds out the workflow pages based on data in the workflow state object
   */
  setupWorkflow() {
    let workflowPages = this.state.workflowPages.slice();

    this.props.workflow.pages.forEach(page => {
      switch (page.id) {
        case MONEY_TRANSFER_PAGES.SENDER:
        case MONEY_TRANSFER_PAGES.RECEIVER:
        case MONEY_TRANSFER_PAGES.PAYER_NETWORKS:
          this.addDynamicFormStep(workflowPages, page);
          break;
        case MONEY_TRANSFER_PAGES.FRAUD_WARNING:
          this.addFraudWarningWorkflowStep(workflowPages, page);
          break;
      }
    })

    this.addReviewWorkflowStep(workflowPages);
    this.addSuccessWorkflowStep(workflowPages);

    this.setState({ workflowPages: workflowPages });
  }

  clearWorkflow() {
    /// Clear out the workflow in redux state
    this.props.ClearWorkflow();

    /// Remove all pages from the workflowPages array in component state
    /// Only the country/amount and rates pages should remain.
    const workflowPages = _.dropRightWhile(
      this.state.workflowPages,
      (p) =>
        p.type != MONEY_TRANSFER_STEPS.COUNTRY_AND_AMOUNT &&
        p.type != MONEY_TRANSFER_STEPS.RATES
    );
    this.setState({ workflowPages: workflowPages });
  }

  /**
   * True if the completedTransfer changed from the previous props.
   * @param {*} prevProps The previous props.
   */
  wasCompletedTransferSuccessful(prevProps) {
    return (
      prevProps.completedTransfer != this.props.completedTransfer &&
      this.props.completedTransfer &&
      this.props.completedTransfer.success
    );
  }

  /**
   * Changes the step number in the URL.
   * This will trigger the componentDidUpdate() lifecycle method
   * which reads the hash and shows the appropriate step.
   * @param {number} stepNumber
   */
  changeStep(stepNumber) {
    window.location.hash = stepNumber;
  }

  /**
   * Advances to the next page in the workflow.
   * If there are no more pages, no action is taken.
   */
  onNext() {
    const { currentStep, workflowPages } = this.state;
    const newStepNumber = currentStep.order + 1;

    if (newStepNumber <= workflowPages.length) {
      this.changeStep(newStepNumber);
    }
  }

  /**
   * Returns to the previous page in the workflow.
   * If there are no previous pages no action is taken.
   */
  async onBack() {
    const { currentStep } = this.state;
    const newStepNumber = currentStep.order - 1;

    /// If the user is already on step one, do nothing.
    if (newStepNumber < 1) {
      return false;
    }

    /// Steps 1 and 2 are the only steps before getting the workflow.
    /// If the user visits either of these we should clear out all the workflow data.
    this.changeStep(newStepNumber);
    if (newStepNumber < 3) {
      this.props.destroy(PAGES.MONEY_TRANSFER.FORM_NAME);
      this.clearWorkflow();
    }
  }

  getQuote(quoteId) {
    for (let provider of this.props.providerRates.providerRates) {
      for (let quote of provider.quotes) {
        if (quote.quoteId === quoteId) {
          return quote;
        }
      }
    }
  }

  getProvider(quoteId) {
    for (let provider of this.props.providerRates.providerRates) {
      for (let quote of provider.quotes) {
        if (quote.quoteId === quoteId) {
          return provider;
        }
      }
    }
  }

  closeGenericErrorModal() {
    this.setState({ showGenericErrorModal: false });
  }

  handleCountryAmountSubmit(values) {
    const country = this.props.availableCountries.filter(
      (c) => c.iso === values.destinationCountry
    )[0];

    trackEvent(EVENTS.MONEY_TRANSFER.AMOUNT_SUBMITTED, {
      destination_country: country.name,
      destination_currency: values.receiveCurrency,
      send_amount: values.sendAmount
    })

    this.setState({ posting: true });
    return this.props
      .GetProviderRates(
        values.sendAmount,
        values.receiveCurrency,
        values.destinationCountry,
        country.name,
        values.destinationProvince,
        values.destinationCity
      )
      .then(() => {
        if (
          this.props.providerRates.errors &&
          this.props.providerRates.errors.length > 0
        ) {
          throw new SubmissionError({
            _error: this.props.providerRates.errors[0].errorMessage,
          });
        }

        if (
          this.props.providerRates.restrictions &&
          this.props.providerRates.restrictions.length > 0
        ) {
          throw new SubmissionError({
            _error: this.props.providerRates.restrictions[0].errorMessage,
          });
        }

        if (
          this.props?.providerRates?.providerRates?.length
        ) {
          this.setState({ posting: false });
          this.onNext();
        } else {
          throw new SubmissionError({
            _error: PAGES.MONEY_TRANSFER.VALIDATIONS.RATES_NOT_AVAILABLE,
          });
        }
      });
  }

  async handleQuoteSelected(quoteId) {
    const quote = this.getQuote(quoteId);
    const provider = this.getProvider(quoteId);

    const serviceProvider = provider.name === 'Brightwell' ? 'Transfast' : provider.name;

    trackEvent(EVENTS.MONEY_TRANSFER.QUOTE_SELECTED, {
      remittance_service_provider: serviceProvider,
      exchange_rate: this.formatExchangeRate(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.FX_RATE).formattedValue),
      receive_amount: this.removeCurrency(_.find(
        quote.fields,
        (f) => f.id == RATE_CARD_FIELDS.TOTAL_RECEIVE_AMOUNT
      ).formattedValue),
      transfer_fee: this.removeCurrency(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.FEE)
        .formattedValue),
      total_cost: this.removeCurrency(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.TOTAL_COST)
        .formattedValue),
    })

    this.telemarketerSalesRule = provider.telemarketerSalesRule;

    if (this.telemarketerSalesRule) {
      this.handleTelemarketerSalesRulePassed = async () => {
        this.setState({ showTelemarketerSalesRule: false });
        await this.handleQuoteConfirmed(quoteId);
      };
      this.setState({ showTelemarketerSalesRule: true });
    } else {
      await this.handleQuoteConfirmed(quoteId);
    }
  }

  async handleQuoteConfirmed(quoteId) {
    this.setState({ posting: true });

    try {
      await this.props.GetWorkflow(quoteId);

      // currently added to state to avoid none usage errors but probably not needed there. clean up state in general!
      this.setState({ posting: false, quoteId: quoteId });

      this.onNext();
    } catch (e) {
      const message = e.message || COMMON.ERROR_CONTACT_SUPPORT;
      this.setState({ posting: false, showGenericErrorModal: true, errorModalMessage: message });
    }
  }

  handleTelemarketerSalesRuleFailed() {
    this.setState({ showTelemarketerSalesRule: false });
  }

  async handleCommit() {
    const params = this.createGAParams();
    await this.props.Commit(this.state.quoteId, params);
  }

  createGAParams() {
    const quote = this.getQuote(this.state.quoteId);
    const provider = this.getProvider(this.state.quoteId);

    const serviceProvider = provider.name === 'Brightwell' ? 'Transfast' : provider.name;
    const destinationCountry = this.props.availableCountries.find(country => country.iso === this.props.providerRates.rateQuery.destinationCountry);

    return {
      remittance_service_provider: serviceProvider,
      destination_country: destinationCountry.name,
      destination_currency: this.props.providerRates.rateQuery.receiveCurrency,
      send_amount: this.props.providerRates.rateQuery.sendAmount,
      exchange_rate: this.formatExchangeRate(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.FX_RATE).formattedValue),
      receive_amount: this.removeCurrency(_.find(
        quote.fields,
        (f) => f.id == RATE_CARD_FIELDS.TOTAL_RECEIVE_AMOUNT
      ).formattedValue),
      transfer_fee: this.removeCurrency(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.FEE)
        .formattedValue),
      total_cost: this.removeCurrency(_.find(quote.fields, (f) => f.id == RATE_CARD_FIELDS.TOTAL_COST)
        .formattedValue),
    }
  }

  /**
   * Returns value from str, removing the currency 
   * @private
   * @returns {str} 100.0 instead of 100.0 USD
   */
  removeCurrency(str) {
    return str.slice(0, str.indexOf(' '));
  }

  /**
   * Returns value from str 
   * @private
   * @returns {str} 56.00 instead of 1 USD = 56.00 PHP
   */
  formatExchangeRate(str) {
    const startIndex = str.indexOf('= ');
    const endIndex = str.indexOf(' ', startIndex + 2);
    const exchange_rate = str.slice(startIndex + 2, endIndex);
    return exchange_rate;
  }

  /**
   * Returns { serviceProviderFieldId : value } values for sender and receiver
   * Gets the data from the money-transfer-sender-info and money-transfer-receiver-info forms
   * @private
   * @returns {object} form values
   */
  get workflowValues() {
    // Get the form values using the redux-forms getFormValues selector function
    const formFields = this.props.formFields;
    let fields = [];

    if (formFields != undefined) {
      fields = Object.entries(formFields).map(
        ([serviceProviderFieldId, value]) => ({ serviceProviderFieldId, value })
      );
    }

    return fields;
  }

  /**
   * Gets errors from the workflow object based on the category passed in.
   * This is used to trigger validation on the sender/receiver pages.
   * */
  getErrorFromWorkflow(category, result) {
    let errorObject = {};
    if (result) {
      const page = _.find(result.pages, (c) => c.id === category);

      if (!page) {
        return errorObject;
      }

      for (let section of page.sections) {
        for (let field of section.fields) {
          if (
            field.validationError &&
            field.validationError.length > 0
          ) {
            errorObject[field.serviceProviderFieldId] =
              field.validationError[0].errorMessage;
          }
        }
      }
    }

    return errorObject;
  }

  /**
   * Called when the sender information form is submitted.
   */
  handleSubmitSenderInformation() {
    trackEvent(EVENTS.MONEY_TRANSFER.SENDER_DETAILS_SUBMITTED, {});

    this.onNext();
  }

  /**
   * Called when the payout network information form is submitted.
   */
  handleSubmitPayoutNetworkInformation() {
    this.onNext();
  }

  handleWorkflowErrors = (category, stepName, result) => {
    const errors = this.getErrorFromWorkflow(category, result);
    let hasErrors = Object.keys(errors).length > 0;

    // if hasErrors and stepName, then change step to appropriate page
    // This function is called from within the handleSubmitReceiverInformation func,
    // So stepName is omitted for receiver errors, as we are already on the receiver page
    if (hasErrors && stepName) {
      const step = _.find(this.state.workflowPages, (p) => p.type === stepName);
      this.changeStep(step.order);
    }

    return {
      errors,
      hasErrors
    };
  }

  /**
   * Async post of workflow data to validate.
   * Gets the data from the money-transfer-sender-info and money-transfer-receiver-info and sends to API for validation
   * Successful puts push the user to the next page.
   * Validation responses to be added.
   */
  async handleSubmitReceiverInformation() {
    trackEvent(EVENTS.MONEY_TRANSFER.RECEIVER_DETAILS_SUBMITTED);

    const formValues = this.workflowValues;

    try {
      const response = await this.props.ValidateWorkflow(this.state.quoteId, formValues);
      if (response.errors) {
        throw response.errors;
      }
      this.onNext();
    } catch (err) {
      // We validate payer, sender and receiver on this call.
      // If there are sender errors we want to render that component and trigger validation.
      const payerErrors = this.handleWorkflowErrors(FIELD_CATEGORIES.NETWORK_PAYER, MONEY_TRANSFER_STEPS.NETWORK_PAYOUT_INFO, err?.data.result);
      const senderErrors = this.handleWorkflowErrors(FIELD_CATEGORIES.SENDER, MONEY_TRANSFER_STEPS.SENDER_INFO, err?.data.result);
      const receiverErrors = this.handleWorkflowErrors(FIELD_CATEGORIES.RECEIVER, null, err?.data.result);

      if (payerErrors.hasErrors || senderErrors.hasErrors || receiverErrors.hasErrors) {
        throw new SubmissionError({
          ...payerErrors.errors,
          ...senderErrors.errors,
          ...receiverErrors.errors,
        });
      } else if (err?.data?.errors.length > 0) {
        this.setState({
          errorModalMessage: err.data.errors[0].errorMessage,
          showGenericErrorModal: true
        });
      } else {
        this.setState({
          errorModalMessage: COMMON.ERROR_CONTACT_SUPPORT,
          showGenericErrorModal: true
        });
      }
    }
  }

  handleFraudWarningAccepted() {
    trackEvent(EVENTS.MONEY_TRANSFER.FRAUD_WARNING_ACKNOWLEDGED, {});

    this.onNext();
  }

  render() {
    const { currentStep } = this.state;

    return (
      <div className="money-transfer v2">
        {currentStep.type !== MONEY_TRANSFER_STEPS.SUCCESS &&
          <h1>{PAGES.MONEY_TRANSFER.CASH_PICKUP}</h1>
        }

        <RequireCard>
          <div className={`${currentStep.type !== MONEY_TRANSFER_STEPS.SUCCESS ? "page-block" : ""}`}>
            {currentStep.title ? (
              <h2 className="spacing-bottom-medium">
                {currentStep.title}
              </h2>
            ) : null}
            {currentStep.renderComponent()}
          </div>

          {this.telemarketerSalesRule ? (
            <TelemarketerSalesRule
              show={this.state.showTelemarketerSalesRule}
              rule={this.telemarketerSalesRule}
              onRulePassed={this.handleTelemarketerSalesRulePassed}
              onRuleFailed={this.handleTelemarketerSalesRuleFailed}
            />
          ) : null}

          <Modal
            title={COMMON.GENERIC_ERROR_MODAL_HEADER}
            open={this.state.showGenericErrorModal}
            content={this.state.errorModalMessage}
            small={true}
            onClose={() => this.closeGenericErrorModal()}
            actions={[
              {
                title: COMMON.CONTACT_SUPPORT,
                displayType: ACTION_DISPLAY_TYPES.PRIMARY,
                onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
              },
              {
                title: COMMON.BACK_TO_DASHBOARD,
                displayType: ACTION_DISPLAY_TYPES.SECONDARY,
                onClick: () => (location.href = ROUTES.EXTERNAL.DASHBOARD),
              },
            ]}
          />
        </RequireCard>
      </div>
    );
  }

  static propTypes = {
    match: PropTypes.any,
    GetProviderRates: PropTypes.any,
    providerRates: PropTypes.object,
    sendAmount: PropTypes.any,
    GetWorkflow: PropTypes.func,
    ClearWorkflow: PropTypes.func,
    ValidateWorkflow: PropTypes.func,
    Commit: PropTypes.func,
    transferValidation: PropTypes.object,
    completedTransfer: PropTypes.object,
    location: PropTypes.object,
    submit: PropTypes.func,
    workflow: PropTypes.object,
    sendCurrency: PropTypes.string,
    payoutNetworkFields: PropTypes.object,
    senderFields: PropTypes.object,
    receiverFields: PropTypes.object,
    profileValidationErrors: PropTypes.array,
    formFields: PropTypes.any,
    availableCountries: PropTypes.array,
    destroy: PropTypes.func
  };
}

function mapStateToProps(state) {
  const workflow = state.moneyTransfer.workflow;
  const formFields = getFormValues(PAGES.MONEY_TRANSFER.FORM_NAME)(state);

  return {
    sendAmount: state.sendAmount,
    providerRates: state.moneyTransfer.providerRates,
    transferValidation: state.moneyTransfer.transferValidation,
    completedTransfer: state.moneyTransfer.completedTransfer,
    workflow: state.moneyTransfer.workflow,
    sendCurrency: state.card.currencyCode,
    payoutNetworkFields:
      workflow && workflow.fieldCategories
        ? workflow.fieldCategories.find(
          (categories) => categories.id === FIELD_CATEGORIES.NETWORK_PAYER
        )
        : null,
    profileValidationErrors: state.user.profile.validationErrors,
    formFields,
    availableCountries: state.moneyTransfer.availableCountries
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      GetProviderRates,
      GetWorkflow,
      ClearWorkflow,
      ValidateWorkflow,
      Commit,
      submit,
      destroy
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyTransfer);
