import React from "react";
import { ASCII } from "../../constants/forms";
import PropTypes from "prop-types";

const ACCEPTED_CHARACTERS = [
  ASCII.ONE,
  ASCII.TWO,
  ASCII.THREE,
  ASCII.FOUR,
  ASCII.FIVE,
  ASCII.SIX,
  ASCII.SEVEN,
  ASCII.EIGHT,
  ASCII.NINE,
  ASCII.ZERO,
  ASCII.RETURN,
];

/**
 * Validates the input to only allow integer values.
 * @param {*} event The keypress event object
 */
const handleKeyPress = (event) => {
  if (ACCEPTED_CHARACTERS.indexOf(event.charCode) < 0) {
    event.preventDefault();
    return false;
  }
};

/**
 * Renders an integer field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const IntegerInput = ({ ...props }) => {
  return (
    <input
      id={props.id}
      type={props.isSensitive != null ? "password" : "text"}
      onKeyPress={handleKeyPress}
      {...props}
    />
  );
};

IntegerInput.propTypes = {
  isSensitive: PropTypes.bool,
  id: PropTypes.string,
};

export default IntegerInput;
