import * as types from "../constants/actionTypes";
import UserApi from "../api/user";

/**
 * Get's the navigation links and sets to state.
 * @returns {Function}
 * @constructor
 */
export function GetNavBar() {
  return async function (dispatch) {
    let navBarLinks = await UserApi.GetMenu();
    navBarLinks = navBarLinks.menuItems;
    dispatch({
      type: types.RECEIVE_NAVBAR_LINKS,
      navBarLinks,
    });
  };
}

/**
 * Get's the footer links and sets to state.
 * @returns {Function}
 * @constructor
 */
export function GetFooterLinks() {
  return async (dispatch, state) => {
    const { user } = state();
    let links;

    if (user?.isAuthenticated) {
      links = await UserApi.GetAuthFooter();
    } else {
      links = await UserApi.GetNonAuthFooter();
    }

    dispatch({
      type: types.RECEIVE_FOOTER_LINKS,
      links: links.footerItems,
      issuerStatement: links.issuerStatement
    });
  };
}
