import find from "lodash/find";
import { SELECTION_TYPES } from "../constants/enums";

const store = window.localStorage;

/**
 * Manages selections a user has made
 */
class UserSelectionsApi {
  /**
   * Gets stored options by selection type
   *
   * @param type SELECTION_TYPES enum
   * @returns {string | Array}
   * @constructor
   */
  static GetOptionsByType(type) {
    const options = store.getItem(type);
    if (!options) return [];
    return JSON.parse(options);
  }

  static GetAllOptions() {
    let options = {};
    // eslint-disable-next-line no-unused-vars
    for (const type in SELECTION_TYPES) {
      let option = store.getItem(type);
      if (option) {
        options[type] = JSON.parse(option);
      }
    }
    return options;
  }

  /**
   * Add a select option to the store by type
   *
   * @param option Option object to add
   * @param type SELECTION_TYPES enum
   */
  static AddOptionByType(option, type) {
    let options;
    let existing = store.getItem(type);
    if (existing) {
      options = JSON.parse(existing);
    } else {
      options = [];
    }

    if (find(options, (o) => o.label === option.label)) {
      return;
    }

    options.push(option);
    store.setItem(type, JSON.stringify(options));
  }
}

export default UserSelectionsApi;
