import React from "react";
import PropTypes from "prop-types";
import { useFocusedLayout } from "../../services/location";

const Content = (props) => {
  const isFocused = useFocusedLayout();

  //Add focused class to container if focused view (hides border).
  let className = `page-layout-nav__content${isFocused ? " focused" : ""}`;
  return <div className={`${className} ${props.className}`}>{props.children}</div>;
};

const PageNav = (props) => {
  const isFocused = useFocusedLayout();

  //Don't show page links if focused view.
  return isFocused ? null : props.children;
};

const LayoutWithNav = (props) => {
  return <div className="page-layout-nav">{props.children}</div>;
};

LayoutWithNav.PageNav = PageNav;
LayoutWithNav.Content = Content;

Content.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

PageNav.propTypes = {
  children: PropTypes.any,
};

LayoutWithNav.propTypes = {
  children: PropTypes.any,
};

export default LayoutWithNav;
