import React from "react";
import { connect } from "react-redux";
import Select, { components } from "react-select";
import PropTypes from "prop-types";
import { DROPDOWN_STYLES } from "../../../constants/theme";
import { GetCountries } from "../../../actions/catalog";
import IntegerInput from "../IntegerInput";

const SingleValue = ({ ...props }) => {
  return (
    <components.SingleValue {...props}>
      <span className="text-muted">+</span> {props.data.value.phoneCode}
    </components.SingleValue>
  );
};

SingleValue.propTypes = {
  data: PropTypes.object,
};

const Option = ({ ...props }) => {
  let className = "text-muted phone-country-code-select-option-label";
  if (props.isSelected) {
    className += " selected";
  }

  return (
    <components.Option {...props}>
      <span className={className}>+{props.data.value.phoneCode}</span>{" "}
      {props.data.label}
    </components.Option>
  );
};

Option.propTypes = {
  data: PropTypes.object,
  isSelected: PropTypes.bool,
};

/**
 * Renders a text field and phone country picker
 */
class PhoneNumberField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      phoneCountry: props.input.value ? props.input.value.country : null,
      phoneNumber: props.input.value ? props.input.value.number : null,
    };

    this.handlePhoneCountryChange = this.handlePhoneCountryChange.bind(this);
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);

    this.props.GetCountries();
  }

  componentDidUpdate(prevProps) {
    const { country, number } = this.props.input.value;
    if (
      country !== prevProps.input.value.country ||
      number !== prevProps.input.value.number
    ) {
      let countryData;

      if (country) {
        countryData = this.props.countries.filter(
          (item) => item.id === country.id
        )[0];
      }
      this.setState({
        phoneCountry: countryData,
        phoneNumber: number,
      });
    }
  }

  /**
   * The masking library includes the mask characters in the value
   * This function strips those characters out and stores the value in state.
   * @param {object} event The change event for the phone number field
   */
  handlePhoneNumberChange(event) {
    let originalValue = event.target.value;
    let sanitizedValue = originalValue.replace(/_/g, "");

    this.setState({ phoneNumber: sanitizedValue }, this.updateInputValue);
  }

  /**
   * The change event for the phone country.
   * Stores the selected country in state.
   * Values come in the format
   * { value: ABC, label: XYZ }
   * @param {object} value
   */
  handlePhoneCountryChange(value) {
    this.setState({ phoneCountry: value.value }, this.updateInputValue);
  }

  /**
   * Collects both the country value and phone number value and concatinates them.
   */
  updateInputValue() {
    const phoneNumber = {
      number: this.state.phoneNumber,
      country: this.state.phoneCountry,
    };

    this.props.input.onChange(phoneNumber);
  }

  /**
   * Lifecycle method to render the component.
   */
  render() {
    /// This formats the display label for the fields to include the phone country.
    /// EX: United States of America +1
    let options =
      this.props?.countries?.length
        ? this.props.countries.map((c) => {
          return { label: `${c.name}`, value: c };
        })
        : [];
    /// Masking format comes from
    /// https://github.com/insin/inputmask-core
    /// We decided not to do masking right now but it will come soon so I'm leaving this here.
    // let mask = `111 111 1111`;

    /// The React-Select dropdown menu by default is the width of the dropdown control.
    /// For phone numbers the dropdown will be tiny but the options are full country names.
    /// This style sets the dropdown size to 300. This is a made up value that looked good
    /// with the default font size. This won't necessarily be correct for localized values
    /// or if font sizes change for accessibility.
    let dropdownStyles = {
      ...DROPDOWN_STYLES,
      menu: (styles) => ({
        ...styles,
        width: 300,
      }),
    };

    return (
      <div className="phone-number-field" data-testid="phone-number-field" data-cy="phone-number-field">
        <div>
          <Select
            components={{ SingleValue, Option }}
            styles={dropdownStyles}
            options={options}
            placeholder="+"
            isLoading={!options.length}
            onChange={this.handlePhoneCountryChange}
            value={
              this.state.phoneCountry
                ? options.filter(
                  (o) => o.value.iso == this.state.phoneCountry.iso
                )[0]
                : null
            }
          />
        </div>
        <div>
          <IntegerInput
            className="form-control"
            maxLength={this.props.maxLength}
            value={this.state.phoneNumber}
            onChange={this.handlePhoneNumberChange}
            id={this.props.id}
          />
        </div>
      </div>
    );
  }
}

PhoneNumberField.propTypes = {
  input: PropTypes.object,
  countries: PropTypes.array,
  GetCountries: PropTypes.func,
  maxLength: PropTypes.number,
  id: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    countries: state.catalog.countries,
  };
}

export default connect(mapStateToProps, { GetCountries })(PhoneNumberField);
