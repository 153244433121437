import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Action from "../../components/Common/Action";
import SuccessCheckmark from "../../components/Common/Success/SuccessCheckmark";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import { COMMON, PAGES } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker } from "../../services/eventTracker";

class LoginSupport extends Component {
  constructor(props) {
    super(props);

    this.goToLogin = this.goToLogin.bind(this);
    this.goToCannotAccessEmail = this.goToCannotAccessEmail.bind(this);
  }

  goToLogin() {
    location.href = ROUTES.AUTH.LOGIN;
  }

  goToCannotAccessEmail() {
    if (this.props.title == PAGES.AUTH.SUPPORT.FACECHECK_SUPPORT_TICKET) {
      eventTracker.track("FaceCheck_NoEmailAccess");
    }

    const { history } = this.props;
    history.push(ROUTES.AUTH.SUPPORT_EMAIL);
  }

  render() {
    const { title, subtitle, content, showCannotAccessEmail, ticketInfo } =
      this.props;
    return (
      <div>
        <div className="container container-md login-support">
          <div className="row">
            <div className="col-sm-12">
              <h1>{title}</h1>
            </div>
          </div>
          <div className="v2 page-block md">
            <div className="text-center">
              <SuccessCheckmark />
              <h2 className="spacing-top-small">{subtitle}</h2>
              <p className="spacing-bottom-medium small">
                <b>{ticketInfo}</b>
              </p>
              <div
                className="spacing-bottom-large content"
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <Action
                onClick={this.goToLogin}
                className="spacing-bottom-medium btn-lg"
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                title={COMMON.BACK_TO_LOGIN}
              />
              <br />
              {showCannotAccessEmail && (
                <Action
                  onClick={this.goToCannotAccessEmail}
                  className="spacing-bottom-medium btn-lg"
                  displayType={ACTION_DISPLAY_TYPES.LINK}
                  title={PAGES.AUTH.SUPPORT.CANNOT_ACCESS_EMAIL}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginSupport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  ticketInfo: PropTypes.string,
  showCannotAccessEmail: PropTypes.bool,
  history: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    title: state.auth.support.title,
    subtitle: state.auth.support.subtitle,
    content: state.auth.support.content,
    ticketInfo: state.auth.support.ticketInfo,
    showCannotAccessEmail: state.auth.support.showCannotAccessEmail,
  };
}

export default connect(mapStateToProps)(LoginSupport);
