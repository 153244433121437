export const EVENTS = {
  PARAMS: {
    SUCCESS: 'success',
    FAILURE_REASON: 'failure_reason',
    DESTINATION_COUNTRY: 'destination_country',
    DESTINATION_CURRENCY: 'destination_currency',
    SEND_AMOUNT: 'send_amount',
    REMITTANCE_SERVICE_PROVIDER: 'remittance_service_provider',
    EXCHANGE_RATE: 'exchange_rate',
    RECEIVE_AMOUNT: 'receive_amount',
    TRANSFER_FEE: 'transfer_fee',
    TOTAL_COST: 'total_cost',
    USER_PROFILE: 'user_profile',
    CHANGE_PASSWORD: 'change_password',
    SECURITY_QUESTIONS: 'security_questions',
    COMM_PREFS: 'comm_prefs',
    DISCLOSURES: 'disclosures',
    DISCLOSURES_NAME: 'disclosures_name',
    WU_TO_ACCOUNT_CORRIDOR_SELECTED_VALUE: "wutoaccount_corridor_selected_value",
    WU_TO_ACCOUNT_TRANSFER_AMOUNT: "wutoaccount_transfer_amount",
    WU_TO_ACCOUNT_SEND_AMOUNT: "wutoaccount_send_amount",
    WU_TO_ACCOUNT_RECEIVE_AMOUNT: "wutoaccount_receive_amount",
    WU_TO_ACCOUNT_FEE_AMOUNT: "wutoaccount_fee_amount",
    WU_TO_ACCOUNT_TOTAL_COST: "wutoaccount_total_cost",
    WU_TO_ACCOUNT_BANK_NAME: "wutoaccount_bank_name"
  },
  PARAM_VALUES: {
    USER_LOCKED_OUT: 'User is locked out',
    INCORRECT_PIN: 'User entered incorrect pin',
    TRUE: true,
    FALSE: false,
  },
  MONEY_TRANSFER: {
    STARTED: 'cash_pickup_started',
    AMOUNT_SUBMITTED: 'cash_pickup_amount_submitted',
    QUOTE_SELECTED: 'cash_pickup_quote_selected',
    SENDER_DETAILS_SUBMITTED: 'cash_pickup_sender_details_submitted',
    RECEIVER_DETAILS_SUBMITTED: 'cash_pickup_receiver_details_submitted',
    FRAUD_WARNING_ACKNOWLEDGED: 'cash_pickup_fraud_warning_acknowledged',
    TERMS_ACCEPTED: 'cash_pickup_terms_accepted',
    REVIEW_AND_SEND: 'cash_pickup_review_and_send',
    PIN_ID: 'cash_pickup_pin_id',
    SUBMITTED: 'cash_pickup_submitted',
  },
  LOGIN: {
    USERNAME_SUBMITTED: 'login_username_submitted',
    FACECHECK_STARTED: 'login_facecheck_started',
    FACECHECK_SDK_INITIALIZED: 'login_facecheck_sdk_initialized',
    FACECHECK_FACESCAN_COMPLETED: 'login_facecheck_facescan_completed',
    FACECHECK_SUBMITTED: 'login_facecheck_submitted',
    FACECHECK_STEP1_COMPLETED: 'login_facecheck_step1_completed',
    FACECHECK_STEP2_COMPLETED: 'login_facecheck_step2_completed',
    PASSWORD_SUBMITTED: 'login_password_submitted',
    SECURITY_QUESTION_SUBMITTED: 'login_security_question_submitted',
  },
  ENROLLMENT: {
    STARTED: 'enrollment_started',
    ID_VERIFICATION_SUBMITTED: 'enrollment_id_verification_submitted',
    ACCOUNT_CREATED: 'enrollment_account_created',
    SECURITY_QUESTIONS_SUBMITTED: 'enrollment_security_questions_submitted',
    PERSONAL_DATA_SUBMITTED: 'enrollment_personal_data_submitted',
    CARD_DETAILS_SUBMITTED: 'enrollment_card_details_submitted',
    COMMUNICATIONS_SUBMITTED: 'enrollment_communications_submitted',
  },
  ODT: {
    BANK_TRANSFER_STARTED: 'bank_transfer_started'
  },
  MY_CARD: {
    SELECTED: 'my_card_selected'
  },
  LOCK_CARD: {
    LOCK_CARD_SUBMITTED: 'lock_card_submitted',
    UNLOCK_CARD_SUBMITTED: 'unlock_card_submitted'
  },
  SUPPORT: {
    SUPPORT_SELECTED_LOGGED_OUT_SUPPORT: "support_selected_logged_out_support",
    RETURN_LOGIN_LOGGED_OUT_SUPPORT: "return_login_logged_out_support",
    CONTINUE_SUPPORT_LOGGED_OUT_SUPPORT: "continue_support_logged_out_support",
    SUPPORT_SUBMITTED_LOGGED_OUT_SUPPORT: "support_submitted_logged_out_support",
    SUPPORT_SELECTED_CREW_LINK: "support_selected_crew_link",
    CREW_LINK_SELECTED: "crew_link_selected",
    CREW_LINK_SELECTED_MESSAGE_CENTER: "crew_link_selected_message_center",
  },
  USER_PROFILE: {
    PROFILE_SETTINGS_MENU_CLICK: "profile_settings_menu_click",
    PROFILE_DETAILS_SAVE: "profile_details_save",
    PROFILE_PASSWORD_SAVE: "profile_password_save",
    PROFILE_COMM_PREF: "profile_comm_pref",
    PROFILE_DISCLOSURES_VIEW: "profile_disclosures_view"
  },
  TO_ACCOUNT: {
    STARTED: "wutoaccount_started",
    CORRIDOR_SELECTED: "wutoaccount_corridor_selected",
    EXCHANGE_RATE_COMPLETED: "wutoaccount_exchangerate_completed",
    FRAUD_WARNING_ACCEPTED: "wutoaccount_fraud_warning_accepted",
    SENDER_INFO_COMPLETED: "wutoaccount_sender_info_completed",
    RECEIVER_INFO_COMPLETED: "wutoaccount_receiver_info_completed",
    BANK_INFO_COMPLETED: "wutoaccount_bank_info_completed",
    REVIEW_CONFIRM_COMPLETED: "wutoaccount_review_confirm_completed",
    RECEIPT_VIEWED: "wutoaccount_receipt_viewed",
    RECEIPT_DOWNLOADED: "wutoaccount_receipt_downloaded",
    PRIVACY_POLICY_VIEWED: "wutoaccount_wupp_downloaded",
    TERMS_VIEWED: "wutoaccount_wutac_downloaded",
    ESIGN_VIEWED: "wutoaccount_esign_downloaded",
    ANOTHER_TRANSFER_SELECTED: "wutoaccount_receipt_another_transfer",
    DONE_SELECTED: "wutoaccount_receipt_done_selected",
  }
}