import React from "react";
import PropTypes from "prop-types";

const ACCEPTED_CHARACTERS = "1234567890.";

/**
 * Validates input and ensures only numbers and decimals are typed.
 * Also ensures that only a single decimal is entered.
 * "Number" fields rely on keyCode instead of charCodes
 * @param {*} event The event object from the keypress
 */
const handleOnChange = (event, inputOnChange) => {
  let valid = true;
  let value = event.currentTarget.value;
  let acceptableValues = ACCEPTED_CHARACTERS.split("");
  let hasDot = false;
  for (let i of value) {
    if (
      acceptableValues.indexOf(i) < 0 ||
      (hasDot && i === ".")
    ) {
      event.preventDefault();
      valid = false;
    } else if (i === ".") {
      hasDot = true;
    }
  }

  if (valid) {
    inputOnChange(event);
  }
};

/**
 * Renders a decimal field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const DecimalField = ({ input }) => (
  <input
    {...input}
    onChange={(e) => handleOnChange(e, input.onChange)}
    type="text"
    className="form-control"
  />
);

DecimalField.propTypes = {
  input: PropTypes.any,
};

export default DecimalField;
