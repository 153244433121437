import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ENV from "../../constants/environment";
import { COMMON, PAGES } from "../../constants/localization";
import { SUCCESS_FIELDS, ICONS, MONEY_TRANSFER_DIRECTIVE_TYPES, ACTION_DISPLAY_TYPES, MONEY_TRANSFER_FIELD_IDS, MONEY_TRANSFER_STATUS } from "../../constants/enums";
import { SetMoneyTransferDetails } from "../../actions/moneyTransfer";
import * as _ from "lodash";
import MoneyTransferApi from "../../api/moneyTransfer";
import { RootState } from "../../types/redux";
import { Props } from "../../types/props";
import { AvailableAction, DisclaimerType, GetMoneyTransferDetailsResponse, TransferDetailsType } from "../../types/api/moneyTransfer";
import i18n from "../../i18n/config";

import TransactionSummary from "../../components/MoneyTransfer/TransactionSummary";
import Alert from "../../components/Common/Alerts/Alert";
import Icon from "../../components/Common/Icon";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import ActionLink from "../../components/Common/ActionLink";
import Spinner from "../../components/Common/SpinningLoader";
import Modal from "../../components/Common/Modal";
import { ROUTES } from "../../constants/clientRoutes";
import useClickExternalLink from "../../hooks/useClickExternalLink";

interface CustomProps extends Props {
  serviceType?: number;
}

const TransferDetails = (props: CustomProps) => {
  const dispatch = useDispatch();
  const openExternalLinksModal = useClickExternalLink();
  const transferDetails = useSelector((state: RootState) => state.moneyTransfer.transferDetails) as TransferDetailsType;
  const [pageLoading, setPageLoading] = useState(true);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [cancelModalLoading, setCancelModalLoading] = useState(false);
  const [cancelModalContent, setCancelmodalContent] = useState("");
  const [cancelModalTitle, setCancelModalTitle] = useState(<span>Cancel transaction</span>);
  const [transactionTimeField, setTransactionTimeField] = useState({ label: "", formattedValue: "" });
  const [cancelModalActions, setCancelModalActions] = useState([{
    title: "Go back",
    onClick: () => setOpenCancelModal(false),
    displayType: ACTION_DISPLAY_TYPES.LINK,
    className: "bold",
    disabled: cancelModalLoading
  },
  {
    title: PAGES.MY_CARD.TRANSACTION_DETAILS.CANCEL_MODAL_ACTION_TITLE,
    onClick: () => handleCancelTransaction(),
    displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    className: "warning bold",
    disabled: cancelModalLoading
  }]);
  const [cancelIsSuccessful, setCancelIsSuccessful] = useState(false);
  const [displayErrorModal, setDisplayErrorModal] = useState(false);

  /**
   * Get Transfer Details on initial render
   */
  useEffect(() => {
    getTransferDetails();
  }, [])

  /**
   * Watch for changes to transferDetails in Redux
   * if we have a moneyTransferId, then API was successfull, display details
   * If we have errors (not getting transfer details back), display error modal and stop <Spinner />
   */
  useEffect(() => {
    if (transferDetails.moneyTransferId) {
      const statusField = transferDetails.displayFields.find(field => field.id === MONEY_TRANSFER_FIELD_IDS.TRANSACTION_STATUS);

      // Don't change cancel modal content if status is now cancelled. 
      if (statusField.formattedValue !== MONEY_TRANSFER_STATUS.CANCELED && statusField.formattedValue !== MONEY_TRANSFER_STATUS.CANCELLED) {
        const name = transferDetails.displayFields.find(field => field.id === MONEY_TRANSFER_FIELD_IDS.TRANSACTION_RECEIVER_NAME).formattedValue;
        setCancelmodalContent(`${PAGES.MY_CARD.TRANSACTION_DETAILS.CANCEL_MODAL_CONTENT}${name}`);
      }

      const transactionTimeFieldObj = transferDetails.displayFields.find(field => field.id == SUCCESS_FIELDS.TRANSFER_DATE);
      setTransactionTimeField(transactionTimeFieldObj);
      setPageLoading(false);
    }
  }, [transferDetails])

  const getTransferDetails = async () => {
    window.scrollTo(0, 0);
    const { moneyTransferId } = props.match.params;
    const { serviceType } = props;
    try {
      const response = await MoneyTransferApi.GetTransferDetails(
        moneyTransferId,
        serviceType
      ) as GetMoneyTransferDetailsResponse;
      dispatch(SetMoneyTransferDetails(response));
    } catch (err) {
      setPageLoading(false);
      setDisplayErrorModal(true);
    }
  }

  const renderAction = (action: AvailableAction, actionArr: string[]) => {
    return (
      <Alert
        alertClasses={"alert-info alert-bkgrd-white spacing-top-small flex-row font-regular"}
        isHTML={true}
        icon={ICONS.INFO}
        iconClasses={"spacing-right-tiny"}
        key={action.title}
      >
        <p className="bold">{action.title}</p>

        {actionArr.map(item => {
          const directiveObj = action.directives.find(directive => `{${directive.id}}` === item);
          return directiveObj
            ? <>
              {createDirectiveElement(directiveObj)}
            </>
            : <span key={item} dangerouslySetInnerHTML={{ __html: item }} />;
        })}
      </Alert>
    )
  }

  /**
   * Render <Alert /> component with appropriate text and directives 
   */
  const renderAvailableActions = () => {
    const availableActions = transferDetails.availableActions.map((action: AvailableAction) => {
      const pattern = /\{([^}]+)\}/g;
      const matches = action.text.match(pattern);

      let splitString = action.text
      let arr = [];

      if (matches) {
        matches.forEach((match: string) => {
          const temp = splitString.split(match);

          arr.push(temp[0]);
          arr.push(match);
          splitString = temp[1];
        })
      } else {
        arr = [action.text];
      }

      const hasCancelDirective = action.directives.find(directive => directive.type === MONEY_TRANSFER_DIRECTIVE_TYPES.CANCEL_MONEY_TRANSFER);

      if (hasCancelDirective && cancelIsSuccessful) {
        return null;
      }

      return renderAction(action, arr);
    })

    return availableActions;
  }

  /**
   * Build the disclaimers to display
   * @param {*} disclaimers
   */
  const renderDisclaimers = (disclaimers: DisclaimerType[]) => {
    if (disclaimers && disclaimers.length) {
      return disclaimers.map((d) => {
        return (
          <p key={d.disclaimerId} className="x-small disclaimer">
            <sup>{d.disclaimerId} </sup>
            {d.text}
          </p>
        );
      });
    }
  }

  /**
   * Get Receipt from API 
   */
  const handleGetReceipt = async () => {
    const moneyTransferId = transferDetails.moneyTransferId;
    const referenceNumberField = _.find(
      transferDetails.displayFields,
      (f) => f.id == SUCCESS_FIELDS.REFERENCE_NUMBER
    );
    const referenceNumber = referenceNumberField?.formattedValue;

    try {
      await MoneyTransferApi.GetReceipt(moneyTransferId, referenceNumber, props.serviceType);
    } catch (err) {
      setDisplayErrorModal(true);
    };
  }

  /**
   * Returns <a /> or React component based on directive type
   * @param {object} directive object containing id, text, type, url
   */
  const createDirectiveElement = (directive) => {
    const { text, type, url } = directive;

    if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.EXTERNAL_LINK) {
      return (
        <a
          key={directive.id}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => openExternalLinksModal(e, url)}
          title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
          className="">
          {text}
        </a>
      )
    } else if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.CANCEL_MONEY_TRANSFER) {
      return (
        <ActionLink
          text={text}
          clickFunc={() => setOpenCancelModal(true)}
          key={directive.id}
          classes={"bold"}
        />
      )
    } else if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.SUPPORT) {
      return (
        <ActionLink
          text={text}
          href={`${ENV.BASE_NAVIGATOR_URL}/support`}
          key={directive.id}
          classes={"bold"}
        />
      )
    }
  }

  /**
   * Handle Cancel Transaction button click
   * Depending on if API call is successful or not, Modal title,content, and actions should change further guide user
   */
  const handleCancelTransaction = async () => {
    setCancelModalLoading(true);
    setCancelModalActions([
      { ...cancelModalActions[0], disabled: true },
      { ...cancelModalActions[1], disabled: true }
    ])
    try {
      const response = await MoneyTransferApi.CancelTransaction(props.match.params.moneyTransferId);
      setCancelModalTitle(<><Icon icon={ICONS.GREEN_CHECK} /> <span>Transaction canceled</span></>);
      setCancelmodalContent(response.message);
      setCancelModalActions([
        {
          ...cancelModalActions[1],
          className: "bold",
          title: "Close",
          onClick: () => setOpenCancelModal(false),
        }
      ])
      setCancelModalLoading(false);
      setCancelIsSuccessful(true);
      getTransferDetails();
    } catch (err) {
      setCancelmodalContent(err?.data?.errors[0].errorMessage);
      setCancelModalTitle(<><Icon icon={ICONS.WARNING_INFO} /> <span>Transaction not cancelled</span></>);
      setCancelModalActions([
        { ...cancelModalActions[0] },
        {
          ...cancelModalActions[1],
          className: "bold",
          title: "Try again"
        }
      ]);
      setCancelModalLoading(false);
    }
  }

  if (!transferDetails.moneyTransferId) {
    return (
      <>
        <Spinner show={pageLoading} />
        <GenericErrorModal open={displayErrorModal} onClose={() => setDisplayErrorModal(false)} />
      </>
    )
  }

  return (
    <div className="money-transfer v2">
      <h1>{PAGES.MONEY_TRANSFER.DETAILS.TRANSACTION_DETAILS}</h1>
      <div className="page-block">
        <div className="content-block spacing-bottom-none">
          <p className="large">{transactionTimeField.label}</p>
          <p className="large bold">{transactionTimeField.formattedValue}</p>
        </div>

        {transferDetails.availableActions &&
          renderAvailableActions()
        }

        <TransactionSummary
          transfer={transferDetails}
          onGetReceipt={() => handleGetReceipt()}
        />

        <div className="content-block spacing-top-small">
          <div className="spacing-bottom-small">
            <ActionLink
              href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.MY_CARD.INDEX}`}
              classes="mt-link"
            >
              <div className="glyphicon glyphicon-menu-left link_icon"></div>
              {COMMON.BACK}
            </ActionLink>
          </div>

          {renderDisclaimers(transferDetails.disclaimers)}
        </div>
      </div>

      {transferDetails &&
        <Modal
          open={openCancelModal}
          onClose={() => setOpenCancelModal(false)}
          className="modal-title-flex"
          title={cancelModalTitle}
          actions={cancelModalActions}
          content={cancelModalContent}
          contentLoading={cancelModalLoading}
        />
      }
    </div >
  )
}

export default TransferDetails;