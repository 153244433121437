import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector, useDispatch } from "react-redux";
import { reduxForm, getFormValues, SubmissionError, isValid } from "redux-form";
import { FIELD_TYPES, ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import { COMMON, PAGES } from "../../../constants/localization";
import EnrollmentApi from "../../../api/enrollment";
import FlowScreen from "../../../components/Layouts/FlowScreen";
import GenericForm from "../../../components/Common/DynamicFields/GenericForm";
import PageBlock from "../../../components/Layouts/PageBlock";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import ActionRow from "../../../components/Layouts/ActionRow";
import Action from "../../../components/Common/Action";
import ActionLink from "../../../components/Common/ActionLink";
import DynamicInputField from "../../../components/Common/DynamicFields/DynamicInputField";
import { validate } from "./ValidatePersonalDataForm";
import { UpdateCurrentPage } from "../../../actions/enrollment";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";
import { mapErrorsToFields } from "../../../services/formatter";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import i18n from "../../../i18n/config";

const PersonalDataForm = ({ setShowSpinner, ...props }) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const formData = useSelector((state) => state.form[PAGES.ENROLLMENT.PERSONAL_DATA.FORM_NAME]);
  const valid = useSelector((state) => isValid(PAGES.ENROLLMENT.PERSONAL_DATA.FORM_NAME)(state));
  const userData = useSelector((state) => state.enrollment.userData);
  const currentPage = useSelector((state) => state.enrollment.currentPage);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  /**
   * Get country and state data for dropdowns
   */
  useEffect(() => {
    getAllCountries(false);
    getStates();
  }, []);

  /**
   * Get country data and format country objects correctly for FIELD_TYPES.BOOL
   */
  const getAllCountries = async (forPhone) => {
    try {
      const response = await EnrollmentApi.GetAllCountries(forPhone);

      const countries = response.map((country) => ({
        label: country.text,
        value: country.value,
      }));

      setCountries(countries);
    } catch (err) {
      handleDisplayErrorModal(err);
    }
  };

  /**
   * Get state data
   */
  const getStates = async () => {
    try {
      const response = await EnrollmentApi.GetSelectListItems(
        "GetAllStates?format=json"
      );

      const states = response.map((state) => ({
        label: state.text,
        value: state.value,
      }));

      setStates(states);
    } catch (err) {
      handleDisplayErrorModal(err);
    }
  };

  const handleDisplayErrorModal = (err) => {
    if (err?.data?.errors) {
      setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
    } else {
      setErrorMessage(i18n.t("COMMON.ERROR"));
    }

    setShowErrorModal(true);
  }

  /**
   * Submit form data
   */
  const onSubmit = async () => {
    setShowSpinner(true);

    const { values } = formData;

    const payload = {
      id: userData.workflowId,
      firstName: values.firstName,
      lastName: values.lastName,
      city: values.city,
      line1: values.line1,
      line2: values.line2,
      stateId: parseInt(values.stateId),
      province: values.province,
      zipCode: values.zipCode,
      countryId: parseInt(values.countryId),
      email: values.email,
      phoneCountryId: values.phoneNumber.country.id,
      phoneNumber: values.phoneNumber.number,
      mobileNumber: values.mobileNumber,
    }

    try {
      const response = await EnrollmentApi.SavePersonalData(payload);

      trackEvent(EVENTS.ENROLLMENT.PERSONAL_DATA_SUBMITTED, { success: true });
      setShowSpinner(false);
      dispatch(UpdateCurrentPage(response.nextPage));
    } catch (err) {
      if (err.length > 0) {
        // Format errors to display on form fields
        const errorsObj = mapErrorsToFields(err);
        setShowSpinner(false);
        trackEvent(EVENTS.ENROLLMENT.PERSONAL_DATA_SUBMITTED, { success: false, failure_reason: err[0].errorMessage });

        // Scroll to top of page
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });

        // Throw submission error to display errors on form fields
        throw new SubmissionError(errorsObj);
      } else {
        trackEvent(EVENTS.ENROLLMENT.PERSONAL_DATA_SUBMITTED, { success: false, failure_reason: PAGES.ENROLLMENT.ERROR_GENERAL });
        setShowSpinner(false);
        setErrorMessage(i18n.t("COMMON.ERROR"));
        setShowErrorModal(true);
      }
    }
  };

  return (
    <FlowScreen flowTitle="Personal Data">
      <form onSubmit={props.handleSubmit(onSubmit)}>
        <GenericForm.Body>
          <PageBlock>
            <PageBlock.IntroText>
              {
                "Confirm that your personal information is correct. An accurate mailing address is needed to make purchases online."
              }
            </PageBlock.IntroText>
            <PageBlock.Body>
              <InputWrapper className="row">
                <DynamicInputField
                  id="firstName"
                  name="firstName"
                  type={FIELD_TYPES.TEXT}
                  label="First Name"
                  required={true}
                  focus={true}
                  valid={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="lastName"
                  name="lastName"
                  type={FIELD_TYPES.TEXT}
                  label="Last Name"
                  required={true}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="line1"
                  name="line1"
                  type={FIELD_TYPES.TEXT}
                  label="Street Address 1"
                  required={true}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="line2"
                  type={FIELD_TYPES.TEXT}
                  label="Street Address 2"
                  required={false}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="city"
                  type={FIELD_TYPES.TEXT}
                  label="City/Town"
                  required={true}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="countryId"
                  type={FIELD_TYPES.COUNTRY}
                  label="Country"
                  required={true}
                  placeholderText="Select a Country"
                  options={countries}
                  focus={true}
                  isSearchable={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              {formData?.values?.countryId !== "228" && (
                <InputWrapper className="row">
                  <DynamicInputField
                    id="province"
                    type={FIELD_TYPES.TEXT}
                    label="Province"
                    required={true}
                    focus={true}
                    className="col-xs-12 col-sm-7"
                  />
                </InputWrapper>
              )}
              {formData?.values?.countryId === "228" && (
                <InputWrapper className="row">
                  <DynamicInputField
                    id="stateId"
                    type={FIELD_TYPES.COUNTRY}
                    label="State"
                    required={true}
                    placeholderText="Select a State"
                    options={states}
                    focus={true}
                    isSearchable={true}
                    className="col-xs-12 col-sm-7"
                  />
                </InputWrapper>
              )}
              <InputWrapper className="row">
                <DynamicInputField
                  id="zipCode"
                  type={FIELD_TYPES.TEXT}
                  label="Zip Code"
                  required={false}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                  hideOptional={true}
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="phoneNumber"
                  name="phoneNumber"
                  type={FIELD_TYPES.PHONE_NUMBER}
                  label="Phone Number"
                  required={true}
                  valid={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="mobileNumber"
                  type={FIELD_TYPES.BOOL}
                  label="Is this a mobile number? (check if yes)"
                  focus={true}
                  className="col-xs-12 col-sm-7"
                  hideOptional={true}
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="email"
                  type={FIELD_TYPES.TEXT}
                  label="Email"
                  required={true}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>
              <InputWrapper className="row">
                <DynamicInputField
                  id="confirmEmail"
                  type={FIELD_TYPES.TEXT}
                  label="Confirm Email"
                  required={true}
                  focus={true}
                  className="col-xs-12 col-sm-7"
                />
              </InputWrapper>

              <ActionRow>
                <ActionRow.Backward>
                  <ActionLink
                    clickFunc={() => dispatch(UpdateCurrentPage(currentPage - 1))}
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={!valid}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </form>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage}
      />
    </FlowScreen>
  );
};

PersonalDataForm.propTypes = {
  /** user data */
  userData: PropTypes.object.isRequired,
  /** function to show SpinnerLoader */
  setShowSpinner: PropTypes.func.isRequired,
  /** react-router history */
  history: PropTypes.object,
  /** redux-form valid boolean */
  valid: PropTypes.bool,
  /** redux-form submit handler, will check validation on submit */
  handleSubmit: PropTypes.object,
};

PersonalDataForm.displayName = "PersonalDataFormComponent";

let mapStateToProps = (state, props) => {
  if (props?.userData?.workflow) {
    let stateId = null;
    if (props.userData.workflow.stateId) {
      stateId = props.userData.workflow.stateId.toString();
    }
    return {
      formValues: getFormValues(PAGES.ENROLLMENT.PERSONAL_DATA.FORM_NAME)(state),
      initialValues: {
        firstName: props.userData.workflow.firstName,
        lastName: props.userData.workflow.lastName,
        line1: props.userData.workflow.line1,
        line2: props.userData.workflow.line2,
        city: props.userData.workflow.city,
        countryId: props.userData.workflow.countryId.toString(),
        province: props.userData.workflow.province,
        stateId: stateId,
        zipCode: props.userData.workflow.zipCode,
        phoneNumber: {
          number: props.userData.workflow.phoneNumber,
          country: {
            id: props.userData.workflow.phoneCountryId,
          },
        },
        mobileNumber: props.userData.workflow.mobileNumber,
        email: props.userData.workflow.email,
        confirmEmail: props.userData.workflow.confirmEmail,
      },
    };
  } else {
    return {
      formValues: getFormValues(PAGES.ENROLLMENT.PERSONAL_DATA.FORM_NAME)(state),
      initialValues: props.userData,
    };
  }
};

const form = reduxForm({
  form: PAGES.ENROLLMENT.PERSONAL_DATA.FORM_NAME,
  validate,
  enableReinitialize: true,
})(PersonalDataForm);

export default connect(mapStateToProps)(form);
