import React from "react";
import PropTypes from "prop-types";
import Message from "./Message";

/**
 *
 * @param message
 * @param requesterId
 * @param dayItem
 * @returns {jsx}
 * @constructor
 */
export const MessageListItem = ({ message, requesterId, dayItem }) => {
  const { text, sender, timestamp } = message;
  const isUser = requesterId.toString() === sender.senderId.toString();
  return (
    <li key={message.messageId} className="message-item">
      {dayItem}
      <Message
        text={text}
        name={sender.name}
        icon={sender.icon}
        isUser={isUser}
        timestamp={timestamp}
        attachments={message.attachments}
      />
    </li>
  );
};

MessageListItem.propTypes = {
  /** The message to display {text, sender, timestamp, attachments} */
  message: PropTypes.object.isRequired,
  /** Id of the requester */
  requesterId: PropTypes.string.isRequired,
  /** JSX display of message datetime */
  dayItem: PropTypes.object,
};

export default MessageListItem;
