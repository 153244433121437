import React, { Component } from "react";
import PropTypes from "prop-types";
import PageBlock from "../../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../../constants/localization";
import ActionRow from "../../../components/Layouts/ActionRow";
import InputWrapper from "../InputWrapper";
import MobilePINInput from "./MobilePINInput";
import Action from "../../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";

const ErrorMessage = (props) => {
  return props.visible ? (
    <div className="mobile-pin-inputs__error text-danger">{props.children}</div>
  ) : null;
};

ErrorMessage.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node,
};

export default class ChangePINMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
    };

    this.handleFirstPINSubmit = this.handleFirstPINSubmit.bind(this);
    this.handleConfirmPINSubmit = this.handleConfirmPINSubmit.bind(this);
    this.handleFirstPINInput = this.handleFirstPINInput.bind(this);
    this.handleConfirmPINInput = this.handleConfirmPINInput.bind(this);
  }

  componentDidMount() {
    if (
      this.props.initialPIN != "" &&
      this.props.initialPIN.length == PAGES.PIN_RESET.PIN_LENGTH
    ) {
      this.setState({
        step: 2,
      });
    } else {
      this.setState({
        step: 1,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.errorMessage != "" &&
      prevProps.errorMessage == "" &&
      prevState.step == 2
    ) {
      this.setState({
        step: 1,
      });
    }
  }

  handleFirstPINSubmit(event) {
    event.preventDefault();
    if (
      this.props.initialPIN != "" &&
      this.props.initialPIN.length == PAGES.PIN_RESET.PIN_LENGTH
    ) {
      this.setState({
        step: 2,
      });
    }
  }

  handleConfirmPINSubmit(event) {
    event.preventDefault();
    this.props.handleSubmit();
  }

  handleFirstPINInput = (pinInput) => {
    this.props.handleFirstPINChange(pinInput);
  };

  handleConfirmPINInput = (pinInput) => {
    this.props.handleConfirmPINChange(pinInput);
  };

  isButtonDisabled = () => {
    if (
      this.state.step == 1 &&
      this.props.initialPIN.length < PAGES.PIN_RESET.PIN_LENGTH
    ) {
      return true;
    } else if (
      this.state.step == 2 &&
      this.props.confirmPIN.length < PAGES.PIN_RESET.PIN_LENGTH
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const buttonDisabled = this.isButtonDisabled();
    return (
      <PageBlock>
        <PageBlock.Title>
          {this.state.step == 1
            ? PAGES.PIN_RESET.CREATE_NEW_PIN_HEADER
            : PAGES.PIN_RESET.REENTER_NEW_PIN_HEADER}
        </PageBlock.Title>
        <PageBlock.Body>
          <form
            id={this.state.step == 1 ? "FirstPINMobile" : "ConfirmPINMobile"}
            onSubmit={
              this.state.step == 1
                ? this.handleFirstPINSubmit
                : this.handleConfirmPINSubmit
            }
          >
            <InputWrapper
              className={`pin-input-wrapper ${
                this.props.errorMessage != "" ? "has-error" : ""
              }`}
            >
              <MobilePINInput
                onPINUpdate={
                  this.state.step == 1
                    ? this.handleFirstPINInput
                    : this.handleConfirmPINInput
                }
                currentPIN={
                  this.state.step == 1
                    ? this.props.initialPIN
                    : this.props.confirmPIN
                }
              />
              <ErrorMessage visible={this.props.errorMessage != ""}>
                {this.state.step == 2 ? "" : this.props.errorMessage}
              </ErrorMessage>
            </InputWrapper>
            <ActionRow>
              <ActionRow.Forward>
                <Action
                  title={COMMON.NEXT}
                  displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                  type="submit"
                  loading={this.props.loading}
                  disabled={buttonDisabled}
                />
              </ActionRow.Forward>
              <ActionRow.Backward>
                <a href={PAGES.PIN_RESET.CANCEL_URL}>{COMMON.CANCEL}</a>
              </ActionRow.Backward>
            </ActionRow>
          </form>
        </PageBlock.Body>
      </PageBlock>
    );
  }
}

ChangePINMobile.propTypes = {
  initialPIN: PropTypes.string,
  confirmPIN: PropTypes.string,
  errorMessage: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleFirstPINChange: PropTypes.func,
  handleConfirmPINChange: PropTypes.func,
  loading: PropTypes.bool,
};
