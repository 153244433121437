import React from "react";
import FlowScreen from "../../components/Layouts/FlowScreen";
import { PAGES, COMMON } from "../../constants/localization";
import UsernameEntry from "./UsernameEntry";
import PersonalInfoEntry from "./PersonalInfoEntry";
import { FORGOT_PASSWORD_STEPS, ERROR_TYPE } from "../../constants/enums";
import UserApi from "../../api/user";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import PasswordResetOptions from "./PasswordResetOptions";
import EmailAddressEntry from "./EmailAddressEntry";
import { REGEX } from "../../constants/regex";
import { connect } from "react-redux";
import { AddErrors, ClearErrors } from "../../actions/errors";
import { ROUTES } from "../../constants/clientRoutes";
import PropTypes from "prop-types";
import EmailSentSuccess from "../../components/Common/Success/EmailSentSuccess";
import FlowSuccess from "../../components/Common/Success/FlowSuccess";
import SuccessCheckmark from "../../components/Common/Success/SuccessCheckmark";
import ActionLink from "../../components/Common/ActionLink";
import ChangePasswordContainer from "./ChangePasswordContainer";
import {
  ResetUserPassword,
  UpdateAccountLockoutStatus,
} from "../../actions/user";
import { ERROR_CODES } from "../../constants/errorCodes";
import { getQueryVariableFromSearch } from "../../services/location";

class ForgotPasswordContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: "",
      username: "",
      hasError: false,
      errorMessage: "",
      steps: [],
      showGenericErrorModal: false,
      loadingPersonalInfo: false,
      emailAddress: "",
      loading: false,
      sessionInfo: {},
      rulesPassed: [],
      newPasswordSubmissionFailed: false,
      changePasswordResponse: {},
    };
  }

  /**
   * Takes care of things that should happen once
   * when the component is constructed and mounted.
   * In this case, we add a listener for the back
   * button and then figure out the first page of the flow.
   */
  componentDidMount() {
    this.backButtonListener = this.props.history.listen((location, action) => {
      if (action === "POP") {
        this.goBack();
      }
    });
    this.checkForUsernameAndSetStep();
  }

  /**
   * Takes care of clean up when the component unmounts.
   */
  componentWillUnmount() {
    // Unbind listener
    this.backButtonListener();
  }

  /**
   * Changes to the password success step if the change password response is successful.
   * @param {} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.changePasswordResponse !== this.props.changePasswordResponse
    ) {
      let response = this.props.changePasswordResponse;
      if (response?.success) {
        this.changeFlowStep(FORGOT_PASSWORD_STEPS.PASSWORD_SUCCESS);
      }
    }
  }

  /**
   * This method determines the first page in the workflow.
   * Checks if username is already in the URL. If so the
   * username value is set in state and the first step prompting
   * the user to enter username is skipped
   */
  checkForUsernameAndSetStep = () => {
    if (this.props.match.params.username) {
      this.setState({
        username: this.props.match.params.username,
      });
      this.changeFlowStep(FORGOT_PASSWORD_STEPS.RESET_OPTION);
    } else if (this.getPasswordValidationKeyFromUrl()) {
      const key = this.getPasswordValidationKeyFromUrl();
      if (key) {
        this.setState(
          {
            token: key,
          },
          this.submitPasswordResetToken
        );
      }
    } else {
      this.changeFlowStep(FORGOT_PASSWORD_STEPS.ENTER_USERNAME);
    }
  };

  /**
   * Gets the username from the URL.
   */
  getUsernameFromUrl = () => {
    return this.props.match.params.username;
  };

  /**
   * Gets the password validation token from the URL.
   */
  getPasswordValidationKeyFromUrl = () => {
    const { search } = this.props.location;
    const tokenValue = getQueryVariableFromSearch(search, "key");
    return tokenValue || "";
  };

  /**
   * Moves flow to the next step. This also adds to the browser's
   * history object so that clicking the browser back button takes
   * us back one step without changing pages.
   * @param {string} nextStep - Next step in flow to proceed to.
   */
  changeFlowStep = (nextStep) => {
    let { steps } = this.state;

    let { history } = this.props;
    history.push(history.location);

    steps.push(nextStep);
    this.props.clearError();

    if (nextStep === FORGOT_PASSWORD_STEPS.PERSONAL_INFO_ENTRY) {
      this.props.updateAccountLockoutStatus(false);
    }
    this.setState({
      steps,
      currentStep: steps[steps.length - 1],
    });
  };

  /**
   * Sets state's password reset token and proceeds to the next step.
   * @param {object} e - The form submission event.
   */
  submitPasswordResetToken = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { token } = this.state;
    if (token != null && token != "") {
      this.changeFlowStep(FORGOT_PASSWORD_STEPS.NEW_PASSWORD_ENTRY);
    }
  };

  /**
   * Submits values from the Personal Information
   * entry screen
   * @param {array} fields - Values from the Personal
   * Information entry form
   */
  submitPersonalInfo = async (fields) => {
    this.setState({ loading: true });

    try {
      let response = await UserApi.SubmitPersonalInfo(
        this.state.username,
        fields
      );
      if (response.verified) {
        const { userId, token, duration, passwordValidationRules } =
          response;
        this.setState(
          {
            sessionInfo: {
              token,
              userId,
              duration,
              passwordValidationRules,
            },
          },
          () => {
            this.changeFlowStep(FORGOT_PASSWORD_STEPS.NEW_PASSWORD_ENTRY);
            this.setState({
              loading: false,
            });
          }
        );
      }
    } catch (error) {
      let {
        data: { errors },
      } = error;
      this.props.addError(errors);
      if (error.status != 500) {
        this.setState({
          loading: false,
        });

        let accountLockError = errors.find((error) => {
          return error.errorCode == ERROR_CODES.USER.ACCOUNT_LOCKED;
        });

        if (accountLockError) {
          this.props.updateAccountLockoutStatus(true);
        }
      } else {
        this.setState({
          showGenericErrorModal: true,
          loading: false,
        });
      }
    }
  };

  /**
   * Sets state's email address value
   * @param {object} e - Input event object
   * @param {object} validate - Boolean representing whether
   *          to validate if the email is in the correct format
   */
  updateEmailAddress = (e, validate) => {
    let value = e.target.value;
    this.setState({
      emailAddress: value,
    });
    if (validate) {
      let regex = REGEX.EMAIL;
      if (regex.test(value)) {
        this.props.clearError();
      } else {
        this.props.addError([this.invalidEmailFormatError()]);
      }
    }
  };

  /**
   * Sets state's username value and proceeds to the next step.
   * @param {string} username - The username value
   */
  setUsernameAndProceedToNextStep = (username) => {
    this.setUsername(username);
    this.changeFlowStep(FORGOT_PASSWORD_STEPS.RESET_OPTION);
  };

  /**
   * Sets state's username address value.
   * @param {string} username - The username value
   */
  setUsername = (username) => {
    this.setState({
      username: username,
    });
  };

  /**
   * Sets state's username address value.
   * @param {object} e - Input event object
   */
  updateUsername = (e) => {
    this.setUsername(e.target.value);
  };

  /**
   * Toggles the display of the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  /**
   * Checks if form container email address input
   * is valid. Also acts as a backup for browsers that don't
   * support input types.
   */
  checkFormValidity = (formId) => {
    this.setState(
      {
        loading: true,
      },
      () => {
        if (!document.getElementById(formId).checkValidity()) {
          this.props.addError([this.invalidEmailFormatError()]);
          this.setState({
            loading: false,
          });
        }
      }
    );
  };

  /**
   * Ensures email address conforms to an email address pattern using regex.
   * If true submits event to mixpanel and submits email address to the API.
   */
  regexCheckEmailAddress = (e) => {
    e.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        /**
         * Email address regular expression
         */
        let regex = REGEX.EMAIL;
        if (regex.test(this.state.emailAddress)) {
          this.sendEmailAddress();
        } else {
          this.props.addError([this.invalidEmailFormatError()]);
          this.setState({
            loading: false,
          });
        }
      }
    );
  };

  /**
   * Creates error with proper format that matches errors from the API.
   */
  invalidEmailFormatError = () => ({
    errorMessage: PAGES.FORGOT_USERNAME.EMAIL_ENTRY_ERROR,
    errorType: ERROR_TYPE.Field,
    fieldId: "EmailInput",
  });

  /**
   * Sends the email address to the API.
   */
  sendEmailAddress = async () => {
    try {
      await UserApi.SubmitEmail(this.state.username, this.state.emailAddress);
      this.changeFlowStep(FORGOT_PASSWORD_STEPS.EMAIL_SUCCESS);
    } catch (error) {
      if (error.status != 500) {
        let {
          data: { errors },
        } = error;
        this.props.addError(errors);
        this.setState({
          loading: false,
        });
      } else {
        this.setState({
          showGenericErrorModal: true,
          loading: false,
        });
      }
    }
  };

  /**
   * Takes user back to previous screen.
   */
  goBack = () => {
    let { steps, currentStep } = this.state;
    let isSuccess =
      currentStep === FORGOT_PASSWORD_STEPS.PASSWORD_SUCCESS ||
      currentStep === FORGOT_PASSWORD_STEPS.EMAIL_SUCCESS;
    steps.pop();

    if (steps.length === 0 || isSuccess) {
      if (this.getUsernameFromUrl() || isSuccess) {
        // This means the user landed on this flow
        // with a username already submitted
        // OR
        // The user has successfully changed password.
        location.href = ROUTES.EXTERNAL.LOGIN;
      } else {
        this.props.history.goBack();
      }
    } else {
      this.props.clearError();
      this.setState({
        steps,
        currentStep: steps[steps.length - 1],
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <FlowScreen
          flowTitle={PAGES.FORGOT_PASSWORD.MASTER_CONTAINER_TITLE}
          className="forgot-password"
        >
          {this.state.currentStep == FORGOT_PASSWORD_STEPS.ENTER_USERNAME && (
            <UsernameEntry setUsername={this.setUsernameAndProceedToNextStep} />
          )}
          {this.state.currentStep == FORGOT_PASSWORD_STEPS.RESET_OPTION && (
            <PasswordResetOptions
              changeFlowStep={this.changeFlowStep}
              goBack={this.goBack}
            />
          )}
          {this.state.currentStep ==
            FORGOT_PASSWORD_STEPS.PERSONAL_INFO_ENTRY && (
              <PersonalInfoEntry
                username={this.state.username}
                submitPersonalInfo={this.submitPersonalInfo}
                goBack={this.goBack}
                isAccountLocked={this.props.isAccountLocked}
                toggleGenericErrorModal={this.toggleGenericErrorModal}
              />
            )}
          {this.state.currentStep == FORGOT_PASSWORD_STEPS.EMAIL_ENTRY && (
            <EmailAddressEntry
              errors={this.props.errors}
              username={this.state.username}
              emailAddress={this.state.emailAddress}
              submitEmailAddress={this.regexCheckEmailAddress}
              updateUsername={this.updateUsername}
              updateEmailAddress={this.updateEmailAddress}
              checkFormValidity={this.checkFormValidity}
              loading={this.state.loading}
              goBack={this.goBack}
            />
          )}
          {this.state.currentStep == FORGOT_PASSWORD_STEPS.EMAIL_SUCCESS && (
            <EmailSentSuccess id="ForgotPasswordEmailSuccess" />
          )}
          {this.state.currentStep == FORGOT_PASSWORD_STEPS.PASSWORD_SUCCESS && (
            <FlowSuccess>
              <FlowSuccess.Image>
                <SuccessCheckmark />
              </FlowSuccess.Image>
              <FlowSuccess.Title>
                {this.props.changePasswordResponse.title}
              </FlowSuccess.Title>
              <FlowSuccess.Body>
                {this.props.changePasswordResponse.message}
              </FlowSuccess.Body>
              <FlowSuccess.ActionRow>
                <ActionLink
                  dataCy="PasswordSuccessBackToLogin"
                  classes="btn btn-primary"
                  href={ROUTES.EXTERNAL.LOGIN}
                >
                  {COMMON.BACK_TO_LOGIN}
                </ActionLink>
              </FlowSuccess.ActionRow>
            </FlowSuccess>
          )}
          {this.state.currentStep ==
            FORGOT_PASSWORD_STEPS.NEW_PASSWORD_ENTRY && (
              <ChangePasswordContainer
                token={this.state.token}
                errors={this.props.errors}
                addError={this.props.addError}
                clearError={this.props.clearError}
                loading={this.state.loading}
                changePasswordResponse={this.props.changePasswordResponse}
                resetUserPassword={this.props.resetUserPassword}
                sessionInfo={this.state.sessionInfo}
              />
            )}
        </FlowScreen>
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addError: (errors) => {
      dispatch(AddErrors(errors));
    },
    clearError: () => {
      dispatch(ClearErrors());
    },
    resetUserPassword: (userId, token, password) => {
      dispatch(ResetUserPassword(userId, token, password));
    },
    updateAccountLockoutStatus: (isLockedOut) => {
      dispatch(UpdateAccountLockoutStatus(isLockedOut));
    },
  };
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    isAccountLocked: state.user.isAccountLocked,
    changePasswordResponse: state.user.changePasswordResponse,
  };
}

ForgotPasswordContainer.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  addError: PropTypes.func,
  errors: PropTypes.array,
  clearError: PropTypes.func,
  resetUserPassword: PropTypes.func,
  updateAccountLockoutStatus: PropTypes.func,
  invalid: PropTypes.bool,
  isAccountLocked: PropTypes.bool,
  changePasswordResponse: PropTypes.object,
  history: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
