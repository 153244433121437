import * as React from 'react';
import { DropdownType } from '../../../types/fields';

const ExampleIdModalContent = ({ idTypeList }: { idTypeList: DropdownType[] }) => {
  return (
    <ul>
      {idTypeList.map((idType) => (
        <li key={idType.value}>{idType.label}</li>
      ))}
    </ul>
  );
}

export default ExampleIdModalContent;