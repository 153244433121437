import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function transferDetails(
  state = initialState.moneyTransfer.transferDetails,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_TRANSFER_DETAILS_SUCCESS: {
      return Object.assign({}, state, {
        ...action.transferDetails,
      });
    }
    case MONEY_TRANSFER.GET_TRANSFER_DETAILS_ERROR: {
      return Object.assign({}, state, {
        ...action.transferDetails,
        errors: action.errors
      });
    }
    default:
      return state;
  }
}
