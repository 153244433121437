import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../types/redux";

import Modal from "../Common/Modal";
import DisbursementHistoryTable from "./DisbursementHistoryTable";
import { SetBankAccountDisbursementHistory } from "../../actions/bankAccounts";

const DisbursementHistoryModal = () => {
  const disptach = useDispatch();
  const { disbursementHistory } = useSelector((state: RootState) => state.bankAccounts);

  const handleClose = () => {
    disptach(SetBankAccountDisbursementHistory({
      showDisbursementHistory: false,
      bankAccountName: "",
      bankAccountNumber: "",
      disbursementHistory: []
    }))
  }
  return (
    <Modal
      open={disbursementHistory.showDisbursementHistory}
      onClose={() => handleClose()}
      title="View History"
      content={<DisbursementHistoryTable />}
      actions={[]}
      className="table"
    />
  );
}

export default DisbursementHistoryModal;