import React from "react";
import PropTypes from "prop-types";

/**
 * Generates a way to pass a value back to a component without
 * allocating a new function on each render
 */
export class ActionLink extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick = (e) => {
    e.preventDefault();
    const { clickFunc, value } = this.props;
    clickFunc(value);
  };

  render() {
    const { imageSrc, text, classes, imageClasses, href, dataCy } = this.props;
    let cssClasses = "action-link " + classes;

    // href does not work with onClick
    if (href != undefined && href != "") {
      return (
        <a className={cssClasses} href={href} data-cy={dataCy}>
          {imageSrc && <img src={imageSrc} className={imageClasses} />}
          {!imageSrc && text}
          {this.props.children}
        </a>
      );
    }

    return (
      <a
        className={cssClasses}
        href="#"
        onClick={this.handleClick}
        data-cy={dataCy}
      >
        {imageSrc && <img src={imageSrc} className={imageClasses} />}
        {!imageSrc && text}
        {this.props.children}
      </a>
    );
  }
}

ActionLink.propTypes = {
  /** Link text */
  text: PropTypes.string,
  /** Image url if no link text is provided */
  imageSrc: PropTypes.string,
  /** Callback on click */
  clickFunc: PropTypes.func,
  /** Value to return */
  value: PropTypes.any,
  /** CSS classes on the anchor tag */
  classes: PropTypes.string,
  /** CSS classes for the image tag */
  imageClasses: PropTypes.string,
  /** Passthrough */
  children: PropTypes.any,
  /** href to pass through to link */
  href: PropTypes.string,
  /** data-cy attribute value for UI testing */
  dataCy: PropTypes.string,
};

export default ActionLink;
