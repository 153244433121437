
import React, { useEffect, useRef, useState } from 'react';
import { InjectedFormProps, SubmissionError, change, reduxForm } from 'redux-form';
import DynamicInputField from '../../Common/DynamicFields/DynamicInputField';
import { ACTION_DISPLAY_TYPES, COMPANION_CARD_FIELD_REFS, COUNTRY_IDS, FIELD_TYPES, ICONS, SUPPORT_DOC_IDS } from '../../../constants/enums';
import CatalogApi from '../../../api/catalog';
import { GetStateProvincesResponse } from '../../../types/api/catalog';
import { SupportDocType } from '../../../types/api/card';
import ENV from "../../../constants/environment";
import { DropdownType } from '../../../types/fields';
import ActionLink from '../../Common/ActionLink';
import Alert from '../../Common/Alerts/Alert';
import CardApi from '../../../api/card';
import Action from '../../Common/Action';
import { COMMON, PAGES } from '../../../constants/localization';
import InputWrapperWithRef from '../../Common/InputWrapperWithRef';
import { useDispatch } from 'react-redux';
import useClickExternalLink from '../../../hooks/useClickExternalLink';
import i18n from '../../../i18n/config';

type CustomProps = {
  companionCards: boolean;
  idTypeList: DropdownType[];
  documents: SupportDocType[];
  countryOptions: DropdownType[];
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSuccess: React.Dispatch<React.SetStateAction<boolean>>;
  setShowErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const RequestForm: React.FC<CustomProps & InjectedFormProps<{}, CustomProps>> = ({
  handleSubmit,
  companionCards,
  idTypeList,
  documents,
  countryOptions,
  setShowModal,
  setSuccess,
  setShowErrorModal,
  setErrorMessage,
  valid
}) => {
  const dispatch = useDispatch();
  const openExternalLinksModal = useClickExternalLink();
  const [countryId, setCountryId] = useState<number>();
  const [stateOptions, setStateOptions] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [cardholderAgreementChecked, setCardholderAgreementChecked] = useState(false);
  const [feesChecked, setFeesChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const fieldRefs = useRef({});

  // For handling disabling button until all required fields are filled and checkboxes are checked
  useEffect(() => {

    if (documents?.length > 0) {
      // if cardholder agreement doc is present, set checkbox to false
      // else set to true so submit button disabling logic works
      if (documents?.find((document: SupportDocType) => document.typeId === SUPPORT_DOC_IDS.ISSUING_BANK_CARDHOLDER_AGREEMENT)) {
        setCardholderAgreementChecked(false);
      } else {
        setCardholderAgreementChecked(true);
      }

      // if schedule of fees doc is present, set checkbox to false
      // else set to true so submit button disabling logic works
      if (documents?.find(document => document.typeId === SUPPORT_DOC_IDS.COMPANION_CARD_FEES)) {
        setFeesChecked(false);
      } else {
        setFeesChecked(true);
      }
    } else {
      // If no documents returned from api, set both checkboxes to true so submit button disabling logic works
      setCardholderAgreementChecked(true);
      setFeesChecked(true);
    }


  }, [documents])

  // handle disabling submit button
  useEffect(() => {
    if (valid && cardholderAgreementChecked && feesChecked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [valid, cardholderAgreementChecked, feesChecked])

  const handleCountrySelect = async (e: string) => {
    setCountryId(Number(e));

    // if countryId is USA and stateOptions is empty (api call has not already been made), get states
    if (Number(e) === COUNTRY_IDS.USA && stateOptions.length < 1) {
      try {
        const response = await CatalogApi.GetStateProvinces('US') as GetStateProvincesResponse;

        if (response.stateProvinces.length > 0) {
          const options = response.stateProvinces.map((state) => (
            {
              label: state.name,
              value: state.code
            }
          ));

          // if countryId is now USA, set province in redux form to null so value is not included in POST call
          dispatch(change(PAGES.MY_CARD.REQUEST_COMPANION_CARD.FORM_NAME, "province", ""));
          setStateOptions(options);
        }
      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(COMMON.ERROR);
        }

        setShowErrorModal(true);
      }
    } else if (Number(e) !== COUNTRY_IDS.USA) {
      // if countryId is not USA, set stateId in redux form to null so value is not included in POST call
      dispatch(change(PAGES.MY_CARD.REQUEST_COMPANION_CARD.FORM_NAME, "stateId", ""));
    }
  };

  const convertDateTime = (date: string) => {
    const dateOfBirth = new Date(date);
    const year = dateOfBirth.getFullYear();
    const month = dateOfBirth.getMonth() + 1;
    const day = dateOfBirth.getDate();
    return `${year}-${month}-${day}`;
  };

  const onSubmit = async (values) => {
    setSubmitting(true);
    const formData = new FormData();
    for (const key in values) {
      // c# doesn't accept JS Date string, so convert 
      if (key === "dateOfBirth") {
        const convertedDate = convertDateTime(values[key]);
        formData.append(key, convertedDate);
      } else {
        formData.append(key, values[key]);
      }

    }

    try {
      await CardApi.PostCompanionCard(formData);
      setSubmitting(false);
      setSuccess(true);
    } catch (err) {

      setSubmitting(false);
      if (err?.data?.errors) {
        const errors = {};
        for (const error of err.data.errors) {
          errors[error.fieldId] = error.errorMessage;
        }

        // Get first error fieldId to scroll to
        const firstErrorFieldId = err.data.errors[0]?.fieldId;
        if (firstErrorFieldId) {
          fieldRefs.current[`${firstErrorFieldId}Ref`].scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          setShowErrorModal(true);
          setErrorMessage(COMMON.ERROR);
        }

        throw new SubmissionError(errors);
      } else {
        setShowErrorModal(true);
        setErrorMessage(COMMON.ERROR);
      }
    }
  };

  const handleCheckboxChange = (typeId: number) => {
    if (typeId === SUPPORT_DOC_IDS.ISSUING_BANK_CARDHOLDER_AGREEMENT) {
      setCardholderAgreementChecked(!cardholderAgreementChecked);
    } else if (typeId === SUPPORT_DOC_IDS.COMPANION_CARD_FEES) {
      setFeesChecked(!feesChecked);
    }
  };

  const cardholerAgreementLabel = (fileId: number) => {
    let url = `${ENV.BASE_NAVIGATOR_URL}/Administration/GetFile/${fileId}`;
    return (
      <>I have read and accept the {" "}
        <a href={url} target="_blank" rel="noopener noreferrer" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} onClick={(e) => openExternalLinksModal(e, url)}>Cardholder Agreement </a>{/*
        */}for obtaining a Companion Card.
      </>
    )
  };

  const feesLabel = (fileId: number) => {
    let url = `${ENV.BASE_NAVIGATOR_URL}/Administration/GetFile/${fileId}`;
    return (
      <>
        I have reviewed and acknowledge the {" "}
        <a href={url} target="_blank" rel="noopener noreferrer" title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")} onClick={(e) => openExternalLinksModal(e, url)}>Schedule of Fees </a>{/* 
        */}associated with my request for and ongoing support of my Companion Card.
      </>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h3>Your Information</h3>
      <div className="grid grid-cols-2">
        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.CARDHOLDER_FIRST_NAME] = el}>
          <DynamicInputField
            id="cardholderFirstName"
            type={FIELD_TYPES.TEXT}
            label="First Name"
            focus={true}
            required={true}
          />
        </InputWrapperWithRef>


        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.CARDHOLDER_LAST_NAME] = el}>
          <DynamicInputField
            id="cardholderLastName"
            type={FIELD_TYPES.TEXT}
            label="Last Name"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.SHIP_NAME] = el}>
          <DynamicInputField
            id="shipName"
            type={FIELD_TYPES.TEXT}
            label="Ship Name"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.EMPLOYEE_ID] = el}>
          <DynamicInputField
            id="employeeId"
            type={FIELD_TYPES.TEXT}
            label="Employee ID"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.CARDHOLDER_EMAIL] = el}>
          <DynamicInputField
            id="email"
            type={FIELD_TYPES.TEXT}
            label="Email Address"
            required={true}
          />
        </InputWrapperWithRef>
      </div>

      <h3>Companion Cardholder Information</h3>
      <div className="grid grid-cols-2">
        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.SECONDARY_FIRST_NAME] = el}>
          <DynamicInputField
            id="secondaryFirstName"
            type={FIELD_TYPES.TEXT}
            label="First Name"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.SECONDARY_LAST_NAME] = el}>
          <DynamicInputField
            id="secondaryLastName"
            type={FIELD_TYPES.TEXT}
            label="Last Name"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.SECONDARY_EMAIL] = el}>
          <DynamicInputField
            id="secondaryEmail"
            type={FIELD_TYPES.TEXT}
            label="Email"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.DATE_OF_BIRTH] = el}>
          <DynamicInputField
            id="dateOfBirth"
            type={FIELD_TYPES.DATE}
            label="Date of Birth"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ADDRESS_LINE_1] = el}>
          <DynamicInputField
            id="addressLine1"
            type={FIELD_TYPES.TEXT}
            label="Address 1"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ADDRESS_LINE_2] = el}>
          <DynamicInputField
            id="addressLine2"
            type={FIELD_TYPES.TEXT}
            label="Address 2"
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.COUNTRY_ID] = el}>
          <DynamicInputField
            id="countryId"
            type={FIELD_TYPES.SELECT}
            label="Country"
            options={countryOptions}
            required={true}
            onChange={(e: string) => handleCountrySelect(e)}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.CITY] = el}>
          <DynamicInputField
            id="city"
            type={FIELD_TYPES.TEXT}
            label="City"
            required={countryId === COUNTRY_IDS.USA}
          />
        </InputWrapperWithRef>

        {countryId === COUNTRY_IDS.USA &&
          <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.STATE_ID] = el}>
            <DynamicInputField
              id="stateId"
              type={FIELD_TYPES.SELECT}
              label="State"
              required={true}
              options={stateOptions}
            />
          </InputWrapperWithRef>
        }

        {countryId !== COUNTRY_IDS.USA &&
          <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.PROVINCE] = el}>
            <DynamicInputField
              id="province"
              type={FIELD_TYPES.TEXT}
              label="Province"
            />
          </InputWrapperWithRef>
        }

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ZIP_POSTAL_CODE] = el}>
          <DynamicInputField
            id="zipPostalCode"
            type={FIELD_TYPES.TEXT}
            label={countryId === COUNTRY_IDS.USA ? 'Zip Code' : 'Postal Code'}
            required={countryId === COUNTRY_IDS.USA}
          />
        </InputWrapperWithRef>
      </div>

      <h3>Government Issued Photo ID</h3>
      <p>Please upload a scanned copy of your companion's government issued photo ID. The photo ID must be unexpired and contain all of the following:</p>
      <ul>
        <li>Photo</li>
        <li>First and last name</li>
        <li>Date of birth*</li>
        <li>ID number</li>
        <li>ID type</li>
        <li>Country Providing ID</li>
      </ul>

      <p>* If the ID only provides the birth year (example: 1980), the Date of Issuance must be on the ID (example: 03-15-18).</p>
      <ActionLink
        text="Click here for examples of Photo IDs accepted"
        clickFunc={() => setShowModal(true)}
        classes="bold"
      />

      <h3>Upload Identification Document</h3>
      <p className="bold">Your photo id file must be in pdf, jpg, gif, png, or MSWord file format.</p>

      <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ID_FILE] = el}>
        <DynamicInputField
          id="idFile"
          type={FIELD_TYPES.FILE}
          label="Identification Document File"
          required={true}
        />
      </InputWrapperWithRef>

      <div className="grid grid-cols-2">
        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.IDENTIFICATION_TYPE_ID] = el}>
          <DynamicInputField
            id="identificationTypeId"
            type={FIELD_TYPES.SELECT}
            label="ID Type"
            required={true}
            options={idTypeList}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ISSUING_COUNTRY] = el}>
          <DynamicInputField
            id="issuingCountry"
            type={FIELD_TYPES.TEXT}
            label="Country/State of Issuance"
            required={true}
          />
        </InputWrapperWithRef>

        <InputWrapperWithRef className="spacing-bottom-none" ref={el => fieldRefs.current[COMPANION_CARD_FIELD_REFS.ID_NUMBER] = el}>
          <DynamicInputField
            id="idNumber"
            type={FIELD_TYPES.TEXT}
            label="ID Number"
            required={true}
          />
        </InputWrapperWithRef>
      </div>

      <Alert
        alertClasses="alert-info alert-bkgrd-white flex-row"
        icon={ICONS.INFO}
        iconClasses={"spacing-right-tiny"}
      >
        <p className="spacing-bottom-none line-height-medium">
          NOTICE: By clicking the "Submit" button below, you hereby consent to the issuance of a second Brightwell Prepaid Mastercard®
          (<span className="bold">"Companion Card"</span>) to the secondary cardholder named in this form
          (the <span className="bold">"Companion Cardholder"</span>).
          {companionCards &&
            <>
              {" "}Your companion card will have a different card number than your card and will have limited
              features and functionality such as money transfers are disabled for the companion card.
              You will be liable for all transactions made with the Companion Card.  You must notify us
              immediately if you decide to revoke permission to the Companion Cardholder to access your card funds.
              If you request us to revoke the Companion Cardholder’s use of the Companion Card, we may revoke your Card
              and issue you a new card with a different number.
            </>
          }

          {!companionCards &&
            <>{" "}Certain features and functionality such as money transfers will be disabled on the Companion Card. You will be liable for all transactions made with the Companion Card.</>
          }

        </p>
      </Alert>

      {documents?.length > 0 &&
        documents.map(document => {
          const label = document.typeId === SUPPORT_DOC_IDS.ISSUING_BANK_CARDHOLDER_AGREEMENT ? cardholerAgreementLabel(document.urlId) : feesLabel(document.urlId);
          return (
            <div className="checkbox spacing-bottom" key={document.typeId}>
              <input
                type="checkbox"
                id={document.name}
                onChange={() => handleCheckboxChange(document.typeId)}
              />{" "}
              <label htmlFor={document.name} >{label}</label>
            </div>
          );
        })
      }

      <Action
        title={COMMON.SUBMIT}
        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
        type="submit"
        disabled={disabled}
        loading={submitting}
      />
    </form>
  );
};

export default reduxForm<{}, CustomProps>({
  form: PAGES.MY_CARD.REQUEST_COMPANION_CARD.FORM_NAME,
})(RequestForm);
