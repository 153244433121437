import React, { useEffect, useState } from "react";
import i18n from "../../i18n/config";
import { BANK_ACCOUNT_FORM_NAMES, FORM_NAMES } from "../../constants/forms";
import { FIELD_TYPES } from "../../constants/enums";
import { RootState } from "../../types/redux";
import { useDispatch, useSelector } from "react-redux";
import { reduxForm, InjectedFormProps } from "redux-form";
import { BankAccountChildProps } from "../../types/bankAccounts";
import { CurrencyType } from "../../types/api/catalog";
import { DropdownType } from "../../types/fields";
import { useHistory } from "react-router-dom";

import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { ROUTES } from "../../constants/clientRoutes";
import { SetSelectedCurrency } from "../../actions/bankAccounts";

const CurrencySelection: React.FC<BankAccountChildProps & InjectedFormProps<{}, BankAccountChildProps>> = (props: BankAccountChildProps) => {
  const { setTitle, setSubTitle, setBackButton, setForwardButton } = props;
  const disptach = useDispatch();
  const history = useHistory();
  const availableCurrencies = useSelector((state: RootState) => state.bankAccounts.availableCurrencies);
  const [options, setOptions] = useState<DropdownType[]>([]);
  const forwardButtonInitialState = { text: i18n.t("COMMON.NEXT"), clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.ACCOUNT_DETAILS), disabled: true };

  useEffect(() => {
    setTitle(i18n.t("PAGES.BANK_ACCOUNTS.CURRENCY_SELECTION.TITLE"));
    setSubTitle(i18n.t(""));
    setBackButton({
      text: i18n.t("COMMON.BACK"),
      clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.COUNTRY)
    });
    setForwardButton(forwardButtonInitialState);

    // If there are no available currencies, redirect to the country selection page (i.e. user hit page refresh and redux state has been cleared)
    if (availableCurrencies) {
      setOptions(availableCurrencies.map((currency: CurrencyType) => { return { label: currency.name, value: currency.code } }));
    } else {
      history.push(ROUTES.BANKS_ACCOUNTS.COUNTRY);
    }
  }, []);

  const handleChange = (value: string) => {
    const disabled = value === "";
    setForwardButton({
      ...forwardButtonInitialState,
      disabled
    });
    const selectedCurrency = availableCurrencies.find((currency: CurrencyType) => currency.code === value);
    disptach(SetSelectedCurrency(selectedCurrency.name));
  };

  return (
    <form className="spacing-top-medium">
      <div className="max-w-360">
        <DynamicInputField
          id={BANK_ACCOUNT_FORM_NAMES.destinationCurrency}
          type={FIELD_TYPES.SELECT}
          label={i18n.t("PAGES.BANK_ACCOUNTS.CURRENCY_SELECTION.LABEL")}
          required={true}
          focus={true}
          options={options}
          onChange={handleChange}
        />
      </div>
    </form>

  );
}

export default reduxForm<{}, BankAccountChildProps>({
  form: FORM_NAMES.BANK_ACCOUNTS,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(CurrencySelection);