import * as React from 'react';
import { BankAccount } from '../../types/api/odt';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTES } from '../../constants/clientRoutes';
import { ACTION_DISPLAY_TYPES, BANK_ACCOUNT_STATUS } from '../../constants/enums';
import i18n from '../../i18n/config';
import { UpdateDestinationInfo } from '../../actions/onDemandTransfer';

import BankAccountToolTip from './BankAccountToolTip';
import Action from '../Common/Action';
import { BankAccountCardType } from '../../types/components';


const BankAccountCard = ({ bank, handleInfoTipModal, type }: { bank: BankAccount, handleInfoTipModal?: (title: string, content: string) => void, type?: BankAccountCardType }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const countries = localStorage.getItem("GET_COUNTRIES");
  const countriesData = JSON.parse(countries || '[]');
  const destinationCountry = countriesData.find((country: any) => country.iso === bank.destinationCountryIso);
  const destinationCountryName = destinationCountry ? destinationCountry.name : "";
  const destinationCountryId = destinationCountry ? destinationCountry.id : "";

  const handleSelectBank = () => {
    const payload = {
      allocationId: String(bank.allocationId),
      destinationCurrency: bank.destinationCurrencyCode,
      destinationCountryIso: bank.destinationCountryIso,
      destinationCountryName: destinationCountryName,
      destinationCountryId: destinationCountryId,
      isPOPRequired: bank.isPOPRequired,
      isPinRequired: bank.isPinRequired,
      isDoddFrank: bank.isDoddFrank,
      bankAccountFeeBanner: bank.feeBanner
    };
    dispatch(UpdateDestinationInfo(payload));

    history.push(ROUTES.ODT.AMT_PURPOSE);
  };

  const lastElement = () => {
    switch (bank.bankAccountStatus) {
      case BANK_ACCOUNT_STATUS.AVAILABLE:
        return (
          <Action
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            title={i18n.t("COMMON.SELECT")}
            small={true}
            onClick={() => handleSelectBank()}
          />
        );
      case BANK_ACCOUNT_STATUS.INCOMPLETE:
        return (
          <Action
            displayType={ACTION_DISPLAY_TYPES.SECONDARY}
            title={i18n.t("COMMON.FIX")}
            small={true}
            onClick={() => location.href = ROUTES.EXTERNAL.BANK_ACCOUNTS}

          />
        );
      case BANK_ACCOUNT_STATUS.UNDER_REVIEW:
        return <p>{i18n.t("PAGES.ODT.BANK_SELECTION.ACCOUNT_UNDER_REVIEW_LABEL")}</p>
      default:
        return <p></p>;
    }
  }
  return (
    <article className="bank-account-card">
      <p>{bank.accountName}</p>
      <p>*{bank.accountNumber}</p>
      <p>{bank.destinationCurrencyCode} to {destinationCountryName}</p>
      {/* if bank account status isn't available, show label and InfoTip */}
      {bank.bankAccountStatus !== BANK_ACCOUNT_STATUS.AVAILABLE
        ? <BankAccountToolTip bank={bank} handleInfoTipModal={handleInfoTipModal} />
        : <p></p>
      }

      <div className="flex-row justify-content-end">
        {lastElement()}
      </div>

    </article>
  );
}

export default BankAccountCard;