import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { COMMON, PAGES } from "../../constants/localization";
import ActionLink from "../../components/Common/ActionLink";
import Action from "../../components/Common/Action";
import checkIcon from "../../assets/images/icons/check.svg";
import {
  ACTION_DISPLAY_TYPES,
  ACCEPTANCE_BEHAVIOR,
  MONEY_TRANSFER_DIRECTIVE_TYPES,
  ICONS,
  MONEY_TRANSFER_PROVIDER_IDS,
} from "../../constants/enums";
import PinCheck from "../../components/Common/PinCheck";
import Modal from "../../components/Common/Modal";
import { GetTermsAndConditions } from "../../actions/moneyTransfer";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import Alert from "../../components/Common/Alerts/Alert";
import DisplaySection from "../../components/MoneyTransfer/DisplaySection";
import { setExternalLinksModalOpen } from "../../actions/global";
import i18n from "../../i18n/config";

/**
 * Step in the money transfer flow to review the transfer and send it.
 */
class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      posting: false,
      showTermsAndConditionsModal: false,
      showSubmissionErrorModal: false,
      termsAccepted: false,
      showPinCheckModal: false,
    };

    this.handleTermsCheckboxChanged =
      this.handleTermsCheckboxChanged.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePinCheckSuccess = this.handlePinCheckSuccess.bind(this);
    this.handlePinCheckDismiss = this.handlePinCheckDismiss.bind(this);
    this.showTermsAndConditionsModal =
      this.showTermsAndConditionsModal.bind(this);
    this.showSubmissionErrorModal = this.showSubmissionErrorModal.bind(this);
    this.onBack = this.onBack.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasCommitError && this.props.hasCommitError) {
      this.showSubmissionErrorModal();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  /**
   * The 'disable' back link css is not enough to disable the click.
   */
  onBack() {
    if (!this.state.posting) {
      this.props.onBack();
    }
  }

  handleTermsCheckboxChanged(e) {
    trackEvent(EVENTS.MONEY_TRANSFER.TERMS_ACCEPTED, {});
    this.setState({ termsAccepted: e.target.checked });
  }

  async handlePinCheckSuccess() {
    trackEvent(EVENTS.MONEY_TRANSFER.PIN_ID, {});
    this.setState({ showPinCheckModal: false, posting: true });
    this.props.onCommit();
  }

  async handlePinCheckDismiss() {
    this.setState({ showPinCheckModal: false, posting: false });
  }

  handleSubmit() {
    trackEvent(EVENTS.MONEY_TRANSFER.REVIEW_AND_SEND);
    this.setState({ showPinCheckModal: true });
  }

  showTermsAndConditionsModal() {
    this.props.GetTermsAndConditions(this.props.quoteId);
    this.setState({ showTermsAndConditionsModal: true });
  }

  showSubmissionErrorModal() {
    this.setState({ showSubmissionErrorModal: true, posting: false });
  }

  closeSubmissionErrorModal() {
    this.setState({ showSubmissionErrorModal: false, posting: false });
  }

  handleClick = (e, url) => {
    e.preventDefault();

    this.props.setExternalLinksModalOpen({ open: true, url });
  }

  createDirectiveElement = (directive) => {
    const { text, type, url } = directive;
    if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.EXTERNAL_LINK) {
      return (
        <a
          key={directive.id}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className="footer-directive-link"
          title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
          onClick={(e) => this.handleClick(e, url)}
        >
          {text}
        </a>
      )
    } else if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.MODAL) {
      return (
        <ActionLink
          text={text}
          clickFunc={this.showTermsAndConditionsModal}
        />
      )
    }
  }

  /**
   * Takes the label and inserts a link where the given delimeter is.
   * Copy pasta from https://github.com/facebook/react/issues/3386#issuecomment-291152357
   * @return {object} A JSX object containing an array of alternating strings and JSX
   */
  renderTermsLabel(acceptanceText) {
    const { text, directives } = acceptanceText;
    let acceptanceTextStr = text;
    // Replace "," with " ," in acceptanceTextStr to make it easier split on " ";
    // acceptanceTextStr can come back like this "I have read and agree to {PRIVACYSTATEMENT}, {TERMSANDCONDITIONS}, and {ESIGN}"
    // So adding whitespace in front of comma makes it easier to split into array
    // Cannot just do string.replace({DIRECTIVE.ID}, React.Component) as you can't insert a React component into string or will get:
    // "I have read and agree to [object Object], [object Object], and [object Object]"
    // Mapping over array (created from splitting string) allows strings and react components to be rendered
    // Finally, remove whitespace between react component and comma if comma is next element in array
    acceptanceTextStr = acceptanceTextStr.replace(/,/g, " ,");
    const acceptanceTextParts = acceptanceTextStr.split(" ");

    return (
      <span>
        {acceptanceTextParts.map((item, index) => {
          const directiveObj = directives.find(directive => `{${directive.id}}` === item);
          return directiveObj
            ? <>{this.createDirectiveElement(directiveObj)}{acceptanceTextParts[index + 1] === "," ? "" : " "}</>
            : item + " ";
        })}
      </span>
    );
  }

  renderFooter(termsAndConditions, isWesternUnion) {
    return (
      <div className={`terms-footer ${isWesternUnion ? 'western-union' : ''}`}>
        <div className={`${isWesternUnion ? "" : "container"} `}>
          {termsAndConditions &&
            termsAndConditions.behavior !==
            ACCEPTANCE_BEHAVIOR.DO_NOT_SHOW && (
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  onChange={this.handleTermsCheckboxChanged}
                />{" "}
                <label htmlFor="terms">{this.renderTermsLabel(termsAndConditions.acceptanceTextV2)}</label>
              </div>
            )}
          {!isWesternUnion &&
            <div className="send-button-container">
              <Action
                loading={this.state.posting}
                onClick={this.handleSubmit}
                disabled={
                  termsAndConditions &&
                  termsAndConditions.behavior ===
                  ACCEPTANCE_BEHAVIOR.SHOW_AND_REQUIRE &&
                  !this.state.termsAccepted
                }
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                title={PAGES.MONEY_TRANSFER.REVIEW.SEND}
                iconRight={checkIcon}
              />
            </div>
          }

        </div>
      </div>
    );
  }

  render() {
    const { displayFields, displaySections, title, subTitle, titleSubtext, termsAndConditions, footer } = this.props.transferValidation;
    const isWesternUnion = this.props.provider.providerId === MONEY_TRANSFER_PROVIDER_IDS.WESTERN_UNION;

    return (
      <div className="review">
        <h2 className="spacing-bottom-medium">{title}</h2>
        <p className="large">{subTitle}</p>
        <p className="bold marengo-grey">{titleSubtext}</p>

        <Alert
          alertClasses={"alert-info alert-bkgrd-white spacing-top-small flex-row align-items-start message-ocean-blue message-btm-margin bold"}
          message={PAGES.MONEY_TRANSFER.REVIEW.ALERT_HEADING}
          isHTML={false}
          icon={ICONS.INFO}
          iconClasses={"spacing-right-tiny"}
        >
          <p className="spacing-bottom-none font-regular">{PAGES.MONEY_TRANSFER.REVIEW.ALERT_MESSAGE}</p>
        </Alert>

        {displayFields && displayFields.map(field => <p className="marengo-grey" key={field.id}>{field.formattedValue}</p>)}

        <DisplaySection sections={displaySections} />

        <hr />

        {termsAndConditions && termsAndConditions.behavior !==
          ACCEPTANCE_BEHAVIOR.DO_NOT_SHOW && (
            <p
              className="x-small terms-and-conditions"
              dangerouslySetInnerHTML={{
                __html:
                  termsAndConditions.content
              }}
            ></p>
          )}

        {footer &&
          <div className="x-small terms-and-conditions" dangerouslySetInnerHTML={{ __html: footer.content }} />
        }

        {footer && footer.disclaimers.length > 0 &&
          footer.disclaimers.map((d, i) => {
            if (d.superscript) {
              return (
                <div className="disclaimer" key={i}>
                  <sup>{d.superscript}</sup> {d.text}
                </div>
              );
            } else {
              return (
                <div className="disclaimer" key={i}>
                  {d.text}
                </div>
              );
            }
          })
        }

        {this.renderFooter(termsAndConditions, isWesternUnion)}

        <div className="row navRow">
          <div className={`col-xs-12 ${isWesternUnion ? "col-sm-6" : ""}`}>
            <ActionLink
              clickFunc={this.onBack}
              classes={"mt-link" + (this.state.posting && " disabled")}
            >
              <div className="glyphicon glyphicon-menu-left link_icon"></div>
              {COMMON.BACK}
            </ActionLink>
          </div>

          {isWesternUnion &&
            <div className="col-xs-12 col-sm-6 button-cell one">
              <Action
                loading={this.state.posting}
                onClick={this.handleSubmit}
                disabled={
                  termsAndConditions &&
                  termsAndConditions.behavior ===
                  ACCEPTANCE_BEHAVIOR.SHOW_AND_REQUIRE &&
                  !this.state.termsAccepted
                }
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                title={PAGES.MONEY_TRANSFER.REVIEW.CONFIRM_AND_SEND}
              />
            </div>
          }
        </div>

        <PinCheck
          open={this.state.showPinCheckModal}
          onSuccess={this.handlePinCheckSuccess}
          onDismiss={this.handlePinCheckDismiss}
          source={{
            name: PAGES.MONEY_TRANSFER.MONEY_TRANSFER,
            type: PAGES.MONEY_TRANSFER.CASH_PICKUP,
          }}
        />

        {this.props.transferValidation &&
          this.props.transferValidation.termsAndConditions && (
            <Modal
              title={PAGES.MONEY_TRANSFER.REVIEW.TERMS_AND_CONDITIONS}
              open={this.state.showTermsAndConditionsModal}
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.termsAndConditions,
                  }}
                ></div>
              }
              stickyFooter={true}
              contentLoading={!this.props.termsAndConditions}
              onClose={() =>
                this.setState({ showTermsAndConditionsModal: false })
              }
              actions={[
                {
                  title: COMMON.OK,
                  displayType: ACTION_DISPLAY_TYPES.PRIMARY,
                  onClick: () =>
                    this.setState({ showTermsAndConditionsModal: false }),
                  disabled: !this.props.termsAndConditions,
                },
              ]}
            />
          )}

        <Modal
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          open={this.state.showSubmissionErrorModal}
          content={COMMON.ERROR}
          onClose={() => this.closeSubmissionErrorModal()}
          actions={[
            {
              title: COMMON.OK,
              displayType: ACTION_DISPLAY_TYPES.PRIMARY,
              onClick: () => this.closeSubmissionErrorModal(),
            },
          ]}
        />
      </div>
    );
  }
}

Review.propTypes = {
  onCommit: PropTypes.func.isRequired,
  onBack: PropTypes.func.isRequired,
  transferValidation: PropTypes.object,
  termsAndConditions: PropTypes.object,
  GetTermsAndConditions: PropTypes.func,
  quoteId: PropTypes.number,
  hasCommitError: PropTypes.bool,
  provider: PropTypes.object,
  setExternalLinksModalOpen: PropTypes.func,
};

function mapStateToProps(state) {
  const provider = state.moneyTransfer.providerRates.providerRates.find(provider => {
    if (provider.quotes.length > 0) {
      return provider.quotes[0].quoteId === state.moneyTransfer.workflow.quoteId
    }
    return false;
  });
  return {
    transferValidation: state.moneyTransfer.transferValidation,
    termsAndConditions:
      state.moneyTransfer && state.moneyTransfer.workflow
        ? state.moneyTransfer.workflow.termsAndConditions
        : null,
    hasCommitError: state.moneyTransfer.completedTransfer.failure,
    provider
  };
}

export default connect(mapStateToProps, { GetTermsAndConditions, setExternalLinksModalOpen })(Review);
