import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function availableProvinces(
  state = initialState.moneyTransfer.availableProvinces,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_AVAILABLE_PROVINCES: {
      let provinces = [];
      try {
        provinces = action.availableProvinces.slice().map((province: { label: string, value: string }) => {
          return {
            name: province.label,
            iso: province.value
          }
        })
      } catch (err) {
        // If there is an error retrieving available countries we don't need to inform the user because
        // they should be prevented from entering a money transfer flow.
        // The actual error error at the API level should be logged API side.
        provinces = [];
      }

      state = provinces;
      return provinces;
    }
    default:
      return state;
  }
}
