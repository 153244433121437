import React, { Component } from "react";
import PropTypes from "prop-types";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import bwpIcon from "../../assets/images/navigator_chip.png";
import closeIcon from "../../assets/images/icons/close.svg";
import ActionLink from "./ActionLink";
import { PAGES } from "../../constants/localization";
import { osName } from "react-device-detect";
import { eventTracker } from "../../services/eventTracker";

const osWithBanner = ["Android", "iOS"];

class GetApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  getAppLink() {
    switch (osName) {
      case "Android":
        return "https://play.google.com/store/apps/details?id=com.brightwellpayments.android&hl=en_US&gl=US";
      case "iOS":
        return "https://apps.apple.com/us/app/brightwell/id1202097542";
    }

    return "#";
  }

  render() {
    const { show } = this.state;
    const { trackEvent } = this.props;
    // show only for android or iOS phones and tablets
    if (!show || !osWithBanner.includes(osName)) return null;

    return (
      <div className="get-app-row v2">
        <div className="flex-row">
          <div>
            <button
              className="btn-link"
              onClick={() => this.setState({ show: false })}
            >
              <img src={closeIcon} />
            </button>
          </div>
          <div>
            <img className="bw-icon" src={bwpIcon} />
          </div>
          <div>
            <h4>{PAGES.GET_APP.TITLE}</h4>
            <p>{PAGES.GET_APP.SUBTITLE}</p>
          </div>
        </div>
        <div className="text-center">
          <ActionLink
            href={this.getAppLink()}
            classes="btn btn-primary btn-sm"
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            onClick={eventTracker.track(trackEvent || "GetApp")}
            text={PAGES.GET_APP.BUTTON_TEXT}
          />
        </div>
      </div>
    );
  }
}

GetApp.propTypes = {
  trackEvent: PropTypes.string,
};

export default GetApp;
