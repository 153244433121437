import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import LogApi from "../api/log";
import ENV from "../constants/environment";
import GenericErrorModal from "../components/Common/Modals/GenericErrorModal";

/**
 * Error boundary that will log any errors at the boundary's level
 */
class ErrorLogger extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGenericErrorModal: false,
    };
  }
  componentDidCatch(error) {
    this.setState({ showGenericErrorModal: true });
    if (!ENV.__DEV__) {
      LogApi.SendError(error, this.props.userId);
    }
  }

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  render() {
    if (this.state.showGenericErrorModal) {
      return (
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      );
    }
    return this.props.children;
  }
}

ErrorLogger.propTypes = {
  /** The caught error */
  error: PropTypes.object,
  /** Additional error information */
  info: PropTypes.object,
  /** The logged in user */
  userId: PropTypes.string,
  /** Components within the boundary */
  children: PropTypes.any,
};

export default connect((state) => {
  return {
    userId: state.user.profile.userGuid,
  };
})(ErrorLogger);
