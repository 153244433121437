import React from "react";
import PropTypes from "prop-types";
import BREAKPOINTS from "../../constants/breakPoints";

const ALERT_TYPES = {
  success: " alert-success page-success",
  info: " alert-info page-info",
  warning: " alert-warning page-warning",
  danger: " alert-danger page-danger",
};

class PageAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobile: false,
      mobileAlertIsStatic: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    /// The timeout allows for the notification to
    /// render as a transition.
    setTimeout(this.addMobileToggleClass, 200);
  }

  addMobileToggleClass = () => {
    if (window.screen.width < BREAKPOINTS.X_SM_MIN) {
      this.setState({
        isMobile: true,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (window.screen.width < BREAKPOINTS.X_SM_MIN) {
      this.setState({
        mobileAlertIsStatic: true,
      });
    }
  };

  alertType = (type) => {
    return type == null ? ALERT_TYPES["info"] : ALERT_TYPES[type.toLowerCase()];
  };

  render() {
    const alertType = this.alertType(this.props.type);
    return (
      <div
        className={`alert page-alert${alertType} 
            ${this.state.isMobile ? "page-alert-mobile" : ""} 
            ${this.state.mobileAlertIsStatic ? "page-alert-static" : ""} 
            ${this.props.className}`}
        role="alert"
      >
        {this.props.children}
      </div>
    );
  }
}

PageAlert.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PageAlert;
