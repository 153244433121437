import React from 'react';
import PropTypes from "prop-types";
import { FEATURE_FLAGS } from '../../constants/featureFlags';
import { PAGES } from '../../constants/localization';
import ENV from "../../constants/environment";
import InputSkeletonGroup from '../Common/Loaders/InputSkeleton';
import { ROUTES } from '../../constants/clientRoutes';
import i18n from '../../i18n/config';

const QuickLinks = ({ setShowModal, featureFlags, initialApiCallErrors }) => {
  return (
    <section className="col-sm-5 spacing-bottom-medium">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h2>{i18n.t("PAGES.DASHBOARD.QUICK_LINKS_HEADER")}</h2>
        </div>

        {/* if featureFlags length is 0 and initalApiCallErrors is true, then initial api calls from App.js have finished, so remove loading state */}
        {!featureFlags.length > 0 && !initialApiCallErrors &&
          <div className="padding-top-small padding-right-small padding-bottom-small padding-left-small">
            <InputSkeletonGroup numInputs={5} direction='vertical' hideLabels={true} />
          </div>
        }

        {featureFlags.length > 0 &&
          <ul className="list-group" data-cy="quick-links-list">
            {featureFlags.includes(FEATURE_FLAGS.FX_RATES) &&
              <li className="list-group-item">
                <a href="#" onClick={() => setShowModal(true)}>{PAGES.DASHBOARD.QUICK_LINKS.FX_RATES}</a>
              </li>
            }

            <li className="list-group-item">
              <a href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.MY_CARD.INDEX}`}>
                {PAGES.DASHBOARD.QUICK_LINKS.MY_CARD_TRANSACTIONS}
              </a>
            </li>

            {featureFlags.includes(FEATURE_FLAGS.PAYMENT_HISTORY) &&
              <li className="list-group-item">
                <a href={`${ENV.BASE_NAVIGATOR_URL}/Payments`}>{PAGES.DASHBOARD.QUICK_LINKS.PAYMENT_HISTORY}</a>
              </li>
            }

            {featureFlags.includes(FEATURE_FLAGS.BANK_ACCOUNTS) &&
              <li className="list-group-item">
                <a href={`${ENV.BASE_NAVIGATOR_URL}/Beneficiaries/CreateNew`}>{PAGES.DASHBOARD.QUICK_LINKS.BANK_ACCOUNTS}</a>
              </li>
            }

            {featureFlags.includes(FEATURE_FLAGS.CHANGE_ALLOCATION) &&
              <li className="list-group-item">
                <a href={`${ENV.BASE_NAVIGATOR_URL}/Allocation/AllocationAccountSelection`}>{PAGES.DASHBOARD.QUICK_LINKS.CHANGE_ALLOCATION}</a>
              </li>
            }

            {featureFlags.includes(FEATURE_FLAGS.COMPANION_CARD) &&
              <li className="list-group-item">
                <a href={`${ENV.BASE_NAVIGATOR_URL}/MyCard/RequestCompanionCard`}>Request a Companion Card</a>
              </li>
            }

            {featureFlags.includes(FEATURE_FLAGS.WAGE_STATEMENTS) &&
              <li className="list-group-item">
                <a href={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.WAGE_STATEMENTS}`}>{PAGES.DASHBOARD.QUICK_LINKS.WAGE_STEMENTS}</a>
              </li>
            }
          </ul>
        }
      </div>
    </section>
  );
}

QuickLinks.propTypes = {
  /** react hook to update showModal value */
  setShowModal: PropTypes.func,
  /** user's feature flags */
  featureFlags: PropTypes.array,
  /** Redux state to show if initial app calls from App.js have error */
  initialApiCallErrors: PropTypes.object,
};

export default QuickLinks;