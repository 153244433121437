/* eslint-disable no-undef */
// Returns environmental values served by webpack for es-lint reasons

const envOauthStr = process.env.BASE_OAUTH_URL;
const navBaseUrl = process.env.BASE_NAVIGATOR_URL;

export default {
  __DEV__: process.env.__DEV__ === 'true',
  BW_ENV: process.env.BW_ENV,
  BASE_NAME: process.env.BASE_NAME,
  BASE_OAUTH_URL: envOauthStr.replace(/\/+$/g, ''),
  BASE_API_URL: process.env.BASE_API_URL,
  BASE_NAVIGATOR_URL: navBaseUrl.replace(/\/+$/g, ''),
  TOKEN_COOKIE: process.env.TOKEN_COOKIE,
  MIXPANEL_TOKEN: process.env.MIXPANEL_TOKEN,
  PUBLIC_PATH: process.env.PUBLIC_PATH,
  CLIENT_SECRET: process.env.CLIENT_SECRET,
  CLIENT_ID: process.env.CLIENT_ID,
  APP_INSIGHTS_INSTRUMENTATION_KEY: process.env.APP_INSIGHTS_INSTRUMENTATION_KEY,
  APP_INSIGHTS_CONNECTION_STRING: process.env.APP_INSIGHTS_CONNECTION_STRING,
  GA_MEASUREMENT_ID: process.env.GA_MEASUREMENT_ID
}
