import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, getFormValues, Field } from "redux-form";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import Action from "../../components/Common/Action";
import CountryPicker from "../../components/Common/CountryPicker";

class Form extends Component {
  constructor(props) {
    super(props);

    this.onCountrySelect = this.onCountrySelect.bind(this);
  }

  onCountrySelect(val) {
    this.setState({ selectedCountry: val });
  }

  render() {
    return (
      <form className="col-xs-12 col-sm-6" onSubmit={this.props.handleSubmit}>
        <input type="hidden" autoComplete="false" />

        <div className="form-group">
          <label>Select a Country</label>

          <Field
            type="select"
            component={CountryPicker}
            name="country"
            placeholder="Search country"
            onChange={this.onCountrySelect}
          />
        </div>

        {this.props.fields.map((f) => {
          return <DynamicInputField key={f.id} {...f} />;
        })}

        <Action
          type="submit"
          title="Submit"
          displayType={ACTION_DISPLAY_TYPES.PRIMARY}
        />
      </form>
    );
  }
}

Form.propTypes = {
  formValues: PropTypes.object,
  handleSubmit: PropTypes.func,
  fields: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    formValues: getFormValues("Library")(state),
    initialValues: {
      booleanFieldPrePop: "true",
      textFieldPrepop: "Hugh Jackman",
      decimalField: 15,
      date: "1991-08-02T04:00:00.000Z",
      phoneNumberField: {
        type: 1,
        number: "2025550172",
        country: {
          id: 288,
          code: "USA",
          name: "United States of America",
          phoneCode: "1",
          iso: "US",
          isoCode: "US",
        },
      },
    },
  };
}

const form = reduxForm({
  form: "Library",
})(Form);

export default connect(mapStateToProps, {})(form);
