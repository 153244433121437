import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { COMMON, PAGES } from "../../constants/localization";
import { AddReceiptStep, RemoveWorkflowStep } from "../../actions/moneyTransfer";
import { RootState } from "../../types/redux";
import { WorkflowStep } from "../../types/moneyTransfer";
import MoneyTransferApi from "../../api/moneyTransfer";
import { CommitMoneyTransferResponse, ValidateWorkflowResponse } from "../../types/api/moneyTransfer";
import {
  ACTION_DISPLAY_TYPES,
  ACCEPTANCE_BEHAVIOR,
  MONEY_TRANSFER_DIRECTIVE_TYPES,
  ICONS,
  MONEY_TRANSFER_STEPS,
} from "../../constants/enums";
import ENV from '../../constants/environment';
import _ from "lodash";
import { EVENTS } from "../../constants/events";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import i18n from "../../i18n/config";

import ActionLink from "../../components/Common/ActionLink";
import Action from "../../components/Common/Action";
import PinCheck from "../../components/Common/PinCheck";
import Alert from "../../components/Common/Alerts/Alert";
import DisplaySection from "../../components/MoneyTransfer/DisplaySection";
import Receipt from "../MoneyTransfer/Receipt";
import { ROUTES } from "../../constants/clientRoutes";
import { trackEvent } from "../../services/eventTracker";

const Review = ({ pageData, handleError }: { pageData: ValidateWorkflowResponse, handleError: (error: string) => void }) => {
  const openExternalLinksModal = useClickExternalLink();
  const { footer, termsAndConditions, displayFields, displaySections, subTitle, titleSubtext } = pageData.result;
  const dispatch = useDispatch();
  const workflow = useSelector((state: RootState) => state.moneyTransfer.workflow);
  const pageNumber = workflow.pages.findIndex((page: WorkflowStep) => page.type === MONEY_TRANSFER_STEPS.REVIEW) + 1;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPinCheckModal, setShowPinCheckModal] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Updates the page number in the redux store to navigate to the previous page
   */
  const onBack = () => {
    dispatch(RemoveWorkflowStep(pageNumber - 1));
  }

  /**
   * Handles when user clicks the confirm and send button
   */
  const handleClick = () => {
    trackEvent(EVENTS.TO_ACCOUNT.REVIEW_CONFIRM_COMPLETED);
    setShowPinCheckModal(true)
  }

  const handlePinCheckSuccess = async () => {
    setShowPinCheckModal(false);
    setLoading(true);

    try {
      const response = await MoneyTransferApi.Commit(workflow.quoteId, workflow.serviceType) as CommitMoneyTransferResponse;
      dispatch(AddReceiptStep(response,
        {
          number: workflow.pages.length + 1,
          order: workflow.pages.length + 1,
          title: MONEY_TRANSFER_STEPS.RECEIPT,
          type: MONEY_TRANSFER_STEPS.RECEIPT,
          renderComponent: () => (
            <Receipt redirectUrl={`${ENV.BASE_NAVIGATOR_URL}${ROUTES.WU_TO_ACCOUNT.INDEX}`} />
          )
        }
      ))
      setLoading(false);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  }

  const handlePinCheckDismiss = async () => {
    setShowPinCheckModal(false);
  }

  const createDirectiveElement = (directive) => {
    const { text, type, url } = directive;
    if (type === MONEY_TRANSFER_DIRECTIVE_TYPES.EXTERNAL_LINK) {
      return (
        <a
          key={directive.id}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => openExternalLinksModal(e, url)}
          title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
          className="footer-directive-link">
          {text}
        </a>
      )
    }
  }

  /**
   * Takes the label and inserts a link where the given delimeter is.
   * Copy pasta from https://github.com/facebook/react/issues/3386#issuecomment-291152357
   * @return {object} A JSX object containing an array of alternating strings and JSX
   */
  const renderTermsLabel = (acceptanceText) => {
    const { text, directives } = acceptanceText;
    let acceptanceTextStr = text;
    // Replace "," with " ," in acceptanceTextStr to make it easier split on " ";
    // acceptanceTextStr can come back like this "I have read and agree to {PRIVACYSTATEMENT}, {TERMSANDCONDITIONS}, and {ESIGN}"
    // So adding whitespace in front of comma makes it easier to split into array
    // Cannot just do string.replace({DIRECTIVE.ID}, React.Component) as you can't insert a React component into string or will get:
    // "I have read and agree to [object Object], [object Object], and [object Object]"
    // Mapping over array (created from splitting string) allows strings and react components to be rendered
    // Finally, remove whitespace between react component and comma if comma is next element in array
    acceptanceTextStr = acceptanceTextStr.replace(/,/g, " ,");
    const acceptanceTextParts = acceptanceTextStr.split(" ");

    return (
      <span>
        {acceptanceTextParts.map((item, index) => {
          const directiveObj = directives.find(directive => `{${directive.id}}` === item);
          return directiveObj
            ? <>{createDirectiveElement(directiveObj)}{acceptanceTextParts[index + 1] === "," ? "" : " "}</>
            : item + " ";
        })}
      </span>
    );
  }

  const renderFooter = (termsAndConditions) => {
    return (
      <div className={`terms-footer western-union`}>
        <div>
          {termsAndConditions &&
            termsAndConditions.behavior !==
            ACCEPTANCE_BEHAVIOR.DO_NOT_SHOW && (
              <div className="checkbox">
                <input
                  type="checkbox"
                  id="terms"
                  onChange={() => setTermsAccepted(!termsAccepted)}
                />{" "}
                <label htmlFor="terms">{renderTermsLabel(termsAndConditions.acceptanceTextV2)}</label>
              </div>
            )}
        </div>
      </div>
    );
  }

  return (
    <div className="review">

      <p className="large">{subTitle}</p>
      <p className="bold marengo-grey">{titleSubtext}</p>

      <Alert
        alertClasses={"alert-info alert-bkgrd-white spacing-top-small flex-row align-items-start message-ocean-blue message-btm-margin bold"}
        message={PAGES.MONEY_TRANSFER.REVIEW.ALERT_HEADING}
        isHTML={false}
        icon={ICONS.INFO}
        iconClasses={"spacing-right-tiny"}
      >
        <p className="spacing-bottom-none font-regular">{PAGES.MONEY_TRANSFER.REVIEW.ALERT_MESSAGE}</p>
      </Alert>

      {displayFields?.map(field => <p className="marengo-grey" key={field.id}>{field.formattedValue}</p>)}

      <DisplaySection sections={displaySections} />

      <hr />

      {termsAndConditions && termsAndConditions.behavior !==
        ACCEPTANCE_BEHAVIOR.DO_NOT_SHOW && (
          <p
            className="x-small terms-and-conditions"
            dangerouslySetInnerHTML={{
              __html:
                termsAndConditions.content
            }}
          ></p>
        )}

      {footer &&
        <div className="x-small terms-and-conditions" dangerouslySetInnerHTML={{ __html: footer.content }} />
      }

      {footer && footer.disclaimers.length > 0 &&
        footer.disclaimers.map((d) => {
          if (d.superscript) {
            return (
              <div className="disclaimer" key={_.uniqueId()}>
                <sup>{d.superscript}</sup> {d.text}
              </div>
            );
          } else {
            return (
              <div className="disclaimer" key={_.uniqueId()}>
                {d.text}
              </div>
            );
          }
        })
      }

      {renderFooter(termsAndConditions)}

      <div className="row navRow">
        <div className={`col-sm-6`}>
          <ActionLink
            clickFunc={onBack}
            classes={"mt-link"}
          >
            <div className="glyphicon glyphicon-menu-left link_icon"></div>
            {COMMON.BACK}
          </ActionLink>
        </div>


        <div className="col-xs-12 col-sm-6 button-cell one">
          <Action
            loading={loading}
            onClick={handleClick}
            disabled={
              termsAndConditions &&
              termsAndConditions.behavior ===
              ACCEPTANCE_BEHAVIOR.SHOW_AND_REQUIRE &&
              !termsAccepted
            }
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            title={PAGES.MONEY_TRANSFER.REVIEW.CONFIRM_AND_SEND}
          />
        </div>
      </div>

      <PinCheck
        open={showPinCheckModal}
        onSuccess={handlePinCheckSuccess}
        onDismiss={handlePinCheckDismiss}
        source={{
          name: PAGES.MONEY_TRANSFER.MONEY_TRANSFER,
          type: PAGES.MONEY_TRANSFER.CASH_PICKUP,
        }}
      />
    </div>
  );
}

export default Review;
