import { createSelector } from "reselect";
import { ROUTES } from "../constants/clientRoutes";
import { DIRECTIVE_TYPE } from "../constants/enums";
import { isFocusedLayout } from "../services/location";

const restrictLocation = (location) => {
  return (
    location?.pathname?.startsWith(ROUTES.SETTINGS.FORGOT_PASSWORD)
  );
};
const directiveSelector = (state) => state.auth.authResult.directive;
const locationSelector = (state) => state.router.location;
const getFocusedLayoutValue = (directive, location) => {
  let fcEnrollment =
    directive.directiveType == DIRECTIVE_TYPE.FaceCheckEnrollment;
  return (
    fcEnrollment || restrictLocation(location) || isFocusedLayout(location)
  );
};

export default createSelector(
  directiveSelector,
  locationSelector,
  getFocusedLayoutValue
);
