import React from "react";
import UserApi from "../../api/user";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import { connect } from "react-redux";
import { AddErrors, ClearErrors } from "../../actions/errors";
import PropTypes from "prop-types";
import PasswordResetError from "./PasswordResetError";
import PageBlock from "../../components/Layouts/PageBlock.js";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import NewPasswordEntry from "./NewPasswordEntry";
import * as _ from "lodash";
import InputWrapper from "../Pin/InputWrapper";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import { PAGE_NAV_LINKS, PAGES } from "../../constants/localization";
import FlowScreen from "../../components/Layouts/FlowScreen";
import { IconFont } from "../../components/Common/IconFont";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../constants/iconfont";
import PageAlert from "../../components/Common/PageAlert";
import PAGE_ALERT from "../../constants/pageAlertTypes";
import { ChangeUserPassword } from "../../actions/user";

class ChangeCurrentPasswordContainer extends React.Component {
  constructor() {
    super();

    this.state = {
      hasError: false,
      isExpired: false,
      errorMessage: "",
      errorTitle: "",
      sessionInfo: {},
      showGenericErrorModal: false,
      loading: false,
      changePasswordResponse: {},
      successfulUpdate: false,
    };
  }

  /**
   * Initializes the session if not already initialized.
   */
  componentDidMount() {
    this.setState({
      loading: true,
    });
    if (_.isEmpty(this.props.sessionInfo)) {
      this.intializeSession();
    } else {
      const { duration, token, passwordValidationRules } =
        this.props.sessionInfo;
      this.setSessionInfo({
        token,
        duration,
        passwordValidationRules,
        isExpired: false,
      });
    }
  }

  /**
   * Changes to the password success step if the change password response is successful.
   * The invokes clearFieldValues in child component to clear the input fields
   * @param {} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.changePasswordResponse !== this.props.changePasswordResponse
    ) {
      let response = this.props.changePasswordResponse;
      if (response?.success) {
        this.setState({ successfulUpdate: true }, () => {
          this.child.clearFieldValues();
        });
      }
    }
  }

  /**
   * Clean up before unmounting
   */
  componentWillUnmount() {
    // Clean up step - remove timeout when the component is unmounted.
    clearTimeout(this.turnOffSessionTimeout);
  }

  /**
   * Sets the Change password session to state.
   */
  setSessionInfo = (sessionInfo) => {
    this.setState({
      loading: false,
      sessionInfo: sessionInfo,
    });
  };

  /**
   * Initializes or retrieves the change password session.
   * If a session is available, it returns the token,
   * duration (how much time remaining in the session),
   * and the password validation rules.
   */
  intializeSession = () => {
    this.setState(
      {
        loading: true,
      },
      async () => {
        try {
          let response = await UserApi.InitializeChangeCurrentPasswordSession();
          const { duration, token, rules } = response;
          this.setSessionInfo({
            token,
            duration,
            passwordValidationRules: rules,
          });
        } catch (error) {
          let {
            data: { errors },
          } = error;
          if (error.status != 500) {
            this.setState({
              hasError: true,
              loading: false,
              errorTitle: errors[0].errorTitle,
              errorMessage: errors[0].errorMessage,
            });
          } else {
            this.setState({
              hasError: true,
              showGenericErrorModal: true,
              loading: false,
            });
          }
        }
      }
    );
  };

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.loading && (
          <PageBlock>
            <PageBlock.Loader loadingContent={this.state.loading}>
              <InputSkeletonGroup numInputs={1} />
            </PageBlock.Loader>
          </PageBlock>
        )}
        {!this.state.loading && this.state.hasError && (
          <PasswordResetError
            title={this.state.errorTitle}
            description={this.state.errorMessage}
            isExpired={this.state.isExpired}
            sessionInfo={this.state.sessionInfo}
          />
        )}
        {!this.state.loading && !this.state.hasError && (
          <React.Fragment>
            {this.state.successfulUpdate && (
              <PageAlert type={PAGE_ALERT.SUCCESS}>
                <IconFont
                  icon={ICON_FONT_CLASS.CHECKMARK}
                  type={ICON_TYPE.SUCCESS}
                />{" "}
                {PAGES.CHANGE_PASSWORD.PAGE_ALERT_SUCCESSFUL}
              </PageAlert>
            )}
            <LayoutWithNav>
              <LayoutWithNav.PageNav>
                <PageNav>
                  <PageNav.Header>
                    {PAGE_NAV_LINKS.SETTINGS.HEADER}
                  </PageNav.Header>
                  <PageNav.NavContent
                    navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
                  />
                </PageNav>
              </LayoutWithNav.PageNav>
              <LayoutWithNav.Content>
                <FlowScreen flowTitle={PAGES.CHANGE_PASSWORD.CONTAINER_TITLE}>
                  <InputWrapper>
                    <NewPasswordEntry
                      onRef={(ref) => (this.child = ref)}
                      showCurrentPwdInput={true}
                      loading={this.state.loading}
                      sessionInfo={this.state.sessionInfo}
                      addError={this.props.addError}
                      clearError={this.props.clearError}
                      resetUserPassword={this.props.resetUserPassword}
                      changePasswordResponse={this.props.changePasswordResponse}
                      showGenericErrorModal={this.state.showGenericErrorModal}
                    />
                  </InputWrapper>
                </FlowScreen>
              </LayoutWithNav.Content>
            </LayoutWithNav>
          </React.Fragment>
        )}
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addError: (errors) => {
      dispatch(AddErrors(errors));
    },
    clearError: () => {
      dispatch(ClearErrors());
    },
    resetUserPassword: (token, currentPassword, newPassword) => {
      dispatch(ChangeUserPassword(token, currentPassword, newPassword));
    },
  };
}

function mapStateToProps(state) {
  return {
    errors: state.errors,
    changePasswordResponse: state.user.changePasswordResponse,
  };
}

ChangeCurrentPasswordContainer.propTypes = {
  token: PropTypes.string,
  errors: PropTypes.array,
  addError: PropTypes.func,
  clearError: PropTypes.func,
  loading: PropTypes.bool,
  sessionInfo: PropTypes.object,
  resetUserPassword: PropTypes.func,
  changePasswordResponse: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeCurrentPasswordContainer);
