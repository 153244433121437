import { BANK_ACCOUNTS } from "../constants/actionTypes";
import { DisbursementHistoryItem } from "../types/api/bankAccounts";
import { CurrencyType } from "../types/api/catalog";

/**
 * Action type for displaying the delete modal for a bank account
 * @param token
 */
export function ShowDeleteModal(allocationId: number, showModal: boolean) {
  return {
    type: BANK_ACCOUNTS.SHOW_DELETE_MODAL,
    allocationId,
    showModal
  };
}

/**
 * Action type when a bank account is successfully deleted
 * @param token
 */
export function BankAccountDeleted(payload: boolean) {
  return {
    type: BANK_ACCOUNTS.BANK_ACCOUNT_DELETED,
    payload
  };
}

/**
 * Action type to set the disbursement history for a bank account
 * @param token
 */
export function SetBankAccountDisbursementHistory(payload: { showDisbursementHistory: boolean, bankAccountName: string, bankAccountNumber: string, disbursementHistory: DisbursementHistoryItem[] }) {
  return {
    type: BANK_ACCOUNTS.SET_BANK_ACCOUNT_DISBURSEMENT_HISTORY,
    payload
  };
}

/**
 * Action type to set currencies and destination country name for a bank account
 * @param token
 */
export function SetSelectedCountry(currencies: CurrencyType[], destinationCountryName: string) {
  return {
    type: BANK_ACCOUNTS.SET_SELECTED_COUNTRY,
    currencies,
    destinationCountryName
  };
}

/**
 * Action type to set full name of currency for a bank account
 * @param token
 */
export function SetSelectedCurrency(destinationCurrencyName: string) {
  return {
    type: BANK_ACCOUNTS.SET_SELECTED_CURRENCY,
    destinationCurrencyName
  };
}