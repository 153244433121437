import axios from "axios";
import Cookies from "universal-cookie";
import { HTTP_REQUEST_METHOD, HEADERS } from "../constants/api";
import ENV from "../constants/environment";
import { parseAuthToken } from "./auth";

/**
 * Loads the auth token from a cookie
 * @returns {object} The token. Returns null if the cookie is missing.
 */
const getAuthToken = () => {
  const cookie = new Cookies();
  let tokenCookie = cookie.get(ENV.TOKEN_COOKIE);

  return tokenCookie;
};

const handleMissingToken = (token) => {
  if (!token) {
    throw "No authorization token found";
  }
};

/// Thin wrappers around axios to keep it abstracted.
/// NOTE: This only supports authorized requests right now
/// Support for unauthorized will have to be added later.

const requestAuth = async (url, method, data, extraHeaders) => {
  let tokenCookie = getAuthToken();
  handleMissingToken(tokenCookie);
  let token = parseAuthToken(tokenCookie);
  let headers;

  if (extraHeaders) {
    headers = {
      ...HEADERS,
      ...extraHeaders,
      Authorization: `Bearer ${token.accessToken}`,
    };
  } else {
    headers = {
      ...HEADERS,
      Authorization: `Bearer ${token.accessToken}`,
    };
  }

  return await request(url, method, data, headers);
};

const request = (url, method, data, headers) => {
  return axios({
    url: url,
    method: method,
    data: data,
    headers: headers,
  });
};

/**
 * Make network request using an auth bearer token if present
 * otherwise do an anonymous request
 * @param {string} url
 */
export const getAuthOrAnonymous = (url) => {
  let tokenCookie = getAuthToken();
  return tokenCookie
    ? requestAuth(url, HTTP_REQUEST_METHOD.GET)
    : getAnonymous(url);
};

export const get = (url) => {
  return requestAuth(url, HTTP_REQUEST_METHOD.GET);
};

export const post = (url, data, headers) => {
  return requestAuth(url, HTTP_REQUEST_METHOD.POST, data, headers);
};

export const put = (url, data) => {
  return requestAuth(url, HTTP_REQUEST_METHOD.PUT, data);
};

export const deleteMethod = (url, data) => {
  return requestAuth(url, HTTP_REQUEST_METHOD.DELETE, data);
};

export const getAnonymous = (url) => {
  return request(url, HTTP_REQUEST_METHOD.GET);
};

export const postAnonymous = (url, data, headers) => {
  return request(url, HTTP_REQUEST_METHOD.POST, data, headers);
};
export const putAnonymous = (url, data, headers) => {
  return request(url, HTTP_REQUEST_METHOD.PUT, data, headers);
};
