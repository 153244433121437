import React, { useEffect, useState } from 'react';
import UserApi from '../../api/user';
import InputSkeletonGroup from '../Common/Loaders/InputSkeleton';
import PaymentTable from './PaymentTable';
import ENV from "../../constants/environment";
import i18n from '../../i18n/config';

const PaymentSummary = () => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getPayments = async () => {
      const response = await UserApi.GetPaymentSummary();
      setPayments(response);
      setLoading(false);
    }

    getPayments();
  }, []);

  return (
    <section className="row payment-summary spacing-right-none spacing-left-none">
      <div className="panel panel-default">
        <div className="panel-heading">
          <h2>{i18n.t("PAGES.DASHBOARD.PAYMENT_SUMMARY_HEADER")}</h2>
        </div>
        {loading &&
          <div className="padding-top-small padding-right-small padding-bottom-small padding-left-small border-bottom">
            <InputSkeletonGroup numInputs={4} />
          </div>
        }

        {!loading && payments.disbursementDate &&
          <PaymentTable payments={payments} />
        }

        {!loading && !payments.disbursementDate &&
          <ul className="list-group"><li className="list-group-item no-border">No payments could be found</li></ul>
        }

        {!loading &&
          <a href={`${ENV.BASE_NAVIGATOR_URL}/Payments`} className="payment-history-link center text">View Payment History</a>
        }

      </div>
    </section>
  );
}

export default PaymentSummary;