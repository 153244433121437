import React, { Component } from "react";
import PropTypes from "prop-types";
import DynamicField from "../Common/DynamicFields/DynamicInputField";
import { get } from "../../api/api";
import { connect } from "react-redux";
import { getFormValues, change, untouch } from "redux-form";
import {
  RELATED_FIELDS,
  INFO_MODAL_PICTURES,
  SERVICE_PROVIDER_FIELD_ID,
  FIELD_TYPES_BY_NAME,
  FIELD_TYPES,
  ICONS,
} from "../../constants/enums";
import _ from "lodash";
import InfoPictureModal from "../../components/Common/Modals/InfoPictureModal";
import { eventTracker, EVENTS } from "../../services/eventTracker";
import Alert from "../Common/Alerts/Alert";
import { flattenObject } from "../../services/formatter";

/**
 * Displays a collection of dynamic fields in sections.
 */
export class DynamicForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldCategory: null,
      showModal: false,
      modalOptions: {},
      parentRelatedFields: []
    };

    this.rebuildField = this.rebuildField.bind(this);
    this.renderField = this.renderField.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  /**
   * Update the dynamic form state with the fields needed for the cascading dropdowns.
   * As the functionality of related fields changes, this can be expanded to things such as
   * field required based on the changing of a separate field.
   * @param {*} fieldIdToUpdate The cascading field that needs its options populated
   * @param {*} options The options to populate
   * @param {*} event The field's on change event passed back
   */
  rebuildField = async (field) => {
    const currentFormValues = flattenObject(this.props.formValues);
    /// Regex matches on tokens in a string like {TOKEN}
    /// This will match urls like /cities?stateId={stateId}
    let regex = /[^{]+(?=})/g;

    /// 1. Get all field ids needed to build the optionsSource
    let predecessorIds = field.optionsSource.match(regex);

    /// 2. Build the new option source URL with the predecessor field values
    let newOptionsSource = field.optionsSource;

    for (let predecessorId of predecessorIds) {
      let predecessorValue = currentFormValues[predecessorId];
      newOptionsSource = newOptionsSource.replace(
        `{${predecessorId}}`,
        predecessorValue
      );
    }

    /// 3. Get the new options
    let options = await this.getOptions(newOptionsSource);

    let dropdownOptions = options.map((x) => {
      return {
        label: x.label,
        value: x.value,
        isDisabled: x.disabled,
      };
    });

    /// 4. Update the component state with the new field options
    let newFieldCategory = {
      ...this.state.fieldCategory,
      sections: this.state.fieldCategory.sections.map((section) => ({
        ...section,
        fields: section.fields.map((f) =>
          f.serviceProviderFieldId === field.serviceProviderFieldId
            ? {
              ...f,
              options: dropdownOptions,
            }
            : f
        ),
      })),
    };

    this.setState({
      fieldCategory: newFieldCategory,
    });

    this.props.dispatch(
      change(this.props.formName, field.serviceProviderFieldId, null)
    );

    /// The untouch is here so that redux form doesn't mark this field as required once we clear it out.
    this.props.dispatch(
      untouch(this.props.formName, field.serviceProviderFieldId)
    );
  };

  /**
   * Given a field, find all the successor fields.
   * @param {*} predecessorId The ID of the predecessor field.
   */
  getSuccessorFieldsByPredecessorId = (predecessorId) => {
    let successorFields = [];
    for (let section of this.state.fieldCategory.sections) {
      for (let field of section.fields) {
        let matchingPredecessor = field.relatedFields.find(
          (relatedField) =>
            relatedField.type == RELATED_FIELDS.PREDECESSOR &&
            relatedField.id == predecessorId
        );

        if (matchingPredecessor) {
          successorFields.push(field);
        }
      }
    }

    return successorFields;
  };

  mapFieldType = (type) => {
    let fieldType;

    switch (type) {
      case FIELD_TYPES_BY_NAME.PHONE_NUMBER:
        fieldType = FIELD_TYPES.PHONE_NUMBER;
        break;
      case FIELD_TYPES_BY_NAME.CASCADING_DROPDOWN:
        fieldType = FIELD_TYPES.CASCADING_SELECT;
        break;
      case FIELD_TYPES_BY_NAME.BOOL:
        fieldType = FIELD_TYPES.BOOL;
        break;
      case FIELD_TYPES_BY_NAME.DROPDOWN:
      case FIELD_TYPES_BY_NAME.COUNTRY:
      case FIELD_TYPES_BY_NAME.SELECT:
        fieldType = FIELD_TYPES.SELECT;
        break;
      default:
        fieldType = FIELD_TYPES.TEXT;
        break;
    }

    return fieldType;
  }

  renderField = (field) => {
    const type = this.mapFieldType(field.datatype);
    let dynamicField = {
      label: field.name,
      placeholderText:
        field.pattern != null ? field.pattern : field.placeholderText,
      type: type,
      max: field.max,
      required: field.isRequired,
      valid: field.isValid,
      validationError: field.validationError,
      regex: field.regex,
      id: field.serviceProviderFieldId,
      options: field.options,
      value: field.value,
      optionSource: field.optionSource,
      moreInfo: field.moreInfo != null ? field.moreInfo : null,
      showModal: field.moreInfo != null ? this.showModal : null,
      pattern: field.pattern != null ? field.pattern : null,
      isReadOnly: field.isReadOnly
    };

    if (field.isSensitive) {
      dynamicField["isSensitive"] = field.isSensitive;
    }

    if (field.visibleIf) {
      // Find related parent field in state;
      const parentField = this.state.parentRelatedFields.find(parentField => parentField.name === field.visibleIf.fieldId);
      const isVisible = parentField.value === field.visibleIf.value;

      if (!isVisible) {
        return null;
      }
    }

    if (field.requiredIf) {
      const parentField = this.state.parentRelatedFields.find(parentField => parentField.name === field.requiredIf.fieldId);
      const isRequired = parentField.value === field.requiredIf.value;

      if (isRequired) {
        dynamicField.required = true;
      }
    }

    return (
      <div
        className="col-xs-12 col-sm-6 no-float"
        key={field.serviceProviderFieldId}
      >
        <DynamicField {...dynamicField} />
      </div>
    );
  };

  showModal = (modalContent) => {
    if (modalContent != null) {
      this.trackInfoModalOpening(modalContent.fieldId);
      this.setState({
        showModal: true,
        modalOptions: {
          ...modalContent,
        },
      });
    }
  };

  trackInfoModalOpening = (fieldId) => {
    switch (fieldId) {
      case SERVICE_PROVIDER_FIELD_ID.CARD_EXP_DATE:
        eventTracker.track(EVENTS.ChangePIN_CardExpDateToolTip);
        break;
      case SERVICE_PROVIDER_FIELD_ID.CARD_LAST_SIX:
        eventTracker.track(EVENTS.ChangePIN_CardLastSixToolTip);
        break;
      default:
        break;
    }
  };

  closeModal = () => {
    this.setState({
      showModal: false,
    });
  };

  /**
   * Get the options from the provided url to populate a dropdown
   * @param {*} url Url to get the options
   */
  getOptions = async (url) => {
    return url
      ? await get(url)
        .then((response) => {
          return response.data ? response.data.options : [];
        })
        .catch(
          function () {
            return [];
          }.bind(this)
        )
      : [];
  };

  setUpRelatedFields = (sections) => {
    // Find parent related fields to determine if we should display/hide child related fields
    let parentRelatedFields = [];
    sections.forEach(section => {
      section.fields.forEach(field => {
        // If field has related fields with type GROUP_TARGET, then there are child fields dependent on this field's value
        if (field.relatedFields && field.relatedFields.length > 0 && field.relatedFields[0].type === RELATED_FIELDS.GROUP_TARGET) {
          parentRelatedFields.push(field);
          let value = field.value;

          // If value of parentRelatedField has already been set, then use that value in state.
          // (ex. user has a selected Name Format value, goes to next page, and then goes back to page with Name Format)
          if (this.props.formValues[field.serviceProviderFieldId]) {
            value = this.props.formValues[field.serviceProviderFieldId];
          }

          this.setState({
            parentRelatedFields: [
              ...this.state.parentRelatedFields,
              { name: field.serviceProviderFieldId, value: value }
            ]
          });
        }
      })
    });
    this.setState({ fieldCategory: this.props.fieldCategory });
  }

  componentDidMount() {
    this.setUpRelatedFields(this.props.fieldCategory.sections);
  }

  /**
   * Lifecycle method that fires when either props or state changes.
   */
  async componentDidUpdate(prevProps) {
    if (prevProps.fieldCategory != this.props.fieldCategory) {
      this.setState({ fieldCategory: this.props.fieldCategory });
      this.setUpRelatedFields(this.props.fieldCategory.sections);
    }

    if (prevProps.formValues != this.props.formValues) {
      const prevFormValues = flattenObject(prevProps.formValues);
      const currentFormValues = flattenObject(this.props.formValues);
      /// When a field is cleared out it no longer appears in the formValues colleciton.
      /// We still want to update any successor fields though
      /// so we need a union of fields are are present and ones that aren't.
      let prevFieldIds = _.keysIn(prevFormValues);
      let currentFieldIds = _.keysIn(currentFormValues);
      let allFieldIds = _.union(prevFieldIds, currentFieldIds);

      for (let property of allFieldIds) {
        if (
          !prevProps.formValues ||
          prevFormValues[property] != currentFormValues[property]
        ) {
          let successorFields =
            this.getSuccessorFieldsByPredecessorId(property);

          // Find if property that was updated is in state.parentRelatedFields
          // We can update the value in state, so that child related fields are rendered based on visibleIf property
          const parentRelatedField = this.state.parentRelatedFields.find(relatedField => relatedField.name === property);

          if (parentRelatedField) {
            parentRelatedField.value = this.props.formValues[property];
            this.setState({ parentRelatedFields: [...this.state.parentRelatedFields, parentRelatedField] });

            // When parentRelatedField value is changed 
            // We loop through fields and remove fields from redux-form based on if their visibleIf value does not match current parentRelatedField value
            this.state.fieldCategory.sections.forEach(section => {
              section.fields.forEach(field => {
                if (field.visibleIf) {
                  if (field.visibleIf.value !== parentRelatedField.value) {
                    this.props.dispatch(
                      change(this.props.formName, field.serviceProviderFieldId, '')
                    );
                  }
                }
              })
            })
          }

          for (let successorField of successorFields) {
            await this.rebuildField(successorField);
          }
        }
      }
    }
  }

  render() {
    if (this.state.fieldCategory == undefined) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="field-category">
          {this.state.fieldCategory.sections.map((section, i) => (
            <div className="section" key={i}>
              <div className="section-header">
                {section.name}
                {section.info &&
                  <Alert
                    alertClasses={"alert-info alert-bkgrd-white spacing-top-small flex-row align-items-center font-regular"}
                    message={section.info}
                    isHTML={true}
                    icon={ICONS.INFO}
                    iconClasses={"spacing-right-tiny"}
                  />
                }
              </div>

              <div className="row no-float-row">
                {section.fields.map((field) => (
                  this.renderField(field)
                ))}
              </div>
            </div>
          ))}
        </div>
        {this.state.modalOptions.title &&
          this.state.modalOptions.image &&
          this.state.modalOptions.message && (
            <InfoPictureModal
              picture={INFO_MODAL_PICTURES[this.state.modalOptions.image]}
              content={this.state.modalOptions.message}
              onClose={this.closeModal}
              title={this.state.modalOptions.title}
              open={this.state.showModal}
            />
          )}
      </React.Fragment>
    );
  }
}

DynamicForm.propTypes = {
  /** Event that fires when any field changes. Has a single param representing the entire category. */
  onChange: PropTypes.func,
  /** Category object with a collection of sections with fields */
  fieldCategory: PropTypes.object,
  formName: PropTypes.string,
  formValues: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(props.formName)(state),
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToProps, mapDispatchToProps)(DynamicForm);
