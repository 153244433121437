import React from "react";
import PropTypes from "prop-types";
import { PASSWORD_RULE_STATUS } from "../../constants/enums";

const validationStatus = {
  neutral: "#C5D0DE",
  passed: "#42CB91",
  failed: "#FF0033",
};

const ValidationCheckmark = ({ ruleStatus }) => {
  const useCheckamrkIcon =
    ruleStatus == PASSWORD_RULE_STATUS.PASSED ||
    ruleStatus == PASSWORD_RULE_STATUS.NEUTRAL;

  return (
    <svg
      className="password-rule-block__status-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
    >
      <circle
        cx="8"
        cy="8"
        r="7.25"
        stroke={validationStatus[ruleStatus]}
        strokeWidth="1.5"
      ></circle>
      {useCheckamrkIcon && (
        <React.Fragment>
          <path
            stroke={validationStatus[ruleStatus]}
            strokeWidth="1.5"
            d="M12.209 5.69l-5.186 5.186-2.357-2.358"
          ></path>
        </React.Fragment>
      )}
      {ruleStatus == "failed" && (
        <React.Fragment>
          <path
            stroke={validationStatus[ruleStatus]}
            strokeWidth="1.5"
            d="M5.5 5.5l5 5m0-5l-5 5 5-5z"
          ></path>
        </React.Fragment>
      )}
    </svg>
  );
};

ValidationCheckmark.propTypes = {
  ruleStatus: PropTypes.string.isRequired,
};

ValidationCheckmark.defaultProps = {
  passed: "neutral",
};

export default ValidationCheckmark;
