import React from "react";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

const FlowBackToLoginButton = (props) => {
  return (
    <Action
      iconRight={null}
      title={COMMON.BACK_TO_LOGIN}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      className="btn-lg flowbutton__return-login"
      onClick={() => (location.href = ROUTES.EXTERNAL.LOGIN)}
      {...props}
    />
  );
};

export default FlowBackToLoginButton;
