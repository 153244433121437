import ENV from "../constants/environment";
import { CARD_DIRECTIVE_ROUTES } from "../constants/enums";

class AlertService {

  static InActiveCardMessage(card) {
    let inActiveCardMessage;

    if (card.inActiveCardMessage) {
      inActiveCardMessage = card.inActiveCardMessage.message;
      let { directives } = card.inActiveCardMessage;

      directives.forEach(directive => {
        let route = CARD_DIRECTIVE_ROUTES[directive.directiveType];
        let link = `<a href="${ENV.BASE_NAVIGATOR_URL}/${route}">${directive.directiveText}</a>`;
        inActiveCardMessage = inActiveCardMessage.replace(`{${directive.directiveType}}`, link);
      })
    }

    return inActiveCardMessage;
  }

  static ReplaceDirectives(message, directives) {
    directives.forEach(directive => {
      let route = CARD_DIRECTIVE_ROUTES[directive.directiveType];
      let link = `<a href="${ENV.BASE_NAVIGATOR_URL}/${route}">${directive.directiveText}</a>`;
      message = message.replace(`{${directive.directiveType}}`, link);
    })

    return message;
  }
}

export default AlertService;