import { CATALOG } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function stateProvinces(
  state = initialState.catalog.stateProvinces,
  action
) {
  switch (action.type) {
    case CATALOG.GET_STATE_PROVINCES: {
      let stateProvinces = [];
      try {
        stateProvinces = action.stateProvinces.map(function (x) {
          return {
            value: x.code,
            label: x.name,
          };
        });
      } catch (err) {
        stateProvinces = [];
      }

      return stateProvinces;
    }
    default:
      return state;
  }
}
