import React from "react";
import PropTypes from "prop-types";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockTitle = ({ children, id }) =>
  children ? (
    <h2
      className="flow-page-block__title"
      id={id}
      data-cy={CyBuilder("page-block-title", id)}
    >
      {children}
    </h2>
  ) : null;

PageBlockTitle.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default PageBlockTitle;
