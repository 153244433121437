import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubmissionError, change } from "redux-form";
import Modal from "./Modal";
import { COMMON, PAGES } from "../../constants/localization";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import PinCheckForm from "./PinCheckForm";
import { PinCheck } from "../../actions/user";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker, EVENTS } from "../../services/eventTracker";

/**
 * Pin check interaction accepts pin and validates it with the API.
 * Too many failed attempts will show a new modal with support contact options.
 * This control takes 2 callbacks, success and failure.
 */
class PinCheckModalV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLockedOutModal: false,
    };

    this.handlePinSubmit = this.handlePinSubmit.bind(this);
    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    eventTracker.track(EVENTS.PINCheck_Close);

    this.setState({ showLockedOutModal: false });
    this.props.onDismiss();
  }

  handlePinSubmit(values) {
    return this.props
      .PinCheck(values.pin, this.props.cardId, this.props.source)
      .catch(() => {
        throw new SubmissionError({ _error: COMMON.ERROR });
      })
      .then(() => {
        if (this.props.pinCheck.success) {
          this.props.onSuccess();
        } else if (this.props.pinCheck.tooManyAttempts) {
          this.setState({ showLockedOutModal: true });
        } else {
          this.props.dispatch(change("pin-check", "pin", ""));
          throw new SubmissionError({ pin: COMMON.PIN_CHECK.WRONG_PIN });
        }
      });
  }

  render() {
    return (
      <div>
        <Modal
          open={this.props.open && !this.state.showLockedOutModal}
          title={COMMON.PIN_CHECK.PIN}
          content={<PinCheckForm onSubmit={this.handlePinSubmit} />}
          small
          onClose={this.dismiss}
          disableNativeActions
        />

        <Modal
          open={this.props.open && this.state.showLockedOutModal}
          title={COMMON.PIN_CHECK.TOO_MANY_PIN_ATTEMPTS}
          content={COMMON.PIN_CHECK.LOCKED_OUT_MESSAGE}
          onClose={this.dismiss}
          small
          actions={[
            {
              title: COMMON.OK,
              onClick: () => (location.href = ROUTES.EXTERNAL.DASHBOARD),
              displayType: ACTION_DISPLAY_TYPES.PRIMARY,
              focused: true,
            },
            {
              title: PAGES.SUPPORT.CONTACT_SUPPORT,
              onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
              displayType: ACTION_DISPLAY_TYPES.SECONDARY,
            },
          ]}
        />
      </div>
    );
  }
}

PinCheckModalV2.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  source: PropTypes.object.isRequired,
  PinCheck: PropTypes.func,
  cardId: PropTypes.number,
  dispatch: PropTypes.func,
  pinCheck: PropTypes.object,
};

let mapStateToProps = (state) => ({
  cardId: state.card.id,
  pinCheck: state.user.pinCheck,
});

let mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
    ...bindActionCreators({ PinCheck }, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinCheckModalV2);
