import React from "react";
import Lottie from "react-lottie";
import * as checkmark from "../../assets/animations/checkmark.json";
import { PAGES } from "../../constants/localization";
import PageBlock from "../../components/Layouts/PageBlock.js";
import { ROUTES } from "../../constants/clientRoutes";
import PropTypes from "prop-types";
import Action from "../../components/Common/Action.js";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums.js";
import { eventTracker, EVENTS } from "../../services/eventTracker.js";

const animationOptions = {
  loop: false,
  autoplay: true,
  animationData: checkmark,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const trackEventAndRedirect = () => {
  eventTracker.track(EVENTS.ChangePIN_Done);
  location.href = `${ROUTES.EXTERNAL.DASHBOARD}`;
};

const PinSuccess = (props) => {
  return (
    <PageBlock>
      <PageBlock.Body>
        <Lottie
          options={animationOptions}
          height={80}
          width={140}
          isStopped={false}
          isPaused={false}
        />
        <div className="pin-success-text">
          <div className="pin-success-text__header">{props.title}</div>
          <p className="pin-success-text__sub-text">{props.description}</p>
          <Action
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            onClick={trackEventAndRedirect}
            className="btn-lg pin-success__return-dashboard"
            title={PAGES.PIN_RESET.RETURN_DASHBOARD}
          />
        </div>
      </PageBlock.Body>
    </PageBlock>
  );
};

PinSuccess.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default PinSuccess;
