import React, { Component } from "react";
import { reduxForm, getFormValues } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const FormBody = ({ children }) => {
  return <div className="form-body">{children}</div>;
};

FormBody.propTypes = {
  children: PropTypes.node,
};

/**
 * Any children that are inputs should be redux form Fields
 */
class GenericForm extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    /// As of now we only care if the form is
    /// valid. In the future we could care about
    /// way more props.
    if (prevProps.valid != this.props.valid) {
      this.props.onUpdate(this.props.valid);
    }
  }

  render() {
    return (
      <form
        name={this.props.form}
        id={this.props.form}
        onSubmit={this.props.onSubmit}
      >
        {this.props.children}
      </form>
    );
  }
}

GenericForm.Body = FormBody;

GenericForm.propTypes = {
  form: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  valid: PropTypes.bool,
  onUpdate: PropTypes.func,
};

let mapStateToProps = (state, props) => {
  return {
    formValues: getFormValues(props.formName)(state),
  };
};

const form = reduxForm({})(GenericForm);

export default connect(mapStateToProps)(form);