import React from "react";
import PropTypes from "prop-types";
import ActionLink from "../../components/Common/ActionLink";
import { ROUTES } from "../../constants/clientRoutes";
import { COMMON } from "../../constants/localization";

/**
 * Renders a collection of errors related to preventing a money transfer.
 * Includes a "contact support" link.
 * @param restrictions The collection of restrictions.
 * Each item in the array should follow the standard error object format:
 * {
 *   errorCode: "ER100",
 *   errorMessage: "Error message here",
 *   errorDescription: ""
 * }
 * @returns {jsx}
 */
export const Restrictions = ({ restrictions }) => {
  if (restrictions?.length) {
    return (
      <div className="restrictions">
        {restrictions.map((r) => (
          <p key={r.errorCode} className="error">
            {r.errorMessage}
          </p>
        ))}

        <ActionLink
          text={COMMON.CONTACT_SUPPORT}
          href={ROUTES.EXTERNAL.SUPPORT}
          classes="bold"
        />
      </div>
    );
  } else {
    return <div></div>;
  }
};

Restrictions.propTypes = {
  restrictions: PropTypes.array,
};

export default Restrictions;
