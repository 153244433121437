import React from "react";
import PropTypes from "prop-types";
import InputService from "../../../services/input";
import { COMMON } from "../../../constants/localization";

class UserInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = null;
    this.setInputRef = (element) => {
      this.inputRef = element;
    };

    this.state = {
      value: this.props.value || "",
      valid: false,
      hasError: false,
      serviceProviderFieldId: this.props.serviceProviderFieldId || "",
      id: this.props.id || "",
      datatype: this.props.datatype,
    };
  }

  handleChange = (e) => {
    let { pattern } = this.props;
    let input = e.target.value;
    if (pattern) {
      /// This means cursor is at the end of the pattern and
      /// no more characters are allowed, so we exit
      if (this.inputRef.selectionStart > pattern.length) {
        return;
      }
      let { value, setCursor } = InputService.HandlePatternInput(
        pattern,
        input,
        this.inputRef,
        this.state.value
      );

      this.setState(
        {
          value,
          hasError: false,
          valid: this.fieldIsValid(value),
        },
        () => {
          this.inputUpdate();
          setCursor();
        }
      );
    } else {
      this.setState(
        {
          value: input,
          hasError: false,
          valid: this.fieldIsValid(input),
        },
        () => {
          this.inputUpdate();
        }
      );
    }
  };

  handleKeyDown = () => { };

  inputUpdate = () => {
    if (this.props.inputUpdate) {
      let { datatype, id, serviceProviderFieldId, value, hasError, valid } =
        this.state;
      this.props.inputUpdate({
        datatype,
        id,
        serviceProviderFieldId,
        value,
        hasError,
        valid,
      });
    }
  };

  handleBlur = () => {
    this.setState(
      {
        hasError: !this.fieldIsValid(),
      },
      () => {
        this.inputUpdate();
      }
    );
  };

  fieldIsValid = (currentValue) => {
    let { pattern, isRequired } = this.props;
    let { value } = this.state;
    let valueToCheck = currentValue == null ? value : currentValue;
    if (
      (pattern != null && valueToCheck.indexOf("x") != -1 && isRequired) ||
      (!valueToCheck.length && isRequired)
    ) {
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      serviceProviderFieldId,
      id,
      placeholderText,
      pattern,
      datatype,
      max,
    } = this.props;
    const correctId = serviceProviderFieldId || id;
    const placeholder = pattern || placeholderText;
    const maxLength = !pattern ? (max > 0 ? max : null) : null;
    const { type, inputMode } = InputService.GetInputTypeAndMode(
      datatype,
      pattern
    );
    let keyPress = InputService.HandleKeyPress(datatype, maxLength);

    return (
      <div className={`${this.state.hasError ? "has-error" : ""}`}>
        <input
          className={`form-control ${this.props.isSensitive && !this.props.pattern ? "sensitive" : ""
            }`.trim()}
          id={correctId}
          ref={this.setInputRef}
          type={type}
          placeholder={placeholder}
          inputMode={inputMode}
          maxLength={maxLength}
          value={this.state.value}
          required={this.props.isRequired}
          disabled={this.props.disabled}
          onBlur={this.props.onBlur || this.handleBlur}
          onKeyPress={this.props.onKeyPress || keyPress}
          onKeyDown={this.props.onKeyDown || this.handleKeyDown}
          onChange={this.props.onChange || this.handleChange}
        />
        <div
          style={{ visibility: this.state.hasError ? "visible" : "hidden" }}
          className="text-danger input-errors"
        >
          <div>{COMMON.REQUIRED}</div>
        </div>
      </div>
    );
  }
}

UserInput.propTypes = {
  value: PropTypes.string,
  serviceProviderFieldId: PropTypes.string,
  id: PropTypes.string,
  datatype: PropTypes.number,
  pattern: PropTypes.string,
  inputUpdate: PropTypes.func,
  isRequired: PropTypes.bool,
  placeholderText: PropTypes.string,
  max: PropTypes.number,
  isSensitive: PropTypes.bool,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
};

export default UserInput;
