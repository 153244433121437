import { combineReducers } from "redux";
import categories from "./categories";
import conversations from "./conversations";
import errors from "./errors";
import phoneNumbers from "./phoneNumbers";
import activeConversation from "./activeConversation";

export default combineReducers({
  categories,
  conversations,
  activeConversation,
  phoneNumbers,
  errors,
});
