import React from "react";
import { useDispatch } from "react-redux";
import { setExternalLinksModalOpen } from "../actions/global";

const useClickExternalLink = () => {
  const dispatch = useDispatch();

  const openExternalLinksModal = (e: React.MouseEvent<HTMLAnchorElement>, url: string) => {
    e.preventDefault();
    dispatch(setExternalLinksModalOpen({ open: true, url }));
  }


  return openExternalLinksModal;
};

export default useClickExternalLink;