import React from "react";
import PropTypes from "prop-types";
import { ICONS, ICONS_ALT_TEXT } from "../../constants/enums";
import Location from "../../assets/images/icons/Location.svg";
import News_Dashboard from "../../assets/images/icons/News_Dashboard.svg";
import PersonalInfo from "../../assets/images/icons/PersonalInfo_v2.svg";
import ChevronRight from "../../assets/images/icons/ChevronRight.svg";
import Download from "../../assets/images/icons/Download.svg";
import Pencil from "../../assets/images/icons/Pencil.svg";
import Info from "../../assets/images/icons/Info.svg";
import Clock from "../../assets/images/icons/Clock.svg";
import GreenCheck from "../../assets/images/icons/GreenCheck.svg";
import WarningInfo from "../../assets/images/icons/Warning-Info.svg";
import InfoGrey from "../../assets/images/icons/Info_grey.svg";
import ExternalLinkBlue from "../../assets/images/icons/ExternalLink-blue.svg";
import Print from "../../assets/images/icons/Print.svg";
import InfoWhite from "../../assets/images/icons/Info-white.svg";
import CopyBlue from "../../assets/images/icons/Copy-blue.svg";

/**
 * Simple media header
 *
 * @param {number} icon Icon enum
 * @returns {*}
 * @constructor
 */
const Icon = ({ icon, ...props }: { icon: number }) => {
  let src = Location;
  switch (icon) {
    case ICONS.LOCATION:
      src = Location;
      break;
    case ICONS.NEWS_DASHBOARD:
      src = News_Dashboard;
      break;
    case ICONS.PERSONAL_INFO:
      src = PersonalInfo;
      break;
    case ICONS.CHEVRON_RIGHT:
      src = ChevronRight;
      break;
    case ICONS.DOWNLOAD:
      src = Download;
      break;
    case ICONS.PENCIL:
      src = Pencil;
      break;
    case ICONS.INFO:
      src = Info;
      break;
    case ICONS.CLOCK:
      src = Clock;
      break;
    case ICONS.GREEN_CHECK:
      src = GreenCheck;
      break;
    case ICONS.WARNING_INFO:
      src = WarningInfo;
      break;
    case ICONS.INFO_GREY:
      src = InfoGrey;
      break;
    case ICONS.EXTERNAL_LINK_BLUE:
      src = ExternalLinkBlue;
      break;
    case ICONS.PRINT:
      src = Print;
      break;
    case ICONS.INFO_WHITE:
      src = InfoWhite;
      break;
    case ICONS.COPY_BLUE:
      src = CopyBlue
      break;
  }

  return <img src={src} {...props} alt={ICONS_ALT_TEXT[icon]} />;
};

Icon.propTypes = {
  /** Enum value for the icon */
  icon: PropTypes.number.isRequired,
};

export default Icon;
