import AuthApi from "../../../api/auth";
import { ERROR_CODES } from "../../../constants/errorCodes";
import { trackEvent } from "../../eventTracker";
import { EVENTS } from "../../../constants/events";
import { COMMON } from "../../../constants/localization";
import FaceTecSDK from "../../faceTecSDK";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import appInsights from "../../appInsights";

export class LivenessCheckProcessor {
  faceScanResultCallback = null;
  latestSessionResult = null;
  onComplete = null;
  onSessionTokenError = null;
  isSuccess = false;
  // for login we use an auth token, not the auth bearer
  loginToken = false;

  constructor(
    sessionToken = null,
    onComplete = null,
    onSessionTokenError = null,
    loginToken = null
  ) {
    this.faceScanResultCallback = null;
    this.latestSessionResult = null;
    this.onComplete = onComplete;
    this.onSessionTokenError = onSessionTokenError;
    this.loginToken = loginToken;
    this.init = new FaceTecSDK.FaceTecSession(this, sessionToken);
  }

  onFaceTecSDKCompletelyDone() {
    this.onComplete(this.isSuccess, this.latestSessionResult);
  }

  // Required function that handles calling ZoOm Server to get result and decides how to continue.
  // processSessionResultWhileFaceTecSDKWaits(
  processSessionResultWhileFaceTecSDKWaits(
    sessionResult,
    faceScanResultCallback
  ) {
    const _this = this;

    _this.latestSessionResult = sessionResult;
    _this.faceScanResultCallback = faceScanResultCallback;

    // cancellation, timeout, etc.
    if (
      sessionResult.status !==
      FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully
    ) {
      // console.log("Session was not completed successfully, cancelling.  Session Status: " + FaceTecSDK.FaceTecSessionStatus[sessionResult.status]);
      faceScanResultCallback.cancel();
      return;
    }
    // all good proceed
    // complete parameters to be sent to our backend
    let parameters = {
      sessionId: sessionResult.sessionId,
      faceScan: sessionResult.faceScan,
      lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
      auditTrailImage: sessionResult.auditTrail[0],
    };

    //TODO: Move to a service as-is
    //Reject the promise if the request takes too long (60 seconds)
    const promiseTimeout = function (promise, ms, loginToken) {
      return new Promise((resolve, reject) => {
        let id = setTimeout(() => {
          reject(new Error(ERROR_CODES.COMMON.TIMEOUT));
        }, ms);

        promise.then(
          (data) => {
            clearTimeout(id);
            if (loginToken) {
              trackEvent(EVENTS.LOGIN.FACECHECK_SUBMITTED, {
                success: true,
              })
            }
            resolve(data);
          },
          (error) => {
            clearTimeout(id);
            if (loginToken) {
              trackEvent(EVENTS.LOGIN.FACECHECK_SUBMITTED, {
                success: false,
                failure_reason: error?.response?.data?.errors[0]?.errorMessage || COMMON.ERROR,
              })
            }
            reject(error);
          }
        );
      });
    };

    Promise.resolve(true)
      .then(() => {
        let response = _this.loginToken
          ? AuthApi.loginFacecheckEnrollment(parameters, _this.loginToken)
          : AuthApi.authorizeFacecheckEnrollment(parameters);
        return promiseTimeout(response, 60000, _this.loginToken);
      })
      .then((data) => {
        trackEvent(EVENTS.LOGIN.FACECHECK_FACESCAN_COMPLETED, {
          success: true,
          sdk_status: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
            FaceTecSDK.getStatus()
          )
        })
        _this.latestSessionResult = data;
        FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
          "Identity\r\nConfirmed"
        );
        faceScanResultCallback.succeed(data);
        _this.isSuccess = true;
      })
      .catch((error) => {
        if (
          error?.response?.data?.errors?.length > 0 &&
          (error.response.data.errors[0].errorCode ===
            ERROR_CODES.FACECHECK.FACE_MISMATCH ||
            error.response.data.errors[0].errorCode ===
            ERROR_CODES.FACECHECK.ENROLL_FAILED)
        ) {
          trackEvent(EVENTS.LOGIN.FACECHECK_FACESCAN_COMPLETED, {
            success: false,
            failure_reason: error.response.data.errors[0].errorMessage,
            sdk_status: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(
              FaceTecSDK.getStatus()
            )
          })
          faceScanResultCallback.retry();

        } else {
          _this.latestSessionResult = error;
          faceScanResultCallback.cancel();
        }

        appInsights.trackException({
          exception: new Error('Liveness Check Error'), severityLevel: SeverityLevel.Error, properties: {
            faceTecErrorMessage: error,
            faceTecVersion: FaceTecSDK.version(),
          }
        });
      });
  }
}
