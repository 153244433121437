import React from "react";
import PropTypes from "prop-types";
import { IconFont } from "../../Common/IconFont";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../../constants/iconfont";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockError = (props) =>
  props.children ? (
    <div
      className="flow-page-block__alert"
      id={props.id}
      data-cy={CyBuilder("page-block-alert", props.id)}
    >
      <IconFont icon={ICON_FONT_CLASS.ALERT} type={ICON_TYPE.ALERT} />
      <span className="flow-page-block__alert-message">{props.children}</span>
    </div>
  ) : null;

PageBlockError.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default PageBlockError;
