import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../../constants/localization";
import ActionRow from "../../../components/Layouts/ActionRow";
import InputWrapper from "../InputWrapper";
import MobilePINInput from "./MobilePINInput";
import Action from "../../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import InfoTip from "../../../components/Common/InfoTip";
import { ROUTES } from "../../../constants/clientRoutes";
import InputSkeletonGroup from "../../../components/Common/Loaders/InputSkeleton";
import ErrorProvider from "../../../components/ErrorProvider";
import ErrorService from "../../../services/errorService";

const CurrentPINMobile = (props) => {
  const handlePINInput = (pinInput) => {
    props.handleChange(pinInput);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit();
  };

  const handleForgotPINClick = () => {
    props.forgotPINEvent();
  };

  const togglePINHelpModalHandler = () => {
    props.togglePINHelpModal();
  };

  /**
   * Replaces the "contact support" text in the transfer lock message with a hyperlink.
   * @param {string} message - The raw transfer lock message
   */
  const formatTransferLockMessage = (message) => {
    let errorMessage = message.replace("contact support.", "");
    const contactLink = `<span><a class="support-link" href="${ROUTES.EXTERNAL.SUPPORT}">contact support.</a></span>`;
    return (
      <span
        dangerouslySetInnerHTML={{ __html: errorMessage.concat(contactLink) }}
      />
    );
  };

  /**
   * Returns an element to display error below PIN input element if the user is transfer locked.
   */
  const TransferLockError = () => {
    if (props.errors == null && props.isTransferLocked) {
      return (
        <div className="mobile-pin-inputs__error text-danger">
          {formatTransferLockMessage(COMMON.TRANSFER_LOCKED_OUT)}
        </div>
      );
    }
    return null;
  };

  const getErrorsFromArray = (errors) =>
    Array.apply(null, errors).map((error, index) => {
      return (
        <div key={index} className="mobile-pin-inputs__error text-danger">
          {error.errorMessage}
        </div>
      );
    });

  const InputError = ({ errors }) => {
    let screenErrors = ErrorService.GetScreenErrors(errors) || [];
    let fieldErrors = ErrorService.GetFieldErrors(errors) || [];
    if (screenErrors || fieldErrors)
      return getErrorsFromArray(screenErrors.concat(fieldErrors));
    return null;
  };

  return (
    <PageBlock>
      <PageBlock.Title>
        {PAGES.PIN_RESET.MOBILE_ENTER_PIN_HEADER}
        <InfoTip onClick={togglePINHelpModalHandler} />
      </PageBlock.Title>
      <PageBlock.Loader loadingContent={props.isTransferLocked == null}>
        <InputSkeletonGroup numInputs={1} />
      </PageBlock.Loader>
      <PageBlock.Body loadingContent={props.isTransferLocked == null}>
        <form id="EnterPINMobile" onSubmit={handleSubmit}>
          <InputWrapper>
            <MobilePINInput
              onPINUpdate={handlePINInput}
              currentPIN={props.currentPIN}
              hasError={props.errors != null}
              isTransferLocked={props.isTransferLocked}
            />
            <ErrorProvider
              render={(errors) => <InputError errors={errors} />}
            />
            <TransferLockError />
          </InputWrapper>
          <ActionRow>
            <ActionRow.Forward>
              <Action
                title="Next"
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
                disabled={
                  props.isTransferLocked ||
                  props.currentPIN == "" ||
                  props.currentPIN.length != 4
                }
                loading={props.loading}
              />
              <button
                type="button"
                onClick={handleForgotPINClick}
                className="input-help-link"
                to="/pinreset/forgot"
                disabled={props.isTransferLocked}
              >
                {PAGES.PIN_RESET.MOBILE_FORGOT_PIN_HELP_LINK_TEXT}
              </button>
            </ActionRow.Forward>
          </ActionRow>
        </form>
      </PageBlock.Body>
    </PageBlock>
  );
};

CurrentPINMobile.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  currentPIN: PropTypes.string,
  errors: PropTypes.array,
  togglePINHelpModal: PropTypes.func,
  forgotPINEvent: PropTypes.func,
  loading: PropTypes.bool,
  isTransferLocked: PropTypes.bool,
};

export default CurrentPINMobile;
