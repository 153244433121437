import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reduxForm, formValueSelector } from "redux-form";
import { COMMON } from "../../constants/localization";
import Action from "./Action";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../constants/enums";
import DynamicInputField from "./DynamicFields/DynamicInputField";

/**
 *
 */
const PinCheckForm = ({ handleSubmit, submitting, pin, error }) => {
  return (
    <form onSubmit={handleSubmit} autoComplete="off">
      <p>{COMMON.PIN_CHECK.PIN_PROMPT}</p>
      <div className="form-group">
        <DynamicInputField
          type={FIELD_TYPES.PIN}
          autoComplete="off"
          autoCorrect="off"
          spellCheck="off"
          id="pin"
          disabled={submitting}
          autoFocus={true}
        />
      </div>
      {error && <p className="validation-error text-left">{error}</p>}
      <div className="form-group">
        <Action
          displayType={ACTION_DISPLAY_TYPES.PRIMARY}
          title={COMMON.OK}
          type="submit"
          loading={submitting}
          disabled={!pin || pin.length < 4}
          className="spacing-top-small"
          block
        />
      </div>
    </form>
  );
};

PinCheckForm.propTypes = {
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  pin: PropTypes.string,
  error: PropTypes.string,
};

const selector = formValueSelector("pin-check");

let mapStateToProps = (state) => ({
  pin: selector(state, "pin"),
});

export default reduxForm({
  form: "pin-check",
  destroyOnUnmount: true,
  keepDirtyOnReinitialize: false,
})(connect(mapStateToProps)(PinCheckForm));
