import React, { useState } from "react";
import { useSelector } from "react-redux";
import CommunicationPreferencesForm from "../../components/Enrollment/Forms/CommunicationPreferencesForm";
import SpinningLoader from "../../components/Common/SpinningLoader";
import SessionEndModal from "../../components/Enrollment/SessionEndModal";
import PagePathConstants from "../../constants/page-numbers";

const CommunicationPreferences = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  if (currentPage !== PagePathConstants.COMMUNITCAION_PREFERENCES) {
    return null;
  }

  return (
    <div className="">
      <SpinningLoader show={showSpinner} />
      <CommunicationPreferencesForm
        setShowSpinner={setShowSpinner}
      />
      <SessionEndModal />
    </div>
  );
};

CommunicationPreferences.displayName = "CommunicationPreferencesComponent";

export default CommunicationPreferences;
