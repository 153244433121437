import { ErrorObj } from "../types/errors";

export const formatWithDecimals = (value: number, currencyCode: string) => {
  const numberFormat = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: currencyCode });

  const parts = numberFormat.formatToParts(value);

  let valueString = '';

  parts.forEach(part => {
    if (part.type === 'integer') {
      valueString += part.value;
    } else if (part.type === "group") {
      valueString += part.value;
    } else if (part.type === 'decimal') {
      valueString += part.value;
    } else if (part.type === 'fraction') {
      valueString += part.value;
    }
  })

  return `${valueString}`;
}

export const getCurrencyString = (currencyCode: string) => {
  const numberFormat = new Intl.NumberFormat(navigator.language, { style: 'currency', currency: currencyCode, currencyDisplay: "name" });
  const parts = numberFormat.formatToParts(10);
  const currency = parts.find(part => part.type === 'currency').value;

  return `${currency} (${currencyCode})`;
}

export const getExchangeRateString = (transferAmount: number, currencyCode: string, exchangeRate: number, destinationCurrency: string) => {
  const cardString = `${formatWithDecimals(transferAmount, currencyCode)} ${currencyCode}`;
  const destinationString = `${formatWithDecimals(exchangeRate, destinationCurrency)} ${destinationCurrency}`;

  return `${cardString} = ${destinationString}`;
}

export const overZero = (value) => {
  if (value < 0) {
    return 0;
  } else {
    return value;
  }
};

/**
* Receiver bank account fields come to us in a nested object.
* This function flattens the object to a single level to send to the api.
* E.g. { receiver: bank_account: { account_number: 1234 }} becomes { receiver.bank_account.account_number: 1234 }
*/
export const flattenObject = (ob) => {
  const toReturn = {};

  for (let i in ob) {
    if (i === "senderPhone") {
      toReturn[i] = ob[i];
      continue;
    }

    if (!ob.hasOwnProperty(i)) continue;

    typeCheckForFlattenObject(i, ob, toReturn);
  }

  return toReturn;
}

const typeCheckForFlattenObject = (i: any, ob: any, toReturn: any) => {
  if ((typeof ob[i]) == 'object' && ob[i] !== null) {
    let flatObject = flattenObject(ob[i]);
    for (let x in flatObject) {
      if (!flatObject.hasOwnProperty(x)) continue;

      toReturn[i + '.' + x] = flatObject[x];
    }
  } else {
    toReturn[i] = ob[i];
  }
}

export const mapErrorsToFields = (errorsArr: ErrorObj[]) => {
  let errors = {};
  for (let error of errorsArr) {
    const fieldId = toCamelCase(error.errorCode);

    if (fieldId) {
      errors[fieldId] = error.errorMessage;
    }
  }

  return errors;
};

// Function to convert into camel Case
export const toCamelCase = (str: string) => {
  // Using replace method with regEx
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
    return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}

// Function to rename key in an object
export const renameKey = (obj: any, oldKey: any, newKey: any) => {
  const newObj = { ...obj };
  newObj[newKey] = newObj[oldKey];
  delete newObj[oldKey];
  return newObj;
};

export const generateUniqueInteger = () => {
  let timestamp = Date.now();
  timestamp += Math.floor(Math.random() * 10000);

  return timestamp;
}