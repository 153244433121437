import { FIELD_TYPES } from "../constants/enums";
import { ASCII } from "../constants/forms";
import UserInput from "../components/Common/UserInputs/Input";
import ResponsiveDate from "../components/Common/UserInputs/ResponsiveDate";
import React from "react";

class InputService {
  static HandlePatternInput(pattern, input, inputRef, value) {
    /**
     * Returns an array of the valid indices in which
     * a character can be placed
     */
    let GetValidIndices = () => {
      if (pattern) {
        let validInputIndices = [];
        let patternSplit = pattern.split("");
        patternSplit.map((char, index) => {
          if (char == "x") {
            validInputIndices.push(index);
          }
        });
        return validInputIndices;
      }
    };

    /**
     * Splits value into an aray of characters
     */
    let GetInputValueAsArray = () => {
      let correctValue = value.length ? value : pattern;
      return correctValue.split("");
    };

    /**
     * Determines if backspace was pressed
     */
    let BackspacePressed = () => {
      /// Input length is always 1 when the first character
      /// is entered, since the pattern hasn't been entered yet
      /// After the first character is entered the the input value
      /// is always the pattern length + 1 during the onChange event
      /// If the input is ever less than pattern length + 1 but greater
      /// than 1 then we know a character was removed - backspace
      /// was pressed
      return input.length != 1 && input.length < pattern.length;
    };

    /**
     * Gets the cursor's position before character was entered
     * or deleted
     */
    let GetCursorStartPosition = () => {
      /// onChange event stores the value of the cursor after
      /// keypress. Meaning it's off by 1 for what we need.
      /// If the input length is 1 (meaning it's the first
      /// character input) then set the pre-keypress value to
      /// 0. Otherwise get the cursor position and subtract 1
      let cursorStart = input.length == 1 ? 0 : inputRef.selectionStart - 1;

      if (BackspacePressed()) {
        cursorStart += 1;
      }

      return cursorStart;
    };

    let cursorStart = GetCursorStartPosition();
    let backspacePressed = BackspacePressed();
    let validInputIndices = GetValidIndices();

    /**
     * Determines the new input value
     */
    let GetNewInputValue = () => {
      let valueSplit = GetInputValueAsArray();
      let inputCharacter = backspacePressed
        ? "x"
        : input.substring(cursorStart, cursorStart + 1);
      if (input.length == 1) {
        valueSplit[validInputIndices[0]] = inputCharacter;
        value = valueSplit.join("");
      } else if (validInputIndices.indexOf(cursorStart) != -1) {
        valueSplit[cursorStart] = inputCharacter;
        value = valueSplit.join("");
      }
      if (value == pattern) {
        value = "";
      }

      return value;
    };

    /**
     * Sets cursor to next valid character in input
     */
    let SetCursorIndexToNextX = (
      cursorStart,
      backspacePressed,
      inputRef,
      validInputIndices
    ) => {
      let nextX = backspacePressed
        ? cursorStart
        : validInputIndices.find((number) => number > cursorStart);

      if (nextX != undefined) {
        inputRef.setSelectionRange(nextX, nextX);
      }
    };

    return {
      value: GetNewInputValue(),
      setCursor: () => {
        SetCursorIndexToNextX(
          cursorStart,
          backspacePressed,
          inputRef,
          validInputIndices
        );
      },
    };
  }

  static GetInputType(datatype, pattern) {
    /// We're assuming that it's used
    /// for numbers only right now
    if (pattern) {
      return "tel";
    }
    /// Tel is returned to help force mobile
    /// keyboard to only show numbers
    /// This isn't the best symantically but
    /// it is the most consistent way since
    /// not all browsers support inputmode='numeric'
    switch (datatype) {
      case FIELD_TYPES.INTEGER:
      case FIELD_TYPES.DECIMAL:
      case FIELD_TYPES.PHONE_NUMBER:
      case FIELD_TYPES.PIN:
        return "tel";
      case FIELD_TYPES.CASCADING_SELECT:
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.COUNTRY:
        return "select";
      case FIELD_TYPES.DATE:
        return "date";
      case FIELD_TYPES.BOOL:
        return "checkbox";
      case FIELD_TYPES.TEXT:
      default:
        return "text";
    }
  }

  static GetInputMode(datatype) {
    switch (datatype) {
      case FIELD_TYPES.INTEGER:
        return "numeric";
      case FIELD_TYPES.DECIMAL:
        return "decimal";
      case FIELD_TYPES.CASCADING_SELECT:
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.COUNTRY:
        return "select";
      case FIELD_TYPES.PHONE_NUMBER:
        return "tel";
      case FIELD_TYPES.DATE:
        return "text";
      case FIELD_TYPES.BOOL:
        return "bool";
      case FIELD_TYPES.PIN:
        return "numeric";
      case FIELD_TYPES.TEXT:
      default:
        return "text";
    }
  }

  static GetInputTypeAndMode(datatype, pattern) {
    return {
      type: this.GetInputType(datatype, pattern),
      inputMode: this.GetInputMode(datatype),
    };
  }

  static HandleKeyPress(datatype) {
    switch (datatype) {
      case FIELD_TYPES.INTEGER:
      case FIELD_TYPES.PHONE_NUMBER:
      case FIELD_TYPES.DATE:
      case FIELD_TYPES.PIN:
        return this.HandleNumericKeyPress;
      default:
        return null;
    }
  }

  static HandleMaxLengthKeypress(e) {
    const maxLength = e.target.maxLength;
    const inputLength = e.target.value.length;
    if (maxLength && inputLength == maxLength) {
      e.preventDefault();
      return false;
    }
  }

  static HandleNumericKeyPress(e) {
    const ACCEPTED_CHARACTERS = [
      ASCII.ONE,
      ASCII.TWO,
      ASCII.THREE,
      ASCII.FOUR,
      ASCII.FIVE,
      ASCII.SIX,
      ASCII.SEVEN,
      ASCII.EIGHT,
      ASCII.NINE,
      ASCII.ZERO,
      ASCII.RETURN,
    ];

    if (ACCEPTED_CHARACTERS.indexOf(e.charCode) < 0) {
      e.preventDefault();
      return false;
    }
  }

  static GetInput(datatype) {
    switch (datatype) {
      case FIELD_TYPES.INTEGER:
      case FIELD_TYPES.DECIMAL:
      case FIELD_TYPES.PHONE_NUMBER:
      case FIELD_TYPES.PIN:
        return <UserInput />;
      case FIELD_TYPES.DATE:
        return <ResponsiveDate />;
      case FIELD_TYPES.TEXT:
      default:
        return <UserInput />;
    }
  }
}

export default InputService;
