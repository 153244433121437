import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function availableCountries(
  state = initialState.moneyTransfer.availableCountries,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES: {
      let countries = [];
      try {
        countries = action.availableCountries.slice();
      } catch (err) {
        // If there is an error retrieving available countries we don't need to inform the user because
        // they should be prevented from entering a money transfer flow.
        // The actual error error at the API level should be logged API side.
        countries = [];
      }

      state = countries;
      return countries;
    }
    default:
      return state;
  }
}
