import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import FlowButton from "../../components/Buttons/FlowButton";
import { PAGES, COMMON } from "../../constants/localization";
import ActionRow from "../../components/Layouts/ActionRow";
import ValidationCheckmark from "../../components/Common/ValidationCheckmark";
import { ERROR_TYPE, PASSWORD_RULE_STATUS } from "../../constants/enums";
import ErrorProvider from "../../components/ErrorProvider";
import ErrorService from "../../services/errorService";
import PasswordInput from "../../components/Common/UserInputs/PasswordInput";
import BaseFormControl from "../../components/Common/UserInputs/BaseFormControl";
import GenericErrorModal from "../../components/Common/Modals/GenericErrorModal";
import ENV from "../../constants/environment";
import ActionLink from "../../components/Common/ActionLink";
import { ERROR_CODES } from "../../constants/errorCodes";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import _ from "lodash";

class NewPasswordEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPassword: "",
      passwordInput1: "",
      passwordInput2: "",
      rulesPassed: [],
      formSubmitted: false,
      showGenericErrorModal: false,
    };
  }

  /**
   * Shows or hides the generic error modal.
   */
  toggleGenericErrorModal = () => {
    let { showGenericErrorModal } = this.state;
    this.setState({
      showGenericErrorModal: !showGenericErrorModal,
    });
  };

  componentDidMount() {
    {
      this.props.onRef && this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    {
      this.props.onRef && this.props.onRef(undefined);
    }
  }

  /**
   * Invoked to clear the controlled input fields after success
   * response from parent component ChangeCurrentPasswordContainer
   */
  clearFieldValues = () => {
    this.setState({
      currentPassword: "",
      passwordInput1: "",
      passwordInput2: "",
    });
  };

  /**
   * Listens for property changes and updates accordingly.
   * @param {*} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      prevProps.changePasswordResponse !== this.props.changePasswordResponse
    ) {
      this.handleChangePasswordError();
    }
  }

  /**
   * Submits the user's chosen password to API.
   * Validation should be completed before caling this method.
   */
  submitNewPassword = async () => {
    trackEvent(EVENTS.USER_PROFILE.PROFILE_PASSWORD_SAVE);
    this.setState({
      loading: true,
    });
    const { userId, token } = this.props.sessionInfo;

    if (this.props.showCurrentPwdInput) {
      let currentPassword = this.state.currentPassword;
      let newPassword = this.state.passwordInput1;
      this.props.resetUserPassword(token, currentPassword, newPassword);
    } else {
      let password = this.state.passwordInput1;
      this.props.resetUserPassword(userId, token, password);
    }
  };

  /**
   * Validates password fields match. If no match, an error
   * is displayed in the password confirmation field.
   */
  validateConfirm = () => {
    let output = false;
    const { passwordInput1, passwordInput2 } = this.state;
    if (passwordInput1 == passwordInput2) {
      this.props.clearError();
      output = true;
    } else {
      output = false;
      this.props.addError([
        {
          errorType: ERROR_TYPE.Field,
          errorMessage: PAGES.FORGOT_PASSWORD.PASSWORDS_MUST_MATCH,
          fieldId: "PasswordInput2",
        },
      ]);
    }
    return output;
  };

  /**
   * Validates that the password fields match then submits to the server.
   */
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validateConfirm()) {
      this.submitNewPassword();
    }
  };

  /**
   * Returns a green icon if the validation passes,
   * a red icon if the password submission failed on the server,
   * and a neutral icon if the submission has not occured and the validation does not pass.
   */
  checkIfRulePassed = (index) => {
    if (this.state.newPasswordSubmissionFailed) {
      return this.state.rulesPassed.indexOf(index) != -1
        ? PASSWORD_RULE_STATUS.PASSED
        : PASSWORD_RULE_STATUS.FAILED;
    } else {
      return this.state.rulesPassed.indexOf(index) != -1
        ? PASSWORD_RULE_STATUS.PASSED
        : PASSWORD_RULE_STATUS.NEUTRAL;
    }
  };

  /**
   * Checks that the password satisfies each of the password validation rules
   */
  validatePasswordInput1 = (e) => {
    const { passwordValidationRules } = this.props.sessionInfo;
    const passwordInput1 = e.target.value;
    const rulesPassed = [];
    this.props.clearError();
    passwordValidationRules.filter(({ regex }, index) => {
      let ruleRegex = new RegExp(regex);
      if (ruleRegex.test(passwordInput1)) {
        rulesPassed.push(index);
        return true;
      }
    });

    this.setState({
      rulesPassed,
      passwordInput1,
    });
  };

  /**
   * Maps the validation rules returned from the session
   * info into an array using the displayText property.
   */
  parseValidationRules = (validationRuleProperty) => {
    const { passwordValidationRules } = this.props.sessionInfo;
    if (passwordValidationRules) {
      return Array.apply(null, passwordValidationRules).map(
        (passwordValidationRule) =>
          passwordValidationRule[validationRuleProperty]
      );
    }
  };

  /**
   * Sets this.state.currentPassword
   */
  setCurrentPassword = (e) => {
    this.setState({
      currentPassword: e.target.value,
    });
  };

  /**
   * Sets this.state.passwordInput2
   */
  setPasswordInput2 = (e) => {
    this.setState({
      passwordInput2: e.target.value,
    });
  };

  /**
   * Returns true if the submit button should be disabled.
   * The rules are:
   *      One or both new password input fields are empty.
   *      Current Password input field is shown and empty.
   *      All validations have not passed.
   */
  isDisabled = () => {
    const { passwordValidationRules } = this.props.sessionInfo;
    return (
      !this.state.passwordInput1 ||
      !this.state.passwordInput2 ||
      (this.props.showCurrentPwdInput && !this.state.currentPassword) ||
      this.state.rulesPassed.length !== (passwordValidationRules || []).length
    );
  };

  /**
   * Finds the error that corresponds to the given field id then formats the error as HTML.
   * @param {string} errors - The array of password validation errors.
   * @param {string} fieldId - The identifier of the field that the error applies to.
   */
  renderFieldError = (errors, fieldId) => {
    let fieldErrors = ErrorService.GetFieldErrors(errors);
    if (!fieldErrors) return null;
    return Array.apply(
      null,
      fieldErrors
        .filter((error) => error.fieldId == fieldId)
        .map(({ errorMessage }) => (
          <div key={_.uniqueId()} className="validation-error">
            {errorMessage}
          </div>
        ))
    );
  };

  /**
   * Creates HTML divs with the password validation rules.
   * @param {string} validationRulesDisplayText - The array of password validation rules.
   */
  renderPasswordRules = (validationRulesDisplayText) => {
    if (!validationRulesDisplayText) return null;
    return Array.apply(null, validationRulesDisplayText).map((rule, index) => (
      <div key={_.uniqueId()} className="password-rule-block__ruleInfo">
        <ValidationCheckmark ruleStatus={this.checkIfRulePassed(index)} />
        {rule}
      </div>
    ));
  };

  /**
   * Updates the state with errors that occured from the password submission.
   * Looks for and displays field errors if applicable and opens the generic
   * error modal if the error is a 500 error.
   */
  handleChangePasswordError() {
    let error =
      this.props.changePasswordResponse &&
      this.props.changePasswordResponse.error;
    if (error) {
      if (error.status && error.status != 500) {
        let {
          data: { errors },
        } = error;
        if (errors.length) {
          let fieldError = errors.find((error) => {
            return error.errorType == ERROR_TYPE.Field;
          });
          if (fieldError) {
            this.props.addError([
              {
                errorType: ERROR_TYPE.Field,
                errorMessage: fieldError.errorMessage,
                fieldId:
                  fieldError.errorCode ==
                    ERROR_CODES.PASSWORD_RESET.INVALID_CURRENT_PASSWORD
                    ? "CurrentPasswordInput"
                    : "PasswordInput1",
              },
            ]);
          }
        }
        this.setState({
          newPasswordSubmissionFailed: true,
          loading: false,
        });
      } else {
        this.setState({
          showGenericErrorModal: true,
          loading: false,
        });
      }
    }
  }

  render() {
    const { loading } = this.props;
    const validationRulesDisplayText =
      this.parseValidationRules("displayText") || [];
    return (
      <React.Fragment>
        <PageBlock id="PasswordEntry">
          <PageBlock.Title>
            {!this.props.showCurrentPwdInput
              ? PAGES.FORGOT_PASSWORD.NEW_PASSWORD_ENTRY.PAGE_BLOCK_TITLE_1
              : ""}
          </PageBlock.Title>
          <PageBlock.Body>
            <form onSubmit={this.handleSubmit}>
              <div className="row">
                <div
                  className={
                    (!this.props.showCurrentPwdInput
                      ? "col-md-8"
                      : "col-md-10") + " col-sm-12"
                  }
                >
                  {this.props.showCurrentPwdInput && (
                    <div>
                      <BaseFormControl
                        inputId="CurrentPasswordInput"
                        className="password-input_input2"
                      >
                        <label htmlFor="CurrentPasswordInput">
                          {PAGES.CHANGE_PASSWORD.CURRENT_PASSWORD_LABEL_TEXT}
                        </label>
                        <PasswordInput
                          id="CurrentPasswordInput"
                          data-cy="CurrentPasswordInput"
                          title={validationRulesDisplayText}
                          onChange={this.setCurrentPassword}
                          value={this.state.currentPassword}
                          autoComplete="new-password"
                        />
                        <div className="help-block">
                          <ErrorProvider
                            renderElement
                            render={(errors) =>
                              this.renderFieldError(
                                errors,
                                "CurrentPasswordInput"
                              )
                            }
                          />
                        </div>
                      </BaseFormControl>
                      <hr />
                    </div>
                  )}
                  <BaseFormControl
                    inputId="PasswordInput1"
                    className="password-input_input1"
                  >
                    <label htmlFor="PasswordInput1">
                      {
                        PAGES.FORGOT_PASSWORD.NEW_PASSWORD_ENTRY
                          .NEW_PASSWORD_LABEL_TEXT
                      }
                    </label>
                    <PasswordInput
                      id="PasswordInput1"
                      data-cy="PasswordInput1"
                      onChange={this.validatePasswordInput1}
                      title={validationRulesDisplayText}
                      value={this.state.passwordInput1}
                      autoComplete="new-password"
                    />
                    <div className="help-block">
                      <ErrorProvider
                        renderElement
                        render={(errors) =>
                          this.renderFieldError(errors, "PasswordInput1")
                        }
                      />
                    </div>
                  </BaseFormControl>
                  {validationRulesDisplayText.length > 0 && (
                    <div className="password-rule-block">
                      <div className="password-rule-block__heading">
                        Must have:
                      </div>
                      {this.renderPasswordRules(validationRulesDisplayText)}
                    </div>
                  )}
                  <BaseFormControl
                    inputId="PasswordInput2"
                    className="password-input_input2"
                  >
                    <label htmlFor="PasswordInput2">
                      {
                        PAGES.FORGOT_PASSWORD.NEW_PASSWORD_ENTRY
                          .CONFIRM_PASSWORD_LABEL_TEXT
                      }
                    </label>
                    <PasswordInput
                      id="PasswordInput2"
                      data-cy="PasswordInput2"
                      onBlur={this.validateConfirm}
                      onChange={this.setPasswordInput2}
                      value={this.state.passwordInput2}
                      title={validationRulesDisplayText}
                      autoComplete="new-password"
                    />
                    <div className="help-block">
                      <ErrorProvider
                        render={(errors) =>
                          this.renderFieldError(errors, "PasswordInput2")
                        }
                      />
                    </div>
                  </BaseFormControl>
                </div>
              </div>
              <ActionRow>
                <ActionRow.Forward>
                  <FlowButton
                    type="submit"
                    title={
                      this.props.showCurrentPwdInput
                        ? COMMON.SAVE
                        : COMMON.SUBMIT
                    }
                    disabled={this.isDisabled()}
                    loading={loading}
                  />
                </ActionRow.Forward>
                {!this.props.showCurrentPwdInput && (
                  <ActionRow.Backward>
                    <ActionLink
                      href={ENV.BASE_NAVIGATOR_URL}
                      dataCy="new-password-entry-cancel-button"
                      text={COMMON.CANCEL}
                    />
                  </ActionRow.Backward>
                )}
              </ActionRow>
            </form>
          </PageBlock.Body>
        </PageBlock>
        <GenericErrorModal
          onClose={this.toggleGenericErrorModal}
          open={this.state.showGenericErrorModal}
        />
      </React.Fragment>
    );
  }
}

NewPasswordEntry.propTypes = {
  loading: PropTypes.bool,
  showCurrentPwdInput: PropTypes.bool,
  addError: PropTypes.func,
  clearError: PropTypes.func,
  sessionInfo: PropTypes.object,
  resetUserPassword: PropTypes.func,
  changePasswordResponse: PropTypes.object,
  onRef: PropTypes.func,
};

export default NewPasswordEntry;
