import React from 'react';
import PropTypes from "prop-types";
import Icon from '../Icon';

const Alert = ({ alertClasses, message, isHTML, icon, iconClasses, children }) => {
  return (
    <div className={`alert ${alertClasses}`}>
      {icon &&
        <div className="icon-wrapper">
          <Icon
            icon={icon}
            className={iconClasses}
          />
        </div>
      }

      <div className="text-wrapper">
        {(message && isHTML) &&
          <p dangerouslySetInnerHTML={{ __html: message }} className="message"></p>
        }

        {(message && !isHTML) &&
          <p className="message">{message}</p>
        }

        {children}
      </div>
    </div>
  );
}

Alert.propTypes = {
  /** CSS classes to be added to parent element */
  alertClasses: PropTypes.string,
  /** Alert message, can be plain text or HTML */
  message: PropTypes.string,
  /** determines if HTML to use dangerouslySetInnerHTML attribute */
  isHTML: PropTypes.bool,
  /** ICONS property in enums constant */
  icon: PropTypes.number,
  /** CSS classes to be applied to icon element */
  iconClasses: PropTypes.string,
  children: PropTypes.any
};

export default Alert;