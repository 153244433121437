import React from "react";
import PropTypes from "prop-types";

/// This component is temporary
const style = {
  display: "inline-block",
};

const InputLabel = (props) => {
  return (
    <label
      htmlFor={props.labelFor}
      style={style}
      className={`input-label ${props.hidden ? "sr-only" : ""}`}
    >
      {props.children}
    </label>
  );
};

InputLabel.propTypes = {
  hidden: PropTypes.bool,
  children: PropTypes.node,
  labelFor: PropTypes.string,
};

/// This component is temporary
const InputWrapper = (props) => {
  return (
    <div
      className={`form-group input-wrapper ${props.className != null ? props.className : ""
        }`}
    >
      {props.children}
    </div>
  );
};

InputWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

InputWrapper.Label = InputLabel;

export default InputWrapper;
