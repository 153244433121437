import React from "react";
import PropTypes from "prop-types";
import alertIcon from "../../assets/images/icons/Alert.svg";
import Action from "./Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";

const renderAction = (action) => {
  return (
    <Action
      key={action.title}
      onClick={(e) => {
        action.onClick(e, action);
      }}
      dataCy={action.title.toLowerCase().split(" ").join("")}
      title={action.title}
      displayType={action.displayType}
      loading={action.loading}
      disabled={action.disabled}
      autoFocus={action.focused}
      className={action.className}
    />
  );
};

const renderBlockFooter = (actions) => {
  if (actions && actions.length > 0) {
    /* Buttons need to be reversed order in mobile view,
    if we support only browsers with flexbox this would be a lot cleaner */
    return (
      <div className="text-right message-block-footer">
        <div className="hidden-xs">
          {actions.map((action) => {
            return renderAction(action);
          })}
        </div>
        <div className="visible-xs">
          {actions
            .slice()
            .reverse()
            .map((action) => {
              return renderAction(action);
            })}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

/**
 * Helper to create a message block with action buttons
 * for dashboard and contact support
 */
export const ContactSupportMessageBlock = ({ message }) => {
  const actions = [
    {
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
      title: COMMON.BACK_TO_DASHBOARD,
      onClick: () => (location.href = ROUTES.EXTERNAL.DASHBOARD),
    },
    {
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      title: COMMON.CONTACT_SUPPORT,
      onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
    },
  ];

  return (
    <MessageBlock actions={actions}>
      <h3>
        <img src={alertIcon} />
        {COMMON.CONTACT_SUPPORT_TITLE}
      </h3>
      <p>{message}</p>
    </MessageBlock>
  );
};

ContactSupportMessageBlock.propTypes = {
  message: PropTypes.string.isRequired,
};

const MessageBlock = ({ children, actions }) => {
  return (
    <div className="v2 page-block md message-block">
      <div className="text-left spacing-top-small spacing-bottom-medium">
        {children}
      </div>
      {renderBlockFooter(actions)}
    </div>
  );
};

MessageBlock.propTypes = {
  children: PropTypes.node,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      displayType: PropTypes.number,
      onClick: PropTypes.func,
      actionType: PropTypes.number,
      focused: PropTypes.bool,
      loading: PropTypes.bool,
      disabled: PropTypes.bool,
      className: PropTypes.string,
    })
  ),
};

export default MessageBlock;
