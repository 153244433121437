import { APP_STATE, REQUEST_STATUS } from "../constants/enums";

const initialState = {
  global: {
    appState: APP_STATE.Initializing,
    returnUrl: null,
    browserSupported: true,
    externalLinksModal: {
      open: false,
      url: null
    },
    errorModal: {
      open: false,
      errorMessage: ""
    }
  },
  auth: {
    username: null,
    authResult: {
      token: null,
      type: null,
      dataType: null,
      questionId: null,
      question: null,
      message: {
        type: null,
      },
      showMessage: false,
      needsToAcceptDocs: false,
      directive: {
        directiveType: null,
      },
    },
    support: {
      title: null,
      subtitle: null,
      ticketInfo: null,
      content: null,
      showCannotAccessEmail: false,
      category: null,
    },
    timeoutId: 0,
    autoStartFaceCheckSession: false,
  },
  errors: null,
  initialApiCallErrors: null,
  catalog: {
    countries: [],
    stateProvinces: [],
  },
  form: {},
  user: {
    isAuthenticated: null,
    featureFlags: [],
    profile: {
      validationErrors: [],
      errors: [],
    },
    pinCheck: {
      success: false,
      tooManyAttempts: false,
    },
    news: null
  },
  userSelections: {},
  htmlBuilders: {
    navBarLinks: [],
    footerLinks: [],
  },
  token: null,
  card: { status: REQUEST_STATUS.Loading, availability: {} },
  moneyTransfer: {
    providerRates: {
      providerRates: [],
      disclaimers: [],
      errors: [],
      rateQuery: {},
    },
    availableCountries: [],
    availableProvinces: [],
    cancelTransaction: {},
    availableCities: [],
    restrictions: [],
    sendLimit: 0.0,
    workflow: {
      pages: [],
      fieldCategories: [],
      fraudWarning: "",
      termsAndConditions: "",
      errors: [],
      quoteId: "",
      pageNumber: 0,
    },
    transferValidation: {},
    completedTransfer: {
      errors: [],
    },
    transferDetails: {},
  },
  support: {
    categories: [],
    conversations: [],
    activeConversation: 0,
    errors: null,
    phoneNumbers: [],
  },
  spinnersInProgress: 0,
  wageStatements: {
    list: [],
    error: null,
  },
  faceCheck: {
    forceEnrollment: false,
  },
  enrollment: {
    userData: null,
    currentPage: null
  },
  onDemandTransfer: {
    allocationId: null,
    destinationCurrency: null
  },
  bankAccounts: {
    showDeleteModal: false,
    bankAccountDeleted: false,
    disbursementHistory: {
      showDisbursementHistory: false,
    }
  },
  router: {
    action: "POP",
    location: {
      hash: "",
      pathname: "/",
      query: {},
      search: "",
      state: undefined,
    },
  },
};

export default initialState;
