import EnrollmentApi from "../../../api/enrollment";
import { PAGES } from "../../../constants/localization";

const userNameContainsInvalidChars = (username) => {
  return /[-!$%^&*()_+|~=`\\#}{[\]:";'<>?,@./ ]/.test(username);
};

const userNameContainsAlphabeticChar = (username) => {
  if (/[a-zA-Z]/.test(username)) {
    return true;
  }

  return false;
};

const isPasswordComplex = (password, passwordRules) => {
  const inputValue = password;
  let rulesPassed = [];
  let rulesNotPassed = [];

  if (passwordRules.length > 0) {
    passwordRules.filter((rules, index) => {
      let rule = new RegExp(rules.regex);

      if (rule.test(inputValue)) {
        rulesPassed.push(index);
      } else {
        rulesNotPassed.push(rules.displayText);
      }
    });

    if (rulesPassed.length === passwordRules.length) {
      return "success";
    } else {
      return rulesNotPassed[0];
    }
  }

  return null;
};

export const validate = (values) => {
  const errors = {};
  if (!values.userName) {
    errors.userName = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
  } else if (values.userName.length < 6) {
    errors.userName = PAGES.ENROLLMENT.CREATE_USER.ERROR_USERNAME_MIN_LENGTH;
  } else if (values.userName.length > 50) {
    errors.userName = PAGES.ENROLLMENT.CREATE_USER.ERROR_USERNAME_MAX_LENGTH;
  } else if (userNameContainsInvalidChars(values.userName)) {
    errors.userName = PAGES.ENROLLMENT.CREATE_USER.ERROR_USERNAME_INVALID_CHARS;
  } else if (!userNameContainsAlphabeticChar(values.userName)) {
    errors.userName =
      PAGES.ENROLLMENT.CREATE_USER.ERROR_USERNAME_MUST_CONTAIN_ALPHA;
  }

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword =
      PAGES.ENROLLMENT.CREATE_USER.ERROR_PASSWORDS_DO_NOT_MATCH;
  }

  if (!values.esignAgreement) {
    errors.esignAgreement = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
  }

  if (!values.disclosures) {
    errors.disclosures = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
  }

  return errors;
};

export const asyncValidate = async (values) => {
  return EnrollmentApi.GetPasswordRules().then((response) => {
    const returnValue = isPasswordComplex(values.password, response.rules);
    if (returnValue !== "success") {
      throw { password: returnValue };
    }
  });
};
