import React from "react";
import PropTypes from "prop-types";
import { FIELD_TYPES } from "../../constants/enums";
import { PAGES } from "../../constants/localization";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";

const SecurityQuestion = (props) => {
  return (
    <div>
      {props.showLabel && <label>Security Question</label>}
      <div className="security-question-text">{props.text}</div>
    </div>
  );
};

SecurityQuestion.defaultProps = {
  showLabel: true,
};

SecurityQuestion.propTypes = {
  text: PropTypes.string,
  showLabel: PropTypes.bool,
};

const AnswerInputField = (props) => {
  let fieldProps = {
    label: `${PAGES.SECURITY_QUESTIONS.ANSWER_LABEL}`,
    type: FIELD_TYPES[props.dataType.toUpperCase()],
    id: `securityQuestionAnswer`,
    valid: false,
    required: true,
    max: 30,
    className: "question-input form-group",
    isSensitive: true,
  };

  return <DynamicInputField {...fieldProps} />;
};

AnswerInputField.propTypes = {
  dataType: PropTypes.string,
};

const AnswerSecurityQuestion = (props) => {
  return (
    <div
      className="answer-security-question"
      data-cy="personal-security-question"
    >
      <SecurityQuestion text={props.question.question} />
      <AnswerInputField
        dataType={props.question.dataType}
        id={props.question.id}
      />
    </div>
  );
};

AnswerSecurityQuestion.propTypes = {
  question: PropTypes.object,
};

export default AnswerSecurityQuestion;
