import React from 'react';
import Icon from '../Common/Icon';
import { ICONS } from '../../constants/enums';

const PrintPage = () => {
  const handleClick = () => {
    window.print();
  }
  return (
    <button className="print no-styling" onClick={handleClick}>
      <Icon icon={ICONS.PRINT} />
      <p className="spacing-bottom-none spacing-left-tiny">Print this page</p>
    </button>
  );
}

export default PrintPage;