import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { COMMON, PAGES, PAGE_NAV_LINKS } from "../../constants/localization";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import UserApi from "../../api/user";
import ENV from "../../constants/environment";
import Icon from "../../components/Common/Icon";
import { ICONS } from "../../constants/enums";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import useClickExternalLink from "../../hooks/useClickExternalLink";
import i18n from "../../i18n/config";

/**
 * Main container for the disclosures page
 */
const Disclosures = () => {
  const openExternalLinksModal = useClickExternalLink();
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const getDocuments = async () => {
      try {
        const response = await UserApi.GetUserDocuments();

        setDocuments(response);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setError(true);
      }
    }

    getDocuments();
  }, [])

  const handleClick = (documentName, e, url) => {
    const formattedName = documentName.replace(/\s/g, '_').toLowerCase();
    trackEvent(EVENTS.USER_PROFILE.PROFILE_DISCLOSURES_VIEW, {
      [EVENTS.PARAMS.DISCLOSURES_NAME]: formattedName
    });

    openExternalLinksModal(e, url);
  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.SETTINGS.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        <FlowScreen
          flowTitle={PAGES.SETTINGS.DISCLOSURES.TITLE}
          className="security-questions-screen"
        >
          <PageBlock>
            <PageBlock.Loader
              loadingContent={loading}
            >
              <InputSkeletonGroup numInputs={6} />
            </PageBlock.Loader>
            <PageBlock.Body
              loadingContent={loading}
            >
              <ul className="document-list">
                {documents.map(document => {
                  const url = `${ENV.BASE_NAVIGATOR_URL}/Administration/getfile/${document.urlId}`;
                  return (
                    <li key={document.urlId} className="flex-row justify-content-between align-items-center padding-top-medium padding-bottom-medium border-bottom">
                      <p className="large spacing-bottom-none">{document.name}</p>
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="no-underline bold icon-small"
                        title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
                        onClick={(e) => handleClick(document.name, e, url)}
                      >
                        View
                        <Icon icon={ICONS.EXTERNAL_LINK_BLUE} />
                      </a>
                    </li>
                  )
                })}
              </ul>

              {error &&
                <p>{COMMON.ERROR}</p>
              }
            </PageBlock.Body>
          </PageBlock>
        </FlowScreen>
      </LayoutWithNav.Content>
    </LayoutWithNav>
  );
}

Disclosures.propTypes = {
  /** Is there an error to display */
  error: PropTypes.bool,
};

export default Disclosures;