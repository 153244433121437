import React from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import FileUploadField from "../Common/FileUploadField";
import attachmentIcon from "../../assets/images/Upload_Icon_Web.svg";
import sendMessageIcon from "../../assets/images/Send_Message_Icon_Web.svg";

/**
 * The form to submit a message and attachment to the conversation
 */
export class MessageForm extends React.Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    await this.props.onSubmit(values);
    this.props.change("message", "");
  }

  render() {
    const { isClosed, inputDisabled, attachFile, handleSubmit } = this.props;
    return (
      <form id="conversation-form" onSubmit={handleSubmit(this.onSubmit)}>
        <div className="flex-row align-items-center">
          <div className="file-upload spacing-right-medium">
            {!isClosed && (
              <Field
                name="attachment"
                type="file"
                onUploadFile={attachFile}
                disabled={inputDisabled}
                component={FileUploadField}
              />
            )}
            {isClosed && <img src={attachmentIcon} />}
          </div>
          <div className="support-message-input form-group">
            <Field
              name="message"
              component="input"
              placeholder={
                isClosed ? "This conversation has been closed." : "type message"
              }
              disabled={inputDisabled}
              className="form-control"
            />
          </div>
          <div className="send-message spacing-left-medium">
            <img
              className="btn-send"
              onClick={handleSubmit(this.onSubmit)}
              src={sendMessageIcon}
              alt="Send Message"
            />
          </div>
        </div>
      </form>
    );
  }
}

MessageForm.propTypes = {
  /** Is the conversation marked closed */
  isClosed: PropTypes.bool.isRequired,
  /** Disable input and attachment */
  inputDisabled: PropTypes.bool.isRequired,
  /** Callback for form submittal */
  onSubmit: PropTypes.func.isRequired,
  /** Callback on attaching a file */
  attachFile: PropTypes.func.isRequired,
  /** redux-form submission wrapper */
  handleSubmit: PropTypes.func.isRequired,
  /** redux-form change field */
  change: PropTypes.func.isRequired,
};

export default reduxForm({
  form: "conversation",
})(MessageForm);
