import { ICON_SVG } from "./iconsvg";

export const TRANSACTION_TYPES = {
  PURCHASE: 1,
  CREDIT: 2,
  WAGE: 3,
  TRANSFER: 4,
  WITHDRAWAL: 5,
  CASHPICKUP: 7,
  MONEY_TRANSFER: 8,
  PUSH_TO_CARD: 9,
  WU_TO_ACCOUNT: 10,
  MC_TO_ACCOUNT: 11,
};

export const TRANSACTION_SERVICE_TYPES = {
  TO_ACCOUNT: 7
}

export const CASH_PICKUP_NETWORK_TYPES = {
  UNKNOWN: 0,
  PICKUP_ANYWHERE: 1,
  WITHIN_NETWORK: 2,
  DIRECTED: 3,
  SUSPENDED: 4,
};

export const SELECTION_TYPES = {
  COUNTRIES: "COUNTRIES",
};

export const PASSPORT_STATUSES = {
  UNKNOWN: 0,
  EXPIRED: 1,
  INVALID_DATE: 2,
  VALID: 3,
};

export const USER_PROFILE_FIELDS = {
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  PHONE_NUMBER: "phoneNumber",
  PHONE: "phone",
  EMAIL: "email",
  COUNTRY: "country",
  COUNTRY_ISO: "countryIso",
  CITY: "city",
  POSTAL_CODE: "postalCode",
  PROVINCE: "province",
  STATE: "state",
  STATE_PROVINCE_ISO: "stateProvinceIso",
  STATE_PROVINCE_NAME: "stateProvinceName",
  LINE_1: "line1",
  LINE_2: "line2",
  ADDRESS_LINE_1: "addressLine1",
  ADDRESS_LINE_2: "addressLine2",
  ZIP_POSTAL: "zipPostal",
};

export const RATE_CARD_FIELDS = {
  TOTAL_COST: 1,
  TOTAL_RECEIVE_AMOUNT: 2,
  SEND_AMOUNT: 3,
  FEE: 4,
  PROMO_CODE: 7,
  FX_RATE: 30
};

export const SUCCESS_FIELDS = {
  TIME_UNTIL_AVAILABLE: 28,
  REFERENCE_NUMBER: 31,
  TRANSFER_DATE: 33,
};

export const ACTION_DISPLAY_TYPES = {
  PRIMARY: 1,
  SECONDARY: 2,
  TERTIARY: 3,
  LINK: 4,
};

export const ACTION_DISPLAY_TYPES_BY_NAME = {
  PRIMARY: "Primary",
  SECONDARY: "Secondary",
  TERTIARY: "Tertiary",
  LINK: "Link",
};

export const ACTION_TYPES = {
  CONFIRM: 1,
  DISMISS: 2,
  MODAL: 3,
};

export const ACTION_TYPES_BY_NAME = {
  CONTINUE: "Continue",
  DISMISS: "Dismiss",
  CHILD_ACTION: "ChildAction",
  BACK: "Back"
}

export const FIELD_TYPES = {
  PHONE_NUMBER: 1,
  CURRENCY: 2,
  BOOL: 3,
  DATE: 4,
  INTEGER: 5,
  DECIMAL: 6,
  SELECT: 7,
  COUNTRY: 8,
  EMAIL: 9,
  TEXT: 10,
  CASCADING_SELECT: 11,
  PIN: 12,
  TEXTAREA: 13,
  FILE: 14
};

export const FIELD_TYPES_BY_NAME = {
  PHONE_NUMBER: "PhoneNumber",
  CURRENCY: "Currency",
  BOOL: "Boolean",
  DATE: "Date",
  INTEGER: "Integer",
  DECIMAL: "Decimal",
  DROPDOWN: "Dropdown",
  COUNTRY: "Country",
  EMAIL: "Email",
  TEXT: "Text",
  SELECT: "Select",
  CASCADING_DROPDOWN: "CascadingDropdown",
  PIN: "Pin",
  TEXTAREA: "TextArea",
  HORIZONTAL_RULE: "HorizontalRule",
};

export const ICONS = {
  PERSONAL_INFO: 1,
  NEWS_DASHBOARD: 2,
  LOCATION: 3,
  CHEVRON_RIGHT: 4,
  DOWNLOAD: 5,
  PENCIL: 6,
  INFO: 7,
  CLOCK: 8,
  GREEN_CHECK: 9,
  WARNING_INFO: 10,
  INFO_GREY: 11,
  EXTERNAL_LINK_BLUE: 12,
  PRINT: 13,
  INFO_WHITE: 14,
  COPY_BLUE: 15
};

export const ICONS_ALT_TEXT = {
  1: "Personal Info",
  2: "News Dashboard",
  3: "Location",
  4: "Chevron Right",
  5: "Download",
  6: "Pencil",
  7: "Info",
  8: "Clock",
  9: "Green Check",
  10: "Warning Info",
  11: "Info Grey",
  12: "External Link",
  13: "Print",
  14: "Info"
}

export const FIELD_CATEGORIES = {
  SENDER: "Sender",
  RECEIVER: "Receiver",
  NETWORK_PAYER: "Network Payer",
  FRAUD_WARNING: "FraudWarning",
  BANK: "Bank"
};

export const MONEY_TRANSFER_STEPS = {
  COUNTRY_AND_AMOUNT: "COUNTRY_AND_AMOUNT",
  RATES: "RATES",
  NETWORK_PAYOUT_INFO: "NETWORK_PAYOUT_INFO",
  SENDER_INFO: "SENDER_INFO",
  RECEIVER_INFO: "RECEIVER_INFO",
  FRAUD_WARNING: "FRAUD_WARNING",
  REVIEW: "REVIEW",
  SUCCESS: "SUCCESS",
  RECEIPT: "RECEIPT"
};

export const RELATED_FIELDS = {
  PREDECESSOR: "PREDECESSOR",
  SUCCESSOR: "SUCCESSOR",
  GROUP_TARGET: "GROUP_TARGET",
  GROUP_SOURCE: "GROUP_SOURCE"
};

export const ACCEPTANCE_BEHAVIOR = {
  DO_NOT_SHOW: "DO_NOT_SHOW",
  SHOW_AND_DONT_REQUIRE: "SHOW_AND_DONT_REQUIRE",
  SHOW_AND_REQUIRE: "SHOW_AND_REQUIRE",
};

export const RESET_PIN_STEPS = {
  CURRENT_PIN: "CURRENT_PIN",
  FORGOT_PIN: "FORGOT_PIN",
  CHANGE_PIN: "CHANGE_PIN",
};

export const FORGOT_PASSWORD_STEPS = {
  ENTER_USERNAME: "ENTER_USERNAME",
  RESET_OPTION: "RESET_OPTION",
  PERSONAL_INFO_ENTRY: "PERSONAL_INFO_ENTRY",
  EMAIL_ENTRY: "EMAIL_ENTRY",
  EMAIL_SUCCESS: "EMAIL_SUCCESS",
  NEW_PASSWORD_ENTRY: "NEW_PASSWORD_ENTRY",
  PASSWORD_SUCCESS: "PASSWORD_SUCCESS",
};

export const INFO_MODAL_PICTURES = {
  2: ICON_SVG.LAST_SIX_DIGITS,
  3: ICON_SVG.EXP_DATE,
};

export const SERVICE_PROVIDER_FIELD_ID = {
  CARD_LAST_SIX: "cardLastSix",
  CARD_EXP_DATE: "cardExpirationDate",
  DATE_OF_BIRTH: "dateOfBirth",
};

export const PASSWORD_RULE_STATUS = {
  NEUTRAL: "neutral",
  PASSED: "passed",
  FAILED: "failed",
};

export const ERROR_TYPE = {
  None: 0,
  Field: 1,
  Screen: 2,
  OK: 3,
  ContactSupport: 4,
  Dashboard: 5,
  Login: 6,
  ContactSupportAndDashboard: 7,
  ContactSupportAndLogin: 8,
};

export const CARD_TYPE = {
  VISA: 1,
  MASTER_CARD: 2,
};

export const CREDIT_CARD_TYPE_ID = {
  1: 'Visa',
  2: 'Mastercard'
};

export const AUTH_INFO_TYPE = {
  Password: "Password",
  Authenticated: "Authenticated",
  SecurityQuestion: "SecurityQuestion",
  FaceCheck: "FaceCheckLogin",
  Totp: 'Totp'
};

export const DIRECTIVE_TYPE = {
  Dashboard: "Dashboard",
  FaceCheckEnrollment: "FaceCheckEnrollment",
};

export const APP_STATE = {
  Initializing: "Initializing",
  Ready: "Ready",
};

export const SUPPORT_CATEGORY = {
  ForgotSecurityQuestion: "forgot_security_question",
  Facecheck: "facecheck",
};

export const REQUEST_STATUS = {
  Loading: "Loading",
  Complete: "Complete",
};

export const IN_APP_MESSAGE_TYPE = {
  ACKNOWLEDGEMENT: "Acknowledgement",
  APP_UPDATE_REQUIRED: "AppUpdateForce",
  APP_UPDATE_OPTIONAL: "AppUpdateOptional",
  SECURITY_QUESTION_UPDATE_REQUIRED: "SecurityQuestionUpdateForce",
  SECURITY_QUESTION_UPDATE_OPTIONAL: "SecurityQuestionUpdateOptional",
  FACECHECK_ENROLLMENT: "FaceCheckEnrollment", //FaceCheck intro page
};

export const LOCAL_STORAGE = {
  Username: "username",
};

export const ENROLLMENT_COMMUNICATIONS_PREFERENCES = {
  CUSTOM_DROPDOWN: "99",
  OCEAN_PAY_NEWS: "opNews",
  OCEAN_PAY_CARD: "opCard",
  BANK_NOTIFICATIONS: "bankNotifs",
  US_ID: 228,
  FEATURE_FLAGS: {
    DISABLE_FACECHECK: "12099-disable-facecheck",
  },
};

export const TOTP_FLOW = {
  SCREENS: {
    HOW_IT_WORKS: 1,
    LINK_AUTHENTICATOR_APP: 2,
    VERIFY_AUTHENTICATOR_APP: 3,
    SUCCESS: 4
  }
}

export const SUPPORT_CONVERSATION_CATEGORY_TYPES = {
  BANK_ACCOUNT: 'bank_account',
  MY_CARD: 'my_card',
  TRANSACTION_QUESTION_DISPUTE: 'transaction_question/dispute',
  MONEY_TRANSFER: 'money_transfer',
  SOMETHING_ELSE: 'something_else'
}

export const LOCK_STATUS = {
  LOCKED_BY_SUPPORT: 'lockedBySupport',
  LOCKED_BY_USER: 'lockedByUser',
  UNLOCKED: 'unlocked'
}

export const CARD_DIRECTIVE_ROUTES = {
  SUPPORT: 'Support',
  MYCARD: 'MyCard/lock-card'
}

export const MONEY_TRANSFER_PAGES = {
  SENDER: "Sender",
  RECEIVER: "Receiver",
  PAYER_NETWORKS: "PayerNetworks",
  FRAUD_WARNING: "FraudWarning",
  DYNAMIC_FORM: "DynamicForm",
  STATIC_HTML: "StaticHtml"
}

export const MONEY_TRANSFER_FIELD_IDS = {
  ESTIMATED_ARRIVAL: 6,
  PROMO_CODE: 7,
  TRANSACTION_RECEIVER_NAME: 22,
  TRANSACTION_STATUS: 32,
  TRANSACTION_TIME: 53,
  PROVIDER_ADDRESS: 55,
  RECEIPT_TITLE: 56,
  RECEIPT_SUBTITLE: 57,
  RECEIPT_INFO_BOX: 58,
  TRACKING_NUMBER: 59,
  TRANSACTION_DATE: 60,
  BANK_NAME: 71
}

export const MONEY_TRANSFER_DIRECTIVE_TYPES = {
  EXTERNAL_LINK: "ExternalLink",
  MODAL: "Modal",
  CANCEL_MONEY_TRANSFER: "CancelMoneyTransfer",
  SUPPORT: "Support"
}

export const MONEY_TRANSFER_PROVIDER_IDS = {
  WESTERN_UNION: 7,
  MONEYGRAM: 5,
  TRANSFAST: 4
}

export const MONEY_TRANSFER_SUCCESS_TYPES = {
  RECEIPT: "Receipt",
  SUCCESS: "Success"
}

export const MONEY_TRANSFER_STATUS = {
  CANCELED: "Canceled",
  CANCELLED: "Cancelled"
}

export const MONEY_TRANSFER_PROVIDER_NAMES = {
  WESTERN_UNION: "WesternUnion",
  MONEYGRAM: "MoneyGram"
}

export const COUNTRY_IDS = {
  USA: 228
}

export const SUPPORT_DOC_IDS = {
  ISSUING_BANK_CARDHOLDER_AGREEMENT: 1,
  COMPANION_CARD_FEES: 12
}

export const COMPANION_CARD_FIELD_REFS = {
  CARDHOLDER_FIRST_NAME: "cardholderFirstNameRef",
  CARDHOLDER_LAST_NAME: "cardholderLastNameRef",
  SHIP_NAME: "shipNameRef",
  EMPLOYEE_ID: "employeeIdRef",
  CARDHOLDER_EMAIL: "emailRef",
  SECONDARY_FIRST_NAME: "secondaryFirstNameRef",
  SECONDARY_LAST_NAME: "secondaryLastNameRef",
  SECONDARY_EMAIL: "secondaryEmailRef",
  DATE_OF_BIRTH: "dateOfBirthRef",
  ADDRESS_LINE_1: "addressLine1Ref",
  ADDRESS_LINE_2: "addressLine2Ref",
  COUNTRY_ID: "countryIdRef",
  CITY: "cityRef",
  STATE_ID: "stateIdRef",
  PROVINCE: "provinceRef",
  ZIP_POSTAL_CODE: "zipPostalCodeRef",
  ID_FILE: "idFileRef",
  IDENTIFICATION_TYPE_ID: "identificationTypeIdRef",
  ISSUING_COUNTRY: "issuingCountryRef",
  ID_NUMBER: "idNumberRef",
}

export const DOCUMENT_TYPE_IDS = {
  WUBS_COUNTRIES_LINK: 22
}

export const MONEY_TRANSFER_RATE_CARD_FIELD_TYPES = {
  LABEL_VALUE: "LabelValue",
  MULTILINE_TEXT: "MultilineText"
}

export const BANK_ACCOUNT_STATUS = {
  AVAILABLE: 1,
  INCOMPLETE: 3,
  UNDER_REVIEW: 4,
  DECLINED: 5
}

export const ON_DEMAND_TRANSFER_PROVIDERS = {
  CONVERA_WUBS: 1,
  COREPAY_CAMBRIDGE: 3
}

export const FOOTER_DISCLAIMER_LINKS_IDS = {
  PRIVACY_STATEMENT: "PRIVACYSTATEMENT",
  TERMS_AND_CONDITIONS: "TERMSANDCONDITIONS",
  ESIGN: "ESIGN",
}

export const CARD_TRANSACTIONS = {
  NUM_TO_GET: 20
}

export const TRANSFER_METHODS = {
  PUSH_TO_CARD: "PUSH_TO_CARD",
  TO_ACCOUNT: "BANK_ACCOUNT"
}

export const BANK_ACCOUNT_CARD_TYPES = {
  ODT: "ODT",
  BANK_ACCOUNT: "BANK_ACCOUNT"
}

export const CURRENCY_CODES = {
  USD: "USD",
  EUR: "EUR",
  AUD: "AUD",
}

export const CURRENCY_NAMES = {
  USD: "US Dollar",
  EUR: "Euro",
  AUD: "Australian Dollar",
}