import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FaceTecSDK from "../../services/faceTecSDK";
import FacetecService from "../../services/facetec";
import { ROUTES } from "../../constants/clientRoutes";
import { ERROR_CODES } from "../../constants/errorCodes";
import { PAGES } from "../../constants/localization";
import {
  ACTION_DISPLAY_TYPES,
  SUPPORT_CATEGORY,
  DIRECTIVE_TYPE,
} from "../../constants/enums";
import alertIcon from "../../assets/images/icons/Alert.svg";
import {
  Logout,
  goToNextAuthStep,
  handleFaceCheckSuccess,
  getFacecheckEnrollmentToken,
  OpenAuthSupportTicket,
  setAuthSupportInfo,
  disableFaceCheckSessionAutoStart,
} from "../../actions/auth";
import ErrorBlock from "../../components/Common/ErrorBlock";
import MessageBlock from "../../components/Common/MessageBlock";
import ENV from "../../constants/environment";
import { eventTracker, trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import FaceCheckSupportModal from "../../components/Common/Modals/FaceCheckSupportModal";
import PageBlock from "../../components/Layouts/PageBlock";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import SuccessFaceCheck from "./SuccessFaceCheck";
import ErrorService from "../../services/errorService";
import { appInsights } from "../../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

/**
 * Handles sending face scan data to the API and rendering responses
 */
class FaceCheck extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "",
      loading: props.hasAuthenticated,
      error: null,
      errorCode: "",
      errorMessage: "",
      errorTitle: "",
      success: false,
      showSupportModal: false,
      loadingSubmitTicket: false,
      supportTicketError: null,
    };

    this.onStartClick = this.onStartClick.bind(this);
    this.openSupportModal = this.openSupportModal.bind(this);
    this.closeSupportModal = this.closeSupportModal.bind(this);
    this.goBackToLogin = this.goBackToLogin.bind(this);
    this.doSubmitTicket = this.doSubmitTicket.bind(this);
    this.renderInitialPage = this.renderInitialPage.bind(this);
    this.getInitTokens = this.getInitTokens.bind(this);
  }

  componentWillUnmount() {
    this._isMounted = false;
    // ugly hack to close whenever the screen goes away
    // specially for clicking the back button in the browser
    const closeFacetecButton = document.getElementById(
      "zoom-user-helper-cancel"
    );
    if (closeFacetecButton) {
      closeFacetecButton.click();
    }
  }

  componentDidMount() {
    const {
      enrollMode,
      livenessSessionToken,
      getFacecheckEnrollmentToken,
      autoStart,
    } = this.props;
    this._isMounted = true;

    trackEvent(EVENTS.LOGIN.FACECHECK_STARTED, {});
    if (enrollMode && !livenessSessionToken) {
      this.setState({ loading: true });
      // get facetec token in case they refreshed the page after auth complete
      getFacecheckEnrollmentToken()
        .catch((error) => {
          eventTracker.track("FaceCheck_Enrollment", {
            success: "false",
            error:
              error.status !== undefined
                ? error.status
                : "failure to get facecheck enrollment token",
          });

          this.setState({
            error: error,
            errorTitle: PAGES.FACECHECK.ERROR_TITLE_GET_TOKEN,
            errorMessage: PAGES.FACECHECK.ERROR_CONTENT,
          });
        })
        .then(() => {
          this.setState({ loading: false }, () => {
            if (autoStart) {
              this.onStartClick();
            }
          });
        });
    } else {
      if (autoStart) {
        this.onStartClick();
      }
    }
  }

  /**
   * Retrieves the initialization data for an authenticated user
   */
  getInitTokens() {
    this.props.getFacecheckEnrollmentToken();
    this.renderInitialPage();
  }

  /**
   * Safely update the state from events
   * outside the react lifecycle
   * @param {*} state
   */
  safeSetState(state) {
    if (this._isMounted) {
      this.setState(state);
    }
  }

  /**
   * Begins the ZoOm session to submit face scan data to the API
   */
  onStartClick() {
    const {
      history,
      handleFaceCheckSuccess,
      livenessSessionToken,
      deviceKey,
      licenseKey,
      loginToken,
      goToNextAuthStep,
      enrollMode,
      disableFaceCheckSessionAutoStart,
    } = this.props;

    disableFaceCheckSessionAutoStart();

    this.setState({
      loading: true,
      loadingSubmitTicket: false,
    });

    FacetecService.startFacetec({
      licenseKey,
      deviceKey,
      livenessSessionToken,
      loginToken,
      onStatusChange: (status) => {
        this.safeSetState({
          status: FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(status),
        });
      },
    })
      .then(async (data) => {
        eventTracker.track(this.props.mixpanelEvent, { success: "true" });

        return handleFaceCheckSuccess(data).then(() => {
          if (enrollMode) {
            this.safeSetState({
              success: true,
              loading: false,
            });
          } else {
            goToNextAuthStep(history);
          }
        });
      })
      .catch((error) => {
        appInsights.trackException({
          exception: new Error('Start FaceTec Error'), severityLevel: SeverityLevel.Error, properties: {
            faceTecErrorMessage: error,
            faceTecSessionId: livenessSessionToken,
            faceTecVersion: FaceTecSDK.version(),
            username: this.props.userData.username,
          },
        });
        eventTracker.track(this.props.mixpanelEvent, {
          success: "false",
          error: error.status !== undefined ? error.status : "unknown",
        });

        let resp = ErrorService.GetFaceCheckError(error);
        this.safeSetState({
          error: resp.error,
          errorCode: resp.code,
          errorTitle: resp.title,
          errorMessage: resp.message,
          loading: false,
        });
      });
  }

  renderSupportModal() {
    let title = "";
    if (this.state.errorTitle === PAGES.FACECHECK.ERROR_UPLOAD) {
      title = `${PAGES.FACECHECK.ERROR_UPLOAD}?`;
    } else if (this.state.error) {
      title = PAGES.FACECHECK.ERROR_TRY_AGAIN;
    } else {
      title = PAGES.FACECHECK.NO_WORKING_CAMERA;
    }

    return (
      <FaceCheckSupportModal
        open={this.state.showSupportModal}
        title={title}
        content={PAGES.FACECHECK.SUPPORT_MODAL_BODY}
        loading={this.state.loadingSubmitTicket}
        onClose={this.closeSupportModal}
        onSubmitClick={this.doSubmitTicket}
        cancelText={PAGES.FACECHECK.BACK_TO_FACECHECK}
        onCancelClick={this.closeSupportModal}
        errors={this.state.supportTicketError}
      />
    );
  }

  goBackToLogin() {
    if (this.props.enrollMode) {
      Logout();
      window.location = `${ENV.BASE_NAVIGATOR_URL}/${ROUTES.EXTERNAL.LOGOUT}`;
    } else {
      this.props.history.push(ROUTES.AUTH.LOGIN);
    }
  }

  openSupportModal() {
    eventTracker.track(`${this.props.mixpanelEvent}_Support`);
    this.setState({ showSupportModal: true });
  }

  closeSupportModal() {
    this.setState({ showSupportModal: false, supportTicketError: false });
  }

  doSubmitTicket() {
    const { OpenAuthSupportTicket, setAuthSupportInfo, history } = this.props;
    this.setState({ loadingSubmitTicket: true, supportTicketError: null });

    let description = "";
    if (this.state.errorTitle === PAGES.FACECHECK.ERROR_UPLOAD) {
      description = PAGES.FACECHECK.SUPPORT_REQUEST_ERROR_UPLOAD;
    } else if (this.state.error) {
      description = PAGES.FACECHECK.SUPPORT_REQUEST_FACESCAN;
    } else {
      description = PAGES.FACECHECK.SUPPORT_REQUEST_CAMERA;
    }

    OpenAuthSupportTicket(SUPPORT_CATEGORY.Facecheck, null, description)
      .then((data) => {
        if (data.supportTicketCreated) {
          eventTracker.track(`${this.props.mixpanelEvent}_Support`, {
            success: "true",
          });

          return setAuthSupportInfo({
            title: PAGES.AUTH.SUPPORT.FACECHECK_SUPPORT_TICKET,
            subtitle: PAGES.AUTH.SUPPORT.HIGH_PRIORITY_TITLE,
            ticketInfo: `${PAGES.AUTH.SUPPORT.TICKET_NO} ${data.ticketNumber}`,
            content: `<p>${PAGES.AUTH.SUPPORT.EMAIL_CONFIRMATION}</p><p><b>${data.email}</b></p>`,
            showCannotAccessEmail: true,
            category: SUPPORT_CATEGORY.Facecheck,
          });
        } else {
          eventTracker.track(`${this.props.mixpanelEvent}_Support`, {
            success: "false",
          });

          throw new Error(
            data.activeTicketInCategory
              ? PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED
              : PAGES.AUTH.SUPPORT.TICKET_NOT_CREATED
          );
        }
      })
      .then(() => history.push(ROUTES.AUTH.SUPPORT))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ supportTicketError: error.response.data.errors });
        } else {
          this.setState({
            supportTicketError: [error],
            loadingSubmitTicket: false,
          });
        }
      });
  }

  getActions() {
    const { loading } = this.state;
    const { enrollMode } = this.props;

    const actions = [
      {
        loading,
        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
        title: enrollMode
          ? PAGES.FACECHECK.SETUP_FACECHECK_START
          : PAGES.FACECHECK.LOGIN_FACECHECK_START,
        onClick: this.onStartClick,
        className: "btn-lg",
      },
    ];

    if (!loading) {
      actions.push({
        displayType: ACTION_DISPLAY_TYPES.LINK,
        title: PAGES.FACECHECK.DO_NOT_HAVE_CAMERA,
        onClick: this.openSupportModal,
        className: "btn-link btn-lg",
      });
    }

    return actions.reverse();
  }

  renderInitialPage() {
    this.setState({
      error: null,
    });
  }

  getErrorActions() {
    const { errorCode } = this.state;
    const { enrollMode } = this.props;

    let buttonTitle = PAGES.FACECHECK.ERROR_CONTACT_SUPPORT;
    let buttonAction = this.openSupportModal;
    let linkTitle = PAGES.FACECHECK.ERROR_BACK_TO_LOGIN;
    let linkAction = this.goBackToLogin;

    if (errorCode === ERROR_CODES.COMMON.TIMEOUT) {
      buttonTitle = PAGES.FACECHECK.ERROR_RETRY_ACTION;
      buttonAction = this.renderInitialPage;
    } else if (errorCode === ERROR_CODES.FACECHECK.TOO_MANY_ATTEMPTS) {
      buttonTitle = PAGES.FACECHECK.ERROR_BACK_TO_LOGIN;
      buttonAction = this.goBackToLogin;
      linkTitle = PAGES.FACECHECK.ERROR_CONTACT_SUPPORT;
      linkAction = this.openSupportModal;
    } else if (errorCode === ERROR_CODES.FACECHECK.GENERIC_SDK_ERROR) {
      buttonTitle = !enrollMode
        ? PAGES.FACECHECK.ERROR_BACK_TO_LOGIN
        : PAGES.FACECHECK.ERROR_RETRY_ACTION;
      buttonAction = !enrollMode ? this.goBackToLogin : this.getInitTokens;
      linkTitle = PAGES.FACECHECK.ERROR_CONTACT_SUPPORT;
      linkAction = this.openSupportModal;
    }

    return [
      {
        displayType: ACTION_DISPLAY_TYPES.LINK,
        title: linkTitle,
        onClick: linkAction,
        className: "btn-link btn-lg",
      },
      {
        loading: this.state.loadingSubmitTicket,
        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
        title: buttonTitle,
        onClick: buttonAction,
        className: "btn-lg",
      },
    ];
  }

  renderErrors(errors) {
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  render() {
    const { pageTitle, pageSubTitle, pageContent } = this.props;
    const { error, errorCode, errorMessage, errorTitle, loading, success } =
      this.state;
    const showErrorAlert =
      errorCode !== ERROR_CODES.FACECHECK.TOO_MANY_ATTEMPTS;

    return (
      <div>
        <div className="container-md">
          <div className="row">
            <div className="col-sm-12">
              <h1> {pageTitle} </h1>
            </div>
          </div>
          {loading && (
            <PageBlock>
              <PageBlock.Loader loadingContent={true}>
                <InputSkeletonGroup numInputs={1} />
              </PageBlock.Loader>
            </PageBlock>
          )}
          {!loading && !success && (
            <MessageBlock
              actions={error ? this.getErrorActions() : this.getActions()}
            >
              {error ? (
                <React.Fragment>
                  <h2>
                    {showErrorAlert && <img src={alertIcon} />} {errorTitle}
                  </h2>
                  <p className="large"> {errorMessage} </p>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h2> {pageSubTitle} </h2>
                  <p className="large"> {pageContent} </p>
                </React.Fragment>
              )}
            </MessageBlock>
          )}
          {success && <SuccessFaceCheck />}
        </div>
        {this.renderSupportModal()}
      </div>
    );
  }
}

FaceCheck.propTypes = {
  /** react-router history */
  history: PropTypes.any,
  /**True for enrollment, false for Login */
  enrollMode: PropTypes.bool,
  pageTitle: PropTypes.string,
  pageSubTitle: PropTypes.string,
  pageContent: PropTypes.string,
  mixpanelEvent: PropTypes.string,
  loginToken: PropTypes.string,
  livenessSessionToken: PropTypes.string,
  deviceKey: PropTypes.string,
  licenseKey: PropTypes.string,
  OpenAuthSupportTicket: PropTypes.func,
  setAuthSupportInfo: PropTypes.func,
  goToNextAuthStep: PropTypes.func,
  getFacecheckEnrollmentToken: PropTypes.func,
  handleFaceCheckSuccess: PropTypes.func,
  disableFaceCheckSessionAutoStart: PropTypes.func,
  autoStart: PropTypes.bool,
  hasAuthenticated: PropTypes.bool,
  userData: PropTypes.object
};

function mapStateToProps(state) {
  const enrollMode =
    state.auth.authResult.directive.directiveType ===
    DIRECTIVE_TYPE.FaceCheckEnrollment;
  const sessionData = enrollMode
    ? state.auth.authResult.directive
    : state.auth.authResult;

  return {
    enrollMode: enrollMode,
    loginToken: enrollMode ? null : state.auth.authResult.token,
    mixpanelEvent: enrollMode ? "FaceCheck_Enrollment" : "FaceCheck_Login",
    pageTitle: enrollMode
      ? PAGES.FACECHECK.SETUP_FACECHECK_TITLE
      : PAGES.FACECHECK.LOGIN_FACECHECK_TITLE,
    pageSubTitle: enrollMode
      ? PAGES.FACECHECK.SETUP_FACECHECK_SUBTITLE
      : PAGES.FACECHECK.LOGIN_FACECHECK_SUBTITLE,
    pageContent: enrollMode
      ? PAGES.FACECHECK.SETUP_FACECHECK_CONTENT
      : PAGES.FACECHECK.LOGIN_FACECHECK_CONTENT,
    livenessSessionToken: sessionData.livenessSessionToken,
    deviceKey: sessionData.deviceKey,
    licenseKey: sessionData.licenseKey,
    autoStart: state.auth.autoStartFaceCheckSession,
    hasAuthenticated:
      !enrollMode && state.auth.authResult.type === "Authenticated",
    userData: {
      username: state.auth.username
    }
  };
}

export default connect(mapStateToProps, {
  OpenAuthSupportTicket,
  setAuthSupportInfo,
  goToNextAuthStep,
  getFacecheckEnrollmentToken,
  handleFaceCheckSuccess,
  disableFaceCheckSessionAutoStart,
})(FaceCheck);
