import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { reduxForm, SubmissionError, getFormValues } from 'redux-form';
import { PAGES, COMMON, PAGE_NAV_LINKS } from '../../constants/localization';
import { FORM_NAMES } from '../../constants/forms';
import { FIELD_TYPES, ACTION_DISPLAY_TYPES } from '../../constants/enums';
import { ERROR_CODE_FIELDS_MAP } from '../../constants/api';
import { validateDate, validateCardNumber, validateConfirmCardNumber, validatePinNumber, validateConfirmPinNumber } from '../../services/customValidations';
import { ICON_FONT_CLASS, ICON_TYPE } from '../../constants/iconfont';
import PAGE_ALERT from '../../constants/pageAlertTypes';
import CardApi from '../../api/card';

import AlertModal from '../../components/Common/Modals/AlertModal';
import LayoutWithNav from '../../components/Layouts/LayoutWithNav';
import PageNav from '../../components/Layouts/PageNav';
import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import SpinningLoader from '../../components/Common/SpinningLoader';
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import DynamicInputField from '../../components/Common/DynamicFields/DynamicInputField';
import ActionRow from '../../components/Layouts/ActionRow';
import Action from '../../components/Common/Action';
import PageAlert from "../../components/Common/PageAlert";
import { IconFont } from "../../components/Common/IconFont";

const ActivateReplacementCard = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const formValues = useSelector(state => getFormValues(FORM_NAMES.ACCOUNT.ACTIVATE_REPLACEMENT_CARD)(state));

  const formatValuesForAPI = () => {
    const expDate = new Date(formValues.ExpirationDate);
    const month = expDate.getMonth() + 1;
    const year = expDate.getFullYear();

    return {
      cardNumber: formValues.CardNumber,
      expirationMonth: month,
      expirationYear: year,
      pin: formValues.PinNumber
    }
  }

  const mapProfileErrorsToFieldErrors = (profileErrors) => {
    let errors = {};
    for (let error of profileErrors) {
      let fieldId = ERROR_CODE_FIELDS_MAP[error.errorCode];

      if (fieldId) {
        errors[fieldId] = error.errorMessage;
      }
    }

    return errors;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    const cardData = formatValuesForAPI();

    try {
      await CardApi.ActivateOrEnrollCard(cardData);
      setIsSubmitting(false);
      setIsSuccessful(true);
    } catch (err) {
      setErrorMessage(err?.data?.errors[0].errorMessage || COMMON.ERROR);
      const errorObj = mapProfileErrorsToFieldErrors(err.data.errors);
      setShowErrorModal(true);
      setIsSubmitting(false);
      throw new SubmissionError(errorObj);
    }
  }

  return (
    <LayoutWithNav>
      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage.replace(/\xA0/g, ' ')}
      />
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>
        <FlowScreen
          flowTitle={PAGES.MY_CARD.ACTIVATE_REPLACEMENT_CARD.TITLE}
        >
          {isSuccessful &&
            <PageAlert type={PAGE_ALERT.SUCCESS} className="page-alert-static" >
              <div className='flex-row align-items-center custom-icon-font checkmark-green-bg'>
                <IconFont
                  icon={ICON_FONT_CLASS.CHECKMARK}
                  type={ICON_TYPE.SUCCESS}
                  className={'custom-class'}
                />
                <p className='spacing-bottom-none spacing-left-tiny alert-green'>{PAGES.MY_CARD.ACTIVATE_REPLACEMENT_CARD.SUCCESS_MESSAGE}</p>
              </div>
            </PageAlert>
          }

          <SpinningLoader show={false} />

          <PageBlock>
            <PageBlock.IntroText>
              {PAGES.MY_CARD.ACTIVATE_REPLACEMENT_CARD.PAGE_BLOCK_TITLE}
            </PageBlock.IntroText>
            <PageBlock.Body>
              <GenericForm
                form={FORM_NAMES.ACCOUNT.ACTIVATE_REPLACEMENT_CARD}
                onSubmit={props.handleSubmit(onSubmit)}
                onUpdate={(isFormValid) => setIsDisabled(!isFormValid)}
              >
                <GenericForm.Body>
                  <div className="col-sm-12 col-md-8 padding-left-none no-float">

                    <DynamicInputField
                      id="CardNumber"
                      type={FIELD_TYPES.INTEGER}
                      label="Card Number"
                      required={true}
                      focus={true}
                      validate={validateCardNumber}
                      max={16}
                    />

                    <DynamicInputField
                      id="ConfirmCardNumber"
                      type={FIELD_TYPES.INTEGER}
                      label="Re-enter Card Number"
                      required={true}
                      focus={true}
                      validate={validateConfirmCardNumber}
                      max={16}
                    />

                    <DynamicInputField
                      id="ExpirationDate"
                      type={FIELD_TYPES.DATE}
                      label="Card Expiration Date"
                      required={true}
                      displayDay={false}
                      validate={validateDate}
                    />

                    <DynamicInputField
                      id="PinNumber"
                      type={FIELD_TYPES.PIN}
                      label="Create Pin"
                      required={true}
                      focus={true}
                      validate={validatePinNumber}
                    />

                    <DynamicInputField
                      id="ConfirmPin"
                      type={FIELD_TYPES.PIN}
                      label="Confirm Pin"
                      required={true}
                      focus={true}
                      validate={validateConfirmPinNumber}
                    />

                  </div>

                  <ActionRow>
                    <ActionRow.Forward>
                      <Action
                        title={COMMON.SAVE}
                        displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                        type="submit"
                        loading={isSubmitting}
                        disabled={isDisabled}
                      />
                    </ActionRow.Forward>
                  </ActionRow>

                </GenericForm.Body>
              </GenericForm>
            </PageBlock.Body>
          </PageBlock>
        </FlowScreen>
      </LayoutWithNav.Content>
    </LayoutWithNav >
  );
}

ActivateReplacementCard.propTypes = {
  /** redux-form handleSubmit helper function */
  handleSubmit: PropTypes.func,
};


const form = reduxForm({
  form: FORM_NAMES.ACCOUNT.ACTIVATE_REPLACEMENT_CARD,
})(ActivateReplacementCard);

export default form; 