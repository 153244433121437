import React from "react";
import PropTypes from "prop-types";
import Icon from "../Common/Icon";

/**
 * Display a pickup instruction for money transfers.
 * There are usually a set of these displayed to the user on the success screen.
 *
 * @param {string} title The label to display above the instruction
 * @param {string} content HTML content to display as the body of the instruction
 * @param {number} icon An enumerated icon value. See the constnats/enums.js file for reference.
 * @returns {*} A formatted pickup instruction.
 * @constructor
 */
const PickupInstructionsItem = ({ title, content, icon }) => {
  return (
    <div className="mt-instruction">
      <div className="icon">
        <Icon icon={icon} />
      </div>
      <div>
        <div className="title">{title}</div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      </div>
    </div>
  );
};

PickupInstructionsItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  icon: PropTypes.number,
};

export default PickupInstructionsItem;
