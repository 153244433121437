import React, { forwardRef, ReactNode, RefObject } from 'react';

interface InputWrapperProps {
  children: ReactNode;
  className?: string;
}

const InputWrapperWithRef = forwardRef<HTMLDivElement, InputWrapperProps>(
  ({ children, className }, ref: RefObject<HTMLDivElement>) => {
    return (
      <div className={`form-group input-wrapper ${className ?? ''}`} ref={ref}>
        {children}
      </div>
    );
  }
);

export default InputWrapperWithRef;