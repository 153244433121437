import initialState from "./initialState";

export default function errors(state = initialState.errors, action) {
  switch (action.type) {
    case "ADD_ERRORS":
      return action.errors;
    case "CLEAR_ERRORS":
      return null;
    default:
      return state;
  }
}
