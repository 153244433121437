import React, { useEffect, useState } from "react";
import { PAGES, PAGE_NAV_LINKS, COMMON } from "../../constants/localization";
import { reduxForm, Field, getFormValues, isValid, initialize } from 'redux-form';
import { FORM_NAMES } from "../../constants/forms";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES, ICONS } from "../../constants/enums";
import { GetCommunicationPreferencesResponse, BankAccountCommPref, PutCommunicationPreferencesRequest } from "../../types/api/user";

import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import InputWrapper from "../Pin/InputWrapper";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import PageBlock from "../../components/Layouts/PageBlock";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import Label from "../../components/Common/Label";
import BooleanField from "../../components/Common/DynamicFields/BooleanField";
import UserApi from "../../api/user";
import FormBody from "../../components/Common/DynamicFields/FormBody";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { validateEmail } from "../../services/customValidations";
import Modal from "../../components/Common/Modal";
import AlertModal from "../../components/Common/Modals/AlertModal";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";

/**
 * Main container for the account Communications Preferences page
 */
const CommunicationPreferences = ({ error }) => {
  const dispatch = useDispatch();
  const [showCardAlerts, setShowCardAlerts] = useState(false);
  const [showEEAFXOptin, setShowEEAFXOptin] = useState(false);
  const [bankAccountCommPrefs, setBankAccountCommPrefs] = useState<BankAccountCommPref[]>([]);
  const formValues = useSelector((state: RootState) => getFormValues(FORM_NAMES.ACCOUNT.COMMUNICATION_PREFERENCES)(state)) as PutCommunicationPreferencesRequest;
  const valid = useSelector((state: RootState) => isValid(FORM_NAMES.ACCOUNT.COMMUNICATION_PREFERENCES)(state));
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const getCommunicationPreferences = async () => {
      try {
        const response = await UserApi.GetCommunicationPreferences() as GetCommunicationPreferencesResponse;
        setShowCardAlerts(response.hasCard);
        setShowEEAFXOptin(response.showEEAFXOptIn);

        const bankAccountComms = {};

        if (response.bankAccountCommPrefs) {
          setBankAccountCommPrefs(response.bankAccountCommPrefs);

          response.bankAccountCommPrefs.forEach((bankAccountCommPref) => {
            bankAccountComms[bankAccountCommPref.accountHolderAllocationsId] = bankAccountCommPref.alertOptIn;
            bankAccountComms[`${bankAccountCommPref.accountHolderAllocationsId}-email`] = bankAccountCommPref.bankAccountEmailAddress;
          });
        }

        dispatch(initialize(FORM_NAMES.ACCOUNT.COMMUNICATION_PREFERENCES, {
          news: response.programInfoOptIn,
          cardAlerts: response.cardAlertsOptIn,
          eea: response.eeafxOptIn,
          ...bankAccountComms
        }));

        setLoading(false);

      } catch (err) {
        if (err?.data?.errors) {
          setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
        } else {
          setErrorMessage(COMMON.ERROR);
        }

        setShowErrorModal(true);
      }
    };

    getCommunicationPreferences();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    const bankAccountEmailOptIns = {};
    bankAccountCommPrefs.forEach((bankAccountCommPref) => {
      if (formValues[`${bankAccountCommPref.accountHolderAllocationsId}-email`]) {
        bankAccountEmailOptIns[bankAccountCommPref.accountHolderAllocationsId] = formValues[`${bankAccountCommPref.accountHolderAllocationsId}-email`]
      }

      if (!formValues[bankAccountCommPref.accountHolderAllocationsId]) {
        bankAccountEmailOptIns[bankAccountCommPref.accountHolderAllocationsId] = ""
      }
    });

    const body = {
      programInfoOptIn: formValues.news,
      cardAlertsOptIn: formValues.cardAlerts,
      eeafxOptIn: formValues.eea || false,
      bankAccountEmailOptIns
    }

    try {
      await UserApi.UpdateCommunicationPreferences(body);

      // GA Event
      const hasAccountAlerts = Object.values(bankAccountEmailOptIns).some((email) => email !== "");
      trackEvent(EVENTS.USER_PROFILE.PROFILE_COMM_PREF, {
        news: body.programInfoOptIn,
        card_alerts: body.cardAlertsOptIn,
        account_alerts: hasAccountAlerts
      })
      setSubmitting(false);
      setShowSuccessModal(true);
    } catch (err) {
      setSubmitting(false);
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage || err.data.errors[0].errorDescription);
      } else {
        setErrorMessage(COMMON.ERROR);
      }

      setShowErrorModal(true);
    }
  }

  return (
    <React.Fragment>
      <LayoutWithNav>
        <LayoutWithNav.PageNav>
          <PageNav>
            <PageNav.Header>
              {PAGE_NAV_LINKS.SETTINGS.HEADER}
            </PageNav.Header>
            <PageNav.NavContent
              navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
            />
          </PageNav>
        </LayoutWithNav.PageNav>
        <LayoutWithNav.Content>
          {error && (
            <div className={"alert-danger"}>
              Some of your profile information is invalid. Please update it to
              have access to all our features
            </div>
          )}
          <FlowScreen
            flowTitle={PAGES.SETTINGS.COMMUNICATION_PREFERENCES.TITLE}
            className="communications-preferences-screen"
          >
            <GenericForm
              form={FORM_NAMES.ACCOUNT.COMMUNICATION_PREFERENCES}
              onSubmit={handleSubmit}
              onUpdate={() => { }}
            >
              <FormBody>
                <PageBlock>
                  <PageBlock.Loader
                    loadingContent={loading}
                  >
                    <InputSkeletonGroup numInputs={6} />
                  </PageBlock.Loader>
                  <PageBlock.Body
                    loadingContent={loading}
                  >
                    <InputWrapper>

                      <Label label="News (Check to subscribe)" required={true} hideLabel={false} />
                      <Field
                        id="news"
                        name="news"
                        component={BooleanField}
                        label={`Occasional emails containing program news, special promotions and enhancements.`}
                        hideOptional={true}
                      />

                    </InputWrapper>

                    {showCardAlerts &&
                      <InputWrapper>

                        <Label label="My Card Alerts (Check to subscribe)" required={true} hideLabel={false} />
                        <Field
                          id="cardAlerts"
                          name="cardAlerts"
                          component={BooleanField}
                          label={`Subscribe to card alerts.`}
                          hideOptional={true}
                        />

                      </InputWrapper>
                    }

                    {showEEAFXOptin &&
                      <InputWrapper>

                        <Label label="EEA mark-up (Check to subscribe)" required={true} hideLabel={false} />
                        <Field
                          id="eea"
                          name="eea"
                          component={BooleanField}
                          label={`EEA mark-up, daily and monthly summary`}
                          hideOptional={true}
                        />

                      </InputWrapper>
                    }

                    <h2>{PAGES.SETTINGS.COMMUNICATION_PREFERENCES.BANK_ACCOUNTS_HEADING}</h2>

                    {bankAccountCommPrefs.length > 0 && bankAccountCommPrefs.map((bankAccountCommPref) => {
                      return (
                        <InputWrapper key={bankAccountCommPref.accountHolderAllocationsId} className="spacing-left-medium">

                          <Label label={`${bankAccountCommPref.beneficiaryName} - ${bankAccountCommPref.displayName}`} required={true} hideLabel={false} />
                          <Field
                            id={bankAccountCommPref.accountHolderAllocationsId.toString()}
                            name={bankAccountCommPref.accountHolderAllocationsId.toString()}
                            component={BooleanField}
                            label={`Subscribe to alerts`}
                            hideOptional={true}
                          />

                          {formValues?.[bankAccountCommPref.accountHolderAllocationsId] &&
                            <DynamicInputField
                              id={`${bankAccountCommPref.accountHolderAllocationsId.toString()}-email`}
                              type={FIELD_TYPES.EMAIL}
                              label={`Please provide an email address to receive alerts`}
                              required={true}
                              placeholder="Email Address"
                              validate={validateEmail}
                              className="half-width"
                            />
                          }

                        </InputWrapper>
                      );
                    })}

                    <ActionRow>
                      <ActionRow.Forward>
                        <Action
                          title={COMMON.SUBMIT}
                          displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                          type="submit"
                          loading={submitting}
                          disabled={!valid}
                        />
                      </ActionRow.Forward>
                    </ActionRow>
                  </PageBlock.Body>
                </PageBlock>

                <AlertModal
                  onClose={() => setShowErrorModal(false)}
                  open={showErrorModal}
                  title={COMMON.GENERIC_ERROR_MODAL_HEADER}
                  content={errorMessage}
                />

                <Modal
                  onClose={() => setShowSuccessModal(false)}
                  open={showSuccessModal}
                  title={COMMON.SUCCESS}
                  content={PAGES.SETTINGS.COMMUNICATION_PREFERENCES.SUCCESS_MESSAGE}
                  className="header-icon center"
                  headerIcon={ICONS.GREEN_CHECK}
                />
              </FormBody>
            </GenericForm>
          </FlowScreen>
        </LayoutWithNav.Content>
      </LayoutWithNav>
    </React.Fragment>

  );
}

export default reduxForm({
  form: FORM_NAMES.ACCOUNT.COMMUNICATION_PREFERENCES,
  enableReinitialize: true,
})(CommunicationPreferences);