import React, { useState } from 'react';
import i18n from '../../i18n/config';
import OnDemandTransferApi from '../../api/onDemandTransfer';

import Modal from '../Common/Modal';
import { ACTION_DISPLAY_TYPES, ICONS } from '../../constants/enums';
import Icon from '../Common/Icon';

type CancelODTModalProps = {
  showCancelModal: boolean;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  transferId: string;
  beneficiaryName: string;
};

const CancelODTModal = ({ showCancelModal, setShowCancelModal, transferId, beneficiaryName }: CancelODTModalProps) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [modalTitle, setModalTitle] = useState(<span>{i18n.t("COMMON.CANCEL_TRANSACTION")}</span>);
  const [modalContent, setModalContent] = useState<string>(`${i18n.t("PAGES.ODT.CANCEL_TRANSFER.CANCEL_MODAL_INITIAL_TEXT")} ${beneficiaryName}.`);
  const [modalActions, setModalActions] = useState([{
    title: i18n.t("COMMON.GO_BACK"),
    onClick: () => setShowCancelModal(false),
    displayType: ACTION_DISPLAY_TYPES.LINK,
    className: "bold",
    disabled: loading
  },
  {
    title: i18n.t("COMMON.YES_CANCEL_TRANSACTION"),
    onClick: () => handleCancelTransfer(),
    displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    className: "warning bold",
    disabled: loading
  }])

  const handleCancelTransfer = async () => {
    setLoading(true);
    setModalActions([
      { ...modalActions[0], disabled: true },
      { ...modalActions[1], disabled: true }
    ]);
    try {
      await OnDemandTransferApi.CancelTransfer(transferId);
      setModalTitle(<><Icon icon={ICONS.GREEN_CHECK} /> <span>{i18n.t("COMMON.TRANSACTION_CANCELLED")}</span></>);
      setModalContent("Cancel request was submitted successfully.");
      setModalActions([
        {
          ...modalActions[1],
          className: "bold",
          title: "Close",
          onClick: () => setShowCancelModal(false),
        }
      ]);
      setLoading(false);
    } catch (err) {
      setModalContent(err?.data?.errors[0].errorMessage);
      setModalTitle(<><Icon icon={ICONS.WARNING_INFO} /> <span>{i18n.t("COMMON.TRANSACTION__NOT_CANCELLED")}</span></>);
      setModalActions([
        { ...modalActions[0] },
        {
          ...modalActions[1],
          className: "bold",
          title: "Try again"
        }
      ]);
      setLoading(false);
    }
  };

  return (
    <Modal
      open={showCancelModal}
      onClose={() => setShowCancelModal(false)}
      className="modal-title-flex"
      title={modalTitle}
      actions={modalActions}
      content={modalContent}
      contentLoading={loading}
    />
  );
}

export default CancelODTModal;