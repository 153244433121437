import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { GetEnrollmentData, UpdateCurrentPage } from '../../actions/enrollment';
import { isLoggedIn, getUserData, enrollLogout } from '../../services/enrollment';
import Enroll from './Enroll';
import CreateUser from './CreateUser';
import AccountSecurity from './AccountSecurity';
import CardDetails from './CardDetails';
import CommunicationPreferences from './CommunicationPreferences';
import Complete from './Complete';
import PersonalData from './PersonalData';
import SpinningLoader from '../../components/Common/SpinningLoader';

const EnrollmentPageWizard = () => {
  const dispatch = useDispatch();
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const participantId = getUserData();

    if (!isLoggedIn()) {
      enrollLogout();
      dispatch(UpdateCurrentPage(0));
    } else if (participantId) {
      setShowSpinner(true);

      const getData = async () => {
        await dispatch(GetEnrollmentData(participantId));
        setShowSpinner(false);
      }

      getData();
    } else {
      dispatch(UpdateCurrentPage(0));
    }
  }, []);

  return (
    <React.Fragment >
      <SpinningLoader show={showSpinner} />
      <Enroll />
      <CreateUser />
      <PersonalData />
      <AccountSecurity />
      <CardDetails />
      <CommunicationPreferences />
      <Complete />
    </React.Fragment>
  )
}

export default EnrollmentPageWizard;