import React from 'react';
import { BankAccount } from '../../types/api/odt';

import BankAccountToolTip from '../ODT/BankAccountToolTip';
import { BankAccountCardType } from '../../types/components';
import BankAccountCardActions from './BankAccountCardActions';

const BankAccountCard = ({ bank, handleInfoTipModal, type }: { bank: BankAccount, handleInfoTipModal?: (title: string, content: string) => void, type?: BankAccountCardType }) => {
  const countries = localStorage.getItem("GET_COUNTRIES");
  const countriesData = JSON.parse(countries || '[]');
  const destinationCountry = countriesData.find((country: any) => country.iso === bank.destinationCountryIso);
  const destinationCountryName = destinationCountry ? destinationCountry.name : "";
  const destinationCountryId = destinationCountry ? destinationCountry.id : "";

  return (
    <article className="bank-account-card">
      <p>{bank.accountName}</p>
      <p>*{bank.accountNumber}</p>
      <p>{bank.destinationCurrencyCode} to {destinationCountryName}</p>
      {/* if bank account status isn't available, show label and InfoTip */}
      {bank.moreInfo
        ? <BankAccountToolTip bank={bank} handleInfoTipModal={handleInfoTipModal} />
        : <p></p>
      }

      <BankAccountCardActions
        bank={bank}
        type={type}
        destinationData={{ destinationCountryName, destinationCountryId }}
      />

    </article>
  );
}

export default BankAccountCard;