/// These breakpoints are copied from
/// variables.scss
/// $screen-xxs-min: 350px;
/// $screen-xs-min: 360px;
/// $screen-x-sm-min: 440px;
/// $screen-xs-max: 767px;
/// $screen-sm-min: 768px;
/// $screen-sm-max: 991px;
/// $screen-md-min: 992px;
/// $screen-md-max: 1169px;
/// $screen-lg-min: 1170px;
const BREAKPOINTS = {
  XXS_MIN: 350,
  XS_MIN: 360,
  X_SM_MIN: 440,
  XS_MAX: 767,
  SM_MIN: 768,
  SM_MAX: 991,
  MD_MIN: 992,
  MD_MAX: 1169,
  LG_MIN: 1170,
};

export default BREAKPOINTS;
