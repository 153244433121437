export default {
  FaceTec_accessibility_cancel_button: "Cancel",

  FaceTec_feedback_center_face: "Center your face",
  FaceTec_feedback_face_not_found: "Frame your face",
  FaceTec_feedback_move_phone_away: "Move away",
  FaceTec_feedback_move_away_web: "Move away",
  FaceTec_feedback_move_phone_closer: "Move closer",
  FaceTec_feedback_move_phone_to_eye_level: "Move phone to eye level",
  FaceTec_feedback_move_to_eye_level_web: "Look straight into camera",
  FaceTec_feedback_face_not_looking_straight_ahead: "Look straight ahead",
  FaceTec_feedback_face_not_upright: "Hold your head straight",
  FaceTec_feedback_face_not_upright_mobile: "Keep head straight",
  FaceTec_feedback_hold_steady: "Hold steady",
  FaceTec_feedback_move_web_closer: "Move closer",
  FaceTec_feedback_move_web_even_closer: "Even closer",
  FaceTec_feedback_use_even_lighting: "Light face more evenly",

  FaceTec_instructions_header_ready: "Get ready for your video selfie",
  FaceTec_instructions_message_ready:
    "Please frame your face in the oval with good lighting.",
  FaceTec_action_im_ready: "I am ready",

  FaceTec_result_facemap_upload_message: "Uploading video selfie",
  FaceTec_result_idscan_upload_message:
    "Uploading<br/>encrypted<br/>ID document",

  FaceTec_retry_header: "Please try again",
  FaceTec_retry_subheader_message: "We could not get a good selfie of you.",
  FaceTec_retry_your_image_label: "Your selfie",
  FaceTec_retry_ideal_image_label: "Ideal pose",
  FaceTec_retry_instruction_message_1: "Neutral face - no smiling",
  FaceTec_retry_instruction_message_2: "Good lighting - no glare",
  FaceTec_retry_instruction_message_3: "Visible face - no face coverings",
  FaceTec_action_ok: "OK",

  FaceTec_camera_permission_header: "Enable camera",
  FaceTec_camera_permission_message:
    "Your camera permissions are disabled.<br/>Please check your operating system and browser settings.",
  FaceTec_browser_camera_help_action_link: "https://INSERT_YOUR_URL_HERE",
  FaceTec_camera_permission_launch_settings: "OK",

  FaceTec_enter_fullscreen_header: "Full screen selfie mode",
  FaceTec_enter_fullscreen_message:
    "Before we begin, please click the button below to open full screen mode",
  FaceTec_enter_fullscreen_action: "Open full screen",

  FaceTec_initializing_camera: "Starting camera...",

  FaceTec_idscan_type_selection_header: "Select your<br>ID document type",
  FaceTec_action_select_id_card: "Photo ID",
  FaceTec_action_select_passport: "Passport",
  FaceTec_idscan_capture_id_card_front_instruction_message:
    "Show front of photo ID",
  FaceTec_idscan_capture_id_card_back_instruction_message:
    "Show back of photo ID",
  FaceTec_idscan_capture_passport_instruction_message:
    "Show passport photo page",
  FaceTec_action_take_photo: "Take Photo",
  FaceTec_idscan_review_id_card_front_instruction_message:
    "Confirm photo is clear & legible",
  FaceTec_idscan_review_id_card_back_instruction_message:
    "Confirm photo is clear & legible",
  FaceTec_idscan_review_passport_instruction_message:
    "Confirm photo is clear & legible",
  FaceTec_action_accept_photo: "Accept",
  FaceTec_action_retake_photo: "Retake",
  FaceTec_result_idscan_unsuccess_message:
    "ID photo<br/>did not match<br>user's face",

  FaceTec_result_success_message: "Success!",
};
