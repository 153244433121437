import React from 'react';

const SuccessContent = () => {
  return (
    <>
      <h1>Companion Card Request Complete</h1>
      <p>Thank you for completing your request.</p>
      <p>We will send an email to you and your primary cardholder when your companion card request has been reviewed and approved for processing.</p>
    </>
  );
}

export default SuccessContent;