import React from "react";
import { PAGES, COMMON } from "../../constants/localization";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import InputContainer from "../../components/Common/UserInputs/InputContainer";

const ForgotPIN = (props) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit();
  };

  const getFields = () => {
    let fields = [];
    {
      props.fields.map((field, index) => {
        fields.push(
          <InputContainer
            key={index}
            {...field}
            disabled={props.isTransferLocked}
            className="col-md-6"
            inputUpdate={props.inputUpdate}
          />
        );
      });
    }

    return fields;
  };

  return (
    <PageBlock showScreenErrors>
      <PageBlock.Title>{PAGES.PIN_RESET.FORGOT_PIN_HEADER}</PageBlock.Title>
      <PageBlock.IntroText>{props.introText}</PageBlock.IntroText>
      <PageBlock.Loader loadingContent={props.loadingContent}>
        <InputSkeletonGroup numInputs={4} />
      </PageBlock.Loader>
      <PageBlock.Body loadingContent={props.loadingContent}>
        <form id="ForgotPIN" onSubmit={handleSubmit}>
          <div className="row">{getFields()}</div>
          <ActionRow>
            <ActionRow.Forward>
              <Action
                title={COMMON.NEXT}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
                disabled={props.invalid || props.isTransferLocked}
                loading={props.loading}
              />
            </ActionRow.Forward>
            <ActionRow.Backward>
              <a href={PAGES.PIN_RESET.CANCEL_URL}>Cancel</a>
            </ActionRow.Backward>
          </ActionRow>
        </form>
      </PageBlock.Body>
    </PageBlock>
  );
};

ForgotPIN.propTypes = {
  onSubmit: PropTypes.func,
  fields: PropTypes.any,
  introText: PropTypes.string,
  invalid: PropTypes.bool,
  loading: PropTypes.bool,
  errors: PropTypes.array,
  loadingContent: PropTypes.bool,
  isTransferLocked: PropTypes.bool,
  inputUpdate: PropTypes.func,
};

export default ForgotPIN;
