import React, { useEffect, useState } from 'react';
import CardApi from '../../api/card';
import { SendToWesternUnionResponse } from '../../types/api/card';
import PinCheck from '../../components/Common/PinCheck';
import { COMMON, PAGES } from '../../constants/localization';
import AlertModal from '../../components/Common/Modals/AlertModal';

const SendToWesternUnion = () => {
  const [data, setData] = useState<SendToWesternUnionResponse>();
  const [showPinCheck, setShowPinCheck] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const getRedirectData = async () => {
      try {
        const response = await CardApi.GetRedirectURL() as SendToWesternUnionResponse;
        setData(response);
        if (response.isPinRequired) {
          setShowPinCheck(true);
        } else {
          redirectToWU(response.redirectUrl);
        }
      } catch (err) {
        if (err.data) {
          setErrorMessage(err.data.errors[0].errorMessage);
        } else {
          setErrorMessage(COMMON.ERROR);
        }
        setShowErrorModal(true);
      }
    }

    getRedirectData();
  }, []);

  const handlePinCheckSuccess = () => {
    setShowPinCheck(false);
    redirectToWU(data.redirectUrl);
  }

  const redirectToWU = (url: string) => {
    setTimeout(() => {
      window.location.replace(url);
    }, 2500);
  }

  return (
    <div>
      <h1>{data?.title}</h1>
      <p>{data?.subHeader}</p>
      <p>{data?.disclaimer}</p>

      <PinCheck
        open={showPinCheck}
        onSuccess={handlePinCheckSuccess}
        onDismiss={() => setShowPinCheck(false)}
        source={{
          name: PAGES.MY_CARD.WESTERN_UNION.PIN_CHECK_SOURCE,
          type: PAGES.MY_CARD.WESTERN_UNION.PIN_CHECK_SOURCE,
        }}
      />

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={COMMON.GENERIC_ERROR_MODAL_HEADER}
        content={errorMessage}
      />
    </div>
  );
}

export default SendToWesternUnion;