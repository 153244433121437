import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { DROPDOWN_STYLES } from "../../constants/theme";

/**
 * The generic select component that should be used for all selects
 * to enforce standards (chevron marker, colors etc)
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const GeneralSelect = (props) => {
  const { input, options, name, id, disabled, ...custom } = props;

  let selectedValue = input.value
    ? options.filter((p) => p.value == input.value)[0]
    : options[0];

  return (
    <Select
      {...input}
      {...custom}
      id={id}
      styles={DROPDOWN_STYLES}
      name={name}
      isDisabled={disabled}
      options={options}
      isOptionDisabled={(option) => option.disabled}
      value={selectedValue}
      onBlur={(e) => e.preventDefault()}
      onChange={(option) => input.onChange(option.value)}
    />
  );
};

GeneralSelect.propTypes = {
  /** Main list of options [{label, value}] */
  options: PropTypes.array.isRequired,
  /** Name attribute */
  name: PropTypes.string,
  /** id attribute */
  id: PropTypes.any,
  /** input field */
  input: PropTypes.object,
  /** Disable toggle */
  disabled: PropTypes.bool,

  selectedValue: PropTypes.any,
};

export default GeneralSelect;
