import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { PAGES, COMMON } from "../../constants/localization";
import DisplayField from "./DisplayField";
import nextIcon from "../../assets/images/icons/RightArrow_White.svg";
import {
  RATE_CARD_FIELDS,
  ACTION_DISPLAY_TYPES,
  ACTION_TYPES,
  ICONS,
  MONEY_TRANSFER_FIELD_IDS
} from "../../constants/enums";
import { HTTP_STATUS_CODES } from "../../constants/api";
import Modal from "../Common/Modal";
import FormControl from "../Common/FormControl";
import MoneyTransferApi from "../../api/moneyTransfer";
import { UpdateProviderRates, GetProviderRates } from "../../actions/moneyTransfer";
import Action from "../Common/Action";
import { eventTracker, EVENTS } from "../../services/eventTracker";
import Cancel from "../../assets/images/icons/Cancel.svg";
import Icon from "../Common/Icon";

const fxRateId = RATE_CARD_FIELDS.FX_RATE;
const staticFields = [fxRateId];
let fxRate = {
  label: PAGES.MONEY_TRANSFER.RATE_LIST.CURRENT_EXCHANGE_RATE,
  value: COMMON.NA,
};
/**
 * The card that displays provider rates.
 *
 * @param fields
 * @param providerName
 * @param logo
 * @param providerId
 * @param onSelectedQuote
 * @param promoCodeAvailable
 * @param quoteId
 * @param handleResetProviders
 * @returns {*}
 * @constructor
 */
export class RateCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promoCodeModalShown: false,
      promoCodeSuccessModalShown: false,
      promoCode: "",
      validatingPromoCode: false,
      promoCodeError: "",
      selected: false,
      forceLoading: false
    };

    this.renderPromoCodeButton = this.renderPromoCodeButton.bind(this);
    this.renderPromoCodeForm = this.renderPromoCodeForm.bind(this);
    this.renderPromoCodeModal = this.renderPromoCodeModal.bind(this);
    this.renderPromoCodeSuccessModal =
      this.renderPromoCodeSuccessModal.bind(this);
    this.renderLogo = this.renderLogo.bind(this);
    this.openPromoCodeModal = this.openPromoCodeModal.bind(this);
    this.closePromoCodeModal = this.closePromoCodeModal.bind(this);
    this.closePromoCodeSuccessModal =
      this.closePromoCodeSuccessModal.bind(this);
    this.handlePromoCodeChange = this.handlePromoCodeChange.bind(this);
    this.validatePromoCode = this.validatePromoCode.bind(this);
    this.handleQuoteSelect = this.handleQuoteSelect.bind(this);
  }

  async handleQuoteSelect(quoteId) {
    this.setState({ selected: true });
    await this.props.onSelectedQuote(quoteId);
  }
  /**
   * Event handler for the promo code text box change event.
   * @param {*} event The event object passed back from the input field.
   */
  handlePromoCodeChange(event) {
    this.setState({ promoCode: event.target.value, promoCodeError: "" });
  }

  /**
   * Event handler for the promo code form submit.
   * There isn't a submit button in the form so this only fires on "Enter"
   * @param {*} event The event object passed back from the form submission.
   */
  async handlePromoCodeSubmit(event) {
    event.preventDefault();

    await this.validatePromoCode();

    return false;
  }

  /**
   * Calls the validatePromo API to validate the promo code.
   * @param {*} promoCode The code to validate
   */
  async validatePromoCode() {
    this.setState({ validatingPromoCode: true, promoCodeError: "" });
    let promoCodeResponse;

    try {
      promoCodeResponse = await MoneyTransferApi.ValidatePromoCode(
        this.props.quoteId,
        this.state.promoCode,
        this.props.serviceType
      );

      eventTracker.track(EVENTS.MoneyTransfer_PromoCodeSubmitted, {
        success: true,
      });
      this.props.UpdateProviderRates(promoCodeResponse);

      this.setState({
        promoCodeError: "",
        promoCodeModalShown: false,
        promoCodeSuccessModalShown: true,
      });
    } catch (error) {
      eventTracker.track(EVENTS.MoneyTransfer_PromoCodeSubmitted, {
        success: false,
      });
      if (error.status === HTTP_STATUS_CODES.BAD_REQUEST) {
        let promoCodeError = error.data.errors[0];
        this.setState({ promoCodeError: promoCodeError.errorMessage });
      } else {
        this.setState({ promoCodeError: COMMON.ERROR });
      }
    }

    this.setState({ validatingPromoCode: false });
  }

  /**
   * Renders the promo code form. Currently this appears inside a modal dialog.
   */
  renderPromoCodeForm() {
    return (
      <form
        className="form"
        onSubmit={(event) => this.handlePromoCodeSubmit(event)}
      >
        <FormControl
          label={PAGES.MONEY_TRANSFER.PROMO_CODE.PROMO_CODE}
          validationError={this.state.promoCodeError}
          value={this.state.promoCode}
          onChange={this.handlePromoCodeChange}
          autoFocus={true}
          disabled={this.state.validatingPromoCode}
        />
      </form>
    );
  }

  /**
   * Open the dialog modal so the user can enter a promo code.
   */
  openPromoCodeModal() {
    eventTracker.track(EVENTS.MoneyTransfer_PromoCode);

    this.setState({ promoCodeModalShown: true });
  }

  /**
   * Close the promo code dialog modal and clears out the promo code form.
   */
  closePromoCodeModal() {
    this.setState({
      promoCodeModalShown: false,
      promoCode: "",
      promoCodeError: "",
    });
  }

  /**
   * Closes the promo code success modal.
   */
  closePromoCodeSuccessModal() {
    this.setState({ promoCodeSuccessModalShown: false, promoCode: "" });
  }

  /**
   * Renders the promo code button if promo codes are available from the provider and no code has been entered.
   */
  renderPromoCodeButton() {

    const provider = this.props.providerRates.find(provider => provider.providerId === this.props.providerId);

    // If noPromoState, display Enter Promo Code button
    // Else if appliedPromoState, display green checkmark, promo code text, and 'X' button to remove promo code
    // Condiationally display subText if present
    if (provider?.quotes?.length > 0 && provider?.quotes[0]?.promoCode?.noPromoState) {
      return (
        <div className="promo-link">
          <button
            onClick={this.openPromoCodeModal}
            disabled={this.props.loading}
            className="btn btn-link text-right"
          >
            {provider.quotes[0].promoCode.noPromoState.linkText}
          </button>
        </div>

      );
    } else if (provider?.quotes?.length > 0 && provider.quotes[0].promoCode?.appliedPromoState) {
      return (
        <div className="mt-display applied-promo-wrapper">
          <Icon icon={ICONS.GREEN_CHECK} />
          <p className="promo-code-text">{provider.quotes[0].promoCode.appliedPromoState.appliedPromoCode}</p>
          <button className="no-styling" onClick={() => this.props.handleResetProviders()}> <img src={Cancel} alt="cancel" /></button>
          {provider.quotes[0]?.promoCode?.appliedPromoState?.subText &&
            <p className="applied-promo-text">{provider.quotes[0]?.promoCode?.appliedPromoState?.subText}</p>
          }
        </div>
      )

    } else {
      return null;
    }
  }

  /**
   * Renders the dialog modal that allows users to enter a promo code. Hidden by default.
   */
  renderPromoCodeModal() {
    if (this.props.promoCodeAvailable) {
      const action = {
        title: PAGES.MONEY_TRANSFER.PROMO_CODE.APPLY,
        onClick: this.validatePromoCode,
        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
        disabled: this.state.promoCode.length <= 0,
      };

      return (
        <Modal
          title={PAGES.MONEY_TRANSFER.PROMO_CODE.ENTER_PROMO}
          open={this.state.promoCodeModalShown}
          onClose={this.closePromoCodeModal}
          actions={[action]}
          loading={this.state.validatingPromoCode}
          content={this.renderPromoCodeForm()}
          className="promo-modal"
          small={true}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Renders the modal displayed when a user successfully enters a promo code. Hidden by default.
   */
  renderPromoCodeSuccessModal() {
    if (this.props.promoCodeAvailable) {
      const action = {
        title: COMMON.OK,
        onClick: this.closePromoCodeSuccessModal,
        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
        actionType: ACTION_TYPES.CONFIRM,
        focused: true,
      };

      return (
        <Modal
          title={PAGES.MONEY_TRANSFER.PROMO_CODE.ENTER_PROMO}
          open={this.state.promoCodeSuccessModalShown}
          onClose={this.closePromoCodeSuccessModal}
          actions={[action]}
          content={PAGES.MONEY_TRANSFER.PROMO_CODE.PROMO_APPLIED.replace(
            "{0}",
            this.state.promoCode
          )}
          small={true}
        />
      );
    } else {
      return null;
    }
  }

  /**
   * Renders the provider logo. If no image is available the provider name is used instead.
   */
  renderLogo() {
    if (this.props.logo !== undefined) {
      return (
        <figure className="provider-logo">
          <img
            src={this.props.logo}
            alt={this.props.providerName}
          />
        </figure>

      );
    } else {
      return (
        <div className="logo-name provider-logo">{this.props.providerName}</div>
      );
    }
  }

  /**
   * Renders error messages on the rate card instead of fields.
   * @param {array} errors The list of errors to display on the rate card
   */
  renderErrorMessage(errors) {
    return (
      <div className="row spacing-top-small error">
        <div className="col-xs-12 validation-error">
          {errors.map((e) => e.errorMessage)}
        </div>
      </div>
    );
  }

  /**
   * Renders the exchange rate section, conditionally showing a disclaimer.
   */
  renderExchangeRateSection() {
    if (this.props.errors && this.props.errors.length > 0) {
      return null;
    }

    let superscript = fxRate.superscript ? (
      <sup>{fxRate.superscript}</sup>
    ) : null;
    return (
      <div className="exchange-rate">
        <label className="rate-label">
          {fxRate.label}
          {superscript}:
        </label>
        <span className="rate-description"> {fxRate.formattedValue}</span>
      </div>
    );
  }

  /**
   * Renders the rate card component including logo, fields, promo code management, and the next button.
   */
  render() {
    if (this.props.fields) {
      // get static fields
      fxRate = this.props.fields.find((obj) => {
        return obj.id === fxRateId;
      });
    }

    return (
      <div className={`rate-card ${this.props.providerName}`}>
        {this.renderLogo()}

        {this.renderExchangeRateSection()}

        {/* If promo code is avaiable display hr element */}
        {this.props.promoCodeAvailable &&
          <div className="visible-xs above-promo">
            <hr className="spacing-top-tiny spacing-bottom-tiny" />
          </div>
        }

        {this.renderPromoCodeButton()}

        {/* If promo code is avaiable display hr element */}
        {this.props.promoCodeAvailable &&
          <div className="visible-xs below-promo">
            <hr className="spacing-top-tiny spacing-bottom-tiny" />
          </div>
        }

        {this.props.errors && this.props.errors.length > 0 ? (
          this.renderErrorMessage(this.props.errors)
        ) : (
          <div className="fields-section">
            {this.props.fields.map((field) => {

              let icon = field.id === MONEY_TRANSFER_FIELD_IDS.ESTIMATED_ARRIVAL ? ICONS.CLOCK : null;

              if (!staticFields.includes(field.id)) {
                return (
                  <DisplayField
                    id={field.id}
                    label={field.label}
                    value={field.formattedValue}
                    className={field.label ? field.label.toLowerCase().replace(' ', '-') : ''}
                    key={field.id}
                    originalValue={field.originalFormattedValue}
                    superscript={field.superscript}
                    icon={icon}
                    provider={this.props.providerName}
                    type={field.type}
                  />
                );
              }
            })}


          </div>
        )
        }

        <div className="button-cell">
          <Action
            title={COMMON.NEXT}
            small={true}
            loading={(this.state.selected && this.props.loading) || this.state.forceLoading}
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            disabled={
              this.props.disableButton ||
              (!this.state.selected && this.props.loading)
            }
            onClick={() => this.handleQuoteSelect(this.props.quoteId)}
            iconRight={nextIcon}
          />
        </div>

        {this.renderPromoCodeModal()}
        {this.renderPromoCodeSuccessModal()}
      </div>
    );
  }
}

RateCard.propTypes = {
  providerName: PropTypes.string.isRequired,
  onSelectedQuote: PropTypes.func,
  providerId: PropTypes.number, // used for grouping in v2,
  fields: PropTypes.array,
  logo: PropTypes.string,
  promoCodeAvailable: PropTypes.any,
  quoteId: PropTypes.number,
  UpdateProviderRates: PropTypes.func,
  disableButton: PropTypes.bool,
  errors: PropTypes.array,
  loading: PropTypes.bool,
  moneyTransferValues: PropTypes.object,
  GetProviderRates: PropTypes.func,
  providerRates: PropTypes.array,
  handleResetProviders: PropTypes.func,
  serviceType: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    providerRates: state.moneyTransfer.providerRates.providerRates,
    moneyTransferValues: state?.form?.moneytransfer?.values,
    serviceType: state?.moneyTransfer?.workflow?.serviceType,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    UpdateProviderRates: (promoCodeResponse) => dispatch(UpdateProviderRates(promoCodeResponse)),
    GetProviderRates: (sendAmount, receiveCurrency, destinationCountry, destinationProvince, destinationCity) => dispatch(GetProviderRates(sendAmount,
      receiveCurrency,
      destinationCountry,
      destinationProvince,
      destinationCity))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RateCard);
