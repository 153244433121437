import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { reduxForm, Field } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { COMMON, PAGES } from "../../../constants/localization";
import {
  ACTION_DISPLAY_TYPES,
} from "../../../constants/enums";
import EnrollmentApi from "../../../api/enrollment";
import PagePathConstants from "../../../constants/page-numbers";
import { UpdateCurrentPage } from "../../../actions/enrollment";
import FlowScreen from "../../../components/Layouts/FlowScreen";
import GenericForm from "../../../components/Common/DynamicFields/GenericForm";
import PageBlock from "../../../components/Layouts/PageBlock";
import ValidationSummary from "../../../components/Enrollment/ValidationSummary";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import Label from "../../../components/Common/Label";
import BooleanField from "../../../components/Common/DynamicFields/BooleanField";
import ActionRow from "../../../components/Layouts/ActionRow";
import Action from "../../../components/Common/Action";
import ActionLink from "../../../components/Common/ActionLink";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";

const CommunicationPreferencesForm = ({
  setShowSpinner,
}) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [data, setData] = useState([]);
  const formData = useSelector(
    (state) => state.form[PAGES.ENROLLMENT.COMMUNICATION_PREFERENCES.FORM_NAME]
  );
  const userData = useSelector((state) => state.enrollment.userData);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  /**
   * Get communication preferences docs when userData is updated
   */
  useEffect(() => {
    if (userData.participantId) {
      loadSiteDocsFromServer();
    }
  }, [userData]);

  /**
   * Get communication preferences docs
   */
  const loadSiteDocsFromServer = async () => {
    try {
      const response = await EnrollmentApi.GetCommunicationPreferences(
        userData.participantId
      );

      setData(response.preferences);
    } catch (err) {
      setErrors([PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  /**
   * Form submission handling
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const { values } = formData;
    const checkedPreferences = [];

    Object.keys(values).forEach(key => {
      if (values[key]) {
        checkedPreferences.push(key);
      }
    });

    try {
      await EnrollmentApi.CompleteWorkflow(
        userData.workflowId,
        checkedPreferences,
        userData.productGroupId,
        userData.clientId,
        userData.participantId
      );

      trackEvent(EVENTS.ENROLLMENT.COMMUNICATIONS_SUBMITTED, { success: true });
      dispatch(UpdateCurrentPage(PagePathConstants.COMPLETE));
    } catch (err) {
      setShowSpinner(false);
      trackEvent(EVENTS.ENROLLMENT.COMMUNICATIONS_SUBMITTED, { success: false, failure_reason: err[0].errorMessage || PAGES.ENROLLMENT.ERROR_RETRY });
      setErrors([err[0].errorMessage || PAGES.ENROLLMENT.ERROR_RETRY]);
    }
  };

  const docElements = data.map((doc) => {
    return (
      <InputWrapper key={doc.id}>
        <Label label={doc.label} required={true} hideLabel={false} />
        <Field
          id={doc.id}
          name={doc.id}
          component={BooleanField}
          checked={true}
          label={`${doc.text} (Check to subscribe)`}
          hideOptional={true}
        />
      </InputWrapper>
    );
  });

  return (
    <FlowScreen flowTitle="Communications Preferences">
      <GenericForm
        form={PAGES.ENROLLMENT.COMMUNICATION_PREFERENCES.FORM_NAME}
        onSubmit={handleSubmit}
      >
        <GenericForm.Body>
          <PageBlock>
            <ValidationSummary errors={errors} />
            <PageBlock.Body>
              {docElements}
              <ActionRow>
                <ActionRow.Backward>
                  <ActionLink
                    clickFunc={() => dispatch(UpdateCurrentPage(currentPage - 2))}
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={false}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </GenericForm>
    </FlowScreen>
  );
};

CommunicationPreferencesForm.displayName =
  "CommunicationPreferencesFormComponent";

CommunicationPreferencesForm.propTypes = {
  /** Show SpinnerLoader */
  setShowSpinner: PropTypes.func.isRequired,
  /** useHistory hook value */
  history: PropTypes.object.isRequired,
};

export default reduxForm({
  form: PAGES.ENROLLMENT.COMMUNICATION_PREFERENCES.FORM_NAME,
})(CommunicationPreferencesForm);
