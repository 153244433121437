import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGES } from "../../constants/localization";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES, ACTION_DISPLAY_TYPES_BY_NAME, ACTION_TYPES_BY_NAME, FIELD_CATEGORIES } from "../../constants/enums";
import Modal from "../../components/Common/Modal";
import { ROUTES } from "../../constants/clientRoutes";
import FooterLinks from "../../components/MoneyTransfer/FooterLinks";
import { camelCase } from "lodash";
import { RootState } from "../../types/redux";
import { MoneyTransferButton, MoneyTransferPage, WorkflowStep } from "../../types/moneyTransfer";
import { SetWorkflowPageNumber } from "../../actions/moneyTransfer";
import { EVENTS } from "../../constants/events";
import { trackEvent } from "../../services/eventTracker";

const StaticHTMLPage = ({ pageData }: { pageData: MoneyTransferPage }) => {
  const dispatch = useDispatch();
  const workflow = useSelector((state: RootState) => state.moneyTransfer.workflow);
  const pageNumber = workflow.pages.findIndex((page: WorkflowStep) => page.type === pageData.id) + 1;
  const [supportModalShown, setSupportModalShown] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /**
   * Renders the dialog modal that allows users to navigate to support
   */
  const renderSupportModal = () => {
    const action = {
      title: PAGES.SUPPORT.STAY,
      onClick: () => setSupportModalShown(false),
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    };
    const exitAction = {
      title: PAGES.SUPPORT.EXIT,
      onClick: () => (location.href = ROUTES.EXTERNAL.SUPPORT),
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
    };

    return (
      <Modal
        title={PAGES.SUPPORT.EXIT_MODAL_HEADER}
        open={supportModalShown}
        onClose={() => setSupportModalShown(false)}
        actions={[exitAction, action]}
        content={PAGES.SUPPORT.EXIT_MODAL_TEXT}
        className="support-modal"
      />
    );
  }

  /**
   * Updates the page number in the redux store to navigate to the previous page
   */
  const onBack = () => {
    dispatch(SetWorkflowPageNumber(pageNumber - 1));
  }

  /**
   * Updates the page number in the redux store to navigate to the next page
   */
  const onNext = () => {
    handleGAEvent();
    dispatch(SetWorkflowPageNumber(pageNumber + 1));
  }

  /**
   * Handle sending events to GA
   */
  const handleGAEvent = () => {
    if (pageData.id === FIELD_CATEGORIES.FRAUD_WARNING) {
      trackEvent(EVENTS.TO_ACCOUNT.FRAUD_WARNING_ACCEPTED);
    }
  }

  /**
   * Checks action type returned by API, and returns appropriate onClick function
   */
  const checkActionType = (actionType: string) => {
    let clickFunc: () => void;
    switch (actionType) {
      case ACTION_TYPES_BY_NAME.BACK:
        clickFunc = onBack;
        break;
      case ACTION_TYPES_BY_NAME.CONTINUE:
        clickFunc = onNext;
        break;
    }

    return clickFunc;
  }

  /**
   * API returns display types in string format, need to map to number for <Action /> component
   */
  const mapDisplayType = (displayTypeText: string) => {
    let displayType: number;
    switch (displayTypeText) {
      case ACTION_DISPLAY_TYPES_BY_NAME.PRIMARY:
        displayType = ACTION_DISPLAY_TYPES.PRIMARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.SECONDARY:
        displayType = ACTION_DISPLAY_TYPES.SECONDARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.TERTIARY:
        displayType = ACTION_DISPLAY_TYPES.TERTIARY;
        break;
      case ACTION_DISPLAY_TYPES_BY_NAME.LINK:
        displayType = ACTION_DISPLAY_TYPES.LINK;
        break;
    }

    return displayType;
  }

  /**
   * Renders the action button elements
   */
  const renderButton = (type: string, data: MoneyTransferButton) => {
    const displayType = mapDisplayType(data.displayType);
    const actionFunc = checkActionType(data.actionType);
    return (
      <div className={`col-xs-12 col-sm-6 ${type === "prev" ? "two" : "button-cell one"}`} data-cy={camelCase(data.title)}>
        <Action
          onClick={() => actionFunc()}
          displayType={displayType}
          title={data.title}
        />
      </div>
    )
  }

  /**
   * Render the page
   */
  return (
    <div>
      <div
        className="reset-styles"
        dangerouslySetInnerHTML={{ __html: pageData.content }}
      ></div>
      <div className="row navRow">
        {renderButton("prev", pageData.previousButton)}
        {renderButton("next", pageData.nextButton)}
      </div>

      {workflow.footer &&
        <FooterLinks
          disclaimerLinks={workflow.footer.disclaimerLinks}
          pageName={pageData.id}
        />
      }

      {renderSupportModal()}
    </div>
  );
}

export default StaticHTMLPage;