import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGES } from '../../constants/localization';
import { WorkflowStep } from '../../types/moneyTransfer';
import { MONEY_TRANSFER_STEPS } from '../../constants/enums';
import i18n from '../../i18n/config';
import { RootState } from '../../types/redux';

import CountryAndAmount from './CountryAndAmount';
import RatesDisplay from './RatesDisplay';
import AlertModal from '../../components/Common/Modals/AlertModal';
import { SetInitialToAccountWorkflowPages } from '../../actions/moneyTransfer';

const WUToAccount = ({ serviceType }: { serviceType: number }) => {
  const dispatch = useDispatch();
  const workflow = useSelector((state: RootState) => state.moneyTransfer.workflow);
  const [currentStep, setCurrentStep] = useState<WorkflowStep>();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  // On initial render, set the current step to CountryAndAmount
  useEffect(() => {
    dispatch(SetInitialToAccountWorkflowPages(serviceType, [
      {
        number: 1,
        order: 1,
        title: PAGES.MY_CARD.TO_ACCOUNT.COUNTRY_AMOUNT.TITLE,
        type: MONEY_TRANSFER_STEPS.COUNTRY_AND_AMOUNT,
        renderComponent: () => (
          <CountryAndAmount
            form="moneytransfer"
            handleError={handleError}
          />
        )
      },
      {
        number: 2,
        order: 2,
        title: PAGES.MY_CARD.TO_ACCOUNT.RATE_CARDS.TITLE,
        type: MONEY_TRANSFER_STEPS.RATES,
        renderComponent: () => (
          <RatesDisplay
            pageNumber={2}
            handleError={handleError}
          />
        ),
      }
    ]))
  }, []);

  // When the page number changes, update the current step
  useEffect(() => {
    window.location.hash = String(workflow.pageNumber);
    setCurrentStep(workflow.pages[workflow.pages.findIndex((step: WorkflowStep) => step.order === workflow.pageNumber)]);
  }, [workflow.pageNumber]);

  const handleError = (err: any) => {
    if (err?.data?.errors) {
      setErrorMessage(err.data.errors[0].errorMessage);
    } else {
      setErrorMessage(i18n.t("COMMON.ERROR"));
    }
    setShowErrorModal(true);
  }

  return (
    <div className="money-transfer v2">

      {/* If current step is receipt, don't style with page block and display title */}
      {currentStep?.type === MONEY_TRANSFER_STEPS.RECEIPT &&
        currentStep?.renderComponent()
      }

      {/* If current step is not receipt, style and display everything */}
      {currentStep?.type !== MONEY_TRANSFER_STEPS.RECEIPT &&
        <>
          <h1>{PAGES.MY_CARD.TO_ACCOUNT.TITLE}</h1>

          <div className="page-block">

            <h2 className="spacing-bottom-medium">
              {currentStep?.title}
            </h2>

            {currentStep?.renderComponent()}

            <AlertModal
              onClose={() => setShowErrorModal(false)}
              open={showErrorModal}
              title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
              content={errorMessage}
            />
          </div>
        </>
      }

    </div>

  );
}

export default WUToAccount;