import { USER_SELECTIONS } from "../constants/actionTypes";
import UserSelectionsApi from "../api/userSelection";

/**
 * Adds an option to the recently selected type
 *
 * @param option
 * @param selectionType
 * @returns {Function}
 */
export function AddUserSelection(option, selectionType) {
  return (dispatch) => {
    UserSelectionsApi.AddOptionByType(option, selectionType);

    dispatch({
      type: USER_SELECTIONS.ADD_SELECTION,
      option,
      selectionType,
    });
  };
}

/**
 * Gets all recently selected items
 *
 * @returns {function(): (string|Array)}
 */
export function GetUserSelections() {
  return (dispatch) => {
    const selections = UserSelectionsApi.GetAllOptions();
    dispatch({
      type: USER_SELECTIONS.GET_SELECTIONS,
      selections,
    });
  };
}
