import axios from "axios";
import { HTTP_STATUS_CODES, ROUTES } from "../constants/api";
import AuthApi from "./auth";
import {
  HandleNewResponse,
  HandleNewErrorResponse,
  HandleErrorResponse,
  HandleResponse,
} from "./handlers";
import FileSaver from "file-saver";
import { get, post } from "./api";
import { TRANSACTION_SERVICE_TYPES } from "../constants/enums";

class MoneyTransferApi {
  /**
   * Retrieves provider rates
   * @param destinationCountry
   * @param receiveCurrency
   * @param sendAmount
   * @returns array of providers with quotes
   * @constructor
   */
  static async GetProviderRates(
    sendAmount: string,
    receiveCurrency: string,
    destinationCountry: string,
    destinationProvince?: string,
    destinationCity?: string,
    serviceType?: string
  ) {
    const url = ROUTES.MONEY_TRANSFER.GET_PROVIDERS_RATES(serviceType);

    let payload = {
      ReceiveCountry: destinationCountry,
      ReceiveCurrency: receiveCurrency,
      SendAmount: sendAmount,
      ReceiveState: destinationProvince,
      ReceiveCity: destinationCity,
    };

    try {
      let response = await post(url, payload);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Retrieves a list of countries available to a user to do money transfers.
   *
   * @returns {object}
   */
  static async GetAvailableCountries() {
    let response = await get(ROUTES.MONEY_TRANSFER.GET_AVAILABLE_COUNTRIES);

    return HandleResponse(response);
  }

  /**
   * Retrieves a list of provinces available to a user to do money transfers
   * @param isoCountryCode
   * @returns {object}
   */
  static async GetAvailableProvinces(isoCountryCode) {
    const url = ROUTES.MONEY_TRANSFER.GET_AVAILABLE_PROVINCES(isoCountryCode);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Retrieves a list of cities available to a user to do money transfers
   * @param {string} isoCountryCode
   * @param {string} provinceCode
   * @returns {object}
   */
  static async GetAvailableCities(isoCountryCode, provinceCode) {
    const url = ROUTES.MONEY_TRANSFER.GET_AVAILABLE_CITIES(isoCountryCode, provinceCode);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   *
   * @param {number} quoteId
   * @param {string} promoCode
   * @param {number} serviceType The service type of the transaction
   */
  static async ValidatePromoCode(quoteId, promoCode, serviceType) {
    const url = ROUTES.MONEY_TRANSFER.VALIDATE_PROMO_CODE;

    let payload = {
      quoteId: quoteId,
      promoCode: promoCode,
      serviceType
    };

    try {
      let response = await post(url, payload);
      return HandleResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Retrieves provider rates
   * @param quoteId The id of the quote returned from the GetProviderRates call
   * @returns A workflow object
   */
  static async GetWorkflow(quoteId, serviceType?: string) {
    const url = ROUTES.MONEY_TRANSFER.GET_WORKFLOW(quoteId, serviceType);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Validates user input
   * @param quoteId The id of the quote returned from the GetProviderRates call
   * @param values The serviceProviderFieldId : value key pairs
   * @returns A invalid workflow object or information for the next page
   */
  static async ValidateWorkflow(quoteId, values, serviceType?: string) {
    const url = ROUTES.MONEY_TRANSFER.VALIDATE_WORKFLOW(quoteId);

    let payload = {
      serviceType,
      fields: values,
    };

    try {
      let response = await post(url, payload);
      // Error response status was changed from 400 to 226
      // If status 226 is returned, we have validation errors
      if (response.status === HTTP_STATUS_CODES.VALIDATION_ERROR) {
        throw response;
      } else {
        return HandleNewResponse(response);
      }
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Commits a money transfer user input
   * @param quoteId The id of the quote returned from the GetProviderRates call
   * @returns A completed transaction DTO
   */
  static async Commit(quoteId: string, serviceType?: string) {
    const url = ROUTES.MONEY_TRANSFER.COMMIT;

    let payload = {
      quoteId: quoteId,
      serviceType: serviceType,
    };

    try {
      let response = await post(url, payload);
      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Retrieves money transfer receipt
   * @param {number} url The url of the receipt
   * @param {string} referenceNumber The reference number of the transaction
   * @param {number} serviceType The service type of the transaction
   * @returns Downloads a pdf of the receipt
   */
  static async GetReceipt(moneyTransferId: number, referenceNumber: string, serviceType?: number) {
    const serviceTypeString = serviceType === TRANSACTION_SERVICE_TYPES.TO_ACCOUNT ? "SendToAccount" : "CashPickup";
    const filename = `Brightwell_${serviceTypeString}_${referenceNumber}.pdf`;
    const url = ROUTES.MONEY_TRANSFER.RECEIPT(moneyTransferId, serviceType);

    try {
      const response = await axios.get(url, {
        headers: AuthApi.getAuthorizationHeaders(),
        responseType: "blob",
      });

      await FileSaver.saveAs(new Blob([response.data]), filename);

      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Gets the terms and conditions for a given quote
   * @param quoteId The id of the quote returned from the GetProviderRates call
   * @returns An object containing the raw terms and conditions HTML
   */
  static async GetTermsAndConditions(quoteId) {
    const url = ROUTES.MONEY_TRANSFER.GET_TERMS_AND_CONDITIONS(quoteId);

    try {
      let response = await get(url);
      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Get transaction details for a money transfer
   * @param moneyTransferId The id of the completed transfer
   * @param type The type of the transfer (cash pickup, to account)
   * @returns The details of the transfer
   */
  static async GetTransferDetails(moneyTransferId: string, type?: number) {
    const url = ROUTES.MONEY_TRANSFER.GET_TRANSFER_DETAILS(moneyTransferId, type);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Cancel money transfer transaction
   * @param moneyTransferId The id of the completed transfer
   * @returns The details of the transfer
   */
  static async CancelTransaction(moneyTransferId) {
    const url = ROUTES.MONEY_TRANSFER.CANCEL_TRANSACTION();

    try {
      let response = await post(url, { moneyTransferId });
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }

  /**
   * Retrieves a list of countries available to a user to do money transfers.
   *
   * @returns {object}
   */
  static async GetCountries(serviceType) {
    const url = ROUTES.MONEY_TRANSFER.GET_COUNTRIES(serviceType);

    try {
      let response = await get(url);
      return HandleNewResponse(response);
    } catch (error) {
      return HandleNewErrorResponse(error);
    }
  }
}

export default MoneyTransferApi;
