import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getFormValues, SubmissionError, reduxForm } from "redux-form";
import RateCard from "../../components/MoneyTransfer/RateCard";
import DynamicForm from "../../components/MoneyTransfer/DynamicForm";
import { AddUserSelection } from "../../actions/userSelection";
import {
  ACTION_DISPLAY_TYPES,
  FIELD_TYPES,
  FIELD_TYPES_BY_NAME,
  ICONS,
} from "../../constants/enums";
import InfoModal from "../../components/Common/InfoModal";
import Modal from "../../components/Common/Modal";
import Lottie from "react-lottie";
import * as checkmark from "../../assets/animations/checkmark.json";
import Form from "./Form";
import Action from "../../components/Common/Action";
import PinCheck from "../../components/Common/PinCheck";
import PickupInstructionItem from "../../components/MoneyTransfer/PickupInstructionItem";
import ENV from "../../constants/environment";
import { EVENTS } from "../../services/eventTracker";
import PageBlock from "../../components/Layouts/PageBlock";
import ActionRow from "../../components/Layouts/ActionRow";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import CurrencyField from "../../components/Common/DynamicFields/CurrencyField";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";

class ComponentLibrary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      countryOptions: [],
      selectedCountry: {
        label: "none",
      },
      valueAmount: "100.00 USD",
      boldValue: false,
      superScript: undefined,
      infoModalOpen: false,
      v2ModalOpen: false,
      v2ContentModalOpen: false,
      stickyFooterModalOpen: false,
      showLoadingModal: false,
      modalLoading: false,
      newValue: undefined,
      isAnimationPaused: false,
      isAnimaitonStopped: true,
      showPinCheckModal: false,
      fields: [
        {
          label: "Text Field",
          placeholderText: "Enter Text Here",
          type: FIELD_TYPES.TEXT,
          min: 1,
          max: 30,
          required: true,
          valid: true,
          validationError: null,
          regex: "asdfaksjdfas//*6%///^",
          id: "textField",
          name: "textField",
        },
        {
          label: "Prepopulater Text Field",
          placeholderText: "Enter Text Here",
          type: FIELD_TYPES.TEXT,
          min: 1,
          max: 30,
          required: true,
          valid: true,
          validationError: null,
          regex: "asdfaksjdfas//*6%///^",
          id: "textFieldPrepop",
          name: "textFieldPrepop",
        },
        {
          label: "Integer Field",
          placeholderText: "Enter Integer Here",
          type: FIELD_TYPES.INTEGER,
          max: 100,
          required: false,
          valid: true,
          validationError: null,
          regex: "asdfaksjdfas//*6%///^",
          id: "intField",
          value: "",
        },
        {
          label: "Decimal Field",
          placeholderText: "Enter Decimal Here",
          type: FIELD_TYPES.DECIMAL,
          max: 100,
          required: false,
          valid: false,
          validationError: null,
          regex: "asdfaksjdfas//*6%///^",
          id: "decimalField",
          value: "",
        },
        {
          label: "Dropdown Field",
          placeholderText: null,
          type: FIELD_TYPES.SELECT,
          max: null,
          required: false,
          valid: true,
          validationError: null,
          regex: null,
          id: "dropdownField",
          value: "",
          options: [
            { label: "Red", value: "red" },
            { label: "Blue", value: "blue" },
            { label: "Green", value: "green" },
          ],
        },
        {
          label: "Phone Field",
          placeholderText: "Enter Phone Number",
          type: FIELD_TYPES.PHONE_NUMBER,
          max: 10,
          required: false,
          valid: true,
          validationError: null,
          regex: null,
          id: "phoneNumberField",
        },
        {
          label: "Date Field",
          placeholderText: null,
          type: FIELD_TYPES.DATE,
          required: false,
          valid: true,
          validationError: null,
          regex: null,
          id: "date",
          value: "",
        },
        {
          label: "Country Field",
          placeholderText: null,
          type: FIELD_TYPES.COUNTRY,
          required: false,
          valid: true,
          validationError: null,
          regex: null,
          id: "country",
          value: "",
          options: [
            {
              label: "Afghanistan",
              value: "AFG",
            },
            {
              label: "Åland Islands",
              value: "ALA",
            },
            {
              label: "Albania",
              value: "ALB",
            },
            {
              label: "Algeria\t",
              value: "DZA",
            },
            {
              label:
                "The United Kingdom of Great Britain and Northern Ireland ",
              value: "ASM",
            },
          ],
        },
        {
          label: "Boolean Field",
          placeholderText: "",
          type: FIELD_TYPES.BOOL,
          max: 5,
          required: false,
          valid: true,
          validationError: null,
          regex: "",
          id: "booleanField",
          value: "",
        },
        {
          label: "Boolean Field With Value",
          placeholderText: "",
          type: FIELD_TYPES.BOOL,
          max: 5,
          required: false,
          valid: true,
          validationError: null,
          regex: "",
          id: "booleanFieldPrePop",
          value: "booleanFieldPrePop",
        },
      ],
      fieldCategories: {
        id: 3,
        name: "Payout network",
        sections: [
          {
            name: null,
            fields: [
              {
                name: "Cascading Start",
                placeholderText: "",
                datatype: FIELD_TYPES_BY_NAME.SELECT,
                options: [
                  {
                    label: "Option 1",
                    value: "Id012",
                  },
                  {
                    label: "Option 2",
                    value: "Id010",
                  },
                ],
                max: 5,
                isRequired: true,
                isValid: true,
                validationError: null,
                regex: "",
                serviceProviderFieldId: "cascadingField1",
                value: "",
                relatedFields: [
                  {
                    type: "SUCCESSOR",
                    id: "cascadingField2",
                  },
                ],
              },
              {
                name: "Cascading Field 2",
                placeholderText: "",
                datatype: FIELD_TYPES_BY_NAME.CASCADING_SELECT,
                max: 5,
                required: true,
                isValid: true,
                validationError: null,
                regex: "",
                options: [],
                optionsSource: `${ENV.BASE_API_URL}/moneytransfer/payoutnetwork/cities?providerId=4&countryIso=PH&stateId={cascadingField1}`,
                serviceProviderFieldId: "cascadingField2",
                value: "",
                relatedFields: [
                  {
                    type: "PREDECESSOR",
                    id: "cascadingField1",
                  },
                  {
                    type: "SUCCESSOR",
                    id: "cascadingField3",
                  },
                ],
              },
              {
                name: "Cascading Field 3",
                placeholderText: "",
                datatype: FIELD_TYPES_BY_NAME.CASCADING_SELECT,
                max: 5,
                required: true,
                isValid: true,
                validationError: null,
                regex: "",
                options: [],
                optionsSource: `${ENV.BASE_API_URL}/moneytransfer/payoutnetwork/payers?providerId=4&countryId=226&stateId={cascadingField1}&cityId={cascadingField2}`,
                serviceProviderFieldId: "cascadingField3",
                value: "",
                relatedFields: [
                  {
                    type: "PREDECESSOR",
                    id: "cascadingField2",
                  },
                ],
              },
            ],
          },
        ],
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.openLoadingModal = this.openLoadingModal.bind(this);
  }

  renderModalContent() {
    return (
      <form>
        <div className="form-group">
          <label>Hello World</label>
          <input className="form-control" />
        </div>
      </form>
    );
  }

  renderStickyFooterModalContent() {
    const content =
      "<div>\r\n  <h5>TERMS AND CONDITIONS FOR MONEYGRAM ONLINE SERVICES</h5>\r\n  <p><strong>Important Notices:</strong></p>\r\n  <p>1. PLEASE READ THIS AGREEMENT (DEFINED BELOW) CAREFULLY. THIS AGREEMENT CONTAINS IMPORTANT PROVISIONS INCLUDING AN ARBITRATION PROVISION THAT REQUIRES ALL CLAIMS TO BE RESOLVED BY WAY OF BINDING ARBITRATION. THE TERMS OF THE ARBITRATION PROVISION ARE SET FORTH IN THE SECTION BELOW ENTITLED “ARBITRATION AND WAIVER OF CLASS REMEDY AND JURY TRIAL.”</p>\r\n  <p>2. BY ACCESSING, USING OR ATTEMPTING TO USE THE SERVICES (DEFINED BELOW) IN ANY CAPACITY, YOU ACKNOWLEDGE THAT YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT.</p>\r\n  <p>3. AS EXPLAINED IN FURTHER DETAIL IN THE SECTION BELOW ENTITLED “ELECTRONIC COMMUNICATIONS,” AUTHORIZATION TO ELECTRONICALLY RECEIVE ANY AND ALL COMMUNICATIONS (DEFINED BELOW) IS A CONDITION OF ACCESS TO AND USE OF THE SERVICES, MEANING THAT IF YOU REVOKE YOUR CONSENT TO RECEIVE SUCH COMMUNICATIONS AND DISCLOSURES ELECTRONICALLY YOU UNDERSTAND THAT YOU MAY NOT CONTINUE TO ACCESS OR USE THE SERVICES.</p></div>";
    return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
  }

  handleSubmit() {
    return new Promise(() => {
      // Randomly assign errors to fields
      const validatedFields = [];
      for (let field of this.state.fields) {
        if (Math.random() < 0.5) {
          field.valid = false;
          field.validationError = [
            {
              errorCode: "TBD",
              errorMessage: "Everything is awesome! Except this...",
              errorDescription: null,
            },
          ];
        }

        validatedFields.push(field);
      }

      this.setState({ fields: validatedFields });

      // Create an error object to return to redux form
      let errorObject = {};

      for (let validatedField of validatedFields) {
        if (
          !validatedField.valid &&
          validatedField.validationError &&
          validatedField.validationError.length
        ) {
          errorObject[validatedField.id] =
            validatedField.validationError[0].errorMessage;
        }
      }

      throw new SubmissionError(errorObject);
    });
  }

  openLoadingModal() {
    this.setState({ modalLoading: true, showLoadingModal: true });
    setTimeout(() => {
      this.setState({ modalLoading: false });
    }, 3000);
  }

  render() {
    let fields = [
      {
        id: 3,
        label: "Send amount",
        formattedValue: "100 USD",
      },
      {
        id: 4,
        label: "Receive tax",
        formattedValue: "0.50 USD",
      },
      {
        id: 5,
        label: "Fee",
        formattedValue: "12.50 USD",
      },
      {
        id: 6,
        label: "Estimated arrival",
        formattedValue: "10 min",
      },
      {
        id: 2,
        label: "Total receive amount",
        formattedValue: "5200.00 PHP",
        disclaimerId: 2,
      },
      {
        id: 1,
        label: "Total cost",
        formattedValue: "112.50 USD",
        disclaimerId: 1,
      },
      {
        id: 30,
        label: "Current exchange rate",
        formattedValue: "1 USD = 12222.456 PHP",
      },
    ];

    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData: checkmark,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className={"component-library"}>
        <h1>Component Library</h1>

        <p>Test some components{"'"} functionality!</p>

        <div className={"component-block v2"}>
          <PinCheck
            open={this.state.showPinCheckModal}
            onSuccess={() => {
              this.setState({ showPinCheckModal: false });
            }}
            onDismiss={() => {
              this.setState({ showPinCheckModal: false });
            }}
            source={{
              name: EVENTS.SOURCE.MONEY_TRANSFER,
              type: EVENTS.SOURCE.MONEY_TRANSFER_TYPE.CASH_PICKUP,
            }}
          />
          <Action
            title="Pin check"
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            onClick={() => this.setState({ showPinCheckModal: true })}
          />
        </div>

        <div className={"component-block money-transfer"}>
          <h3>Money Transfer Instructions</h3>
          <PickupInstructionItem
            title="Valid, government issued photo ID"
            content="Name on receipt must match ID exactly"
            icon={ICONS.PERSONAL_INFO}
          />

          <PickupInstructionItem
            title="Reference number"
            content="Can be found on your receipt"
            icon={ICONS.NEWS_DASHBOARD}
          />

          <PickupInstructionItem
            title="MoneyGram location"
            content="Visit <a href='http://www.moneygram.com' target='_blank'>moneygram.com</a> to locate the nearest location"
            icon={ICONS.LOCATION}
          />
        </div>

        <div className={"component-block v2"}>
          <h3>Animations</h3>
          <button
            className="btn btn-primary"
            onClick={() => this.setState({ isAnimaitonStopped: true })}
          >
            stop
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              this.setState({
                isAnimaitonStopped: false,
                isAnimationPaused: false,
              })
            }
          >
            play
          </button>
          <button
            className="btn btn-primary"
            onClick={() =>
              this.setState({ isAnimationPaused: !this.state.isPaused })
            }
          >
            pause
          </button>
          <Lottie
            options={animationOptions}
            height={400}
            width={400}
            isStopped={this.state.isAnimaitonStopped}
            isPaused={this.state.isAnimationPaused}
          />
        </div>

        {/*Add top level css wrapping class to component block!*/}
        <div className="component-block money-transfer v2">
          <div className="row">
            <div className="col-xs-12">
              <h3>Basic Dynamic Rate Card</h3>
              <p></p>
              <div className={"row"}>
                <div className="col-xs-12">
                  <div className="form-group">
                    <RateCard
                      fields={fields}
                      providerName="MoneyGram"
                      logo="https://readiness.brightwellpayments.com/externalapi/v1/assets/MoneyGram.png"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*Add top level css wrapping class to component block!*/}
        <div className="component-block v2">
          <div className="row">
            <div className="col-xs-12">
              <h3>Modals</h3>

              <div className="row">
                <div className="col-xs-3">
                  <InfoModal
                    text="Hello World!"
                    open={this.state.infoModalOpen}
                    onClose={() => {
                      this.setState({ infoModalOpen: false });
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ infoModalOpen: true });
                    }}
                  >
                    Show Info Modal
                  </button>
                </div>
                <div className="col-xs-3">
                  <Modal
                    content="Welcome to the world. Good Luck."
                    title="Hello World"
                    open={this.state.v2ModalOpen}
                    actions={[
                      {
                        title: "Nevermind",
                        displayType: ACTION_DISPLAY_TYPES.SECONDARY,
                        focused: true,
                        onClick: () => {
                          this.setState({ v2ModalOpen: false });
                        },
                      },
                      {
                        title: "Live Life",
                        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
                        onClick: () => { },
                      },
                    ]}
                    onClose={() => {
                      this.setState({ v2ModalOpen: false });
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ v2ModalOpen: true });
                    }}
                  >
                    Show V2 Modal
                  </button>
                </div>
                <div className="col-xs-3">
                  <Modal
                    content={this.renderModalContent()}
                    title="Hello World"
                    open={this.state.v2ContentModalOpen}
                    actions={[
                      {
                        title: "Submit",
                        focused: true,
                        displayType: ACTION_DISPLAY_TYPES.PRIMARY,
                      },
                    ]}
                    onPositiveAction={() => { }}
                    onClose={() => {
                      this.setState({ v2ContentModalOpen: false });
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ v2ContentModalOpen: true });
                    }}
                  >
                    Show V2 Modal With Component
                  </button>
                </div>
                <div className="col-xs-3">
                  <Modal
                    content={this.renderStickyFooterModalContent()}
                    title="Sticky footer"
                    open={this.state.stickyFooterModalOpen}
                    stickyFooter={true}
                    onClose={() => {
                      this.setState({ stickyFooterModalOpen: false });
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.setState({ stickyFooterModalOpen: true });
                    }}
                  >
                    Show Sticky Footer Modal
                  </button>
                </div>
                <div className="col-xs-3">
                  <Modal
                    content={this.renderModalContent()}
                    title="Loading modal"
                    contentLoading={this.state.modalLoading}
                    open={this.state.showLoadingModal}
                    onClose={() => {
                      this.setState({ showLoadingModal: false });
                    }}
                  />
                  <button
                    className="btn btn-primary"
                    onClick={this.openLoadingModal}
                  >
                    Loading Modal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="component-block v2">
          <div className="row">
            <div className="col-xs-12">
              <h3>Dynamic Field Generator</h3>
              <p></p>
              <div className={"row"}>
                <Form onSubmit={this.handleSubmit} fields={this.state.fields} />
              </div>
            </div>
          </div>
        </div>

        {/*Add top level css wrapping class to component block!*/}
        <div className="component-block v2">
          <div className="row">
            <div className="col-xs-12">
              <h3>Cascading Dynamic Field Generator</h3>
              <p></p>
              <form className="form">
                <DynamicForm fieldCategory={this.state.fieldCategories} />
              </form>
            </div>
          </div>
        </div>

        {/*Add top level css wrapping class to component block!*/}
        <div className="component-block v2">
          <div className="row">
            <div className="col-xs-12">
              <h3>Currency Component</h3>
              <div className={"row"}>
                <div className="spacing-left-small spacing-right-small">
                  <label>Default (no props)</label>
                  <hr />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="form-group">
                    <CurrencyField />
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className="spacing-left-small spacing-right-small">
                  <label>Populated via Dynamic Field</label>
                  <hr />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="form-group">
                    <DynamicInputField
                      type={FIELD_TYPES.CURRENCY}
                      label="Dynamic Field"
                      targetCurrency="EUR"
                      required={true}
                      isTargetCurrencyRequired={true}
                      alwaysShowTargetCurrencySelector={true}
                    />
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className="spacing-left-small spacing-right-small">
                  <label>Old Currency Converter Style</label>
                  <hr />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="form-group">
                    <CurrencyField
                      label="Transaction amount"
                      currencyIso="USD"
                      targetCurrency="EUR"
                      required={true}
                      targetCurrencies={["GBR", "EUR"]}
                      isTargetCurrencyRequired={true}
                      alwaysShowTargetCurrencySelector={true}
                      decimalPlaces={2}
                      displayArrow={true}
                    />
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div className="spacing-left-small spacing-right-small">
                  <label>New Currency Converter Style</label>
                  <hr />
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="form-group">
                    <CurrencyField
                      label="Transaction amount"
                      hideLabel={true}
                      required={true}
                      targetCurrencies={["GBR", "EUR"]}
                      isTargetCurrencyRequired={true}
                      alwaysShowTargetCurrencySelector={true}
                      decimalPlaces={2}
                      displayArrow={false}
                      targetCurrencyLabel="Currency"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="component-block v2 layout-components">
          <div className="row">
            <div className="col-xs-12">
              <h3>Flow Screen and Page Block with Page Nav</h3>
              <br />
              <br />
              <LayoutWithNav>
                <LayoutWithNav.PageNav>
                  <PageNav>
                    <PageNav.Header>Page Nav Header</PageNav.Header>
                    <PageNav.NavContent>
                      <PageNav.NavLink Href="#" Text="Page Nav Link 1" />
                      <PageNav.NavLink Href="#" Text="Page Nav Link 2" />
                    </PageNav.NavContent>
                  </PageNav>
                </LayoutWithNav.PageNav>
                <LayoutWithNav.Content>
                  <FlowScreen flowTitle="Flow Screen Title">
                    <PageBlock>
                      <PageBlock.Error>
                        Page Block top error goes here
                      </PageBlock.Error>
                      <PageBlock.Title>Page Block Title</PageBlock.Title>
                      <PageBlock.IntroText>
                        Page Block Intro Text
                      </PageBlock.IntroText>
                      <PageBlock.Body>
                        Body element(s) goes here
                        <ActionRow>
                          <ActionRow.Backward>
                            <a href="#">Cancel</a>
                          </ActionRow.Backward>
                          <ActionRow.Forward>
                            <button type="button" className="btn btn-primary">
                              Action
                            </button>
                          </ActionRow.Forward>
                        </ActionRow>
                      </PageBlock.Body>
                    </PageBlock>
                  </FlowScreen>
                </LayoutWithNav.Content>
              </LayoutWithNav>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ComponentLibrary.propTypes = {
  AddUserSelection: PropTypes.any,
  rate: PropTypes.object,
  formValues: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    rate: {
      logo: "",
      name: "MoneyGram",
      fee: 5.0,
      receiveAmount: 5144.0,
      receiveCurrency: "PHP",
      sendCurrency: "USD",
      rateDescription: "1.00 USD = 51.3415 PHP",
      updatedAt: "03/12/2019",
      sendAmount: 100,
      maxSendAmount: 1000,
      maxReceiveAmount: 1000,
    },
    formValues: getFormValues("Library")(state),
    initialValues: {
      booleanFieldPrePop: true,
      textFieldPrepop: "Hugh Jackman",
      decimalField: 15,
      date: "1991-08-02T04:00:00.000Z",
      phoneNumberField: {
        type: 1,
        number: "2025550172",
        country: {
          id: 288,
          code: "USA",
          name: "United States of America",
          phoneCode: "1",
          iso: "US",
          isoCode: "US",
        },
      },
    },
  };
}

const dynamicForm = reduxForm({
  form: "dynamicForm",
})(ComponentLibrary);

export default connect(mapStateToProps, {
  AddUserSelection,
})(dynamicForm);
