import React, { useState } from 'react';
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import LayoutWithNav from '../../Layouts/LayoutWithNav';
import { COMMON, PAGES, PAGE_NAV_LINKS } from '../../../constants/localization';
import { IconFont } from '../../Common/IconFont';
import { ICON_TYPE, ICON_FONT_CLASS } from '../../../constants/iconfont';
import PageNav from '../../Layouts/PageNav';
import PageBlock from '../../Layouts/PageBlock';
import InputSkeletonGroup from '../../Common/Loaders/InputSkeleton';
import InputWrapper from '../../../containers/Pin/InputWrapper';
import FlowScreen from '../../Layouts/FlowScreen';
import Arrow from "../../../assets/images/icons/ChevronRight.svg";
import AuthenticatorInstructions from './AuthenticatorInstructions';
import { FEATURE_FLAGS } from '../../../constants/featureFlags';
import { TOTP_FLOW } from '../../../constants/enums';
import AuthApi from '../../../api/auth';
import MFAtoFaceCheckModal from './MFAtoFaceCheckModal';
import PageAlert from '../../Common/PageAlert';
import AlertModal from '../../Common/Modals/AlertModal';
import PAGE_ALERT from '../../../constants/pageAlertTypes';
import { GetFeatureFlags } from '../../../actions/user';


const HowItWorksScreen = ({ loading, setLoading, featureFlags, page, setPage }) => {
  const [displayAccordion, setDisplayAccordion] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  if (page !== TOTP_FLOW.SCREENS.HOW_IT_WORKS) {
    return null;
  }

  const handleClick = async () => {
    if (featureFlags.includes(FEATURE_FLAGS.TOTP_DISPLAY_SETUP)) {
      setLoading(true);
      setPage(TOTP_FLOW.SCREENS.LINK_AUTHENTICATOR_APP)
      setSuccess(false);
    } else {
      setShowModal(true);
    }
  }

  const disableTotpAPICall = async () => {
    try {
      await AuthApi.disbleTOTP();
      await dispatch(GetFeatureFlags());
      setSuccess(true);
      setShowModal(false);
    } catch (err) {
      setShowModal(false);
      setShowErrorModal(true);
    }
  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.SETTINGS.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content>

        {loading && (
          <PageBlock>
            <PageBlock.Loader loadingContent={loading}>
              <InputSkeletonGroup numInputs={1} />
            </PageBlock.Loader>
          </PageBlock>
        )}

        {!loading &&
          <FlowScreen flowTitle={PAGES.SETTINGS.LOG_IN_METHOD.TITLE} className={'how-it-works__screen'}>

            <MFAtoFaceCheckModal showModal={showModal} setShowModal={setShowModal} disableTotpAPICall={disableTotpAPICall} />

            {success &&
              <PageAlert type={PAGE_ALERT.SUCCESS} >
                <div className='flex-row align-items-center custom-icon-font checkmark-green-bg'>
                  <IconFont
                    icon={ICON_FONT_CLASS.CHECKMARK}
                    type={ICON_TYPE.SUCCESS}
                    className={'custom-class'}
                  />
                  <p className='spacing-bottom-none spacing-left-tiny alert-green'>Log in method has changed</p>
                </div>

              </PageAlert>
            }

            <InputWrapper>
              <div className="flex-row spacing-top-medium">
                <p className="spacing-right-large shark-grey bold">Current Method</p>
                <p className="bold"> {featureFlags.includes(FEATURE_FLAGS.TOTP_DISPLAY_SETUP) ? 'FaceCheck' : 'Authenticator app'}</p>
              </div>

              <button className="btn btn-primary spacing-top-small" onClick={handleClick}>
                Change to {featureFlags.includes(FEATURE_FLAGS.TOTP_DISPLAY_SETUP) ? 'Authenticator app' : 'FaceCheck'}
              </button>

              {featureFlags.includes(FEATURE_FLAGS.TOTP_DISPLAY_SETUP) &&

                <React.Fragment>

                  <hr />

                  <div className="spacing-left-small spacing-right-small">
                    <div className="flex-row align-items-center" role="button" onClick={() => setDisplayAccordion(!displayAccordion)}>

                      <figure className={`spacing-right-small position-absolute ${displayAccordion ? 'rotate-90-center' : ''}`}>
                        <img src={Arrow} alt="arrow down" />
                      </figure>

                      <p className="spacing-bottom-none spacing-left-medium">How it works</p>
                    </div>

                    <div className={`spacing-left-medium collapse ${displayAccordion ? 'show' : ''}`}>
                      <AuthenticatorInstructions />
                    </div>
                  </div>

                  <hr />
                </React.Fragment>
              }
            </InputWrapper>
          </FlowScreen>
        }

        <AlertModal
          onClose={() => setShowErrorModal(false)}
          open={showErrorModal}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
        />

      </LayoutWithNav.Content>
    </LayoutWithNav>
  );
}

HowItWorksScreen.propTypes = {
  /** loading boolean */
  loading: PropTypes.bool,
  /** hook to set loading state */
  setLoading: PropTypes.func,
  /** user feature flags from redux */
  featureFlags: PropTypes.array,
  /** number to track which screen to display in flow */
  page: PropTypes.number,
  /** Hook to change pages in flow */
  setPage: PropTypes.func,
};

export default HowItWorksScreen;