import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import PropTypes from "prop-types";
import { COLORS, DROPDOWN_STYLES } from "../../../constants/theme";
import { COMMON } from "../../../constants/localization";
import { change } from "redux-form";
import { camelCase } from "lodash";

/**
 * Avoids triggerring the blur event if the input has no value.
 * The reason is that the blur send a signal to the Redux form
 * that the field has been touched when it has not. Therefore,
 * a validation message might show up pre-maturely.
 */
const handleOnBlur = (input) => {
  const valid = input?.value;
  if (valid) {
    input.onBlur && input.onBlur();
  }
};

const placeholderCss = () => ({
  color: COLORS.SHARK_GREY,
});

/**
 * Renders a dropdown field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const DropdownField = ({
  id,
  input,
  options,
  isSearchable,
  placeholder,
  selectedValue,
  isClearable,
  menuPlacement,
  isReadOnly,
  meta
}) => {
  const dispatch = useDispatch();
  // For TransFast Indonesia, there is an issue where the country value is coming back as an empty string
  // Also receiverCountry is not registering in redux-form
  // so set the value using meta.initial, then set the field and value in redux form with dispatch(change())
  let value = selectedValue || input.value || (meta?.initial);

  useEffect(() => {
    if (value && input.name && (meta?.form)) {
      dispatch(change(meta.form, input.name, value));
    }
  }, [value]);

  return (
    <div data-cy={camelCase(id)}>
      <Select
        className="dynamic-dropdown"
        {...input}
        isClearable={isClearable}
        styles={{ ...DROPDOWN_STYLES, placeholder: (styles) => ({ ...styles, ...placeholderCss() }), }}
        value={
          options?.length && value
            ? options.filter((o) => o.value === value)[0]
            : ""
        }
        onChange={(e) => input?.onChange(e ? e.value : "")}
        options={options ? options : []}
        isDisabled={!options || !options.length || isReadOnly}
        isSearchable={isSearchable}
        placeholder={placeholder || COMMON.SELECT}
        menuPlacement={menuPlacement}
        onBlur={() => handleOnBlur(input)}
      />
    </div>
  );
};

DropdownField.defaultProps = {
  isClearable: true,
};

DropdownField.propTypes = {
  /** The collection of dropdown options.
   * Each element in this collection should have the structure { value: 'asdf', label: 'asdf' }
   */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  /** The input element behind the dropdown */
  input: PropTypes.object,
  /** Adds an X to the dropdown to allow quickly clearing the value.*/
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  placeholder: PropTypes.string,
  selectedValue: PropTypes.string,
  menuPlacement: PropTypes.string,
  isReadOnly: PropTypes.bool,
  meta: PropTypes.object,
  id: PropTypes.string,
};

export default DropdownField;
