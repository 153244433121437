import React from "react";
import Action from "../Common/Action";
import { COMMON } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";

const FlowContactSupportButton = (props) => {
  return (
    <Action
      iconRight={null}
      title={COMMON.CONTACT_SUPPORT}
      displayType={ACTION_DISPLAY_TYPES.PRIMARY}
      className="btn-lg flowbutton__contact-support"
      onClick={() => (location.href = ROUTES.EXTERNAL.SUPPORT)}
      {...props}
    />
  );
};

export default FlowContactSupportButton;
