import React from "react";
import { PAGES, COMMON } from "../../constants/localization";
import PageBlock from "../../components/Layouts/PageBlock";
import ActionRow from "../../components/Layouts/ActionRow";
import ActionLink from "../../components/Common/ActionLink";
import ENV from "../../constants/environment";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import InputWrapper from "../Pin/InputWrapper";
import PropTypes from "prop-types";

const EmailEntryFormId = "EmailEntryForm";

const EmailEntry = (props) => {
  return (
    <PageBlock id="EmailEntry">
      <PageBlock.Title>
        {PAGES.FORGOT_USERNAME.EMAIL_ENTRY_HEADER}
      </PageBlock.Title>
      <PageBlock.IntroText>
        {PAGES.FORGOT_USERNAME.EMAIL_ENTRY_SUBHEADER}
      </PageBlock.IntroText>
      <PageBlock.Body>
        <form id={EmailEntryFormId} onSubmit={props.submitEmailAddress}>
          <div className="row">
            <div className="col-md-5">
              <InputWrapper className={`${props.hasError ? " has-error" : ""}`}>
                <InputWrapper.Label labelFor="EmailEntry">
                  {PAGES.FORGOT_USERNAME.EMAIL_INPUT_LABEL_TEXT}
                </InputWrapper.Label>
                <input
                  className="form-control"
                  type="email"
                  id="EmailEntry"
                  required
                  data-cy="email-input"
                  onChange={props.updateEmailAddress}
                  value={props.emailAddress}
                />
                {props.hasError && (
                  <div className="text-danger" data-cy="email-input-error">
                    {props.errorMessage}
                  </div>
                )}
                <button
                  type="button"
                  className="input-help-link"
                  data-cy="forgot-email"
                  onClick={props.showForgotEmailModal}
                >
                  {PAGES.FORGOT_USERNAME.FORGOT_EMAIL_LINK_TEXT}
                </button>
              </InputWrapper>
            </div>
          </div>
          <ActionRow>
            <ActionRow.Forward>
              <Action
                title={PAGES.FORGOT_USERNAME.SEND_BUTTON_TEXT}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                dataCy="email-entry-submit-button"
                disabled={!props.validEmail}
                onClick={() => props.checkFormValidity(EmailEntryFormId)}
                className="send-button"
                type="submit"
                loading={props.loading}
              />
            </ActionRow.Forward>
            <ActionRow.Backward>
              <ActionLink
                href={ENV.BASE_NAVIGATOR_URL}
                dataCy="email-entry-back-button"
                text={COMMON.BACK}
              />
            </ActionRow.Backward>
          </ActionRow>
        </form>
      </PageBlock.Body>
    </PageBlock>
  );
};

EmailEntry.propTypes = {
  submitEmailAddress: PropTypes.func,
  hasError: PropTypes.bool,
  updateEmailAddress: PropTypes.func,
  emailAddress: PropTypes.string,
  errorMessage: PropTypes.string,
  showForgotEmailModal: PropTypes.func,
  validEmail: PropTypes.bool,
  checkFormValidity: PropTypes.func,
  loading: PropTypes.bool,
};

export default EmailEntry;
