import React from "react";
import PropTypes from "prop-types";

const FlowScreen = (props) => {
  return (
    <div className={`v2 flow-screen ${props.className || ''}`}>
      <h1 className="flow-screen__header">{props.flowTitle}</h1>
      {props.children}
    </div>
  );
};

FlowScreen.propTypes = {
  className: PropTypes.string,
  flowTitle: PropTypes.string,
  children: PropTypes.any,
};

export default FlowScreen;
