import React from "react";
import PropTypes from "prop-types";

const MobileDate = (props) => {
  return (
    <div className={`mobile-date ${props.hasError ? "has-error" : ""}`.trim()}>
      <input
        type="date"
        placeholder="MM/DD/YYYY"
        className="form-control"
        pattern="\d{4}-\d{2}-\d{2}"
        disabled={props.disabled}
        value={props.value ? props.value : ""}
        onChange={props.handleMobileChange}
        onFocus={props.handleMobileFocus}
        onBlur={props.handleBlur}
      />
    </div>
  );
};

MobileDate.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  value: PropTypes.string,
  handleMobileChange: PropTypes.func,
  handleMobileFocus: PropTypes.func,
  handleBlur: PropTypes.func,
};

export default MobileDate;
