import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/clientRoutes";

/**
 * Displays if a conversation is not found
 * @returns {jsx}
 * @constructor
 */
export const MissingConversation = () => {
  return (
    <div>
      <h3>Conversation Not Found</h3>
      <p>
        Unfortunately we could not find this conversation. Please{" "}
        <Link to={ROUTES.SUPPORT_CONVERSATIONS}>
          return to the support center
        </Link>{" "}
        to try again.
      </p>
    </div>
  );
};

export default MissingConversation;
