import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import ActionLink from "../../components/Common/ActionLink";
import {
  goToNextAuthStep,
  AuthorizeSecurityQuestion,
  OpenAuthSupportTicket,
  setAuthSupportInfo,
} from "../../actions/auth";
import ActionRow from "../../components/Layouts/ActionRow";
import { COMMON, PAGES } from "../../constants/localization";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import {
  ACTION_DISPLAY_TYPES,
  FIELD_TYPES,
  SUPPORT_CATEGORY,
} from "../../constants/enums";
import Action from "../../components/Common/Action";
import ErrorBlock from "../../components/Common/ErrorBlock";
import { ROUTES } from "../../constants/clientRoutes";
import { eventTracker, trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import FaceCheckSupportModal from "../../components/Common/Modals/FaceCheckSupportModal";

class SecurityQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: null,
      showDoNotRememberModal: false,
      loadingSubmitTicket: false,
      supportTicketError: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onDoNotRememberPress = this.onDoNotRememberPress.bind(this);
    this.closeDoNotRememberModal = this.closeDoNotRememberModal.bind(this);
    this.doSubmitTicket = this.doSubmitTicket.bind(this);
    this.onCancelPress = this.onCancelPress.bind(this);
  }

  async onSubmit({ answer }) {
    if (answer instanceof Date) {
      answer = answer.toISOString().split("T")[0];
    }
    const { AuthorizeSecurityQuestion, history, goToNextAuthStep } = this.props;
    this.setState({ errors: null });

    return AuthorizeSecurityQuestion(answer)
      .then(() => {
        trackEvent(EVENTS.LOGIN.SECURITY_QUESTION_SUBMITTED, {
          success: true
        })
        goToNextAuthStep(history);
      })
      .catch((error) => {
        // eventTracker.track("Login_SecurityQuestion_Entered", {
        //   success: "false",
        // });
        if (error && error.response && error.response.data) {
          trackEvent(EVENTS.LOGIN.SECURITY_QUESTION_SUBMITTED, {
            success: false,
            failure_reason: error.response.data.errors[0].errorMessage
          })
          this.setState({ errors: error.response.data.errors });
        } else {
          trackEvent(EVENTS.LOGIN.SECURITY_QUESTION_SUBMITTED, {
            success: false,
            failure_reason: PAGES.AUTH.GENERIC_ERROR_MESSAGE
          })
        }
      });
  }

  doSubmitTicket() {
    const { OpenAuthSupportTicket, setAuthSupportInfo, history } = this.props;
    this.setState({ loadingSubmitTicket: true, supportTicketError: null });

    // Security questions flow will send as "FaceCheck" category per requirement
    OpenAuthSupportTicket(
      SUPPORT_CATEGORY.Facecheck,
      null,
      PAGES.AUTH.SUPPORT.REQUEST_DESCRIPTION
    )
      .then((data) => {
        if (data.supportTicketCreated) {
          eventTracker.track("Login_SecurityQuestion_Support", {
            success: "true",
          });

          return setAuthSupportInfo({
            title: PAGES.AUTH.SUPPORT.SECURITY_QUESTION_TICKET_TITLE,
            subtitle: PAGES.AUTH.SUPPORT.TICKET_SUBMITTED,
            ticketInfo: `${PAGES.AUTH.SUPPORT.TICKET_NO} ${data.ticketNumber}`,
            content: `<p>${PAGES.AUTH.SUPPORT.EMAIL_CONFIRMATION}</p><p><b>${data.email}</b></p>`,
            showCannotAccessEmail: true,
            category: SUPPORT_CATEGORY.Facecheck,
          });
        } else {
          eventTracker.track("Login_SecurityQuestion_Support", {
            success: "false",
          });

          throw new Error(
            data.activeTicketInCategory
              ? PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED
              : PAGES.AUTH.SUPPORT.TICKET_NOT_CREATED
          );
        }
      })
      .then(() => {
        history.push(ROUTES.AUTH.SUPPORT);
      })
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ supportTicketError: error.response.data.errors });
        } else {
          this.setState({
            supportTicketError: [error],
            loadingSubmitTicket: false,
          });
        }
      });
  }

  renderErrors(errors) {
    return errors && <ErrorBlock filterErrors={false} errors={errors} />;
  }

  renderDoNotRememberModal() {
    return (
      <FaceCheckSupportModal
        open={this.state.showDoNotRememberModal}
        title={PAGES.AUTH.SECURITY_QUESTION.DO_NOT_REMEMBER_MODAL_TITLE}
        content={PAGES.AUTH.SECURITY_QUESTION.DO_NOT_REMEMBER_MODAL_CONTENT}
        loading={this.state.loadingSubmitTicket}
        onClose={this.closeDoNotRememberModal}
        onSubmitClick={this.doSubmitTicket}
        onCancelClick={this.closeDoNotRememberModal}
        errors={this.state.supportTicketError}
      />
    );
  }

  closeDoNotRememberModal() {
    this.setState({ showDoNotRememberModal: false, supportTicketError: false });
  }

  onCancelPress() {
    const { history } = this.props;
    history.push(ROUTES.AUTH.LOGIN);
  }

  onDoNotRememberPress() {
    eventTracker.track("Login_SecurityQuestion_Forgot");

    this.setState({ showDoNotRememberModal: true });
  }

  render() {
    const { handleSubmit, submitting, authResult, valid } = this.props;
    const { errors } = this.state;
    return (
      <div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12">
              <h1>{PAGES.AUTH.LOGIN}</h1>
            </div>
          </div>
        </div>
        <div className="v2 page-block">
          {this.renderErrors(errors)}
          <h2 className="spacing-bottom-medium">
            {PAGES.AUTH.SECURITY_QUESTION.TITLE}
          </h2>
          <p className="large spacing-bottom-medium">
            {PAGES.AUTH.SECURITY_QUESTION.INFO}
          </p>
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div className="security-question-form">
              <label>{authResult.question}</label>
              <div className="row">
                <div className="col-xs-12 col-lg-4">
                  <DynamicInputField
                    type={FIELD_TYPES[authResult.dataType.toUpperCase()]}
                    id="answer"
                    max={60}
                    className="question-input form-group"
                    required
                  />
                  <div className="text-right spacing-bottom-medium">
                    <ActionLink
                      classes="inline-block mt-link"
                      clickFunc={this.onDoNotRememberPress}
                      text={PAGES.AUTH.SECURITY_QUESTION.DO_NOT_REMEMBER}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ActionRow>
              <ActionRow.Forward>
                <Action
                  disabled={!valid}
                  title={COMMON.SEND}
                  displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                  loading={submitting}
                  type="submit"
                  dataCy="security-question-submit"
                />
              </ActionRow.Forward>
              <ActionRow.Backward>
                <ActionLink
                  classes="inline-block mt-link"
                  clickFunc={this.onCancelPress}
                  text={COMMON.CANCEL}
                />
              </ActionRow.Backward>
            </ActionRow>
          </form>
        </div>
        {this.renderDoNotRememberModal()}
      </div>
    );
  }
}

SecurityQuestion.propTypes = {
  /** redux-form submit wrapper */
  handleSubmit: PropTypes.func.isRequired,
  valid: PropTypes.bool,
  /** API login action */
  goToNextAuthStep: PropTypes.func.isRequired,
  OpenAuthSupportTicket: PropTypes.func.isRequired,
  setAuthSupportInfo: PropTypes.func.isRequired,
  /** react-router history */
  history: PropTypes.any,
  /** redux-form initialize callback */
  initialize: PropTypes.func,
  submitting: PropTypes.bool,
  /** AuthResult object coming from backend password endpoint */
  authResult: PropTypes.any,
  /** redux auth action */
  AuthorizeSecurityQuestion: PropTypes.func
};

function mapStateToProps(state) {
  return {
    authResult: state.auth.authResult,
  };
}

let securityQuestionForm = reduxForm({
  form: "authSecurityQuestion",
})(SecurityQuestion);

export default connect(mapStateToProps, {
  goToNextAuthStep,
  AuthorizeSecurityQuestion,
  OpenAuthSupportTicket,
  setAuthSupportInfo,
})(securityQuestionForm);
