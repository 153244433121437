import { WAGE_STATEMENTS } from "../constants/actionTypes";
import WageStatementApi from "../api/wageStatements";

/**
 * Get's the wage statements and sets them to state.
 * @returns {Function}
 * @constructor
 */
export function GetWageStatements() {
  return async (dispatch) => {
    try {
      let list = await WageStatementApi.GetWageStatements();
      dispatch({
        type: WAGE_STATEMENTS.RECEIVE_WAGE_STATEMENTS_SUCCESS,
        list,
      });
    } catch (error) {
      dispatch({
        type: WAGE_STATEMENTS.RECEIVE_WAGE_STATEMENTS_FAILURE,
        message:
          error.message ||
          "Unfortunately there was a problem retrieving your statements. Please try again",
      });
    }
  };
}
