import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { COMMON, PAGES } from "../../constants/localization";
import { ROUTES } from "../../constants/clientRoutes";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES, SUCCESS_FIELDS } from "../../constants/enums";
import Lottie from "react-lottie";
import * as checkmark from "../../assets/animations/checkmark.json";
import { GetReceipt } from "../../actions/moneyTransfer";
import Modal from "../../components/Common/Modal";
import TransactionSummary from "../../components/MoneyTransfer/TransactionSummary";
import * as _ from "lodash";
import { eventTracker, EVENTS } from "../../services/eventTracker";

/**
 * Step in the moneytransfer flow to view your completed transaction.
 */
class Success extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transactionHoldModalShown: props.transactionHeld,
    };

    this.handleGetReceipt = this.handleGetReceipt.bind(this);
    this.closeTransactionHoldModal = this.closeTransactionHoldModal.bind(this);
    this.renderTransactionHoldModal =
      this.renderTransactionHoldModal.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({ isAnimationStopped: false, isAnimationPaused: false });
  }

  /**
   * Close the transaction dialog modal.
   */
  closeTransactionHoldModal() {
    this.setState({ transactionHoldModalShown: false });
  }

  /**
   * Renders the dialog modal that informs the user their transaction is on hold. Hidden by default.
   */
  renderTransactionHoldModal(content) {
    const action = {
      title: PAGES.SUPPORT.CONTACT_SUPPORT,
      onClick: () => (window.location.href = ROUTES.EXTERNAL.SUPPORT),
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
    };

    return (
      <Modal
        title={PAGES.MONEY_TRANSFER.SUCCESS.TRANSACTION_HOLD_MODAL_TITLE}
        open={this.state.transactionHoldModalShown}
        onClose={this.closeTransactionHoldModal}
        actions={[action]}
        closeOnOverlayClick={false}
        content={
          content && content.trim() != ""
            ? content
            : PAGES.MONEY_TRANSFER.SUCCESS.TRANSACTION_HOLD_MODAL_TEXT
        }
        small={true}
      />
    );
  }

  handleAnotherTransferClick = () => {
    eventTracker.track(EVENTS.MoneyTransfer_AnotherTransfer);

    window.location.href = ROUTES.EXTERNAL.MONEY_TRANSFER;
  };

  /**
   * Prep redirects
   */
  handleDoneClick = () => {
    window.location.href = ROUTES.EXTERNAL.DASHBOARD;
  };

  /**
   * Build the disclaimers to display
   * @param {*} disclaimers
   */
  renderDisclaimers(disclaimers) {
    return disclaimers.map((d) => {
      return (
        <p key={d.disclaimerId} className="x-small disclaimer">
          <sup>{d.disclaimerId} </sup>
          {d.text}
        </p>
      );
    });
  }

  async handleGetReceipt() {
    eventTracker.track(EVENTS.MoneyTransfer_DownloadReceipt);

    const referenceNumberField = _.find(
      this.props.completedTransfer.displayFields,
      (f) => f.id == SUCCESS_FIELDS.REFERENCE_NUMBER
    );
    const moneyTransferId = this.props.completedTransfer.moneyTransferId;

    try {
      await this.props.GetReceipt(
        moneyTransferId,
        referenceNumberField.formattedValue
      );
    } catch (e) {
      //TODO
    }
  }

  render() {
    const animationOptions = {
      loop: false,
      autoplay: true,
      animationData: checkmark,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className="success">
        <div>
          {!this.props.transactionHeld && (
            <div className="spacing-top-medium">
              <Lottie
                options={animationOptions}
                height={80}
                width={140}
                isStopped={false}
                isPaused={false}
              />
            </div>
          )}

          {!this.props.transactionHeld && (
            <div className="center">
              <h1 className="alt">
                {PAGES.MONEY_TRANSFER.STEP_TITLES.SUCCESS}
              </h1>
              <p
                className="large"
                dangerouslySetInnerHTML={{
                  __html: PAGES.MONEY_TRANSFER.SUCCESS.SUBTITLE,
                }}
              ></p>
            </div>
          )}
        </div>

        {this.props.completedTransfer && (
          <TransactionSummary
            transfer={this.props.completedTransfer}
            onGetReceipt={this.handleGetReceipt}
            transactionHeld={this.props.transactionHeld}
          />
        )}

        <div className="content-block spacing-top-medium">
          <div className="row spacing-top-medium spacing-bottom-medium">
            <div className="col-xs-12 col-sm-4 two col-sm-offset-4">
              <Action
                block
                title={PAGES.MONEY_TRANSFER.SUCCESS.ANOTHER}
                onClick={this.handleAnotherTransferClick}
                displayType={ACTION_DISPLAY_TYPES.TERTIARY}
              />
            </div>
            <div className="col-xs-12 col-sm-4 button-cell one">
              <Action
                block
                title={COMMON.DONE}
                onClick={this.handleDoneClick}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                autoFocus={true}
              />
            </div>
          </div>

          {this.renderDisclaimers(this.props.completedTransfer.disclaimers)}
          {this.renderTransactionHoldModal(this.props.transactionHeldText)}
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  moneyTransferId: PropTypes.number.isRequired,
  completedTransfer: PropTypes.object,
  GetReceipt: PropTypes.func,
  transactionHeld: PropTypes.bool,
  transactionHeldText: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    completedTransfer: state.moneyTransfer.completedTransfer,
    transactionHeld: state.moneyTransfer.completedTransfer.hasTransactionHold,
    transactionHeldText:
      state.moneyTransfer.completedTransfer.transactionHoldText,
  };
}

export default connect(mapStateToProps, {
  GetReceipt,
})(Success);
