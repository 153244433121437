import React, { useState, useEffect } from 'react';
import { reduxForm, getFormValues, InjectedFormProps } from 'redux-form';
import { FORM_NAMES } from '../../constants/forms';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import i18n from '../../i18n/config';
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from '../../constants/enums';
import { ROUTES } from '../../constants/clientRoutes';
import { RootState } from '../../types/redux';
import OnDemandTransferApi from '../../api/onDemandTransfer';
import { GetRateInquiryRequest, GetRateInquiryResponse } from '../../types/api/odt';
import { SetRateInquiryData } from '../../actions/onDemandTransfer';
import { ODTContainerProps } from '../../types/props';
import { DropdownType } from '../../types/fields';
import ENV from "../../constants/environment";

import GenericForm from '../Common/DynamicFields/GenericForm';
import FormBody from '../Common/DynamicFields/FormBody';
import DynamicInputField from '../Common/DynamicFields/DynamicInputField';
import ActionRow from '../Layouts/ActionRow';
import Action from '../Common/Action';
import ActionLink from '../Common/ActionLink';
import useClickExternalLink from '../../hooks/useClickExternalLink';

const AmountPurposeForm: React.FC<ODTContainerProps & InjectedFormProps<{}, ODTContainerProps>> = ({ setShowButtons, setShowErrorModal, setErrorMessage, handleSubmit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const openExternalLinksModal = useClickExternalLink();
  const cardCurrencyCode = useSelector((state: RootState) => state.card.currencyCode);
  const formValues = useSelector((state: RootState) => getFormValues(FORM_NAMES.ODT.AMOUNT_PURPOSE)(state)) as GetRateInquiryRequest;
  const odtData = useSelector((state: RootState) => state.onDemandTransfer);
  const [options, setOptions] = useState<DropdownType>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const checkboxNonDoddFrankLabelUrl = `${ENV.BASE_NAVIGATOR_URL}/Administration/getfile/${odtData.odtTermsAndConditionsDocId}`;

  const checkboxDoddFrankLabel = (
    <p className="font-regular">
      {i18n.t("FORMS.ODT_AMT_PURPOSE.CHECKBOX_PREDEFINED_POP_TEXT")}
    </p>
  )

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation();

    openExternalLinksModal(e, checkboxNonDoddFrankLabelUrl);
  }

  const checkboxNonDoddFrankLabel = (
    <p className="font-regular">
      {i18n.t("FORMS.ODT_AMT_PURPOSE.CHECKBOX_FREE_POP_TEXT")}
      <a
        href={checkboxNonDoddFrankLabelUrl}
        target="_blank"
        rel="noreferrer noopener"
        onClick={(e) => handleClick(e)}
        title={i18n.t("COMMON.EXTERNAL_LINK_TITLE")}
        className="no-underline"
      >
        {i18n.t("FORMS.ODT_AMT_PURPOSE.CHECKBOX_FREE_POP_LINK_TEXT")}
      </a>
      .
    </p>
  )

  useEffect(() => {
    if (odtData.isPOPRequired) {
      const getPurposeOfPayment = async () => {
        try {
          const response = await OnDemandTransferApi.GetPurposeOfPayment(odtData.destinationCountryId);
          const filteredResponse = response.filter((option: { selected: boolean, text: string, value: string }) => option.text !== "Select...");
          const formattedOptions = filteredResponse.map((option: { selected: boolean, text: string, value: string }) => ({ label: option.text, value: option.value }));
          setOptions(formattedOptions);
        } catch (err) {
          if (err?.data?.errors) {
            setErrorMessage(err.data.errors[0].errorMessage);
          } else {
            setErrorMessage(i18n.t("COMMON.ERROR"));
          }
          setLoading(false);
          setShowErrorModal(true);
        }
      }

      getPurposeOfPayment();
    }
  }, []);

  const onSubmit = async () => {
    setLoading(true);

    try {
      const response = await OnDemandTransferApi.GetRateInquiry(
        cardCurrencyCode,
        odtData.destinationCurrency,
        formValues.transferAmount,
        odtData.allocationId
      ) as GetRateInquiryResponse;

      const purposeOfPaymentProperties = {
        purposeOfPaymentCustom: odtData.isPOPRequired ? "" : formValues.purposeOfPayment,
        purposeOfPaymentSelected: odtData.isPOPRequired ? true : false,
        purposeOfPaymentId: odtData.isPOPRequired ? formValues.purposeOfPayment : null
      }

      const payload = {
        ...response[0],
        ...purposeOfPaymentProperties,
        transferAmount: formValues.transferAmount,
      }

      dispatch(SetRateInquiryData(payload));
      setShowButtons(true);
      history.push(ROUTES.ODT.EXCHANGE_RATE);

    } catch (err) {
      if (err?.data?.errors[0]?.errorMessage) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else if (err?.data?.message) {
        setErrorMessage(err.data.message);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setLoading(false);
      setShowErrorModal(true);
    }
  };

  return (
    <GenericForm
      form={FORM_NAMES.ODT.AMOUNT_PURPOSE}
      onSubmit={handleSubmit(onSubmit)}
      onUpdate={(isValid: boolean) => { setIsDisabled(!isValid) }}
    >
      <FormBody className="max-w-360">

        {odtData.isPOPRequired &&
          <DynamicInputField
            label={""}
            type={FIELD_TYPES.SELECT}
            id={'purposeOfPayment'}
            required={true}
            disabled={false}
            options={options}
          />
        }

        {!odtData.isPOPRequired &&
          <DynamicInputField
            label={""}
            type={FIELD_TYPES.TEXTAREA}
            id={'purposeOfPayment'}
            required={true}
            disabled={false}
          />
        }

        <DynamicInputField
          label={i18n.t("FORMS.COMMON.TRANSFER_AMOUNT")}
          type={FIELD_TYPES.CURRENCY}
          id={'transferAmount'}
          required={true}
          disabled={false}
          currencyIso={cardCurrencyCode}
          hideLabel={false}
        />

        <DynamicInputField
          label={odtData.isDoddFrank ? checkboxDoddFrankLabel : checkboxNonDoddFrankLabel}
          type={FIELD_TYPES.BOOL}
          id={"checkTerms"}
          required={true}
          disabled={false}
          currencyIso="USD"
        />

      </FormBody>

      <ActionRow>
        <ActionRow.Forward>
          <Action
            displayType={ACTION_DISPLAY_TYPES.PRIMARY}
            title={i18n.t("COMMON.QUOTE")}
            type={"submit"}
            disabled={isDisabled}
            loading={loading}
          />
        </ActionRow.Forward>
        <ActionRow.Backward>

          <ActionLink
            clickFunc={() => location.href = `${ENV.BASE_NAVIGATOR_URL}${ROUTES.ODT.BANK_ACCOUNT_SELECTION}`}
          >
            <div className="glyphicon glyphicon-menu-left link_icon"></div>
            {i18n.t("COMMON.BACK")}
          </ActionLink>
        </ActionRow.Backward>
      </ActionRow>
    </GenericForm>
  );
}
const form = reduxForm<{}, ODTContainerProps>({
  form: FORM_NAMES.ODT.AMOUNT_PURPOSE,
  enableReinitialize: true,
})(AmountPurposeForm);

export default form;
