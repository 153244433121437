import React, { Component } from "react";
import { COMMON } from "../../../constants/localization";
import ForgotPIN from "../ForgotPIN";
import { connect } from "react-redux";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import CardApi from "../../../api/card";
import PropTypes from "prop-types";
import { eventTracker, EVENTS } from "../../../services/eventTracker";
import {
  RESET_PIN_STEPS,
  FIELD_TYPES,
} from "../../../constants/enums";
import { AddErrors, ClearErrors } from "../../../actions/errors";

class ForgotPINContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
      loading: false,
      loadingContent: false,
      showGenericAlertModal: false,
      showAlertModal: false,
      showLastSixModal: false,
      showExpDateModal: false,
      introText: "",
      fields: [],
      numFields: 0,
      fieldsToSubmit: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.clearError();
    this.getPINResetWorkflow();
  }

  async getPINResetWorkflow() {
    this.setState({ loadingContent: true });

    try {
      let response = await CardApi.GetPINResetWorkflow();
      this.setState({
        loadingContent: false,
        introText: response.forgotPINDescription,
        fields: response.fields,
        numFields: response.fields.length,
      });
    } catch (error) {
      this.setState({
        showGenericAlertModal: true,
      });
    }
  }

  async submitForgotPINForm() {
    if (this.props.cardId != null) {
      try {
        let response = await CardApi.ValidateForgotPINWorkflow(
          this.state.fieldsToSubmit,
          this.props.cardId
        );
        if (response.verified && response.token != null) {
          eventTracker.track(EVENTS.ChangePIN_IDVerificationSubmitted, {
            success: true,
          });
          this.props.setResponseToken(response.token);
          this.props.changeStep(RESET_PIN_STEPS.CHANGE_PIN);
        }
      } catch (error) {
        eventTracker.track(EVENTS.ChangePIN_IDVerificationSubmitted, {
          success: false,
        });
        let {
          data: { errors },
        } = error;
        this.props.addError(errors);
        this.props.checkTransferLock();
        if (error.status != 500) {
          this.setState({
            loading: false,
          });
        } else {
          this.setState({
            showGenericErrorModal: true,
            loading: false,
          });
        }
      }
    } else {
      eventTracker.track(EVENTS.ChangePIN_IDVerificationSubmitted, {
        success: false,
      });
      this.setState({
        loading: false,
        showGenericAlertModal: true,
      });
    }
  }

  /**
   * Searches errors array for a specific error code
   * @param errorCode
   * @returns error message
   */
  findError = (errorCode) => {
    if (this.state.errors.length) {
      let errorQuery = this.state.errors.find((error) => {
        return error.errorCode == errorCode;
      });

      if (errorQuery) {
        return errorQuery.errorMessage;
      }
    }
  };

  handleSubmit() {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          errors: [],
        },
        () => {
          this.submitForgotPINForm();
        }
      );
    }
  }

  closeGenericModal = () => {
    this.setState({
      showGenericAlertModal: false,
    });
  };

  inputUpdate = (inputState) => {
    let { fieldsToSubmit } = this.state;
    let { hasError, serviceProviderFieldId, datatype, value, valid } =
      inputState;

    for (let i = 0; i < fieldsToSubmit.length; i++) {
      if (fieldsToSubmit[i].serviceProviderFieldId == serviceProviderFieldId) {
        fieldsToSubmit.splice(i, 1);
        break;
      }
    }
    if (!hasError && valid) {
      if (datatype == FIELD_TYPES.DATE) {
        inputState.value = new Date(value).toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      }
      fieldsToSubmit.push(inputState);
    }

    this.setState({
      fieldsToSubmit,
    });
  };

  render() {
    return (
      <React.Fragment>
        <ForgotPIN
          introText={this.state.introText}
          fields={this.state.fields}
          onSubmit={this.handleSubmit}
          loading={this.state.loading}
          loadingContent={this.state.loadingContent}
          isTransferLocked={this.props.isTransferLocked}
          invalid={this.state.fieldsToSubmit < this.state.fields}
          inputUpdate={this.inputUpdate}
        />
        <AlertModal
          open={this.state.showGenericAlertModal}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
          onClose={this.closeGenericModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forgotPINForm: state.form.ForgotPIN,
    cardId: state.card.id,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addError: (errors) => {
      dispatch(AddErrors(errors));
    },
    clearError: () => {
      dispatch(ClearErrors());
    },
  };
}

ForgotPINContainer.propTypes = {
  forgotPINForm: PropTypes.any,
  cardId: PropTypes.number,
  response: PropTypes.string,
  setResponseToken: PropTypes.func,
  changeStep: PropTypes.func,
  errors: PropTypes.object,
  clearError: PropTypes.func,
  addError: PropTypes.func,
  isTransferLocked: PropTypes.bool,
  checkTransferLock: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPINContainer);
