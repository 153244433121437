import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  GetSupportCategories,
  StartNewConversation,
} from "../../actions/support";
import NewConversationForm from "../../components/Support/NewConversationForm";
import SpinningLoader from "../../components/Common/SpinningLoader";
import Conversation from "./Conversation";
import { COMMON, PAGES } from "../../constants/localization";
import Modal from "../../components/Common/Modal";
import ActionRow from "../../components/Layouts/ActionRow";
import { ACTION_DISPLAY_TYPES } from "../../constants/enums";
import Action from "../../components/Common/Action";
import ActionLink from "../../components/Common/ActionLink";

class NewConversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      showActiveTicketModal: false,
      showConversation: false,
      selectedCategoryId: null,
    };
  }

  componentDidMount() {
    if (!this.props.categories || this.props.categories.length === 0) {
      this.props.GetSupportCategories();
    }
  }

  /**
   * Submits new conversation request to api.
   * If the conversation  in props is an active conversation,
   * A modal will display before redirecting to the active conversation
   * @param {*} categoryId The categoryID
   */
  onSubmit = async () => {
    this.setState({ submitting: true });
    await this.props.StartNewConversation(this.state.selectedCategoryId);
    this.setState({ submitting: false });

    if (!this.props.error) {
      this.setState({
        showConversation: true,
      });
      if (this.props.isActiveConversation) {
        this.setState({
          showActiveTicketModal: true,
        });
      }
    }
  };

  onSelectedCategoryChange = (selectedCategoryId) => {
    this.setState({ selectedCategoryId });
  };

  /**
   * Sets state of showActiveTicketModal to false
   */
  onCloseActiveTicketModal = () => {
    this.setState({
      showActiveTicketModal: false,
    });
  };

  onBackPress = () => {
    this.props.history.goBack();
  };

  render() {
    if (this.props.error) {
      return <div className="error">{this.props.error.message}</div>;
    }

    const { submitting, selectedCategoryId } = this.state;

    return (
      <div>
        <SpinningLoader show={this.state.submitting} />
        {!this.state.showConversation && (
          <div className="v2">
            <NewConversationForm
              onSelectedCategoryChange={this.onSelectedCategoryChange}
              submitting={this.state.submitting}
              categories={this.props.categories}
              selectedCategoryId={this.state.selectedCategoryId}
            />
            <div className="container">
              <ActionRow>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    onClick={this.onSubmit}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    loading={submitting}
                    disabled={selectedCategoryId === null}
                    className="btn-lg"
                    type="submit"
                  />
                </ActionRow.Forward>
                <ActionRow.Backward>
                  <ActionLink
                    classes="inline-block mt-link btn-lg"
                    clickFunc={this.onBackPress}
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
              </ActionRow>
            </div>
          </div>
        )}
        {this.state.showConversation && (
          <Conversation
            conversationId={this.props.conversationId}
            history={this.props.history}
          />
        )}
        {this.state.showActiveTicketModal && (
          <Modal
            content={PAGES.SUPPORT.ACTIVE_TICKET_TEXT}
            title={PAGES.SUPPORT.ACTIVE_TICKET_HEADER}
            open={this.state.showActiveTicketModal}
            onClose={this.onCloseActiveTicketModal}
            small={true}
          />
        )}
      </div>
    );
  }
}

NewConversation.propTypes = {
  categories: PropTypes.array.isRequired,
  GetSupportCategories: PropTypes.func.isRequired,
  error: PropTypes.object,
  StartNewConversation: PropTypes.any,
  history: PropTypes.any,
  conversationId: PropTypes.string,
  isActiveConversation: PropTypes.bool,
};

function mapStateToProps(state) {
  let recentConversationId = state.support.conversations[0]
    ? state.support.conversations[0].conversationId
    : "0";

  return {
    categories: state.support.categories,
    conversationId: state.support.activeConversation || recentConversationId,
    isActiveConversation: state.support.activeConversation > 0,
    error: state.support.errors,
  };
}

export default connect(mapStateToProps, {
  StartNewConversation,
  GetSupportCategories,
})(NewConversation);
