import React from "react";
import PropTypes from "prop-types";
import { renderCounter } from "./DynamicFieldsHelpers";

/**
 * Keeps track of the input value. Used to render the counter.
 * @param {*} event Event object of the change
 */
const handleValueChange = (event, input) => {
  input.onChange(event.target.value);
};

/**
 * Renders a text input with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
const TextField = ({ input, maxLength, isSensitive, autoFocus, disabled, ...props }) => (
  <div>
    <input
      id={props.id}
      autoFocus={autoFocus}
      {...input}
      onChange={(e) => handleValueChange(e, input)}
      maxLength={maxLength}
      type={isSensitive != null ? "password" : "text"}
      className="form-control"
      disabled={disabled}
    />

    {renderCounter(maxLength, input.value.length)}
  </div>
);

TextField.propTypes = {
  /** The input element */
  input: PropTypes.any,
  /** The maximum length allowed in the text box. */
  maxLength: PropTypes.number,
  isSensitive: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string,
};

export default TextField;
