import React from "react";
import PropTypes from "prop-types";
import PageBlock from "../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../constants/localization";
import ActionRow from "../../components/Layouts/ActionRow";
import FlowSendButton from "../../components/Buttons/FlowSendButton";
import ActionLink from "../../components/Common/ActionLink";
import InputWrapper from "../Pin/InputWrapper";
import ContactSupportModal from "../../components/Common/Modals/ContactSupportModal";
import { eventTracker, EVENTS } from "../../services/eventTracker";

const EmailEntryFormId = "EmailAddressEntryForm";

class EmailAddressEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsValid: false,
      showForgotEmailModal: false,
      scrollTop: false,
    };
  }

  /**
   * Toggles display of ForgotEmail modal
   */
  toggleForgotEmailModal = () => {
    let { showForgotEmailModal } = this.state;
    if (!showForgotEmailModal) {
      eventTracker.track(EVENTS.ForgotUsername_EmailUnknown);
    }
    this.setState({
      showForgotEmailModal: !showForgotEmailModal,
    });
  };

  /**
   * Gets errors for specified field
   * @param {string} fieldId - Id of field to
   * get errors for
   */
  getFieldErrors = (fieldId) => {
    let { errors } = this.props;
    if (!errors) return [];
    let errorsResult = [];
    if (errors.length > 0) {
      let fieldErrors = errors.filter((error) => error.fieldId == fieldId);
      fieldErrors.map((error, index) => {
        errorsResult.push(<div key={index}>{error.errorMessage}</div>);
      });
    }
    return errorsResult;
  };

  render() {
    const emailFieldErrors = this.getFieldErrors("EmailInput");
    return (
      <React.Fragment>
        <PageBlock id="email-address-entry" showScreenErrors>
          <PageBlock.Title>
            {PAGES.FORGOT_PASSWORD.EMAIL_ENTRY.PAGE_BLOCK_TITLE}
          </PageBlock.Title>
          <PageBlock.IntroText>
            {PAGES.FORGOT_PASSWORD.EMAIL_ENTRY.PAGE_BLOCK_TEXT}
          </PageBlock.IntroText>
          <PageBlock.Body>
            <form
              id={EmailEntryFormId}
              onSubmit={this.props.submitEmailAddress}
            >
              <div className="row username-row">
                <div className="col-sm-5">
                  <InputWrapper>
                    <InputWrapper.Label labelFor="UsernameInput">
                      {PAGES.FORGOT_PASSWORD.EMAIL_ENTRY.USERNAME_LABEL_TEXT}
                    </InputWrapper.Label>
                    <input
                      type="text"
                      data-cy="username-input"
                      className="form-control"
                      id="UsernameInput"
                      required
                      onChange={this.props.updateUsername}
                      value={this.props.username}
                    />
                  </InputWrapper>
                </div>
              </div>
              <div className="row email-row">
                <div className="col-sm-5">
                  <InputWrapper
                    className={`${
                      emailFieldErrors.length > 0 ? " has-error" : ""
                    }`}
                  >
                    <InputWrapper.Label labelFor="EmailInput">
                      {PAGES.FORGOT_PASSWORD.EMAIL_ENTRY.EMAIL_LABEL_TEXT}
                    </InputWrapper.Label>
                    <input
                      type="email"
                      data-cy="email-input"
                      className="form-control"
                      required
                      id="EmailInput"
                      onChange={(e) => this.props.updateEmailAddress(e, false)}
                      onBlur={(e) => this.props.updateEmailAddress(e, true)}
                      value={this.props.emailAddress}
                    />
                    {emailFieldErrors.length > 0 && (
                      <div
                        className="text-danger input-errors"
                        data-cy="email-input-error"
                      >
                        {emailFieldErrors}
                      </div>
                    )}
                    <button
                      className="input-help-link"
                      data-cy="forgot-email"
                      type="button"
                      onClick={this.toggleForgotEmailModal}
                    >
                      {PAGES.FORGOT_PASSWORD.EMAIL_ENTRY.FORGOT_EMAIL_LINK_TEXT}
                    </button>
                  </InputWrapper>
                </div>
              </div>
              <ActionRow>
                <ActionRow.Forward>
                  <FlowSendButton
                    iconRight={null}
                    dataCy="email-address-entry-submit"
                    disabled={
                      this.props.username.trim() == "" ||
                      this.props.emailAddress.trim() == ""
                    }
                    loading={this.props.loading}
                    onClick={() =>
                      this.props.checkFormValidity(EmailEntryFormId)
                    }
                  />
                </ActionRow.Forward>
                <ActionRow.Backward>
                  <ActionLink
                    clickFunc={this.props.goBack}
                    dataCy="email-address-entry-back-button"
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
              </ActionRow>
            </form>
          </PageBlock.Body>
        </PageBlock>
        <ContactSupportModal
          open={this.state.showForgotEmailModal}
          title={PAGES.FORGOT_PASSWORD.MODALS.FORGOT_EMAIL_MODAL_TITLE}
          content={PAGES.FORGOT_PASSWORD.MODALS.FORGOT_EMAIL_MODAL_CONTENT}
          onClose={this.toggleForgotEmailModal}
          hasSupport={false}
        />
      </React.Fragment>
    );
  }
}

EmailAddressEntry.propTypes = {
  submitEmailAddress: PropTypes.func,
  updateUsername: PropTypes.func,
  username: PropTypes.string,
  updateEmailAddress: PropTypes.func,
  emailAddress: PropTypes.string,
  loading: PropTypes.bool,
  checkFormValidity: PropTypes.func,
  goBack: PropTypes.func,
  errors: PropTypes.array,
};

export default EmailAddressEntry;
