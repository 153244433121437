import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import ENV from "../../constants/environment";

const AlreadyEnrolledModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => props.handleClose({ show: false, bodyMessage: "" })}
      dialogClassName="enrollment-form styled-modal v2"
    >
      <Modal.Header closeButton>
        <Modal.Title>Already enrolled</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.bodyMessage}</Modal.Body>
      <Modal.Footer>
        <a href={ENV.BASE_NAVIGATOR_URL} className="btn btn-primary medium btn-lg">
          Log in
        </a>
      </Modal.Footer>
    </Modal>
  );
};

AlreadyEnrolledModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.any,
  bodyMessage: PropTypes.string,
};

export default AlreadyEnrolledModal;
