import React from "react";
import PropTypes from "prop-types";

/**
 * Builds out data-cy attribute value. If ID is passed in
 * it is appanded to the rootname. Otherwise the element
 * has a default data-cy value matching the rootname
 * @param {string} rootName - Root Name of element, acts as default
 * @param {string} id - ID of element
 */
const CyBuilder = (rootName, id) => {
  const idStr = id ? `-${id}` : ``;
  return `${rootName}${idStr}`;
};

const PageBlockIntroText = ({ children, id }) =>
  children ? (
    <div
      className="flow-page-block__intro-text"
      id={id}
      data-cy={CyBuilder("page-block-intro-text", id)}
    >
      {children}
    </div>
  ) : null;

PageBlockIntroText.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
};

export default PageBlockIntroText;
