import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/clientRoutes";

const DevRoutes = () => {
  return (
    <div className="row">
      <div className="col-xs-12">
        <ul className="unstyled">
          <li>
            <Link to={ROUTES.SUPPORT_CONVERSATIONS}>Support</Link>
          </li>
          <li>
            <Link to={ROUTES.MONEY_TRANSFER}>Money Transfer</Link>
          </li>
          <li>
            <Link to={ROUTES.SETTINGS.PERSONAL_DETAILS}>Settings</Link>
          </li>
          <li>
            <Link to={ROUTES.COMPONENT_LIBRARY}>Component Library</Link>
          </li>
          <li>
            <Link to={ROUTES.SETTINGS.FORGOT_USERNAME}>Forgot Username</Link>
          </li>
          <li>
            <Link to={ROUTES.SETTINGS.FORGOT_PASSWORD}>Forgot Password</Link>
          </li>
          <li>
            <Link to={ROUTES.SETTINGS.CHANGE_CURRENT_PASSWORD}>
              Change Current Password
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

DevRoutes.propTypes = {};

export default DevRoutes;
