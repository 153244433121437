import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES, SUPPORT_CATEGORY } from "../../constants/enums";
import chrome from "../../assets/images/chrome.png";
import safari from "../../assets/images/safari.svg";
import edge from "../../assets/images/microsoft-edge.svg";
import { osName } from "react-device-detect";
import GetApp from "../../components/Common/GetApp";
import { PAGES } from "../../constants/localization";
import { OpenAuthSupportTicket, setAuthSupportInfo } from "../../actions/auth";
import { eventTracker } from "../../services/eventTracker";
import { ROUTES } from "../../constants/clientRoutes";
import FaceCheckSupportModal from "../../components/Common/Modals/FaceCheckSupportModal";
class UnsupportedBrowser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSupportModal: false,
      supportTicketError: null,
      loadingSubmitTicket: false,
    };

    this.closeSupportModal = this.closeSupportModal.bind(this);
    this.openSupportModal = this.openSupportModal.bind(this);
    this.doSubmitTicket = this.doSubmitTicket.bind(this);
  }

  closeSupportModal() {
    this.setState({ showSupportModal: false });
  }

  openSupportModal() {
    eventTracker.track("UnsupportedBrowser_GetHelp");
    this.setState({ showSupportModal: true });
  }

  doSubmitTicket() {
    const { OpenAuthSupportTicket, setAuthSupportInfo, history } = this.props;
    this.setState({ loadingSubmitTicket: true, supportTicketError: null });

    OpenAuthSupportTicket(
      SUPPORT_CATEGORY.Facecheck,
      null,
      PAGES.FACECHECK.SUPPORT_REQUEST_BROWSER
    )
      .then((data) => {
        if (data.supportTicketCreated) {
          eventTracker.track("UnsupportedBrowser_Support", { success: "true" });

          return setAuthSupportInfo({
            title: PAGES.AUTH.SUPPORT.FACECHECK_SUPPORT_TICKET,
            subtitle: PAGES.AUTH.SUPPORT.HIGH_PRIORITY_TITLE,
            ticketInfo: `${PAGES.AUTH.SUPPORT.TICKET_NO} ${data.ticketNumber}`,
            content: `<p>${PAGES.AUTH.SUPPORT.EMAIL_CONFIRMATION}</p><p><b>${data.email}</b></p>`,
            showCannotAccessEmail: true,
            category: SUPPORT_CATEGORY.Facecheck,
          });
        } else {
          eventTracker.track("UnsupportedBrowser_Support", {
            success: "false",
          });

          throw new Error(
            data.activeTicketInCategory
              ? PAGES.AUTH.SUPPORT.TICKET_ALREADY_OPENED
              : PAGES.AUTH.SUPPORT.TICKET_NOT_CREATED
          );
        }
      })
      .then(() => history.push(ROUTES.AUTH.SUPPORT))
      .catch((error) => {
        if (error && error.response && error.response.data) {
          this.setState({ supportTicketError: error.response.data.errors });
        } else {
          this.setState({
            supportTicketError: [error],
            loadingSubmitTicket: false,
          });
        }
      });
  }

  renderSupportModal() {
    return (
      <FaceCheckSupportModal
        open={this.state.showSupportModal}
        loading={this.state.loadingSubmitTicket}
        onClose={this.closeSupportModal}
        onSubmitClick={this.doSubmitTicket}
        onCancelClick={this.closeSupportModal}
        errors={this.state.supportTicketError}
      />
    );
  }

  render() {
    return (
      <React.Fragment>
        <GetApp trackEvent="UnsupportedBrowser_App" />
        <div className=" container unsupported-browser">
          <div className="row">
            <div className="col-sm-12">
              <h1>{PAGES.UNSUPPORTED.TITLE}</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="v2 page-block">
                <h2>{PAGES.UNSUPPORTED.SUBTITLE}</h2>
                <p>{PAGES.UNSUPPORTED.DESCRIPTION}</p>
                <ul className="browser-list">
                  {osName !== "iOS" && (
                    <li>
                      <a
                        onClick={() =>
                          eventTracker.track("UnsupportedBrowser_Chrome")
                        }
                        href="https://www.google.com/chrome/"
                      >
                        <img src={chrome} alt="Chrome Link" />
                        {PAGES.UNSUPPORTED.CHROME}
                      </a>
                    </li>
                  )}
                  {osName !== "Android" && (
                    <li>
                      <a
                        onClick={() =>
                          eventTracker.track("UnsupportedBrowser_Safari")
                        }
                        href="https://www.apple.com/safari/"
                      >
                        <img src={safari} alt="Safari Link" />
                        {PAGES.UNSUPPORTED.SAFARI}
                      </a>
                    </li>
                  )}
                  {osName !== "iOS" && (
                    <li>
                      <a
                        onClick={() =>
                          eventTracker.track("UnsupportedBrowser_Edge")
                        }
                        href="https://www.microsoft.com/edge"
                      >
                        <img src={edge} alt="Edge Link" />
                        {PAGES.UNSUPPORTED.EDGE}
                      </a>
                    </li>
                  )}
                </ul>
                <hr />
                <p className="small">
                  {PAGES.UNSUPPORTED.GET_HELP_DESCRIPTION}
                </p>
                <Action
                  displayType={ACTION_DISPLAY_TYPES.SECONDARY}
                  onClick={this.openSupportModal}
                  title={PAGES.UNSUPPORTED.GET_HELP}
                />
                <p className="small spacing-top-medium">
                  {PAGES.UNSUPPORTED.FOOTNOTE}
                  <a
                    onClick={() =>
                      eventTracker.track("Unsupportedbrowser_FaceCheckLink")
                    }
                    href="https://www.brightwell.com/facecheck#FAQ"
                  >
                    {PAGES.UNSUPPORTED.FOOTNOTE_LINK}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          {this.renderSupportModal()}
        </div>
      </React.Fragment>
    );
  }
}
UnsupportedBrowser.propTypes = {
  history: PropTypes.any.isRequired,
  OpenAuthSupportTicket: PropTypes.func.isRequired,
  setAuthSupportInfo: PropTypes.func.isRequired,
};

export default connect(null, {
  OpenAuthSupportTicket,
  setAuthSupportInfo,
})(UnsupportedBrowser);
