import React, { useEffect, useState } from "react";
import i18n from "../../i18n/config";
import { BANK_ACCOUNT_FORM_NAMES, FORM_NAMES } from "../../constants/forms";
import { FIELD_TYPES } from "../../constants/enums";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import BankAccountsApi from "../../api/bankAccounts";
import useErrorModal from "../../hooks/useErrorModal";
import { reduxForm, InjectedFormProps } from "redux-form";
import { BankAccountChildProps } from "../../types/bankAccounts";
import { CountryType } from "../../types/api/catalog";
import { DropdownType } from "../../types/fields";
import { useHistory } from "react-router-dom";

import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { ROUTES } from "../../constants/clientRoutes";
import { SetSelectedCountry } from "../../actions/bankAccounts";

const CountrySelection: React.FC<BankAccountChildProps & InjectedFormProps<{}, BankAccountChildProps>> = (props: BankAccountChildProps) => {
  const { setTitle, setSubTitle, setBackButton, setForwardButton } = props;
  const dispatch = useDispatch();
  const openErrorModal = useErrorModal();
  const history = useHistory();
  const userGuid = useSelector((state: RootState) => state.user.profile.userGuid);
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [options, setOptions] = useState<DropdownType[]>([]);
  const forwardButtonInitialState = { text: i18n.t("COMMON.NEXT"), clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.CURRENCY), disabled: true };

  useEffect(() => {
    setTitle(i18n.t("PAGES.BANK_ACCOUNTS.COUNTRY_SELECTION.TITLE"));
    setSubTitle(i18n.t("PAGES.BANK_ACCOUNTS.COUNTRY_SELECTION.SUBTITLE"));
    setBackButton({
      text: i18n.t("COMMON.BACK"),
      clickFunc: () => history.push(ROUTES.BANKS_ACCOUNTS.INDEX)
    });
    setForwardButton(forwardButtonInitialState);

  }, []);

  useEffect(() => {
    if (userGuid) {
      getCountries();
    }

  }, [userGuid]);

  const getCountries = async () => {
    try {
      const response = await BankAccountsApi.GetCountriesCurrencies(userGuid) as CountryType[];
      const countryOptions = response.map((country: CountryType) => { return { label: country.name, value: country.isoCode } });
      setOptions(countryOptions);
      setCountries(response);
    } catch (err) {
      openErrorModal(err);
    }
  }

  const handleChange = (value: string) => {
    const disabled = value === "";
    setForwardButton({
      ...forwardButtonInitialState,
      disabled
    });
    const selectedCountry = countries.find((country: CountryType) => country.isoCode === value);
    if (selectedCountry) {
      dispatch(SetSelectedCountry(selectedCountry.currencies, selectedCountry.name));
    }
  }

  return (
    <form className="spacing-top-medium">
      <div className="max-w-360">
        <DynamicInputField
          id={BANK_ACCOUNT_FORM_NAMES.destinationCountry}
          type={FIELD_TYPES.SELECT}
          label={i18n.t("PAGES.BANK_ACCOUNTS.COUNTRY_SELECTION.LABEL")}
          required={true}
          focus={true}
          options={options}
          onChange={(value: string) => handleChange(value)}
        />
      </div>
    </form>

  );
}

export default reduxForm<{}, BankAccountChildProps>({
  form: FORM_NAMES.BANK_ACCOUNTS,
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true
})(CountrySelection);