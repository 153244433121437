import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import intersectionBy from "lodash/intersectionBy";
import searchIcon from "../../assets/images/icons/Search.svg";
import { COMMON } from "../../constants/localization";
import { COLORS, DROPDOWN_STYLES } from "../../constants/theme";
import { camelCase } from "lodash";

/**
 * CSS for adding the magnifying glass
 * @returns object
 */
const magnifyingGlassCss = () => ({
  alignItems: "center",
  display: "flex",
  color: COLORS.INTERACTIVE_BLUE, // This needs to get moved into a common styles file.
  "font-weight": "bold",
  border: 0,
  ":before": {
    background: `url("${searchIcon}")`,
    color: COLORS.SHARK_GREY,
    width: 20,
    height: 20,
    "margin-right": 5,
    content: '" "',
    display: "block",
  },
});

const placeholderCss = () => ({
  color: COLORS.SHARK_GREY, // This needs to get moved into a common styles file.
  display: "flex",
  marginLeft: 30, // This is the exact size of the magnifying image
});

/**
 * Select input with filterable options and support for recently selected
 *
 * @param props
 * @returns {*}
 */
const FilteredDropDown = (props) => {
  const { recentOptions, fullOptions, name, id, disabled, input, ...custom } =
    props;

  let options = fullOptions;

  if (recentOptions && recentOptions.length > 0) {
    options = [
      {
        label: COMMON.RECENT_SELECTIONS,
        options: intersectionBy(recentOptions, fullOptions, "value"),
      },
      {
        label: props.allOptionsLabel,
        options: fullOptions,
      },
    ];
  }

  const selectedValue = props.input.value
    ? fullOptions.filter((p) => p.value == props.input.value)[0]
    : null;

  return (
    <div data-cy={camelCase(props.dataCy)}>
      <Select
        {...input}
        {...custom}
        id={id}
        className="filtered-dropdown"
        styles={{
          ...DROPDOWN_STYLES,
          input: (styles) => ({ ...styles, ...magnifyingGlassCss() }),
          singleValue: (styles) => ({ ...styles, ...magnifyingGlassCss() }),
          placeholder: (styles) => ({ ...styles, ...placeholderCss() }),
        }}
        name={name}
        isDisabled={disabled}
        options={options}
        isClearable
        isOptionDisabled={(option) => option.disabled}
        value={selectedValue}
        onBlur={(e) => e.preventDefault()}
        onChange={(option) => input.onChange(option ? option.value : null)}
      />
    </div>
  );
};

FilteredDropDown.propTypes = {
  /** Recently selected options */
  recentOptions: PropTypes.array,
  /** Main list of options [{label, value}] */
  fullOptions: PropTypes.array.isRequired,
  /** The label for all options if grouping */
  allOptionsLabel: PropTypes.string,
  /** Name attribute */
  name: PropTypes.string,
  /** id attribute */
  id: PropTypes.any,
  /** Disable toggle */
  disabled: PropTypes.bool,
  /** input field */
  input: PropTypes.object,
  /** Default value for the dropdown */
  selectedValue: PropTypes.any,
  /** Data attribute to use for automated ui testing selector */
  dataCy: PropTypes.string,
};

FilteredDropDown.defaultProps = {
  recentOptions: [],
  fullOptions: [],
  allOptionsLabel: "All",
};

export default FilteredDropDown;
