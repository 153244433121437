import React from 'react';
import "../../../assets/styles/main.scss";
import AuthenticatorInstructions from './AuthenticatorInstructions';

const WebviewWrapper = () => {
  return (
    <div className='v2 webview-styling'>
      <AuthenticatorInstructions />
    </div>
  );
}

export default WebviewWrapper;