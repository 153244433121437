import React, { useState } from "react";
import { useSelector } from "react-redux";
import CreateUserForm from "../../components/Enrollment/Forms/CreateUserForm";
import SessionEndModal from "../../components/Enrollment/SessionEndModal";
import SpinningLoader from "../../components/Common/SpinningLoader";
import PagePathConstants from "../../constants/page-numbers";

/**
 * Create User Page, second page of enrollment
 */
const CreateUser = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  if (currentPage !== PagePathConstants.CREATE_USER) {
    return null;
  }

  return (
    <div className="v2">
      <SpinningLoader show={showSpinner} />

      <CreateUserForm
        setShowSpinner={setShowSpinner}
      />
      <SessionEndModal />
    </div>
  );
};

CreateUser.displayName = "CreateUserComponent";
export default CreateUser;
