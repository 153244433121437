import React from 'react';
import PropTypes from 'prop-types';
import { ICONS } from '../../constants/enums';
import AlertService from '../../services/alertService';
import Alert from '../Common/Alerts/Alert';
import { generateUniqueInteger } from '../../services/formatter';

const Alerts = ({ card }) => {
  return (
    <React.Fragment>
      {card?.notifications && 
        card.notifications.map((notification) => {
          const message = AlertService.ReplaceDirectives(notification.message, notification.directives);
          return (
            <Alert
              key={generateUniqueInteger()}
              alertClasses={"alert-info flex-row align-items-start"}
              message={message}
              isHTML={true}
              icon={ICONS.INFO}
              iconClasses={"spacing-right-tiny"}
            />
          )
        })
      }
    </React.Fragment>
  );
}

Alerts.propTypes = {
  /** user feature flag data */
  featureFlags: PropTypes.array,
  /** card data */
  card: PropTypes.object,
}

export default Alerts;