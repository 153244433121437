import React from "react";
import PropTypes from "prop-types";
import Modal from "../Modal";
import { IconFont } from "../IconFont";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../../constants/iconfont";

const AlertTitle = (title) => {
  return (
    <React.Fragment>
      <IconFont icon={ICON_FONT_CLASS.ALERT} type={ICON_TYPE.ALERT} /> {title}
    </React.Fragment>
  );
};

const AlertModal = (props) => {
  return (
    <Modal
      className={props.className}
      open={props.open}
      content={props.content}
      title={AlertTitle(props.title)}
      actions={props.actions}
      onClose={props.onClose}
    />
  );
};

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  open: PropTypes.bool.isRequired,
  actions: PropTypes.array,
  onClose: PropTypes.func,
  className: PropTypes.string,
};

export default AlertModal;
