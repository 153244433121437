import React, { Component } from "react";
import { Field } from "redux-form";
import PropTypes from "prop-types";
import { FIELD_TYPES } from "../../../constants/enums";
import { COMMON } from "../../../constants/localization";
import TextField from "./TextField";
import IntegerField from "./IntegerField";
import DecimalField from "./DecimalField";
import DropdownField from "./DropdownField";
import PhoneNumberField from "./PhoneNumberField";
import DateField from "./DateField";
import BooleanField from "./BooleanField";
import PinField from "./PinField";
import CurrencyField from "./CurrencyField";
import Label from "../Label";
import TextAreaField from "./TextAreaField";
import FileField from "./FileField";

/**
 * Renders a decimal field with appropriate validation.
 * Should be used as a component of a Redux form Field component.
 */
class DynamicInputField extends Component {
  constructor(props) {
    super(props);

    this.validate = this.validate.bind(this);
  }

  /**
   * Validates that a value is set if required.
   * For @see DateField, checks that the date is valid
   */
  validate = (value) => {
    if (this.props.required && (!value || value.length === 0)) {
      return COMMON.REQUIRED;
    }

    if (this.props.type === FIELD_TYPES.DATE && value == "Invalid Date") {
      return COMMON.INVALID_DATE;
    }

    if (this.props.type === FIELD_TYPES.PHONE_NUMBER) {
      if (value?.country?.id && !value.number) {
        return COMMON.VALIDATION_ERRORS.PHONE_NUMBER_REQUIRED;
      } else if (value?.number && !value?.country?.id) {
        return COMMON.VALIDATION_ERRORS.COUNTRY_CODE_REQUIRED;
      }
    }

    if (this.props.max && value && value.length > this.props.max) {
      return COMMON.VALIDATION_ERRORS.MAX_LENGTH_EXCEEDED;
    }

    return undefined;
  };

  /**
   * Renders the appropriate field based on the type.
   */
  renderField = (props) => {
    let field;
    let hasError =
      props.meta.touched && props.meta.error && props.meta.error.length > 0;
    const max = this.props.max === 0 ? 999 : this.props.max;

    /**
     * Error handling for PhoneFieldType as props.meta.touched is not registered with onBlur event
     * So hasError is never set to true
     */
    if (props.type === FIELD_TYPES.PHONE_NUMBER) {
      if (props.input.value?.country?.id && !props.input.value?.number) {
        hasError = true;
      } else if (props.input.value?.number && !props.input.value?.country?.id) {
        hasError = true;
      }
    }

    /**
     * Possible for user to have account details (like street address) that is 50 chars long
     * That exceeds the max length of the field that MG or TF allow, so we want to show a validation error
     */
    if (this.props.max && props?.input?.value && props.input.value.length > this.props.max) {
      hasError = true;
    }

    if (this.props.validationError && this.props.validationError.length > 0) {
      // validationError array isn't removed from page fields until resubmitting validate
      // If user has touched/changed the field, don't add hasError class
      if (!props.meta.touched) {
        hasError = true;
      }
    }

    const formGroupCssClasses =
      props.className != null
        ? `${props.className} ${!hasError || "has-error"}`
        : "form-group " + (!hasError || "has-error");

    switch (props.type) {
      case FIELD_TYPES.INTEGER:
        field = <IntegerField {...props} />;
        break;
      case FIELD_TYPES.DECIMAL:
        field = <DecimalField {...props} />;
        break;
      case FIELD_TYPES.CASCADING_SELECT:
      case FIELD_TYPES.SELECT:
      case FIELD_TYPES.COUNTRY:
        field = <DropdownField {...props} />;
        break;
      case FIELD_TYPES.PHONE_NUMBER:
        field = <PhoneNumberField {...props} maxLength={props.max} />;
        break;
      case FIELD_TYPES.DATE:
        field = <DateField {...props} />;
        break;
      case FIELD_TYPES.CURRENCY:
        field = <CurrencyField {...props} />;
        break;
      case FIELD_TYPES.BOOL:
        field = <BooleanField {...props} />;
        break;
      case FIELD_TYPES.PIN:
        field = <PinField {...props} />;
        break;
      case FIELD_TYPES.TEXTAREA:
        field = <TextAreaField {...props} maxLength={props.max} />;
        break;
      case FIELD_TYPES.FILE:
        field = <FileField {...props} />;
        break;
      case FIELD_TYPES.TEXT:
      default:
        field = <TextField {...props} maxLength={max} />;
        break;
    }

    return (
      <div className={formGroupCssClasses}>
        <Label
          label={props.label}
          required={props.required}
          hideLabel={props.type == FIELD_TYPES.BOOL || props.hideLabel}
          moreInfo={props.moreInfo}
          showModalFunction={props.showModal}
          fieldId={props.id}
          hideOptional={props.hideOptional}
        />
        {field}
        <div className="help-block">
          {
            <span className="validation-error">
              {hasError ? props.meta.error : " "}
            </span>
          }
        </div>
      </div>
    );
  };

  /**
   * Lifecycle method to render the component.
   * */
  render() {
    return (
      <Field
        name={this.props.id}
        component={this.renderField}
        maxLength={this.props.max}
        {...this.props}
        validationError={this.props.validationError}
        placeholder={this.props.placeholderText}
        validate={this.props.validate || this.validate}
      />
    );
  }
}

DynamicInputField.propTypes = {
  /** Field label */
  label: PropTypes.any,
  /** The type of field. This should come from the FIELD_TYPES constant */
  type: PropTypes.number.isRequired,
  /** The field ID. This will be used as the form field name */
  id: PropTypes.string.isRequired,
  /** If the field is required. Optional fields will get an (optional) label */
  required: PropTypes.bool,
  /** The list of select options (not implemented yet) */
  options: PropTypes.array,
  /** URL used to retrieve the options if none are populated */
  optionsSource: PropTypes.string,
  /** Array of fields related to this field */
  relatedFields: PropTypes.array,
  /** The minimum length if text or minimum value if a number */
  min: PropTypes.number,
  /** The maximum length if text or maximum value if a number */
  max: PropTypes.number,
  /** If the field value is valid at initialization */
  valid: PropTypes.bool,
  /** The error message to show for invalid fields */
  validationError: PropTypes.array,
  /** A regular expression used to validate the field (not implemented yet) */
  regex: PropTypes.string,
  /** Placeholder text for the input */
  placeholderText: PropTypes.string,
  /** Custom validation to run insted of the validation provided by @see {DynamicInputField}. */
  validate: PropTypes.func,
  /** Value to initialize the field with. */
  value: PropTypes.any,
};

export default DynamicInputField;
