import React from "react";
import PageBlock from "../../../components/Layouts/PageBlock";
import { PAGES, COMMON } from "../../../constants/localization";
import { ERROR_CODES } from "../../../constants/errorCodes";
import Action from "../../../components/Common/Action";
import ActionRow from "../../../components/Layouts/ActionRow";
import InputWrapper from "../InputWrapper";
import InputLabel from "../InputLabel";
import { ACTION_DISPLAY_TYPES } from "../../../constants/enums";
import PropTypes from "prop-types";
import PinField from "../../../components/Common/DynamicFields/PinField";
import InfoTip from "../../../components/Common/InfoTip";
import { ROUTES } from "../../../constants/clientRoutes";
import InputSkeletonGroup from "../../../components/Common/Loaders/InputSkeleton";
import ErrorProvider from "../../../components/ErrorProvider";
import ErrorService from "../../../services/errorService";

const CurrentPINDesktop = (props) => {
  const handleInputChange = (event) => {
    props.handleChange(event.target.value);
  };

  const handleInputSubmit = (event) => {
    event.preventDefault();
    props.handleSubmit();
  };

  const handleForgotPINClick = () => {
    props.forgotPINEvent();
  };

  const togglePINHelpModalHandler = () => {
    props.togglePINHelpModal();
  };

  const events = {
    onChange: handleInputChange,
  };

  /**
   * Searches errors array for a specific error code
   * @param errorCode
   * @returns error message
   */
  const findError = (errorCode) => {
    if (props.errors && props.errors.fieldErrors) {
      let errorQuery = props.errors.fieldErrors.find((error) => {
        return error.errorCode == errorCode;
      });

      if (errorQuery) {
        if (errorCode == ERROR_CODES.PIN.TOO_MANY_ATTEMPTS) {
          return formatErrorMessage(errorQuery.errorMessage);
        } else {
          return errorQuery.errorMessage;
        }
      }
    }
  };

  /**
   * Replaces the "contact support" text in the transfer lock message with a hyperlink.
   * @param {string} message - The raw transfer lock message
   */
  const formatErrorMessage = (message) => {
    let errorMessage = message.replace("contact support.", "");
    const contactLink = `<span><a class="support-link" href="${ROUTES.EXTERNAL.SUPPORT}">contact support.</a></span>`;
    return (
      <span
        dangerouslySetInnerHTML={{ __html: errorMessage.concat(contactLink) }}
      />
    );
  };

  /**
   * Returns an element to display error below PIN input element
   * @param {string} errorCode
   */
  const InputError = ({ errors }) => {
    const fieldErrors = ErrorService.GetFieldErrors(errors);
    return fieldErrors != null ? createErrorsOutput(fieldErrors) : null;
  };

  const createErrorsOutput = (fieldErrors) =>
    Array.apply(null, fieldErrors).map((error, index) => {
      return (
        <div key={index} className="text-danger">
          {error.errorMessage}
        </div>
      );
    });

  InputError.propTypes = {
    errors: PropTypes.array,
  };

  /**
   * If the user is transfer locked, this gets the transfer lock message
   * and formats with a hyperlink to contact support. Empty string otherwise.
   */
  const getTransferLockMessage = () => {
    return formatErrorMessage(COMMON.TRANSFER_LOCKED_OUT);
  };

  const TransferLockedError = () => {
    if (props.isTransferLocked && props.errors == null) {
      return <PageBlock.Error>{getTransferLockMessage()}</PageBlock.Error>;
    }
    return null;
  };

  return (
    <PageBlock showScreenErrors>
      <TransferLockedError />
      <PageBlock.Title>{PAGES.PIN_RESET.ENTER_PIN_HEADER}</PageBlock.Title>
      <PageBlock.Loader loadingContent={props.isTransferLocked == null}>
        <InputSkeletonGroup numInputs={1} />
      </PageBlock.Loader>
      <PageBlock.Body loadingContent={props.isTransferLocked == null}>
        <form id="EnterPIN" onSubmit={handleInputSubmit}>
          <div className="pin-reset-input-group">
            <InputWrapper
              width="33%"
              className={
                findError(ERROR_CODES.PIN.INVALID)
                  ? "pin-input-wrapper has-error"
                  : "pin-input-wrapper"
              }
            >
              <InputLabel>{PAGES.PIN_RESET.PIN_INPUT_LABEL}</InputLabel>
              <InfoTip onClick={togglePINHelpModalHandler} />
              <PinField
                events={events}
                pin={props.currentPIN}
                disabled={props.isTransferLocked}
              />
              <ErrorProvider
                render={(errors) => <InputError errors={errors} />}
              />
              <button
                type="button"
                onClick={handleForgotPINClick}
                className="input-help-link"
                to="/pinreset/forgot"
                disabled={props.isTransferLocked}
              >
                {PAGES.PIN_RESET.FORGOT_PIN_HELP_LINK_TEXT}
              </button>
            </InputWrapper>
          </div>
          <ActionRow>
            <ActionRow.Forward>
              <Action
                title={COMMON.NEXT}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                type="submit"
                disabled={props.currentPIN.length < 4 || props.isTransferLocked}
                loading={props.loading}
              />
            </ActionRow.Forward>
            <ActionRow.Backward>
              <a href={PAGES.PIN_RESET.CANCEL_URL}>Cancel</a>
            </ActionRow.Backward>
          </ActionRow>
        </form>
      </PageBlock.Body>
    </PageBlock>
  );
};

CurrentPINDesktop.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  currentPIN: PropTypes.string,
  errors: PropTypes.array,
  togglePINHelpModal: PropTypes.func,
  forgotPINEvent: PropTypes.func,
  loading: PropTypes.bool,
  isTransferLocked: PropTypes.bool,
};

export default CurrentPINDesktop;
