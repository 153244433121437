import React, { Component } from "react";
import { PropTypes } from "prop-types";
import attachmentIcon from "../../assets/images/Upload_Icon_Web_blue.svg";

/**
 * File upload form field
 */
export class FileUploadField extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * On Change event handler
   * @param e event
   */
  onChange(e) {
    const {
      input: { onChange },
    } = this.props;
    onChange(Object.assign({}, e.target.files[0]));
  }

  render() {
    return (
      <div className="file-upload">
        <label htmlFor="attachment" className="file-upload-label">
          <img src={attachmentIcon} className="btn-attachment" />

          <input
            id="attachment"
            name="attachment"
            type="file"
            accept=".jpg,.png,.jpeg,.pdf,.bmp,.gif"
            onChange={this.props.onUploadFile}
          />
        </label>
      </div>
    );
  }
}

FileUploadField.propTypes = {
  /** input form field */
  input: PropTypes.any,
  /** file upload callback */
  onUploadFile: PropTypes.func,
};

export default FileUploadField;