import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UnsupportedBrowser from "../../containers/Login/UnsupportedBrowser";

/**
 * Wrapper for checking browse compatibility before showing screen.
 *
 * @param Component
 * @param rest
 * @returns {null|*}
 * @constructor
 */
export const withBrowserCompatible = (Component) => {
  const Sub = ({ browserSupported, ...rest }) =>
    browserSupported ? (
      <Component {...rest} />
    ) : (
      <UnsupportedBrowser {...rest} />
    );

  Sub.propTypes = {
    browserSupported: PropTypes.bool,
  };

  return connect((state) => {
    return {
      browserSupported: state.global.browserSupported,
    };
  })(Sub);
};

withBrowserCompatible.propTypes = {
  /** Component to display at the route */
  component: PropTypes.object.isRequired,
};
