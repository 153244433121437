import React from "react";
import PropTypes from "prop-types";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import { FIELD_TYPES } from "../../constants/enums";
import { PAGES } from "../../constants/localization";

const QuestionInputField = (props) => {
  let modifiedQuestions = props.questions;
  modifiedQuestions.forEach((question) => {
    question.value = question.id;
    question.label = question.question;
  });

  let fieldProps = {
    label: `${PAGES.SECURITY_QUESTIONS.QUESTION_LABEL} ${props.questionNumber}`,
    type: FIELD_TYPES.SELECT,
    id: `${PAGES.SECURITY_QUESTIONS.QUESTION_LABEL}${props.questionNumber}`,
    valid: false,
    required: true,
    options: modifiedQuestions,
    className: "answer-input form-group col-sm-7",
    isSearchable: false,
    isClearable: true,
    placeholderText: PAGES.SECURITY_QUESTIONS.DROPDOWN_PLACEHOLDER,
  };

  return <DynamicInputField {...fieldProps} />;
};

QuestionInputField.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
};

const AnswerInputField = (props) => {
  let fieldProps = {
    label: `${PAGES.SECURITY_QUESTIONS.ANSWER_LABEL} ${props.questionNumber}`,
    type: FIELD_TYPES[props.dataType.toUpperCase()],
    id: `${PAGES.SECURITY_QUESTIONS.ANSWER_LABEL}${props.questionNumber}`,
    valid: false,
    required: true,
    max: 30,
    className: "question-input form-group col-sm-5",
  };

  return <DynamicInputField {...fieldProps} />;
};

AnswerInputField.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  dataType: PropTypes.string.isRequired,
};

const QuestionAnswer = ({ questionGroup, questionNumber }) => {
  return (
    <div className="security-question row">
      <QuestionInputField {...questionGroup} questionNumber={questionNumber} />
      <AnswerInputField {...questionGroup} questionNumber={questionNumber} />
    </div>
  );
};

QuestionAnswer.propTypes = {
  questionNumber: PropTypes.number.isRequired,
  questionGroup: PropTypes.object.isRequired,
};

export default QuestionAnswer;
