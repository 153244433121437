import React from 'react';
import PropTypes from "prop-types";
import Modal from '../../Common/Modal';
import { ACTION_DISPLAY_TYPES, ACTION_TYPES } from '../../../constants/enums';

const MFAtoFaceCheckModal = ({ showModal, setShowModal, disableTotpAPICall }) => {

  const actions = [
    {
      title: 'Go Back',
      displayType: ACTION_DISPLAY_TYPES.SECONDARY,
      onClick: () => setShowModal(false),
      actionType: ACTION_TYPES.DISMISS,
      focused: true,
    },
    {
      title: 'Continue',
      displayType: ACTION_DISPLAY_TYPES.PRIMARY,
      onClick: disableTotpAPICall,
      actionType: ACTION_TYPES.CONFIRM,
      focused: true,
    },
  ]

  return (
    <Modal
      onClose={() => setShowModal(false)}
      open={showModal}
      title={'Are you sure?'}
      content={
        <div>
          <p>You are about to change log in method from Authenticator app to FaceCheck.</p>
        </div>
      }
      actions={actions}
    />
  );
}

MFAtoFaceCheckModal.propTypes = {
  /** Boolean to display modal to change to FaceCheck */
  showModal: PropTypes.bool,
  /** hook to set showModal state */
  setShowModal: PropTypes.func,
  /** AuthAPI call to disable totp */
  disableTotpAPICall: PropTypes.func,
};

export default MFAtoFaceCheckModal;