import React from "react";
import PropTypes from "prop-types";
import FlowNextButton from "./FlowNextButton";
import FlowSendButton from "./FlowSendButton";
import FlowSubmitButton from "./FlowSubmitButton";

const FlowButton = ({ type, ...props }) => {
  switch (type.toLowerCase()) {
    case "next":
      return <FlowNextButton {...props} />;
    case "send":
      return <FlowSendButton {...props} />;
    case "submit":
      return <FlowSubmitButton {...props} />;
    default:
      return <FlowSubmitButton {...props} />;
  }
};

FlowButton.propTypes = {
  type: PropTypes.string,
};

export default FlowButton;
