import { appInsights } from "../services/appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

/**
 * Interacts with the App Insights
 */
class LogApi {
  /**
   * Sends error details to App Insights
   * @param error
   * @param userId
   * @returns {Promise<void>} null
   * @constructor
   * @async
   */
  static async SendError(error: Error, userId: string) {
    let body = {
      message: error.message,
      stackTrace: error.stack,
      client: window.navigator.userAgent,
      userId,
    };

    appInsights.trackException({
      exception: new Error('Unhandled Web Exception'), severityLevel: SeverityLevel.Error, properties: body,
    });
  }

  /**
   * Sends error details to App Insights for HandleErrorResponse (old api error handler)
   * @param error
   * @returns {Promise<void>} null
   * @constructor
   * @async
   */
  static async OldErrorHandler(error: string) {
    let body = {
      message: error,
      client: window.navigator.userAgent,
    };

    appInsights.trackException({
      exception: new Error('Unhandled Web Exception'), severityLevel: SeverityLevel.Error, properties: body,
    });
  }
}

export default LogApi;
