import React, { Component } from "react";
import PropTypes from "prop-types";
import ChangePINMobile from "../Mobile/ChangePINMobile";
import ChangePINDesktop from "../Desktop/ChangePINDesktop";
import BREAKPOINTS from "../../../constants/breakPoints";
import { PAGES, COMMON } from "../../../constants/localization";
import { connect } from "react-redux";
import AlertModal from "../../../components/Common/Modals/AlertModal";
import CardApi from "../../../api/card";
import { eventTracker, EVENTS } from "../../../services/eventTracker";
import PinSuccess from "../PinSuccess";
import { ERROR_CODES } from "../../../constants/errorCodes";

class ChangePINContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialPIN: "",
      confirmPIN: "",
      errorMessage: "",
      showGenericAlertModal: false,
      loading: false,
      pinSuccess: false,
      pinSuccessTitle: "",
      pinSuccessDescription: "",
      errors: [],
      showErrorOnFirstInput: false,
      showErrorOnSecondInput: false,
    };

    this.handleFirstPINChange = this.handleFirstPINChange.bind(this);
    this.handleConfirmPINChange = this.handleConfirmPINChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleFirstPINChange(pin) {
    this.setState({
      initialPIN: pin,
    });
  }

  handleConfirmPINChange(pin) {
    this.setState({
      confirmPIN: pin,
    });
  }

  handleSubmit() {
    if (!this.state.loading) {
      this.setState(
        {
          loading: true,
          errorMessage: "",
          errors: [],
          showErrorOnFirstInput: false,
          showErrorOnSecondInput: false,
        },
        () => {
          if (this.isInputValid()) {
            this.submitPIN();
          }
        }
      );
    }
  }

  async submitPIN() {
    if (this.props.responseToken != "" && this.props.cardId != null) {
      try {
        let response = await CardApi.ChangepIN(
          this.state.confirmPIN,
          this.props.cardId,
          this.props.responseToken
        );
        if (response.description != null && response.title != null) {
          this.setState(
            {
              pinSuccess: true,
              pinSuccessTitle: response.title,
              pinSuccessDescription: response.description,
            },
            () => {
              eventTracker.track(EVENTS.ChangePIN_NewPINSubmitted, {
                success: true,
              });
            }
          );
        }
      } catch (error) {
        eventTracker.track(EVENTS.ChangePIN_NewPINSubmitted, {
          success: false,
        });
        this.setState(
          {
            loading: false,
          },
          () => {
            if (error.data == null) {
              this.setState({
                showGenericAlertModal: true,
                initialPIN: "",
                confirmPIN: "",
              });
            } else {
              this.setState(
                {
                  errors: error.data.errors,
                },
                () => {
                  this.checkForError();
                }
              );
            }
          }
        );
      }
    }
  }

  checkForError = () => {
    let invalidToken = this.findError(ERROR_CODES.PIN.INVALID_TOKEN);
    let invalidPIN = this.findError(ERROR_CODES.PIN.USING_OLD_PIN);
    if (invalidToken != null) {
      this.setState({
        showGenericAlertModal: true,
        initialPIN: "",
        confirmPIN: "",
      });
    } else if (invalidPIN != null) {
      this.setState({
        errorMessage: invalidPIN,
        showErrorOnFirstInput: true,
        initialPIN: "",
        confirmPIN: "",
      });
    } else {
      this.setState({
        showGenericAlertModal: true,
        initialPIN: "",
        confirmPIN: "",
      });
    }
  };

  /**
   * Searches errors array for a specific error code
   * @param errorCode
   * @returns error message
   */
  findError = (errorCode) => {
    if (this.state.errors.length) {
      let errorQuery = this.state.errors.find((error) => {
        return error.errorCode == errorCode;
      });

      if (errorQuery) {
        return errorQuery.errorMessage;
      }
    }
  };

  isInputValid() {
    /// Check length
    /// Make sure only numeric characters
    if (
      this.state.initialPIN.length < PAGES.PIN_RESET.PIN_LENGTH ||
      this.state.confirmPIN.length < PAGES.PIN_RESET.PIN_LENGTH
    ) {
      this.setState({
        errorMessage: PAGES.PIN_RESET.ERRORS.PIN_CRITERIA,
        showErrorOnSecondInput: true,
        loading: false,
        initialPIN: "",
        confirmPIN: "",
      });
      return false;
    } else if (this.state.initialPIN != this.state.confirmPIN) {
      this.setState({
        errorMessage: PAGES.PIN_RESET.ERRORS.PINS_DONT_MATCH,
        showErrorOnSecondInput: true,
        loading: false,
        initialPIN: "",
        confirmPIN: "",
      });
      return false;
    } else {
      return true;
    }
  }

  closeGenericModal = () => {
    this.setState({
      showGenericAlertModal: false,
    });
  };

  render() {
    const { width } = this.props;
    const isMobile = width <= BREAKPOINTS.SM_MIN;

    return (
      <React.Fragment>
        {!isMobile && !this.state.pinSuccess && (
          <ChangePINDesktop
            handleSubmit={this.handleSubmit}
            handleFirstPINChange={this.handleFirstPINChange}
            handleConfirmPINChange={this.handleConfirmPINChange}
            initialPIN={this.state.initialPIN}
            confirmPIN={this.state.confirmPIN}
            errors={this.state.errors}
            errorMessage={this.state.errorMessage}
            loading={this.state.loading}
            showErrorOnFirstInput={this.state.showErrorOnFirstInput}
            showErrorOnSecondInput={this.state.showErrorOnSecondInput}
          />
        )}
        {isMobile && !this.state.pinSuccess && (
          <ChangePINMobile
            handleFirstPINChange={this.handleFirstPINChange}
            handleConfirmPINChange={this.handleConfirmPINChange}
            handleSubmit={this.handleSubmit}
            initialPIN={this.state.initialPIN}
            confirmPIN={this.state.confirmPIN}
            errorMessage={this.state.errorMessage}
            loading={this.state.loading}
          />
        )}
        <AlertModal
          open={this.state.showGenericAlertModal}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
          onClose={this.closeGenericModal}
        />
        {this.state.pinSuccess && (
          <PinSuccess
            title={this.state.pinSuccessTitle}
            description={this.state.pinSuccessDescription}
          />
        )}
      </React.Fragment>
    );
  }
}

ChangePINContainer.propTypes = {
  changeStep: PropTypes.func,
  width: PropTypes.number,
  responseToken: PropTypes.string,
  cardId: PropTypes.number,
  clearError: PropTypes.func,
  addError: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cardId: state.card.id,
  };
};

export default connect(mapStateToProps)(ChangePINContainer);
