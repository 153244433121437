import React, { useEffect, useState } from "react";
import IndexForm from "../../components/Enrollment/Forms/IndexForm";
import { useDispatch, useSelector } from "react-redux";
import {
  setParticipantId,
} from "../../services/enrollment";
import EnrollmentApi from "../../api/enrollment";
import AlreadyEnrolledModal from "../../components/Enrollment/AlreadyEnrolledModal";
import { SetEnrollmentData } from "../../actions/enrollment";
import SpinningLoader from "../../components/Common/SpinningLoader";
import { trackEvent } from "../../services/eventTracker";
import { EVENTS } from "../../constants/events";
import { RootState } from "../../types/redux";
import { ErrorObj } from "../../types/errors";

/**
 * Enroll Page, first page of enrollment
 */
const Enroll = () => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [enrolledModal, setEnrolledModal] = useState({
    show: false,
    bodyMessage: "",
  });
  const currentPage = useSelector((state: RootState) => state.enrollment.currentPage);

  useEffect(() => {
    if (currentPage === 0) {
      trackEvent(EVENTS.ENROLLMENT.STARTED);
    }
  })

  if (currentPage !== 0) {
    return null;
  }

  /**
   * EnrollmentApi call to get user enrollment data based on participant Id. If successful, will move user on to next incomplete page.
   */
  const getEnrollmentData = async (participantId: string) => {
    try {
      const response = await EnrollmentApi.GetEnrollmentData(participantId);

      dispatch(SetEnrollmentData(response));
      setParticipantId(response);
    } catch (err) {
      const errors = err.data.errors.map((error: ErrorObj) => error.errorMessage);
      setShowSpinner(false);
      setErrors([...errors]);
    }
  };

  return (
    <div>
      <SpinningLoader show={showSpinner} />
      <IndexForm
        errors={errors}
        setErrors={setErrors}
        getEnrollmentData={getEnrollmentData}
        setShowSpinner={setShowSpinner}
        setEnrolledModal={setEnrolledModal}
      />
      <AlreadyEnrolledModal
        show={enrolledModal.show}
        handleClose={setEnrolledModal}
        bodyMessage={enrolledModal.bodyMessage}
      />
    </div>
  );
};

export default Enroll;
