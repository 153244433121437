import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function completedTransfer(
  state = initialState.moneyTransfer.completedTransfer,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.RESET_COMMIT_ERRORS: {
      return Object.assign({}, state, {
        ...state,
        errors: [],
        failure: false,
      });
    }
    case MONEY_TRANSFER.COMMIT_SUCCESS: {
      return Object.assign({}, state, {
        ...action.commitResult,
      });
    }
    case MONEY_TRANSFER.COMMIT_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        errors: action.errors,
        failure: true,
      });
    }
    default:
      return state;
  }
}
