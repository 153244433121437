import React from "react";
import PropTypes from "prop-types";
import Icon from "../Common/Icon";
import { MONEY_TRANSFER_FIELD_IDS, MONEY_TRANSFER_PROVIDER_NAMES, MONEY_TRANSFER_RATE_CARD_FIELD_TYPES } from "../../constants/enums";

/**
 * The display field is the base display field for Money Transfer
 * This should be reusable for all things Money Transfer.
 * In the case this becomes the BW standard, we can pull this into the common folder.
 *
 *
 * @param label
 * @param value
 * @param boldValue
 * @param superscript
 * @returns {*}
 * @constructor
 */
export const DisplayField = ({
  id,
  label,
  value,
  originalValue,
  boldValue,
  superscript,
  invalid,
  className,
  icon,
  provider,
  type
}) => {
  let valueClass = "p";
  let containerClass = '';
  if (boldValue) {
    valueClass += " bold";
  }

  let originalValueDiv;
  if (originalValue) {
    originalValueDiv = (
      <div className="value strike-through">{originalValue}</div>
    );
    valueClass += " new";
  }

  if (invalid) {
    valueClass += " error";
  }

  let superscriptDiv;
  if (superscript) {
    superscriptDiv = <sup>{superscript}</sup>;
  }

  if (type === MONEY_TRANSFER_RATE_CARD_FIELD_TYPES.MULTILINE_TEXT) {
    containerClass = 'multiline-text';
  }

  return (
    <div className={`mt-display ${className} ${containerClass}`}>
      {icon &&
        <Icon icon={icon} />
      }
      {label &&
        <label className={invalid ? "error" : ""}>
          {label}
          {superscriptDiv}
        </label>
      }
      {originalValueDiv}

      <div className={valueClass}>
        {type === MONEY_TRANSFER_RATE_CARD_FIELD_TYPES.MULTILINE_TEXT
          ? <div dangerouslySetInnerHTML={{ __html: value }} />
          : value
        }
        {(provider === MONEY_TRANSFER_PROVIDER_NAMES.WESTERN_UNION && id === MONEY_TRANSFER_FIELD_IDS.ESTIMATED_ARRIVAL) &&
          superscriptDiv
        }
      </div>
    </div>
  );
};

DisplayField.propTypes = {
  id: PropTypes.number,
  label: PropTypes.string,
  value: PropTypes.any,
  originalValue: PropTypes.string,
  boldValue: PropTypes.bool,
  superscript: PropTypes.any,
  invalid: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.number,
  provider: PropTypes.string,
  type: PropTypes.string
};

export default DisplayField;
