import React, { useState, useEffect } from "react";
import i18n from "../../i18n/config";
import { BankAccount, GetBankAccountsResponse } from "../../types/api/odt";
import OnDemandTransferApi from "../../api/onDemandTransfer";
import { ACTION_DISPLAY_TYPES, BANK_ACCOUNT_CARD_TYPES, BANK_ACCOUNT_STATUS } from "../../constants/enums";
import { BankAccountCardType } from "../../types/components";
import { useHistory } from "react-router-dom";

import FlowScreen from "../../components/Layouts/FlowScreen";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import BankAccountCard from "../../components/Common/BankAccountCard";
import Modal from "../../components/Common/Modal";
import Action from "../../components/Common/Action";
import DeleteBankAccountModal from "../../components/BankAccounts/DeleteBankAccountModal";
import useErrorModal from "../../hooks/useErrorModal";
import ApiErrorModal from "../../components/Common/Modals/ApiErrorModal";
import { useSelector } from "react-redux";
import { RootState } from "../../types/redux";
import DisbursementHistoryModal from "../../components/BankAccounts/DisbursementHistoryModal";
import { ROUTES } from "../../constants/clientRoutes";

const BankAccounts = () => {
  const openErrorModal = useErrorModal();
  const history = useHistory();
  const { bankAccountDeleted } = useSelector((state: RootState) => state.bankAccounts);
  const [availableBanks, setAvailableBanks] = useState<BankAccount[]>();
  const [unavailableBanks, setUnavailableBanks] = useState<BankAccount[]>();
  const [loading, setLoading] = useState(true);
  const [showInfoTipModal, setShowInfoTipModal] = useState(false);
  const [infoTipModal, setInfoTipModal] = useState<{ title: string, content: string }>({ title: "", content: "" });

  useEffect(() => {
    setLoading(true);
    getBanks();
  }, [bankAccountDeleted]);

  const getBanks = async () => {
    try {
      const response = await OnDemandTransferApi.GetBankAccounts() as GetBankAccountsResponse;

      setAvailableBanks(response.bankAccounts.filter(bank => bank.bankAccountStatus === BANK_ACCOUNT_STATUS.AVAILABLE));
      setUnavailableBanks(response.bankAccounts.filter(bank => bank.bankAccountStatus !== BANK_ACCOUNT_STATUS.AVAILABLE));

      setLoading(false);
    } catch (err) {
      openErrorModal(err);
    }
  }

  const handleInfoTipModal = (title: string, content: string) => {
    setShowInfoTipModal(!showInfoTipModal);
    setInfoTipModal({ title, content });
  }

  return (
    <FlowScreen
      flowTitle={i18n.t("PAGES.BANK_ACCOUNTS.INDEX.TITLE")}
      className="v3"
    >

      {loading &&
        <InputSkeletonGroup numInputs={4} />
      }

      {!loading &&
        <>
          <p className="spacing-bottom-medium">{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.SUBTITLE")}</p>

          {/* Available banks */}
          <section>
            <div className="flex-row justify-content-between spacing-bottom-small">
              <h2>{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.AVAILABLE_HEADING")}</h2>
              <Action
                title={i18n.t("PAGES.BANK_ACCOUNTS.INDEX.ADD_BANK_ACCOUNT")}
                displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                onClick={() => history.push(ROUTES.BANKS_ACCOUNTS.COUNTRY)}
              />
            </div>

            {availableBanks?.map((bank) => (
              <BankAccountCard
                key={bank.allocationId}
                bank={bank}
                handleInfoTipModal={handleInfoTipModal}
                type={BANK_ACCOUNT_CARD_TYPES.BANK_ACCOUNT as BankAccountCardType}
              />
            ))}
          </section>

          {/* Unavailable banks */}
          <section className="spacing-top-medium">
            <h2>{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.UNAVAILABLE_HEADING")}</h2>
            <p>{i18n.t("PAGES.BANK_ACCOUNTS.INDEX.UNAVAILABLE_SUBHEADING")}</p>
            {unavailableBanks?.map((bank) => (
              <BankAccountCard
                key={bank.allocationId}
                bank={bank}
                handleInfoTipModal={handleInfoTipModal}
              />
            ))}
          </section>

          <Modal
            onClose={() => setShowInfoTipModal(false)}
            open={showInfoTipModal}
            title={infoTipModal?.title}
            content={infoTipModal?.content}
          />

          <DeleteBankAccountModal />

          <DisbursementHistoryModal />

          <ApiErrorModal />
        </>
      }

    </FlowScreen >
  );
}

export default BankAccounts;