import React from "react";
import PropTypes from "prop-types";

function BaseInput({ className, ...props }) {
  const inputClass = className ? className : "";
  return <input className={`form-control ${inputClass}`.trim()} {...props} />;
}

BaseInput.propTypes = {
  className: PropTypes.string,
};

export default BaseInput;
