import React, { useState } from 'react';
import { COMMON, PAGES } from '../../constants/localization';
import ENV from "../../constants/environment";
import { ACTION_DISPLAY_TYPES, ICONS } from '../../constants/enums';

import FlowScreen from '../../components/Layouts/FlowScreen';
import PageBlock from '../../components/Layouts/PageBlock';
import Alert from '../../components/Common/Alerts/Alert';
import ActionLink from '../../components/Common/ActionLink';
import { ROUTES } from '../../constants/clientRoutes';
import Action from '../../components/Common/Action';
import { trackEvent } from '../../services/eventTracker';
import { EVENTS } from '../../constants/events';

const GetHelpFaster = () => {
  const [disabled, setDisabled] = useState(true);

  const handleClick = () => {
    trackEvent(EVENTS.SUPPORT.CONTINUE_SUPPORT_LOGGED_OUT_SUPPORT);
    window.location.href = `${ROUTES.EXTERNAL.SUPPORT_OLD}`;
  }

  const handleLogInClick = () => {
    trackEvent(EVENTS.SUPPORT.RETURN_LOGIN_LOGGED_OUT_SUPPORT);
    window.location.href = `${ENV.BASE_NAVIGATOR_URL}`;
  }

  return (
    <FlowScreen flowTitle={PAGES.GET_HELP_FASTER.TITLE}>
      <PageBlock>
        <PageBlock.Body>
          <Alert
            alertClasses={"alert-info alert-bkgrd-white flex-row align-items-center"}
            message={PAGES.GET_HELP_FASTER.ALERT_TEXT}
            isHTML={true}
            icon={ICONS.INFO}
            iconClasses={"spacing-right-tiny"}
          />
          <div className="flow-page-block__component__child sm">

            <h2>Get help faster:</h2>
            <p className="marengo-grey">Log in before contacting Support:</p>
            <ActionLink
              text={COMMON.LOG_IN}
              clickFunc={handleLogInClick}
              classes="btn btn-primary full-width"
            />
            <hr />

            <div className="checkbox">
              <input
                type="checkbox"
                id="verifyIdAgreement"
                onChange={() => setDisabled(!disabled)}
              />{" "}
              <label htmlFor="verifyIdAgreement" className="marengo-grey">
                I understand opening a Support ticket without being logged-in requires ID verification.
              </label>
            </div>

            <Action
              title={PAGES.GET_HELP_FASTER.ACTION_TEXT}
              displayType={ACTION_DISPLAY_TYPES.SECONDARY}
              className="full-width spacing-top-small"
              onClick={handleClick}
              disabled={disabled}
            />
          </div>
        </PageBlock.Body>
      </PageBlock>
    </FlowScreen>
  );
}

export default GetHelpFaster;