import React from "react";
import PropTypes from "prop-types";
import QuestionAnswer from "./QuestionAnswer";

const generateQuestionAnswers = (props) => {
  let QuestionAnswers = [];
  if (props.questions != null) {
    props.questions.forEach((questionGroup, index) => {
      QuestionAnswers.push(
        <QuestionAnswer
          questionGroup={questionGroup}
          questionNumber={index + 1}
          key={index}
        />
      );
    });
  }

  return QuestionAnswers;
};

const SecurityQuestions = (props) => {
  const QuestionAnswers = generateQuestionAnswers(props);
  return <div className="security-questions">{QuestionAnswers}</div>;
};

SecurityQuestions.propTypes = {
  questions: PropTypes.array,
};

export default SecurityQuestions;
