import ENV from "./environment";

export const QUERYSTRING_KEYS = {
  IS_FOCUSED: "isFocused",
};

export const BASE_NAME = ENV.BASE_NAME.slice(0, -1);

export const ROUTES = {
  DASHBOARD: "/dashboard",
  MONEY_TRANSFER: "/mycard/moneytransfer",
  MONEY_TRANSFER_DETAILS: "/mycard/moneytransfer/:moneyTransferId",
  CURRENCY_CONVERTER: "/MyCard/CardCurrencyConverter",
  COMPONENT_LIBRARY: "/components",
  SUPPORT: "/support",
  SUPPORT_INDEX: "/support/index",
  SUPPORT_PUBLIC: "/support/GenericSupportTicket",
  SUPPORT_GET_HELP_FASTER: "/support/GetHelpFaster",
  SUPPORT_NEW: "/support/conversations/new",
  SUPPORT_CONVERSATIONS: "/support/conversations",
  WAGE_STATEMENTS: "/payments/wagestatements",
  AUTH: {
    LOGIN_FACECHECK: `/login/faceCheck`,
    SETUP_FACECHECK: `/login/setupFaceCheck`,
    SUCCESS_FACECHECK: `/login/successFaceCheck`,
    INTRO_FACECHECK: `/login/introFaceCheck`,
    PASSWORD_LOGIN: "/login/password",
    LOGIN: `${ENV.BASE_NAME}`,
    SECURITY_QUESTION: `/login/securityQuestion`,
    SUPPORT: `/login/support`,
    SUPPORT_EMAIL: `/login/supportEmail`,
    TOTP: `/login/totp`
  },
  EXTERNAL: {
    PROFILE: "/Account/AccountDetails",
    LOGOUT: "/Account/LogOff",
    REVIEW_DOCS: `${ENV.BASE_NAVIGATOR_URL}/Account/ReviewAndAccept`,
    LOGIN: `${ENV.BASE_NAME}`,
    SUPPORT_OLD: `${ENV.BASE_NAVIGATOR_URL}/Support/GenericSupportTicket`,
    SUPPORT: `${ENV.BASE_NAVIGATOR_URL}/Support`,
    DASHBOARD: `${ENV.BASE_NAVIGATOR_URL}/Dashboard`,
    MONEY_TRANSFER: `${ENV.BASE_NAVIGATOR_URL}/MyCard/MoneyTransfer`,
    MY_CARD: `${ENV.BASE_NAVIGATOR_URL}/MyCard`,
    SESSION_KEEP_ALIVE: `${ENV.BASE_NAVIGATOR_URL}/Home/KeepSessionAlive`,
    ON_DEMAND_TRANSFER: `${ENV.BASE_NAVIGATOR_URL}/OnDemandTransfer/ODTSelection`,
    BANK_ACCOUNTS: `${ENV.BASE_NAVIGATOR_URL}/Beneficiaries`,
    ADD_BANK: `${ENV.BASE_NAVIGATOR_URL}/Beneficiaries/CreateNew`,
  },
  ENROLLMENT: {
    ENROLL: "/enrollment",
  },
  MY_CARD: {
    INDEX: '/MyCard',
    LOCK_CARD: '/mycard/lock-card',
    ACTIVATE_REPLACEMENT_CARD: '/MyCard/ActivateCard',
    WESTERN_UNION: '/MyCard/WesternUnion',
    SEND_TO_WESTERN_UNION: '/MyCard/SendToWesternUnion',
    CARD_TO_CARD_TRANSFER: '/MyCard/CardToCardTransfer',
    REQUEST_COMPANION_CARD: '/MyCard/RequestCompanionCard',
    PUSH_TO_CARD_TRANSFER_DETAILS: "/MyCard/PushToCardTransfer/:pushToCardTransferId",
    TO_ACCOUNT_TRANSFER_DETAILS: "/MyCard/ToAccount/:moneyTransferId",
    MC_TO_ACCOUNT_TRANSFER_DETAILS: "/MyCard/MCToAccount/:transferId",
    CARD_STATEMENTS: "/MyCard/CardStatements",
  },
  SETTINGS: {
    INDEX: '/Account',
    PERSONAL_DETAILS: "/Account/AccountDetails",
    SECURITY_QUESTIONS: "/Account/ChangeSecurityQuestions",
    FORGOT_USERNAME: "/Account/ForgotUsername",
    FORGOT_PASSWORD: "/Account/ForgotPassword",
    CHANGE_CURRENT_PASSWORD: "/Account/ChangePassword",
    FORGOT_SECURITY_QUESTION: "/Account/ForgotSecurityQuestions",
    LOG_IN_METHOD: "/Account/LogInMethod",
    DISCLOSURES: "/Account/Disclosures",
    COMMUNICATION_PREFERENCES: "/Account/CommunicationPreferences",
    REVIEW_AND_ACCEPT: "/Account/ReviewAndAccept",
  },
  ODT: {
    ON_DEMAND_TRANSFER: "/OnDemandTransfer",
    BANK_ACCOUNT_SELECTION: '/OnDemandTransfer/ODTSelection',
    AMT_PURPOSE: '/OnDemandTransfer/ODTAmountPurpose',
    EXCHANGE_RATE: "/OnDemandTransfer/ODTRate",
    TRANSFER_DETAILS: "/OnDemandTransfer/ODTTransferDetails",
    TRANSFER_CONFIRMATION: "/OnDemandTransfer/ODTConfirmation",
    TRANSFERS: "/OnDemandTransfer/Transfers",
    CANCEL_TRANSFER: "/OnDemandTransfer/CancelTransfer/:transferId",
  },
  WU_TO_ACCOUNT: {
    INDEX: "/MyCard/WesternUnionToAccount",
  },
  BANKS_ACCOUNTS: {
    INDEX: "/Beneficiaries",
    COUNTRY: "/Beneficiaries/CreateNew",
    CURRENCY: "/Beneficiaries/ReviewAccountType",
    ACCOUNT_DETAILS: "/Beneficiaries/ConfirmTransferType",
    REVIEW: "/Beneficiaries/ReviewAccountDetails"
  },
  TOTP_INSTRUCTIONS: "/totp-instructions",
  CARD_TRANSACTION_HISTORY: "/mycard/transactionhistory",
  DEV_ROUTES: "/devroutes",
  PIN_RESET: "/mycard/pinchange",
  PIN_FORGOT: "/mycard/pinchange#forgot",
  PRIVACY: "/privacy"
};
