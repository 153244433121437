import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { PAGE_NAV_LINKS } from "../../constants/localization";
import ENV from "../../constants/environment";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import ActionRow from "../../components/Layouts/ActionRow";
import ActionLink from "../../components/Common/ActionLink";
import Action from "../../components/Common/Action";
import { GetCardTransactionHistory } from "../../actions/card";
import { ACTION_DISPLAY_TYPES, CARD_TRANSACTIONS, CREDIT_CARD_TYPE_ID, TRANSACTION_TYPES } from "../../constants/enums";
import { RootState } from "../../types/redux";
import { CardTransaction } from "../../types/api/card";
import CardApi from "../../api/card";
import AlertModal from "../../components/Common/Modals/AlertModal";
import i18n from "../../i18n/config";

/**
 * Main container for the MyCard index page
 */
const MyCard = () => {
  const dispatch = useDispatch();
  const card = useSelector((state: RootState) => state.card);
  const [transactionIndex, setTransactionIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (card?.id) {
      getCardTransactions(card.id, 0, CARD_TRANSACTIONS.NUM_TO_GET);
      setTransactionIndex(CARD_TRANSACTIONS.NUM_TO_GET);
    }

  }, [card.id])

  const getCardTransactions = async (cardId: number, skip?: number, take?: number) => {
    try {
      const response = await CardApi.GetCardTransactions(cardId, skip, take) as CardTransaction[];
      dispatch(GetCardTransactionHistory(response));
      setLoading(false);
    } catch (err) {
      if (err?.data?.errors) {
        setErrorMessage(err.data.errors[0].errorMessage);
      } else {
        setErrorMessage(i18n.t("COMMON.ERROR"));
      }
      setLoading(false);
      setShowErrorModal(true);
    }
  };

  // Get the next set of transactions
  // Add the number of transactions to the index
  const handleClick = () => {
    getCardTransactions(card.id, transactionIndex, transactionIndex + CARD_TRANSACTIONS.NUM_TO_GET);
    setTransactionIndex(transactionIndex + CARD_TRANSACTIONS.NUM_TO_GET);
  }

  function addZero(i) {
    if (i < 10) { i = "0" + i }
    return i;
  }

  const formatDate = (dateTime) => {
    const dateObj = new Date(dateTime);

    const month = dateObj.toLocaleString('default', { month: 'short' });

    let h = addZero(dateObj.getHours());
    let m = addZero(dateObj.getMinutes());
    let time = h + ":" + m;

    return (
      `${dateObj.getDate()}-${month}-${dateObj.getFullYear()} ${time}`
    )
  }

  return (
    <LayoutWithNav>
      <LayoutWithNav.PageNav>
        <PageNav>
          <PageNav.Header>
            {PAGE_NAV_LINKS.MY_CARD.HEADER}
          </PageNav.Header>
          <PageNav.NavContent
            navLinks={PAGE_NAV_LINKS.MY_CARD.LINKS}
          />
        </PageNav>
      </LayoutWithNav.PageNav>
      <LayoutWithNav.Content className="allow-overflow">
        <FlowScreen
          flowTitle={''}
          className="v3 mycard-index"
        >
          <PageBlock>
            <PageBlock.Loader
              loadingContent={loading}
            >
              <InputSkeletonGroup numInputs={4} />
            </PageBlock.Loader>
            <PageBlock.Body loadingContent={loading}>
              {(!loading && card.transactions) &&
                < React.Fragment >
                  <div className="flex-row align-items-center justify-content-between spacing-bottom-medium">
                    <h2>Card Balance</h2>
                    <ActionLink
                      text="View Monthly Statements"
                      href={`${ENV.BASE_NAVIGATOR_URL}/MyCard/CardStatements?cardid=${card.id}`}
                      classes="btn btn-primary mobile-spacing"
                    />
                  </div>
                  <div className="overflow-scroll">
                    <table className="table old-navigator-style">
                      <thead>
                        <tr>
                          <th>Card</th>
                          <th>Status</th>
                          <th>Balance</th>
                        </tr>

                      </thead>

                      <tbody>
                        <tr>
                          <td>My {CREDIT_CARD_TYPE_ID[card.creditCardTypeId]} **{card.lastSix.slice(2)}
                            <br></br> <i>Expiration {addZero(card.expirationMonth)} / {card.expirationYear} </i>
                          </td>
                          <td>{card.status === 'Complete' ? 'Issued' : 'Not Issued'}</td>
                          <td>{card.balanceString}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <PageBlock.Title>Recent Transactions</PageBlock.Title>
                  <div className="overflow-scroll">
                    <table className="table old-navigator-style">
                      <thead>
                        <tr>
                          <th>Date/Time</th>
                          <th>Description</th>
                          <th>Status</th>
                          <th>Amount</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        {card?.transactions.map((item: CardTransaction, index: number) => {
                          if (index < transactionIndex) {
                            const viewDetails = () => {
                              let url: string;
                              switch (item.type) {
                                case TRANSACTION_TYPES.MONEY_TRANSFER:
                                  url = `${ENV.BASE_NAVIGATOR_URL}/MyCard/MoneyTransfer/${item.moneyTransferId}`;
                                  break;
                                case TRANSACTION_TYPES.PUSH_TO_CARD:
                                  url = `${ENV.BASE_NAVIGATOR_URL}/MyCard/PushToCardTransfer/${item.pushToCardTransferId}`;
                                  break;
                                case TRANSACTION_TYPES.WU_TO_ACCOUNT:
                                  url = `${ENV.BASE_NAVIGATOR_URL}/MyCard/ToAccount/${item.moneyTransferId}`;
                                  break;
                                case TRANSACTION_TYPES.MC_TO_ACCOUNT:
                                  url = `${ENV.BASE_NAVIGATOR_URL}/MyCard/MCToAccount/${item.transferId}`;
                                  break;
                                default:
                                  return null;
                              }

                              return (
                                <ActionLink
                                  classes="bold no-underline"
                                  href={url}
                                  text={'View Details'}
                                />
                              )
                            }
                            return (
                              <tr key={item.date + item.type + item.amount}>
                                <td>{formatDate(item.date)}</td>
                                <td><div dangerouslySetInnerHTML={{ __html: item.htmlDescription }} /></td>
                                <td>{item.status}</td>
                                <td>{item.formattedAmount}</td>
                                <td>
                                  {viewDetails()}
                                </td>
                              </tr>
                            )
                          }
                        })}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              }

              <ActionRow>
                <ActionRow.Forward>
                  <Action
                    title="View More Transactions"
                    onClick={handleClick}
                    classes="btn btn-primary"
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    disabled={card.transactions && (card.transactions.length < transactionIndex)}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>

          <p>*Fees may apply. Refer to the fee schedule included in your Cardholder Agreement.</p>
        </FlowScreen>
      </LayoutWithNav.Content>

      <AlertModal
        onClose={() => setShowErrorModal(false)}
        open={showErrorModal}
        title={i18n.t("COMMON.ERROR_MODAL_HEADING")}
        content={errorMessage}
      />
    </LayoutWithNav>
  );
}

export default MyCard;