import { MONEY_TRANSFER } from "../../constants/actionTypes";
import initialState from "../initialState";

export default function cancelTransaction(
  state = initialState.moneyTransfer.transferDetails,
  action
) {
  switch (action.type) {
    case MONEY_TRANSFER.CANCEL_TRANSACTION_SUCCESS: {
      return Object.assign({}, state, {
        ...action.cancelledTransaction,
      });
    }
    case MONEY_TRANSFER.CANCEL_TRANSACTION_ERROR: {
      return Object.assign({}, state, {
        ...action.cancelledTransaction,
        errors: action.errors
      });
    }
    default:
      return state;
  }
}