import React from "react";
import { Switch, Route, Router } from "react-router-dom";
import PropTypes from "prop-types";
import PrivateRoute from "../../components/Common/PrivateRoute";
import PrivateRedirectRoute from "../../components/Common/PrivateRedirectRoute";
import NoMatch from "../NoMatch";
import NewConversation from "../Support/NewConversation";
import { ROUTES } from "../../constants/clientRoutes";
import ENV from "../../constants/environment";
import Conversations from "../Support/Conversations";
import Conversation from "../Support/Conversation";
import Login from "../Login/Login";
import Password from "../Login/Password";
import WageStatements from "../WageStatements/WageStatements";
import ResetPIN from "../Pin/ResetPIN";
import MoneyTransfer from "../MoneyTransfer/MoneyTransfer";
import ComponentLibrary from "../ComponentLibrary/ComponentLibrary";
import DevRoutes from "../../components/Navigation/DevRoutes";
import TransferDetails from "../MoneyTransfer/TransferDetails";
import SecurityQuestionsContainer from "../SecurityQuestions/SecurityQuestionsContainer";
import ForgotSecurityQuestionContainer from "../SecurityQuestions/ForgotSecurityQuestionContainer";
import ForgotUsernameContainer from "../ForgotUsername/ForgotUsernameContainer";
import ForgotPasswordContainer from "../ForgotPassword/ForgotPasswordContainer";
import ChangeCurrentPasswordContainer from "../ForgotPassword/ChangeCurrentPasswordContainer";
import CurrencyConverter from "../MoneyTransfer/CurrencyConverter";
import FaceCheck from "../Login/FaceCheck";
import IntroFaceCheck from "../Login/IntroFaceCheck";
import SecurityQuestion from "../Login/SecurityQuestion";
import LoginSupport from "../Login/LoginSupport";
import LoginSupportEmail from "../Login/LoginSupportEmail";
import { withBrowserCompatible } from "../../components/Common/BrowserCompatible";
import PublicSupport from "../Support/PublicSupport";
import EnrollmentPageWizard from "../Enrollment/EnrollmentPageWizard";
import LogInMethod from "../Settings/LogInMethod";
import TOTPLogin from "../Login/TOTPLogin";
import Dashboard from "../../containers/Dashboard/Dashboard";
import MyCard from "../../containers/MyCard/index";
import LockCard from "../MyCard/LockCard";
import ActivateReplacementCard from "../MyCard/ActivateReplacementCard";
import PrivacyPolicy from "../PrivacyPolicy";
import CardToCardTransfer from "../MyCard/CardToCardTransfer";
import RequestCompanionCard from "../MyCard/RequestCompanionCard";
import Disclosures from "../Settings/Disclosures";
import WesternUnion from "../MyCard/WesternUnion";
import SendToWesternUnion from "../MyCard/SendToWesternUnion";
import PersonalDetails from "../Settings/PersonalDetails";
import CommunicationPreferences from "../Settings/CommunicationPreferences";
import GetHelpFaster from "../Support/GetHelpFaster";
import ODTWrapper from "../ODT/ODTWrapper";
import RRSDKTransferDetails from "../MyCard/RRSDKTransferDetails";
import Transfers from "../ODT/Transfers";
import CancelTransfer from "../ODT/CancelTransfer";
import ReviewAndAccept from "../Settings/ReviewAndAccept";
import WUToAccount from "../WUToAccount/Index";
import BankAccountsList from "../BankAccounts/Index";
import { TRANSACTION_SERVICE_TYPES, TRANSFER_METHODS } from "../../constants/enums";
import CardStatements from "../MyCard/CardStatements";
import BankAccountWizard from "../BankAccounts/BankAccountWizard";

/**
 * Handles all high level url routing
 *
 * @returns {jsx}
 * @constructor
 */
const Routes = ({ history }) => {
  const dev = ENV.BW_ENV !== "Prod";
  return (
    <Router history={history}>
      <Switch>
        <Route exact path={"/"} component={Login} />
        <Route exact path={ROUTES.AUTH.LOGIN} component={Login} />
        <Route exact path={ROUTES.AUTH.SUPPORT} component={LoginSupport} />
        <PrivateRedirectRoute
          exact
          path={ROUTES.AUTH.SUPPORT_EMAIL}
          component={LoginSupportEmail}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.AUTH.PASSWORD_LOGIN}
          component={Password}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.AUTH.SECURITY_QUESTION}
          component={SecurityQuestion}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.AUTH.TOTP}
          component={TOTPLogin}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.AUTH.LOGIN_FACECHECK}
          component={withBrowserCompatible(FaceCheck)}
        />
        <PrivateRoute
          exact
          path={ROUTES.AUTH.SETUP_FACECHECK}
          component={withBrowserCompatible(FaceCheck)}
        />
        <PrivateRoute
          exact
          path={ROUTES.AUTH.INTRO_FACECHECK}
          component={withBrowserCompatible(IntroFaceCheck)}
        />
        {dev && <Route exact path={ROUTES.DEV_ROUTES} component={DevRoutes} />}
        <Route path={ROUTES.COMPONENT_LIBRARY} component={ComponentLibrary} />
        <PrivateRoute
          exact
          path={ROUTES.MONEY_TRANSFER_DETAILS}
          component={TransferDetails}
        />
        <PrivateRoute
          exact
          path={ROUTES.MY_CARD.PUSH_TO_CARD_TRANSFER_DETAILS}
          customProps={{ transferMethod: TRANSFER_METHODS.PUSH_TO_CARD }}
          component={RRSDKTransferDetails}
        />
        <PrivateRoute
          exact
          path={ROUTES.MY_CARD.MC_TO_ACCOUNT_TRANSFER_DETAILS}
          customProps={{ transferMethod: TRANSFER_METHODS.TO_ACCOUNT }}
          component={RRSDKTransferDetails}
        />
        <PrivateRoute
          exact
          path={ROUTES.MY_CARD.TO_ACCOUNT_TRANSFER_DETAILS}
          customProps={{ serviceType: TRANSACTION_SERVICE_TYPES.TO_ACCOUNT }}
          component={TransferDetails}
        />
        <Route
          path={ROUTES.SETTINGS.FORGOT_USERNAME}
          component={ForgotUsernameContainer}
        />
        <Route
          path={`${ROUTES.SETTINGS.FORGOT_PASSWORD}/:username`}
          component={ForgotPasswordContainer}
        />
        <Route
          path={ROUTES.SETTINGS.FORGOT_PASSWORD}
          component={ForgotPasswordContainer}
        />
        <Route
          path={ROUTES.SETTINGS.CHANGE_CURRENT_PASSWORD}
          component={ChangeCurrentPasswordContainer}
        />

        {/* 
          Routes for MVC migration to react
          Commented out so that can't be navigated to before release.
        */}
        <PrivateRoute path={ROUTES.DASHBOARD} exact component={Dashboard} />
        <PrivateRoute exact path={ROUTES.MY_CARD.INDEX} component={MyCard} />
        <PrivateRoute exact path={ROUTES.MY_CARD.LOCK_CARD} component={LockCard} />
        <PrivateRoute exact path={ROUTES.MY_CARD.ACTIVATE_REPLACEMENT_CARD} component={ActivateReplacementCard} />
        <PrivateRoute exact path={ROUTES.MY_CARD.REQUEST_COMPANION_CARD} component={RequestCompanionCard} />
        <PrivateRoute exact path={ROUTES.SETTINGS.PERSONAL_DETAILS} component={PersonalDetails} />
        <PrivateRoute exact path={ROUTES.SETTINGS.DISCLOSURES} component={Disclosures} />
        <PrivateRoute exact path={ROUTES.MY_CARD.WESTERN_UNION} component={WesternUnion} />
        <PrivateRoute exact path={ROUTES.MY_CARD.SEND_TO_WESTERN_UNION} component={SendToWesternUnion} />
        <PrivateRoute exact path={ROUTES.MY_CARD.CARD_STATEMENTS} component={CardStatements} />

        <PrivateRoute exact path={ROUTES.MY_CARD.CARD_TO_CARD_TRANSFER} component={CardToCardTransfer} />
        <Route
          path={ROUTES.SETTINGS.FORGOT_SECURITY_QUESTION}
          component={ForgotSecurityQuestionContainer}
        />
        <PrivateRoute
          path={ROUTES.SETTINGS.LOG_IN_METHOD}
          component={LogInMethod}
        />
        <PrivateRoute path={ROUTES.PIN_RESET} component={ResetPIN} />
        <PrivateRoute
          path={ROUTES.SETTINGS.SECURITY_QUESTIONS}
          component={SecurityQuestionsContainer}
        />
        <PrivateRoute
          path={ROUTES.SETTINGS.COMMUNICATION_PREFERENCES}
          component={CommunicationPreferences}
        />
        <PrivateRoute
          path={ROUTES.SETTINGS.REVIEW_AND_ACCEPT}
          component={ReviewAndAccept}
        />
        <PrivateRoute
          path={ROUTES.WAGE_STATEMENTS}
          component={WageStatements}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.SUPPORT}
          redirectTo={ROUTES.SUPPORT_PUBLIC}
          component={Conversations}
        />
        <PrivateRedirectRoute
          exact
          path={ROUTES.SUPPORT_INDEX}
          redirectTo={ROUTES.SUPPORT_PUBLIC}
          component={Conversations}
        />
        <PrivateRoute
          exact
          path={ROUTES.SUPPORT_CONVERSATIONS}
          component={Conversations}
        />
        <PrivateRoute
          exact
          path={`${ROUTES.SUPPORT_CONVERSATIONS}/:id(\\d+)`}
          component={Conversation}
        />
        <PrivateRoute
          exact
          path={ROUTES.SUPPORT_NEW}
          component={NewConversation}
        />
        <PrivateRoute
          path={`${ROUTES.MONEY_TRANSFER}/:step?`}
          component={MoneyTransfer}
        />
        <Route path={ROUTES.CURRENCY_CONVERTER} component={CurrencyConverter} />
        <PrivateRoute
          path={ROUTES.ODT.TRANSFERS}
          component={Transfers}
          exact
        />
        <PrivateRoute
          path={ROUTES.ODT.CANCEL_TRANSFER}
          component={CancelTransfer}
          exact
        />
        <PrivateRoute
          path={`${ROUTES.ODT.ON_DEMAND_TRANSFER}`}
          component={ODTWrapper}
        />
        <Route
          path={ROUTES.EXTERNAL.PROFILE}
          exact
          render={() => {
            window.location = `${ENV.BASE_NAVIGATOR_URL}${ROUTES.EXTERNAL.PROFILE}`;
          }}
        />
        <Route
          path={ROUTES.EXTERNAL.LOGOUT}
          exact
          render={() => {
            window.location = ENV.BASE_NAVIGATOR_URL;
          }}
        />
        <PrivateRoute
          path={ROUTES.WU_TO_ACCOUNT.INDEX}
          customProps={{ serviceType: TRANSACTION_SERVICE_TYPES.TO_ACCOUNT }}
          component={WUToAccount}
        />

        <Route path={ROUTES.SUPPORT_PUBLIC} exact component={PublicSupport} />
        <Route exact path={ROUTES.SUPPORT_GET_HELP_FASTER} component={GetHelpFaster} />
        <Route exact path={ROUTES.BANKS_ACCOUNTS.INDEX} component={BankAccountsList} />
        <Route path={`${ROUTES.BANKS_ACCOUNTS.INDEX}/:page`} component={BankAccountWizard} />

        <Route path={ROUTES.ENROLLMENT.ENROLL} component={EnrollmentPageWizard} />

        <Route path={ROUTES.PRIVACY} component={PrivacyPolicy} />

        <Route component={NoMatch} />
      </Switch>
    </Router>
  );
};

Routes.propTypes = {
  /** The state tree */
  store: PropTypes.object.isRequired,
  /** react-router history */
  history: PropTypes.object.isRequired,
};

export default Routes;
