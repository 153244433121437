import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { ReturnUrl } from "../../actions/global";
import { APP_STATE } from "../../constants/enums";
import { ROUTES } from "../../constants/clientRoutes";

/**
 * Wrapper for react-router to manage if the route is accessible to the user
 *
 * @param Component
 * @param rest
 * @returns {null|*}
 * @constructor
 */
export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { appState, isAuthenticated, userFlags, flag, ReturnUrl, customProps } = rest;
  if (appState !== APP_STATE.Ready) {
    return null;
  } else if (!isAuthenticated) {
    ReturnUrl(window.location.pathname);
    // redirect to login if not authenticated
    // not setting from, since there are several redirects
    // after login screen depending on auth flow
    return <Redirect to={{ pathname: ROUTES.AUTH.LOGIN }} />;
  }

  if (flag && userFlags.indexOf(flag) < 0) return null;

  return <Route {...rest} render={(props) => {
    return <Component {...props} {...customProps} />
  }
  } />;
};

PrivateRoute.propTypes = {
  /** react-router location */
  location: PropTypes.any,
  /** Component to display at the route */
  component: PropTypes.elementType.isRequired,
  /** Is the user authenticated */
  isAuthenticated: PropTypes.bool,
  /** Feature flag to test */
  flag: PropTypes.string,
  /** Set return url for when the user logins */
  ReturnUrl: PropTypes.any,
  /** current app state */
  appState: PropTypes.string,
};

function areEqual(prevProps, nextProps) {
  // only re render route if these props changed
  return (
    prevProps.isAuthenticated === nextProps.isAuthenticated &&
    prevProps.userFlags === nextProps.userFlags &&
    prevProps.appState === nextProps.appState &&
    prevProps.location === nextProps.location
  );
}

export default connect(
  (state) => {
    return {
      appState: state.global.appState,
      isAuthenticated: state.user.isAuthenticated,
      userFlags: state.user.featureFlags,
    };
  },
  {
    ReturnUrl,
  }
)(React.memo(PrivateRoute, areEqual));
