import { ENROLLMENT } from "../constants/actionTypes";
import initialState from "./initialState";

export default function enrollmentReducer(state = initialState.enrollment, action) {
  switch (action.type) {
    case ENROLLMENT.UPDATE_ENROLLMENT_DATA: {
      return Object.assign({}, state, { userData: action.userData, currentPage: action.userData.nextPage });
    }
    case ENROLLMENT.UPDATE_CURRENT_PAGE: {
      return Object.assign({}, state, { currentPage: action.newPage });
    }
    default:
      return state;
  }
}