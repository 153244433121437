import axios from "axios";
import AuthApi from "./auth";
import { ROUTES } from "../constants/api";
import { HandleResponse, HandleErrorResponse } from "./handlers";
import { getAuthOrAnonymous, postAnonymous } from "./api";

/**
 * API calls dealing with support.
 */
class SupportApi {
  /**
   * Get's the standard auth headers and appends the api version
   * @returns {object} Headers
   * @constructor
   */
  static Headers() {
    let headers = Object.assign({}, AuthApi.getAuthorizationHeaders());
    headers["api-version"] = "1.0";

    return headers;
  }

  /**
   * Get's the main support data
   * @returns {Promise<void|*>} support data
   * @constructor
   */
  static async GetSupportIndex() {
    const url = `${ROUTES.SUPPORT.INDEX}`;

    try {
      let response = await axios.get(url, {
        headers: this.Headers(),
      });

      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  static async GetSupportCategories() {
    return getAuthOrAnonymous(ROUTES.SUPPORT.CATEGORIES).then(
      (response) => response.data
    );
  }

  /**
   * Post authenticated support ticket
   * @returns {Promise<void|*>} support data
   * @constructor
   */
  static async PostAuthenticatedSupportTicket(
    category,
    authToken = null,
    email = null,
    description = null
  ) {
    const url = ROUTES.SUPPORT.POST_TICKET;

    const payload = {
      supportRequest: category,
    };

    if (email) {
      payload.email = email;
    }

    if (description) {
      payload.supportQuestion = description;
    }

    const config = {
      headers: this.Headers(),
    };

    // Use auth token when partially authenticated.
    // Do not populate when fully authenticated
    if (authToken) {
      payload.token = authToken;
    }

    return axios
      .post(url, payload, config)
      .then((response) => {
        return HandleResponse(response);
      })
      .catch((error) => {
        return HandleErrorResponse(error);
      });
  }

  static async createSupportTicket(data) {
    return postAnonymous(ROUTES.SUPPORT.POST_TICKET, data).then((response) => {
      return HandleResponse(response);
    });
  }

  /**
   * Create's a new support conversation
   * @param {string} category
   * @returns {Promise<void|object>} response The new conversation data
   * @constructor
   */
  static async NewConversation(category) {
    const url = `${ROUTES.SUPPORT.NEW_CONVERSATION}`;
    try {
      let response = await axios.post(
        url,
        { category },
        { headers: this.Headers() }
      );

      return HandleResponse(response);
    } catch (err) {
      return HandleErrorResponse(err);
    }
  }

  /**
   * Submit's a file attachment to the conversation
   * @param {number} conversationId
   * @param {number} lastFetchedMessageId
   * @param {binary} attachment
   * @returns {Promise<void|object>} Updated conversation
   * @constructor
   */
  static async PostAttachment(
    conversationId,
    lastFetchedMessageId,
    attachment
  ) {
    const url = ROUTES.SUPPORT.POST_ATTACHMENTS(conversationId);

    let formData = new FormData();
    formData.append("files", attachment);
    formData.append("lastFetchedMessageId", lastFetchedMessageId);

    let headers = this.Headers();
    headers["content-type"] = "multipart/form-data";

    try {
      let response = await axios.post(url, formData, {
        headers,
      });

      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Add's a new message to the conversation
   * @param {string} text
   * @param {number} lastFetchedMessageId
   * @param {number} conversationId
   * @returns {Promise<void|object>} The updated conversation
   * @constructor
   */
  static async PostMessage(text, lastFetchedMessageId, conversationId) {
    const url = `${ROUTES.SUPPORT.POST_MESSAGE(conversationId)}`;
    let body = {
      text,
      lastFetchedMessageId,
    };

    try {
      let response = await axios.post(url, body, {
        headers: this.Headers(),
      });

      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }

  /**
   * Mark a conversation as read.
   * @param {number} conversationId
   */
  static async ReadConversation(conversationId) {
    const url = `${ROUTES.SUPPORT.POST_READ_CONVERSATION(conversationId)}`;
    try {
      let response = await axios.post(
        url,
        { conversationId },
        {
          headers: this.Headers(),
        }
      );
      return HandleResponse(response);
    } catch (error) {
      return HandleErrorResponse(error);
    }
  }
}

export default SupportApi;
