import React, { Component } from "react";
import LayoutWithNav from "../../components/Layouts/LayoutWithNav";
import PageNav from "../../components/Layouts/PageNav";
import { PAGE_NAV_LINKS, PAGES, COMMON } from "../../constants/localization";
import FlowScreen from "../../components/Layouts/FlowScreen";
import PageBlock from "../../components/Layouts/PageBlock";
import SecurityQuestions from "./SecurityQuestions";
import ActionRow from "../../components/Layouts/ActionRow";
import Action from "../../components/Common/Action";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../constants/enums";
import InputWrapper from "../Pin/InputWrapper";
import GenericForm from "../../components/Common/DynamicFields/GenericForm";
import { connect } from "react-redux";
import { change, untouch } from "redux-form";
import DynamicInputField from "../../components/Common/DynamicFields/DynamicInputField";
import AlertModal from "../../components/Common/Modals/AlertModal";
import FlowSuccess from "../../components/Common/Success/FlowSuccess";
import UserApi from "../../api/user";
import CatalogApi from "../../api/catalog";
import PropTypes from "prop-types";
import InputSkeletonGroup from "../../components/Common/Loaders/InputSkeleton";
import { ROUTES } from "../../constants/clientRoutes";
import SuccessCheckmark from "../../components/Common/Success/SuccessCheckmark";
import ActionLink from "../../components/Common/ActionLink";
import { getQueryVariableFromSearch } from "../../services/location";
import PAGE_ALERT from "../../constants/pageAlertTypes";
import { ICON_FONT_CLASS, ICON_TYPE } from "../../constants/iconfont";
import FocusLayoutSelector from "../../selectors/isFocusedLayout";
import { IconFont } from "../../components/Common/IconFont";
import PageAlert from "../../components/Common/PageAlert";
import { ERROR_CODES } from "../../constants/errorCodes";
import { EVENTS, eventTracker } from "../../services/eventTracker";

const FORM_NAME = "SecurityQuestionForm";

class SecurityQuestionsContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      securityQuestions: [],
      userSecurityQuestions: [],
      loading: false,
      loadingContent: false,
      successfulUpdate: false,
      formIsValid: false,
      showGenericError: false,
    };

    this.closeGenericModal = this.closeGenericModal.bind(this);
  }

  componentDidMount() {
    this.geSecurityQuestions();
  }

  /**
   * If this is the reset flow (unauthenticated), it skips getting the user's current password questions
   * and only loads the default questions. If this is the change flow (authenticated), it gets the user's
   * current password questions and also loads the defualt questions.
   */
  async geSecurityQuestions() {
    this.setState({ loadingContent: true });

    try {
      let key = this.getValueFromUrl("key");
      let response = key ? {} : await UserApi.GetUserPasswordQuestions();
      if (response.questions) {
        this.setState({ userSecurityQuestions: response.questions });
      }

      await this.loadDefaultSecurityQuestions();
    } catch (error) {
      this.setState({
        showGenericError: true,
      });
    }
  }

  async loadDefaultSecurityQuestions() {
    try {
      let response = await CatalogApi.GetPasswordQuestions();
      if (response.groups != null) {
        this.setState(
          {
            loadingContent: false,
            securityQuestions: response.groups,
          },
          () => {
            if (this.state.userSecurityQuestions.length) {
              this.matchSelectedQuestions();
            }
          }
        );
      } else {
        this.setState({
          loadingContent: false,
          showGenericError: true,
        });
      }
    } catch (error) {
      this.setState({
        showGenericError: true,
      });
    }
  }

  matchSelectedQuestions() {
    let securityQuestions = this.state.securityQuestions;
    let userSecurityQuestions = this.state.userSecurityQuestions;
    for (let i = 0; i < securityQuestions.length; i++) {
      let currentQuestionCollection = securityQuestions[i].questions;
      for (let j = 0; j < currentQuestionCollection.length; j++) {
        for (let k = 0; k < userSecurityQuestions.length; k++) {
          if (
            currentQuestionCollection[j].question ==
            userSecurityQuestions[k].question
          ) {
            this.preselectValues(i + 1, currentQuestionCollection[j].id);
            break;
          }
        }
      }
    }
  }

  preselectValues(groupNum, questionId) {
    this.props.dispatch(
      change(
        FORM_NAME,
        `${PAGES.SECURITY_QUESTIONS.QUESTION_LABEL}${groupNum}`,
        questionId
      )
    );
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState(
      {
        loading: true,
      },
      () => {
        let securityQuestionRequest = this.buildSecurityRequest();
        if (securityQuestionRequest == null) {
          this.setState({
            showGenericError: true,
            loading: false,
          });
        } else {
          this.submitPasswordQuestionAnswers(securityQuestionRequest);
        }
      }
    );
  };

  /**
   * Submits the request for updating the security questions to the server.
   * Displays a field error if invalid password error (error code US009).
   * Displays the generic error modal for all other errors.
   * @param {*} securityQuestionRequest
   */
  async submitPasswordQuestionAnswers(securityQuestionRequest) {
    try {
      let key = this.getValueFromUrl("key");
      await (key
        ? UserApi.ResetPasswordQuestions(securityQuestionRequest)
        : UserApi.UpdatePasswordQuestions(securityQuestionRequest));
      eventTracker.track(EVENTS.UpdateSecurityQuestions_AnswersSubmitted, {
        success: true,
      });
      this.setState(
        {
          loading: false,
          successfulUpdate: true,
        },
        () => {
          this.clearPasswordFieldValue();
        }
      );
    } catch (error) {
      eventTracker.track(EVENTS.UpdateSecurityQuestions_AnswersSubmitted, {
        success: false,
      });
      let {
        data: { errors },
      } = error;
      let showModal = true;
      if (error.status != 500) {
        if (errors[0].errorCode == ERROR_CODES.USER.INVALID_USER_OR_PASSWORD) {
          showModal = false;
          this.setInvalidPasswordError(errors[0].errorMessage);
        }
      }

      this.setState({
        showGenericError: showModal,
        loading: false,
      });
    }
  }

  /**
   * Sets the sync error in redux form so that the field displays an error.
   * This may not be the best way but I tried other solutions that I thought would
   * work but to no avail.  I tried:
   *  **throw new SubmissionError({Password: "Invalid Password"})**
   *  **this.props.dispatch(updateSyncErrors(FORM_NAME, {Password: "Invalid Password"}}))**
   *  **also tried using a custom validate method**
   */
  setInvalidPasswordError(errorMessage) {
    this.props.dispatch({
      type: "@@redux-form/UPDATE_SYNC_ERRORS",
      meta: { form: FORM_NAME },
      payload: {
        syncErrors: {
          [PAGES.SECURITY_QUESTIONS.PASSWORD_LABEL]: errorMessage,
        },
      },
    });
  }

  clearPasswordFieldValue() {
    this.props.dispatch(
      change(FORM_NAME, PAGES.SECURITY_QUESTIONS.PASSWORD_LABEL, "")
    );
    this.props.dispatch(
      untouch(FORM_NAME, PAGES.SECURITY_QUESTIONS.PASSWORD_LABEL)
    );
  }

  /**
   * Gathers all the informationit needs from the UI to send as request parameters.
   * The reset flow (unauthenticated) uses the key, session token, password, and questions.
   * The change flow (authenticated) uses the userid, in-app message type, password, and questions.
   */
  buildSecurityRequest() {
    if (this.props.securityQuestionForm.values != null) {
      let userGuid = this.props.userGuid;
      let values = this.props.securityQuestionForm.values;
      let questionGroupCount = this.state.securityQuestions.length;
      /// Using the password label to get the password value from
      /// the redux form collection of values
      let password = values[PAGES.SECURITY_QUESTIONS.PASSWORD_LABEL];
      let securityQuestions = [];
      for (let i = 1; i <= questionGroupCount; i++) {
        const answer = values[`${PAGES.SECURITY_QUESTIONS.ANSWER_LABEL}${i}`];
        securityQuestions.push({
          /// Using the question & answer field labels to get the password value from
          /// the redux form collection of values
          questionId: values[`${PAGES.SECURITY_QUESTIONS.QUESTION_LABEL}${i}`],
          // get only date #13134
          answer:
            answer instanceof Date
              ? answer.toISOString().split("T")[0]
              : answer,
        });
      }

      let output = {};
      let key = this.getValueFromUrl("key");
      if (key) {
        output = {
          key,
          password,
          token: this.props.sessionToken,
          passwordQuestions: securityQuestions,
        };
      } else {
        let msgType = this.getValueFromUrl("msgType", 0);
        output = {
          userId: userGuid,
          password,
          inAppMessageType: msgType,
          passwordQuestions: securityQuestions,
        };
      }
      return output;
    }
  }

  updateFormProps = (isFormValid) => {
    this.setState({
      formIsValid: isFormValid,
    });
  };

  closeGenericModal() {
    this.setState({
      showGenericError: false,
    });
  }

  /**
   * Gets the value of the key from the URL.
   */
  getValueFromUrl = (key, defaultVal) => {
    const { search } = this.props.location;
    const val = getQueryVariableFromSearch(search, key);
    return val || defaultVal || "";
  };

  render() {
    return (
      <React.Fragment>
        {this.state.successfulUpdate && !this.props.isFocused && (
          <PageAlert type={PAGE_ALERT.SUCCESS}>
            <IconFont
              icon={ICON_FONT_CLASS.CHECKMARK}
              type={ICON_TYPE.SUCCESS}
            />{" "}
            {PAGES.SECURITY_QUESTIONS.PAGE_ALERT_SUCCESSFUL}
          </PageAlert>
        )}
        <LayoutWithNav>
          <LayoutWithNav.PageNav>
            <PageNav>
              <PageNav.Header>
                {PAGE_NAV_LINKS.SETTINGS.HEADER}
              </PageNav.Header>
              <PageNav.NavContent
                navLinks={PAGE_NAV_LINKS.SETTINGS.LINKS}
              />
            </PageNav>
          </LayoutWithNav.PageNav>
          <LayoutWithNav.Content>
            <React.Fragment>
              {this.state.successfulUpdate && this.props.isFocused && (
                <FlowScreen
                  flowTitle={PAGES.SECURITY_QUESTIONS.MASTER_CONTAINER_TITLE}
                  className="security-questions-screen"
                >
                  <FlowSuccess>
                    <FlowSuccess.Image>
                      <SuccessCheckmark />
                    </FlowSuccess.Image>
                    <FlowSuccess.Title>
                      {PAGES.SECURITY_QUESTIONS.PAGE_ALERT_SUCCESSFUL}
                    </FlowSuccess.Title>
                    <FlowSuccess.ActionRow>
                      <ActionLink
                        dataCy="SecurityQuestionSuccessGoToDashboard"
                        classes="btn btn-primary"
                        href={this.props.successButtonUrl}
                        text={this.props.successButtonLabel}
                      />
                    </FlowSuccess.ActionRow>
                  </FlowSuccess>
                </FlowScreen>
              )}
              {(!this.state.successfulUpdate ||
                (this.state.successfulUpdate && !this.props.isFocused)) && (
                  <FlowScreen
                    flowTitle={PAGES.SECURITY_QUESTIONS.MASTER_CONTAINER_TITLE}
                    className="security-questions-screen"
                  >
                    <GenericForm
                      form={FORM_NAME}
                      onSubmit={this.handleSubmit}
                      onUpdate={this.updateFormProps}
                    >
                      <GenericForm.Body>
                        <PageBlock>
                          <PageBlock.Loader
                            loadingContent={this.state.loadingContent}
                          >
                            <InputSkeletonGroup numInputs={6} />
                          </PageBlock.Loader>
                          <PageBlock.Body
                            loadingContent={this.state.loadingContent}
                          >
                            <PageBlock.Text>
                              {PAGES.SECURITY_QUESTIONS.TITLE_DISCLAIMER}
                            </PageBlock.Text>
                            <SecurityQuestions
                              questions={this.state.securityQuestions}
                            />
                            <div className="security-questions-password">
                              <p>
                                {PAGES.SECURITY_QUESTIONS.PASSWORD_DISCLAIMER}
                              </p>

                              <InputWrapper className="row">
                                <DynamicInputField
                                  label={
                                    PAGES.SECURITY_QUESTIONS
                                      .CURRENT_PASSWORD_LABEL
                                  }
                                  type={FIELD_TYPES.TEXT}
                                  id={PAGES.SECURITY_QUESTIONS.PASSWORD_LABEL}
                                  isSensitive={true}
                                  required={true}
                                  className="form-group col-sm-7"
                                />
                              </InputWrapper>
                            </div>
                            <ActionRow>
                              <ActionRow.Forward>
                                <Action
                                  title={COMMON.SAVE}
                                  displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                                  type="submit"
                                  loading={this.state.loading}
                                  disabled={!this.state.formIsValid}
                                />
                              </ActionRow.Forward>
                            </ActionRow>
                          </PageBlock.Body>
                        </PageBlock>
                      </GenericForm.Body>
                    </GenericForm>
                  </FlowScreen>
                )}
            </React.Fragment>
          </LayoutWithNav.Content>
        </LayoutWithNav>
        <AlertModal
          open={this.state.showGenericError}
          title={COMMON.GENERIC_ERROR_MODAL_HEADER}
          content={COMMON.ERROR}
          onClose={this.closeGenericModal}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    securityQuestionForm: state.form[FORM_NAME],
    userGuid: state.user.profile.userGuid,
    isFocused: FocusLayoutSelector(state),
  };
};

SecurityQuestionsContainer.propTypes = {
  securityQuestionForm: PropTypes.object,
  userGuid: PropTypes.string,
  location: PropTypes.object,
  dispatch: PropTypes.func,
  isFocused: PropTypes.bool,
  sessionToken: PropTypes.string,
  successButtonLabel: PropTypes.string,
  successButtonUrl: PropTypes.string,
};

SecurityQuestionsContainer.defaultProps = {
  successButtonLabel: COMMON.GO_TO_DASHBOARD,
  successButtonUrl: ROUTES.EXTERNAL.DASHBOARD,
};

export default connect(mapStateToProps)(SecurityQuestionsContainer);
