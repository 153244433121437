import React, { useState } from "react";
import PropTypes from "prop-types";
import { reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouteLink } from "react-router-dom";
import { ACTION_DISPLAY_TYPES, FIELD_TYPES } from "../../../constants/enums";
import { COMMON, PAGES } from "../../../constants/localization";
import EnrollmentApi from "../../../api/enrollment";
import FlowScreen from "../../../components/Layouts/FlowScreen";
import GenericForm from "../../../components/Common/DynamicFields/GenericForm";
import PageBlock from "../../../components/Layouts/PageBlock";
import ValidationSummary from "../../../components/Enrollment/ValidationSummary";
import InputWrapper from "../../../containers/Pin/InputWrapper";
import DynamicInputField from "../../../components/Common/DynamicFields/DynamicInputField";
import ActionRow from "../../../components/Layouts/ActionRow";
import Action from "../../../components/Common/Action";
import ActionLink from "../../../components/Common/ActionLink";
import PageConstants from "../../../constants/page-numbers";
import PagePathConstants from "../../../constants/page-paths";
import { UpdateCurrentPage } from "../../../actions/enrollment";
import { trackEvent } from "../../../services/eventTracker";
import { EVENTS } from "../../../constants/events";
import { validateDate, validateCardNumber, validateConfirmCardNumber, validatePinNumber, validateConfirmPinNumber } from "../../../services/customValidations";

const CardDetailsForm = ({ setShowSpinner }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const formData = useSelector(
    (state) => state.form[PAGES.ENROLLMENT.CARD_DETAILS.FORM_NAME]
  );
  const userData = useSelector((state) => state.enrollment.userData);
  const currentPage = useSelector((state) => state.enrollment.currentPage);

  /**
   * Handle form submission
   */
  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    const { values } = formData;
    const expDate = new Date(values.ExpirationDate);

    const payload = {
      id: userData.workflowId,
      cardNumber: values.CardNumber,
      expMonth: String(expDate.getMonth() + 1),
      expYear: String(expDate.getFullYear()),
      pin: values.PinNumber,
      cardId: userData.cardId,
      bankProductId: userData.bankProductId,
      cardProductId: userData.cardProductId,
      companionParticipant: userData.companionParticipant,
      productGroupId: userData.productGroupId,
      clientId: userData.clientId,
      participantId: userData.participantId,
    }

    try {
      const response = await EnrollmentApi.SaveCardDetails(payload);

      trackEvent(EVENTS.ENROLLMENT.CARD_DETAILS_SUBMITTED, { success: true });
      setShowSpinner(false);
      dispatch(UpdateCurrentPage(response.nextPage));
    } catch (err) {
      setShowSpinner(false);
      trackEvent(EVENTS.ENROLLMENT.CARD_DETAILS_SUBMITTED, { success: false, failure_reason: err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL });
      setErrors([err[0].errorMessage || PAGES.ENROLLMENT.ERROR_GENERAL]);
    }
  };

  return (
    <FlowScreen flowTitle="Card Details">
      <GenericForm
        form={PAGES.ENROLLMENT.CARD_DETAILS.FORM_NAME}
        onSubmit={handleSubmit}
        onUpdate={(isValid) => setIsDisabled(!isValid)}
      >
        <GenericForm.Body>
          <PageBlock>
            <PageBlock.IntroText>
              {
                "Please complete the details below. Your card will not be active and ready to use until you complete the Navigator enrollment process."
              }
            </PageBlock.IntroText>
            <ValidationSummary errors={errors} />
            <PageBlock.Body>
              <div className="col-sm-12 col-md-6">
                <InputWrapper className="row">
                  <DynamicInputField
                    id="CardNumber"
                    type={FIELD_TYPES.INTEGER}
                    label="Card Number"
                    required={true}
                    focus={true}
                    validate={validateCardNumber}
                    max={16}
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="ConfirmCardNumber"
                    type={FIELD_TYPES.INTEGER}
                    label="Re-enter Card Number"
                    required={true}
                    focus={true}
                    validate={validateConfirmCardNumber}
                    max={16}
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="ExpirationDate"
                    type={FIELD_TYPES.DATE}
                    label="Card Expiration Date"
                    required={true}
                    displayDay={false}
                    validate={validateDate}
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="PinNumber"
                    type={FIELD_TYPES.PIN}
                    label="Create Pin"
                    required={true}
                    focus={true}
                    validate={validatePinNumber}
                  />
                </InputWrapper>

                <InputWrapper className="row">
                  <DynamicInputField
                    id="ConfirmPin"
                    type={FIELD_TYPES.PIN}
                    label="Confirm Pin"
                    required={true}
                    focus={true}
                    validate={validateConfirmPinNumber}
                  />
                </InputWrapper>
              </div>

              <div className="col-sm-12 col-md-6">
                {(userData && (userData.autoAllocateToCard ||
                  userData.bankProductId === 0)) && (
                    <p className="page-Instructions">
                      <span>If you dont have a card, </span>
                      <RouteLink to="/">Logout</RouteLink>
                      <span>
                        &nbsp;and complete enrollment once you&apos;ve received
                        your card.
                      </span>
                    </p>
                  )}
                <div
                  id="CardEnrollmentInstructions"
                  className="CardEnrollmentInstructions text-left"
                >
                  <p className="text-left">
                    <span className="page-instructions-2 font-color-6 bestPractices">
                      pin best practices
                    </span>
                  </p>
                  <ul className="text-left">
                    <li className="line-spacing-1">
                      Your PIN must be 4 digits.
                    </li>
                    <li className="line-spacing-1">
                      Your PIN can only contain numbers.
                    </li>
                    <li className="line-spacing-1">
                      Do not share your PIN with anyone.
                    </li>
                    <li className="line-spacing-1">
                      Do not write your PIN on your card.
                    </li>
                  </ul>
                </div>
              </div>

              <ActionRow>
                <ActionRow.Backward>
                  <ActionLink
                    clickFunc={() => dispatch(UpdateCurrentPage(currentPage - 1))}
                    text={COMMON.BACK}
                  />
                </ActionRow.Backward>
                <ActionRow.Forward>
                  <Action
                    title={COMMON.SUBMIT}
                    displayType={ACTION_DISPLAY_TYPES.PRIMARY}
                    type="submit"
                    disabled={isDisabled}
                  />
                </ActionRow.Forward>
              </ActionRow>
            </PageBlock.Body>
          </PageBlock>
        </GenericForm.Body>
      </GenericForm>
    </FlowScreen>
  );
};

CardDetailsForm.propTypes = {
  /** Function to display SpinningLoader */
  setShowSpinner: PropTypes.func.isRequired,
  /** react-router history */
  history: PropTypes.any.isRequired,
};

CardDetailsForm.defaultProps = {
  autoAllocateToCard: false,
  bankProductId: 0,
  backLocation: PagePathConstants[PageConstants.PERSONAL_DATA],
};

CardDetailsForm.displayName = "CardDetailsFormComponent";

export default reduxForm({
  form: PAGES.ENROLLMENT.CARD_DETAILS.FORM_NAME,
})(CardDetailsForm);
