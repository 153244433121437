import SupportApi from "../api/support";
import { SUPPORT } from "../constants/actionTypes";

function submitting() {
  return {
    type: SUPPORT.POSTING_NEW_CONVERSATION,
  };
}

/**
 * Get's the main support information and sets to state.
 * @returns {Function}
 * @constructor
 */
export function GetSupport() {
  return async function (dispatch) {
    try {
      let index = await SupportApi.GetSupportIndex();
      dispatch({
        type: SUPPORT.GET_INDEX_SUCCESS,
        index,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT.GET_INDEX_FAILURE,
        message:
          error.message || "Unfortunately there was an error, please try again",
      });
    }
  };
}

export function GetSupportCategories() {
  return async function (dispatch) {
    return SupportApi.GetSupportCategories().then((response) => {
      dispatch({
        type: SUPPORT.UPDATE_CATEGORIES,
        categories: response.categories,
      });

      return response.categories;
    });
  };
}

/**
 * Create's a new support conversation and set's updated values to state.
 * @param category
 * @returns {Function}
 * @constructor
 */
export function StartNewConversation(category) {
  return async (dispatch) => {
    dispatch(submitting());
    try {
      let { requesterId, conversation, isActiveConversation } =
        await SupportApi.NewConversation(category);

      dispatch({
        type: SUPPORT.POST_NEW_CONVERSATION_SUCCESS,
        conversation,
        requesterId,
        isActiveConversation: isActiveConversation,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT.POST_NEW_CONVERSATION_FAILURE,
        message:
          error.message ||
          "Unfortunately there was an issue.  Please try again",
      });
    }
  };
}

/**
 * Adds an file attachment to a conversation.
 * @param conversationId
 * @param lastFetchedMessageId
 * @param attachment
 * @returns {Function}
 * @constructor
 */
export function PostAttachment(
  conversationId,
  lastFetchedMessageId,
  attachment
) {
  return async (dispatch) => {
    try {
      let response = await SupportApi.PostAttachment(
        conversationId,
        lastFetchedMessageId,
        attachment
      );
      const { conversation } = response;
      dispatch({
        type: SUPPORT.POST_NEW_ATTACHMENT_SUCCESS,
        conversation,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT.POST_NEW_ATTACHMENT_FAILURE,
        message:
          error.message ||
          "Unfortunately there was an issue uploading your file. Please try again.",
      });
    }
  };
}

/**
 * Adds a new message to the given conversation.
 * @param message
 * @param lastFetchedMessageId
 * @param conversationId
 * @returns {Function}
 * @constructor
 */
export function PostMessage(message, lastFetchedMessageId, conversationId) {
  return async (dispatch) => {
    try {
      let response = await SupportApi.PostMessage(
        message,
        lastFetchedMessageId,
        conversationId
      );
      const { conversation } = response;
      dispatch({
        type: SUPPORT.POST_NEW_MESSAGE_SUCCESS,
        conversation,
      });
    } catch (error) {
      dispatch({
        type: SUPPORT.POST_NEW_MESSAGE_FAILURE,
        message:
          error.message ||
          "Unfortunately there was a problem while sending your message, please try again.",
      });
    }
  };
}

/**
 * Mark a conversation as read.
 * @param {number} conversationId
 * @returns {Function}
 * @constructor
 */
export function ReadConversation(conversationId) {
  return async (dispatch) => {
    return SupportApi.ReadConversation(conversationId).then(() => {
      dispatch({
        type: SUPPORT.POST_READ_CONVERSATION,
        conversationId,
      });
    });
  };
}

export function CreateSupportTicket(data) {
  return async () => {
    return SupportApi.createSupportTicket(data);
  };
}
