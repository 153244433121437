import { BANK_ACCOUNTS } from "../constants/actionTypes";
import initialState from "./initialState";

export default function bankAccounts(state = initialState.bankAccounts, action) {
  switch (action.type) {
    case BANK_ACCOUNTS.SHOW_DELETE_MODAL:
      return {
        ...state,
        allocationIdToDelete: action.allocationId,
        showDeleteModal: action.showModal
      };
    case BANK_ACCOUNTS.BANK_ACCOUNT_DELETED:
      return {
        ...state,
        bankAccountDeleted: action.payload
      };
    case BANK_ACCOUNTS.SET_BANK_ACCOUNT_DISBURSEMENT_HISTORY:
      return {
        ...state,
        disbursementHistory: {
          showDisbursementHistory: action.payload.showDisbursementHistory,
          bankAccountName: action.payload.bankAccountName,
          bankAccountNumber: action.payload.bankAccountNumber,
          disbursementHistory: action.payload.disbursementHistory
        }
      };
    case BANK_ACCOUNTS.SET_SELECTED_COUNTRY:
      return {
        ...state,
        availableCurrencies: action.currencies,
        destinationCountryName: action.destinationCountryName
      };
    case BANK_ACCOUNTS.SET_SELECTED_CURRENCY:
      return {
        ...state,
        destinationCurrencyName: action.destinationCurrencyName
      };
    default:
      return state;
  }
};