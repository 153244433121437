import React from 'react';

const SuccessModalContent = ({ message }: { message: string }) => {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
}

export default SuccessModalContent;