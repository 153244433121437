import { COUNTRY_IDS } from "../../../constants/countryCurrencies";
import { PAGES } from "../../../constants/localization";

const validAddress = (value) => {
  if (!value) return;
  if (hasPoBox(value)) {
    return false;
  }

  return true;
};

const hasPoBox = (str) => {
  const poBox =
    /(?:P(?:(o|0)st(?:al)?)?[.\-\s]*(?:(?:(O|0)(?:ffice)?[.\-\s]*)?B(?:ox|in|\b|\d)|(o|0|mb)(?:ffice|\b)(?:[-.\s]*\d)|code)|p(o|0)st(?:al)?[-.\s\b]*\d|box[-.\s\b]*\d)/i;
  var pattern = new RegExp(poBox, "i");
  return str.match(pattern);
};

const validZipCode = (value, values) => {

  if (values.countryId === COUNTRY_IDS.USA) {
    if (value && value.length < 1) {
      return false;
    } else if (/^\d{5}(-\d{4})?$/.test(value) === false) {
      return false;
    } else {
      return true;
    }
  } else if (values.countryId === COUNTRY_IDS.CANADA) {
    if (value && value.length < 1) {
      return false;
    } else if (value && value.length > 10) {
      return false;
    } else if (/^[A-Za-z0-9\- ]+$/.test(value) === false) {
      return false;
    } else {
      return true;
    }
  } else if (value && value.length > 0) {
    if (value && value.length > 10) {
      return false;
    } else if (/^[A-Za-z0-9\- ]+$/.test(value) === false) {
      return false;
    } else {
      return true;
    }
  } else {
    return true;
  }

};

const emailsMatch = (email, confirmEmail) => {
  if (!email || !confirmEmail) return;

  if (email.length > 0 && confirmEmail.length > 0) {
    if (email === confirmEmail) {
      return true;
    }

    return false;
  }
};

export const validate = (values) => {
  const errors = {};
  const { phoneNumber } = values;

  if (!values.firstName) {
    errors.firstName = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
  }

  if (!values.lastName) {
    errors.lastName = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
  }

  if (!validAddress(values.line1)) {
    errors.line1 = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_NO_PO_BOX;
  }

  if (values.line2 && !validAddress(values.line2)) {
    errors.line2 = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_NO_PO_BOX;
  }

  if (!validZipCode(values.zipCode, values)) {
    if (values.countryId === COUNTRY_IDS.USA) {
      if (values.zipCode.length < 1) {
        errors.zipCode = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
      } else {
        errors.zipCode = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_INVALID_ZIP_USA;
      }
    } else if (values.zipCode.length > 10) {
      errors.zipCode = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_INVALID_ZIP_LENGTH;
    } else if (values.countryId === COUNTRY_IDS.CANADA) {
      if (values.zipCode.length < 1) {
        errors.zipCode = PAGES.ENROLLMENT.ERROR_IS_REQUIRED;
      } else {
        errors.zipCode = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_INVALID_ZIP;
      }
    } else {
      errors.zipCode = PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_INVALID_ZIP;
    }

  }

  if (!emailsMatch(values.email, values.confirmEmail)) {
    errors.confirmEmail =
      PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_EMAILS_DO_NOT_MATCH;
  }

  if (phoneNumber) {
    if (
      phoneNumber.number !== null &&
      phoneNumber.number.length > 0 &&
      !phoneNumber.country
    ) {
      errors.phoneNumber =
        PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_COUNTRY_CODE_REQUIRED;
    }

    if (
      (phoneNumber.number === null || phoneNumber.number.length < 1) &&
      phoneNumber.country &&
      phoneNumber.country.id
    ) {
      errors.phoneNumber =
        PAGES.ENROLLMENT.PERSONAL_DATA.ERROR_PHONE_NUMBER_REQUIRED;
    }
  }

  return errors;
};
