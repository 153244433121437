import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import user from "./user";
import htmlBuilders from "./htmlBuilders";
import token from "./token";
import auth from "./auth";
import global from "./global";
import support from "./Support";
import card from "./card";
import catalog from "./Catalog";
import spinnersInProgress from "./spinnersInProgress";
import ChoiceEngineCashPickup from "./Forms/ChoiceEngineCashPickup";
import wageStatements from "./wageStatements";
import userSelections from "./userSelections";
import moneyTransfer from "./MoneyTransfer";
import errors from "./errors";
import initialApiCallErrors from "./dashboard";
import faceCheck from "./faceCheck";
import enrollment from "./enrollment";
import onDemandTransfer from "./onDemandTransfer";
import bankAccounts from "./bankAccounts";

export default (history) =>
  combineReducers({
    global,
    auth,
    form: formReducer.plugin({
      ChoiceEngineCashPickup,
    }),
    catalog,
    user,
    htmlBuilders,
    token,
    card,
    moneyTransfer,
    support,
    spinnersInProgress,
    userSelections,
    wageStatements,
    errors,
    initialApiCallErrors,
    faceCheck,
    enrollment,
    onDemandTransfer,
    bankAccounts,
    router: connectRouter(history),
  });
