import React from "react";
import PropTypes from "prop-types";
import CategorySelection from "../../components/Support/CategorySelection";
import { PAGES } from "../../constants/localization";
import CrewPageAlert from "./CrewPageAlert";
import { useSelector } from "react-redux";
import { FEATURE_FLAGS } from "../../constants/featureFlags";

/**
 * Entry point for beginning a support conversation
 *
 * @param categories
 * @param onSubmit
 * @returns {*}
 * @constructor
 */
export const NewConversationForm = ({
  categories,
  selectedCategoryId,
  onSelectedCategoryChange,
}) => {
  const featureFlags = useSelector((state) => state.user.featureFlags);

  return (
    <div className="support category">
      <h1 className="page-title">{PAGES.SUPPORT.LOGGED_IN.TITLE}</h1>

      {featureFlags.includes(FEATURE_FLAGS.ENABLE_CREWLINK_BANNER) &&
        <CrewPageAlert />
      }

      <p className="page-subtitle spacing-bottom-medium">
        {PAGES.SUPPORT.LOGGED_IN.SUBTITLE}
      </p>
      <CategorySelection
        categories={categories}
        selectedCategoryId={selectedCategoryId}
        onChange={onSelectedCategoryChange}
      />
    </div>
  );
};

NewConversationForm.propTypes = {
  /** Callback for submitting */
  onSelectedCategoryChange: PropTypes.func.isRequired,
  /** Array of strings */
  categories: PropTypes.array.isRequired,
  selectedCategoryId: PropTypes.string,
};

export default NewConversationForm;
